import { TopRightArrowIcon } from 'src/assets'
import { Link } from 'react-router-dom'

const PagesLinkSection = ({
  linksData = [],
  children = null,
  SectionIcon,
  title,
}) => {
  return (
    <div className='manage__container__pages-sections--desktop__card'>
      <div>
        <div className='manage__container__pages-sections--desktop__card__section-icon'>
          <SectionIcon />
        </div>

        <div className='manage__container__pages-sections--desktop__card__title'>
          {title}
        </div>
      </div>

      <div className='manage__container__pages-sections--desktop__card__links'>
        {children}

        {linksData?.map((link) => {
          return !!link ? (
            <Link
              className='manage__container__pages-sections--desktop__card__links__link'
              to={link?.path}
            >
              <p className='manage__container__pages-sections--desktop__card__links__link__title'>
                {link?.title}
              </p>

              <TopRightArrowIcon />
            </Link>
          ) : null
        })}
      </div>
    </div>
  )
}

export default PagesLinkSection
