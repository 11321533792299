import { ClientInformation, ProductDetails } from './components'

function ClientInformationAndProjectDetails({ project }) {
  return (
    <div className='client_information_wrapper'>
      <ClientInformation project={project} />
      <ProductDetails project={project} />
    </div>
  )
}

export default ClientInformationAndProjectDetails
