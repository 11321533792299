import { useTranslation } from 'react-i18next'

function MultiselectFooter({
  resetSelectedOptions,
  handleSelectOptions,
  handleSaveOptions,
}) {
  const { t } = useTranslation()

  return (
    <div className='multiselect_options_footer'>
      <div>
        <button
          className='multiselect_reset_btn'
          onClick={resetSelectedOptions}
        >
          {t('reset')}
        </button>
      </div>
      <div className='right_btns'>
        <button
          className='multiselect_cancel_btn'
          onClick={handleSelectOptions}
        >
          {t('cancel')}
        </button>
        <button className='multiselect_save_btn' onClick={handleSaveOptions}>
          {t('save')}
        </button>
      </div>
    </div>
  )
}

export default MultiselectFooter
