import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

export const useCrawlSpace = (setFieldsValue, getStepFiles) => {
  const [fileList, setFileList] = useState(getStepFiles(7))
  const { t } = useTranslation()

  useEffect(() => {
    setFieldsValue({
      crawlSpace_pictures: fileList,
    })
  }, [fileList])

  const layout = useMemo(() => {
    return {
      labelCol: { span: i18next.language === 'en' ? 10 : 9 },
      wrapperCol: { span: i18next.language === ' en' ? 14 : 15 },
    }
  }, [])

  const uploadChangeHandler = (info) => {
    setFileList(info?.fileList)
  }

  return { layout, t, fileList, uploadChangeHandler }
}
