import FormPowerOptimizersFormModal from './FormPowerOptimizersFormModal'
import { useAddPowerOptimizer } from './hooks'

const AddPowerOptimizer = ({ setAddPowerOptimizerModal, show }) => {
  const { addPowerOptimizerError, handleAdding, isLoading, form, t } =
    useAddPowerOptimizer(setAddPowerOptimizerModal)

  return (
    <FormPowerOptimizersFormModal
      closeHandler={() => setAddPowerOptimizerModal(false)}
      errors={addPowerOptimizerError}
      actionButtonTitle={t('save')}
      onFinish={handleAdding}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default AddPowerOptimizer
