import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updateActivityRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowActivityModal = (setShowActivityModal, currentActivity) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const closeModal = () => setShowActivityModal(false)

  const {
    mutate: updateActivity,
    isLoading,
    error,
  } = useMutation(
    (values) =>
      !isLoading && updateActivityRequest(currentActivity?.id, values),
    {
      onSuccess: () => {
        message.success(t('updated_successfully'))
        queryClient.refetchQueries('activities')
        closeModal()
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (!!currentActivity) {
      form.setFieldsValue(currentActivity)
    }
  }, [currentActivity, form])

  return {
    showActivityError: handleValidationError(error),
    updateActivity,
    closeModal,
    isLoading,
    form,
    t,
  }
}
