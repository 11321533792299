import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allPanelsRequest = async ({
  searchKeyWord = '',
  brandId = '',
  size = 10,
  companyId,
  sort = '',
  page,
}) => {
  return await axios.get(
    `api/panels?page=${page}&per_page=${size}${
      brandId && `&brands[]=${brandId}`
    }${searchKeyWord && `&search=${searchKeyWord}`}${appendCompanyId(
      companyId
    )}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updatePanelRequest = async (id, data) => {
  return await axios.put(`api/panels/${id}`, data)
}

export const addPanelsRequest = async (data) => {
  return await axios.post('api/panels', data)
}

export const importPanelsRequest = async (data) => {
  return await axios.post('api/panels/import', data)
}

export const deletePanelRequest = async (id) => {
  return await axios.delete(`api/panels/${id}`)
}
