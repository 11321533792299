export const DashboardReportingIcon = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none'>
      <g clipPath='url(#clip0_6552_5451)'>
        <path
          d='M45.6202 30.3496C37.9302 30.3496 31.6602 36.6196 31.6602 44.3096C31.6602 51.9996 37.9302 58.2696 45.6202 58.2696C48.0502 58.2696 50.2702 57.6596 52.3002 56.5496C56.6502 54.2196 59.5802 49.5696 59.5802 44.3096C59.5802 36.6196 53.3102 30.3496 45.6202 30.3496Z'
          fill='#65B2FE'
        />
        <path
          d='M45.6201 44.3105H59.5801C59.5801 49.5705 56.6501 54.2205 52.3001 56.5505L52.0001 55.7405L45.6301 44.3105H45.6201Z'
          fill='#3474D6'
        />
        <path
          d='M59.5801 44.3096H45.6201V30.3496C53.3101 30.3496 59.5801 36.6196 59.5801 44.3096Z'
          fill='#5EA6E9'
        />
        <path
          d='M52.0002 55.64L52.3002 56.45C50.3802 57.56 48.0502 58.17 45.6202 58.17C37.9302 58.17 31.6602 51.9 31.6602 44.21C31.6602 36.52 37.9302 30.25 45.6202 30.25V44.21L51.9902 55.64H52.0002Z'
          fill='#3858AC'
        />
        <path
          d='M14.0602 0C18.2102 0 21.6502 3.44 21.6502 7.59C21.6502 11.74 18.2102 15.18 14.0602 15.18C9.91022 15.18 6.47021 11.74 6.47021 7.59C6.47021 3.44 9.81022 0 14.0602 0Z'
          fill='#DCB98E'
        />
        <path d='M15.28 26.5996H12.75V29.1296H15.28V26.5996Z' fill='#EF727A' />
        <path
          d='M26.71 39.2497H20.34C19.63 39.2497 19.03 38.6397 19.03 37.9397C19.03 37.2397 19.64 36.6297 20.34 36.6297H25.4V27.2197C25.4 24.6897 24.29 22.3597 22.16 20.5397C20.04 18.7197 17.1 17.7097 14.07 17.7097C7.8 17.7097 2.64 21.9597 2.64 27.2197V36.7297H7.69C8.4 36.7297 9 37.3397 9 38.0397C9 38.7397 8.39 39.3497 7.69 39.3497H1.31C0.6 39.3497 0 38.7397 0 38.0397V27.2197C0 20.6397 6.27 15.1797 13.96 15.1797C17.6 15.1797 21.14 16.3897 23.67 18.6197C26.3 20.8497 27.82 23.9797 27.82 27.2197V37.9397C27.92 38.6497 27.42 39.2497 26.71 39.2497Z'
          fill='#EF727A'
        />
        <path
          d='M7.68988 59.4791C6.97988 59.4791 6.37988 58.8691 6.37988 58.1591V34.1891C6.37988 33.4791 6.98988 32.8691 7.68988 32.8691C8.38988 32.8691 8.99988 33.4791 8.99988 34.1891V58.2691C8.99988 58.8791 8.38988 59.4791 7.68988 59.4791Z'
          fill='#DA5359'
        />
        <path
          d='M20.33 59.4791C19.62 59.4791 19.02 58.8691 19.02 58.1591V34.1891C19.02 33.4791 19.63 32.8691 20.33 32.8691C21.03 32.8691 21.64 33.4791 21.64 34.1891V58.2691C21.64 58.8791 21.03 59.4791 20.33 59.4791Z'
          fill='#DA5359'
        />
        <path
          d='M14.06 59.4802C13.35 59.4802 12.75 58.8702 12.75 58.1602V44.3002C12.75 43.5902 13.36 42.9902 14.06 42.9902C14.76 42.9902 15.37 43.6002 15.37 44.3002V58.2602C15.27 58.8702 14.76 59.4702 14.06 59.4702V59.4802Z'
          fill='#DA5359'
        />
        <path
          d='M54.52 7.59094H36.82C36.11 7.59094 35.5 6.98094 35.5 6.28094C35.5 5.58094 36.11 4.96094 36.82 4.96094H54.52C55.23 4.96094 55.84 5.57094 55.84 6.28094C55.74 7.09094 55.23 7.59094 54.52 7.59094Z'
          fill='#BBBBBB'
        />
        <path
          d='M49.4698 15.1691H36.8298C36.1198 15.1691 35.5098 14.5591 35.5098 13.8591C35.5098 13.1591 36.1198 12.5391 36.8298 12.5391H49.4698C50.1798 12.5391 50.7798 13.1491 50.7798 13.8591C50.7798 14.5691 50.1698 15.1691 49.4698 15.1691Z'
          fill='#BBBBBB'
        />
        <path
          d='M54.52 22.7591H36.82C36.11 22.7591 35.5 22.1491 35.5 21.4391C35.5 20.7291 36.11 20.1191 36.82 20.1191H54.52C55.23 20.1191 55.84 20.7291 55.84 21.4391C55.74 22.2491 55.23 22.7591 54.52 22.7591Z'
          fill='#BBBBBB'
        />
      </g>
      <defs>
        <clipPath id='clip0_6552_5451'>
          <rect width='59.58' height='59.48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
