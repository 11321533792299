import { useProjectsOverview } from './useProjectsOverview'
import { Table, DatePicker, Select } from 'antd'
import { Header, CsvButton } from 'src/common'
import { BsArrowRight } from 'react-icons/bs'
import { columns } from './table/columns'

const ProjectsOverview = () => {
  const {
    handleDateChange,
    onManagerSelect,
    accountManagers,
    projects,
    isProjectLoading,
    exportReporting,
    isExportLoading,
    paginationInfo,
    handlePageChange,
    t,
  } = useProjectsOverview()

  return (
    <div className='projects-overview'>
      <Header />
      <div className='projects-overview__title'>
        {t('sales_projects_overview')}
      </div>
      <div className='projects-overview__contentitem'>
        <div className='projects-overview__content'>
          <div className='contentheader'>
            <Select
              placeholder={t('select_sales')}
              style={{ width: 200 }}
              onSelect={onManagerSelect}
              allowClear
              onClear={onManagerSelect}
            >
              {accountManagers?.map((accountManager) => (
                <Select.Option
                  key={accountManager?.id}
                  value={accountManager?.id}
                >
                  {accountManager?.name}
                </Select.Option>
              ))}
            </Select>
            <DatePicker.RangePicker
              className='contentheader__datepicker_container__datepicker'
              format='YY-MM-DD'
              separator={<BsArrowRight color='#bfbfbf' />}
              onChange={(_, dateString) => handleDateChange(dateString)}
            />
          </div>

          <Table
            scroll={{ x: '992px' }}
            bordered
            rowKey='id'
            columns={columns(t)}
            loading={isProjectLoading}
            dataSource={projects}
            pagination={{
              total: paginationInfo.total,
              current: paginationInfo.page,
              onChange: (page, size) => {
                handlePageChange(page, size)
              },
            }}
          />
          <div className='contentFooter'>
            <CsvButton
              onClick={exportReporting}
              disabled={isExportLoading}
              text={t('download_cvs')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectsOverview
