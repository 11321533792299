import ProjectDetailsSelector from './ProjectDetailsSelector'
import { useProjectDetails } from './hooks/useProjectDetails'
import { categories, getProjectStatuses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import { Form, Input } from 'antd'

const ProjectDetails = ({
  projectCondition,
  projectCompanyId,
  mutationErrors,
  project,
}) => {
  const {
    fetchGeneralConditionsOnScroll,
    accountManagersList,
    conditionsList,
    executorsList,
    t,
  } = useProjectDetails({ projectCompanyId })

  return (
    <div className='project__details__container'>
      <h3 className='section__title'>{t('project_details')}</h3>

      <div className='form__section__container project__details__inputs'>
        <ProjectDetailsSelector
          optionsList={getProjectStatuses(project?.status_id)}
          error={mutationErrors}
          label={t('status')}
          name='status_id'
        />

        <ProjectDetailsSelector
          optionsList={accountManagersList}
          label={t('account_manager')}
          error={mutationErrors}
          name='manager_id'
        />

        <ProjectDetailsSelector
          optionsList={executorsList}
          error={mutationErrors}
          label={t('executor')}
          name='executor_id'
          required={false}
        />

        <ProjectDetailsSelector
          fetchOnScroll={fetchGeneralConditionsOnScroll}
          optionsList={conditionsList}
          projectOption={projectCondition}
          error={mutationErrors}
          label={t('condition')}
          name='condition_id'
        />

        <ProjectDetailsSelector
          optionsList={categories}
          error={mutationErrors}
          label={t('category')}
          name='category_id'
        />

        <Form.Item
          className={`annual_consumption_input ${
            mutationErrors?.annual_consumption && 'error'
          }`}
          label={`${t('annual_consumption')}*`}
        >
          <Form.Item name='annual_consumption' noStyle>
            <Input
              error={mutationErrors?.annual_consumption}
              placeholder={t('annual_consumption')}
              name='annual_consumption'
              controls={false}
              type='number'
              min={0}
            />
          </Form.Item>
          <ShowFieldError error={mutationErrors?.annual_consumption} />
        </Form.Item>

        <Form.Item
          className={`${mutationErrors?.remarks && 'error'}`}
          label={t('comment')}
        >
          <Form.Item name='note' noStyle>
            <Input.TextArea name='note' placeholder={t('comment')} />
          </Form.Item>
          <ShowFieldError error={mutationErrors?.remarks} />
        </Form.Item>
      </div>
    </div>
  )
}

export default ProjectDetails
