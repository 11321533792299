import { appendCompanyId } from 'src/utils'
import { axios } from 'src/services'

export const allActivitiesRequest = async ({
  searchKeyWord = '',
  brandId = '',
  size = 10,
  companyId,
  sort = '',
  page,
}) => {
  return await axios.get(
    `api/activities?page=${page}&per_page=${size}${
      brandId && `&brands[]=${brandId}`
    }${searchKeyWord && `&search=${searchKeyWord}`}${appendCompanyId(
      companyId
    )}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updateActivityRequest = async (id, data) => {
  return await axios.put(`api/activities/${id}`, data)
}

export const importActivitiesRequest = async (data) => {
  return await axios.post('api/activities/import', data)
}

export const addActivityRequest = async (data) => {
  return await axios.post('api/activities', data)
}

export const deleteActivityRequest = async (id) => {
  return await axios.delete(`api/activities/${id}`)
}
