import { DatePicker, PhoneNumberInput, ShowFieldError } from 'src/common'
import { canManageProject, disablePastDate } from 'src/utils'
import { useContactSection } from './hooks/useContactSection'
import { Form, Input } from 'antd'

const ContactSection = ({ mutationErrors, currentType }) => {
  const { t } = useContactSection()

  return (
    <div className='form__section__container contact__section'>
      <Form.Item
        className={`project__creation__form__item ${
          currentType === 1 && 'project__creation__form__item__disabled'
        } ${mutationErrors?.company_name && currentType === 2 && 'error'}`}
        label={`${t('company_name')}*`}
      >
        <Form.Item name='company_name' noStyle>
          <Input
            placeholder={t('company_name')}
            disabled={currentType === 1}
            error={mutationErrors?.company}
            name='company_name'
          />
        </Form.Item>
        {currentType === 2 ? (
          <ShowFieldError error={mutationErrors?.company_name} />
        ) : null}
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.made_on_date && 'error'
        }`}
        label={`${t('date')}*`}
      >
        <Form.Item name='made_on_date' noStyle>
          <DatePicker
            error={mutationErrors?.made_on_date}
            disabled={!canManageProject()}
            name='made_on_date'
            disabledDate={(current) => disablePastDate(current)}
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.made_on_date} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.name && 'error'
        }`}
        label={`${t('client_name')}*`}
      >
        <Form.Item name='name' noStyle>
          <Input
            placeholder={t('name')}
            error={mutationErrors?.name}
            name='name'
          />
        </Form.Item>{' '}
        <ShowFieldError error={mutationErrors?.name} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.email && 'error'
        }`}
        label={`${t('email')}*`}
      >
        <Form.Item name='email' noStyle>
          <Input
            placeholder={t('email')}
            error={mutationErrors?.email}
            name='email'
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.email} />
      </Form.Item>

      <PhoneNumberInput
        wrapperClassName={`project__creation__form__item`}
        name='phone_number'
        placeholder={t('phone_number')}
        label={`${t('phone_number')}*`}
        error={mutationErrors?.phone_number}
        inputStyles={{ height: '4.8rem' }}
      />

      <PhoneNumberInput
        wrapperClassName={`project__creation__form__item`}
        name='mobile'
        placeholder={t('telephone')}
        label={t('telephone')}
        error={mutationErrors?.mobile}
        inputStyles={{ height: '4.8rem' }}
        errorClass='error'
      />

      <Form.Item
        className={`${mutationErrors?.remarks && 'error'}`}
        label={t('remarks')}
      >
        <Form.Item name='remarks' noStyle>
          <Input.TextArea name='remarks' placeholder={t('remarks')} />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.remarks} />
      </Form.Item>
    </div>
  )
}

export default ContactSection
