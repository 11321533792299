import i18next from 'i18next'

export const kindFilter = () => [
  {
    text: i18next.t('material'),
    value: 'material',
  },
  {
    text: i18next.t('converter'),
    value: 'converter',
  },
  {
    text: i18next.t('mounting_system'),
    value: 'mounting system',
  },
  {
    text: i18next.t('panel'),
    value: 'panel',
  },
  {
    text: i18next.t('wifi_module'),
    value: 'wifi module',
  },
  {
    text: i18next.t('power_optimizer'),
    value: 'power optimizer',
  },
]
