import { statusCountsRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Form } from 'antd'

export const useFilterAndSortSection = () => {
  const { t } = useTranslation()

  const [accountManagersForm] = Form.useForm()

  const { data: statusCountsData } = useQuery(
    'statusCounts',
    statusCountsRequest
  )

  const findStatusCount = (statusId) => {
    return statusCountsData?.data?.data?.find(
      (statusData) => statusData?.status_id === statusId
    )?.total
  }

  return {
    statusCountsList: statusCountsData?.data?.data,
    accountManagersForm,
    findStatusCount,
    t,
  }
}
