import { useEffect } from 'react'
import { useState } from 'react'
import { canManageProject } from 'src/utils'

export function useQuotationSignature({
  headquartersSignatureSrc,
  customerSignatureSrc,
}) {
  const [isHeadquartersSignatureModalOpen, setHeadquartersSignatureModalState] =
    useState(false)
  const [headquartersSignature, setHeadquartersSignature] = useState(() => {
    return headquartersSignatureSrc
      ? `${process.env.REACT_APP_BACKEND_URI}/storage/${headquartersSignatureSrc}`
      : null
  })
  const [isCustomerSignatureModalOpen, setCustomerSignatureModalState] =
    useState(false)
  const [customerSignature, setCustomerSignature] = useState(() => {
    return customerSignatureSrc
      ? `${process.env.REACT_APP_BACKEND_URI}/storage/${customerSignatureSrc}`
      : null
  })

  useEffect(() => {
    if (!customerSignatureSrc) {
      setCustomerSignature(null)
    }
  }, [customerSignatureSrc])

  useEffect(() => {
    if (!headquartersSignatureSrc) {
      setHeadquartersSignature(null)
    }
  }, [headquartersSignatureSrc])

  function toggleHeadquartersSignatureModal() {
    if (!canManageProject()) return

    return setHeadquartersSignatureModalState((prev) => !prev)
  }

  function toggleCustomerSignatureModal() {
    return setCustomerSignatureModalState((prev) => !prev)
  }

  return {
    headquartersSignature,
    setHeadquartersSignature,
    isCustomerSignatureModalOpen,
    isHeadquartersSignatureModalOpen,
    customerSignature,
    toggleCustomerSignatureModal,
    toggleHeadquartersSignatureModal,
    setCustomerSignature,
  }
}
