import { BLOCK_TYPES, INLINE_STYLES } from './constants'
import ControlsButton from './ControlsButton'

export function BlockStyleControls(props) {
  return (
    <div className='block-controls-wrapper'>
      {BLOCK_TYPES.map((type) => (
        <ControlsButton
          key={type.label}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          displayStyle={type.displayStyle}
        />
      ))}
    </div>
  )
}

export function InlineStyleControls(props) {
  return (
    <div className='inline-controls-wrapper'>
      {INLINE_STYLES.map((type) => (
        <ControlsButton
          key={type.label}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          displayStyle={type.displayStyle}
        />
      ))}
    </div>
  )
}
