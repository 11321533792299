export const appendStatusIds = (statusIds = [], appendStatusIds = false) => {
  let temp = ''

  if (!appendStatusIds) {
    if (statusIds) {
      statusIds?.forEach((id) => {
        temp += `&status_id[]=${id}`
      })
    }
  } else {
    let openProjectsIds = [1, 2, 3, 4, 5]

    if (statusIds.length === 0) {
      openProjectsIds.forEach((status) => {
        temp += `&status_id[]=${status}`
      })
    } else {
      statusIds?.forEach((status) => {
        if (openProjectsIds.includes(status)) {
          temp += `&status_id[]=${status}`
        } else {
          temp += `&status_id[]=0`
        }
      })
    }
  }

  return temp
}
