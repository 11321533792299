import { Header, ButtonWithPlus, Search } from 'src/common'
import AddUsersModal from './components/AddUserModal'
import ShowUserModal from './components/ShowUserModal'
import getUserTableColumns from './UserTableColumns'
import { AgreeDeleteModal } from 'src/common'
import { isSalesPlus } from 'src/utils'
import { useUsers } from 'src/hooks'
import { Table } from 'antd'

const Users = () => {
  const {
    setIsCompanyFilterVisible,
    skillsForDropDown,
    handleTableChange,
    usersDataLoading,
    handlePageChange,
    setShowUserModal,
    setAddUserModal,
    paginationInfo,
    companyFilters,
    setDeleteModal,
    updateUserInfo,
    onSearchClick,
    showUserModal,
    addUserModal,
    rolesFilter,
    deleteModal,
    deleteUser,
    users,
    roles,
    user,
    t,
  } = useUsers()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('users')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={usersDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          {!isSalesPlus() ? (
            <ButtonWithPlus
              text={t('add')}
              onClick={() => setAddUserModal(true)}
            />
          ) : null}
        </div>
        <Table
          className='users_table'
          scroll={{ x: '992px' }}
          bordered
          columns={getUserTableColumns({
            setIsCompanyFilterVisible,
            setDeleteModal,
            companyFilters,
            rolesFilter,
            user,
            t,
          })}
          dataSource={users || []}
          rowKey={(record) => record.id}
          loading={usersDataLoading}
          onChange={handleTableChange}
          onRow={(record) => {
            return {
              onClick: () => setShowUserModal({ show: true, data: record }),
            }
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
        />
      </div>

      {addUserModal && (
        <AddUsersModal
          skillsForDropDown={skillsForDropDown}
          setAddUserModal={setAddUserModal}
          show={addUserModal}
          roles={roles}
          user={user}
        />
      )}

      {showUserModal.show && (
        <ShowUserModal
          skillsForDropDown={skillsForDropDown}
          setShowUserModal={setShowUserModal}
          currentUser={showUserModal.data}
          updateUserInfo={updateUserInfo}
          show={showUserModal.show}
          roles={roles}
          user={user}
        />
      )}

      {deleteModal.show && (
        <AgreeDeleteModal
          visible={deleteModal.show}
          targetName={deleteModal?.data?.name}
          onCancel={() => setDeleteModal({ show: false, data: {} })}
          onSubmit={() =>
            deleteModal.data.id && deleteUser(deleteModal.data.id)
          }
        />
      )}
    </div>
  )
}

export default Users
