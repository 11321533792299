import { useGetUserInfo } from './useGetUserInfo'
import {
  showPlanningPipeline,
  isSuperUser,
  showReports,
  isAdmin,
} from 'src/utils'
import { useMemo } from 'react'

export const useEnabledFeatures = () => {
  const { user } = useGetUserInfo()

  const isReportingEnabled = useMemo(() => {
    return (showReports() && !!user?.company?.module_reports) || isSuperUser()
  }, [user])

  const isNewLeadsEnabled = useMemo(() => {
    return (isAdmin() && !!user?.company?.module_leads) || isSuperUser()
  }, [user])

  const isSolarPlanningEnabled = useMemo(() => {
    if (isSuperUser()) return true
    return !!user?.company?.enable_solarplanning && showPlanningPipeline()
  }, [user])

  return { isReportingEnabled, isSolarPlanningEnabled, isNewLeadsEnabled }
}
