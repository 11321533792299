import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useState, useRef, useEffect } from 'react'
import { useOnClickOutside } from 'src/hooks'

function useCalendarEvent() {
  const [isTaskTooltipVisible, setTaskTooltipVisible] = useState(false)
  const [isHoverTooltipVisible, setIsHoverTooltipVisible] = useState(false)
  const [isPopupModalVisible, setIsPopupModalVisible] = useState(false)

  const { lg, sm } = useBreakpoint()

  useEffect(() => {
    if (lg && isPopupModalVisible) {
      handlePopupModalClose()
    }
    if (!lg && isTaskTooltipVisible) {
      setTaskTooltipVisible(false)
    }
  }, [lg])

  function handleTaskInfoTooltipToggle(e) {
    if (lg) {
      if (isHoverTooltipVisible) {
        setIsHoverTooltipVisible(false)
      }

      setTaskTooltipVisible((prev) => !prev)
    } else {
      handlePopupModalOpen()
    }
  }

  function handleTaskInfoTooltipClose(e) {
    if (lg) {
      if (isTaskTooltipVisible) {
        setTaskTooltipVisible(false)
      }
    } else {
      handlePopupModalClose()
    }
  }

  function handHoverTooltipClose() {
    if (isHoverTooltipVisible) {
      setIsHoverTooltipVisible(false)
    }
  }

  function handHoverTooltipOpen() {
    if (!isTaskTooltipVisible) {
      setIsHoverTooltipVisible(true)
    }
  }

  function handleEventMouseDown(e) {
    if (isTaskTooltipVisible) {
      return e.stopPropagation()
    }
  }

  function handlePopupModalOpen() {
    if (isHoverTooltipVisible) {
      setIsHoverTooltipVisible(false)
    }
    setIsPopupModalVisible(true)
  }

  function handlePopupModalClose() {
    setIsPopupModalVisible(false)
  }

  const eventRef = useRef(null)

  useOnClickOutside(eventRef, handHoverTooltipClose)

  return {
    isHoverTooltipVisible: isHoverTooltipVisible && lg,
    handleTaskInfoTooltipToggle,
    handleTaskInfoTooltipClose,
    handHoverTooltipClose,
    handHoverTooltipOpen,
    handleEventMouseDown,
    isPopupModalVisible,
    isTaskTooltipVisible,
    isSmall: !sm,
    eventRef,
  }
}

export { useCalendarEvent }
