import axios from '../../axios'

export const brandByClassificationRequest = async (classification, project) => {
  return await axios.get(
    `api/brands?classification=${classification}${
      project ? `&project=${project}` : ''
    }`
  )
}

export const addBrandRequest = async (data) => {
  return await axios.post('api/brands', data)
}
