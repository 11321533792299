import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

export const useComplexityAndComments = (getStepFiles, setFieldsValue) => {
  const [fileList, setFileList] = useState(getStepFiles(8))

  useEffect(() => {
    setFieldsValue({
      complexity_pictures: fileList,
    })
  }, [fileList])

  const { t } = useTranslation()

  const layout = useMemo(() => {
    return {
      labelCol: { span: i18next.language === 'nl' ? 10 : 8 },
      wrapperCol: { span: i18next.language === 'nl' ? 14 : 16 },
    }
  }, [])

  const uploadChangeHandler = (info) => {
    setFileList(info?.fileList)
  }

  return { layout, t, uploadChangeHandler, fileList }
}
