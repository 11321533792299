import { BsChevronDown } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd'

const ManagePageSectionsAccordion = ({
  setActiveAccordionKey,
  activeAccordionKey,
  children = null,
  linksData = [],
  accordionKey,
  title,
}) => {
  return (
    <Collapse
      onChange={(key) => setActiveAccordionKey(key)}
      activeKey={activeAccordionKey}
      expandIcon={({ isActive }) => (
        <BsChevronDown
          style={{
            transform: isActive ? 'rotate(180deg) translateY(1rem)' : '',
            transition: 'all 0.3s ease',
          }}
          size={18}
        />
      )}
      bordered={false}
      expandIconPosition='right'
      accordion
    >
      <Collapse.Panel header={title} key={accordionKey}>
        <div className='manage__container__pages-sections--mobile__accordion-container'>
          {linksData?.map((link) => {
            return !!link ? (
              <Link
                className='manage__container__pages-sections--mobile__accordion-container__link'
                to={link?.path}
              >
                {link?.title}
              </Link>
            ) : null
          })}
          {children}
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

export default ManagePageSectionsAccordion
