import { useQueryClient, useMutation } from 'react-query'
import { appendCompanyId, isSuperUser } from 'src/utils'
import { useGetProductBrand } from 'src/hooks'
import { addBrandRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export const usePanelModalForm = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const { mutate: addPanelBrand, isLoading: addPanelBrandLoading } =
    useMutation(addBrandRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries('panelBrands')
      },
    })

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'panelBrands',
    url: `api/brands?classification=1${appendCompanyId(user?.company?.id)}`,
  })

  const clickHandler = (brandInput) => {
    if (!brandInput) return

    const additionalOptions = {}

    if (isSuperUser()) {
      additionalOptions['company_id'] = user?.company?.id
    }

    addPanelBrand({
      name: brandInput,
      classification: 1,
      ...additionalOptions,
    })
  }

  return {
    panelBrands: productBrands,
    addPanelBrandLoading,
    fetchBrandOnScroll,
    addPanelBrand,
    clickHandler,
    t,
  }
}
