import { SelectRenderInput } from 'src/screens/Manage/components'
import { usePanelModalForm } from './hooks'
import { Select } from 'antd'
import {
  AmountInputItem,
  FormModalWrapper,
  SelectItem,
  InputItem,
} from 'src/common'

const PanelModalForm = ({
  currentPanel = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  show,
  form,
}) => {
  const {
    addPanelBrandLoading,
    fetchBrandOnScroll,
    clickHandler,
    panelBrands,
    t,
  } = usePanelModalForm()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      closeHandler={closeHandler}
      modalTitle={t('panel')}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <SelectItem
        defaultValue={currentPanel?.brand?.id}
        onPopupScroll={fetchBrandOnScroll}
        error={errors?.brand_id}
        dropdownRender={(menu) => {
          return (
            <SelectRenderInput
              disabled={addPanelBrandLoading}
              clickHandler={clickHandler}
              buttonTitle={t('add_brand')}
              menu={menu}
            />
          )
        }}
        label={t('brand')}
        name='brand_id'
        form={form}
      >
        {currentPanel?.brand && (
          <Select.Option
            key={currentPanel?.brand?.id}
            value={currentPanel?.brand?.id}
          >
            {currentPanel?.brand?.name}
          </Select.Option>
        )}
        {panelBrands?.map((page) => {
          return page?.data?.data?.map((brand) => {
            if (currentPanel?.brand?.id !== brand?.id) {
              return (
                <Select.Option key={brand?.id} value={brand?.id}>
                  {brand?.name}
                </Select.Option>
              )
            } else {
              return null
            }
          })
        })}
      </SelectItem>

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <InputItem error={errors?.type} label={t('type')} name='type' />

      <InputItem
        error={errors?.watt}
        label={t('watt')}
        type='number'
        name='watt'
      />

      <InputItem
        error={errors?.voc}
        label={t('voc')}
        type='number'
        name='voc'
      />

      <InputItem
        error={errors?.weight}
        label={t('weight')}
        type='number'
        name='weight'
      />

      <InputItem
        error={errors?.width}
        label={t('width')}
        type='number'
        name='width'
      />

      <InputItem
        error={errors?.lmp}
        label={t('lmp')}
        type='number'
        name='lmp'
      />

      <InputItem
        error={errors?.length}
        label={t('length')}
        type='number'
        name='length'
      />

      <InputItem
        error={errors?.depth}
        label={t('depth')}
        type='number'
        name='depth'
      />

      <AmountInputItem
        defaultValue={currentPanel?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={currentPanel?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />

      <InputItem
        label={t('warranty_per_year')}
        error={errors?.warranty}
        name='warranty'
        type='number'
      />

      <InputItem
        error={errors?.description}
        label={t('description')}
        name='description'
      />
    </FormModalWrapper>
  )
}

export default PanelModalForm
