import { useFetchCountries, useGeocodeAddress } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const useAddressSection = ({ form, getAddressOnInitialRender }) => {
  const [zipcode, setZipcode] = useState(() => form.getFieldValue('zipcode'))
  const [houseNumber, setHouseNumber] = useState(() =>
    form.getFieldValue('house_number')
  )

  const { t } = useTranslation()

  const { countriesData } = useFetchCountries()

  useGeocodeAddress({
    formValues: { zipcode, houseNumber },
    countries: countriesData?.data,
    form,
    calculateOnInitialRender: getAddressOnInitialRender,
  })

  return { countriesList: countriesData?.data, t, setZipcode, setHouseNumber }
}
