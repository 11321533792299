import { formItemErrorClasses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import { Form, Input } from 'antd'

const InputItem = ({
  changeHandler = () => {},
  withErrorHeight = true,
  disableScroll = true,
  placeholder = '',
  disabled = false,
  required = true,
  className = '',
  type = 'text',
  value = '',
  style = {},
  error,
  label,
  name,
}) => {
  return (
    <Form.Item
      className={`custom-antd-input-item ${formItemErrorClasses(error)} ${
        error && 'ant-input-border-error'
      } ${className} ${name}`}
      required={required}
      label={label}
      style={style}
      name={name}
    >
      <Form.Item name={name}>
        <Input
          type={type}
          placeholder={placeholder}
          onWheel={() => {
            if (type === 'number' && disableScroll)
              document.activeElement.blur()
          }}
          disabled={disabled}
          value={value}
          onChange={(e) => changeHandler(e.target.value, name)}
        />
      </Form.Item>

      <ShowFieldError withHeight={withErrorHeight} error={error} />
    </Form.Item>
  )
}

export default InputItem
