export const VacationIcon = () => {
  return (
    <svg width='61' height='60' viewBox='0 0 61 60' fill='none'>
      <g id='Isolation_Mode' clipPath='url(#clip0_6552_3321)'>
        <g id='1910, Desk, Job, Office, Person, Staff'>
          <g id='Group'>
            <path
              id='Vector'
              d='M7.59002 59.4798C6.88002 59.4798 6.27002 58.8698 6.27002 58.1598V31.6598C6.27002 30.9498 6.88002 30.3398 7.59002 30.3398C8.30002 30.3398 8.90002 30.9498 8.90002 31.6598V58.2598C8.90002 58.8698 8.29002 59.4698 7.59002 59.4698V59.4798Z'
              fill='#60534A'
            />
            <path
              id='Vector_2'
              d='M30.3498 59.4798C29.6398 59.4798 29.0298 58.8698 29.0298 58.1598V31.6598C29.0298 30.9498 29.6398 30.3398 30.3498 30.3398C31.0598 30.3398 31.6598 30.9498 31.6598 31.6598V58.2598C31.6598 58.8698 31.0498 59.4698 30.3498 59.4698V59.4798Z'
              fill='#60534A'
            />
            <path
              id='Vector_3'
              d='M54.4199 8.90039C57.2099 8.90039 59.4799 11.1604 59.4799 13.9604C59.4799 16.7604 57.2199 19.0204 54.4199 19.0204C51.6199 19.0204 49.3599 16.7604 49.3599 13.9604C49.3599 11.1604 51.6199 8.90039 54.4199 8.90039Z'
              fill='#EEA243'
            />
            <path
              id='Vector_4'
              d='M32.88 0C37.03 0 40.47 3.44 40.47 7.59C40.47 11.74 37.03 15.18 32.88 15.18C28.73 15.18 25.29 11.74 25.29 7.59C25.29 3.44 28.73 0 32.88 0Z'
              fill='#BEC3C7'
            />
            <path
              id='Vector_5'
              d='M24.0798 20.2295L32.8798 31.6595H30.3498H16.4898L7.58984 19.0195L24.0798 20.2295Z'
              fill='#747371'
            />
            <path
              id='Vector_6'
              d='M35.6102 55.6395C35.3102 55.6395 35.1002 55.5395 34.9002 55.4395C34.2902 55.0395 34.1902 54.2295 34.6002 53.7195L44.0102 40.5695C44.0102 40.4695 44.1102 40.4695 44.1102 40.3695C44.8202 39.6595 45.8302 39.2595 46.8402 39.2595H50.6802V32.6795C50.2702 32.7795 49.8702 32.8795 49.3602 32.8795H42.9902C42.2802 32.8795 41.6702 32.2695 41.6702 31.5695C41.6702 30.8695 42.2802 30.2495 42.9902 30.2495H49.3602C49.5602 30.2495 49.7602 30.1495 49.9702 30.0495L51.1802 29.3395C51.5802 29.1395 52.0902 29.1395 52.4902 29.3395C52.9002 29.5395 53.1002 29.9495 53.1002 30.4495V40.3595C53.1002 41.0695 52.4902 41.6695 51.7802 41.6695H46.7202C46.4202 41.6695 46.1102 41.7695 45.9102 41.9695L36.6002 55.0195C36.4002 55.4195 35.9902 55.6295 35.5902 55.6295L35.6102 55.6395Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_7'
              d='M39.1498 59.2798C38.9498 59.2798 38.6398 59.1798 38.4398 59.0798C37.8298 58.6798 37.7298 57.8698 38.1398 57.3598L46.7398 44.9198C46.9398 44.6198 47.3498 44.4098 47.7498 44.4098H55.5398C56.9598 44.4098 58.0698 43.2998 58.0698 41.8798V26.7098C58.0698 25.3998 56.9598 24.2798 55.6398 24.1798H55.4398C54.7298 24.1798 54.1298 24.4798 53.6198 24.9898C53.5198 25.0898 53.4198 25.1898 53.3198 25.1898L49.1698 27.7198C48.9698 27.8198 48.7698 27.9198 48.5598 27.9198H41.6798C40.9698 27.9198 40.3698 28.5298 40.3698 29.2398C40.3698 29.9498 40.9798 30.5598 41.6798 30.5598C42.3798 30.5598 42.9998 31.1698 42.9998 31.8698C42.9998 32.5698 42.3898 33.1898 41.6798 33.1898C39.5598 33.1898 37.8398 31.4698 37.8398 29.3498C37.8398 27.2298 39.5598 25.5098 41.6798 25.5098H48.2598L51.9998 23.2798C52.9098 22.3698 54.1198 21.7598 55.4398 21.7598H55.7398C58.4698 21.8598 60.5998 24.0898 60.5998 26.8198V41.9898C60.5998 44.8198 58.3698 47.0498 55.5398 47.0498H48.3598L40.0698 58.8898C39.9698 59.0898 39.5598 59.2898 39.1598 59.2898L39.1498 59.2798Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_8'
              d='M42.99 32.88H1.31C0.6 32.88 0 32.27 0 31.57C0 30.87 0.61 30.25 1.31 30.25H42.99C43.7 30.25 44.31 30.86 44.31 31.57C44.31 32.28 43.7 32.88 42.99 32.88Z'
              fill='#73665C'
            />
            <path
              id='Vector_9'
              d='M35.4098 11.4299C35.1098 11.4299 34.7998 11.3299 34.4998 11.0299L31.9698 8.49992C31.7698 8.29992 31.5698 7.88992 31.5698 7.58992V3.73992C31.5698 3.02992 32.1798 2.41992 32.8798 2.41992C33.5798 2.41992 34.1998 3.02992 34.1998 3.73992V6.97992L36.3198 9.09992C36.8298 9.60992 36.8298 10.4099 36.3198 10.9199C36.0198 11.3199 35.7098 11.4299 35.4098 11.4299Z'
              fill='white'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_6552_3321'>
          <rect width='60.59' height='59.48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
