import { useHomeScreenHeader } from './useHomeScreenHeader'
import { NewProjectButtonLink } from './components'
import { Header } from 'src/common'

const HomeScreenHeader = () => {
  const { t } = useHomeScreenHeader()

  return (
    <Header
      left={<p className='homescreen__header__title'>{t('projects')}</p>}
      wrapperClassName='homescreen__header-wrapper'
      className='homescreen__header'
      right={<NewProjectButtonLink />}
    />
  )
}

export default HomeScreenHeader
