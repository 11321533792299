import { useMutation, useQueryClient } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { loginAsTenantRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { changeLanguage } from 'i18next'
import { setUser } from 'src/actions'

const useConfiguration = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const queryClient = useQueryClient()

  const { isLoading: isLoginningAsTennant, mutate: loginAsTenantMutation } =
    useMutation((id) => !isLoginningAsTennant && loginAsTenantRequest(id), {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          changeLanguage(resp.data?.user?.lang)
          dispatch(setUser(resp.data))
          queryClient.invalidateQueries()
          history.push('/')
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    })

  return { t, loginAsTenantMutation, isLoginningAsTennant }
}

export default useConfiguration
