export const joinFirstLetters = (word, letterCount) => {
  const firstLettersOfWord = word
    ?.split(' ')
    .map((word) => word[0])
    .slice(0, letterCount && letterCount)
    .join('')
    .toUpperCase()

  return firstLettersOfWord
}
