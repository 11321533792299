import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { exportOffer } from 'src/services'
import {
  handleNonValidationError,
  offerStatuses,
  downloadFile,
  canManageProject,
} from 'src/utils'

export const useOfferHeader = ({
  offerStatus,
  offerSent,
  offerId,
  openedOffers,
}) => {
  const [showOfferDeleteModal, setShowOfferDeleteModal] = useState(false)

  const { search } = useLocation()
  const { t } = useTranslation()
  const history = useHistory()

  const params = new URLSearchParams(search)

  const { quotationSigned, deliverySigned, quotationSent } = offerStatuses

  const stepsContainer = document.getElementById('project-steps')

  const { mutate: exportOfferMutation, isLoading: isOfferExporting } =
    useMutation(exportOffer, {
      onSuccess: (resp) => downloadFile(resp?.data),
      onError: (error) => handleNonValidationError(error),
    })

  const stepClickHandler = (stepName) => {
    stepsContainer?.scroll({
      left:
        stepName === 'products' || stepName === 'check'
          ? -stepsContainer?.offsetWidth
          : stepsContainer?.offsetWidth,
      behavior: 'smooth',
    })

    const index = openedOffers?.findIndex((offer) => offer?.id === offerId)

    params.set(`openedOffers[${index}].id`, offerId)
    params.set(`openedOffers[${index}].step`, stepName)

    history.replace(`?${params.toString()}`)
  }

  const detectOfferStatus = () => {
    return offerSent &&
      offerStatus !== quotationSigned &&
      offerStatus !== deliverySigned
      ? quotationSent
      : offerStatus
  }

  function downloadOffer() {
    if (!canManageProject()) return
    exportOfferMutation({ offerId })
  }

  return {
    setShowOfferDeleteModal,
    showOfferDeleteModal,
    detectOfferStatus,
    stepClickHandler,
    isOfferExporting,
    downloadOffer,
    t,
  }
}
