import OffersClientInformation from './OffersClientInformation'
import ProjectHeader from '../ProjectHeader/ProjectHeader'
import { useHistory, useLocation } from 'react-router-dom'
import { CustomModal } from 'src/common'

const ProjectDataModal = ({ project }) => {
  const history = useHistory()
  const location = useLocation()

  return (
    <CustomModal
      show={window.location.href.includes('projectDataModal')}
      className='project__data__modal__wrapper'
      isEmpty={true}
      close={() => {
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete('projectDataModal')

        history.push({
          pathname: location.pathname,
          search: searchParams.toString(),
        })
      }}
      wrapClassName='project__modal__wrap'
    >
      <div className='project__data__modal'>
        <ProjectHeader />

        <OffersClientInformation
          project={project}
          className='project__data__modal__container client-information '
        />
      </div>
    </CustomModal>
  )
}

export default ProjectDataModal
