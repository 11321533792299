import { useMutation, useQuery, useQueryClient } from 'react-query'
import { canManageProject } from 'src/utils'
import { useState, useRef } from 'react'
import {
  createAllInViewScenarioRequest,
  allInViewUrlDataRequest,
} from 'src/services'

const INTERVAL = 2000
const REFETCH_MAXIMUM_ATTEMPTS = 20

function useAllInViewRequest({ remoteId, offerId, canCreateAllInView }) {
  const [isAllInViewModalOpen, setIsAllInViewModalOpen] = useState(false)
  const [allInviewId, setAllInViewId] = useState(null)
  const refetchAttempts = useRef(0)

  const queryClient = useQueryClient()

  const {
    data: allInViewUrlData,
    isLoading: isAllInViewUrlLoading,
    error: allInViewUrlError,
    refetch,
  } = useQuery(
    ['allInViewUrl', allInviewId],
    () => allInViewUrlDataRequest(allInviewId),
    {
      enabled: !!allInviewId && !!isAllInViewModalOpen,
      onSuccess: () => {
        refetchAttempts.current = 0
      },
      refetchInterval: (_, meta) => {
        if (!isProcessing(meta?.state?.error)) {
          return false
        }
        refetchAttempts.current++
        return INTERVAL
      },
    }
  )

  const {
    mutate: createAllInViewScenarioMutation,
    isLoading: isCreateScenarioLoading,
    error: createScenarioError,
  } = useMutation(createAllInViewScenarioRequest, {
    onSuccess: (resp) => {
      setAllInViewId(resp?.data?.id)
      queryClient.invalidateQueries(['offer', offerId])
    },
  })

  function onAllInViewClick() {
    if (!canManageProject()) return
    setIsAllInViewModalOpen(true)

    if (remoteId) {
      setAllInViewId(offerId)
    } else {
      if (canCreateAllInView) {
        createAllInViewScenarioMutation(offerId)
      }
    }
  }

  function closeAllInViewModal() {
    setIsAllInViewModalOpen(false)
    clearAllInViewAddress()
  }

  function isProcessing(error) {
    return (
      error?.response?.status === 425 &&
      refetchAttempts.current <= REFETCH_MAXIMUM_ATTEMPTS
    )
  }

  function isAllInViewError() {
    if (isProcessing(allInViewUrlError)) return false
    return createScenarioError || allInViewUrlError
  }

  function isAllInViewLoading() {
    if (isProcessing(allInViewUrlError)) return true
    return isCreateScenarioLoading || isAllInViewUrlLoading
  }

  function clearAllInViewAddress() {
    setAllInViewId(null)
    refetchAttempts.current = 0
    queryClient.removeQueries(['allInViewUrl', allInviewId])
  }

  function reTryGetAllInViewUrl() {
    refetchAttempts.current = 0
    refetch()
  }

  return {
    allInViewIframeSrc: allInViewUrlData?.data?.url,
    isAllViewLoading: isAllInViewLoading(),
    allInViewError: isAllInViewError(),
    clearAllInViewAddress,
    isAllInViewModalOpen,
    reTryGetAllInViewUrl,
    closeAllInViewModal,
    onAllInViewClick,
  }
}

export { useAllInViewRequest }
