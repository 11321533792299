export const quotationTableColumns = (title = '', product = '', translate) => [
  {
    title,
    render: (_, record) => {
      return <div>{record?.[product]?.name}</div>
    },
    width: '50.00%',
  },
  {
    title: translate('quantity'),
    dataIndex: 'quantity',
    render: (_, record) => {
      return <div>{record?.quantity || record?.hours}</div>
    },
    width: '50.00%',
  },
]
