export const BurgerMenuIcon = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none'>
      <path
        d='M3.5 7H24.5M3.5 14H24.5M3.5 21H24.5'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
