import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGeocodeAddress, useQueryParams } from 'src/hooks'
import { Form, notification } from 'antd'
import { useMutation } from 'react-query'
import { createLeadRequest } from 'src/services'
import {
  appendPrefix,
  handleNonValidationError,
  handleValidationError,
} from 'src/utils'

const initialValues = {
  type: 1,
  company_name: '',
  name: '',
  email: '',
  phone_number: '',
  zipcode: '',
  house_number: '',
  annual_consumption: null,
  remarks: '',
  city: '',
  'g-recaptcha-response': '',
}

function useLeadIframe() {
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()

  const query = useQueryParams()
  const token = query.get('token')

  const [zipcode, setZipcode] = useState(initialValues.zipcode)
  const [houseNumber, setHouseNumber] = useState(initialValues.house_number)

  useGeocodeAddress({
    formValues: { zipcode, houseNumber },
    countries: null,
    form,
    locationName: 'city',
  })

  const [currentType, setCurrentType] = useState(initialValues.type)

  const [leadIframeLanguage, setLeadIframeLanguage] = useState(
    () => i18n.language
  )

  useEffect(() => {
    if (leadIframeLanguage !== 'nl') {
      changeLeadIframeLanguage('nl')
    }
  }, [])

  const {
    mutate: createLeadMutation,
    isLoading: isLeadCreating,
    error: createLeadError,
  } = useMutation(createLeadRequest, {
    onSuccess: () => {
      notification['success']({
        message: t('Success'),
        description: t('lead_created_successfully'),
        placement: 'top',
        duration: 2,
      })
      form.resetFields()
      setCurrentType(initialValues.type)
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function changeLeadIframeLanguage(lang) {
    i18n.changeLanguage(lang)
    setLeadIframeLanguage(lang)
  }

  const isCompanyFieldDisabled = useMemo(() => {
    return currentType === initialValues.type
  }, [currentType])

  function handleTypeChange(val) {
    setCurrentType(val.target.value)

    if (val.target.value === initialValues.type) {
      form.setFieldsValue({
        company_name: '',
      })
    }
  }

  function handleZipcodeChange(val) {
    setZipcode(val.target.value)
  }

  function handleHouseNumberChange(val) {
    setHouseNumber(val.target.value)
  }

  function createLead(val) {
    return createLeadMutation({
      data: { ...val, phone_number: appendPrefix(val?.phone_number) },
      token,
    })
  }

  function handleCaptchaChange(val) {
    form.setFieldsValue({
      'g-recaptcha-response': val,
    })
  }

  return {
    createLeadError: handleValidationError(createLeadError),
    changeLeadIframeLanguage,
    handleHouseNumberChange,
    isCompanyFieldDisabled,
    handleZipcodeChange,
    handleCaptchaChange,
    leadIframeLanguage,
    handleTypeChange,
    isLeadCreating,
    initialValues,
    createLead,
    form,
    t,
  }
}

export { useLeadIframe }
