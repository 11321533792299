import { ProjectCountDetails } from '../ProjectCountDetails'
import { AllProjectsTable } from './components'
import { TicketView } from '../TicketView'

const AllProjects = ({
  handleTableChange,
  isProjectLoading,
  handlePageChange,
  paginationInfo,
  archiveProject,
  deleteProject,
  setTicketView,
  ticketView,
  project,
  onRow,
}) => {
  return (
    <>
      <ProjectCountDetails
        totalProjectsCount={paginationInfo?.total}
        currentProjectsCount={project?.length}
        setTicketView={setTicketView}
        ticketView={ticketView}
      />

      {ticketView ? (
        <TicketView
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
          archiveProject={archiveProject}
          deleteProject={deleteProject}
          projects={project}
        />
      ) : (
        <AllProjectsTable
          className='tablet__table__visibility'
          handleTableChange={handleTableChange}
          isProjectLoading={isProjectLoading}
          handlePageChange={handlePageChange}
          archiveProject={archiveProject}
          paginationInfo={paginationInfo}
          deleteProject={deleteProject}
          project={project}
          onRow={onRow}
        />
      )}

      <AllProjectsTable
        handleTableChange={handleTableChange}
        isProjectLoading={isProjectLoading}
        handlePageChange={handlePageChange}
        archiveProject={archiveProject}
        paginationInfo={paginationInfo}
        deleteProject={deleteProject}
        project={project}
        onRow={onRow}
      />
    </>
  )
}

export default AllProjects
