import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useFetchCountries } from 'src/hooks'
import { useParams } from 'react-router'
import { useState } from 'react'
import {
  deleteInspectionImageRequest,
  updateInspectionRequest,
  deleteInspectionRequest,
  createInspectionRequest,
} from 'src/services'
import {
  handleNonValidationError,
  appendValuesToFormData,
  handleValidationError,
  parseDateString,
  formatToYMD,
} from 'src/utils'

function useInspection(projectInspection) {
  const [isAddInspectionModalOpen, setAddInspectionModalOpen] = useState(false)
  const [wizardPage, setWizardPage] = useState(0)
  const [inspection, setInspection] = useState(null)
  const [isInspectionFileSizeError, setIsInspectionFileSizeError] =
    useState(false)

  const [
    isInspectionDeleteConfirmationOpen,
    setInspectionDeleteConfirmationOpen,
  ] = useState(false)

  const [uploadPictureErrors, setUploadPictureErrors] = useState({
    convertersAndDC_pictures: null,
    accessibility_picture: null,
    fusebox_pictures: null,
  })

  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const { projectId } = useParams()

  const { countriesData } = useFetchCountries()

  const {
    mutate: updateInspection,
    isLoading: updatingInspection,
    error: updateInspectionError,
  } = useMutation(
    ({ projectId, data }) => updateInspectionRequest(projectId, data),
    {
      onSuccess: (resp) => {
        if (wizardPage === 8) {
          closeAddInspectionModal()
        } else {
          setWizardPage((prev) => prev + 1)
        }

        setInspection(resp?.data?.item)

        queryClient.invalidateQueries(['projects', projectId])
      },
      onError: (error) => {
        if (error.message?.includes('Network Error')) {
          setIsInspectionFileSizeError(true)
          return
        }
        handleNonValidationError(error)
      },
    }
  )

  const { mutate: deleteInspectionMutation, isLoading: inspectionDeleting } =
    useMutation(deleteInspectionRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects', projectId])
        closeInspectionDeleteConfirmation()
        closeAddInspectionModal()
      },
      onError: (error) => handleNonValidationError(error),
    })

  const {
    mutate: deleteInspectionImageMutation,
    isLoading: isInspectionPictureDeleting,
  } = useMutation(({ id }) => deleteInspectionImageRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects', projectId])
    },
    onError: (error) => handleNonValidationError(error),
  })

  const { mutate: createInspectionMutation } = useMutation(
    ({ projectId }) => createInspectionRequest(projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects', projectId])
      },
      onError: (error) => handleNonValidationError(error),
    }
  )

  function openAddInspectionModal() {
    setIsInspectionFileSizeError(false)
    setAddInspectionModalOpen(true)

    if (!projectInspection) {
      createInspection()
    } else {
      const { inspection_date } = projectInspection || {}
      const date = inspection_date && parseDateString(inspection_date)

      setInspection({
        ...projectInspection,
        inspection_date: date,
      })
    }
  }

  function createInspection() {
    createInspectionMutation({ projectId })
  }

  const clearInspectionUploadErrors = () => {
    setUploadPictureErrors({
      convertersAndDC_pictures: null,
      accessibility_picture: null,
      fusebox_pictures: null,
    })
  }

  function closeAddInspectionModal() {
    setAddInspectionModalOpen(false)
    setWizardPage(0)
    setInspection(null)
    clearInspectionUploadErrors()
  }

  function handlePreviousStepClick() {
    setIsInspectionFileSizeError(false)
    clearInspectionUploadErrors()
    if (wizardPage > 0) {
      setWizardPage((prev) => prev - 1)
    }
  }

  function getValues(values) {
    let data = {
      step: wizardPage + 1,
    }

    if (wizardPage === 0) {
      const { inspection_date, ...otherData } = values || {}
      const date = inspection_date && formatToYMD(inspection_date)
      const stepValues = { ...data, inspection_date: date, ...otherData }

      return stepValues
    } else {
      return { ...data, ...values }
    }
  }

  function updateInspectionValuesToFormDataHelper(values, fileField) {
    let data
    data = appendValuesToFormData(values)
    if (fileField?.length) {
      fileField?.forEach((file) => {
        if (file?.originFileObj) {
          data.append('files[]', file?.originFileObj)
        }
      })
    }
    return data
  }

  function updateInspectionValuesToFormData(values) {
    let formDataValues

    if (wizardPage === 0) {
      const { roofsurface_pictures, ...rest } = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        roofsurface_pictures
      )
    }

    if (wizardPage === 1) {
      const { accessibility_picture, reached, ...rest } = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        accessibility_picture
      )
      if (reached?.length) {
        for (let value of reached) {
          if (value > 7) {
            formDataValues.append('reached[]', value)
          }
        }
      }
    }

    if (wizardPage === 2) {
      const { convertersAndDC_pictures, roofoutlet, ...rest } =
        getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        convertersAndDC_pictures
      )
      if (roofoutlet?.length) {
        for (let value of roofoutlet) {
          if (value) {
            formDataValues.append('roofoutlet[]', value)
          }
        }
      }
    }

    if (wizardPage === 3) {
      const { acTraject_pictures, ac_route_run, ...rest } = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        acTraject_pictures
      )
      if (ac_route_run?.length) {
        for (let value of ac_route_run) {
          if (value) {
            formDataValues.append('ac_route_run[]', value)
          }
        }
      }
    }
    if (wizardPage === 4) {
      const data = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(data)
    }
    if (wizardPage === 5) {
      const { fusebox_pictures, ...rest } = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        fusebox_pictures
      )
    }
    if (wizardPage === 6) {
      const { crawlSpace_pictures, condition_of_crawl_space, ...rest } =
        getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        crawlSpace_pictures
      )
      if (condition_of_crawl_space?.length) {
        for (let value of condition_of_crawl_space) {
          if (value) {
            formDataValues.append('condition_of_crawl_space[]', value)
          }
        }
      }
    }

    if (wizardPage === 7) {
      const {
        complexity_pictures,

        ...rest
      } = getValues(values)
      formDataValues = updateInspectionValuesToFormDataHelper(
        rest,
        complexity_pictures
      )
    }

    if (wizardPage === 8) {
      const { reach_type, ...rest } = getValues(values)

      formDataValues = updateInspectionValuesToFormDataHelper(rest)

      if (reach_type?.length) {
        for (let value of reach_type) {
          if (typeof value === 'number') {
            formDataValues.append('reach_type[]', value)
          }
        }
      }
    }

    return formDataValues
  }

  const checkUploadFileExistence = (
    uploadedImageFile,
    fieldName,
    targetPage
  ) => {
    let isFileError = false

    if (wizardPage === targetPage) {
      const pictureExists = projectInspection?.files?.find(
        (el) => el.step === targetPage + 1
      )

      setUploadPictureErrors((prev) => ({
        ...prev,
        [fieldName]:
          !!pictureExists || !!uploadedImageFile?.length
            ? null
            : t('pictures_field_is_required'),
      }))

      if (!pictureExists && !uploadedImageFile?.length) {
        isFileError = true
      } else {
        isFileError = false
        clearInspectionUploadErrors()
      }
    }

    return isFileError
  }

  const onNextClick = (values) => {
    setIsInspectionFileSizeError(false)
    const data = updateInspectionValuesToFormData(values)

    const accessibilityFileError = checkUploadFileExistence(
      values?.accessibility_picture,
      'accessibility_picture',
      1
    )

    const converterAndDcFileError = checkUploadFileExistence(
      values?.convertersAndDC_pictures,
      'convertersAndDC_pictures',
      2
    )

    const fuseboxFileError = checkUploadFileExistence(
      values?.fusebox_pictures,
      'fusebox_pictures',
      5
    )

    if (accessibilityFileError || converterAndDcFileError || fuseboxFileError) {
      return
    } else {
      updateInspection({ data, projectId })
    }
  }

  const openInspectionDeleteConfirmation = () => {
    setInspectionDeleteConfirmationOpen(true)
  }

  const closeInspectionDeleteConfirmation = () => {
    setInspectionDeleteConfirmationOpen(false)
  }

  const deleteInspection = () => {
    deleteInspectionMutation(projectId)
  }

  const getStepFiles = (stepId) => {
    if (!inspection?.files) {
      return null
    }

    const stepFiles = []

    inspection.files?.forEach((file) => {
      if (file?.step === stepId) {
        const stetFile = {
          uid: file?.id,
          name: file?.file_src,
          status: 'done',
          url: `${process.env.REACT_APP_BACKEND_URI}/storage/companies/${file?.file_src}`,
        }
        return stepFiles.push(stetFile)
      }
    })

    return stepFiles
  }

  const deleteInspectionImage = (data) => {
    if (!data?.originFileObj) {
      setUploadPictureErrors({
        convertersAndDC_pictures: true,
        accessibility_picture: true,
        roofsurface_pictures: true,
        fusebox_pictures: true,
      })

      deleteInspectionImageMutation({ id: data?.uid })
    }
  }

  return {
    addInspectionError: handleValidationError(updateInspectionError) || {
      isInspectionFileSizeError,
    },
    isInspectionDeleteConfirmationOpen,
    closeInspectionDeleteConfirmation,
    openInspectionDeleteConfirmation,
    isInspectionPictureDeleting,
    isAddInspectionModalOpen,
    handlePreviousStepClick,
    closeAddInspectionModal,
    openAddInspectionModal,
    setUploadPictureErrors,
    deleteInspectionImage,
    uploadPictureErrors,
    inspectionDeleting,
    updatingInspection,
    deleteInspection,
    countriesData,
    getStepFiles,
    onNextClick,
    inspection,
    wizardPage,
    t,
  }
}

export { useInspection }
