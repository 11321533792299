export function downloadFile(
  url,
  additionalOPtions = {
    newTab: false,
  }
) {
  try {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (additionalOPtions.newTab) {
      tempLink.target = '_blank'
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  } catch (error) {
    console.log(error)
  }
}
