import { Link, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEnabledFeatures } from 'src/hooks'
import { Header } from 'src/common'
import { isSales } from 'src/utils'

const ReportingScreen = () => {
  const { isReportingEnabled } = useEnabledFeatures()
  const { t } = useTranslation()

  if (!isReportingEnabled) {
    return <Redirect to='/' />
  }

  return (
    <div className='reporting'>
      <Header />
      <div className='reporting__title'>{t('reporting')}</div>
      <div className='reporting__content'>
        {!isSales() && (
          <Link className='reporting__card' to='reporting/financial'>
            <div>
              <div className='reporting__cardtext'>
                {t('financial_reporting')}
              </div>
            </div>
          </Link>
        )}

        <Link className='reporting__card' to='reporting/material'>
          <div>
            <div className='reporting__cardtext'>{t('material_reporting')}</div>
          </div>
        </Link>

        <Link className='reporting__card' to='reporting/projects_overview'>
          <div>
            <div className='reporting__cardtext'>
              {t('sales_projects_overview')}
            </div>
          </div>
        </Link>

        <Link className='reporting__card' to='reporting/general-reporting'>
          <div>
            <div className='reporting__cardtext'>{t('general_reporting')}</div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ReportingScreen
