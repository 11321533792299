import i18next from 'i18next'

export const deliveryTableColumns = (title = '', product = '') => [
  {
    title,
    render: (_, record) => {
      return <div>{record?.[product]?.name}</div>
    },
    width: '50.00%',
  },
  {
    title: `${i18next.t('quantity')}`,
    dataIndex: 'quantity',
    render: (_, record) => {
      return <div>{record?.quantity || record?.hours}</div>
    },
    width: '50.50%',
  },
]
