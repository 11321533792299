import { getCountryId } from 'src/utils'
import axios from 'axios'
import { useEffect, useRef } from 'react'

function getAddressRequest(zipcode, houseNumber) {
  const outFields = `outFields=StName,CountryCode,City,Postal`
  const supportedCountryCodes = 'nld,bel,lux,deu,de'
  return axios.get(
    `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?address=${houseNumber}&countryCode=${supportedCountryCodes}&postal=${zipcode}&${outFields}&forStorage=false&f=json`
  )
}

export const useGeocodeAddress = ({
  form,
  countries,
  locationName = 'location',
  calculateOnInitialRender = true,
}) => {
  const zipcode = form.getFieldValue('zipcode')
  const houseNumber = form.getFieldValue('house_number')

  const addressRef = useRef(calculateOnInitialRender)

  const addressData = () => {
    getAddressRequest(zipcode, houseNumber)
      .then((resp) => {
        const respPath =
          resp?.data?.candidates?.[0] && resp?.data?.candidates?.[0]?.attributes

        const country = getCountryId(respPath?.CountryCode, countries)
        form.setFieldsValue({
          [locationName]: respPath?.City || '',
          street: respPath?.StName || '',
          country_id: !zipcode && !houseNumber ? 649 : country?.id,
        })
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (!addressRef.current) {
      addressRef.current = true
    } else {
      if (zipcode || houseNumber) {
        const timeoutId = setTimeout(addressData, 800)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [zipcode, houseNumber])
}
