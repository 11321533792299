import { usePageQueryParams, useUsers } from 'src/hooks'
import { handleNonValidationError } from 'src/utils'
import { useQuery, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { downloadFile } from 'src/utils'
import { useState } from 'react'
import {
  salesOverviewReportingRequest,
  getSalesRequest,
  exportRequest,
} from 'src/services'

export const useProjectsOverview = () => {
  const { t } = useTranslation()
  const { user } = useUsers()
  const [managerId, setManagerId] = useState('')
  const [projectDates, setProjectDates] = useState({
    from: '',
    till: '',
  })

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const {
    data: salesReportData,
    isLoading: isSalesReportDataLoading,
    isPreviousData,
  } = useQuery(
    [
      'sales_report',
      page,
      size,
      managerId,
      projectDates.from,
      projectDates.till,
    ],
    () => {
      const { from, till } = projectDates

      return salesOverviewReportingRequest({
        managerId,
        page,
        size,
        from,
        till,
      })
    },
    {
      keepPreviousData: true,
    }
  )

  const { data: managersData } = useQuery(
    ['users', 'managers', user?.company_id],
    () => getSalesRequest({ trashed: 1 })
  )

  const { mutate: exportMutation, isLoading: isExportLoading } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
      onError: (err) => {
        handleNonValidationError(err)
      },
    }
  )

  function exportReporting() {
    const formData = new FormData()
    if (managerId) {
      formData.append('manager_ids[]', managerId)
    }

    if (projectDates.from && projectDates.till) {
      formData.append('from', projectDates.from)
      formData.append('till', projectDates.till)
    }

    formData.append('model', 'sale_report')
    exportMutation(formData)
  }

  function onManagerSelect(val) {
    deleteQueryParams('page')
    setManagerId(val)
  }

  function handleDateChange(value) {
    if (!value) return
    deleteQueryParams('page')
    setProjectDates({
      from: value?.[0],
      till: value?.[1],
    })
  }

  return {
    paginationInfo: {
      total: salesReportData?.data?.meta?.total,
      page,
    },
    handlePageChange,
    handleDateChange,
    onManagerSelect,
    accountManagers: managersData?.data?.data,
    projects: salesReportData?.data?.data,
    isProjectLoading: isSalesReportDataLoading,
    exportReporting,
    isExportLoading: isExportLoading || isPreviousData,
    t,
  }
}
