import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function usePlanningTool(form) {
  const { t } = useTranslation()

  const [hours, setHours] = useState(() => {
    const initialHours = form.getFieldValue('working_hours')
    if (!initialHours) return undefined

    return JSON.parse(form.getFieldValue('working_hours'))
  })

  useEffect(() => {
    form.setFieldsValue({
      working_hours: JSON.stringify(hours),
    })
  }, [hours])

  function setInitialData(day) {
    if (!day) return undefined
    const start = day?.start.split(':')
    const end = day?.end.split(':')
    return [
      new Date().setHours(Number(start[0]), Number(start[1])),
      new Date().setHours(Number(end[0]), Number(end[1])),
    ]
  }

  return { setInitialData, setHours, hours, t }
}

export { usePlanningTool }
