import axios from '../../axios'

export const loginRequest = async (data) => {
  return await axios.post('api/login', data)
}

export const loginAsTenantRequest = async (id) => {
  return await axios.post('api/login_tenant', { company_id: id })
}

export const addUserRequest = async (data) => {
  return await axios.post('api/register', data)
}

export const forgotPasswordRequest = async (data) => {
  return await axios.post('api/req-password-reset', data)
}

export const resetPasswordRequest = async (data) => {
  return await axios.post('api/update-password', data)
}

export const logoutRequest = async () => {
  return await axios.post('api/logout')
}
