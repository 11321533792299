import { companyTabErrors } from '../Manage/screens/Companies/components/CompanyModal/tabError/tabError'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useFileUpload, useEnabledFeatures } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setUpdateUser } from 'src/actions'
import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import {
  updateCompanyRequest,
  getCompanyRequest,
  addCompanyRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  getCompanyPdfFileUrl,
  getQueryParam,
  isSuperUser,
  isAdmin,
} from 'src/utils'

const useCompany = () => {
  const [privacyStatementSrc, setPrivacyStatementSrc] = useState(null)

  const [termsOfConditionSrc, setTermsOfConditionSrc] = useState(null)

  const [privacyStatementFile, setPrivacyStatementFile] = useState({})
  const [termsOfConditionFile, setTermsOfConditionFile] = useState({})
  const [logoFile, setLogoFile] = useState({})

  const { isNewLeadsEnabled } = useEnabledFeatures()

  const [activeTab, setActiveTab] = useState(
    getQueryParam('activeTab') || 'setup'
  )

  const [companyTabError, setCompanyTabError] = useState(false)

  const companyId = getQueryParam('id')

  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useSelector((state) => state.globalSlice)

  const canViewCompanyPage = () => {
    if (companyId) {
      return (isAdmin() && user?.company_id === +companyId) || isSuperUser()
    } else {
      return isSuperUser()
    }
  }

  const { data: companyData, isLoading: isCompanyDataLoading } = useQuery(
    ['companies', companyId],
    () => getCompanyRequest(companyId),

    {
      enabled: canViewCompanyPage(),
    }
  )

  useEffect(() => {
    setPrivacyStatementSrc(
      getCompanyPdfFileUrl(companyData?.data?.data, 'privacy_statement')
    )

    setTermsOfConditionSrc(
      getCompanyPdfFileUrl(companyData?.data?.data, 'terms_of_conditions')
    )
  }, [companyData?.data?.data])

  useEffect(() => {
    const activeTab = getQueryParam('activeTab')

    if (!!activeTab) {
      setActiveTab(activeTab)
    }
  }, [window.location.search])

  const {
    mutate: updateCompanyMutation,
    isLoading: updating,
    error: updateErrors,
  } = useMutation(
    ({ id, data }) => !updating && updateCompanyRequest(id, data),
    {
      onSuccess: (response, variables) => {
        setCompanyTabError(false)
        queryClient.refetchQueries('companies')
        message.success(t('updated_successfully'))

        if (user?.company_id === variables?.id) {
          const { id, logo } = response?.data?.data

          const tempUser = {
            ...user,
            company: {
              id,
              logo,
            },
          }

          dispatch(setUpdateUser(tempUser))
          setPrivacyStatementFile({})
          setTermsOfConditionFile({})
          setLogoFile({})
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
        setCompanyTabError(companyTabErrors(handleValidationError(error)))
      },
    }
  )

  const {
    isLoading: creating,
    mutate: createCompany,
    error: createError,
  } = useMutation((data) => !creating && addCompanyRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('companies')
      message.success(t('company_added_successfully'))
      history.push('/manage/companies')
    },

    onError: (error) => {
      handleNonValidationError(error)
      setCompanyTabError(companyTabErrors(handleValidationError(error)))
    },
  })

  function getErrorState() {
    const formError =
      handleValidationError(createError) || handleValidationError(updateErrors)
    const fileError = handleValidationError(uploadError)

    return {
      ...formError,
      logo: fileError?.logo,
      privacy_statement: fileError?.privacy_statement,
      terms_of_conditions: fileError?.terms_of_conditions,
    }
  }

  const { uploadFile, uploadError, isUploading } = useFileUpload()

  async function uploadCompanyFile(fileInfo, url, fieldName) {
    if (fileInfo?.id) {
      return fileInfo?.id
    }

    if (fileInfo?.fileList?.length > 0) {
      try {
        let response = await uploadFile({
          url: `company/${url}`,
          data: {
            file: fileInfo?.file,
          },
        })

        return response?.data?.id
      } catch (error) {
        form.setFields([
          {
            name: fieldName,
            errors: [error?.response?.data?.errors?.file?.[0]],
          },
        ])

        return error
      }
    }

    return null
  }

  const getValidFile = (uploadedFile, currentCompanyFile) => {
    if (uploadedFile?.fileList?.length > 0) return uploadedFile

    return currentCompanyFile
  }

  const submitHandler = async (values) => {
    try {
      const {
        country,
        logo,
        created_at,
        updated_at,
        privacy_statement,
        terms_of_conditions,
        logo_src,
        ...rest
      } = values || {}

      const [companyId, privacyStatementId, termsOfConditionsId] =
        await Promise.all([
          uploadCompanyFile(getValidFile(logoFile, logo), 'logo', 'logo'),

          uploadCompanyFile(
            getValidFile(privacyStatementFile, privacy_statement),
            'privacy-statement',
            'privacy_statement'
          ),
          uploadCompanyFile(
            getValidFile(termsOfConditionFile, terms_of_conditions),
            'terms-of-conditions',
            'terms_of_conditions'
          ),
        ])

      rest['company_logo_id'] = companyId

      rest['privacy_statement_id'] = privacyStatementId

      rest['terms_of_conditions_id'] = termsOfConditionsId

      const id = companyData?.data?.data?.id

      if (id) {
        updateCompanyMutation({ id, data: rest })
      } else {
        createCompany(rest)
      }
    } catch (error) {
      return
    }
  }

  const getLoadingState = () => updating || creating || isUploading

  return {
    disableSubmitButton: getLoadingState(),
    companyData: companyData?.data?.data,
    updateErrors: getErrorState(),
    setPrivacyStatementFile,
    setTermsOfConditionFile,
    setPrivacyStatementSrc,
    setTermsOfConditionSrc,
    privacyStatementFile,
    termsOfConditionFile,
    isCompanyDataLoading,
    termsOfConditionSrc,
    privacyStatementSrc,
    canViewCompanyPage,
    isNewLeadsEnabled,
    companyTabError,
    submitHandler,
    setActiveTab,
    setLogoFile,
    activeTab,
    companyId,
    logoFile,
    updating,
    form,
    t,
  }
}

export default useCompany
