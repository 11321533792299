import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { statuses } from 'src/common'
import { categories } from 'src/utils'

function useProductDetailsInfo(project) {
  const { t } = useTranslation()

  const status = useMemo(
    () => statuses.find((status) => status?.id === project?.status_id),
    [project?.status_id]
  )
  const category = useMemo(
    () => categories.find((category) => category?.id === project?.category_id),
    [project?.category_id]
  )

  return { category, status, t }
}

export { useProductDetailsInfo }
