import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

const ActionButtonGroup = ({ disableSubmit }) => {
  const { t } = useTranslation()

  return (
    <div className='action__button__group'>
      <Link to='/sales-projects'>
        <Button className='cancel__btn' type='secondary'>
          {t('cancel')}
        </Button>
      </Link>

      <Button disabled={disableSubmit} htmlType='submit' type='primary'>
        {t('save')}
      </Button>
    </div>
  )
}

export default ActionButtonGroup
