import { useQuery } from 'react-query'
import { usePageQueryParams } from 'src/hooks'
import { allOffersRequest } from 'src/services'

function useGetOrders(select) {
  const { page, size, handlePageChange } = usePageQueryParams(
    'order-page',
    'order-size'
  )

  const {
    data: ordersInfo,
    isLoading,
    isPreviousData,
  } = useQuery(['orders', { page, size, isConfirmed: 1 }], {
    queryFn: allOffersRequest,
    select: selectOrderData,
    keepPreviousData: true,
  })

  function selectOrderData(response) {
    if (typeof select === 'function') {
      return select(response)
    } else {
      return response?.data
    }
  }

  return {
    isOrdersLoading: isLoading || isPreviousData,
    paginationInfo: {
      total: ordersInfo?.meta?.total,
      page,
    },
    orders: ordersInfo?.data,
    handlePageChange,
  }
}

export { useGetOrders }
