import { allMaterialsRequest, allActivitiesRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { OPTIONS_TYPE } from 'src/common'
import { useInfiniteQuery } from 'react-query'
import { useMemo } from 'react'
import { flatPaginatedData } from 'src/utils'

function groupByKey(array, key) {
  if (!array?.length) return
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    })
  }, {})
}

export function useOptionsWizard({ attachedOptions = [], projectCompanyId }) {
  const { t } = useTranslation()

  const {
    data: materials,
    fetchNextPage: fetchMaterialsNextPage,
    hasNextPage: hasMaterialsNextPage,
  } = useInfiniteQuery(
    ['materials', projectCompanyId],
    ({ pageParam = 1 }) =>
      allMaterialsRequest({
        page: pageParam,
        companyId: projectCompanyId,
        size: 20,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(projectCompanyId),
    }
  )

  const {
    data: activities,
    fetchNextPage: fetchActivitiesNextPage,
    hasNextPage: hasActivitiesNextPage,
  } = useInfiniteQuery(
    ['activities', projectCompanyId],
    ({ pageParam = 1 }) =>
      allActivitiesRequest({
        page: pageParam,
        companyId: projectCompanyId,
        size: 20,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(projectCompanyId),
    }
  )

  const groupedMaterials = useMemo(() => {
    return groupByKey(flatPaginatedData(materials), 'type')
  }, [materials])

  const groupedActivities = useMemo(() => {
    return groupByKey(flatPaginatedData(activities), 'type_id')
  }, [activities])

  const attaChedActivities = useMemo(() => {
    return attachedOptions.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.activity
    )
  }, [attachedOptions])

  const attaChedMaterials = useMemo(() => {
    return attachedOptions.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.material
    )
  }, [attachedOptions])

  function fetchOptions() {
    if (!hasActivitiesNextPage && !hasMaterialsNextPage) return

    if (hasActivitiesNextPage) {
      fetchActivitiesNextPage()
    }
    if (hasMaterialsNextPage) {
      fetchMaterialsNextPage()
    }
  }

  return {
    isActivitiesOrMaterials: hasMaterialsNextPage || hasActivitiesNextPage,
    attaChedActivities,
    attaChedMaterials,
    groupedActivities,
    groupedMaterials,
    fetchOptions,
    t,
  }
}
