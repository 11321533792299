export const BLOCK_TYPES = [
  { label: 'Header', style: 'header-one', displayStyle: {} },
]

export const INLINE_STYLES = [
  { label: 'B', style: 'BOLD', displayStyle: { fontWeight: 'bold' } },
  { label: 'I', style: 'ITALIC', displayStyle: { fontStyle: 'italic' } },
  {
    label: 'U',
    style: 'UNDERLINE',
    displayStyle: { textDecoration: 'underline' },
  },
]
