function DetailsAndActivitySwitcher({
  isDetails,
  isActivity,
  handleCondenseView,
}) {
  return (
    <div className='details_and_activity_switcher'>
      <div className='btns_container'>
        <button
          className={`details_btn ${isDetails ? 'active' : ''}`}
          onClick={() => {
            if (!isDetails) {
              handleCondenseView()
            }
          }}
        >
          Details
        </button>
        <button
          className={`activity_btn ${isActivity ? 'active' : ''}`}
          onClick={() => {
            if (!isActivity) {
              handleCondenseView()
            }
          }}
        >
          Activity
        </button>
      </div>
    </div>
  )
}

export default DetailsAndActivitySwitcher
