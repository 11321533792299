import GeneralTableColumns from './GeneralTableColumns'
import { useGeneralConditions } from './useConditions'
import { Table } from 'antd'
import {
  ShowGeneralConditionModal,
  AddGeneralConditionModal,
} from './components'
import {
  AgreeDeleteModal,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Search,
  Header,
} from 'src/common'

const GeneralConditions = () => {
  const {
    isGeneralConditionsDataLoading,
    setShowGeneralConditionModal,
    setAddGeneralConditionModal,
    isGeneralConditionDeleting,
    showGeneralConditionModal,
    setDeleteGeneralCondition,
    addGeneralConditionModal,
    deleteGeneralCondition,
    setShowImportCsvModal,
    showImportCsvModal,
    generalConditions,
    handlePageChange,
    importConditions,
    exportConditions,
    deleteCondition,
    exportDisabled,
    paginationInfo,
    setImportError,
    onSearchClick,
    importError,
    importing,
    onRow,
    t,
  } = useGeneralConditions()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>
        {t('general_conditions')}
      </div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isGeneralConditionsDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            onClick={() => setAddGeneralConditionModal(true)}
            text={t('add')}
          />
        </div>
        <Table
          bordered
          scroll={{ x: '992px' }}
          columns={GeneralTableColumns(setDeleteGeneralCondition, t)}
          dataSource={generalConditions || []}
          rowKey={(record) => record.id}
          onRow={onRow}
          loading={isGeneralConditionsDataLoading}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
        />
        <div className='contentFooter'>
          <CsvButton
            onClick={exportConditions}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            onClick={() => setShowImportCsvModal(true)}
            text={t('import_cvs')}
          />
        </div>
      </div>

      {showImportCsvModal ? (
        <ImportCvsModal
          closeModal={() => setShowImportCsvModal(false)}
          setImportError={setImportError}
          submitModal={importConditions}
          importError={importError}
          show={showImportCsvModal}
          disabled={importing}
        />
      ) : null}

      {addGeneralConditionModal && (
        <AddGeneralConditionModal
          setAddGeneralConditionModal={setAddGeneralConditionModal}
          show={addGeneralConditionModal}
        />
      )}

      {showGeneralConditionModal.show && (
        <ShowGeneralConditionModal
          setShowGeneralConditionModal={setShowGeneralConditionModal}
          currentGeneralCondition={showGeneralConditionModal.data}
          show={showGeneralConditionModal.show}
        />
      )}

      {deleteGeneralCondition.show && (
        <AgreeDeleteModal
          visible={deleteGeneralCondition.show}
          disabled={isGeneralConditionDeleting}
          targetName={deleteGeneralCondition?.data?.name}
          onCancel={() => setDeleteGeneralCondition({ show: false, data: {} })}
          onSubmit={() =>
            deleteGeneralCondition.data.id &&
            deleteCondition(deleteGeneralCondition.data.id)
          }
        />
      )}
    </div>
  )
}

export default GeneralConditions
