export const getHomeTablesSortName = (field) => {
  let temp = ''

  if (field?.includes('contacts')) {
    temp = 'name'
  } else if (field?.includes('manager')) {
    temp = 'manager_name'
  } else if (field?.includes('executor')) {
    temp = 'executor_name'
  } else if (field?.includes('type')) {
    temp = 'type'
  } else if (field?.includes('status_id')) {
    temp = 'status'
  } else if (field?.includes('updated_at')) {
    temp = 'updated_at'
  }

  return temp
}
