import { useTranslation } from 'react-i18next'
import { useRef, useEffect } from 'react'
import SignaturePad from 'signature_pad'

export const useSignatureModal = (close, setImageUrl, saveHandler) => {
  const signaturePadRef = useRef(null)
  const canvasRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return
    if (!signaturePadRef.current) {
      signaturePadRef.current = new SignaturePad(canvas, {
        maxWidth: 0.7,
      })
      resizeCanvas()
    }
    return clearSignature()
  })

  const resizeCanvas = () => {
    const canvas = canvasRef.current
    if (!canvas) return
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    if (canvas.offsetWidth && canvas.offsetHeight) {
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext('2d').scale(ratio, ratio)
      clearSignature()
    }
  }

  window.addEventListener('resize', resizeCanvas)

  const clearSignature = () => {
    return signaturePadRef.current?.clear()
  }

  const saveSignature = () => {
    if (signaturePadRef.current?.isEmpty()) {
      setImageUrl('')
    } else {
      setImageUrl(signaturePadRef.current?.toDataURL())
    }
    saveHandler()
    close()
  }

  return {
    clearSignature,
    saveSignature,
    canvasRef,
    t,
  }
}
