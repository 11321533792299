import { BiTrash } from 'react-icons/bi'
import { Button } from 'antd'

const ButtonWithTrash = ({ text, className = '', ...props }) => {
  return (
    <Button type='danger' {...props} className={`alignButtonIcon ${className}`}>
      <BiTrash />
      {text}
    </Button>
  )
}
export default ButtonWithTrash
