export const MyCompanyIcon = () => {
  return (
    <svg width='61' height='60' viewBox='0 0 61 60' fill='none'>
      <g id='Isolation_Mode' clipPath='url(#clip0_6552_6100)'>
        <g id='1908, Business, Businessman, Conference, Discussion, Meeting'>
          <g id='Group'>
            <path
              id='Vector'
              d='M40.4601 27.8208C39.7501 27.8208 39.1401 27.2108 39.1401 26.5108V22.6708C39.1401 20.6508 38.2301 18.8308 36.6101 17.4108C34.8901 15.8908 32.6701 15.0808 30.3401 15.0808C25.4801 15.0808 21.4401 18.5208 21.4401 22.6708C21.4401 23.3808 20.8301 23.9808 20.1301 23.9808C19.4301 23.9808 18.8101 23.3708 18.8101 22.6708C18.8101 17.1108 23.8701 12.5508 30.2401 12.5508C33.2701 12.5508 36.1101 13.5608 38.2301 15.4808C40.4601 17.4008 41.6701 19.9308 41.6701 22.6608V26.5008C41.7701 27.3108 41.1601 27.8108 40.4601 27.8108V27.8208Z'
              fill='#469BE0'
            />
            <path
              id='Vector_2'
              d='M30.35 59.4798C29.64 59.4798 29.04 58.8698 29.04 58.1698V31.6598C29.04 30.9498 29.65 30.3398 30.35 30.3398C31.05 30.3398 31.66 30.9498 31.66 31.6598V58.2598C31.66 58.8698 31.05 59.4698 30.35 59.4698V59.4798Z'
              fill='#60534A'
            />
            <path
              id='Vector_3'
              d='M54.4201 8.90039C57.2101 8.90039 59.4801 11.1604 59.4801 13.9604C59.4801 16.7604 57.2201 19.0204 54.4201 19.0204C51.6201 19.0204 49.3601 16.7604 49.3601 13.9604C49.3601 11.1604 51.6201 8.90039 54.4201 8.90039Z'
              fill='#FFD8A8'
            />
            <path
              id='Vector_4'
              d='M30.35 0C33.14 0 35.41 2.26 35.41 5.06C35.41 7.86 33.15 10.12 30.35 10.12C27.55 10.12 25.29 7.86 25.29 5.06C25.29 2.26 27.55 0 30.35 0Z'
              fill='#FFD8A8'
            />
            <path
              id='Vector_5'
              d='M6.37006 19.0204C9.16462 19.0204 11.4301 16.755 11.4301 13.9604C11.4301 11.1658 9.16462 8.90039 6.37006 8.90039C3.5755 8.90039 1.31006 11.1658 1.31006 13.9604C1.31006 16.755 3.5755 19.0204 6.37006 19.0204Z'
              fill='#FFD8A8'
            />
            <path
              id='Vector_6'
              d='M35.61 55.6395C35.31 55.6395 35.1 55.5395 34.9 55.4395C34.29 55.0295 34.19 54.2295 34.6 53.7195L44.01 40.5695C44.01 40.4695 44.11 40.4695 44.11 40.3695C44.82 39.6595 45.83 39.2595 46.84 39.2595H50.68V32.6795C50.28 32.7795 49.87 32.8795 49.36 32.8795H42.99C42.28 32.8795 41.68 32.2695 41.68 31.5595C41.68 30.8495 42.29 30.2495 42.99 30.2495H49.36C49.56 30.2495 49.77 30.1495 49.97 30.0495L51.18 29.3395C51.58 29.1395 52.09 29.1395 52.5 29.3395C52.9 29.5395 53.11 29.9495 53.11 30.4495V40.3595C53.11 41.0695 52.5 41.6795 51.79 41.6795H46.73C46.43 41.6795 46.12 41.7795 45.92 41.9795L36.61 55.0295C36.41 55.4395 36 55.6395 35.6 55.6395H35.61Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_7'
              d='M39.1501 59.2798C38.9501 59.2798 38.6401 59.1798 38.4401 59.0798C37.8301 58.6698 37.7301 57.8698 38.1401 57.3598L46.7401 44.9198C46.9401 44.6198 47.3501 44.4098 47.7501 44.4098H55.5401C56.9601 44.4098 58.0701 43.2998 58.0701 41.8798V26.7098C58.0701 25.3898 56.9601 24.2798 55.6401 24.1798H55.4401C54.7301 24.1798 54.1201 24.4798 53.6201 24.9898C53.5201 25.0898 53.4201 25.1898 53.3201 25.1898L49.1701 27.7198C48.9701 27.8198 48.7701 27.9198 48.5601 27.9198H41.6801C40.9701 27.9198 40.3601 28.5298 40.3601 29.2398C40.3601 29.9498 40.9701 30.5498 41.6801 30.5498C42.3901 30.5498 42.9901 31.1598 42.9901 31.8698C42.9901 32.5798 42.3801 33.1898 41.6801 33.1898C39.5601 33.1898 37.8401 31.4698 37.8401 29.3498C37.8401 27.2298 39.5601 25.5098 41.6801 25.5098H48.2601L52.0001 23.2798C52.9101 22.3698 54.1201 21.7598 55.4401 21.7598H55.7401C58.4701 21.8598 60.6001 24.0898 60.6001 26.8198V41.9898C60.6001 44.8198 58.3801 47.0498 55.5401 47.0498H48.3601L40.0701 58.8898C39.9701 59.0898 39.5601 59.2898 39.1601 59.2898L39.1501 59.2798Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_8'
              d='M25.0901 55.6408C24.6901 55.6408 24.2801 55.4408 24.0801 55.1308L14.7701 42.0808C14.5701 41.8808 14.2601 41.7808 13.9601 41.7808H8.90009C8.19009 41.7808 7.59009 41.1708 7.59009 40.4708V30.5608C7.59009 30.0508 7.79009 29.6508 8.20009 29.4508C8.60009 29.2508 9.11009 29.2508 9.51009 29.4508L10.7201 30.1608C10.9201 30.2608 11.1201 30.3608 11.3301 30.3608H17.7001C18.4101 30.3608 19.0201 30.9708 19.0201 31.6808C19.0201 32.3908 18.4101 32.9908 17.7001 32.9908H11.3301C10.9301 32.9908 10.5201 32.8908 10.0201 32.7908V39.3708H13.8601C14.8701 39.3708 15.8801 39.7808 16.5901 40.4808C16.5901 40.4808 16.6901 40.5808 16.6901 40.6808L26.1001 53.8308C26.5001 54.4408 26.4001 55.1508 25.8001 55.5508C25.6001 55.5508 25.2901 55.6508 25.0901 55.6508V55.6408Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_9'
              d='M21.55 59.2806C21.15 59.2806 20.74 59.0806 20.54 58.7706L12.25 46.9306H5.06C2.23 46.9306 0 44.7106 0 41.8706V26.7006C0 23.9706 2.12 21.7406 4.86 21.6406H5.16C6.47 21.6406 7.69 22.2506 8.6 23.1606L12.34 25.3806H18.92C21.04 25.3806 22.76 27.1006 22.76 29.2206C22.76 31.3406 21.04 33.0606 18.92 33.0606C18.21 33.0606 17.61 32.4506 17.61 31.7406C17.61 31.0306 18.22 30.4306 18.92 30.4306C19.62 30.4306 20.24 29.8206 20.24 29.1106C20.24 28.4006 19.63 27.7906 18.92 27.7906H12.04C11.84 27.7906 11.64 27.6906 11.43 27.5906L7.28 25.1606C7.18 25.0606 7.08 25.0606 6.98 24.9606C6.47 24.4506 5.87 24.1506 5.16 24.1506H4.96C3.54 24.0506 2.53 25.1606 2.53 26.5806V41.7506C2.53 43.1706 3.64 44.2806 5.06 44.2806H12.85C13.25 44.2806 13.66 44.4806 13.86 44.7906L22.46 57.2306C22.86 57.8406 22.76 58.5506 22.16 58.9506C21.96 59.1506 21.76 59.2506 21.55 59.2506V59.2806Z'
              fill='#65B2FE'
            />
            <path
              id='Vector_10'
              d='M42.9899 32.88H17.6999C16.9899 32.88 16.3899 32.27 16.3899 31.56C16.3899 30.85 16.9999 30.25 17.6999 30.25H42.9899C43.6999 30.25 44.3099 30.86 44.3099 31.56C44.3099 32.26 43.6999 32.88 42.9899 32.88Z'
              fill='#73665C'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_6552_6100'>
          <rect width='60.59' height='59.48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
