import { formItemErrorClasses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import FormatNumber from './FormatNumber'
import { Form } from 'antd'

const AmountInputItem = ({
  required = true,
  className = '',
  setFieldsValue,
  defaultValue,
  label = '',
  name = '',
  error,
}) => {
  return (
    <Form.Item
      className={`${formItemErrorClasses(error)} ${
        error && 'ant-input-border-error'
      } ${className} custom-antd-number-format-input-item antd-input-hover-and-focus`}
      required={required}
      label={label}
    >
      <Form.Item name={name}>
        <FormatNumber
          setFieldsValue={setFieldsValue}
          defaultValue={defaultValue}
          name={name}
        />
      </Form.Item>

      <ShowFieldError withHeight error={error} />
    </Form.Item>
  )
}

export default AmountInputItem
