import { useUploadDeliveredGoods } from './hooks'
import { InboxOutlined } from '@ant-design/icons'
import { canManageProject } from 'src/utils'
import { CustomUpload } from 'src/common'

const UploadDeliveredGoods = ({
  multiple = true,
  handleFileDrag = () => {},
  handleRemove = () => {},
  defaultFiles = [],
  error = false,
}) => {
  const { t, changeHandler, beforeUpload } =
    useUploadDeliveredGoods(handleFileDrag)

  return (
    <div className='uploaddoc'>
      <CustomUpload
        style={{
          marginBottom: '1rem',
        }}
        defaultFileList={defaultFiles}
        disabled={!canManageProject()}
        beforeUpload={beforeUpload}
        onChange={changeHandler}
        onRemove={handleRemove}
        listType='picture-card'
        multiple={multiple}
        accept='image/*'
        isDragger
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{t('delivered_goods')}</p>
      </CustomUpload>
      {error ? <p style={{ color: 'red' }}>{error}</p> : null}
    </div>
  )
}

export default UploadDeliveredGoods
