import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const useQuotationAgreement = () => {
  const { t } = useTranslation()
  const [isTermsOfAgreementModalOpen, setIsTermsOfAgreementModalOpen] =
    useState(false)

  return { t, isTermsOfAgreementModalOpen, setIsTermsOfAgreementModalOpen }
}
