import { handleNonValidationError, handleValidationError } from 'src/utils'
import { updateMountingSystemRequest } from 'src/services'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowMountingSystem = (
  currentMountingSystem,
  setShowMountingSystemModal
) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const {
    mutate: updateMountingSystem,
    isLoading,
    error,
  } = useMutation(
    (variables) =>
      !isLoading &&
      updateMountingSystemRequest(currentMountingSystem.id, variables),
    {
      onSuccess: (resp) => {
        if (resp.data.success) {
          message.success(t('updated_successfully'))
          queryClient.invalidateQueries('mountingSystem')
          closeModal()
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const closeModal = () => {
    setShowMountingSystemModal({ show: false, data: {} })
  }

  useEffect(() => {
    if (currentMountingSystem) {
      form.setFieldsValue(currentMountingSystem)
    }
  }, [currentMountingSystem, form])

  return {
    updateMountingSystemError: handleValidationError(error),
    currentMountingSystem,
    updateMountingSystem,
    closeModal,
    isLoading,
    form,
    t,
  }
}
