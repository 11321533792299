import { InvoiceIcon, TeamWorkIcon, ClockIcon, NetworkIcon } from 'src/assets'
import { PagesLinkSection, ManagePageSectionsAccordion } from './components'
import { isSuperUserOrAdmin } from 'src/utils'
import { useManageScreen } from './useManageScreen'
import { Header } from 'src/common'

const ManageScreen = () => {
  const {
    setActiveAccordionKey,
    companySettingsPages,
    showCompanySettings,
    activeAccordionKey,
    applicationPages,
    saleProductPages,
    showProducts,
    t,
  } = useManageScreen()

  return (
    <div className='manage'>
      <Header />

      <div className='manage__container  '>
        <div className='manage__container__title'>{t('manage')}</div>

        <div className='manage__container__pages-sections--desktop'>
          {showProducts && (
            <PagesLinkSection
              linksData={saleProductPages}
              title={t('sale_products')}
              SectionIcon={TeamWorkIcon}
            />
          )}

          {isSuperUserOrAdmin() && (
            <PagesLinkSection
              linksData={applicationPages}
              title={t('applications')}
              SectionIcon={InvoiceIcon}
            />
          )}

          {isSuperUserOrAdmin() && (
            <PagesLinkSection
              title={t('planning_tool')}
              SectionIcon={ClockIcon}
            >
              <div className='coming-soon'>{t('coming_soon')}</div>
            </PagesLinkSection>
          )}

          {showCompanySettings && (
            <PagesLinkSection
              linksData={companySettingsPages}
              title={t('company_settings')}
              SectionIcon={NetworkIcon}
            />
          )}
        </div>

        <div className='manage__container__pages-sections--mobile'>
          {showProducts && (
            <ManagePageSectionsAccordion
              setActiveAccordionKey={setActiveAccordionKey}
              activeAccordionKey={activeAccordionKey}
              linksData={saleProductPages}
              title={t('sale_products')}
              accordionKey='1'
            />
          )}

          {isSuperUserOrAdmin() && (
            <ManagePageSectionsAccordion
              setActiveAccordionKey={setActiveAccordionKey}
              activeAccordionKey={activeAccordionKey}
              linksData={applicationPages}
              title={t('applications')}
              accordionKey='2'
            />
          )}

          {isSuperUserOrAdmin() && (
            <ManagePageSectionsAccordion
              setActiveAccordionKey={setActiveAccordionKey}
              activeAccordionKey={activeAccordionKey}
              title={t('planning_tool')}
              accordionKey='3'
            >
              <div className='coming-soon--mobile'>{t('coming_soon')}</div>
            </ManagePageSectionsAccordion>
          )}

          {showCompanySettings && (
            <ManagePageSectionsAccordion
              setActiveAccordionKey={setActiveAccordionKey}
              activeAccordionKey={activeAccordionKey}
              linksData={companySettingsPages}
              title={t('company_settings')}
              accordionKey='4'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ManageScreen
