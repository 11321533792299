import CalendarEvent from './CalendarEvent'

function CustomMonthEvent({ event, onEditClick }) {
  return (
    <CalendarEvent event={event} onEditClick={onEditClick}>
      <div className='custom-month-event'>
        {event?.title} {event.start.toLocaleString()}
      </div>
    </CalendarEvent>
  )
}

export default CustomMonthEvent
