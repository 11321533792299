import { handleNonValidationError } from 'src/utils'
import { useEnabledFeatures } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { logoutRequest } from 'src/services'
import { setRemoveUser } from 'src/actions'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

const useBurgerMenu = () => {
  const { isReportingEnabled, isSolarPlanningEnabled } = useEnabledFeatures()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { mutate: logoutMutation } = useMutation(logoutRequest, {
    onSuccess: () => {
      dispatch(setRemoveUser())
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  return {
    iconsMainColor: '#3D92EC',
    isSolarPlanningEnabled,
    isReportingEnabled,
    logoutMutation,
    t,
  }
}

export default useBurgerMenu
