import { useTranslation } from 'react-i18next'

export const useInfoBoxes = ({ address, accountManagerInfo }) => {
  const { t } = useTranslation()

  const { street, house_number, zipcode, location } = address
  const { name, email, phoneNumber } = accountManagerInfo

  return {
    house_number,
    phoneNumber,
    location,
    zipcode,
    street,
    email,
    name,
    t,
  }
}
