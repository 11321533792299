import TabLink from './TabLink'

const TabSwitcher = () => {
  return (
    <div className='tab__switcher__container'>
      <TabLink tabName='survey' />
      <TabLink tabName='options' />
    </div>
  )
}

export default TabSwitcher
