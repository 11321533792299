import { getAccountManagersRequest, createProjectRequest } from 'src/services'
import { handleNonValidationError, canPerform } from 'src/utils'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roles } from 'src/utils'
import { useState } from 'react'
import { Form } from 'antd'
import {
  useHandleQueryParams,
  useEnabledFeatures,
  useQueryParams,
} from 'src/hooks'
import {
  STATUS_IDS_PARAM,
  MANAGER_IDS_PARAM,
  PROJECT_LIST_TYPE,
} from './constants'

export const useHomeScreen = () => {
  const [ticketView, setTicketView] = useState(false)
  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  const [statusFiltersForm] = Form.useForm()

  const query = useQueryParams()
  const statusIds = JSON.parse(query.get(STATUS_IDS_PARAM)) || []
  const managerIds = JSON.parse(query.get(MANAGER_IDS_PARAM)) || []
  const projectListType = query.get(PROJECT_LIST_TYPE)

  const { user } = useSelector((state) => state.globalSlice)

  const { isNewLeadsEnabled } = useEnabledFeatures()

  const { t } = useTranslation()
  const history = useHistory()

  const { data: managersData, isLoading: isManagersLoading } = useQuery(
    ['users', 'managers'],
    () =>
      getAccountManagersRequest(
        user?.company_id,
        user?.roles[0]?.id === roles.superUser
      )
  )

  const { mutate: startNewProjectMutation, isLoading } = useMutation(
    createProjectRequest,
    {
      onSuccess: (response) => {
        if (response?.data?.success) {
          history.push({
            pathname: `/create-project/${t('client')}/${
              response.data?.unfinished_project?.id || response?.data?.id
            }`,
            state: {
              openProject: true,
            },
          })
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function disabledStartNewProject() {
    if (isLoading) {
      return true
    }
    if (canPerform('manage project') || canPerform('manage own project')) {
      return false
    }
    return true
  }

  function handleAllProjectClick() {
    if (!projectListType) {
      appendQueryParams({ [PROJECT_LIST_TYPE]: 'all' })
    }
  }

  function handleOpenProjectClick() {
    deleteQueryParams(PROJECT_LIST_TYPE)
  }

  function isOpenProjectTabActive() {
    return projectListType !== 'all'
  }

  return {
    isOpenProjectTabActive: isOpenProjectTabActive(),
    managersList: managersData?.data?.data,
    isLoading: isManagersLoading,
    startNewProjectMutation,
    disabledStartNewProject,
    handleOpenProjectClick,
    handleAllProjectClick,
    isNewLeadsEnabled,
    statusFiltersForm,
    setTicketView,
    ticketView,
    managerIds,
    statusIds,
    t,
  }
}
