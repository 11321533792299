import { useTranslation } from 'react-i18next'
import PlaningProduct from './components/PlaningProduct'
import { getProductDisplayInfo } from '../../../utils'

function ProductInformation({ products }) {
  const { t } = useTranslation()

  return (
    <div className='product_information_wrap'>
      <div className='product_information_header'>
        <h4>{t('product_information')}</h4>
      </div>
      <div className='product_information_content'>
        {products?.map((product) => {
          return (
            <PlaningProduct
              key={product?.id}
              product={getProductDisplayInfo(product)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProductInformation
