import { useTranslation } from 'react-i18next'

export const useUploadDeliveredGoods = (handleFileDrag) => {
  const { t } = useTranslation()

  const changeHandler = (info) => {
    handleFileDrag(info?.fileList)
  }

  const beforeUpload = () => {
    return false
  }

  return { t, changeHandler, beforeUpload }
}
