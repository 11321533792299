import { TableSettings } from '../TableSettings'
import { useTranslation } from 'react-i18next'
import { getArrayValueById } from 'src/utils'
import { statuses } from 'src/common'

const TableStatusTags = ({ value, record, deleteProject, archiveProject }) => {
  const { t } = useTranslation()

  const tagColor = statuses.find((status) => status.id === value)?.color

  const statusClass = `homescreen__status ${
    !value && 'homescreen__status--single'
  }`
  const renderStatus = record.archived ? (
    <div className='homescreen__status-tag archived'>{t('archived')}</div>
  ) : (
    value && (
      <div
        className='homescreen__status-tag'
        style={{
          color: tagColor,
        }}
      >
        {getArrayValueById(statuses, value, t)}
      </div>
    )
  )

  return (
    <div className={statusClass}>
      {value && renderStatus}
      <TableSettings
        isArchived={record?.archived}
        onDelete={() => deleteProject(record?.id)}
        onArchive={() => archiveProject(record?.id)}
      />
    </div>
  )
}

export default TableStatusTags
