import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updateMaterialRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowMaterialsModal = (
  setShowMaterialModal,
  currentMaterial
) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const closeModal = () => {
    setShowMaterialModal({ show: false, data: {} })
  }

  const {
    isLoading,
    mutate: updateMaterial,
    error,
  } = useMutation(
    (variables) =>
      !isLoading && updateMaterialRequest(currentMaterial.id, variables),
    {
      onSuccess: () => {
        queryClient.refetchQueries('materials')
        setShowMaterialModal(false)
        message.success(t('updated_successfully'))
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (currentMaterial) {
      form.setFieldsValue(currentMaterial)
    }
  }, [currentMaterial, form])

  return {
    updateMaterialError: handleValidationError(error),
    updateMaterial,
    closeModal,
    isLoading,
    Option,
    form,
    t,
  }
}
