import { removeOfferProductRequest, attachOptionRequest } from 'src/services'
import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { message, Form } from 'antd'
import { useEffect } from 'react'

export const useRenderMaterialsOption = (attachedOPtion, option) => {
  const { offerId } = useParams()

  const { t } = useTranslation()

  const [form] = Form.useForm()

  useEffect(() => form.resetFields(), [attachedOPtion])

  const queryClient = useQueryClient()
  const {
    mutate: addMaterialToProject,
    isLoading: isAdding,
    error: addingError,
  } = useMutation(attachOptionRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
        message.success(`${t('added_successfully')}`)
      }
    },
    onError: (error) => handleNonValidationError(error),
  })

  const { mutate: removeMaterialFromProject, isLoading: removingMaterial } =
    useMutation(removeOfferProductRequest, {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries(['offer', offerId])
          message.success(` ${t('deleted_successfully')}`)
        }
      },
      onError: (error) => handleNonValidationError(error),
    })

  const addMaterial = () => {
    const formValues = form.getFieldsValue()

    addMaterialToProject({
      optionable_id: option?.id,
      offer_id: +offerId,
      optionable_type: 2,
      ...formValues,
    })
  }

  const removeMaterial = () => {
    removeMaterialFromProject({
      id: attachedOPtion?.id,
      table: 'offer_option',
    })
  }

  return {
    error: handleValidationError(addingError),
    addMaterial,
    removeMaterial,
    removingMaterial,
    isAdding,
    t,
    form,
  }
}
