import { handleNonValidationError, isAdmin } from 'src/utils'
import { getNewLeadsCountRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

const useLeadsButton = () => {
  const { t } = useTranslation()

  const { data } = useQuery(
    'leads-notifications-count',
    getNewLeadsCountRequest,
    { onError: handleNonValidationError, enabled: isAdmin() }
  )

  return { t, newLeadsCount: data?.data?.count }
}

export default useLeadsButton
