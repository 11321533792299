import { Input } from 'antd'

import { SearchIcon } from 'src/assets/icons'
import { usePlanningSearch } from './hooks'

function PlanningSearch() {
  const { handlePlanningSearchChange, t } = usePlanningSearch()

  return (
    <div className='planning-search'>
      <Input
        onChange={handlePlanningSearchChange}
        placeholder={t('planning_search_placeholder')}
      />
      <SearchIcon />
    </div>
  )
}

export default PlanningSearch
