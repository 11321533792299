import { Form } from 'antd'
import { format } from 'date-fns/esm'
import { useTranslation } from 'react-i18next'
import { parseDateString } from 'src/utils'

const InstallationWeekQuotation = ({ installationWeek }) => {
  const { t } = useTranslation()

  return (
    <div className='installationWeek'>
      <Form
        name='installation-week'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      >
        <Form.Item label={t('installation_week')}>
          <div className='installationWeek__text'>
            {installationWeek
              ? `${format(parseDateString(installationWeek), 'yyyy-MM-dd (w)')}`
              : ''}
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default InstallationWeekQuotation
