import { CustomUpload, ShowFieldError, InputItem } from 'src/common'
import { Form, Input, Checkbox, Typography } from 'antd'
import { useConvertersAndDC } from './hooks'

const ConvertersAndDC = ({
  setFieldsValue = () => {},
  error,
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
}) => {
  const { t, fileList, uploadChangeHandler, checkboxChangeHandler } =
    useConvertersAndDC(setFieldsValue, getStepFiles)

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('converters_and_dc_title')}
      </Typography.Title>

      <Form.Item
        className={error?.roofoutlet ? 'group-label-error' : ''}
        label={<div>{t('roofoutlet')}</div>}
        required
      >
        <Form.Item name='roofoutlet' noStyle>
          <Checkbox.Group onChange={checkboxChangeHandler}>
            <Checkbox className='wizards_checkbox_space' value={7}>
              {t('drilling_trough_the_roof_decking')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={8}>
              {t('dc_roof_vent')}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.roofoutlet} />
      </Form.Item>

      <InputItem
        placeholder={t('estimate_length_dc_in_meters')}
        label={t('estimate_length_dc_in_meters')}
        error={error?.length_dc}
        name='length_dc'
      />

      <Form.Item label={t('extra_information_converters_and_dc')}>
        <Form.Item name='extra_information_converter_and_dc' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>

      <Form.Item required label={t('upload_pictures_converters_and_dc')}>
        <Form.Item name='convertersAndDC_pictures' noStyle>
          <CustomUpload
            beforeUpload={() => false}
            fileList={fileList}
            onChange={uploadChangeHandler}
            multiple
            listType='picture-card'
            accept='image/*'
            onRemove={deleteInspectionImage}
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
          <ShowFieldError
            withHeight={true}
            error={error?.convertersAndDC_pictures}
          />
        </Form.Item>
        <p className='inspection_wizard_form__instruction'>
          {t('checklist_pictures')} <br /> {t('picture_converterlocation')}
          <br />
          {t('picture_dc_cableroute')} <br /> {t('picture_location_dc_outlet')}
        </p>
      </Form.Item>
    </>
  )
}

export default ConvertersAndDC
