import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from 'src/hooks'
import { useMemo } from 'react'

const useLeadIntegration = (companyData) => {
  const { user } = useGetUserInfo()
  const { t } = useTranslation()

  const iframePreviewToken = useMemo(() => {
    return companyData?.lead_tokens?.[0]
  }, [companyData])

  return {
    iframePreviewToken,
    user,
    t,
  }
}

export default useLeadIntegration
