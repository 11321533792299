function CarIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M5.76936 4.53872C5.56531 4.53872 5.36962 4.61978 5.22534 4.76406C5.08106 4.90834 5 5.10403 5 5.30808V12.2323C5 12.4364 5.08106 12.6321 5.22534 12.7763C5.36962 12.9206 5.56531 13.0017 5.76936 13.0017H14.2323C14.4364 13.0017 14.6321 12.9206 14.7763 12.7763C14.9206 12.6321 15.0017 12.4364 15.0017 12.2323V5.30808C15.0017 5.10403 14.9206 4.90834 14.7763 4.76406C14.6321 4.61978 14.4364 4.53872 14.2323 4.53872H12.6936M7.30808 3V6.07744M12.6936 3V6.07744M7.30808 4.53872H11.1549M9.97776 6.07744V7.23148M7.6466 7.42382L8.64676 8.00084M7.6466 10.1166L8.64676 9.53956M9.97776 11.463V10.3089M12.3089 10.1166L11.3087 9.53956M12.3089 7.42382L11.3087 8.00084'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.97817 10.304C10.828 10.304 11.5169 9.61509 11.5169 8.76528C11.5169 7.91547 10.828 7.22656 9.97817 7.22656C9.12836 7.22656 8.43945 7.91547 8.43945 8.76528C8.43945 9.61509 9.12836 10.304 9.97817 10.304Z'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { CarIcon }
