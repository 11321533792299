import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const generalConditionsByTypeRequest = async ({
  type,
  page,
  size = 10,
  searchKeyWord = '',
  companyId,
}) => {
  return await axios.get(
    `api/conditions?page=${page}&per_page=${size}${
      searchKeyWord && `&search=${searchKeyWord}`
    }${type && `&type=${type}`}${appendCompanyId(companyId)}`
  )
}

export const updateGeneralConditionRequest = async (id, data) => {
  return await axios.put(`api/conditions/${id}`, data)
}

export const addGeneralConditionRequest = async (data) => {
  return await axios.post('api/conditions', data)
}

export const importGeneralConditionsRequest = async (data) => {
  return await axios.post('api/condition/import', data)
}

export const searchGeneralConditionsRequest = async (data) => {
  return await axios.post('api/conditions/search', data)
}

export const deleteGeneralConditionRequest = async (id) => {
  return await axios.delete(`api/conditions/${id}`)
}
