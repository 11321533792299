import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

function useIframeInformation({ iframeApiToken }) {
  const { t } = useTranslation()
  const [isEmbedCodeCopied, setIsEmbedCodeCopied] = useState(false)

  const iframeUrl = useMemo(() => {
    return `${window.location.origin}/lead-iframe?token=${iframeApiToken}`
  }, [iframeApiToken])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    }
  }

  function handleCopyClick(text, setState) {
    if (!text) return
    copyTextToClipboard(text)
      .then(() => {
        setState(true)
        setTimeout(() => {
          setState(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleIframeUrlCopy() {
    const iframe = `<iframe src="${iframeUrl}" title="lead-iframe"></iframe>`
    return handleCopyClick(iframe, setIsEmbedCodeCopied)
  }

  return {
    handleIframeUrlCopy,
    isEmbedCodeCopied,
    iframeUrl,
    t,
  }
}

export { useIframeInformation }
