import { useQueryClient, useMutation } from 'react-query'
import { appendCompanyId, isSuperUser } from 'src/utils'
import { addBrandRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useGetProductBrand } from 'src/hooks'
import { useSelector } from 'react-redux'

export const useConverterFormInputs = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const { mutate: addConverterBrand, isLoading: addConverterBrandLoading } =
    useMutation(addBrandRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries('converterBrands')
      },
    })

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'converterBrands',
    url: `api/brands?classification=4${appendCompanyId(user?.company?.id)}`,
  })

  const clickHandler = (newBrandInputValue) => {
    if (!newBrandInputValue) return
    const additionalOptions = {}

    if (isSuperUser()) {
      additionalOptions['company_id'] = user?.company?.id
    }

    addConverterBrand({
      name: newBrandInputValue,
      classification: 4,
      ...additionalOptions,
    })
  }

  return {
    converterBrands: productBrands,
    addConverterBrandLoading,
    fetchBrandOnScroll,
    clickHandler,
    t,
  }
}
