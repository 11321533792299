import { unitList } from '../constants/unitList'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import {
  AmountInputItem,
  FormModalWrapper,
  SelectItem,
  InputItem,
  typeList,
} from 'src/common'

const MaterialsFormModal = ({
  currentMaterial = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  show,
  form,
}) => {
  const { t } = useTranslation()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      closeHandler={closeHandler}
      modalTitle={t('material')}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <SelectItem
        defaultValue={currentMaterial?.type}
        error={errors?.type}
        label={t('type')}
        name='type'
        form={form}
      >
        {typeList.map((item, index) => (
          <Select.Option key={index} value={item}>
            {t(item)}
          </Select.Option>
        ))}
      </SelectItem>

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <SelectItem
        defaultValue={currentMaterial?.unit}
        error={errors?.unit}
        label={t('unit')}
        form={form}
        name='unit'
      >
        {unitList.map((item, index) => (
          <Select.Option key={index} value={item}>
            {t(item)}
          </Select.Option>
        ))}
      </SelectItem>

      <AmountInputItem
        defaultValue={currentMaterial?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={currentMaterial?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />
    </FormModalWrapper>
  )
}

export default MaterialsFormModal
