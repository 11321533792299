import { ActionButtonsGroup } from 'src/screens/Home/components/ActionButtonsGroup'
import { useManagersFilter } from './useManagersFilter'
import { ManagerSearch } from '../ManagerSearch'
import { UpOutlined } from '@ant-design/icons'
import { AvatarIcon } from 'src/assets'
import { Checkbox, Form } from 'antd'

const ManagersFilter = ({ accountManagersForm, managersList, managerIds }) => {
  const {
    setAccountManagersModalOpen,
    accountManagersModalOpen,
    setManagerSearchKeyword,
    managerSearchKeyword,
    submitHandler,
    resetHandler,
    options,
    t,
  } = useManagersFilter({ managersList, accountManagersForm, managerIds })

  return (
    <div
      className={`projects__filter__dropdown managers-filter ${
        accountManagersModalOpen && 'projects__filter__dropdown__active'
      }`}
    >
      {accountManagersModalOpen && (
        <div
          onClick={() => setAccountManagersModalOpen(false)}
          className='filters__overlay'
        ></div>
      )}

      <div
        className='projects__filter__dropdown__container'
        onClick={() => setAccountManagersModalOpen((prev) => !prev)}
      >
        <div className='label__title'>
          <AvatarIcon />
          <div className='managers__label'>{t('account_manager')}</div>
        </div>
        <UpOutlined
          className={`${
            accountManagersModalOpen
              ? 'filter__dropdown__icon__active'
              : 'filter__dropdown__icon'
          }`}
          color='#606870'
        />
      </div>

      {accountManagersModalOpen && (
        <div className='projects__filters__checkbox managers__checkbox'>
          <p className='total__projects__count'>
            {t('overall')} {managersList?.length} <span>{t('manager')}</span>
          </p>

          <ManagerSearch
            setManagerSearchKeyword={setManagerSearchKeyword}
            managerSearchKeyword={managerSearchKeyword}
          />

          <Form
            initialValues={{ filters: managerIds }}
            form={accountManagersForm}
            onFinish={submitHandler}
          >
            <Form.Item name='filters'>
              <Checkbox.Group
                className='manager__checkbox__group'
                options={options}
              />
            </Form.Item>

            <ActionButtonsGroup
              cancelHandler={() => setAccountManagersModalOpen(false)}
              resetHandler={resetHandler}
              className='margin-t'
            />
          </Form>
        </div>
      )}
    </div>
  )
}

export default ManagersFilter
