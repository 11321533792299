import { Checkbox } from 'antd'

const CustomCheckbox = (props) => {
  const {
    containerClass = '',
    checkboxClass = '',
    setCheckboxValue,
    disabled = false,
    checkboxValue,
    title,
  } = props

  return (
    <div className={containerClass}>
      <Checkbox
        className={checkboxClass}
        onChange={() => setCheckboxValue(!checkboxValue)}
        checked={checkboxValue}
        value={checkboxValue}
        disabled={disabled}
      >
        {title}
      </Checkbox>
    </div>
  )
}

export default CustomCheckbox
