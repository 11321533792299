import { AiOutlineSearch } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

const ManagerSearch = ({ setManagerSearchKeyword, managerSearchKeyword }) => {
  const { t } = useTranslation()

  return (
    <div className='manager__search__input__container'>
      <input
        onChange={(e) => setManagerSearchKeyword(e.target.value)}
        className='manager__search__input'
        placeholder={t('search_manager')}
        value={managerSearchKeyword}
        type='text'
      />

      <div className='search__icon'>
        <AiOutlineSearch size={20} color='#ADB5BD' />
      </div>
    </div>
  )
}

export default ManagerSearch
