import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Form, message } from 'antd'
import {
  allConvertersRequest,
  attachConverterToProjectRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  appendCompanyId,
  flatPaginatedData,
  isBottom,
} from 'src/utils'
import { useGetProductBrand } from 'src/hooks'

export function useAttachConverterToProject({
  data,
  deleteConverter,
  projectCompanyId,
}) {
  const [form] = Form.useForm()
  const { md, sm } = useBreakpoint()
  const layout = {
    labelCol: { span: !md ? 7 : !sm ? 6 : 5 },
    wrapperCol: { span: 20 },
  }
  const [brandId, setBrandId] = useState(data?.converter?.brand_id)
  const [converter, setConverter] = useState(data?.converter)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { offerId } = useParams()

  const queryClient = useQueryClient()

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'converterBrands',
    url: `api/brands?classification=4&project=1${appendCompanyId(
      projectCompanyId
    )}`,
  })

  const {
    data: converters,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['converters', brandId, projectCompanyId],
    ({ pageParam = 1 }) =>
      allConvertersRequest({
        page: pageParam,
        companyId: projectCompanyId,
        brandId,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(brandId) && Boolean(projectCompanyId),
    }
  )

  function clearForm() {
    if (!data) {
      form.resetFields()
      setConverter(null)
      setBrandId(null)
      deleteConverter()
    }
    if (data && data?.converter?.id !== converter?.id) {
      form.resetFields()
      setConverter(data?.converter)
      setBrandId(data?.converter?.brand_id)
    }
    if (data && data?.converter?.id === converter?.id) {
      message.success(`${t('updated_successfully')}`)
    }
  }

  const {
    mutate: addConverterToProject,
    isLoading: isAdding,
    error: addingError,
  } = useMutation(attachConverterToProjectRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
        clearForm()
      }
    },
    onError: (err) => handleNonValidationError(err),
  })

  function attachConverterToProject(values) {
    if (!canManageProject()) return

    addConverterToProject({
      offer_id: offerId,
      converter_id: converter?.id,
      brand_id: brandId,
      ...values,
    })
  }

  const { t } = useTranslation()

  useEffect(() => {
    if (data) {
      form.resetFields()
    }
    if (openConfirmationModal) {
      closeConfirmationModal()
    }
  }, [data])

  const clickHandler = () => {
    if (!canManageProject()) return

    if (data) {
      return openConfirmationModal()
    } else {
      deleteConverter()
    }
  }

  const converterChangeHandler = (val) => {
    setBrandId(val)
    form.setFieldsValue({
      type: '',
      converter_type: '',
      quantity: '',
    })
    setConverter(null)
  }

  const typeChangeHandler = (converterType) => {
    let tempConverters = flatPaginatedData(converters)

    setConverter(
      tempConverters?.find((converter) => converter?.type === converterType)
    )
  }

  const finishHandler = (values) => {
    const { quantity } = values || {}
    attachConverterToProject({
      quantity,
    })
  }

  function closeConfirmationModal() {
    setShowConfirmationModal(false)
  }

  function openConfirmationModal() {
    setShowConfirmationModal(true)
  }

  function fetchConverterOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return
    fetchNextPage()
  }

  return {
    error: handleValidationError(addingError),
    converters: converters?.pages,
    converterBrands: productBrands,
    attachConverterToProject,
    closeConfirmationModal,
    showConfirmationModal,
    fetchBrandOnScroll,
    fetchConverterOnScroll,
    converter,
    isAdding,
    brandId,
    setBrandId,
    setConverter,
    layout,
    form,
    t,
    clickHandler,
    converterChangeHandler,
    typeChangeHandler,
    finishHandler,
  }
}
