import { useTranslation } from 'react-i18next'

function ClientInformation({ project }) {
  const { t } = useTranslation()

  return (
    <>
      <div className='client_information_header'>
        <h4>{t('client_information')}</h4>
      </div>
      <div className='client_information_and_project_container'>
        <div className='client_information_and_project_group group_border_right'>
          <div className='information_row'>
            <span>{t('client_type')}</span>
            <span>{project?.type === 1 ? t('private') : t('business')}</span>
          </div>
          <div className='information_row'>
            <span>{t('date')}</span>
            <span>{project?.made_on_date}</span>
          </div>
          <div className='information_row'>
            <span>{t('email')}</span>
            <span>{project?.email}</span>
          </div>
          <div className='information_row'>
            <span>{t('telephone')}</span>
            <span>{project?.mobile}</span>
          </div>
          <div className='information_row'>
            <span>{t('location')}</span>
            <span>{t(project?.location)}</span>
          </div>
          <div className='information_row'>
            <span>{t('house_number_suffix')}</span>
            <span>{project?.house_number_prefix}</span>
          </div>
          <div className='information_row'>
            <span>{t('zipcode')}</span>
            <span>{project?.zipcode}</span>
          </div>
        </div>
        <div className='client_information_and_project_group'>
          <div className='information_row'>
            <span>{t('house_number')}</span>
            <span>{project?.house_number}</span>
          </div>
          <div className='information_row'>
            <span>{t('client_name')}</span>
            <span>{project?.name}</span>
          </div>
          <div className='information_row'>
            <span>{t('phone_number')}</span>
            <span>{project?.phone_number}</span>
          </div>
          <div className='information_row'>
            <span>{t('country')}</span>
            <span>{project?.country?.name}</span>
          </div>
          <div className='information_row'>
            <span>{t('street')}</span>
            <span>{project?.street}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientInformation
