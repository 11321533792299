import { countriesInfoRequest } from 'src/services'
import { STALE_AND_CACHE } from 'src/common'
import { useQuery } from 'react-query'

function useFetchCountries() {
  const { data: countriesData, isLoading: isCountriesLoading } = useQuery(
    'countries',
    countriesInfoRequest,
    {
      ...STALE_AND_CACHE,
    }
  )

  return { countriesData, isCountriesLoading }
}

export { useFetchCountries }
