import React from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  name,
}) => {
  const radius = 30 + innerRadius + (outerRadius - innerRadius)
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill='#000' textAnchor={x > cx ? 'start' : 'end'}>
      {`${name}: ${value}`}
    </text>
  )
}

const ProjectStatusesChart = ({ projectStatuses }) => {
  return (
    <ResponsiveContainer height={350}>
      <PieChart height={350}>
        <Tooltip
          contentStyle={{
            borderRadius: '8px',
            padding: '5px 7px',
            border: '1px solid #DEE2E6',
          }}
        />
        <Legend />
        <Pie
          isAnimationActive={false}
          data={projectStatuses}
          cx='50%'
          cy='50%'
          outerRadius={100}
          fill='#8884d8'
          dataKey='value'
        >
          {projectStatuses?.map((status) => {
            return <Cell key={`cell-${status?.id}`} fill={status?.color} />
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default ProjectStatusesChart
