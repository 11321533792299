import { useGetUserInfo, useEnabledFeatures } from 'src/hooks'
import { getProfilePictureSrc } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const useDashboard = () => {
  const { user, isUserDataLoading } = useGetUserInfo()
  const { isReportingEnabled, isSolarPlanningEnabled } = useEnabledFeatures()

  const { t } = useTranslation()

  const userAvatar = useMemo(() => getProfilePictureSrc(user), [user])

  return {
    isSolarPlanningEnabled,
    isUserDataLoading,
    isReportingEnabled,
    userAvatar,
    user,
    t,
  }
}

export default useDashboard
