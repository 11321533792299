import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const useAcTraject = (setFieldsValue, getStepFiles) => {
  const [fileList, setFileList] = useState(getStepFiles(4))

  const { t } = useTranslation()

  useEffect(() => {
    setFieldsValue({
      acTraject_pictures: fileList,
    })
  }, [fileList])

  const uploadChangeHandler = (info) => {
    setFileList(info?.fileList)
  }

  const checkboxChangeHandler = (value) => {
    setFieldsValue({
      ac_route_run: value,
    })
  }

  return { t, uploadChangeHandler, fileList, checkboxChangeHandler }
}
