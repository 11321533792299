import {
  PROJECT_PAID_STATUS,
  PROJECT_STATUS,
  TASKS,
  WORKER_ROLES,
} from './enums'

const eventsDummyData = [
  {
    id: 1,
    title: 'Event 1',
    start: new Date(2023, 7, 1, 10, 0), // Year, Month (0-based), Day, Hour, Minute
    end: new Date(2023, 7, 1, 12, 0),
    resource: {
      name: 'Shanshe',
      status: 'planned',
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 11',
        address: 'address 11',
        planner: 'planner 11',
        status: PROJECT_STATUS.on_hold.value,
        paid_status: PROJECT_PAID_STATUS.not_paid.value,
      },
    },
  },
  {
    id: 2,
    title: 'Event 2',

    start: new Date(2023, 6, 19, 14, 30),
    end: new Date(2023, 6, 19, 16, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.planned.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 5',
        address: 'address 5',
        planner: 'planner 5',
        status: PROJECT_STATUS.on_hold.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 3,
    title: 'Event 3',
    start: new Date(2023, 6, 20, 9, 0),
    end: new Date(2023, 6, 20, 10, 30),
    resource: {
      name: 'Not Resource',
      status: TASKS.work_complete.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 6',
        address: 'address 6',
        planner: 'planner 6',
        status: PROJECT_STATUS.finished.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 4,
    title: 'Event 4',
    start: new Date(2023, 6, 19, 15, 30),
    end: new Date(2023, 6, 19, 16, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.planned_and_confirmed.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 7',
        address: 'address 7',
        planner: 'planner 7',
        status: PROJECT_STATUS.finished.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },

  {
    id: 6,
    title: 'Event 6',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.planned_and_confirmed.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 8',
        address: 'address 8',
        planner: 'planner 8',
        status: PROJECT_STATUS.finished.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 7,
    title: 'Event 7',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.work_complete.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 1',
        address: 'address 1',
        planner: 'planner 1',
        status: PROJECT_STATUS.finished.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 8,
    title: 'Event 8',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 17, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.planned.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 2',
        address: 'address 2',
        planner: 'planner 2',
        status: PROJECT_STATUS.lost.value,
        paid_status: PROJECT_PAID_STATUS.not_paid.value,
      },
    },
  },
  {
    id: 9,
    title: 'Event 9',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      status: TASKS.planned_and_confirmed.value,
      img_src: 'https://unsplash.com/photos/_H6wpor9mjs',
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],

      project: {
        name: 'name 3',
        address: 'address 3',
        planner: 'planner 3',
        status: PROJECT_STATUS.on_hold.value,
        paid_status: PROJECT_PAID_STATUS.partially_paid.value,
      },
    },
  },
  {
    id: 8,
    title: 'Event 6',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.work_complete.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 4',
        address: 'address 4',
        planner: 'planner 4',
        status: PROJECT_STATUS.planning.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 9,
    title: 'Event 6',
    start: new Date(2023, 6, 19, 16, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.work_complete.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 5',
        address: 'address 5',
        planner: 'planner 5',
        status: PROJECT_STATUS.finished.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },
  {
    id: 1,
    title: 'Event 10',
    start: new Date(2023, 6, 19, 8, 30),
    end: new Date(2023, 6, 19, 19, 0),
    resource: {
      name: 'Shmagi',
      status: TASKS.planned.value,
      workers: [
        {
          role: WORKER_ROLES.mechanic,
          name: 'Shanshe',
          img_src:
            'https://images.unsplash.com/photo-1558898479-33c0057a5d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
        },
        {
          role: WORKER_ROLES.roof_worker,
          name: 'Saba',
          img_src:
            'https://plus.unsplash.com/premium_photo-1666866587910-2f333c109ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=930&q=80',
        },

        {
          role: WORKER_ROLES.team_lead,
          name: 'Tazo',
          img_src:
            'https://images.unsplash.com/photo-1544435253-f0ead49638fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
        },
      ],
      project: {
        name: 'name 12',
        address: 'address 12',
        planner: 'planner 12',
        status: PROJECT_STATUS.on_hold.value,
        paid_status: PROJECT_PAID_STATUS.fully_paid.value,
      },
    },
  },

  // Add more events here...
]

export { eventsDummyData }
