import { appendDutchCode } from 'src/utils'
import UserFormModal from './UserFormModal'
import { useShowUserModal } from './hooks'

const ShowUserModal = ({
  skillsForDropDown,
  setShowUserModal,
  updateUserInfo,
  currentUser,
  roles = [],
  user,
  show,
}) => {
  const { showUserValues, closeModal, updateUser, isLoading, error, form, t } =
    useShowUserModal(updateUserInfo, user, currentUser, setShowUserModal)

  return (
    <UserFormModal
      skillsForDropDown={skillsForDropDown}
      showUserValues={showUserValues}
      actionButtonTitle={t('update')}
      closeHandler={closeModal}
      initialValues={{
        ...currentUser,
        phone_number: appendDutchCode(currentUser?.phone_number),
        skills: currentUser?.skills?.map((skill) => skill?.id),
      }}
      isLoading={isLoading}
      onFinish={(values) => {
        delete values.draft_signature_id
        updateUser({ ...values, lang: form.getFieldValue('lang') })
      }}
      errors={error}
      roles={roles}
      form={form}
      user={user}
      show={show}
      data={currentUser}
    />
  )
}

export default ShowUserModal
