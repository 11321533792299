import { AiOutlineSearch } from 'react-icons/ai'
import { useSearch } from './useSearch'

const Search = ({
  placeholder,
  onSearchClick = () => {},
  className = '',
  disabled = false,
  stayActive = false,
}) => {
  const {
    handleSearchIconClick,
    handleInputClick,
    handleOnChange,
    clearFocusRef,
    searchValue,
    activeSearch,
    focusInput,
    inputRef,
    lg,
    t,
  } = useSearch(onSearchClick)

  return (
    <div className={`searchForm ${className}`} ref={clearFocusRef}>
      <input
        placeholder={placeholder || t('search_word')}
        className={
          activeSearch
            ? focusInput
              ? 'searchInput searchInput-focused searchInput--active'
              : 'searchInput searchInput--active'
            : `searchInput ${
                (lg || lg === undefined) && !stayActive
                  ? ''
                  : 'searchInput--active'
              }`
        }
        value={searchValue}
        onClick={handleInputClick}
        onChange={(e) => {
          handleOnChange(e.target.value)
        }}
        ref={inputRef}
      />
      <button
        className={
          focusInput ? 'searchButton searchButton-focused' : 'searchButton'
        }
        onClick={handleSearchIconClick}
      >
        <AiOutlineSearch />
      </button>
    </div>
  )
}

export default Search
