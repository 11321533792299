import { appendValuesToFormData, handleNonValidationError } from 'src/utils'
import { fileUploadRequest } from 'src/services'
import { useMutation } from 'react-query'

export function useFileUpload() {
  const {
    mutateAsync: fileUploadMutation,
    isLoading: isUploading,
    error: uploadError,
  } = useMutation(
    ({ url, data }) => {
      return fileUploadRequest(url, data)
    },
    {
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  async function uploadFile({ url, data }) {
    const formData = appendValuesToFormData(data)
    return fileUploadMutation({
      url,
      data: formData,
    })
  }

  return {
    uploadFile,
    isUploading,
    uploadError,
  }
}
