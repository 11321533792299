import useTabSelectorOption from './useTabSelectorOption'

const TabSelectorOption = ({ title, isError = false, setActiveTab }) => {
  const { t, activeTab, tabSelectHandler } = useTabSelectorOption(
    title,
    setActiveTab
  )

  return (
    <div
      onClick={tabSelectHandler}
      className={`company__tab-selector__option ${
        activeTab === title ? 'company__tab-selector__option--active' : ''
      } ${isError ? 'company__tab-selector__option--error' : ''}`}
    >
      {t(title)}
    </div>
  )
}

export default TabSelectorOption
