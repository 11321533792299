import { useTranslation } from 'react-i18next'
import { offerInfoRequest } from 'src/services'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'

export const useProducts = () => {
  const { t } = useTranslation()
  const { product, offerId, projectId } = useParams()

  const { isLoading: isOfferLoading, data: offerData } = useQuery(
    ['offer', offerId],
    () => offerInfoRequest(offerId),
    {
      enabled: !!offerId,
    }
  )

  return {
    disableEditing: !!offerData?.data?.order_created_at,
    offer: offerData?.data,
    isOfferLoading,
    projectId,
    product,
    t,
  }
}
