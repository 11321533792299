import { Header } from 'src/common'

import { ActivitiesTable, TypesTable } from './components'

const Activities = () => {
  return (
    <div className='manageScreensLayout'>
      <Header />
      <ActivitiesTable />
      <TypesTable />
    </div>
  )
}

export default Activities
