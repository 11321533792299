import { updateCurrentUserRequest } from 'src/services'
import { handleNonValidationError, isWorker } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { message, Form } from 'antd'
import { useState } from 'react'

export const useChangePasswordModal = (setShowModal, userData) => {
  const [fetchErrors, setFetchErrors] = useState({})
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const { resetFields } = form

  const cancelHandler = () => {
    setShowModal(false)
    resetFields()
    setFetchErrors({})
  }

  const appendFormValues = (object, key, value) => {
    if (!!value) {
      object[key] = value
    }
  }

  const updateRequest = (formValues) => {
    const { password, password_confirmation, old_password } = formValues

    const newPasswordData = {
      old_password: old_password || 0,
    }

    appendFormValues(newPasswordData, 'password', password)
    appendFormValues(
      newPasswordData,
      'password_confirmation',
      password_confirmation
    )

    if (isWorker()) {
      newPasswordData['skills'] = userData?.skills?.map((skill) => skill?.id)
      newPasswordData['is_team_lead'] = userData?.is_team_lead
    }

    return updateCurrentUserRequest({
      ...newPasswordData,
      name: userData?.name,
      lang: userData?.lang,
    })
  }

  const { mutate: resetPasswordMutation, isLoading } = useMutation(
    updateRequest,
    {
      onSuccess: () => {
        message.success(t('password_change_success'))
        setFetchErrors({})
        setShowModal(false)
        resetFields()
      },

      onError: (error) => {
        handleNonValidationError(error)
        setFetchErrors(error?.response?.data?.errors)
      },
    }
  )

  const submitHandler = (formValues) => {
    resetPasswordMutation(formValues)
  }

  return {
    submitHandler,
    cancelHandler,
    fetchErrors,
    isLoading,
    form,
    t,
  }
}
