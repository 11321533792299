import { useTranslation } from 'react-i18next'
import { ShowFieldError } from 'src/common'
import { Form, Select } from 'antd'
import i18next from 'i18next'

const ProjectDetailsSelector = ({
  changeLanguage = false,
  required = true,
  optionsList,
  error = {},
  label,
  name,
  fetchOnScroll = false,
  projectOption = null,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      className={`project_details_selector ${error[name] && 'selector_error'}`}
      label={`${label}${required ? '*' : ''}`}
      name={name}
    >
      <Form.Item name={name} noStyle>
        <Select
          onPopupScroll={(e) => fetchOnScroll && fetchOnScroll(e)}
          placeholder={label}
          name={name}
        >
          {projectOption && (
            <Select.Option key={projectOption?.id} value={projectOption?.id}>
              {changeLanguage && i18next.language === 'nl'
                ? projectOption?.name_du
                : t(projectOption?.name)}
            </Select.Option>
          )}
          {fetchOnScroll
            ? optionsList?.map((item) => {
                return item?.data?.data?.map((option) => {
                  if (projectOption?.id !== option?.id) {
                    return (
                      <Select.Option key={option?.id} value={option?.id}>
                        {changeLanguage && i18next.language === 'nl'
                          ? option?.name_du
                          : t(option?.name)}
                      </Select.Option>
                    )
                  } else {
                    return null
                  }
                })
              })
            : optionsList?.map((option) => {
                return (
                  <Select.Option key={option?.id} value={option?.id}>
                    {changeLanguage && i18next.language === 'nl'
                      ? option?.name_du
                      : t(option?.name)}
                  </Select.Option>
                )
              })}
        </Select>
      </Form.Item>
      <ShowFieldError withHeight={true} error={error[name]} />
    </Form.Item>
  )
}

export default ProjectDetailsSelector
