import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  deleteWifiModuleRequest,
  allWifiModulesRequest,
  importWifiRequest,
  exportRequest,
} from 'src/services'

export function useWifiModules() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const [deleteWifiModule, setDeleteWifiModule] = useState({
    show: false,
    data: {},
  })

  const [showWifiModule, setShowWifiModule] = useState({
    show: false,
    data: {},
  })

  const [showAddWifiModuleModal, setShowAddWifiModuleModal] = useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [importError, setImportError] = useState()

  const [selectedWifiModuleIds, setSelectedWifiModulesIds] = useState([])
  const rowSelection = rowSelectionActions(setSelectedWifiModulesIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const {
    isLoading: isWifiModuleLoading,
    data: wifiModulesData,
    isPreviousData,
  } = useQuery(
    ['wifiModule', page, size, sort, searchKeyWord, user?.company_id],
    () =>
      allWifiModulesRequest({
        companyId: user?.company_id,
        searchKeyWord,
        page,
        size,
        sort,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedWifiModulesIds,
      'wifiModule',
      wifiModulesData?.data?.data?.length
    )

  const { mutate: DeleteWifiModule, isLoading: deleting } = useMutation(
    deleteWifiModuleRequest,

    {
      onSuccess: (resp, deletedModuleId) => {
        if (resp.data.success) {
          if (selectedWifiModuleIds.includes(deletedModuleId)) {
            setSelectedWifiModulesIds((prev) =>
              prev.filter((id) => id !== deletedModuleId)
            )
          }

          if (wifiModulesData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('wifiModule')
          setDeleteWifiModule({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importWifi, isLoading: importing } = useMutation(
    importWifiRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('wifiModule')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )
  const { mutate: exportWifiMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    }
  )

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  function exportWifi() {
    exportWifiMutation({
      model: 'wifi_modules',
    })
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedWifiModuleIds,
      table: 'wifi_modules',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage wifi-module') ||
          canPerform('manage own wifi-module')
        ) {
          setShowWifiModule({ show: true, data: record })
        }
      },
    }
  }

  return {
    exportDisabled: exporting || !wifiModulesData?.data?.data?.length,
    isWifiModulesDataLoading: isWifiModuleLoading || isPreviousData,
    paginationInfo: {
      total: wifiModulesData?.data?.meta?.total,
      page,
    },
    wifiModules: wifiModulesData?.data?.data,
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowAddWifiModuleModal,
    showAddWifiModuleModal,
    setShowImportCsvModal,
    selectedWifiModuleIds,
    deletingMultipleItem,
    deleteSelectedItems,
    setDeleteWifiModule,
    showImportCsvModal,
    setShowWifiModule,
    handlePageChange,
    deleteWifiModule,
    DeleteWifiModule,
    showWifiModule,
    setImportError,
    onSearchClick,
    rowSelection,
    importError,
    importWifi,
    exportWifi,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  }
}
