import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { MdOutlineDone, MdDeleteForever } from 'react-icons/md'
import { Collapse, Form, InputNumber } from 'antd'
import { useRenderMaterialsOption } from './hooks'
import { ShowFieldError } from 'src/common'

const RenderMaterialsOption = ({ option, attachedOPtion = null }) => {
  const { Panel } = Collapse

  const { error, addMaterial, removeMaterial, t, form } =
    useRenderMaterialsOption(attachedOPtion, option)

  return (
    <Collapse expandIconPosition='right' className='child_options'>
      <Panel
        header={option?.name}
        className={`child_options__content ${
          attachedOPtion && 'child_options__attached'
        }`}
      >
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            quantity: attachedOPtion?.quantity,
          }}
        >
          <Form.Item
            required
            className={formItemErrorClasses(error?.quantity)}
            label={t('quantity')}
          >
            <Form.Item name='quantity' noStyle>
              <InputNumber
                style={inputStyles(error?.quantity)}
                disabled={!canManageProject()}
                controls={false}
              />
            </Form.Item>
            <ShowFieldError error={error?.quantity} />
          </Form.Item>

          <Form.Item label={t('unit')}>{option?.unit}</Form.Item>

          <div className='child_options__footer'>
            <MdOutlineDone onClick={addMaterial} className='icon add-icon' />

            <MdDeleteForever
              onClick={removeMaterial}
              className='icon delete-icon'
            />
          </div>
        </Form>
      </Panel>
    </Collapse>
  )
}

export default RenderMaterialsOption
