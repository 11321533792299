import { allProjectsRequest } from 'src/services'
import { getHomeTablesSortName } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { usePageQueryParams } from 'src/hooks'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useState } from 'react'

export const useOpenProjects = (statusIds, managerIds) => {
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const history = useHistory()

  const { t } = useTranslation()

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams('open-page', 'open-size')

  const {
    data: projectsData,
    isLoading: isProjectLoading,
    isPreviousData,
  } = useQuery(
    [
      'projects',
      'not completed',
      page,
      size,
      searchKeyWord,
      sort,
      statusIds,
      managerIds,
    ],
    () =>
      allProjectsRequest({
        appendOpenStatusIds: true,
        searchKeyWord,
        managerIds,
        statusIds,
        page,
        size,
        sort,
      }),
    {
      keepPreviousData: true,
    }
  )

  const openProjectsSearchHandler = (keyWord) => {
    if (keyWord) {
      deleteQueryParams('open-page')
    }
    setSearchKeyWord(keyWord)
  }

  function handleTableChange(_, filters, sorter) {
    const { field, order } = sorter

    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      const fieldName = getHomeTablesSortName(field)
      setSort(`${fieldName},${tempOrd}`)
    }

    if (!order) {
      setSort('')
    }
  }

  function handleProjectClick(record) {
    history.push(`/project/${record?.id}/offer`)
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        let selection = window.getSelection()
        if (!selection.toString()) {
          handleProjectClick(record)
        }
      },
      onContextMenu: () => {
        return false
      },
    }
  }

  return {
    isProjectLoading: isProjectLoading || isPreviousData,
    project: projectsData?.data?.data,
    paginationInfo: {
      total: projectsData?.data?.meta?.total,
      page,
    },
    openProjectsSearchHandler,
    handleProjectClick,
    handleTableChange,
    handlePageChange,
    translate: t,
    onRow,
  }
}
