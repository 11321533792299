export const NetworkIcon = () => {
  return (
    <svg width='90' height='89' viewBox='0 0 90 89' fill='none'>
      <g id='791, Hierarchical Network, Hierarchical Structure, Hierarchy, Network'>
        <g id='Group'>
          <path
            id='Vector'
            d='M80.392 9.09406C85.1178 9.09406 88.9597 12.9359 88.9597 17.6618C88.9597 22.3877 85.1178 26.2296 80.392 26.2296C75.6661 26.2296 71.8242 22.3877 71.8242 17.6618C71.8242 12.9359 75.6661 9.09406 80.392 9.09406Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_2'
            d='M7.54988 17.6801C11.1538 17.6801 14.0607 20.587 14.0607 24.1909C14.0607 27.7948 11.1538 30.7017 7.54988 30.7017C3.94598 30.7017 1.03906 27.7778 1.03906 24.1909C1.03906 20.604 3.96298 17.6801 7.54988 17.6801Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_3'
            d='M43.0265 22.6442L48.3474 36.0058L43.0265 41.3266L37.7227 36.0058L43.0265 22.6442Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_4'
            d='M32.2302 51.95H23.1524C22.1325 49.553 21.6055 46.8161 21.6055 43.8922C21.6055 32.0605 31.2102 22.4728 43.0249 22.4728L37.721 35.8344L43.0249 41.1553L48.3457 35.8344L43.0249 22.4728C54.8565 22.4728 64.4443 32.0775 64.4443 43.8922C64.4443 46.7991 63.9343 49.383 62.8973 51.95H32.2132H32.2302Z'
            fill='white'
          />
          <path
            id='Vector_5'
            d='M43.0275 1.22345C48.8584 1.22345 53.6522 6.01731 53.6522 11.8482C53.6522 17.679 48.8584 22.4729 43.0275 22.4729C37.1967 22.4729 32.4028 17.679 32.4028 11.8482C32.2328 6.01731 37.0267 1.22345 43.0275 1.22345Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_6'
            d='M79.8799 72.3489V77.6528H59.4805V72.3489H69.7652H79.8799Z'
            fill='white'
          />
          <path
            id='Vector_7'
            d='M53.1416 72.3489V77.6528H32.7422V72.3489H43.0269H53.1416Z'
            fill='white'
          />
          <path
            id='Vector_8'
            d='M26.3994 72.3489V77.6528H6V72.3489H16.1147H26.3994Z'
            fill='white'
          />
          <path
            id='Vector_9'
            d='M79.8799 77.6531H59.4805V87.2579H79.8799V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_10'
            d='M53.1416 77.6531H32.7422V87.2579H53.1416V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_11'
            d='M26.3994 77.6531H6V87.2579H26.3994V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_12'
            d='M43.027 23.6633C36.5162 23.6633 31.1953 18.3425 31.1953 11.8317C31.1953 5.32085 36.4992 0 43.027 0C49.5548 0 54.8586 5.32085 54.8586 11.8317C54.6886 18.3425 49.5548 23.6633 43.027 23.6633ZM43.027 2.41393C37.7231 2.41393 33.4222 6.69781 33.4222 12.0187C33.4222 17.3395 37.7061 21.6234 43.027 21.6234C48.3478 21.6234 52.6317 17.3395 52.6317 12.0187C52.6317 6.69781 48.3478 2.41393 43.027 2.41393Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_13'
            d='M32.2309 53.1398C31.7209 53.1398 31.2109 52.6298 31.2109 52.1198V44.0621C31.2109 43.5521 31.7209 43.0251 32.2309 43.0251C32.7409 43.0251 33.2679 43.5351 33.2679 44.0621V52.1198C33.4379 52.6298 32.9279 53.1398 32.2309 53.1398Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_14'
            d='M53.6489 53.1398C53.1389 53.1398 52.6289 52.6298 52.6289 52.1198V44.0621C52.6289 43.5521 53.1389 43.0251 53.6489 43.0251C54.1589 43.0251 54.6859 43.5351 54.6859 44.0621V52.1198C54.6859 52.6298 54.3459 53.1398 53.6489 53.1398Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_15'
            d='M43.0267 42.3468C42.6867 42.3468 42.5167 42.1768 42.3467 42.0068L36.8559 36.686C36.5159 36.346 36.5159 36.006 36.6859 35.479L42.0067 22.1174C42.1767 21.7774 42.5167 21.4374 43.0267 21.4374C43.5367 21.4374 43.8767 21.7774 44.0637 22.1174L49.3675 35.479C49.5375 35.819 49.3675 36.329 49.1975 36.686L43.8937 42.0068C43.5537 42.3468 43.2137 42.3468 43.0437 42.3468H43.0267ZM38.9128 35.666L43.0267 39.7799L47.1406 35.666L43.0267 25.3813L38.9128 35.666Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_16'
            d='M62.7468 53.1403H23.1549C22.6449 53.1403 22.3049 52.8003 22.1349 52.4603C21.0979 49.7234 20.418 46.9694 20.418 44.0625C20.418 31.7209 30.5327 21.6061 42.8743 21.6061C55.216 21.6061 65.3307 31.7209 65.3307 44.0625C65.3307 46.9694 64.8207 49.7234 63.6138 52.4603C63.6138 52.8003 63.2738 53.1403 62.7638 53.1403H62.7468ZM24.0049 50.9133H62.2198C63.0698 48.6864 63.4268 46.2895 63.4268 44.0625C63.4268 32.9278 54.349 23.8331 43.1973 23.8331C32.0456 23.8331 22.9679 32.9108 22.9679 44.0625C22.6279 46.4594 23.1379 48.6864 23.9879 50.9133H24.0049Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_17'
            d='M26.3999 88.4491H6.00044C5.49046 88.4491 4.98047 87.9391 4.98047 87.4121V72.3336C4.98047 71.8236 5.49046 71.3136 6.00044 71.3136H26.3999C26.9098 71.3136 27.4198 71.8236 27.4198 72.3336V87.4121C27.4198 87.9221 26.9098 88.4491 26.3999 88.4491ZM7.03741 86.2222H25.2099V73.3705H7.03741V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_18'
            d='M26.3999 78.8602H6.00044C5.49046 78.8602 4.98047 78.3502 4.98047 77.8232C4.98047 77.2962 5.49046 76.8032 6.00044 76.8032H26.3999C26.9098 76.8032 27.4198 77.3132 27.4198 77.8232C27.4198 78.3332 26.9098 78.8602 26.3999 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_19'
            d='M53.1395 88.4491H32.7401C32.2301 88.4491 31.7031 87.9391 31.7031 87.4121V72.3336C31.7031 71.8236 32.2131 71.3136 32.7401 71.3136H53.1395C53.6495 71.3136 54.1595 71.8236 54.1595 72.3336V87.4121C54.1595 87.9221 53.6495 88.4491 53.1395 88.4491ZM33.7771 86.2222H51.9496V73.3705H33.7771V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_20'
            d='M53.1395 78.8602H32.7401C32.2301 78.8602 31.7031 78.3502 31.7031 77.8232C31.7031 77.2962 32.2131 76.8032 32.7401 76.8032H53.1395C53.6495 76.8032 54.1595 77.3132 54.1595 77.8232C54.1595 78.3332 53.6495 78.8602 53.1395 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_21'
            d='M79.8817 88.4491H59.4823C58.9723 88.4491 58.4453 87.9391 58.4453 87.4121V72.3336C58.4453 71.8236 58.9553 71.3136 59.4823 71.3136H79.8817C80.3917 71.3136 80.9187 71.8236 80.9187 72.3336V87.4121C80.9187 87.9221 80.5787 88.4491 79.8817 88.4491ZM60.6892 86.2222H78.8617V73.3705H60.6892V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_22'
            d='M79.8817 78.8602H59.4823C58.9723 78.8602 58.4453 78.3502 58.4453 77.8232C58.4453 77.2962 58.9553 76.8032 59.4823 76.8032H79.8817C80.3917 76.8032 80.9187 77.3132 80.9187 77.8232C80.9187 78.3332 80.5787 78.8602 79.8817 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_23'
            d='M43.0239 73.3706C42.5139 73.3706 42.0039 72.8606 42.0039 72.3506V51.9512C42.0039 51.4412 42.5139 50.9142 43.0239 50.9142C43.5339 50.9142 44.0609 51.4242 44.0609 51.9512V72.3506C44.0609 73.0306 43.5509 73.3706 43.0239 73.3706Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_24'
            d='M69.7661 73.37C69.2561 73.37 68.7461 72.86 68.7461 72.3501V61.5554H17.3226V72.3501C17.3226 72.86 16.8126 73.37 16.3026 73.37C15.7926 73.37 15.2656 72.86 15.2656 72.3501V60.5184C15.2656 60.0084 15.7756 59.4984 16.3026 59.4984H69.9531C70.4631 59.4984 70.9901 60.0084 70.9901 60.5184V72.3501C70.8201 73.03 70.3101 73.37 69.7831 73.37H69.7661Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_25'
            d='M7.54779 31.7219C3.4339 31.7219 0 28.288 0 24.1741C0 20.0602 3.4339 16.6263 7.54779 16.6263C11.6617 16.6263 15.0956 20.0602 15.0956 24.1741C15.0956 28.288 11.6617 31.7219 7.54779 31.7219ZM7.54779 18.8702C4.64087 18.8702 2.24394 21.2671 2.24394 24.1911C2.24394 27.115 4.64087 29.5119 7.54779 29.5119C10.4547 29.5119 12.8686 27.115 12.8686 24.1911C12.8686 21.2671 10.4717 18.8702 7.54779 18.8702Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_26'
            d='M80.3899 27.4369C75.069 27.4369 70.7852 23.153 70.7852 17.8321C70.7852 12.5113 75.069 8.22742 80.3899 8.22742C85.7107 8.22742 89.9946 12.5113 89.9946 17.8321C90.1646 22.966 85.7107 27.4369 80.3899 27.4369ZM80.3899 10.3014C76.276 10.3014 72.8421 13.7353 72.8421 17.8491C72.8421 21.963 76.276 25.3969 80.3899 25.3969C84.5038 25.3969 87.9377 21.963 87.9377 17.8491C87.9377 13.7353 84.5038 10.3014 80.3899 10.3014Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_27'
            d='M80.392 9.09406C85.1178 9.09406 88.9597 12.9359 88.9597 17.6618C88.9597 22.3877 85.1178 26.2296 80.392 26.2296C75.6661 26.2296 71.8242 22.3877 71.8242 17.6618C71.8242 12.9359 75.6661 9.09406 80.392 9.09406Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_28'
            d='M7.54988 17.6801C11.1538 17.6801 14.0607 20.587 14.0607 24.1909C14.0607 27.7948 11.1538 30.7017 7.54988 30.7017C3.94598 30.7017 1.03906 27.7778 1.03906 24.1909C1.03906 20.604 3.96298 17.6801 7.54988 17.6801Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_29'
            d='M43.0265 22.6442L48.3474 36.0058L43.0265 41.3266L37.7227 36.0058L43.0265 22.6442Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_30'
            d='M32.2302 51.95H23.1524C22.1325 49.553 21.6055 46.8161 21.6055 43.8922C21.6055 32.0605 31.2102 22.4728 43.0249 22.4728L37.721 35.8344L43.0249 41.1553L48.3457 35.8344L43.0249 22.4728C54.8565 22.4728 64.4443 32.0775 64.4443 43.8922C64.4443 46.7991 63.9343 49.383 62.8973 51.95H32.2132H32.2302Z'
            fill='white'
          />
          <path
            id='Vector_31'
            d='M43.0275 1.22345C48.8584 1.22345 53.6522 6.01731 53.6522 11.8482C53.6522 17.679 48.8584 22.4729 43.0275 22.4729C37.1967 22.4729 32.4028 17.679 32.4028 11.8482C32.2328 6.01731 37.0267 1.22345 43.0275 1.22345Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_32'
            d='M79.8799 72.3489V77.6528H59.4805V72.3489H69.7652H79.8799Z'
            fill='white'
          />
          <path
            id='Vector_33'
            d='M53.1416 72.3489V77.6528H32.7422V72.3489H43.0269H53.1416Z'
            fill='white'
          />
          <path
            id='Vector_34'
            d='M26.3994 72.3489V77.6528H6V72.3489H16.1147H26.3994Z'
            fill='white'
          />
          <path
            id='Vector_35'
            d='M79.8799 77.6531H59.4805V87.2579H79.8799V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_36'
            d='M53.1416 77.6531H32.7422V87.2579H53.1416V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_37'
            d='M26.3994 77.6531H6V87.2579H26.3994V77.6531Z'
            fill='#D0EAFE'
          />
          <path
            id='Vector_38'
            d='M43.027 23.6633C36.5162 23.6633 31.1953 18.3425 31.1953 11.8317C31.1953 5.32085 36.4992 0 43.027 0C49.5548 0 54.8586 5.32085 54.8586 11.8317C54.6886 18.3425 49.5548 23.6633 43.027 23.6633ZM43.027 2.41393C37.7231 2.41393 33.4222 6.69781 33.4222 12.0187C33.4222 17.3395 37.7061 21.6234 43.027 21.6234C48.3478 21.6234 52.6317 17.3395 52.6317 12.0187C52.6317 6.69781 48.3478 2.41393 43.027 2.41393Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_39'
            d='M32.2309 53.1398C31.7209 53.1398 31.2109 52.6298 31.2109 52.1198V44.0621C31.2109 43.5521 31.7209 43.0251 32.2309 43.0251C32.7409 43.0251 33.2679 43.5351 33.2679 44.0621V52.1198C33.4379 52.6298 32.9279 53.1398 32.2309 53.1398Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_40'
            d='M53.6489 53.1398C53.1389 53.1398 52.6289 52.6298 52.6289 52.1198V44.0621C52.6289 43.5521 53.1389 43.0251 53.6489 43.0251C54.1589 43.0251 54.6859 43.5351 54.6859 44.0621V52.1198C54.6859 52.6298 54.3459 53.1398 53.6489 53.1398Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_41'
            d='M43.0267 42.3468C42.6867 42.3468 42.5167 42.1768 42.3467 42.0068L36.8559 36.686C36.5159 36.346 36.5159 36.006 36.6859 35.479L42.0067 22.1174C42.1767 21.7774 42.5167 21.4374 43.0267 21.4374C43.5367 21.4374 43.8767 21.7774 44.0637 22.1174L49.3675 35.479C49.5375 35.819 49.3675 36.329 49.1975 36.686L43.8937 42.0068C43.5537 42.3468 43.2137 42.3468 43.0437 42.3468H43.0267ZM38.9128 35.666L43.0267 39.7799L47.1406 35.666L43.0267 25.3813L38.9128 35.666Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_42'
            d='M62.7468 53.1403H23.1549C22.6449 53.1403 22.3049 52.8003 22.1349 52.4603C21.0979 49.7234 20.418 46.9694 20.418 44.0625C20.418 31.7209 30.5327 21.6061 42.8743 21.6061C55.216 21.6061 65.3307 31.7209 65.3307 44.0625C65.3307 46.9694 64.8207 49.7234 63.6138 52.4603C63.6138 52.8003 63.2738 53.1403 62.7638 53.1403H62.7468ZM24.0049 50.9133H62.2198C63.0698 48.6864 63.4268 46.2895 63.4268 44.0625C63.4268 32.9278 54.349 23.8331 43.1973 23.8331C32.0456 23.8331 22.9679 32.9108 22.9679 44.0625C22.6279 46.4594 23.1379 48.6864 23.9879 50.9133H24.0049Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_43'
            d='M26.3999 88.4491H6.00044C5.49046 88.4491 4.98047 87.9391 4.98047 87.4121V72.3336C4.98047 71.8236 5.49046 71.3136 6.00044 71.3136H26.3999C26.9098 71.3136 27.4198 71.8236 27.4198 72.3336V87.4121C27.4198 87.9221 26.9098 88.4491 26.3999 88.4491ZM7.03741 86.2222H25.2099V73.3705H7.03741V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_44'
            d='M26.3999 78.8602H6.00044C5.49046 78.8602 4.98047 78.3502 4.98047 77.8232C4.98047 77.2962 5.49046 76.8032 6.00044 76.8032H26.3999C26.9098 76.8032 27.4198 77.3132 27.4198 77.8232C27.4198 78.3332 26.9098 78.8602 26.3999 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_45'
            d='M53.1395 88.4491H32.7401C32.2301 88.4491 31.7031 87.9391 31.7031 87.4121V72.3336C31.7031 71.8236 32.2131 71.3136 32.7401 71.3136H53.1395C53.6495 71.3136 54.1595 71.8236 54.1595 72.3336V87.4121C54.1595 87.9221 53.6495 88.4491 53.1395 88.4491ZM33.7771 86.2222H51.9496V73.3705H33.7771V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_46'
            d='M53.1395 78.8602H32.7401C32.2301 78.8602 31.7031 78.3502 31.7031 77.8232C31.7031 77.2962 32.2131 76.8032 32.7401 76.8032H53.1395C53.6495 76.8032 54.1595 77.3132 54.1595 77.8232C54.1595 78.3332 53.6495 78.8602 53.1395 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_47'
            d='M79.8817 88.4491H59.4823C58.9723 88.4491 58.4453 87.9391 58.4453 87.4121V72.3336C58.4453 71.8236 58.9553 71.3136 59.4823 71.3136H79.8817C80.3917 71.3136 80.9187 71.8236 80.9187 72.3336V87.4121C80.9187 87.9221 80.5787 88.4491 79.8817 88.4491ZM60.6892 86.2222H78.8617V73.3705H60.6892V86.2222Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_48'
            d='M79.8817 78.8602H59.4823C58.9723 78.8602 58.4453 78.3502 58.4453 77.8232C58.4453 77.2962 58.9553 76.8032 59.4823 76.8032H79.8817C80.3917 76.8032 80.9187 77.3132 80.9187 77.8232C80.9187 78.3332 80.5787 78.8602 79.8817 78.8602Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_49'
            d='M43.0239 73.3706C42.5139 73.3706 42.0039 72.8606 42.0039 72.3506V51.9512C42.0039 51.4412 42.5139 50.9142 43.0239 50.9142C43.5339 50.9142 44.0609 51.4242 44.0609 51.9512V72.3506C44.0609 73.0306 43.5509 73.3706 43.0239 73.3706Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_50'
            d='M69.7661 73.37C69.2561 73.37 68.7461 72.86 68.7461 72.3501V61.5554H17.3226V72.3501C17.3226 72.86 16.8126 73.37 16.3026 73.37C15.7926 73.37 15.2656 72.86 15.2656 72.3501V60.5184C15.2656 60.0084 15.7756 59.4984 16.3026 59.4984H69.9531C70.4631 59.4984 70.9901 60.0084 70.9901 60.5184V72.3501C70.8201 73.03 70.3101 73.37 69.7831 73.37H69.7661Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_51'
            d='M7.54779 31.7219C3.4339 31.7219 0 28.288 0 24.1741C0 20.0602 3.4339 16.6263 7.54779 16.6263C11.6617 16.6263 15.0956 20.0602 15.0956 24.1741C15.0956 28.288 11.6617 31.7219 7.54779 31.7219ZM7.54779 18.8702C4.64087 18.8702 2.24394 21.2671 2.24394 24.1911C2.24394 27.115 4.64087 29.5119 7.54779 29.5119C10.4547 29.5119 12.8686 27.115 12.8686 24.1911C12.8686 21.2671 10.4717 18.8702 7.54779 18.8702Z'
            fill='#63AFF4'
          />
          <path
            id='Vector_52'
            d='M80.3899 27.4369C75.069 27.4369 70.7852 23.153 70.7852 17.8321C70.7852 12.5113 75.069 8.22742 80.3899 8.22742C85.7107 8.22742 89.9946 12.5113 89.9946 17.8321C90.1646 22.966 85.7107 27.4369 80.3899 27.4369ZM80.3899 10.3014C76.276 10.3014 72.8421 13.7353 72.8421 17.8491C72.8421 21.963 76.276 25.3969 80.3899 25.3969C84.5038 25.3969 87.9377 21.963 87.9377 17.8491C87.9377 13.7353 84.5038 10.3014 80.3899 10.3014Z'
            fill='#63AFF4'
          />
        </g>
      </g>
    </svg>
  )
}
