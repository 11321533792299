import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'

export const useTagSettings = (clearAllHandler, setShowAllTagsModal) => {
  const { t } = useTranslation()

  const onClickHandler = (e, callBack) => {
    e.domEvent.stopPropagation()
    callBack && callBack()
  }

  const clickHandler = (e) => {
    e.stopPropagation()
  }

  const viewHandler = () => {
    setShowAllTagsModal(true)
  }

  const menu = (
    <Menu className='tags__settings__container'>
      <Menu.Item
        onClick={(e) => onClickHandler(e, viewHandler)}
        className=' '
        key='1'
      >
        {t('view_all_tags')}
      </Menu.Item>

      <Menu.Item
        className=' '
        onClick={(e) => onClickHandler(e, clearAllHandler)}
        key='2'
      >
        {t('clear-all')}
      </Menu.Item>
    </Menu>
  )

  return { menu, clickHandler }
}
