import { Notifications, NotificationIcon, NavigationLink } from './components'
import { getProfilePictureSrc, showSettings, isSupplier } from 'src/utils'
import { useSideNavigation } from './useSideNavigation'
import { AiOutlineUser } from 'react-icons/ai'
import {
  SalesPipelineIcon,
  ReportingIcon,
  PlanningIcon,
  SettingsIcon,
  SideBarOPen,
  LogoutIcon,
  HomeIcon,
} from 'src/assets'

const SideNavigation = () => {
  const {
    fetchNotificationsOnScroll,
    toggleSideNavigationState,
    handleNotificationClick,
    isNotificationsLoading,
    isSolarPlanningEnabled,
    notificationParentRef,
    unreadNotifications,
    closeSideNavigation,
    toggleNotification,
    isReportingEnabled,
    isNotificationOpen,
    notifications,
    logoutHandler,
    windowHeight,
    currentPath,
    sideBarRef,
    open,
    user,
    t,
  } = useSideNavigation()

  return (
    <div
      className={`sideNavigation ${open ? `sideNavigation--opened` : ''}`}
      onMouseLeave={closeSideNavigation}
      ref={sideBarRef}
    >
      {isNotificationOpen ? (
        <Notifications
          fetchNotificationsOnScroll={fetchNotificationsOnScroll}
          handleNotificationClick={handleNotificationClick}
          isNotificationsLoading={isNotificationsLoading}
          notificationParentRef={notificationParentRef}
          toggleNotification={toggleNotification}
          notifications={notifications}
        />
      ) : null}
      <NavigationLink logoLink={true} />

      <div className='dummy__div' />

      <div
        className='sideNavigation__iconsWrapper'
        style={{ gap: windowHeight > 600 ? '3rem' : '0.5rem' }}
      >
        {!open ? (
          <div onClick={toggleSideNavigationState} className='open-button'>
            <SideBarOPen />
          </div>
        ) : null}
        <NavigationLink
          className={user?.avatar?.name && 'user__navItem'}
          linkClassName='user__navItem'
          imageSrc={getProfilePictureSrc(user || '')}
          Icon={AiOutlineUser}
          title={user?.name}
          uri='/profile'
        />

        <div
          className={`notifications__navItem ${
            isNotificationOpen && 'notifications__navItem--active'
          }`}
        >
          <NotificationIcon
            unreadNotifications={unreadNotifications}
            toggleNotification={toggleNotification}
          />

          <p
            onClick={toggleNotification}
            className='title slide-in-left-animation'
          >
            {t('notifications')}
          </p>
        </div>

        {isReportingEnabled ? (
          <NavigationLink
            linkClassName='reporting__navItem'
            Icon={ReportingIcon}
            title='reporting'
            uri='/reporting'
          />
        ) : null}

        {isSolarPlanningEnabled ? (
          <NavigationLink
            linkClassName='planning__navItem'
            title='planning_pipeline'
            Icon={PlanningIcon}
            uri='/planning'
          />
        ) : null}

        {!isSupplier() && (
          <NavigationLink
            linkClassName='sales__navItem'
            Icon={SalesPipelineIcon}
            title='sales_pipeline'
            uri='/sales-projects'
            styles={{
              paddingLeft: '2.6rem',
            }}
          />
        )}

        {showSettings() ? (
          <NavigationLink
            linkClassName='settings__navItem'
            Icon={SettingsIcon}
            title='settings'
            uri='/manage'
          />
        ) : null}

        <NavigationLink
          linkClassName='logout__link__mobile'
          onClick={logoutHandler}
          uri={currentPath}
          Icon={LogoutIcon}
          title='log_out'
        />

        <NavigationLink
          linkClassName='dashboard__navItem--mobile'
          title='dashboard'
          Icon={HomeIcon}
          uri='/'
        />
      </div>

      <NavigationLink
        linkClassName='logout__link__desktop'
        className='logout__navItem'
        onClick={logoutHandler}
        uri={currentPath}
        Icon={LogoutIcon}
        title='log_out'
      />
    </div>
  )
}

export default SideNavigation
