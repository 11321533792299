import QuotationInfoBoxes from './QuotationInfoBoxes'
import { useInfoBoxes } from './useInfoBoxes'

const InfoBoxes = (props) => {
  const {
    house_number,
    phoneNumber,
    location,
    zipcode,
    street,
    email,
    name,
    t,
  } = useInfoBoxes(props)

  return (
    <div className='client__and__manager__boxes'>
      <QuotationInfoBoxes
        title={t('client_address')}
        firstFieldData={{
          name: t('address'),
          value: `${street} ${house_number}`,
        }}
        secondFieldData={{
          name: t('zipcode'),
          value: zipcode,
        }}
        thirdFieldData={{
          name: t('city'),
          value: location,
        }}
      />

      <QuotationInfoBoxes
        title={t('account_manager')}
        firstFieldData={{
          name: t('name'),
          value: name,
        }}
        secondFieldData={{
          name: t('email'),
          value: email,
        }}
        thirdFieldData={{
          name: t('phone_number'),
          value: phoneNumber,
        }}
      />
    </div>
  )
}

export default InfoBoxes
