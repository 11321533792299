import { useMutation, useQuery, useQueryClient } from 'react-query'
import { downloadFile, canPerform } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { message } from 'antd'
import {
  importActivityTypesRequest,
  deleteActivityTypeRequest,
  allActivityTypesRequest,
  exportRequest,
} from 'src/services'
import { usePageQueryParams } from 'src/hooks'

export const useTypesActivities = () => {
  const queryClient = useQueryClient()
  const [importError, setImportError] = useState()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const { page, size, handlePageChange } = usePageQueryParams(
    'type-page',
    'type-size'
  )

  const [deleteActivityTypes, setDeleteActivityTypes] = useState({
    show: false,
    data: {},
  })

  const [showTypesModal, setShowTypesModal] = useState({
    show: false,
    data: {},
  })

  const [showAddTypeModal, setShowAddTypeModal] = useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const {
    data: activityTypesData,
    isLoading: isActivityTypesDataLoading,
    isPreviousData,
  } = useQuery(
    ['activity_types', page, size, user?.company?.id],
    () =>
      allActivityTypesRequest({
        page,
        size,
        companyId: user?.company?.id,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { mutate: deleteActivityTypeMutation, isLoading: deleting } =
    useMutation(deleteActivityTypeRequest, {
      onSuccess: (resp) => {
        if (resp.data.success) {
          queryClient.refetchQueries('activity_types')
          setDeleteActivityTypes({ show: false, data: {} })
        }
      },
      onError: () => {
        message.error({
          content: 'This type is used on the item',
          duration: 2,
        })
      },
    })

  const { mutate: importActivityTypes, isLoading: importing } = useMutation(
    importActivityTypesRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('activity_types')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportActivityTypesMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    })

  function exportActivityTypes() {
    exportActivityTypesMutation({
      model: 'activity_types',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage activity-type') ||
          canPerform('manage own activity-type')
        ) {
          setShowTypesModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    isActivityTypesDataLoading: isActivityTypesDataLoading || isPreviousData,
    paginationInfo: {
      total: activityTypesData?.data?.meta?.total,
      page,
    },
    activityTypes: activityTypesData?.data?.data,
    deleteActivityTypeMutation,
    setDeleteActivityTypes,
    setShowImportCsvModal,
    setShowAddTypeModal,
    importActivityTypes,
    exportActivityTypes,
    deleteActivityTypes,
    showImportCsvModal,
    setShowTypesModal,
    handlePageChange,
    showAddTypeModal,
    showTypesModal,
    setImportError,
    importError,
    importing,
    exporting,
    deleting,
    onRow,
    t,
  }
}
