export const TopRightArrowIcon = () => {
  return (
    <svg width='8' height='8' viewBox='0 0 8 8' fill='none'>
      <path
        id='Vector'
        d='M-3.12032e-07 7.13846L5.90769 1.23077L0.615384 1.23077L0.615384 -3.22792e-07L8 0L8 7.38462L6.76923 7.38462L6.76923 2.09231L0.861538 8L-3.12032e-07 7.13846Z'
        fill='#1F73E2'
      />
    </svg>
  )
}
