import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const useFusebox = (getStepFiles, setFieldsValue) => {
  const [fileList, setFileList] = useState(getStepFiles(6))

  useEffect(() => {
    setFieldsValue({
      fusebox_pictures: fileList,
    })
  }, [fileList])

  const { t } = useTranslation()

  const uploadChangeHandler = (info) => {
    setFileList(info?.fileList)
  }

  return { t, fileList, uploadChangeHandler }
}
