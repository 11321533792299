import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import TeamMultiselectContainer from './TeamMultiselectContainer'
import { useTaskMultiselect } from '../../hooks'

function TaskMultiselect({ label }) {
  const { t } = useTranslation()
  const teams = [
    { id: 1, team: 'Team 1' },
    { id: 2, team: 'Team 2' },
    { id: 3, team: 'Team 3' },
    { id: 4, team: 'Team 4' },
    { id: 5, team: 'Team 5' },
    { id: 6, team: 'Team 6' },
    { id: 7, team: 'Team 7' },
    { id: 8, team: 'Team 8' },
    { id: 9, team: 'Team 9' },
    { id: 10, team: 'Team 10' },
  ]

  const employees = [
    { id: 1, employee: 'Jhon' },
    { id: 2, employee: 'Employee 2' },
    { id: 3, employee: 'Employee 3' },
    { id: 4, employee: 'Employee 4' },
    { id: 5, employee: 'Employee 5' },
    { id: 6, employee: 'Employee 6' },
    { id: 7, employee: 'Employee 7' },
    { id: 8, employee: 'Employee 8' },
    { id: 9, employee: 'Employee 9' },
    { id: 10, employee: 'Employee 10' },
  ]

  const {
    isSelectOpen,
    isTeamContainerOpen,
    isEmployeeContainerOpen,
    setIsSelectOpen,
    handleSelectOptions,
    handleTeamMultiselect,
    handleEmployeeMultiselect,
  } = useTaskMultiselect()

  return (
    <div className='task_multiselect_group'>
      <label>{label}</label>
      <div className='task_multiselect_header' onClick={handleSelectOptions}>
        <div className='task_multiselect_header_placeholder'>{label}</div>
        <div>
          <DownOutlined
            className={`task_multiselect_down_outline ${
              isSelectOpen ? 'task_multiselect_open_down_outlined' : ''
            }`}
          />
        </div>
      </div>
      {isSelectOpen ? (
        <div className='task_multiselect_content'>
          <div className='task_multiselect_sidebar'>
            <button
              onClick={handleTeamMultiselect}
              className={`${
                isTeamContainerOpen ? 'multiselect_open_active' : ''
              }`}
            >
              Team{' '}
              <DownOutlined
                className={`multiselect_down_outline ${
                  isTeamContainerOpen ? 'multiselect_open_down_outlined' : ''
                }`}
              />
            </button>
            <button
              onClick={handleEmployeeMultiselect}
              className={`${
                isEmployeeContainerOpen ? 'multiselect_open_active' : ''
              }`}
            >
              Employee{' '}
              <DownOutlined
                className={`multiselect_down_outline ${
                  isEmployeeContainerOpen
                    ? 'multiselect_open_down_outlined'
                    : ''
                }`}
              />
            </button>
          </div>
          {isTeamContainerOpen ? (
            <TeamMultiselectContainer
              teams={teams}
              handleSelectOptions={handleSelectOptions}
              isSelectOpen={isSelectOpen}
              setIsSelectOpen={setIsSelectOpen}
              searchableKey='team'
              headerTitle={`${t('overall')} ${teams.length} ${t('team')}`}
              searchPlaceholder={t('search_team')}
            />
          ) : null}
          {isEmployeeContainerOpen ? (
            <TeamMultiselectContainer
              teams={employees}
              handleSelectOptions={handleSelectOptions}
              isSelectOpen={isSelectOpen}
              setIsSelectOpen={setIsSelectOpen}
              searchableKey='employee'
              headerTitle={`${t('overall')} ${employees.length} ${t(
                'employee'
              )}`}
              searchPlaceholder={t('search_employee')}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default TaskMultiselect
