import { ProjectDetailsContainer, TasksContainer } from './components'

function PlaningDetails() {
  return (
    <div className='project_details_wrap'>
      <ProjectDetailsContainer />
      <TasksContainer />
    </div>
  )
}

export default PlaningDetails
