import { useOpenProjects } from './components/OpenProjects/useOpenProjects'
import { useAllProjects } from './components/AllProjects/useAllProjects'
import { useHomeScreen } from './useHomeScreen'
import { Pagination, Spin } from 'antd'
import { canPerform } from 'src/utils'
import {
  ChangeProjectCategoryButton,
  FilterAndSortSection,
  NewProjectButtonLink,
  HomeScreenHeader,
  LeadsButton,
  OpenProjects,
  AllProjects,
} from './components'

const HomeScreen = () => {
  const {
    startNewProjectMutation,
    disabledStartNewProject,
    handleOpenProjectClick,
    isOpenProjectTabActive,
    handleAllProjectClick,
    isNewLeadsEnabled,
    statusFiltersForm,
    setTicketView,
    managersList,
    ticketView,
    managerIds,
    statusIds,
    isLoading,
    t,
  } = useHomeScreen()

  const {
    allProjectsSearchHandler,
    handleTableChange,
    isProjectLoading,
    handlePageChange,
    paginationInfo,
    archiveProject,
    managersFilter,
    deleteProject,
    statusFilter,
    project,
    onRow,
  } = useAllProjects(statusIds, managerIds)

  const {
    handlePageChange: handleOpenProjectChange,
    paginationInfo: openProjectPaginationInfo,
    handleTableChange: handleOpenTableChange,
    isProjectLoading: isOpenProjectLoading,
    openProjectsSearchHandler,
    onRow: onOpenProjectRow,
    project: openProjects,
  } = useOpenProjects(statusIds, managerIds)

  return (
    <div className='homescreen'>
      {isLoading ? (
        <Spin
          style={{
            marginTop: '5rem',
          }}
        />
      ) : (
        <>
          <HomeScreenHeader
            disabledStartNewProject={disabledStartNewProject}
            startNewProjectMutation={startNewProjectMutation}
          />

          <div className='homescreen__project-creation--mobile'>
            <p>{t('projects')}</p>
            <NewProjectButtonLink />
          </div>

          <FilterAndSortSection
            openProjectsSearchHandler={openProjectsSearchHandler}
            allProjectsSearchHandler={allProjectsSearchHandler}
            statusFiltersForm={statusFiltersForm}
            paginationInfo={paginationInfo}
            managersList={managersList}
            managerIds={managerIds}
            statusIds={statusIds}
          />

          {canPerform('view project') || canPerform('view own project') ? (
            <div className='homescreen__contents'>
              <div className='homescreen__contents__table__switcher'>
                <div className='homescreen__contents__categories'>
                  <ChangeProjectCategoryButton
                    clickHandler={handleOpenProjectClick}
                    isActive={isOpenProjectTabActive}
                    title={t('home_open_projects')}
                    count={openProjectPaginationInfo?.total}
                  />
                  <ChangeProjectCategoryButton
                    clickHandler={handleAllProjectClick}
                    isActive={!isOpenProjectTabActive}
                    title={t('all_projects')}
                    count={paginationInfo.total}
                  />
                </div>

                {isNewLeadsEnabled ? <LeadsButton /> : null}
              </div>

              {isOpenProjectTabActive ? (
                <OpenProjects
                  handleOpenTableChange={handleOpenTableChange}
                  handlePageChange={handleOpenProjectChange}
                  paginationInfo={openProjectPaginationInfo}
                  isProjectLoading={isOpenProjectLoading}
                  archiveProject={archiveProject}
                  setTicketView={setTicketView}
                  deleteProject={deleteProject}
                  onRow={onOpenProjectRow}
                  ticketView={ticketView}
                  project={openProjects}
                  translate={t}
                />
              ) : (
                <AllProjects
                  handleTableChange={handleTableChange}
                  isProjectLoading={isProjectLoading}
                  handlePageChange={handlePageChange}
                  paginationInfo={paginationInfo}
                  archiveProject={archiveProject}
                  managersFilter={managersFilter}
                  deleteProject={deleteProject}
                  setTicketView={setTicketView}
                  statusFilter={statusFilter}
                  ticketView={ticketView}
                  project={project}
                  onRow={onRow}
                  t={t}
                />
              )}

              <Pagination
                className='mobile__pagination'
                current={
                  isOpenProjectTabActive
                    ? openProjectPaginationInfo?.page
                    : paginationInfo?.page
                }
                total={
                  isOpenProjectTabActive
                    ? openProjectPaginationInfo?.total
                    : paginationInfo?.total
                }
                onChange={
                  isOpenProjectTabActive
                    ? handleOpenProjectChange
                    : handlePageChange
                }
                simple
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default HomeScreen
