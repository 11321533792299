import { Form, Input } from 'antd'

const TextAreaInput = ({ className = '', name, text }) => {
  return (
    <Form.Item name={name}>
      <Input.TextArea className={className} />
    </Form.Item>
  )
}

export default TextAreaInput
