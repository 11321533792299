import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

const ModalButtons = ({
  actionButtonTitle,
  isLoading = false,
  submitHandler,
  closeHandler,
}) => {
  const { t } = useTranslation()

  return (
    <div className='modalContent__button'>
      <Button
        className='modalContent__button__child'
        onClick={closeHandler}
        type='secondary'
      >
        {t('cancel')}
      </Button>
      <Button
        className='modalContent__button__child'
        onClick={submitHandler}
        disabled={isLoading}
        type='primary'
      >
        {actionButtonTitle}
      </Button>
    </div>
  )
}

export default ModalButtons
