import { useTranslation } from 'react-i18next'
import { CustomModal } from 'src/common'
import { Tags } from '../Tags'
import { Button } from 'antd'

const AllTagsModal = ({
  setShowAllTagsModal,
  accountManagersForm,
  statusFiltersForm,
  showAllTagsModal,
  clearAllHandler,
  findStatusCount,
  managersList,
  managerIds,
  statusIds,
  statuses,
}) => {
  const { t } = useTranslation()

  return (
    <CustomModal
      close={() => setShowAllTagsModal(false)}
      show={showAllTagsModal}
      zIndex={9999}
    >
      <>
        <div className='view__all__tags__container'>
          <Tags
            accountManagersForm={accountManagersForm}
            statusFiltersForm={statusFiltersForm}
            findStatusCount={findStatusCount}
            managersList={managersList}
            managerIds={managerIds}
            statusIds={statusIds}
            statuses={statuses}
          />
        </div>

        <Button
          className='all__tags__modal__clear__btn'
          onClick={clearAllHandler}
          type='text'
        >
          {t('clear-all')}
        </Button>
      </>
    </CustomModal>
  )
}

export default AllTagsModal
