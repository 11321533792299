import TypesTableColumn from '../../ActivitiesTables/TypesTableColumn'
import ShowActivityType from './ShowActivityType'
import AddActivityType from './AddActivityType'
import { useTypesActivities } from './hooks'
import { canPerform } from 'src/utils'
import { Table, Space } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
} from 'src/common'

const TypesActivities = () => {
  const {
    deleteActivityTypeMutation,
    isActivityTypesDataLoading,
    setDeleteActivityTypes,
    setShowImportCsvModal,
    importActivityTypes,
    exportActivityTypes,
    deleteActivityTypes,
    setShowAddTypeModal,
    showImportCsvModal,
    setShowTypesModal,
    handlePageChange,
    showAddTypeModal,
    showTypesModal,
    paginationInfo,
    setImportError,
    activityTypes,
    importError,
    importing,
    exporting,
    deleting,
    onRow,
    t,
  } = useTypesActivities()

  return (
    <>
      <div className='manageScreensLayout__title'>{t('types')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Space>
            <ButtonWithPlus
              disabled={
                canPerform('manage activity-type') ||
                canPerform('manage own activity-type')
                  ? false
                  : true
              }
              onClick={() => setShowAddTypeModal(true)}
              text={t('add_type')}
            />
          </Space>
        </div>

        <Table
          bordered
          scroll={{ x: '992px' }}
          rowKey='id'
          dataSource={activityTypes || []}
          columns={TypesTableColumn(setDeleteActivityTypes, t)}
          onRow={onRow}
          loading={isActivityTypesDataLoading}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
        />

        <div className='contentFooter'>
          <CsvButton
            onClick={exportActivityTypes}
            disabled={exporting || !activityTypes?.length}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage activity-type') ||
              canPerform('manage own activity-type')
                ? false
                : true
            }
            onClick={() => setShowImportCsvModal(true)}
            text={t('import_cvs')}
          />
        </div>
      </div>

      {showImportCsvModal && (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          submitModal={importActivityTypes}
          setImportError={setImportError}
          importError={importError}
          disabled={importing}
        />
      )}

      {deleteActivityTypes.show && (
        <AgreeDeleteModal
          visible={deleteActivityTypes.show}
          targetName={deleteActivityTypes?.data?.name}
          onCancel={() => setDeleteActivityTypes({ show: false, data: {} })}
          onSubmit={() =>
            deleteActivityTypes.data.id &&
            deleteActivityTypeMutation(deleteActivityTypes.data.id)
          }
          disabled={deleting}
        />
      )}

      {showAddTypeModal && (
        <AddActivityType
          setShowAddTypeModal={setShowAddTypeModal}
          show={showAddTypeModal}
        />
      )}

      {showTypesModal.show && (
        <ShowActivityType
          setShowTypesModal={setShowTypesModal}
          currentType={showTypesModal.data}
          show={showTypesModal.show}
        />
      )}
    </>
  )
}

export default TypesActivities
