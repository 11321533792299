import { ButtonWithTrash } from 'src/common'
import { canPerform } from 'src/utils'

const TypesTableColumn = (setDeleteModal, t) => {
  return [
    {
      title: t('name'),
      dataIndex: 'name',
    },

    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          disabled={
            canPerform('manage activity-type') ||
            canPerform('manage own activity-type')
              ? false
              : true
          }
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default TypesTableColumn
