import { memo } from 'react'

const ShowFieldError = ({
  noErrorClassName = '',
  className = '',
  withHeight,
  error = '',
}) => {
  if (error === '' || error === undefined) {
    return withHeight ? (
      <div className={`error-height ${noErrorClassName}`}></div>
    ) : null
  }

  return (
    <div
      className={`ant-form-item-explain ant-form-item-explain-error ${className}`}
    >
      <div role='alert'>{error}</div>
    </div>
  )
}

export default memo(ShowFieldError)
