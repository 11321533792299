import { useTranslation } from 'react-i18next'
import { useMultiselectField } from '../../hooks'
import { MultiselectContainer, MultiselectHeader } from './index'

function MultiselectField({ label, options, searchPlaceholder }) {
  const { t } = useTranslation()

  const {
    isSelectOpen,
    selectedOptions,
    searchQuery,
    setSearchQuery,
    handleSelectOptions,
    handleOptionSelect,
    filteredOptions,
    resetSelectedOptions,
    handleSaveOptions,
  } = useMultiselectField({ options })

  return (
    <div className='project_details_multiselect_group'>
      <label htmlFor='planner'>{t('planner')}</label>
      <MultiselectHeader
        isSelectOpen={isSelectOpen}
        handleOptionSelect={handleOptionSelect}
        selectedOptions={selectedOptions}
        handleSelectOptions={handleSelectOptions}
        label={label}
      />

      {isSelectOpen ? (
        <MultiselectContainer
          options={options}
          labelKey={'employee'}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filteredOptions={filteredOptions}
          selectedOptions={selectedOptions}
          headerTitle={`${t('overall')} ${options.length} ${t('employee')}`}
          handleOptionSelect={handleOptionSelect}
          resetSelectedOptions={resetSelectedOptions}
          handleSelectOptions={handleSelectOptions}
          handleSaveOptions={handleSaveOptions}
          searchPlaceholder={searchPlaceholder}
        />
      ) : null}
    </div>
  )
}

export default MultiselectField
