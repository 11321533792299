import { sendMailToCustomerRequest, templatesInfoRequest } from 'src/services'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { message, Form } from 'antd'
import { useMemo } from 'react'

export const useCustomMessageModal = ({
  headquartersSignatureSrc,
  customerSignatureSrc,
  companyId,
  offerId,
  close,
}) => {
  const HOMEMADE_ENERGY_ID = 106

  const isHomemadeEnergy = useMemo(() => {
    return HOMEMADE_ENERGY_ID === companyId
  }, [companyId])

  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const { data: templatesData, isLoading: isTemplateLoading } = useQuery(
    ['templates', 'offer'],
    () => templatesInfoRequest('offer'),
    {
      enabled: isHomemadeEnergy,
    }
  )

  const {
    mutate: sendMailToCustomerMutation,
    isLoading: isMailSending,
    error,
  } = useMutation(
    ({ offerId, data }) => sendMailToCustomerRequest(offerId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['offer', offerId])
        queryClient.invalidateQueries(['offers', projectId])

        message.success(`${t('mail_sent')}`)

        close()
      },
      onError: (error) => handleNonValidationError(error),
    }
  )

  const sendMailToCustomer = ({
    includeCustomMessage,
    template_id,
    message,
  }) => {
    const type =
      headquartersSignatureSrc && customerSignatureSrc ? 'offer' : 'quotation'

    let data = { type }

    if (includeCustomMessage) {
      data.message = message
    }

    if (isHomemadeEnergy) {
      data.template_id = template_id
    }

    sendMailToCustomerMutation({
      offerId,
      data,
    })
  }

  const homeMadeEnergyTemplates = useMemo(() => {
    return templatesData?.data?.data
      ?.filter((item) => item.company_id === HOMEMADE_ENERGY_ID)
      .map((item) => {
        return { name: item?.name, id: item?.id }
      })
  }, [templatesData])

  return {
    errors: error?.response?.data?.errors,
    homeMadeEnergyTemplates,
    sendMailToCustomer,
    isTemplateLoading,
    isHomemadeEnergy,
    isMailSending,
    form,
    t,
  }
}
