import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updateWifiModuleRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowWifiModule = (currentWifiModule, setShowWifiModule) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const closeModal = () => setShowWifiModule({ show: false, data: {} })

  const {
    isLoading,
    mutate: updateWifiModule,
    error,
  } = useMutation(
    (variables) =>
      !isLoading && updateWifiModuleRequest(currentWifiModule.id, variables),
    {
      onSuccess: (resp) => {
        if (resp.data.success) {
          queryClient.refetchQueries('wifiModule')
          closeModal()
          message.success(t('updated_successfully'))
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (currentWifiModule) {
      form.setFieldsValue(currentWifiModule)
    }
  }, [currentWifiModule, form])

  return {
    updateWifiModuleError: handleValidationError(error),
    currentWifiModule,
    updateWifiModule,
    closeModal,
    isLoading,
    form,
    t,
  }
}
