import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form, message } from 'antd'
import {
  attachWifiModuleToProjectRequest,
  allWifiModulesRequest,
} from 'src/services'
import {
  canManageProject,
  handleNonValidationError,
  handleValidationError,
  isBottom,
} from 'src/utils'

export function useAttachWifiToProject({
  data: wifiModuleData,
  deleteWifi,
  projectCompanyId,
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { md, sm } = useBreakpoint()
  const { t } = useTranslation()
  const layout = {
    labelCol: { span: !md ? 8 : !sm ? 6 : 5 },
    wrapperCol: { span: 20 },
  }

  const { offerId } = useParams()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    if (openConfirmationModal) {
      closeConfirmationModal()
    }
  }, [wifiModuleData])

  const {
    data: wifiModules,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['wifiModule', projectCompanyId],
    ({ pageParam = 1 }) =>
      allWifiModulesRequest({
        page: pageParam,
        companyId: projectCompanyId,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
    }
  )

  const clearForm = () => {
    if (!wifiModuleData) {
      form.resetFields()
      deleteWifi()
    }
    if (
      wifiModuleData &&
      wifiModuleData?.wifi_module_id !== form.getFieldValue('wifi_module_id')
    ) {
      form.resetFields()
    }

    if (wifiModuleData) {
      message.success(`${t('updated_successfully')}`)
    }
  }

  const {
    mutate: addWifiToProject,
    isLoading: isAdding,
    error: addIngError,
  } = useMutation(attachWifiModuleToProjectRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
        clearForm()
      }
    },
    onError: (err) => handleNonValidationError(err),
  })

  function closeConfirmationModal() {
    setShowConfirmationModal(false)
  }

  function openConfirmationModal() {
    setShowConfirmationModal(true)
  }

  const clickHandler = () => {
    if (!canManageProject()) return

    if (wifiModuleData) {
      return openConfirmationModal()
    } else {
      deleteWifi()
    }
  }

  const finishHandler = (val) => {
    if (!canManageProject()) return
    addWifiToProject({ offer_id: offerId, ...val })
  }

  const selectChangeHandler = () => {
    form.setFieldsValue({
      quantity: '',
    })
  }

  function fetchWifiModuleOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return
    fetchNextPage()
  }

  return {
    fetchWifiModuleOnScroll,
    addWifiToProject,
    isAdding,
    error: handleValidationError(addIngError),
    layout,
    wifiModules: wifiModules?.pages,
    closeConfirmationModal,
    openConfirmationModal,
    showConfirmationModal,
    t,
    form,
    clickHandler,
    finishHandler,
    selectChangeHandler,
  }
}
