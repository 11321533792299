import TermsOfAgreementModal from './TermsOfAgreementModal'
import { canManageProject } from 'src/utils'
import { useQuotationAgreement } from './hooks'
import { ShowFieldError } from 'src/common'
import { Checkbox } from 'antd'

const QuotationAgreement = ({
  onAgreementChange,
  value,
  error,
  terms_of_condition_src,
  disabled = false,
}) => {
  const { t, isTermsOfAgreementModalOpen, setIsTermsOfAgreementModalOpen } =
    useQuotationAgreement()

  return (
    <div className='agreement'>
      <div className='agreement__title'>{t('agreement')}</div>
      <div className='agreement__text'>{t('agreement_text')}</div>
      <div className='agreement__link'>
        {canManageProject() && terms_of_condition_src ? (
          <a
            href={terms_of_condition_src}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('terms_of_agreement')}
          </a>
        ) : null}
      </div>
      <Checkbox
        checked={value}
        onChange={(e) => onAgreementChange(e.target.value)}
        value={value}
        className='agreement__checkbox'
        disabled={!canManageProject() || disabled}
      >
        {t('i_agree')}
      </Checkbox>
      <TermsOfAgreementModal
        close={() => {
          setIsTermsOfAgreementModalOpen(false)
        }}
        show={isTermsOfAgreementModalOpen}
      />
      <ShowFieldError withHeight={true} error={error} />
    </div>
  )
}

export default QuotationAgreement
