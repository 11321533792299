import { useState } from 'react'

function useProjectViewModal() {
  const [openProjectViewModal, setOpenProjectViewModal] = useState(false)
  const [modalData, setModalData] = useState([])

  const projectViewModalOnClick = (record = []) => {
    setOpenProjectViewModal(!openProjectViewModal)
    setModalData(record)
  }

  return {
    projectViewModalOnClick,
    modalData,
    openProjectViewModal,
  }
}

export { useProjectViewModal }
