import RenderMaterialsOption from './RenderMaterialsOptions'
import RenderChildOptions from './RenderChildOptions'
import { AccordionWrapper } from 'src/common'
import { useRenderOptions } from './hooks'
import { Collapse } from 'antd'

const RenderOptions = ({
  options = [],
  title = '',
  materials = false,
  attaChedActivities = [],
  attaChedMaterials = [],
}) => {
  const { Panel } = Collapse

  const {
    attaChedActivitiesOptions,
    attaChedMaterialOptions,
    attachedTypes,
    t,
  } = useRenderOptions({
    attaChedActivities,
    attaChedMaterials,
    materials,
    options,
  })

  return (
    <>
      <AccordionWrapper
        AccordionContent={() => (
          <div className='content__header'>
            <div className='current'>{attachedTypes?.length}</div>
            <div className='whole'>/{options?.length}</div>
          </div>
        )}
      >
        <Panel header={t(title)}>
          {options?.map((option) => {
            return materials ? (
              <RenderMaterialsOption
                attachedOPtion={attaChedMaterialOptions(option)}
                option={option}
                key={option?.id}
              />
            ) : (
              <RenderChildOptions
                attachedOPtion={attaChedActivitiesOptions(option)}
                option={option}
                key={option?.id}
              />
            )
          })}
        </Panel>
      </AccordionWrapper>
    </>
  )
}

export default RenderOptions
