import CustomExpandIcon from './utils/CustomExpandIcon'
import { Collapse } from 'antd'

const AccordionWrapper = ({ children, AccordionContent, ...props }) => {
  return (
    <Collapse
      {...props}
      expandIcon={(props) => CustomExpandIcon(props, AccordionContent)}
      className={`accordion ${props.className}`}
      expandIconPosition='right'
      accordion
    >
      {children}
    </Collapse>
  )
}

export default AccordionWrapper
