import { ShowFieldError, CustomUpload } from 'src/common'
import { useFileUploadValidation } from 'src/hooks'
import { PlusOutlined } from '@ant-design/icons'
import { CompanyTextEditor } from './components'
import ShowCompanyFile from './ShowCompanyFile'
import { Form, Upload, Button } from 'antd'
import { InputItem } from 'src/common'

const BasicInformationTab = ({
  setTermOfConditionsFile,
  setPrivacyStatementSrc,
  setPrivacyStatementFile,
  setTermsOfConditionSrc,
  privacyStatementSrc,
  termsOfConditionSrc,
  setLogoFile,
  emailText,
  offerText,
  errors,
  logo,
  form,
}) => {
  const { uploadHandler, t, fileUploadClassName } = useFileUploadValidation(
    form,
    errors
  )

  return (
    <>
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <Form.Item
        className={fileUploadClassName('logo')}
        label={t('logo')}
        name='logo'
      >
        <CustomUpload
          beforeUpload={() => false}
          onRemove={() => form.setFieldsValue({ logo: null })}
          listType='picture-card'
          maxCount={1}
          onChange={(info) => {
            uploadHandler(info, 'images', 'logo')
            setLogoFile(info)
          }}
          accept='image/*'
          defaultFileList={
            logo
              ? [
                  {
                    uid: logo,
                    url: logo,
                  },
                ]
              : []
          }
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('upload')}</div>
          </div>
        </CustomUpload>
        <ShowFieldError error={errors?.logo} />
      </Form.Item>

      <Form.Item
        className={fileUploadClassName('privacy_statement')}
        label={t('privacy_statement')}
        name='privacy_statement'
      >
        <Upload
          beforeUpload={() => false}
          maxCount={1}
          onChange={(info) => {
            uploadHandler(info, 'file', 'privacy_statement')
            setPrivacyStatementFile(info)
          }}
          accept='.pdf'
        >
          <Button icon={<PlusOutlined />}>{t('upload')}</Button>
        </Upload>
        {privacyStatementSrc ? (
          <ShowCompanyFile
            src={privacyStatementSrc}
            text={t('privacy_statement')}
            onDelete={() => {
              form.setFieldsValue({ privacy_statement: null })
              setPrivacyStatementSrc(null)
            }}
          />
        ) : null}
        <ShowFieldError error={errors?.privacy_statement?.[0]} />
      </Form.Item>

      <Form.Item
        className={fileUploadClassName('terms_of_conditions')}
        label={t('terms_of_condition')}
        name='terms_of_conditions'
      >
        <Upload
          beforeUpload={() => false}
          onChange={(info) => {
            uploadHandler(info, 'file', 'terms_of_conditions')
            setTermOfConditionsFile(info)
          }}
          maxCount={1}
          accept='.pdf'
        >
          <Button icon={<PlusOutlined />}>{t('upload')}</Button>
        </Upload>
        {termsOfConditionSrc ? (
          <ShowCompanyFile
            src={termsOfConditionSrc}
            text={t('terms_of_condition')}
            onDelete={() => {
              form.setFieldsValue({
                terms_of_conditions: null,
                terms_of_conditions_id: null,
              })
              setTermsOfConditionSrc(null)
            }}
          />
        ) : null}
        <ShowFieldError error={errors?.terms_of_conditions?.[0]} />
      </Form.Item>

      <InputItem
        error={errors?.email_quotation_from}
        label={t('mail_quotation_from')}
        name='email_quotation_from'
        required={false}
      />

      <CompanyTextEditor
        error={errors?.email_quotation_text}
        setFieldsValue={form.setFieldsValue}
        label={t('text_quotation_mail')}
        fieldName='email_quotation_text'
        initialState={emailText}
      />

      <CompanyTextEditor
        setFieldsValue={form.setFieldsValue}
        error={errors?.offerte_link}
        label={t('text_offer_link')}
        initialState={offerText}
        fieldName='offerte_link'
      />

      <InputItem
        error={errors?.email_confirmation_from}
        label={t('mail_confirmation_from')}
        name='email_confirmation_from'
        required={false}
      />
    </>
  )
}

export default BasicInformationTab
