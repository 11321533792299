import ProjectTableColumns from 'src/screens/Home/ProjectTableColumns'
import { Table } from 'antd'

const OpenProjectsTable = ({
  project = [],
  paginationInfo = {},
  handlePageChange,
  isProjectLoading,
  onRow,
  className = 'desktop__table__visibility',
  handleOpenTableChange,
  archiveProject,
  deleteProject,
}) => {
  return (
    <div className={`content ${className}`}>
      <div className='content__table'>
        <Table
          scroll={{ x: '992px' }}
          columns={ProjectTableColumns(archiveProject, deleteProject)}
          rowKey={(record) => record.id}
          bordered
          dataSource={project || []}
          pagination={{
            total: paginationInfo?.total,
            current: paginationInfo?.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={handleOpenTableChange}
          loading={isProjectLoading}
          onRow={onRow}
        />
      </div>
    </div>
  )
}

export default OpenProjectsTable
