import { PAYMENT_CONDITION_TYPE } from 'src/screens/Manage/screens/PaymentConditions/usePaymentConditions'
import { getSalesRequest, generalConditionsByTypeRequest } from 'src/services'
import { useInfiniteQuery, useQuery } from 'react-query'
import { getTechniciansRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isBottom } from 'src/utils'

export const useProjectDetails = ({ projectCompanyId }) => {
  const { user } = useSelector((state) => state.globalSlice)
  const { t } = useTranslation()

  const companyId = projectCompanyId || user?.company?.id

  const { data: managersData } = useQuery(['users', 'sales', companyId], () =>
    getSalesRequest({ company_id: companyId })
  )

  const { data: technicians } = useQuery(
    ['users', 'technician', companyId],
    () => getTechniciansRequest(companyId)
  )

  const {
    data: conditionData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['paymentConditions', PAYMENT_CONDITION_TYPE, companyId],
    ({ pageParam = 1 }) =>
      generalConditionsByTypeRequest({
        page: pageParam,
        companyId,
        type: PAYMENT_CONDITION_TYPE,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(companyId),
    }
  )

  function fetchGeneralConditionsOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return

    fetchNextPage()
  }

  return {
    accountManagersList: managersData?.data?.data,
    executorsList: technicians?.data?.data,
    conditionsList: conditionData?.pages,
    fetchGeneralConditionsOnScroll,
    t,
  }
}
