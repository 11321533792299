import GeneralConditionsFormModal from './GeneralConditionsFormModal'
import { useAddGeneralConditionModal } from './hooks'

const AddGeneralConditionModal = ({ setAddGeneralConditionModal, show }) => {
  const {
    handleAddGeneralCondition,
    createConditionError,
    closeModal,
    isLoading,
    form,
    t,
  } = useAddGeneralConditionModal(setAddGeneralConditionModal)

  return (
    <GeneralConditionsFormModal
      onFinish={handleAddGeneralCondition}
      actionButtonTitle={t('save')}
      errors={createConditionError}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}
export default AddGeneralConditionModal
