import { formattedDate } from 'src/utils'

function renderTaskPlanningTableColumns(t) {
  return [
    {
      title: t('project_name'),
      dataIndex: 'project_name',
    },
    {
      title: t('planner'),
      dataIndex: 'planner',
    },
    {
      title: t('date'),
      dataIndex: 'updated_at',
      render: (value) => {
        return formattedDate(value)
      },
      sorter: true,
    },
    {
      title: t('departure_time'), // TODO if it want add formatter
      dataIndex: 'departure_time',
    },
    {
      title: t('starting_time'), // TODO if it want add formatter
      dataIndex: 'starting_time',
    },
    {
      title: t('finishing_time'), // TODO if it want add formatter
      dataIndex: 'finishing_time',
    },
    {
      title: t('team_members'),
      dataIndex: 'team_members',
    },
    {
      title: t('machine'),
      dataIndex: 'machine',
    },
    {
      title: t('type_of_work'),
      dataIndex: 'type_of_work',
    },
    {
      title: t('address'),
      dataIndex: 'address',
    },
  ]
}

export { renderTaskPlanningTableColumns }
