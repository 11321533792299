import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updatePanelRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Form } from 'antd'

export const useShowPanelModal = (setShowPanelModal, showPanelModal) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const {
    mutate: updatePanel,
    isLoading,
    error,
  } = useMutation(
    (variables) =>
      !isLoading && updatePanelRequest(showPanelModal.data?.id, variables),
    {
      onSuccess: (resp) => {
        if (resp.data.success) {
          closeModal()
          queryClient.refetchQueries('panels')
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const closeModal = () => {
    setShowPanelModal({ show: false, data: {} })
  }

  useEffect(() => {
    if (showPanelModal.data) {
      form.setFieldsValue(showPanelModal.data)
    }
  }, [form, showPanelModal.data])

  return {
    updatePanelError: handleValidationError(error),
    currentPanel: showPanelModal?.data,
    showPanelModal,
    updatePanel,
    closeModal,
    isLoading,
    form,
    t,
  }
}
