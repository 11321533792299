import { useUserModalForm } from './hooks'
import { Form, Radio, Select } from 'antd'
import {
  SelectLanguageInput,
  PasswordInputItem,
  FormModalWrapper,
  PhoneNumberInput,
  NumberInputItem,
  SelectItem,
  InputItem,
  ShowFieldError,
} from 'src/common'

import {
  translateRole,
  isSalesPlus,
  isSuperUser,
  isAdmin,
  isSales,
  formItemErrorClasses,
} from 'src/utils'

const UserFormModal = ({
  showUserValues = null,
  initialValues = {},
  actionButtonTitle,
  skillsForDropDown,
  closeHandler,
  data = {},
  isLoading,
  onFinish,
  errors,
  roles,
  user,
  form,
  show,
}) => {
  const {
    fetchCompaniesOnScroll,
    isWorkerSelected,
    isSalesSelected,
    setSelectedRole,
    companies,
    t,
  } = useUserModalForm(data)

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      initialValues={initialValues}
      closeHandler={closeHandler}
      modalTitle={t('user')}
      labelCol={{ span: 4 }}
      isLoading={isLoading}
      className='user__form'
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem
        disabled={isSalesPlus()}
        error={errors?.name}
        label={t('name')}
        name='name'
      />

      <InputItem
        disabled={isSales()}
        error={errors?.email}
        label={t('email')}
        name='email'
      />

      {!showUserValues && (
        <PasswordInputItem
          autoComplete='one-time-code'
          error={errors?.password}
          label={t('password')}
          name='passwordSearch'
        />
      )}

      {(isSuperUser() || isAdmin()) && (
        <PhoneNumberInput
          error={errors?.phone_number?.[0]}
          label={t('phone_number')}
          name='phone_number'
        />
      )}

      {isSuperUser() && (
        <SelectItem
          onPopupScroll={fetchCompaniesOnScroll}
          defaultValue={data?.company?.id}
          error={errors?.company_id}
          label={t('company')}
          name='company_id'
          form={form}
        >
          {!!data?.company && (
            <Select.Option key={data?.company?.id} value={data?.company?.id}>
              {data?.company?.name}
            </Select.Option>
          )}
          {companies?.map((page) => {
            return page?.data?.data?.map((company) => {
              if (data?.company?.id !== company?.id) {
                return (
                  <Select.Option key={company?.id} value={company?.id}>
                    {company?.name}
                  </Select.Option>
                )
              } else return null
            })
          })}
        </SelectItem>
      )}

      {(isSuperUser() || isAdmin()) && (
        <SelectItem
          defaultValue={data?.roles?.[0]?.id}
          onChange={setSelectedRole}
          error={errors?.role}
          label={t('role')}
          name='role'
          form={form}
        >
          {roles?.map((role) => {
            return (
              <Select.Option key={role?.id} value={role?.id}>
                {translateRole(role?.name)}
              </Select.Option>
            )
          })}
        </SelectItem>
      )}

      {isSalesSelected && (
        <>
          {(isSuperUser() || isAdmin()) && (
            <NumberInputItem
              label={t('maximum_discount_for_sales')}
              name='max_discount'
              onChangeHandler={(id) => {
                form.setFieldsValue({
                  max_discount: id,
                })
              }}
              error={errors?.max_discount}
              controls={false}
            />
          )}

          <NumberInputItem
            label={`${t('commission')} (%)`}
            name='commission'
            onChangeHandler={(id) => {
              form.setFieldsValue({
                commission: id,
              })
            }}
            error={errors?.commission}
            controls={false}
          />
        </>
      )}

      {isWorkerSelected && (
        <>
          <Form.Item
            className={`${formItemErrorClasses(errors?.skills)} ${
              errors?.skills && 'ant-selector-error'
            } custom-antd-select-item`}
            name='skills'
            label={t('skills')}
            style={{ marginBottom: 0 }}
            required
          >
            <Form.Item name='skills'>
              <Select
                mode='multiple'
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder={t('worker_skills_placeholder')}
                defaultValue={[]}
                options={skillsForDropDown}
              />
            </Form.Item>

            <ShowFieldError withHeight error={errors?.skills} />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 0 }}
            className='custom-antd-input-item'
            name='is_team_lead'
            label={t('is_team_lead')}
            required
          >
            <Form.Item name='is_team_lead'>
              <Radio.Group>
                <Radio value={1}>{t('yes')}</Radio>
                <Radio value={0}>{t('no')}</Radio>
              </Radio.Group>
            </Form.Item>
            <ShowFieldError withHeight error={errors?.is_team_lead} />
          </Form.Item>
        </>
      )}

      {(isSuperUser() || isAdmin()) && (
        <SelectLanguageInput
          error={errors?.lang?.[0]}
          label={t('language')}
          form={form}
          name='lang'
        />
      )}
    </FormModalWrapper>
  )
}

export default UserFormModal
