import { useHandleContainers } from '../hooks'
import { History, Notes } from './components'

function HistoryAndNotes() {
  const {
    handleToggleHistoryContent,
    handleToggleNotesContent,
    isHistoryContentOpen,
    isNotesContentOpen,
  } = useHandleContainers()

  return (
    <div className='history_and_notes_wrap'>
      <History
        handleToggleHistoryContent={handleToggleHistoryContent}
        isHistoryContentOpen={isHistoryContentOpen}
      />

      <Notes
        handleToggleNotesContent={handleToggleNotesContent}
        isNotesContentOpen={isNotesContentOpen}
      />
    </div>
  )
}

export default HistoryAndNotes
