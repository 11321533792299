import { EditOutlined } from '@ant-design/icons'

function InputGroup({ id, label, type = 'text', placeholder = '', icon }) {
  return (
    <div className='project_details_input_group'>
      <label htmlFor={id}>{label}</label>
      <div className='project_details_input'>
        <input
          className={!icon ? 'input_full' : ''}
          id={id}
          type={type}
          placeholder={placeholder}
        />
        {icon ? icon : null}
      </div>
    </div>
  )
}

export default InputGroup
