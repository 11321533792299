import MaterialsFormModal from './MaterialsFormModal'
import { useShowMaterialsModal } from './hooks'

const ShowMaterialsModal = ({
  setShowMaterialModal,
  currentMaterial,
  show,
}) => {
  const {
    updateMaterialError,
    updateMaterial,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowMaterialsModal(setShowMaterialModal, currentMaterial)

  return (
    <MaterialsFormModal
      currentMaterial={currentMaterial}
      actionButtonTitle={t('update')}
      errors={updateMaterialError}
      closeHandler={closeModal}
      onFinish={updateMaterial}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}

export default ShowMaterialsModal
