import { useInfiniteQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { axios } from 'src/services'

export function useGetProductBrand({ queryKey, url, perPage = 20 }) {
  const { user } = useSelector((state) => state.globalSlice)

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [queryKey, user?.company?.id],
    ({ pageParam = 1 }) =>
      axios.get(`${url}&page=${pageParam}&per_page=${perPage}`),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
    }
  )

  function fetchBrandOnScroll(e) {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1

    if (!bottom || !hasNextPage) return

    fetchNextPage()
  }

  return {
    productBrands: data?.pages,
    fetchBrandOnScroll,
    isBrandLoading: isLoading,
  }
}
