import { ProfileForm } from './components'
import { useProfile } from './useProfile'
import { Header } from 'src/common'
import { Spin } from 'antd'

const Profile = () => {
  const {
    firstLettersOfUsername,
    isUserDataLoading,
    profileImageSrc,
    userData,
    t,
  } = useProfile()

  return (
    <div className='profile'>
      <Header />
      <div className='profile__title'>{t('user-profile')}</div>

      <div
        className={`profile__info__container ${
          isUserDataLoading && 'profile__info__container__loading'
        }`}
      >
        <div className='profile__info__container__letters'>
          {profileImageSrc ? (
            <img
              src={profileImageSrc}
              alt=''
              width={140}
              height={140}
              style={{ borderRadius: '50%' }}
            />
          ) : (
            <div>{userData ? firstLettersOfUsername : <Spin />}</div>
          )}
        </div>

        {isUserDataLoading && (
          <Spin className='loading_icon' tip={t('loading')} />
        )}

        <ProfileForm />
      </div>
    </div>
  )
}

export default Profile
