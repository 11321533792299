import useSelectLanguageInput from './useSelectLanguageInput'
import { SelectLanguage, ShowFieldError } from 'src/common'
import { formItemErrorClasses } from 'src/utils'
import { Form } from 'antd'

const SelectLanguageInput = (props) => {
  const { changeHandler, value } = useSelectLanguageInput(
    props?.form,
    props?.name,
    props?.changeHandler
  )

  return (
    <Form.Item
      required={props?.required ? props?.required : true}
      label={props?.label}
      name={props?.name}
      className={`${formItemErrorClasses(props?.error)} ${
        props?.error && 'ant-input-border-error'
      } ${props?.className}`}
    >
      <SelectLanguage
        style={{ width: '100%' }}
        onChange={changeHandler}
        selected={value}
        {...props}
      />

      <ShowFieldError error={props?.error} withHeight={true} />
    </Form.Item>
  )
}

export default SelectLanguageInput
