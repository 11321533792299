import { useTranslation } from 'react-i18next'
import { useHandleQueryParams } from 'src/hooks'
import { view } from '../data'

function useListHeader() {
  const { t } = useTranslation()

  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  function handleCalendarViewClick() {
    return appendQueryParams({ view: view.CALENDAR })
  }
  function handleTasksViewClick() {
    return appendQueryParams({ view: view.TASkS })
  }

  function handleProjectListViewClick() {
    return deleteQueryParams('view')
  }

  return {
    handleProjectListViewClick,
    handleCalendarViewClick,
    handleTasksViewClick,
    t,
  }
}

export { useListHeader }
