import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

const ProfileFormButtons = ({ disabled, submitForm, cancelHandler }) => {
  const { t } = useTranslation()

  return (
    <div className='profile__form__buttons'>
      <Button disabled={disabled} type='secondary' onClick={cancelHandler}>
        {t('cancel')}
      </Button>
      <Button type='primary' disabled={disabled} onClick={submitForm}>
        {t('update')}
      </Button>
    </div>
  )
}

export default ProfileFormButtons
