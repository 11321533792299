import InspectionIcon from 'src/assets/icons/inspection.svg'
import { FolderIcon } from 'src/assets'
import OffersAndCommentsTooltip from './OffersAndCommentsTooltip'
import { useTranslation } from 'react-i18next'
import { useInspection } from './hooks'
import { AddInspectionModal } from '../wizards/AddInspectionModal'
import { canManageProject, downloadFile } from 'src/utils'
import { LinkWithQueryParam } from 'src/common'

function OffersHeader({
  createOffer,
  isOffersActionLoading,
  inspection: projectInspection,
  offersCount,
  project = {},
}) {
  const { t } = useTranslation()

  const {
    isInspectionDeleteConfirmationOpen,
    closeInspectionDeleteConfirmation,
    openInspectionDeleteConfirmation,
    isInspectionPictureDeleting,
    isAddInspectionModalOpen,
    handlePreviousStepClick,
    closeAddInspectionModal,
    openAddInspectionModal,
    deleteInspectionImage,
    setUploadPictureErrors,
    inspectionDeleting,
    uploadPictureErrors,
    updatingInspection,
    addInspectionError,
    deleteInspection,
    getStepFiles,
    onNextClick,
    wizardPage,
    inspection,
  } = useInspection(projectInspection)

  return (
    <>
      <div className='header-wrapper'>
        <div className='offers__count__container'>
          <h5>
            {t('offers')} {offersCount ? `(${offersCount})` : null}
          </h5>

          <LinkWithQueryParam
            queryParamName='projectDataModal'
            queryParamValue='1'
          >
            <div className='folder__icon'>
              <FolderIcon />
            </div>
          </LinkWithQueryParam>
        </div>
        <div className='buttons'>
          <button onClick={openAddInspectionModal} className='inspection'>
            <span>{t('inspection')}</span>
            <OffersAndCommentsTooltip title={t('download')}>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  if (!projectInspection?.is_finished) return
                  const downloadPath = `${process.env.REACT_APP_BACKEND_URI}/storage/companies/inspection_${projectInspection?.id}.pdf`
                  downloadFile(downloadPath, { newTab: true })
                }}
                style={
                  projectInspection?.is_finished
                    ? {
                        cursor: 'pointer',
                        background: '#3D92EC',
                      }
                    : {
                        cursor: 'not-allowed',
                        background: '#ADB5BD',
                      }
                }
              >
                <img src={InspectionIcon} alt={'home'} />
              </div>
            </OffersAndCommentsTooltip>
          </button>
          {canManageProject() ? (
            <button
              onClick={createOffer}
              disabled={isOffersActionLoading}
              className={`new-offer ${
                isOffersActionLoading && 'new-offer-disabled'
              }`}
            >
              <span className='plus'>
                <span className='vertical' />
                <span className='horizontal' />
              </span>
              <span className='offer-text'>{t('new_offer')}</span>
            </button>
          ) : null}
        </div>
      </div>

      {isAddInspectionModalOpen && (
        <AddInspectionModal
          isInspectionPictureDeleting={isInspectionPictureDeleting}
          getStepFiles={getStepFiles}
          show={isAddInspectionModalOpen}
          wizardPage={wizardPage}
          closeModal={closeAddInspectionModal}
          initialValues={inspection}
          previousClick={handlePreviousStepClick}
          error={addInspectionError}
          loading={updatingInspection}
          onNextClick={onNextClick}
          isDeleteConfirmationOpen={isInspectionDeleteConfirmationOpen}
          closeDeleteConfirmation={closeInspectionDeleteConfirmation}
          openDeleteConfirmation={openInspectionDeleteConfirmation}
          deleteInspection={deleteInspection}
          deleteInspectionImage={deleteInspectionImage}
          isDeleting={inspectionDeleting}
          project={project}
          setUploadPictureErrors={setUploadPictureErrors}
          uploadPictureErrors={uploadPictureErrors}
        />
      )}
    </>
  )
}

export default OffersHeader
