import { CloseOutlined, DownOutlined } from '@ant-design/icons'

function MultiselectHeader({
  isSelectOpen,
  handleSelectOptions,
  selectedOptions,
  label,
  handleOptionSelect,
}) {
  return (
    <>
      {isSelectOpen ? (
        <div
          className='multiselect_background'
          onClick={handleSelectOptions}
        ></div>
      ) : null}
      <div
        className='multiselect_field'
        onClick={() => {
          if (!selectedOptions.length) {
            return handleSelectOptions()
          }
        }}
      >
        {!selectedOptions.length ? (
          <div className='multiselect_header_placeholder'>{label}</div>
        ) : (
          <div className='multiselect_header_placeholder_selected_options'>
            {selectedOptions?.map((option) => (
              <div
                key={option.id}
                className='multiselect_header_placeholder_selected_option'
              >
                <img src='https://img.freepik.com/free-icon/user_318-159711.jpg' />
                <span>{option.employee}</span>
                <CloseOutlined onClick={() => handleOptionSelect(option)} />
              </div>
            ))}
          </div>
        )}
        <div>
          <DownOutlined
            className={`multiselect_down_outline ${
              isSelectOpen ? 'multiselect_open_down_outlined' : ''
            }`}
            onClick={() => {
              if (!!selectedOptions.length) {
                return handleSelectOptions()
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

export default MultiselectHeader
