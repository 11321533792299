export const HomeIcon = () => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none'>
      <path
        d='M19.578 8.18701L17.3793 6.22441V2.96101C17.3793 2.57306 17.0684 2.27639 16.713 2.27639H15.3139C14.9363 2.27639 14.6476 2.59588 14.6476 2.96101V3.78257L10.7388 0.290967C10.3168 -0.0969889 9.69494 -0.0969889 9.27296 0.290967L0.389285 8.20983C0.0339376 8.52932 -0.0993174 9.03138 0.0783561 9.4878C0.23382 9.94421 0.655795 10.2409 1.12219 10.2409H2.8323V17.3382C2.8323 17.7033 3.12102 18 3.47636 18H7.29634V12.8425C7.29634 12.1122 7.87378 11.5189 8.58448 11.5189H11.405C12.1157 11.5189 12.6932 12.1122 12.6932 12.8425V18H16.5132C16.8685 18 17.1572 17.7033 17.1572 17.3382V10.2409H18.8673C19.4892 10.2409 20 9.71601 20 9.07702C20 8.71189 19.8445 8.39239 19.578 8.18701Z'
        fill='#ADB5BD'
      />
    </svg>
  )
}
