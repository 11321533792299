import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { useMoveAddInspectionWizard } from './hooks'
import { Button, Space } from 'antd'

const MoveAddInspectionWizard = ({
  wizardPage = 0,
  onPreviousClick = () => {},
  onNextClick = () => {},
  disabled = false,
}) => {
  const { t } = useMoveAddInspectionWizard()

  return (
    <>
      <Space>
        {wizardPage > 0 && (
          <Button
            type='secondary'
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={onPreviousClick}
            disabled={disabled}
          >
            <AiOutlineArrowLeft />
            {t('previous')}
          </Button>
        )}

        <Button
          type='primary'
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={onNextClick}
          disabled={disabled}
        >
          {wizardPage !== 8 ? <AiOutlineArrowRight /> : null}
          {wizardPage === 8 ? t('save') : t('next')}
        </Button>
      </Space>
    </>
  )
}

export default MoveAddInspectionWizard
