import { ActionButtonsGroup } from 'src/screens/Home/components/ActionButtonsGroup'
import { useStatusFilters } from './useStatusFilters'
import { UpOutlined } from '@ant-design/icons'
import { isTechnician } from 'src/utils'
import { LabelIcon } from 'src/assets'
import { Checkbox, Form } from 'antd'

const StatusFilters = ({
  statusFiltersForm,
  findStatusCount,
  paginationInfo,
  statusIds,
}) => {
  const {
    setStatusModalOpen,
    statusModalOpen,
    submitHandler,
    statusOptions,
    resetHandler,
    t,
  } = useStatusFilters({ statusFiltersForm, findStatusCount, statusIds })

  return (
    <div
      className={`projects__filter__dropdown ${
        statusModalOpen && 'projects__filter__dropdown__active'
      } ${isTechnician() && 'dropdown__full__width'}`}
    >
      {statusModalOpen && (
        <div
          onClick={() => setStatusModalOpen(false)}
          className='filters__overlay'
        ></div>
      )}

      <div
        className='projects__filter__dropdown__container'
        onClick={() => setStatusModalOpen((prev) => !prev)}
      >
        <div className='label__title'>
          <LabelIcon />
          <div>{t('status')}</div>
        </div>
        <UpOutlined
          className={`${
            statusModalOpen
              ? 'filter__dropdown__icon__active'
              : 'filter__dropdown__icon'
          }`}
          color='#606870'
        />
      </div>

      {statusModalOpen && (
        <div className='projects__filters__checkbox statuses__checkbox'>
          <p className='total__projects__count'>
            {t('overall')} {paginationInfo?.total} {t('project')}
          </p>

          <Form
            initialValues={{ filters: statusIds }}
            onFinish={submitHandler}
            form={statusFiltersForm}
          >
            <Form.Item name='filters'>
              <Checkbox.Group
                className='status__checkbox__group'
                options={statusOptions}
              />
            </Form.Item>

            <ActionButtonsGroup
              cancelHandler={() => setStatusModalOpen(false)}
              resetHandler={resetHandler}
            />
          </Form>
        </div>
      )}
    </div>
  )
}

export default StatusFilters
