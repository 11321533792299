import FormActivityType from './FormActivityType'
import { useShowActivityType } from './hooks'

const ShowActivityType = ({ setShowTypesModal, currentType, show }) => {
  const {
    updateActivityTypeError,
    updateActivityType,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowActivityType(setShowTypesModal, currentType)

  return (
    <FormActivityType
      errors={updateActivityTypeError}
      actionButtonTitle={t('update')}
      onFinish={updateActivityType}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default ShowActivityType
