import { ListItem, TotalPrices } from './components'
import { useCheckWizard } from './useCheckWizard'
import { Typography, Form, Button } from 'antd'
import { BiTrash } from 'react-icons/bi'
import { memo } from 'react'
import {
  showCheckPriceDetails,
  formItemErrorClasses,
  hidePurchasePrice,
  canManageProject,
  parseDateString,
  disablePastDate,
  dataUrlToFile,
  formatToYMD,
  inputStyles,
  joinPanels,
} from 'src/utils'
import {
  AutoSignatureBtn,
  SignatureModal,
  ShowFieldError,
  CustomCheckbox,
  DatePicker,
  Controle,
} from 'src/common'

const CheckWizard = ({
  panels = [],
  converters = [],
  mountingSystems = [],
  wifiModules = [],
  powerOptimizers = [],
  options = [],
  totals = {},
  installationWeek = '',
  correction = 0,
  signatureSrc = '',
  address = {},
  contact = {},
  projectDiscount,
  showAdditionalPriceDetails,
  signatureId,
  offerId,
  disableCheckEditing,
}) => {
  const { Title } = Typography
  const {
    subTotalSellingPrice,
    isSignatureModalOpen,
    toggleSignatureModal,
    onSellingPriceChange,
    setShowPriceDetails,
    canSeePriceDetails,
    onCorrectionChange,
    totalSellingPrice,
    setSignatureImage,
    showPriceDetails,
    onDiscountChange,
    setIsAutoSigned,
    correctionValue,
    signatureImage,
    projectPrices,
    purchasePrice,
    marginPercent,
    isAutoSigned,
    activities,
    materials,
    addCheck,
    discount,
    margin,
    adding,
    error,
    form,
    t,
  } = useCheckWizard({
    panels,
    converters,
    mountingSystems,
    wifiModules,
    powerOptimizers,
    options,
    correction,
    totals,
    signatureSrc,
    projectDiscount,
    showAdditionalPriceDetails,
    signatureId,
    offerId,
  })

  return (
    <>
      <div className='checkscreen'>
        <Title level={3} className='capitalize_text'>
          {t('check')}
        </Title>

        <>
          <Controle
            name={`${contact?.name || ''}`}
            address={` ${address?.street || ''} - ${
              address?.house_number || ''
            } ${address?.zipcode || ''}, ${address?.location || ''}`}
            mail={`${contact?.email || ''}`}
          />
          <div className='type-desctiption'>
            {showCheckPriceDetails() && (
              <CustomCheckbox
                containerClass='price_details_checkbox '
                setCheckboxValue={setShowPriceDetails}
                title={t('additional-price-details')}
                checkboxValue={showPriceDetails}
              />
            )}

            <div className='check_header_wrapper'>
              <div className='product_title'>{t('type_or_description')}</div>
              <div className='price_title_wrapper'>
                <span
                  className={`price_title quantity ${
                    hidePurchasePrice() && 'quantity_hide_purchase_price'
                  }`}
                >
                  {t('quantity')}
                </span>
                {canSeePriceDetails ? (
                  <span className='price_title purchase_price'>
                    {t('purchase_price')}
                  </span>
                ) : null}
                <span className='price_title selling_price'>
                  {t('selling_price')}
                </span>
                <span className='price_title'>
                  {t('total_selling_price_abbr')}
                </span>
              </div>
            </div>

            <Form form={form} initialValues={projectPrices} className='list'>
              {panels?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  products={joinPanels(panels)}
                  title={t('panels')}
                  product='panel'
                />
              ) : null}
              {converters?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('converters')}
                  products={converters}
                  product='converter'
                />
              ) : null}
              {powerOptimizers?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('power_optimizers')}
                  products={powerOptimizers}
                  product='power_optimizer'
                />
              ) : null}

              {wifiModules?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('wifi_modules')}
                  products={wifiModules}
                  product='wifi_module'
                />
              ) : null}

              {mountingSystems?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('mounting_systems')}
                  products={mountingSystems}
                  product='mounting_system'
                />
              ) : null}

              {activities?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('activities')}
                  products={activities}
                  product='optionable'
                />
              ) : null}

              {materials?.length ? (
                <ListItem
                  onSellingPriceChange={onSellingPriceChange}
                  canSeePriceDetails={canSeePriceDetails}
                  disableEditing={disableCheckEditing}
                  title={t('materials')}
                  products={materials}
                  product='optionable'
                />
              ) : null}

              <hr style={{ marginTop: '2.5rem' }} />

              <TotalPrices
                subTotalSellingPrice={subTotalSellingPrice}
                canSeePriceDetails={canSeePriceDetails}
                onCorrectionChange={onCorrectionChange}
                totalSellingPrice={totalSellingPrice}
                disableEditing={disableCheckEditing}
                onDiscountChange={onDiscountChange}
                correctionValue={correctionValue}
                purchasePrice={purchasePrice}
                marginPercent={marginPercent}
                discount={discount}
                margin={margin}
                error={error}
              />
            </Form>

            <div className='preffered'>
              <div className='title'>{t('preferred_installation_week')}</div>

              <Form
                initialValues={
                  installationWeek
                    ? {
                        installation_week: parseDateString(installationWeek),
                      }
                    : null
                }
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Form.Item
                  label={t('installation_week')}
                  className={`preffered__input ${formItemErrorClasses(
                    error?.installation_week
                  )}`}
                >
                  <Form.Item name='installation_week' noStyle>
                    <DatePicker
                      style={inputStyles(error?.installation_week)}
                      disabledDate={(current) => {
                        return disablePastDate(current)
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={t('preferred_installation_placeholder')}
                      disabled={!canManageProject() || disableCheckEditing}
                      format={'yyyy-MM-dd (w)'}
                    />
                  </Form.Item>
                  <ShowFieldError
                    withHeight={true}
                    error={error?.installation_week}
                  />
                </Form.Item>
              </Form>
            </div>

            {signatureImage ? (
              <div className='signatureImgAndDeleteWrapper'>
                <img
                  className='signatureImg'
                  src={signatureImage}
                  alt='signature'
                />
                {!signatureSrc && !adding ? (
                  <Button
                    onClick={() => {
                      setIsAutoSigned(false)
                      setSignatureImage('')
                    }}
                    className='deleteSignatureImg'
                    type='danger'
                  >
                    <BiTrash />
                  </Button>
                ) : null}
              </div>
            ) : (
              <div className='signature'>
                <div className='title'>{t('check_signature_title')}</div>
                <div className='signature__button signature__button__container'>
                  <Button
                    disabled={disableCheckEditing}
                    onClick={toggleSignatureModal}
                    type='secondary'
                  >
                    {t('enter_signature')}
                  </Button>

                  <AutoSignatureBtn
                    setSignature={setSignatureImage}
                    setAutoSigned={setIsAutoSigned}
                    disabled={disableCheckEditing}
                    btnType='secondary'
                  />
                </div>
              </div>
            )}
            <ShowFieldError withHeight={true} error={error?.file} />
          </div>
          <SignatureModal
            setImageUrl={setSignatureImage}
            show={isSignatureModalOpen}
            close={toggleSignatureModal}
          />
          <div className='checkscreen__footer'>
            <Button
              disabled={adding || !canManageProject() || disableCheckEditing}
              onClick={async () => {
                if (!canManageProject()) return

                const val = form.getFieldValue('installation_week')

                let signature = ''

                if (!isAutoSigned) {
                  signature = await dataUrlToFile(
                    signatureImage,
                    'signature.png'
                  )
                }

                addCheck({
                  installation_week: val ? formatToYMD(val) : null,
                  signature,
                })
              }}
              type='primary'
            >
              {t('save_project')}
            </Button>
          </div>
        </>
      </div>
    </>
  )
}

export default memo(CheckWizard)
