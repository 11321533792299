import { useTranslation } from 'react-i18next'
import { ButtonWithPlus } from 'src/common'
import { isTechnician } from 'src/utils'
import { Link } from 'react-router-dom'

const NewProjectButtonLink = () => {
  const { t } = useTranslation()

  return !isTechnician() ? (
    <Link to='/project/project-creation'>
      <ButtonWithPlus
        className='new__project__btn'
        text={t('new_project')}
        type='primary'
        size={20}
      />
    </Link>
  ) : null
}

export default NewProjectButtonLink
