import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useEnabledFeatures } from 'src/hooks'
import { isSuperUser } from 'src/utils'
import { useMemo } from 'react'

const useCompanySectionsDropdown = ({
  companySections,
  setActiveTab,
  companyData,
}) => {
  const { isNewLeadsEnabled } = useEnabledFeatures()
  const { search } = useLocation()
  const { t } = useTranslation()
  const history = useHistory()

  let options = useMemo(() => {
    const {
      FINANCIAL_INFORMATION,
      EMAIL_INFORMATION,
      LEAD_INTEGRATION,
      CONFIGURATION,
      SETUP,
    } = companySections

    let availableSections = [
      {
        value: SETUP,
        label: t(SETUP),
      },

      {
        value: FINANCIAL_INFORMATION,
        label: t(FINANCIAL_INFORMATION),
      },

      {
        value: EMAIL_INFORMATION,
        label: t(EMAIL_INFORMATION),
      },
    ]

    if (isNewLeadsEnabled && !!companyData) {
      availableSections.push({
        value: LEAD_INTEGRATION,
        label: t(LEAD_INTEGRATION),
      })
    }

    if (isSuperUser()) {
      availableSections.push({
        value: CONFIGURATION,
        label: t(CONFIGURATION),
      })
    }

    return availableSections
  }, [companyData, companySections, isNewLeadsEnabled, t])

  const handleChange = (value) => {
    const params = new URLSearchParams(search)
    params.set('activeTab', value)
    history.replace(`?${params.toString()}`)
    setActiveTab(value)
  }

  return { options, t, handleChange }
}

export default useCompanySectionsDropdown
