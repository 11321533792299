import { ButtonWithTrash } from 'src/common'
import {
  translateRole,
  isSalesPlus,
  isSuperUser,
  hasRole,
  roles,
} from 'src/utils'

function getUsersColumnWidth() {
  return hasRole(roles.superUser) ? '25%' : '33.33%'
}

function disableDelete(user, record) {
  if (user?.roles?.[0]?.id === roles.admin) {
    return (
      record?.roles?.[0]?.id === roles.superUser ||
      record?.roles?.[0]?.id === roles.admin
    )
  }
  return user?.id === record?.id
}

const columns = ({
  setIsCompanyFilterVisible,
  setDeleteModal,
  companyFilters,
  rolesFilter,
  user,
  t,
}) => {
  return [
    {
      title: t('name'),
      dataIndex: 'name',
      width: getUsersColumnWidth(),
      sorter: true,
    },
    {
      title: t('mail'),
      dataIndex: 'email',
      width: getUsersColumnWidth(),
      sorter: true,
    },
    {
      title: t('role'),
      dataIndex: 'roles',
      width: getUsersColumnWidth(),
      render: (_, record) => {
        return translateRole(record?.roles?.[0]?.name)
      },
      filters: !isSalesPlus() ? rolesFilter : false,
    },

    {
      title: t('company'),
      dataIndex: 'company',
      width: getUsersColumnWidth(),
      render: (_, record) => {
        return record?.company?.name
      },
      sorter: true,
      filters: isSuperUser() ? companyFilters : false,
      onFilterDropdownVisibleChange: (visible) => {
        setIsCompanyFilterVisible(visible)
      },
    },

    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => {
        return (
          <ButtonWithTrash
            text={t('delete')}
            disabled={disableDelete(user, record)}
            onClick={(event) => {
              setDeleteModal({ show: true, data: record })
              event.stopPropagation()
            }}
          />
        )
      },
    },
  ]
}

function getUserTableColumns({
  setIsCompanyFilterVisible,
  companiesFilter,
  setDeleteModal,
  companyFilters,
  rolesFilter,
  user,
  t,
}) {
  const userTableColumns = columns({
    setIsCompanyFilterVisible,
    companiesFilter,
    companyFilters,
    setDeleteModal,
    rolesFilter,
    user,
    t,
  })
  return hasRole(roles.superUser)
    ? userTableColumns
    : userTableColumns?.filter((column) => column?.dataIndex !== 'company')
}

export default getUserTableColumns
