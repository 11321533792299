import { useQueryParams } from 'src/hooks/useQueryParams'
import { offerSignatureRequest } from 'src/services'
import { useQuery } from 'react-query'

export function useQuotationClone() {
  const query = useQueryParams()
  const token = query.get('token')

  const {
    data: quotationOfferData,
    isLoading,
    error,
  } = useQuery('quotationProject', () => offerSignatureRequest(token))

  return {
    quotationOffer: quotationOfferData?.data,
    isLoading,
    error,
  }
}
