import { useTaskPopup } from '../hooks/useTaskPopup'

import {
  ProjectPaidStatusIcon,
  PlannedWorkIcon,
  TaskStatusIcon,
  AddressIcon,
  PlannerIcon,
  DeleteIcon,
  TeamsIcon,
  DateIcon,
  TimeIcon,
  EditIcon,
  CarIcon,
  XIcon,
} from 'src/assets'

import {
  PROJECT_STATUS_COLORS,
  PROJECT_PAID_COlORS,
  PROJECT_PAID_STATUS,
  WORKER_ROLES_COLOR,
  PROJECT_STATUS,
  WORKER_ROLES,
  TASK_COLORS,
} from '../data'
import { AgreeDeleteModal } from 'src/common'

function TaskPopup({ onClose, event, onEditClick }) {
  const {
    handleDeleteConfirmationModalClose,
    handleDeleteConfirmationModalOpen,
    isDeleteConfirmationModalVisible,
    taskInfoRef,
    t,
  } = useTaskPopup(onClose)

  return (
    <div
      onMouseDown={(e) => e.stopPropagation()}
      ref={taskInfoRef}
      className='task-popup-wrapper'
    >
      <div className='popup-header'>
        <h3>{t('task_details')}</h3>
        <div className='header-icons'>
          <EditIcon handleClick={onEditClick} disabled width={16} height={16} />
          <DeleteIcon handleClick={handleDeleteConfirmationModalOpen} />

          <div onClick={onClose} className='close'>
            <XIcon />
          </div>
        </div>
      </div>
      <div className='popup-details popup-project-details'>
        <div className='info-row'>
          <div className='project-icon' />
          <div className='project-name-and-status'>
            <span className='name'>{event.resource.project?.name}</span>
            <span
              className='project-statuses'
              style={{
                color:
                  PROJECT_STATUS_COLORS[
                    PROJECT_STATUS[event.resource.project?.status]?.value
                  ]?.text,
                background:
                  PROJECT_STATUS_COLORS[
                    PROJECT_STATUS[event.resource.project?.status]?.value
                  ]?.background,
              }}
            >
              {t(PROJECT_STATUS[event.resource.project?.status]?.value)}
            </span>
          </div>
        </div>
        <div className='info-row'>
          <AddressIcon />
          <span className='row-text'>Address</span>
        </div>
        <div className='info-row'>
          <ProjectPaidStatusIcon />
          <div
            className='project-statuses'
            style={{
              color:
                PROJECT_PAID_COlORS[
                  PROJECT_PAID_STATUS[event.resource.project?.paid_status]
                    ?.value
                ]?.text,
              background:
                PROJECT_PAID_COlORS[
                  PROJECT_PAID_STATUS[event.resource.project?.paid_status]
                    ?.value
                ]?.background,
            }}
          >
            {t(PROJECT_PAID_STATUS[event.resource.project?.paid_status]?.value)}
          </div>
        </div>
        <div className='info-row'>
          <PlannerIcon />
          <span className='row-text'>Planner</span>
        </div>
      </div>
      <div className='popup-details popup-task-details'>
        <div className='info-row'>
          <DateIcon />
          <span className='row-text'>Some Date</span>
        </div>
        <div className='info-row'>
          <TimeIcon />
          <div className='task-date'>
            <span>Date Desc</span>
            <span className='date'>Date</span>
          </div>
        </div>

        <div className='info-row'>
          <div className='empty-placeholder' />
          <div className='task-date'>
            <span>Date Desc</span>
            <span className='date'>Date</span>
          </div>
        </div>
        <div className='info-row'>
          <TaskStatusIcon />
          <div
            className='task-statuses'
            style={{
              background: TASK_COLORS[event?.resource.status].background,
              color: TASK_COLORS[event?.resource.status].text,
            }}
          >
            {t(event?.resource.status)}
          </div>
        </div>

        <div className='info-row'>
          <PlannedWorkIcon />
          <span>Planned Work</span>
        </div>

        <div className='info-row'>
          <CarIcon />
          <span>Car</span>
        </div>

        <div className='info-row'>
          <TeamsIcon />
          <span>Team</span>
        </div>
        {event?.resource?.workers?.map((worker, i) => {
          return (
            <div key={i} className='info-row'>
              <div className='empty-placeholder' />
              <div className='worker-info'>
                <img
                  width={20}
                  height={20}
                  src={worker?.img_src}
                  alt='team-member'
                />
                <span>{worker?.name}, surname</span>
                <div
                  style={{
                    color: WORKER_ROLES_COLOR[worker?.role?.value]?.text,
                  }}
                  className='role'
                >
                  {t(
                    Object.values(WORKER_ROLES)?.find(
                      (role) => role.value === worker?.role?.value
                    )?.name
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {isDeleteConfirmationModalVisible ? (
        <AgreeDeleteModal
          text={t('task_delete_warning')}
          visible={isDeleteConfirmationModalVisible}
          onCancel={handleDeleteConfirmationModalClose}
        />
      ) : null}
    </div>
  )
}
export default TaskPopup
