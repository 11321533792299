import { escLargeLogo } from 'src/assets'

export const storageFileUri = (path = '', fileName = '') => {
  return `${process.env.REACT_APP_BACKEND_URI}/storage/${path}/${fileName}`
}

export const getLogoUrl = (company, config = { defaultImage: true }) => {
  if (company?.logo) {
    return storageFileUri(company?.logo?.path, company?.logo?.name)
  }

  if (!company?.logo && !company?.logo_src && config.defaultImage) {
    return escLargeLogo
  }
  return null
}

export const getCompanyPdfFileUrl = (company, fileName) => {
  if (company?.[fileName]) {
    return storageFileUri(company?.[fileName]?.path, company?.[fileName]?.name)
  }

  return null
}
