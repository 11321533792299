import { SelectRenderInput } from 'src/screens/Manage/components'
import { useFormActivity } from './hooks'
import {
  AmountInputItem,
  FormModalWrapper,
  SelectItem,
  InputItem,
} from 'src/common'

const ActivityFormModal = ({
  currentActivity = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  show,
  form,
}) => {
  const {
    fetchActivityTypeOnScroll,
    addActivityLoading,
    activityTypes,
    clickHandler,
    Option,
    t,
  } = useFormActivity()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      closeHandler={closeHandler}
      modalTitle={t('activity')}
      labelCol={{ span: 4 }}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <SelectItem
        defaultValue={currentActivity?.type?.id}
        onPopupScroll={fetchActivityTypeOnScroll}
        error={errors?.type_id}
        dropdownRender={(menu) => {
          return (
            <SelectRenderInput
              disabled={addActivityLoading}
              clickHandler={clickHandler}
              buttonTitle={t('add_type')}
              menu={menu}
            />
          )
        }}
        label={t('type')}
        name='type_id'
        form={form}
      >
        {currentActivity?.type && (
          <Option
            value={currentActivity?.type?.id}
            key={currentActivity?.type?.id}
          >
            {currentActivity?.type?.name}
          </Option>
        )}

        {activityTypes?.map((page) => {
          return page?.data?.data?.map((activityType) => {
            if (currentActivity?.type?.id !== activityType?.id) {
              return (
                <Option value={activityType?.id} key={activityType?.id}>
                  {activityType?.name}
                </Option>
              )
            } else {
              return null
            }
          })
        })}
      </SelectItem>

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <AmountInputItem
        defaultValue={currentActivity?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={currentActivity?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />
    </FormModalWrapper>
  )
}

export default ActivityFormModal
