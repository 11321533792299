import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updateConverterRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { message } from 'antd'
import { Form } from 'antd'

export const useShowConverterModal = (currentConverter, setShowConverter) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const {
    mutate: updateConverter,
    isLoading,
    error,
  } = useMutation(
    (variables) =>
      !isLoading && updateConverterRequest(currentConverter?.id, variables),
    {
      onSuccess: (resp) => {
        if (resp.data.success) {
          closeModal()
          queryClient.refetchQueries('converters')
          message.success(t('updated_successfully'))
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const closeModal = () => {
    setShowConverter({ show: false, data: {} })
  }

  useEffect(() => {
    if (!!currentConverter) {
      form.setFieldsValue(currentConverter)
    }
  }, [currentConverter, form])

  return {
    updateConverterError: handleValidationError(error),
    updateConverter,
    closeModal,
    isLoading,
    form,
    t,
  }
}
