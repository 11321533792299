function ConfirmInfoIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='none'
    >
      <path
        d='M13.5 0C6.05368 0 0 6.05368 0 13.5C0 20.9463 6.05368 27 13.5 27C20.9463 27 27 20.9463 27 13.5C27 6.05368 20.9463 0 13.5 0ZM13.5 21.6853C12.7042 21.6853 12.0505 21.0316 12.0505 20.2358C12.0505 19.44 12.7042 18.7863 13.5 18.7863C14.2958 18.7863 14.9495 19.44 14.9495 20.2358C14.9495 21.0316 14.3242 21.6853 13.5 21.6853ZM14.9211 14.2389C14.7789 14.2958 14.6653 14.4663 14.6653 14.6653V16.1147C14.6653 16.74 14.1537 17.2516 13.5284 17.2516C12.9032 17.2516 12.3916 16.74 12.3916 16.1147V14.6653C12.3916 13.4716 13.1305 12.42 14.2389 12.0505C15.1768 11.7379 16.0011 10.7716 15.7737 9.46421C15.6032 8.55474 14.8642 7.81579 13.9547 7.64526C13.2726 7.53158 12.5905 7.70211 12.0789 8.12842C11.5105 8.58316 11.2263 9.23684 11.2263 9.89053C11.2263 10.5158 10.7147 11.0274 10.0895 11.0274C9.46421 11.0274 8.95263 10.5158 8.95263 9.89053C8.95263 8.52632 9.54947 7.24737 10.5726 6.39474C11.5958 5.51368 12.96 5.14421 14.3242 5.4C16.1716 5.71263 17.6779 7.24737 17.9905 9.06632C18.3884 11.3684 17.1095 13.5284 14.9211 14.2389Z'
        fill='#FF7A45'
      />
    </svg>
  )
}

export { ConfirmInfoIcon }
