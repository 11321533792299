import { useEffect } from 'react'

export const useInfiniteQueryInAntdFilter = ({
  querySelector = '.ant-table-filter-dropdown .ant-dropdown-menu',
  isTargetFilterVisible,
  fetchNextPage,
  hasNextPage,
}) => {
  useEffect(() => {
    if (isTargetFilterVisible) {
      let targetFilterNode = null

      const antdFiltersContainers = document.querySelectorAll(querySelector)

      antdFiltersContainers?.forEach((el) => {
        const parentNode = el?.parentNode?.parentNode

        if (!parentNode?.classList?.contains('ant-dropdown-hidden')) {
          targetFilterNode = parentNode?.children?.[0]?.children?.[0]
          return
        }
      })

      if (!!targetFilterNode) {
        targetFilterNode.addEventListener('scroll', () => {
          const { scrollTop, scrollHeight, clientHeight } = targetFilterNode

          const isScrollEnd =
            Math.abs(scrollHeight - clientHeight - scrollTop) < 1

          if (!isScrollEnd || !hasNextPage) return

          fetchNextPage()
        })

        return () => targetFilterNode.removeEventListener('scroll', () => {})
      }
    }
  }, [fetchNextPage, hasNextPage, isTargetFilterVisible, querySelector])
}
