import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useFileUpload } from 'src/hooks'
import { Form, message } from 'antd'
import { useState } from 'react'

export const useAddDocument = (setAddDocumentModal) => {
  const [document, setDocument] = useState(null)
  const [errors, setErrors] = useState({})

  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const { uploadFile, isUploading } = useFileUpload()

  const submitHandler = async (data) => {
    try {
      await uploadFile({
        url: 'additional-doc',
        data: { file: document, is_public: 1, title: data.title },
      })

      await queryClient.invalidateQueries('document-library')
      message.success(t('document_added_successfully'))
      setAddDocumentModal(false)
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    }
  }

  return { form, t, document, setDocument, submitHandler, errors, isUploading }
}
