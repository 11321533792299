import { AiOutlineClose } from 'react-icons/ai'
import { useTags } from './useTags'
import { MANAGER_IDS_PARAM, STATUS_IDS_PARAM } from 'src/screens/Home/constants'

const Tags = ({
  accountManagersForm,
  statusFiltersForm,
  findStatusCount,
  statusIds = [],
  statuses = [],
  managersList,
  managerIds,
  sliceEnd,
}) => {
  const { manageFilterParams, clearPageQuery, t } = useTags()

  return [...statusIds, ...managerIds]
    .slice(sliceEnd && 0, sliceEnd)
    .map((statusId) => {
      const statusInfo = statuses.find((s) => s.id === statusId)

      let tagInfo = statusInfo

      if (!tagInfo) {
        tagInfo = managersList?.find((m) => m.id === statusId)
      }

      return (
        <span key={tagInfo?.id} className='status__tag'>
          <p className='status__tag__name'>
            {t(tagInfo?.name)}{' '}
            <span
              style={{
                color: tagInfo?.color,
              }}
            >
              {findStatusCount(tagInfo?.id)}
            </span>
          </p>

          <AiOutlineClose
            onClick={() => {
              clearPageQuery()

              if (statusInfo) {
                const filteredStatusIds = statusIds.filter(
                  (id) => id !== statusId
                )

                manageFilterParams(filteredStatusIds, STATUS_IDS_PARAM)

                statusFiltersForm.setFieldsValue({
                  filters: filteredStatusIds,
                })
              } else {
                const filteredManagerIds = managerIds.filter(
                  (id) => id !== statusId
                )

                manageFilterParams(filteredManagerIds, MANAGER_IDS_PARAM)

                accountManagersForm.setFieldsValue({
                  filters: filteredManagerIds,
                })
              }
            }}
          />
        </span>
      )
    })
}

export default Tags
