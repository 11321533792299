import { allCompaniesRequest, deleteCompanyRequest } from 'src/services'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { usePageQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { STALE_AND_CACHE } from 'src/common'
import { hasRole, roles } from 'src/utils'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export function useCompanies() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState({ show: false, data: {} })

  const { user } = useSelector((state) => state.globalSlice)

  const [showCompanyModal, setShowCompanyModal] = useState({
    show: false,
    data: {},
    type: '',
  })

  const [searchKeyWord, setSearchKeyWord] = useState('')

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const {
    data: companiesData,
    isLoading: isCompaniesDataLoading,
    isPreviousData,
  } = useQuery(
    ['companies', page, size, searchKeyWord],
    () => allCompaniesRequest({ page, size, searchKeyWord }),
    {
      ...STALE_AND_CACHE,
      keepPreviousData: true,
    }
  )

  const { mutate: deleteCompany, isLoading: isCompanyDeleting } = useMutation(
    deleteCompanyRequest,
    {
      onSuccess: (resp) => {
        if (resp.data.success) {
          setDeleteModal({ show: false, data: {} })
          queryClient.refetchQueries('companies')
        }
      },
    }
  )

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const clickHandler = () => {
    history.push('/manage/company?activeTab=setup')
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        history.push(`/manage/company?id=${record?.id}&activeTab=setup`)
      },
    }
  }

  return {
    isCompaniesDataLoading: isCompaniesDataLoading || isPreviousData,
    companies: companiesData?.data?.data,
    paginationInfo: {
      total: companiesData?.data?.meta?.total,
      page,
    },
    isSuperUser: hasRole(roles.superUser),
    setShowCompanyModal,
    isCompanyDeleting,
    handlePageChange,
    showCompanyModal,
    setDeleteModal,
    onSearchClick,
    deleteCompany,
    clickHandler,
    deleteModal,
    onRow,
    user,
    t,
  }
}
