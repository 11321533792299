import { BiTrash } from 'react-icons/bi'
import { Button } from 'antd'

const UploadedDocuments = ({
  showDocumentTitle = false,
  deleteAdditionalDocument,
  disabled = false,
  data = [],
  title = '',
}) => {
  return (
    <>
      {data?.length ? (
        <div className='show_additional_document'>
          <p>
            {title}:
            {data?.map((file) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                  }}
                  key={file?.id}
                >
                  <a
                    href={`${process.env.REACT_APP_BACKEND_URI}/storage/${file?.path}/${file?.name}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {showDocumentTitle ? file?.title : file?.name}
                  </a>
                  {!disabled ? (
                    <Button
                      onClick={() => deleteAdditionalDocument(file?.id)}
                      type='danger'
                    >
                      <BiTrash />
                    </Button>
                  ) : null}
                </div>
              )
            })}
          </p>
        </div>
      ) : null}
    </>
  )
}

export default UploadedDocuments
