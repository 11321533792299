import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  importMountingSystemsRequest,
  deleteMountingSystemRequest,
  allMountingSystemsRequest,
  exportRequest,
} from 'src/services'

export function useMountingSystems() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [importError, setImportError] = useState()
  const [sort, setSort] = useState('')

  const [deleteMountingSystem, setDeleteMountingSystem] = useState({
    show: false,
    data: {},
  })

  const [showMountingSystemModal, setShowMountingSystemModal] = useState({
    show: false,
    data: {},
  })

  const [showAddMountingSystemModal, setShowAddMountingSystemModal] =
    useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [selectedMountingSystemsIds, setSelectedMountingSystemsIds] = useState(
    []
  )
  const rowSelection = rowSelectionActions(setSelectedMountingSystemsIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const {
    data: mountingSystemsData,
    isLoading: isMountingSystemsDataLoading,
    isPreviousData,
  } = useQuery(
    ['mountingSystem', page, size, sort, searchKeyWord, user?.company?.id],
    () =>
      allMountingSystemsRequest({
        companyId: user?.company?.id,
        searchKeyWord,
        page,
        sort,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedMountingSystemsIds,
      'mountingSystem',
      mountingSystemsData?.data?.data?.length
    )

  const { mutate: DeleteMountingSystem, isLoading: deleting } = useMutation(
    deleteMountingSystemRequest,
    {
      onSuccess: (resp, deletedSystemId) => {
        if (resp.data.success) {
          if (selectedMountingSystemsIds.includes(deletedSystemId)) {
            setSelectedMountingSystemsIds((prev) =>
              prev.filter((id) => id !== deletedSystemId)
            )
          }

          if (mountingSystemsData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          setDeleteMountingSystem({ show: false, data: {} })
          queryClient.refetchQueries('mountingSystem')
        }
      },
    }
  )

  const { mutate: importMountingSystems, isLoading: importing } = useMutation(
    importMountingSystemsRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('mountingSystem')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportMountingSystemsMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    })

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  function exportMountingSystems() {
    exportMountingSystemsMutation({
      model: 'mounting_systems',
    })
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedMountingSystemsIds,
      table: 'mounting_systems',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage mounting-system') ||
          canPerform('manage own mounting-system')
        ) {
          setShowMountingSystemModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    exportDisabled: exporting || !mountingSystemsData?.data?.data?.length,
    isMountingSystemsDataLoading:
      isMountingSystemsDataLoading || isPreviousData,
    mountingSystems: mountingSystemsData?.data?.data,
    paginationInfo: {
      total: mountingSystemsData?.data?.meta?.total,
      page,
    },
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowAddMountingSystemModal,
    selectedMountingSystemsIds,
    setShowMountingSystemModal,
    showAddMountingSystemModal,
    showMountingSystemModal,
    setDeleteMountingSystem,
    setShowImportCsvModal,
    importMountingSystems,
    exportMountingSystems,
    DeleteMountingSystem,
    deletingMultipleItem,
    deleteMountingSystem,
    deleteSelectedItems,
    showImportCsvModal,
    handlePageChange,
    setImportError,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  }
}
