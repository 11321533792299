import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useQueryParams, useFileUpload } from 'src/hooks'
import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OPTIONS_TYPE } from 'src/common'
import { useParams } from 'react-router'
import { message } from 'antd'
import {
  deleteAdditionalDocumentRequest,
  saveQuotationCloneRequest,
  saveQuotationRequest,
  userInfoRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  dataUrlToFile,
} from 'src/utils'

export function useQuotationWizard({
  options = [],
  agreement,
  headquartersSignature,
  customerSignature,
  headquartersSignatureSrc,
  customerSignatureSrc,
  quotationCloneId,
  vatNumber,
  attachInspection,
  offerId,
}) {
  const [isCustomerSignatureExist, setCustomerSignature] =
    useState(customerSignatureSrc)
  const [additionalDocument, setAdditionalDocument] = useState([])
  const [vatValue, setVatValue] = useState(() => vatNumber)

  const [agreementValue, setAgreementValue] = useState(!!agreement)
  const [inspectionCheckboxValue, setInspectionCheckboxValue] = useState(
    !!attachInspection
  )
  const [uploadedHeadId, setUploadedHeadId] = useState(null)
  const [uploadedCustomerId, setUploadedCustomerId] = useState(null)
  const [customerSignatureDate, setCustomerSignatureDate] = useState(null)

  const [isHeadAutoSigned, setIsHeadAutoSigned] = useState(false)

  const [showCustomMessageModal, setShowCustomMessageModal] = useState(false)

  const [showDocumentLibraryModal, setShowDocumentLibraryModal] =
    useState(false)

  const queryClient = useQueryClient()
  const { t, i18n } = useTranslation()

  const [quotationCloneLanguage, setQuotationCloneLanguage] = useState(
    () => i18n.language
  )

  const query = useQueryParams()
  const token = query.get('token')

  const { projectId } = useParams()

  const { uploadFile, uploadError, isUploading } = useFileUpload()

  const { data: userData } = useQuery('user_info', userInfoRequest)

  useEffect(() => {
    if (uploadedHeadId && !headquartersSignature) {
      setUploadedHeadId(null)
    }
  }, [headquartersSignature])

  useEffect(() => {
    if (uploadedCustomerId && !customerSignature) {
      setUploadedCustomerId(null)
    }
  }, [customerSignature])

  const materials = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.material
    )
  }, [options])

  useEffect(() => {
    if (quotationCloneId && quotationCloneLanguage !== 'nl') {
      changeQuotationCloneLanguage('nl')
    }
  }, [])

  const activities = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.activity
    )
  }, [options])

  const {
    mutate: saveQuotationMutation,
    isLoading: saving,
    error: saveError,
  } = useMutation(({ offerId, data }) => saveQuotationRequest(offerId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId])
      queryClient.invalidateQueries(['projects', projectId])
      queryClient.invalidateQueries(['offers', projectId])

      message.success(`${t('saved_successfully')}`)
      setAdditionalDocument([])
      setShowDocumentLibraryModal(false)
    },
    onError: (error) => {
      handleNonValidationError(error)

      const validationErrors = error?.response?.data?.errors
      const additionalDocError = validationErrors?.additional_doc_ids?.[0]
      const agreementError = validationErrors?.agreement?.[0]

      additionalDocError && message.error(additionalDocError)
      agreementError && message.error(agreementError)
    },
  })

  const {
    mutate: saveQuotationCloneMutation,
    isLoading: savingQuotationClone,
    error: saveQuotationCloneError,
  } = useMutation(({ data }) => saveQuotationCloneRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId])
      setCustomerSignature(true)
      message.success(`${t('saved_successfully')}`)
    },
    onError: (error) => {
      if (!error?.response?.data?.validation_error) {
        queryClient.invalidateQueries('quotationProject')
      }
    },
  })

  const { mutate: deleteAdditionalDocumentMutation } = useMutation(
    ({ offerId, fileId }) => deleteAdditionalDocumentRequest(offerId, fileId),
    {
      onSuccess: () => {
        message.success(`${t('deleted_successfully')}`)

        queryClient.invalidateQueries(['offer', offerId])
      },
      onError: (err) => {
        handleNonValidationError(err)
      },
    }
  )

  function onAgreementChange(value) {
    setAgreementValue(!value)
  }

  async function saveQuotation(libraryDocuments = []) {
    if (quotationCloneId) {
      const customerSignatureFileObj = await uploadCustomerSignature({
        url: 'signature/customer',
        additionalData: { token },
      })

      saveQuotationCloneMutation({
        data: {
          offer_id: quotationCloneId,
          token,
          ...customerSignatureFileObj,
        },
      })
    } else {
      if (!canManageProject()) return

      const data = {
        step: 2,
        agreement: Number(agreementValue),
        has_inspection_file: inspectionCheckboxValue ? 1 : 0,
        vat: vatValue,
      }

      let headSignatureFileObj = { head_signature_id: null }

      if (!isHeadAutoSigned) {
        headSignatureFileObj = await uploadHeadSignature()
      } else {
        const signatureId = userData?.data?.draft_signature?.id
        if (signatureId) {
          headSignatureFileObj.head_signature_id = signatureId
        }
      }

      const customerSignatureFileObj = await uploadCustomerSignature({})
      const additionalDocumentIds = await uploadAdditionalDocument()

      const additional_doc_ids = []

      if (additionalDocumentIds?.length) {
        additional_doc_ids.push(...additionalDocumentIds)
      }

      if (libraryDocuments?.length) {
        additional_doc_ids.push(...libraryDocuments)
      }

      saveQuotationMutation({
        offerId,
        data: {
          ...data,
          ...headSignatureFileObj,
          ...customerSignatureFileObj,
          additional_doc_ids,
        },
      })
    }
  }

  function handleAdditionFileDrag(info) {
    if (!info) return
    setAdditionalDocument([...info?.fileList])
  }

  function handleAdditionFileRemove() {
    setAdditionalDocument('')
  }

  function handleDeleteAdditionalDocument(id) {
    deleteAdditionalDocumentMutation({
      offerId,
      fileId: id,
    })
  }

  function saveDisabledHelper() {
    const loading = getLoadingState()
    if (quotationCloneId && customerSignatureSrc) {
      return true
    }
    if (quotationCloneId && !loading) {
      return false
    }

    if (loading || !canManageProject()) {
      return true
    }
    return false
  }

  function disableCustomerSignatureDelete() {
    if (isCustomerSignatureExist) {
      return true
    }
    if (savingQuotationClone) {
      return true
    }
    if (saving) {
      return true
    }
    return false
  }

  function onVatChange(e) {
    setVatValue(e.target.value)
  }

  async function uploadHeadSignature() {
    if (headquartersSignatureSrc) return {}
    if (headquartersSignature && !uploadedHeadId) {
      const quotationHeadQuartersSignatureFile = !headquartersSignatureSrc
        ? await dataUrlToFile(
            headquartersSignature,
            'headquartersSignature.png'
          )
        : ''
      let data = {
        file: quotationHeadQuartersSignatureFile,
        media_type: 'head_signature',
      }
      let response = await uploadFile({ url: 'signature', data })
      setUploadedHeadId(response?.data?.id)

      return { head_signature_id: response?.data?.id }
    }
    if (uploadedHeadId) {
      return { head_signature_id: uploadedHeadId }
    }
  }

  async function uploadCustomerSignature({
    url = 'signature',
    additionalData = {},
  }) {
    if (customerSignatureSrc) return {}
    if (customerSignature && !uploadedCustomerId) {
      const quotationCustomerSignatureFile = !customerSignatureSrc
        ? await dataUrlToFile(customerSignature, 'customerSignature.png')
        : ''
      let data = {
        file: quotationCustomerSignatureFile,
        media_type: 'customer_signature',
        ...additionalData,
      }
      let response = await uploadFile({ url, data })
      setUploadedCustomerId(response?.data?.id)
      setCustomerSignatureDate(response?.data?.created_at)
      return { customer_signature_id: response?.data?.id }
    }
    if (uploadedCustomerId) {
      return { customer_signature_id: uploadedCustomerId }
    }
  }

  async function uploadAdditionalDocument() {
    if (!additionalDocument?.length) return
    let additionalDocumentIds = []

    for (let document of additionalDocument) {
      const file = document?.originFileObj
      if (file) {
        const res = await uploadFile({
          url: 'additional-doc',
          data: { file },
        })
        additionalDocumentIds.push(res?.data?.id)
      }
    }

    return additionalDocumentIds
  }

  function getLoadingState() {
    return saving || isUploading || savingQuotationClone
  }

  function getValidationError() {
    if (getLoadingState()) return {}

    const save = handleValidationError(saveError)
    const upload = handleValidationError(uploadError)
    return { ...save, ...upload }
  }

  function handleQuotationCloneLanguageChange(lang) {
    if (!quotationCloneId) return
    changeQuotationCloneLanguage(lang)
  }

  function changeQuotationCloneLanguage(lang) {
    i18n.changeLanguage(lang)
    setQuotationCloneLanguage(lang)
  }

  return {
    disableCustomerSignatureDelete: disableCustomerSignatureDelete(),
    customerSignatureError:
      saveQuotationCloneError?.response?.data?.validation_error
        ?.quot_customer_signature,
    quotationSaveErrors: saveError?.response?.data?.errors,
    userSignatureData: userData?.data?.draft_signature,
    validationError: getValidationError(),
    handleQuotationCloneLanguageChange,
    offerFileList: additionalDocument,
    handleDeleteAdditionalDocument,
    setShowDocumentLibraryModal,
    setInspectionCheckboxValue,
    showDocumentLibraryModal,
    saving: getLoadingState(),
    setShowCustomMessageModal,
    handleAdditionFileRemove,
    inspectionCheckboxValue,
    handleAdditionFileDrag,
    quotationCloneLanguage,
    customerSignatureDate,
    setIsHeadAutoSigned,
    showCustomMessageModal,
    saveDisabledHelper,
    onAgreementChange,
    isHeadAutoSigned,
    agreementValue,
    saveQuotation,
    onVatChange,
    activities,
    materials,
    vatValue,
    t,
  }
}
