import { useMenuLinkWrapper } from './hooks'
import { Link } from 'react-router-dom'

const MenuLinkWrapper = ({ backgroundColor, borderColor, title, path }) => {
  const { LinkIcon, t } = useMenuLinkWrapper(title)

  return (
    <Link
      style={{
        border: `2px solid ${borderColor}`,
        backgroundColor,
      }}
      to={path}
    >
      <div className='dashboard__menu-links__content'>
        <LinkIcon />
        <div className='dashboard__menu-links__content__title'>{t(title)}</div>
      </div>
    </Link>
  )
}

export default MenuLinkWrapper
