import { addOfferCheckRequest, userInfoRequest } from 'src/services'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useState, useMemo, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFileUpload } from 'src/hooks'
import { OPTIONS_TYPE } from 'src/common'
import { useParams } from 'react-router'
import { message, Form } from 'antd'
import {
  handleNonValidationError,
  handleValidationError,
  showCheckPriceDetails,
  canManageProject,
  joinPanels,
} from 'src/utils'

export function useCheckWizard({
  panels = [],
  converters = [],
  mountingSystems = [],
  wifiModules = [],
  powerOptimizers = [],
  options = [],
  correction = 0,
  totals = {},
  signatureSrc,
  projectDiscount,
  showAdditionalPriceDetails,
  offerId,
}) {
  const priceRef = useRef(false)
  const { projectId } = useParams()

  const [correctionValue, setCorrectionValue] = useState(() =>
    Number(correction || 0)
  )
  const [discount, setDiscount] = useState(projectDiscount)
  const [showPriceDetails, setShowPriceDetails] = useState(
    !!showAdditionalPriceDetails
  )

  const [uploadedSignatureId, setUploadedSignatureId] = useState(null)
  const [isAutoSigned, setIsAutoSigned] = useState(false)

  const queryClient = useQueryClient()
  const [isSignatureModalOpen, setSignatureModalState] = useState(false)
  const [signatureImage, setSignatureImage] = useState(() => {
    return signatureSrc
      ? `${process.env.REACT_APP_BACKEND_URI}/storage/${signatureSrc}`
      : null
  })

  const { t } = useTranslation()

  const { uploadFile, isUploading, uploadError } = useFileUpload()

  const { data: userData, isLoading: isUserDataLoading } = useQuery(
    'user_info',
    userInfoRequest
  )

  const materials = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.material
    )
  }, [options])

  const activities = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.activity
    )
  }, [options])

  const setInitialValuesHelper = (arr = [], pricesObject = {}, params) => {
    if (!arr?.length) return

    arr.forEach((item) => {
      const price = item?.selling_price
        ? Number(item?.selling_price)
        : Number(item?.[params?.product]?.selling_price)

      const quantity = item?.quantity || item?.hours

      pricesObject[
        `ind_selling_price_${params?.product}_${
          params?.product === 'panel' ? item?.panel?.id : item.id
        }`
      ] = price
      pricesObject[`purchase_price_${params?.product}_${item.id}`] = Number(
        quantity * Number(item?.[params?.product]?.purchase_price)
      ).toFixed(2)

      pricesObject[`total_selling_price_${params?.product}_${item.id}`] =
        Number(quantity * price).toFixed(2)
    })
  }

  const [projectPrices, setProjectPrices] = useState(() => {
    const values = {}

    setInitialValuesHelper(joinPanels(panels), values, { product: 'panel' })
    setInitialValuesHelper(converters, values, {
      product: 'converter',
    })
    setInitialValuesHelper(mountingSystems, values, {
      product: 'mounting_system',
    })
    setInitialValuesHelper(wifiModules, values, {
      product: 'wifi_module',
    })
    setInitialValuesHelper(powerOptimizers, values, {
      product: 'power_optimizer',
    })

    setInitialValuesHelper(activities, values, {
      product: 'optionable',
    })

    setInitialValuesHelper(materials, values, {
      product: 'optionable',
    })

    return values
  })

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(projectPrices)
  }, [projectPrices])

  useEffect(() => {
    if (!signatureSrc) {
      setSignatureImage(null)
    }
  }, [signatureSrc])

  useEffect(() => {
    if (uploadedSignatureId && !signatureImage) {
      setUploadedSignatureId(null)
    }
  }, [signatureImage])

  const onCorrectionChange = (value) => {
    priceRef.current = true

    setCorrectionValue(value || 0)
  }

  const onDiscountChange = (value) => {
    priceRef.current = true

    setDiscount(value || 0)
  }

  const getSubTotalSellingPrice = () => {
    if (!priceRef.current && totals?.subtotal_selling_price) {
      return totals?.subtotal_selling_price
    } else {
      let sum = 0
      for (let key in projectPrices) {
        if (key.includes('total_selling_price')) {
          sum += Number(projectPrices[key])
        }
      }
      return sum
    }
  }

  const getProductSellingPrices = (productName) => {
    const selling_prices = []
    if (productName === 'panel') {
      for (let item of panels) {
        selling_prices.push({
          id: Number(item?.id),
          selling_price: Number(
            projectPrices[`ind_selling_price_panel_${item?.panel?.id}`]
          ),
        })
      }
    } else {
      for (let key in projectPrices) {
        if (key.includes(`ind_selling_price_${productName}`)) {
          const name = key.split('_')
          selling_prices.push({
            id: Number(name[name.length - 1]),
            selling_price: Number(projectPrices[key]),
          })
        }
      }
    }

    return selling_prices
  }

  const getPurchasePrice = () => {
    if (!priceRef.current && totals?.total_purchase_price) {
      return totals?.total_purchase_price
    } else {
      let sum = 0
      for (let key in projectPrices) {
        if (key.includes('purchase_price')) {
          sum += Number(projectPrices[key])
        }
      }
      return sum
    }
  }

  const subTotalSellingPrice = useMemo(
    () => getSubTotalSellingPrice(),
    [projectPrices]
  )

  const purchasePrice = useMemo(() => getPurchasePrice(), [projectPrices])

  const totalSellingPrice = useMemo(() => {
    const discountedPrice = subTotalSellingPrice * (discount / 100)
    if (!priceRef.current && totals?.total_selling_price) {
      return totals?.total_selling_price
    } else {
      return subTotalSellingPrice + Number(correctionValue) - discountedPrice
    }
  }, [correctionValue, subTotalSellingPrice, discount])

  const margin = useMemo(() => {
    if (!priceRef.current && totals?.margin) {
      return totals?.margin
    } else {
      return totalSellingPrice - Number(purchasePrice)
    }
  }, [purchasePrice, totalSellingPrice])

  const marginPercent = useMemo(() => {
    if (!priceRef.current && totals?.margin_in_percent) {
      return totals?.margin_in_percent
    } else {
      if (totalSellingPrice === 0) return 0
      return (margin / Math.abs(totalSellingPrice)) * 100
    }
  }, [margin, totalSellingPrice])

  const {
    mutate: addCheckMutation,
    isLoading: adding,
    error: addError,
  } = useMutation(({ offerId, data }) => addOfferCheckRequest(offerId, data), {
    onSuccess: () => {
      invalidateProject()
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const toggleSignatureModal = () => {
    if (!canManageProject()) return
    return setSignatureModalState((prev) => !prev)
  }

  const addCheck = async (values) => {
    try {
      const { installation_week, signature } = values
      const data = {
        step: 1,
        show_additional_price_details: showPriceDetails ? 1 : 0,
        correction: Number(correctionValue),
        project_discount: discount,
        offer_panels: JSON.stringify(getProductSellingPrices('panel')),
        offer_converters: JSON.stringify(getProductSellingPrices('converter')),
        offer_mounting_systems: JSON.stringify(
          getProductSellingPrices('mounting_system')
        ),
        offer_wifi_modules: JSON.stringify(
          getProductSellingPrices('wifi_module')
        ),
        offer_power_optimizers: JSON.stringify(
          getProductSellingPrices('power_optimizer')
        ),
        offer_options: JSON.stringify(getProductSellingPrices('optionable')),
        installation_week,
      }

      let signatureObj = { signature_id: null }

      if (!isAutoSigned) {
        signatureObj = await uploadSignatureFile(signature)
      } else {
        const signature_id = userData?.data?.draft_signature?.id
        if (signature_id) {
          signatureObj.signature_id = signature_id
        }
      }

      addCheckMutation({
        offerId,
        data: { ...data, ...signatureObj },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onSellingPriceChange = (value) => {
    priceRef.current = true

    setProjectPrices((prev) => {
      return {
        ...prev,
        ...value,
      }
    })
  }

  function getValidationError() {
    const add = handleValidationError(addError)
    const upload = handleValidationError(uploadError)
    return { ...add, ...upload }
  }

  function getLoadingState() {
    return adding || isUploading
  }

  function invalidateProject() {
    queryClient.invalidateQueries(['offer', offerId])
    queryClient.invalidateQueries(['projects', projectId])

    message.success(`${t('saved_successfully')}`)
  }

  async function uploadSignatureFile(file) {
    if (signatureSrc) return {}
    if (!uploadedSignatureId) {
      const uploadVariables = {
        url: 'signature',
        data: {
          file,
          media_type: 'signature',
        },
      }
      let response = await uploadFile(uploadVariables)
      setUploadedSignatureId(response?.data?.id)
      return { signature_id: response?.data?.id }
    } else {
      return { signature_id: uploadedSignatureId }
    }
  }

  return {
    canSeePriceDetails: showCheckPriceDetails() && showPriceDetails,
    userSignatureData: userData?.data?.draft_signature,
    error: getValidationError(),
    adding: getLoadingState(),
    subTotalSellingPrice,
    isSignatureModalOpen,
    toggleSignatureModal,
    onSellingPriceChange,
    setShowPriceDetails,
    onCorrectionChange,
    totalSellingPrice,
    setSignatureImage,
    isUserDataLoading,
    onDiscountChange,
    setProjectPrices,
    showPriceDetails,
    setIsAutoSigned,
    correctionValue,
    signatureImage,
    projectPrices,
    purchasePrice,
    isAutoSigned,
    marginPercent,
    activities,
    materials,
    addCheck,
    discount,
    margin,
    form,
    t,
  }
}
