import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { MdOutlineDone, MdDeleteForever } from 'react-icons/md'
import { Collapse, Form, Input, InputNumber } from 'antd'
import { useRenderChildOptions } from './hooks'

import { ShowFieldError } from 'src/common'

const RenderChildOptions = ({ option, attachedOPtion = null }) => {
  const { Panel } = Collapse
  const { TextArea } = Input

  const { removeActivityHandler, error, form, t, addActivityHandler } =
    useRenderChildOptions(attachedOPtion, option)

  return (
    <Collapse expandIconPosition='right' className='child_options'>
      <Panel
        header={option?.name}
        className={`child_options__content ${
          attachedOPtion && 'child_options__attached'
        }`}
      >
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            hours: attachedOPtion?.hours || '',
            remarks: attachedOPtion?.remarks || '',
          }}
        >
          <Form.Item
            required
            className={formItemErrorClasses(error?.hours)}
            label={t('number')}
          >
            <Form.Item name='hours' noStyle>
              <InputNumber
                style={inputStyles(error?.hours)}
                disabled={!canManageProject()}
                min={0}
                controls={false}
              />
            </Form.Item>
            <ShowFieldError error={error?.hours} />
          </Form.Item>

          <Form.Item
            className={formItemErrorClasses(error?.remarks)}
            label={t('remarks')}
          >
            <Form.Item name='remarks' noStyle>
              <TextArea
                style={inputStyles(error?.remarks)}
                disabled={!canManageProject()}
              />
            </Form.Item>
            <ShowFieldError error={error?.remarks} />
          </Form.Item>

          <div className='child_options__footer'>
            <MdOutlineDone
              onClick={addActivityHandler}
              className='icon add-icon'
            />
            <MdDeleteForever
              onClick={removeActivityHandler}
              className='icon delete-icon'
            />
          </div>
        </Form>
      </Panel>
    </Collapse>
  )
}

export default RenderChildOptions
