import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form, message } from 'antd'
import {
  attachPowerOptimizerToProjectRequest,
  allPowerOptimizersRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  isBottom,
  flatPaginatedData,
} from 'src/utils'

export function useAttachPowerOptimizer({
  data,
  deletePowerOptimizer,
  projectCompanyId,
}) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { md, sm } = useBreakpoint()
  const layout = {
    labelCol: { span: !md ? 8 : !sm ? 6 : 6 },
    wrapperCol: { span: 20 },
  }

  const [powerOptimizer, setPowerOptimizer] = useState(data?.power_optimizer)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { offerId } = useParams()

  const queryClient = useQueryClient()

  const {
    data: powerOptimizers,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['powerOptimizers', projectCompanyId],
    ({ pageParam = 1 }) =>
      allPowerOptimizersRequest({
        page: pageParam,
        companyId: projectCompanyId,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    form.resetFields()
    if (openConfirmationModal) {
      closeConfirmationModal()
    }
  }, [data])

  const clearForm = () => {
    if (!data) {
      form.resetFields()
      setPowerOptimizer(null)
      deletePowerOptimizer()
    }
    if (data && data?.power_optimizer?.name !== powerOptimizer?.name) {
      form.resetFields()
      setPowerOptimizer(data?.power_optimizer)
    }
    if (data && data?.power_optimizer?.name === powerOptimizer?.name) {
      message.success(` ${t('updated_successfully')}`)
    }
  }

  const {
    mutate: addPowerOptimizerToProject,
    isLoading: isAdding,
    error: addIngError,
  } = useMutation(attachPowerOptimizerToProjectRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
        clearForm()
      }
    },
    onError: (error) => handleNonValidationError(error),
  })

  function attachPowerOptimizer(values) {
    if (!canManageProject()) return

    return addPowerOptimizerToProject({
      offer_id: offerId,
      power_optimizer_id: powerOptimizer?.id,
      ...values,
    })
  }

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false)
  }

  const openConfirmationModal = () => {
    setShowConfirmationModal(true)
  }

  const finishHandler = (values) => {
    const { quantity } = values || {}
    attachPowerOptimizer({
      quantity,
    })
  }

  const powerOptimizerChangeHandler = (val) => {
    form.setFieldsValue({
      type: '',
      quantity: '',
    })

    let tempPowerOptimizers = flatPaginatedData(powerOptimizers)

    setPowerOptimizer(tempPowerOptimizers?.find((el) => el.name === val))
  }

  const clickHandler = () => {
    if (!canManageProject()) return
    if (data) {
      return openConfirmationModal()
    } else {
      deletePowerOptimizer()
    }
  }

  function fetchOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return
    fetchNextPage()
  }

  return {
    attachPowerOptimizer,
    addPowerOptimizerToProject,
    powerOptimizers: powerOptimizers?.pages,
    powerOptimizer,
    isAdding,
    setPowerOptimizer,
    error: handleValidationError(addIngError),
    layout,
    showConfirmationModal,
    closeConfirmationModal,
    openConfirmationModal,
    t,
    form,
    finishHandler,
    powerOptimizerChangeHandler,
    clickHandler,
    fetchOnScroll,
  }
}
