function toFixedNumberHelper(value, sign = '') {
  if (value === null || value === undefined) return
  return `${value?.toFixed(2)} ${sign}`
}

export const columns = (t) => [
  {
    title: t('sales_person'),
    dataIndex: 'manager_name',
  },
  {
    title: t('project_reference'),
    dataIndex: 'reference',
  },
  {
    title: t('project_name'),
    dataIndex: 'project_name',
  },
  {
    title: t('commission'),
    dataIndex: 'commission_percent',
    render: (value) => toFixedNumberHelper(value, '%'),
  },
  {
    title: `${t('commission_amount')}`,
    dataIndex: 'commission_amount',
    render: (value) => toFixedNumberHelper(value),
  },
  {
    title: t('vat'),
    dataIndex: 'vat_amount',
    render: (value) => toFixedNumberHelper(value),
  },

  {
    title: t('total_selling_price'),
    dataIndex: 'total_selling_price',
    render: (value) => toFixedNumberHelper(value),
  },

  {
    title: t('final_price'),
    dataIndex: 'final_price',
    render: (value) => toFixedNumberHelper(value),
  },
]
