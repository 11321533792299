import { useQueryClient, useMutation } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { deleteOfferRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export const useOfferDeletionModal = (offerId) => {
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const { t } = useTranslation()

  const { mutate: deleteOfferMutation, isLoading } = useMutation(
    () => deleteOfferRequest(offerId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['offers', projectId])
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  return { t, deleteOfferMutation, isLoading }
}
