import { RightOutlined } from '@ant-design/icons'
import { TableSettings } from '../TableSettings'
import { useTranslation } from 'react-i18next'
import { ProjectInfo } from './components'
import { formattedDate } from 'src/utils'
import { Link } from 'react-router-dom'
import { statuses } from 'src/common'
import { Pagination } from 'antd'

const TicketView = ({
  paginationInfo = {},
  handlePageChange,
  projects = [],
  deleteProject,
  archiveProject,
}) => {
  const { t } = useTranslation()

  return (
    <div className='ticket__view'>
      <div className='tickets__list'>
        {projects.map((project) => {
          return (
            <div className='tickets__list__item'>
              <div className='tickets__list__item__header'>
                <div className='project_id'>
                  {t('project')} # {project?.id}
                </div>

                <div
                  className={`project_status ${
                    project?.archived && 'project_archived'
                  }`}
                >
                  {project?.archived
                    ? t('archived')
                    : t(
                        statuses.find(
                          (status) => status.id === project?.status_id
                        )?.name
                      )}
                </div>
              </div>

              <ProjectInfo value={project?.name} title={t('project_name')} />

              <ProjectInfo
                value={project?.manager_name}
                title={t('account_manager')}
              />

              <ProjectInfo title={t('reference')} value={project?.reference} />

              <ProjectInfo
                value={formattedDate(project?.updated_at)}
                title={t('date')}
              />

              <ProjectInfo
                title={t('type')}
                value={
                  (project?.type === 1 && 'Private') ||
                  (project?.type === 2 && 'Business')
                }
              />

              <ProjectInfo title={t('city')} value={project?.location} />

              <div className='tickets__list__item__footer'>
                <TableSettings
                  placement='topLeft'
                  isArchived={project?.archived}
                  onDelete={() => deleteProject(project?.id)}
                  onArchive={() => archiveProject(project?.id)}
                />
                <Link to={`/project/${project?.id}/offer`}>
                  <RightOutlined />
                </Link>
              </div>
            </div>
          )
        })}
      </div>

      <Pagination
        className='tablet__pagination'
        current={paginationInfo?.page}
        total={paginationInfo?.total}
        onChange={handlePageChange}
      />
    </div>
  )
}

export default TicketView
