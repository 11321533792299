import { useUploadDocumentDraggable } from './hooks'
import UploadedDocuments from './UploadedDocuments'
import { InboxOutlined } from '@ant-design/icons'
import { canManageProject } from 'src/utils'

const UploadDocumentDraggable = ({
  text,
  multiple = false,
  accept = '*',
  handleFileDrag = () => {},
  offerFileSrc = '',
  maxCount = false,
  handleRemove = () => {},
  deleteAdditionalDocument = () => {},
  disabled = false,
  fileList = [],
  error = '',
  additionalText = null,
}) => {
  const { t, Dragger } = useUploadDocumentDraggable()

  return (
    <div className={`uploaddoc ${error ? 'additional__doc__error' : ''}`}>
      <Dragger
        disabled={!canManageProject() || disabled}
        beforeUpload={() => false}
        onChange={handleFileDrag}
        defaultFileList={offerFileSrc}
        multiple={multiple}
        maxCount={maxCount}
        accept={accept}
        fileList={fileList}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{text || t('delivered_goods')}</p>
        {additionalText}
      </Dragger>

      <div className='additional__doc__error__message'>
        {error && <p style={{ paddingTop: '2rem' }}>{error}</p>}
      </div>

      <div className='uploaddoc__uploaded-documents'>
        <UploadedDocuments
          deleteAdditionalDocument={deleteAdditionalDocument}
          data={offerFileSrc?.filter((el) => !el?.parent_id)}
          title={t('see_additional_document')}
          disabled={disabled}
        />

        <UploadedDocuments
          data={offerFileSrc?.filter((el) => !!el?.parent_id)}
          deleteAdditionalDocument={deleteAdditionalDocument}
          title={t('see_documents_from_library')}
          showDocumentTitle={true}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default UploadDocumentDraggable
