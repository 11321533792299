import { ModalButtons } from './components'
import { CustomModal } from 'src/common'
import { Typography, Form } from 'antd'

const FormModalWrapper = ({
  wrapperCol = { span: 20 },
  labelCol = { span: 5 },
  initialValues = {},
  actionButtonTitle,
  className = '',
  closeHandler,
  modalTitle,
  isLoading,
  onFinish,
  children,
  show,
  form,
}) => {
  return (
    <CustomModal show={show} close={closeHandler}>
      <Typography.Title level={3}>{modalTitle}</Typography.Title>
      <div className='formContent'>
        <Form
          initialValues={initialValues}
          wrapperCol={wrapperCol}
          labelCol={labelCol}
          onFinish={onFinish}
          form={form}
        >
          <div className={className}>{children}</div>
        </Form>
      </div>

      <ModalButtons
        actionButtonTitle={actionButtonTitle}
        submitHandler={form.submit}
        closeHandler={closeHandler}
        isLoading={isLoading}
      />
    </CustomModal>
  )
}

export default FormModalWrapper
