import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { quotationTableColumns } from './table/Column'

const TableQuotation = ({ title = '', data = [], product = '' }) => {
  const { t } = useTranslation()
  return (
    <Table
      className='table'
      bordered
      columns={quotationTableColumns(title, product, t)}
      dataSource={data}
      pagination={false}
    />
  )
}

export default TableQuotation
