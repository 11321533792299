import axios from '../../axios'

export const offerInfoRequest = async (id) => {
  return await axios.get(`api/offers/${id}`)
}

export const allOffersRequest = (context) => {
  const { queryKey } = context || {}
  const { page, size, isConfirmed } = queryKey?.[1] || {}
  return axios.get(
    `api/offers?page=${page}&per_page=${size}${
      isConfirmed ? `&order_created_at=1` : ''
    }`
  )
}

export const offersRequest = async (id, pageParam) => {
  return await axios.get(
    `api/offers?project_id=${id}&page=${pageParam}&per_page=5`
  )
}

export const offerSignatureRequest = async (token) => {
  return await axios.get(`api/offers/signature?token=${token}`)
}

export const createOfferRequest = async (data) => {
  return await axios.post('api/offers', data)
}

export const removeOfferProductRequest = async (data) => {
  return await axios.post('api/offers/remove_attach', data)
}

export const attachPanelToProjectRequest = async (data) => {
  return await axios.post('api/offers/attach_panel', data)
}

export const attachPowerOptimizerToProjectRequest = async (data) => {
  return await axios.post('api/offers/attach_power_optimizer', data)
}

export const attachConverterToProjectRequest = async (data) => {
  return await axios.post('api/offers/attach_converter', data)
}

export const attachMountingSystemToProjectRequest = async (data) => {
  return await axios.post('api/offers/attach_mounting_system', data)
}

export const attachWifiModuleToProjectRequest = async (data) => {
  return await axios.post('api/offers/attach_wifi_module', data)
}

export const attachOptionRequest = async (data) => {
  return await axios.post('api/offers/attach_option', data)
}

export const sendMailToCustomerRequest = async (offerId, data) => {
  return await axios.post(`api/offers/${offerId}/notification`, data)
}

export const saveQuotationCloneRequest = async (data) => {
  return await axios.post('api/offers/store_signature', data)
}

export const confirmOrderRequest = async (id) => {
  return await axios.patch(`api/offers/${id}/order_step`)
}

export const saveOfferDeliveryRequest = async (id, data) => {
  return await axios.patch(`api/offers/${id}/delivery_step`, data)
}

export const saveQuotationRequest = async (id, data) => {
  return await axios.patch(`api/offers/${id}/quotation_step`, data)
}

export const addOfferCheckRequest = async (id, data) => {
  return await axios.patch(`api/offers/${id}/check_step`, data)
}

export const deleteOfferRequest = async (id) => {
  return await axios.delete(`api/offers/${id}`)
}

export const deleteMultipleOfferFiles = async (offerId, data) => {
  return await axios.delete(`api/offers/${offerId}/files`, { data })
}

export const deleteOfferDeliveryImageRequest = async (offerId, fileId) => {
  return await axios.delete(`api/offers/${offerId}/files/${fileId}`)
}

export const deleteAdditionalDocumentRequest = async (offerId, fileId) => {
  return await axios.delete(`api/offers/${offerId}/files/${fileId}`)
}
