import { initializeApp } from 'firebase/app'
import { getToken, getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

let messaging = null

;(async function init() {
  if (!(await isSupported())) return

  messaging = getMessaging(firebaseApp)
})()

export const getFirebaseToken = async () => {
  try {
    if (!(await isSupported())) return
    const permission = await Notification.requestPermission()
    if (permission !== 'granted') return
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
    })
  } catch (error) {
    console.error(error)
  }
}
