import { useTranslation } from 'react-i18next'

const LeadsTableColumns = () => {
  const { t } = useTranslation()

  return [
    {
      title: t('id'),
      dataIndex: 'id',
      sorter: true,
    },

    {
      title: t('client_name'),
      dataIndex: 'name',
      sorter: true,
      render: (text) => {
        return <div className='client__name'>{text}</div>
      },
    },

    {
      title: t('mail'),
      dataIndex: 'email',
    },

    {
      title: t('phone_number'),
      dataIndex: 'phone_number',
    },

    {
      title: t('city'),
      dataIndex: 'city',
      sorter: true,
    },

    {
      title: t('lead_from'),
      dataIndex: 'source_name',
    },
  ]
}

export default LeadsTableColumns
