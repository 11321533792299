import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import {
  ArrowsAltOutlined,
  CloseOutlined,
  ShrinkOutlined,
} from '@ant-design/icons'

function Sidebar({
  projectViewModalOnClick,
  isCondense,
  condenseModalOnClick,
}) {
  const { t } = useTranslation()

  return (
    <div className='project-view-modal-header'>
      <h2>{t('schedule_task')}</h2>

      <div className='header-button-container'>
        <button
          onClick={() => {
            condenseModalOnClick()
          }}
          className='condense-btn'
        >
          {isCondense ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
          {isCondense ? t('expand') : t('condense')}
        </button>

        <div className='modal-corner-btns'>
          <button onClick={projectViewModalOnClick}>
            <CloseOutlined />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
