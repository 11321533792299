import TabSelectorOption from './TabSelectorOption'
import { useEnabledFeatures } from 'src/hooks'
import { isSuperUser } from 'src/utils'

const TabSelector = ({
  companyTabError,
  companySections,
  setActiveTab,
  companyData,
  activeTab,
}) => {
  const { isNewLeadsEnabled } = useEnabledFeatures()

  return (
    <div className='company__tab-selector'>
      <TabSelectorOption
        isError={!!companyTabError?.setupFields}
        title={companySections.SETUP}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />

      <TabSelectorOption
        isError={!!companyTabError?.financialInformationFields}
        title={companySections.FINANCIAL_INFORMATION}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />

      <TabSelectorOption
        isError={!!companyTabError?.emailInformationFields}
        title={companySections.EMAIL_INFORMATION}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />

      {isNewLeadsEnabled && !!companyData && (
        <TabSelectorOption
          title={companySections.LEAD_INTEGRATION}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      )}

      {isSuperUser() && (
        <TabSelectorOption
          title={companySections.CONFIGURATION}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      )}
    </div>
  )
}

export default TabSelector
