import { financialFirstTable, financialSecondTable } from './table/Columns'
import { Header, CsvButton } from 'src/common'
import { BsArrowRight } from 'react-icons/bs'
import { useFinancialReport } from './hooks'
import { Redirect } from 'react-router-dom'
import ExpandedTable from './ExpandedTable'
import { Table, DatePicker } from 'antd'
import { isSales } from 'src/utils'

const FinancialReporting = () => {
  const {
    reportData,
    setDateRange,
    exportFinancialReportData,
    isReportDataLoading,
    exporting,
    t,
  } = useFinancialReport()

  if (isSales()) {
    return <Redirect to='/reporting' />
  }

  return (
    <div className='financial'>
      <Header />
      <div className='financial__title'>{t('financial_reporting')}</div>
      <div className='financial__content'>
        <div className='financial__contentitem'>
          <div className='contentheader'>
            <div className='contentheader__title'>- {t('quotations')} -</div>
            <div className='contentheader__datepicker_container'>
              <DatePicker.RangePicker
                className='contentheader__datepicker_container__datepicker'
                format='YY-MM-DD'
                separator={<BsArrowRight color='#bfbfbf' />}
                onChange={(_, dateString) => setDateRange(dateString)}
              />
            </div>
          </div>
          <Table
            scroll={{ x: '992px' }}
            columns={financialFirstTable(t)}
            dataSource={[reportData?.statuses]}
            bordered
            pagination={false}
            loading={isReportDataLoading}
          />
        </div>
        <div className='financial__contentitem'>
          <div className='contentheader'>
            <div className='contentheader__title'>
              - {t('accountmanager_plural')} -
            </div>
          </div>
          <Table
            scroll={{ x: '992px' }}
            columns={financialSecondTable(t)}
            style={{ marginBottom: '2rem' }}
            dataSource={reportData?.reports}
            rowKey={(record) => record.id}
            bordered
            pagination={false}
            expandable={{
              expandedRowRender: (record) => {
                return <ExpandedTable id={record?.id} />
              },
            }}
            loading={isReportDataLoading}
          />
          <div className='contentFooter'>
            <CsvButton
              disabled={
                exporting || isReportDataLoading || !reportData?.reports?.length
              }
              onClick={exportFinancialReportData}
              text={t('download_cvs')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinancialReporting
