import { getCurrentUserId, roles } from 'src/utils'
import axios from '../../axios'

export const userInfoRequest = async () => {
  return await axios.get(`api/users/${getCurrentUserId()}`)
}

export const getTechniciansRequest = async (companyId) => {
  return await axios.get(
    `api/users?role_id[]=${roles.technician}&company_id[]=${companyId}&all=1`
  )
}

export const getSalesRequest = async ({ company_id = '', trashed = '' }) => {
  return await axios.get(
    `api/users?${`role_id[]=${roles.admin}&role_id[]=${roles.sales}&role_id[]=${
      roles.salesPlus
    }${company_id ? `&company_id[]=${company_id}` : ''}${
      trashed && `&trashed=${trashed}`
    }`}`
  )
}

export const getUsersRequest = async ({
  searchKeyword,
  companyIds,
  roleIds,
  page,
  size,
  sort,
}) => {
  return await axios.get(
    `api/users?page=${page}&per_page=${size}${
      searchKeyword && `&search=${searchKeyword}`
    }${sort ? `&sort[]=${sort}` : ''}${roleIds ? roleIds : ''}${
      companyIds ? companyIds : ''
    }`
  )
}

export const getAccountManagersRequest = async (company_id, isSuperUser) => {
  return await axios.get(
    `api/users?${`role_id[]=${roles.admin}&role_id[]=${roles.sales}&role_id[]=${roles.salesPlus}`}${
      isSuperUser ? '' : company_id ? `&company_id[]=${company_id}` : ''
    }&all=1`
  )
}

export const updateUserRequest = async (id, data) => {
  return await axios.put(`api/users/update/${id}`, data)
}

export const updateCurrentUserRequest = async (data) => {
  return await axios.put(`api/users/update/${getCurrentUserId()}`, data)
}

export const deleteUserRequest = async (id) => {
  return await axios.delete(`api/users/${id}`)
}
