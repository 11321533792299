import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addActivityTypeRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddActivityType = (setShowAddTypeModal) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { user } = useSelector((state) => state.globalSlice)

  const closeModal = () => {
    form.resetFields()
    setShowAddTypeModal(false)
  }

  const {
    mutate: addActivityType,
    isLoading,
    error,
  } = useMutation((data) => !isLoading && addActivityTypeRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('activity_types')
      closeModal()
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function handleAddActivityType(values) {
    return addActivityType({ ...values, company_id: user?.company?.id })
  }

  return {
    addActivityTypeError: handleValidationError(error),
    addActivityType: handleAddActivityType,
    isLoading,
    closeModal,
    form,
    t,
  }
}
