import { useState } from 'react'

function useStartFinishTime() {
  const [isTimeOptionsOpen, setIsTimeOptionsOpen] = useState(false)
  const [startTimeActive, setStartTimeActive] = useState(false)
  const [startTime, setStartTime] = useState(false)
  const [finishTime, setFinishTime] = useState(false)
  const [finishTimeActive, setFinishTimeActive] = useState(false)
  const timeArray = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const isAM = hour < 12
      const hour12Format = hour % 12 || 12
      const timeLabel = `${String(hour12Format).padStart(2, '0')}:${String(
        minute
      ).padStart(2, '0')} ${isAM ? 'AM' : 'PM'}`
      const timeName = `${isAM ? '0' : ''}${hour}${String(minute).padStart(
        2,
        '0'
      )}`

      timeArray.push({ label: timeLabel, name: timeName })
    }
  }

  const startTimeActiveOnClick = () => {
    setStartTimeActive(true)
    setFinishTimeActive(false)
    setIsTimeOptionsOpen(true)
  }

  const finishTimeActiveOnClick = () => {
    setStartTimeActive(false)
    setFinishTimeActive(true)
    setIsTimeOptionsOpen(true)
  }

  const setStartTimeOnClick = (time) => {
    console.log('aq var')
    setStartTime(time)
    finishTimeActiveOnClick()
  }

  const setFinishTimeOnClick = (time) => {
    setFinishTime(time)
    setStartTimeActive(false)
    setFinishTimeActive(false)
    setIsTimeOptionsOpen(false)
  }

  const closeOptions = () => {
    setStartTimeActive(false)
    setFinishTimeActive(false)
    setIsTimeOptionsOpen(false)
    console.log('aq var')
  }

  return {
    isTimeOptionsOpen,
    startTime,
    startTimeActive,
    finishTime,
    finishTimeActive,
    timeArray,
    startTimeActiveOnClick,
    finishTimeActiveOnClick,
    setStartTimeOnClick,
    setFinishTimeOnClick,
    closeOptions,
  }
}

export { useStartFinishTime }
