import { CustomUpload, ShowFieldError, InputItem } from 'src/common'
import { Form, Input, Checkbox, Typography } from 'antd'
import { useAcTraject } from './hooks'

const AcTraject = ({
  setFieldsValue = () => {},
  error = {},
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
}) => {
  const { t, uploadChangeHandler, fileList, checkboxChangeHandler } =
    useAcTraject(setFieldsValue, getStepFiles)

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('ac_traject')}
      </Typography.Title>

      <Form.Item
        className={error?.ac_route_run ? 'group-label-error' : ''}
        label={<div>{t('ac_route_run')}</div>}
        required
      >
        <Form.Item name='ac_route_run' noStyle>
          <Checkbox.Group onChange={checkboxChangeHandler}>
            <Checkbox className='wizards_checkbox_space' value={1}>
              {t('pv_distributor_possible')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={2}>
              {t('cablechannel_possible')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={3}>
              {t('empty_pipe_useable')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={4}>
              {t('route_through_possible')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={5}>
              {t('route_outside_possible')}
            </Checkbox>

            <br />
          </Checkbox.Group>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.ac_route_run} />
      </Form.Item>

      <InputItem
        placeholder={t('estimate_length_ac_in_meters')}
        label={t('estimate_length_ac_in_meters')}
        error={error?.ac_length}
        name='ac_length'
      />

      <Form.Item label={t('ac_traject_information')}>
        <Form.Item name='ac_traject_information' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>

      <Form.Item label={t('upload_pictures_ac_traject')}>
        <Form.Item name='acTraject_pictures' noStyle>
          <CustomUpload
            defaultFileList={getStepFiles(4)}
            onRemove={deleteInspectionImage}
            onChange={uploadChangeHandler}
            beforeUpload={() => false}
            listType='picture-card'
            fileList={fileList}
            accept='image/*'
            multiple
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default AcTraject
