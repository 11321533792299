import { DownOutlined } from '@ant-design/icons'
import { NotesIcon, Comment } from './index'
import { useTranslation } from 'react-i18next'

function Notes({ handleToggleNotesContent, isNotesContentOpen }) {
  const { t } = useTranslation()
  const comments = [{ name: 'rame' }, { name: 'rame' }] // TODO

  return (
    <div className='container notes_container'>
      <button
        onClick={handleToggleNotesContent}
        type='button'
        className='header_btn'
      >
        <div className='text_container'>
          <NotesIcon />
          <span>{t('notes_or_comments')}</span>
        </div>
        <DownOutlined
          className={`down_outline ${
            isNotesContentOpen ? 'open_down_outlined' : ''
          }`}
        />
      </button>

      <div className={`content_container ${isNotesContentOpen ? 'open' : ''}`}>
        <div className='content'>
          <div className='notes_input_group'>
            <form>
              <label htmlFor='add_comment'>
                <input type='text' placeholder={t('add_your_comment_here')} />
              </label>
            </form>
          </div>

          {comments?.map((comment, index) => {
            return <Comment key={index} comment={comment} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Notes
