import { readAllNotificationsRequest } from 'src/services'
import { useMutation, useQueryClient } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from 'src/hooks'

export const useNotifications = (handleNotificationClick) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useGetUserInfo()

  const { mutate: markAllAsReadMutation } = useMutation(
    readAllNotificationsRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications', user?.company?.id])
        queryClient.invalidateQueries([
          'notifications_count',
          user?.company?.id,
        ])
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const onNotificationClick = (notification) => {
    handleNotificationClick({
      notificationsId: notification?.id,
      projectId: notification?.data?.project_id,
    })
  }

  return { t, onNotificationClick, markAllAsReadMutation }
}
