import { companyFormConstant } from 'src/screens/Manage/screens/Companies/constants/companyFormConstant'
import AdditionalInformationTab from './tabs/AdditionalInformationTab'
import { ButtonWithLogin, CustomModal, AntdForm } from 'src/common'
import { isSuperUser, appendPrefix, isAdmin } from 'src/utils'
import BasicInformationTab from './tabs/BasicInformationTab'
import IframeInformation from './tabs/IframeInformation'
import ConfigurationTab from './tabs/ConfigurationTab'
import { useCompanyModal } from './useCompanyModal'
import { CustomSpan } from './tabError/tabError'
import { Tabs, Typography, Button } from 'antd'

import PlanningTool from './tabs/PlanningTool'

const CompanyModal = ({ setShowCompanyModal, showCompanyModal }) => {
  const {
    setTermOfConditionsFile,
    setPrivacyStatementSrc,
    setPrivacyStatementFile,
    setTermsOfConditionSrc,
    termsOfConditionSrc,
    privacyStatementSrc,
    companyRequestError,
    isNewLeadsEnabled,
    initialFormValues,
    tennantlogining,
    iframeApiToken,
    categoryError,
    finishHandler,
    clickHandler,
    setLogoFile,
    closeModal,
    isLoading,
    apiToken,
    form,
    logo,
    t,
  } = useCompanyModal(setShowCompanyModal, showCompanyModal)

  return (
    <AntdForm
      onFinish={(formValues) => {
        const formData = {
          ...companyFormConstant,
          ...showCompanyModal?.data,
          ...formValues,
        }

        if (!formData?.country_id) {
          formData.country_id = companyFormConstant.country_id
        }

        finishHandler({
          ...formData,
          phone_number: appendPrefix(formData?.phone_number),
        })
      }}
      initialValues={initialFormValues}
      name='additional-information'
      wrapperCol={{ span: 20 }}
      labelCol={{ span: 7 }}
      form={form}
    >
      <CustomModal show={showCompanyModal?.show} close={() => closeModal()}>
        <Typography.Title level={3}>{t('company')}</Typography.Title>
        <div className='formContent company__form__modal'>
          <Tabs defaultActiveKey='1'>
            <Tabs.TabPane
              key={1}
              tab={
                <CustomSpan
                  text={t('setup')}
                  isError={categoryError?.basicInfoFields}
                />
              }
              className='skrt'
            >
              <BasicInformationTab
                setTermOfConditionsFile={setTermOfConditionsFile}
                setPrivacyStatementFile={setPrivacyStatementFile}
                setPrivacyStatementSrc={setPrivacyStatementSrc}
                setTermsOfConditionSrc={setTermsOfConditionSrc}
                termsOfConditionSrc={termsOfConditionSrc}
                privacyStatementSrc={privacyStatementSrc}
                emailText={showCompanyModal?.data?.email_quotation_text}
                offerText={showCompanyModal?.data?.offerte_link}
                errors={companyRequestError}
                setLogoFile={setLogoFile}
                companyId={showCompanyModal?.data?.id}
                form={form}
                logo={logo}
              />
            </Tabs.TabPane>

            <Tabs.TabPane
              key={2}
              tab={
                <CustomSpan
                  text={t('additional_information')}
                  isError={categoryError?.configTabFields}
                />
              }
              className='skrt'
            >
              <ConfigurationTab
                countryId={showCompanyModal?.data?.country_id}
                data={showCompanyModal?.data}
                errors={companyRequestError}
                form={form}
              />
            </Tabs.TabPane>

            {isSuperUser() ? (
              <Tabs.TabPane
                key={3}
                tab={
                  <CustomSpan
                    text={t('configuration')}
                    isError={categoryError?.additionalInfoFields}
                  />
                }
              >
                <AdditionalInformationTab
                  data={showCompanyModal?.data}
                  errors={companyRequestError}
                  form={form}
                />
              </Tabs.TabPane>
            ) : null}

            {(iframeApiToken || apiToken) && isNewLeadsEnabled ? (
              <Tabs.TabPane
                key={4}
                tab={<CustomSpan text={t('lead_integration')} />}
              >
                <IframeInformation
                  apiToken={apiToken}
                  iframeApiToken={iframeApiToken}
                />
              </Tabs.TabPane>
            ) : null}

            <Tabs.TabPane
              key={5}
              tab={<CustomSpan text={t('planning_tool')} />}
            >
              <PlanningTool form={form} errors={companyRequestError} />
            </Tabs.TabPane>
          </Tabs>
        </div>

        <div className='modalContent__button'>
          {Object.keys(showCompanyModal?.data)?.length && !isAdmin() ? (
            <ButtonWithLogin
              onClick={clickHandler}
              disabled={tennantlogining}
              className='modalContent__button__child'
              type='secondary'
              text={t('login_as_tenant')}
            />
          ) : null}

          <Button
            className='modalContent__button__child'
            type='primary'
            disabled={isLoading}
            onClick={form.submit}
          >
            {!Object.keys(showCompanyModal?.data)?.length
              ? t('save')
              : t('update')}
          </Button>
        </div>
      </CustomModal>
    </AntdForm>
  )
}

export default CompanyModal
