import { useQueryClient, useMutation } from 'react-query'
import { appendCompanyId, isSuperUser } from 'src/utils'
import { addBrandRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useGetProductBrand } from 'src/hooks'
import { useSelector } from 'react-redux'

export const useMountingSystemForm = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    mutate: addMountingSystemBrand,
    isLoading: addMountingSystemBrandLoading,
  } = useMutation(addBrandRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('mountingSystemBrands')
    },
  })

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'mountingSystemBrands',
    url: `api/brands?classification=3${appendCompanyId(user?.company?.id)}`,
  })

  const clickHandler = (newBrand) => {
    if (!newBrand) return

    const additionalOptions = {}

    if (isSuperUser()) {
      additionalOptions['company_id'] = user?.company?.id
    }
    addMountingSystemBrand({
      name: newBrand,
      classification: 3,
      ...additionalOptions,
    })
  }

  return {
    mountingSystemBrands: productBrands,
    addMountingSystemBrandLoading,
    addMountingSystemBrand,
    fetchBrandOnScroll,
    clickHandler,
    t,
  }
}
