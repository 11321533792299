import { formItemErrorClasses, inputIconColor } from 'src/utils'
import { ShowFieldError } from 'src/common'
import { AiFillLock } from 'react-icons/ai'
import { Form, Input } from 'antd'

const PasswordInputItem = ({
  placeholder = '',
  autoComplete = '',
  required = true,
  className = '',
  error,
  label,
  name,
}) => {
  return (
    <Form.Item
      className={`custom-antd-input-item ${formItemErrorClasses(error)} ${
        error && 'form-item-border-error '
      } ${className}`}
      required={required}
      label={label}
    >
      <Form.Item name={name}>
        <Input.Password
          placeholder={placeholder}
          prefix={
            <AiFillLock color={inputIconColor(error)} opacity={0.5} size={18} />
          }
          autoComplete={autoComplete}
        />
      </Form.Item>

      <ShowFieldError withHeight error={error} />
    </Form.Item>
  )
}

export default PasswordInputItem
