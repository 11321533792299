export const LeftArrowIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M12.6654 8H3.33203'
        stroke='#606870'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.9987 12.6666L3.33203 7.99992L7.9987 3.33325'
        stroke='#606870'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
