import i18next from 'i18next'

export const useLanguageSelector = () => {
  const defaultValue = i18next.language

  const options = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'nl',
      label: 'Dutch',
    },
  ]

  const languageChangeHandler = (value) => {
    i18next.changeLanguage(value)
  }

  return { languageChangeHandler, defaultValue, options }
}
