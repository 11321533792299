import { usePanelsAccordion } from './usePanelsAccordion'
import { Panel, AddInputs } from './components'
import { AccordionWrapper } from 'src/common'
import { canManageProject } from 'src/utils'
import { Collapse } from 'antd'

const Panels = ({ panels = [], projectCompanyId }) => {
  const {
    removePanel,
    removingPanel,
    showPanelAddingForm,
    openPanelAddingForm,
    closePanelAddingForm,
    translate,
  } = usePanelsAccordion()

  return (
    <div className='survey__accordion firstaccordion'>
      <AccordionWrapper defaultActiveKey={panels?.length ? ['1'] : null}>
        <Collapse.Panel header={`${translate('panels_upper')}*`} key='1'>
          <div className='panels'>
            {panels?.map((item) => (
              <Panel
                deletePanel={removePanel}
                deleteDisable={removingPanel}
                data={item}
                key={item?.id}
                projectCompanyId={projectCompanyId}
              />
            ))}
          </div>
          {showPanelAddingForm ? (
            <Panel
              deletePanel={closePanelAddingForm}
              projectCompanyId={projectCompanyId}
            />
          ) : null}

          <AddInputs
            onClick={openPanelAddingForm}
            disabled={showPanelAddingForm || !canManageProject()}
          />
        </Collapse.Panel>
      </AccordionWrapper>
    </div>
  )
}

export default Panels
