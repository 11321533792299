import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { handleValidationError } from 'src/utils'

export const useErrorModal = (importError) => {
  const { t } = useTranslation()

  const errorMessage = useMemo(() => {
    if (!importError) return

    const validationError = handleValidationError(importError)

    if (!!validationError && typeof validationError === 'object') {
      const error = Object.values(validationError)?.[0]

      return error?.file || error
    }

    const resp = importError?.request?.response
    if (resp) return JSON.parse(resp)?.message

    return null
  }, [importError])

  return { t, errorMessage }
}
