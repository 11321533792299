import { BsPersonFill, BsFillTelephoneFill } from 'react-icons/bs'
import { ImLocation2 } from 'react-icons/im'
import { GoMailRead } from 'react-icons/go'
import { memo } from 'react'

const Controle = ({
  title = 'controle',
  phoneNumber,
  secondName,
  address,
  name,
  mail,
}) => {
  return (
    <div className='controle'>
      <div className='title'>{title}</div>
      <div className='controle__content'>
        <div className='contentbox'>
          <div className='contentbox__icon'>
            <BsPersonFill />
          </div>
          <div className='text'>{name}</div>
        </div>

        {address && (
          <div className='contentbox'>
            <div className='contentbox__icon'>
              <ImLocation2 />
            </div>
            {secondName && <div className='text'>{secondName}</div>}
            <div className='text'>{address}</div>
          </div>
        )}

        {phoneNumber && (
          <div className='contentbox'>
            <div className='contentbox__icon'>
              <BsFillTelephoneFill />
            </div>

            <div className='text'>{phoneNumber}</div>
          </div>
        )}

        <div className='contentbox'>
          <div className='contentbox__icon'>
            <GoMailRead />
          </div>
          <div className='text'>{mail}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(Controle)
