import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  importActivitiesRequest,
  deleteActivityRequest,
  allActivitiesRequest,
  exportRequest,
} from 'src/services'

export const useActivities = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    size,
    page,
  } = usePageQueryParams()

  const [deleteActivities, setDeleteActivities] = useState({
    show: false,
    data: {},
  })

  const [showActivityModal, setShowActivityModal] = useState({
    show: false,
    data: {},
  })

  const [showAddActivitiesModal, setShowAddActivitiesModal] = useState(false)

  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const [showCSVModal, setShowCSVModal] = useState(false)
  const [importError, setImportError] = useState()

  const [selectedActivitiesIds, setSelectedActivitiesIds] = useState([])
  const rowSelection = rowSelectionActions(setSelectedActivitiesIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const {
    data: activitiesData,
    isLoading: isActivitiesDataLoading,
    isPreviousData,
  } = useQuery(
    ['activities', page, size, sort, searchKeyWord, user?.company?.id],
    () =>
      allActivitiesRequest({
        companyId: user?.company?.id,
        searchKeyWord,
        page,
        sort,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedActivitiesIds,
      'activities',
      activitiesData?.data?.data?.length
    )

  const { mutate: deleteActivity, isLoading: deleting } = useMutation(
    deleteActivityRequest,
    {
      onSuccess: (resp, deletedActivityId) => {
        if (resp?.data?.success) {
          if (selectedActivitiesIds.includes(deletedActivityId)) {
            setSelectedActivitiesIds((prev) =>
              prev.filter((id) => id !== deletedActivityId)
            )
          }

          if (activitiesData?.data?.data.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('activities')
          setDeleteActivities({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importActivities, isLoading: importing } = useMutation(
    importActivitiesRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.refetchQueries('activities')
          setShowCSVModal(false)
        }
      },

      onError: (error) => {
        setImportError(error)
      },
    }
  )
  const { mutate: exportActivitiesMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    })

  const exportActivities = () => {
    exportActivitiesMutation({
      model: 'activities',
    })
  }

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedActivitiesIds,
      table: 'activities',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage activity') ||
          canPerform('manage own activity')
        ) {
          setShowActivityModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    isActivitiesDataLoading: isActivitiesDataLoading || isPreviousData,
    paginationInfo: {
      total: activitiesData?.data?.meta?.total,
      page,
    },
    activities: activitiesData?.data?.data,
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowAddActivitiesModal,
    showAddActivitiesModal,
    selectedActivitiesIds,
    deletingMultipleItem,
    setShowActivityModal,
    setDeleteActivities,
    deleteSelectedItems,
    showActivityModal,
    handlePageChange,
    deleteActivities,
    exportActivities,
    importActivities,
    setShowCSVModal,
    setImportError,
    deleteActivity,
    onSearchClick,
    rowSelection,
    showCSVModal,
    importError,
    importing,
    exporting,
    deleting,
    setSort,
    onRow,
    t,
  }
}
