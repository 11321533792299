import { FormModalWrapper, PasswordInputItem } from 'src/common'
import { useChangePasswordModal } from './hooks'

const ChangePasswordModal = ({ showModal, setShowModal, userData }) => {
  const { submitHandler, cancelHandler, fetchErrors, isLoading, form, t } =
    useChangePasswordModal(setShowModal, userData)

  return (
    <FormModalWrapper
      closeHandler={() => cancelHandler()}
      modalTitle={t('reset_password')}
      actionButtonTitle={t('update')}
      onFinish={submitHandler}
      isLoading={isLoading}
      show={showModal}
      form={form}
    >
      <div className='change__password__container'>
        <PasswordInputItem
          error={fetchErrors?.old_password?.[0]}
          label={t('old_password')}
          name='old_password'
        />

        <PasswordInputItem
          error={fetchErrors?.password?.[0]}
          label={t('new_password')}
          name='password'
        />

        <PasswordInputItem
          error={fetchErrors?.password_confirmation?.[0]}
          label={t('repeat_password')}
          name='password_confirmation'
        />
      </div>
    </FormModalWrapper>
  )
}

export default ChangePasswordModal
