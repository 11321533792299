import ShowCompanyFile from 'src/screens/Manage/screens/Companies/components/CompanyModal/tabs/ShowCompanyFile'
import { Form, Button, Select, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useSetup from './useSetup'
import i18next from 'i18next'
import {
  PhoneNumberInput,
  ShowFieldError,
  CustomUpload,
  SelectItem,
  InputItem,
} from 'src/common'

const Setup = ({
  setPrivacyStatementFile,
  setTermsOfConditionFile,
  setPrivacyStatementSrc,
  setTermsOfConditionSrc,
  termsOfConditionFile,
  privacyStatementFile,
  termsOfConditionSrc,
  privacyStatementSrc,
  disableActionButtons,
  setLogoFile,
  companyData,
  errors,
  show,
  form,
}) => {
  const {
    setCompanyLogoFileList,
    companyLogoFileList,
    uploadHandler,
    countriesData,
    userLanguage,
    t,
  } = useSetup({
    companyData,
    errors,
    form,
  })

  return (
    <div
      style={{
        display: show ? 'grid' : 'none',
      }}
      className='company__tab-container__setup'
    >
      <Form.Item
        className={`company__tab-container__setup__logo-input ${
          userLanguage === 'nl' &&
          'company__tab-container__setup__logo-input--dutch'
        }`}
        label={t('logo')}
        name='logo'
      >
        <CustomUpload
          beforeUpload={() => false}
          listType='picture-card'
          maxCount={1}
          onChange={(info) => {
            uploadHandler(info, 'images', 'logo')
            setLogoFile(info)
            setCompanyLogoFileList([info?.fileList?.[0]])
          }}
          accept='image/*'
          disabled={disableActionButtons}
          fileList={companyLogoFileList?.length ? companyLogoFileList : []}
        >
          <div className='company__tab-container__setup__logo-input__action_buttons'>
            <Button disabled={disableActionButtons} type='primary'>
              {t('upload_new_image')}
            </Button>

            {companyLogoFileList?.length ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  form.setFieldsValue({ logo: null })
                  setLogoFile({})
                  setCompanyLogoFileList([])
                }}
                className='company__tab-container__setup__logo-input__action_buttons__delete-button'
                disabled={disableActionButtons}
                type='default'
              >
                {t('delete')}
              </Button>
            ) : null}
          </div>
        </CustomUpload>
        <ShowFieldError error={errors?.logo} />
      </Form.Item>

      <div className='company__tab-container__setup__input-group'>
        <InputItem error={errors?.name} label={t('name')} name='name' />

        <InputItem
          error={errors?.reference_prefix}
          label={t('reference_prefix')}
          name='reference_prefix'
        />

        <InputItem error={errors?.email} label={t('email')} name='email' />

        <PhoneNumberInput
          wrapperClassName='custom-antd-input-item phone-number'
          error={errors?.phone_number}
          name='phone_number'
          label={t('phone')}
          required={true}
        />

        <InputItem error={errors?.street} label={t('street')} name='street' />

        <InputItem
          error={errors?.house_number}
          label={t('house_number')}
          name='house_number'
        />

        <InputItem
          error={errors?.house_number_suffix}
          label={t('house_number_suffix')}
          name='house_number_suffix'
          required={false}
        />

        <InputItem
          error={errors?.zipcode}
          label={t('zipcode')}
          name='zipcode'
        />

        <InputItem error={errors?.city} label={t('city')} name='city' />

        <SelectItem
          defaultValue={companyData?.country_id}
          error={errors?.country_id}
          label={t('country')}
          name='country_id'
          required={false}
          form={form}
        >
          {countriesData?.data?.map((country) => {
            return (
              <Select.Option key={country?.id} value={country?.id}>
                {i18next.language === 'nl' ? country?.name_du : country?.name}
              </Select.Option>
            )
          })}
        </SelectItem>

        <div />

        <InputItem
          error={errors?.website}
          label={t('website')}
          name='website'
          required={false}
        />

        <Form.Item
          className={`pdf-upload privacy-statement ${
            errors?.privacy_statement_id?.[0] && 'pdf-upload-error'
          }`}
          label={t('privacy_statement')}
          name='privacy_statement'
        >
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            onChange={(info) => {
              uploadHandler(info, 'file', 'privacy_statement')
              setPrivacyStatementFile(info)
            }}
            fileList={
              privacyStatementFile?.file?.name
                ? [privacyStatementFile?.file]
                : []
            }
            accept='.pdf'
          >
            <Button disabled={disableActionButtons} icon={<PlusOutlined />}>
              {t('upload')}
            </Button>
          </Upload>
          {privacyStatementSrc ? (
            <div>
              <ShowCompanyFile
                src={privacyStatementSrc}
                text={t('privacy_statement')}
                onDelete={() => {
                  if (!disableActionButtons) {
                    form.setFieldsValue({
                      privacy_statement: null,
                      privacy_statement_id: null,
                    })
                    setPrivacyStatementSrc(null)
                  }
                }}
                disabled={disableActionButtons}
              />
            </div>
          ) : null}
          <ShowFieldError error={errors?.privacy_statement?.[0]} />
        </Form.Item>

        <div />

        <Form.Item
          className={`pdf-upload ${
            errors?.terms_of_conditions_id?.[0] && 'pdf-upload-error'
          } terms-of-conditions`}
          label={t('terms_of_condition')}
          name='terms_of_conditions'
        >
          <Upload
            beforeUpload={() => false}
            onChange={(info) => {
              uploadHandler(info, 'file', 'terms_of_conditions')
              setTermsOfConditionFile(info)
            }}
            fileList={
              termsOfConditionFile?.file?.name
                ? [termsOfConditionFile?.file]
                : []
            }
            maxCount={1}
            accept='.pdf'
          >
            <Button disabled={disableActionButtons} icon={<PlusOutlined />}>
              {t('upload')}
            </Button>
          </Upload>
          {termsOfConditionSrc ? (
            <div>
              <ShowCompanyFile
                src={termsOfConditionSrc}
                text={t('terms_of_condition')}
                onDelete={() => {
                  if (!disableActionButtons) {
                    form.setFieldsValue({
                      terms_of_conditions: null,
                      terms_of_conditions_id: null,
                    })
                    setTermsOfConditionSrc(null)
                  }
                }}
                disabled={disableActionButtons}
              />
            </div>
          ) : null}
          <ShowFieldError error={errors?.terms_of_conditions?.[0]} />
        </Form.Item>
      </div>
    </div>
  )
}

export default Setup
