import TaskViewRadioSwitcher from './TaskViewRadioSwitcher'
import { useListHeader } from './hooks'

function ListHeader({ isProjectListActive, isCalendarActive, isTasksActive }) {
  const {
    handleProjectListViewClick,
    handleCalendarViewClick,
    handleTasksViewClick,

    t,
  } = useListHeader()

  return (
    <div className='list-header-wrapper'>
      <div
        onClick={handleProjectListViewClick}
        className={`project-list ${
          isProjectListActive && 'project-list__active'
        }`}
      >
        {t('planning_project_list_header_text')}
      </div>
      <div
        className={`task-planning-and-calendar-switcher ${
          !isProjectListActive && 'task-planning-and-calendar-switcher__active'
        }`}
      >
        <div
          onClick={handleTasksViewClick}
          className={`task-planning ${
            isTasksActive && 'task-planning__active'
          }`}
        >
          {t('planning_task_list_header_text')}
        </div>
      </div>

      <TaskViewRadioSwitcher
        handleCalendarViewClick={handleCalendarViewClick}
        handleTasksViewClick={handleTasksViewClick}
        isCalendarActive={isCalendarActive}
        isTasksActive={isTasksActive}
      />
    </div>
  )
}

export default ListHeader
