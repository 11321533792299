import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addMountingSystemRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddMountingSystemModal = (setShowAddMountingSystemModal) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    mutate: addMountingSystem,
    isLoading,
    error,
  } = useMutation(
    (variables) => !isLoading && addMountingSystemRequest(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mountingSystem')
        closeModal()
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const closeModal = () => {
    setShowAddMountingSystemModal(false)
    form.resetFields()
  }

  function handleAddMountingSystem(values) {
    return addMountingSystem({ ...values, company_id: user?.company?.id })
  }

  return {
    addMountingSystemError: handleValidationError(error),
    addMountingSystem: handleAddMountingSystem,
    closeModal,
    isLoading,
    form,
    t,
  }
}
