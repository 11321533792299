import { userFormConstants } from '../constants/userFormConstants'
import UserFormModal from './UserFormModal'
import { useUsersModal } from './hooks'

const UsersModal = ({
  skillsForDropDown = [],
  setAddUserModal,
  roles = [],
  user,
  show,
}) => {
  const { closeModal, isLoading, addUser, error, form, t } = useUsersModal(
    setAddUserModal,
    user
  )

  return (
    <UserFormModal
      skillsForDropDown={skillsForDropDown}
      initialValues={userFormConstants}
      actionButtonTitle={t('save')}
      closeHandler={closeModal}
      isLoading={isLoading}
      onFinish={addUser}
      errors={error}
      roles={roles}
      form={form}
      user={user}
      show={show}
    />
  )
}

export default UsersModal
