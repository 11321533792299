import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useGetProject } from '../../OffersAndComments/hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useFetchCountries } from 'src/hooks'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Form, message } from 'antd'
import { useMemo } from 'react'
import {
  handleNonValidationError,
  handleValidationError,
  appendDutchCode,
  parseDateString,
  getQueryParam,
  appendPrefix,
  formatToYMD,
} from 'src/utils'
import {
  updateProjectRequest,
  createProjectRequest,
  getLeadInfoRequest,
  deleteLeadsRequest,
} from 'src/services'

export const useProjectCreation = () => {
  const { user } = useSelector((state) => state.globalSlice)
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()

  const isLead = getQueryParam('isLead')

  const handleInvalidation = () => {
    queryClient.invalidateQueries('statusCounts')
    queryClient.invalidateQueries('projects')
  }

  const projectIdWithoutQueryString = useMemo(() => {
    return projectId?.split('?')?.[0]
  }, [projectId])

  const { project, isProjectLoading } = useGetProject({
    projectId: isLead ? null : projectIdWithoutQueryString,
  })

  const { isCountriesLoading } = useFetchCountries()

  const { data: leads, isLoading: isLeadsLoading } = useQuery(
    ['leads', projectId],
    () => getLeadInfoRequest(projectId),
    {
      onError: (error) => {
        handleNonValidationError(error)
      },

      select: (resp) => resp?.data?.data,
      enabled: !!isLead,
    }
  )

  const {
    isLoading: isUpdating,
    mutate: updateProject,
    error: updateProjectError,
  } = useMutation(
    (data) => updateProjectRequest(projectIdWithoutQueryString, data),
    {
      onSuccess: () => {
        handleInvalidation()
        message.success(t('updated_successfully'))
      },
    }
  )

  const { mutate: deleteLeadsMutation } = useMutation(() =>
    deleteLeadsRequest([+projectId])
  )

  const {
    isLoading: isCreating,
    mutate: createProject,
    error: createProjectError,
  } = useMutation(
    (data) => {
      if (!isCreating) {
        return createProjectRequest(data)
      }
    },

    {
      onSuccess: (response) => {
        handleInvalidation()

        if (isLead) {
          history.push('/sales-projects')
          message.success(t('project_created_successfully'))
          deleteLeadsMutation()
        } else {
          history.push(`/project/${response?.data?.id}/offer`)
        }
      },
    }
  )

  const generateRequestData = (values) => {
    const dataObject = {
      ...values,
      phone_number: appendPrefix(values?.phone_number),
      mobile: appendPrefix(values?.mobile),
      made_on_date: formatToYMD(values?.made_on_date),
      company_id: user?.company_id,
    }

    if (values?.type === 1) {
      delete dataObject?.company_name
    }

    if (typeof values?.executor_id !== 'number') {
      delete dataObject?.executor_id
    }

    return dataObject
  }

  const updateProjectHandler = (values) => {
    updateProject(generateRequestData(values))
  }

  const createProjectHandler = (values) => {
    createProject(generateRequestData(values))
  }

  const getProjectCreationFormInitialValues = () => {
    let projectState = { made_on_date: new Date() }

    if (project) {
      projectState = {
        ...projectState,
        ...project,
        company_name: project?.type === 2 ? project?.company_name : '',
      }
    }

    if (!project && leads) {
      const { company_id, created_at, id, ip, read_at, updated_at, ...rest } =
        leads
      projectState = { ...projectState, ...rest }
    }

    return {
      type: 1,
      ...projectState,
      phone_number: appendDutchCode(projectState?.phone_number),
      mobile: appendDutchCode(projectState?.mobile),
      made_on_date: parseDateString(projectState?.made_on_date),
    }
  }

  function getCompanyId() {
    let id = null
    if (project) {
      id = project?.company_id
    }

    if (!project && leads) {
      id = leads?.company_id
    }

    return id
  }

  return {
    projectCreationFormInitialValues: getProjectCreationFormInitialValues(),
    isLoading: isProjectLoading || isLeadsLoading || isCountriesLoading,
    getAddressOnInitialRender: !Boolean(project?.id),
    submitHandler:
      !!projectIdWithoutQueryString && !isLead
        ? updateProjectHandler
        : createProjectHandler,
    mutationErrors:
      handleValidationError(updateProjectError) ||
      handleValidationError(createProjectError),
    disableSubmit: isUpdating || isCreating,
    projectCondition: project?.condition,
    projectCompanyId: getCompanyId(),
    project,
    isLead,
    form,
  }
}
