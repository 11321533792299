import { InputItem } from 'src/common'
import { Button, Form } from 'antd'

const SelectRenderInput = ({ menu, disabled, clickHandler, buttonTitle }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form}>
      {menu}
      <div className='custom_dropdown'>
        <InputItem className='custom_dropdown__input' name='inputValue' />

        <Button
          onClick={() => {
            clickHandler(form.getFieldValue('inputValue'))
            form.resetFields()
          }}
          className='custom_dropdown__button'
          disabled={disabled}
          type='primary'
        >
          {buttonTitle}
        </Button>
      </div>
    </Form>
  )
}

export default SelectRenderInput
