import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { ShowFieldError, AgreeDeleteModal } from 'src/common'
import { Select, Form, Button, InputNumber } from 'antd'
import { useAttachWifiToProject } from './hooks'

const WifiModule = ({
  deleteWifi = () => {},
  data = null,
  deleteDisable = false,
  projectCompanyId,
}) => {
  const {
    isAdding,
    error,
    layout,
    wifiModules,
    closeConfirmationModal,
    showConfirmationModal,
    t,
    form,
    clickHandler,
    finishHandler,
    selectChangeHandler,
    fetchWifiModuleOnScroll,
  } = useAttachWifiToProject({ data, deleteWifi, projectCompanyId })

  return (
    <Form
      onFinish={finishHandler}
      className='accordionArrayItem'
      name='wifiModule'
      initialValues={{
        quantity: data?.quantity,
        wifi_module_id: data?.wifi_module_id,
      }}
      form={form}
      {...layout}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Form.Item
        className={formItemErrorClasses(error?.wifi_module_id)}
        required
        label={t('select_wifi_module')}
      >
        <Form.Item name='wifi_module_id' noStyle>
          <Select
            className={error?.wifi_module_id ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={selectChangeHandler}
            onPopupScroll={fetchWifiModuleOnScroll}
          >
            {data?.wifi_module && (
              <Select.Option
                key={data?.wifi_module?.id}
                value={data?.wifi_module?.id}
              >
                {data?.wifi_module?.name}
              </Select.Option>
            )}
            {wifiModules?.map((page) => {
              return page?.data?.data?.map((wifiModule) => {
                if (data?.wifi_module?.id !== wifiModule?.id) {
                  return (
                    <Select.Option key={wifiModule?.id} value={wifiModule?.id}>
                      {wifiModule?.name}
                    </Select.Option>
                  )
                } else {
                  return null
                }
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.wifi_module_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.quantity)}
        label={t('quantity')}
        required
      >
        <Form.Item name='quantity' noStyle>
          <InputNumber
            style={inputStyles(error?.quantity)}
            disabled={!canManageProject()}
            className='input_number'
            min={0}
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.quantity} />
      </Form.Item>

      <div className='accordionArrayItem__removeSurveyProduct'>
        <Button
          onClick={clickHandler}
          disabled={isAdding || deleteDisable || !canManageProject()}
          type={data ? 'danger' : 'secondary'}
        >
          {!data ? t('close') : t('delete')}
        </Button>

        <Button
          disabled={isAdding || !canManageProject()}
          htmlType='submit'
          type='primary'
        >
          {!data ? t('save') : t('update')}
        </Button>
      </div>
      <AgreeDeleteModal
        visible={showConfirmationModal}
        text={t('wifi_module_delete_warning')}
        targetName={data?.wifi_module?.name}
        yesText={t('yes')}
        noText={t('no')}
        onCancel={closeConfirmationModal}
        disabled={deleteDisable}
        onSubmit={() => deleteWifi(data?.id)}
      />
    </Form>
  )
}
export default WifiModule
