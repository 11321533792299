import { useParams, useHistory } from 'react-router-dom'
import { hasRole, roles } from 'src/utils'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export const useLoginScreen = () => {
  const history = useHistory()

  const { content } = useParams('content')

  const { token } = useSelector((state) => state.globalSlice)

  useEffect(() => {
    if (token) {
      if (hasRole(roles.supplier)) {
        history.push('/reporting')
      } else {
        history.push('/')
      }
    }
  }, [token])

  return { content }
}
