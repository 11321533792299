import { useFormatNumber } from './useFormatNumber'
import NumberFormat from 'react-number-format'

const FormatNumber = ({ setFieldsValue, name, defaultValue }) => {
  const { setValue } = useFormatNumber(setFieldsValue, name)

  return (
    <NumberFormat
      onValueChange={({ floatValue }) => setValue(floatValue)}
      defaultValue={defaultValue}
      allowNegative={false}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      prefix='€ '
    />
  )
}

export default FormatNumber
