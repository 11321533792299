import { CustomUpload, ShowFieldError } from 'src/common'
import { Form, Input, Checkbox, Typography } from 'antd'
import { useCrawlSpace } from './hooks'

const CrawlSpace = ({
  setFieldsValue = () => {},
  error = {},
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
}) => {
  const { layout, t, fileList, uploadChangeHandler } = useCrawlSpace(
    setFieldsValue,
    getStepFiles
  )

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('crawl_space')}
      </Typography.Title>

      <Form.Item
        {...layout}
        className={error?.condition_of_crawl_space ? 'group-label-error' : ''}
        required
        label={<div>{t('condition_of_crawl_space')}</div>}
      >
        <Form.Item name='condition_of_crawl_space' noStyle>
          <Checkbox.Group
            onChange={(value) => {
              setFieldsValue({
                condition_of_crawl_space: value,
              })
            }}
          >
            <Checkbox className='wizards_checkbox_space' value={1}>
              {t('crawl_space_is_present')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={2}>
              {t('not_present')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={3}>
              {t('crawl_space_is_accessible')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={4}>
              {t('not_accessible')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={5}>
              {t('crawl_space_is_dry')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={6}>
              {t('wet_moist')}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <ShowFieldError
          error={error?.condition_of_crawl_space}
          withHeight={true}
        />
      </Form.Item>

      <Form.Item {...layout} label={t('extra_information_crawl_space')}>
        <Form.Item name='extra_information_crawl_space' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>

      <Form.Item {...layout} label={t('upload_crawl_space_pictures')}>
        <Form.Item name='crawlSpace_pictures' noStyle>
          <CustomUpload
            beforeUpload={() => false}
            multiple
            listType='picture-card'
            accept='image/*'
            fileList={fileList}
            onChange={uploadChangeHandler}
            onRemove={deleteInspectionImage}
          >
            <div>
              <div style={{ marginTop: 8, textTransform: 'uppercase`' }}>
                {t('upload')}
              </div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default CrawlSpace
