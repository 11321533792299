import { BackLink, BurgerMenu } from 'src/common'
import { BurgerMenuIcon } from 'src/assets'
import { Link } from 'react-router-dom'
import { useHeader } from 'src/hooks'

const Header = ({
  className = '',
  wrapperClassName = '',
  left = null,
  right = null,
  fullWidth = false,
  showBackLink = true,
}) => {
  const { logotype, isBurgerMenuOpen, setIsBurgerMenuOpen } = useHeader()

  return (
    <>
      <BurgerMenu
        disableBurgerMenu={() => setIsBurgerMenuOpen(false)}
        isBurgerMenuOpen={isBurgerMenuOpen}
      />

      <div
        className={`main-header-wrapper ${wrapperClassName} ${
          fullWidth && 'main-header-wrapper__full-width'
        }`}
      >
        <div className={`header ${className}`}>
          {showBackLink && <BackLink />}

          {left && left}

          <Link to='/'>
            <img className='logo' src={logotype} alt='logo' />
          </Link>

          {right && right}

          <div
            onClick={() => setIsBurgerMenuOpen(true)}
            className='burger-menu-icon'
          >
            <BurgerMenuIcon />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
