import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allActivityTypesRequest = async ({
  page,
  size = 10,
  brandId = '',
  companyId,
}) => {
  return await axios.get(
    `api/activity_types?page=${page}&per_page=${size}${
      brandId && `&brands[]=${brandId}`
    }${appendCompanyId(companyId)}`
  )
}

export const updateActivityTypeRequest = async (id, data) => {
  return await axios.put(`api/activity_types/${id}`, data)
}

export const addActivityTypeRequest = async (data) => {
  return await axios.post('api/activity_types', data)
}

export const importActivityTypesRequest = async (data) => {
  return await axios.post('api/activity_types/import', data)
}

export const deleteActivityTypeRequest = async (id) => {
  return await axios.delete(`api/activity_types/${id}`)
}
