import { useState } from 'react'

export const useControlsButton = (props) => {
  const [isActive, setActive] = useState(false)

  let onClickButton = (e) => {
    e.preventDefault()
    props.onToggle(props.style)

    if (!isActive) setActive(true)
    if (isActive) setActive(false)
  }

  return { isActive, onClickButton }
}
