import { useTranslation } from 'react-i18next'

const UploadAdditionalText = ({ setShowDocumentLibraryModal, disabled }) => {
  const { t } = useTranslation()

  return (
    <div className='document-library-text'>
      <p>{t('or')}</p>
      <p
        className='document-library-text__select'
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation()

          if (disabled) return
          setShowDocumentLibraryModal(true)
        }}
      >
        {t('select_from_document_library')}
      </p>
    </div>
  )
}

export default UploadAdditionalText
