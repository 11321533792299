import { appendStatusIds, appendManagerIds } from 'src/utils'
import axios from '../../axios'

export const allProjectsRequest = async ({
  appendOpenStatusIds,
  searchKeyWord,
  managerIds,
  statusIds,
  page,
  size,
  sort,
}) => {
  return await axios.get(
    `api/projects?page=${page}&per_page=${size}${
      searchKeyWord ? `&search=${searchKeyWord}` : ''
    }${sort ? `&sort[]=${sort}` : ''}${
      statusIds ? appendStatusIds(statusIds, appendOpenStatusIds) : ''
    }${managerIds ? appendManagerIds(managerIds) : ''}`
  )
}

export const projectInfoRequest = async (id) => {
  return await axios.get(`api/projects/${id}`)
}

export const getProjectsByManagerIdRequest = async (id) => {
  return await axios.post('api/projects/get_by_manager', { manager_id: id })
}

export const updateProjectRequest = async (id, data) => {
  return await axios.put(`api/projects/${id}`, data)
}

export const updateInspectionRequest = async (id, data) => {
  return await axios.post(`api/projects/${id}/inspection/update`, data)
}

export const createProjectRequest = async (data) => {
  return await axios.post('api/projects', data)
}

export const createInspectionRequest = async (id) => {
  return await axios.post(`api/projects/${id}/inspection/create`)
}

export const archiveProjectRequest = async (data) => {
  return await axios.post('api/project/archive', data)
}

export const deleteProjectRequest = async (id) => {
  return await axios.delete(`api/projects/${id}`)
}

export const deleteInspectionRequest = async (id) => {
  return await axios.delete(`api/projects/${id}/inspection`)
}
