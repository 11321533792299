import { Link } from 'react-router-dom'
import { useTabLink } from './hooks'

const TabLink = ({ tabName }) => {
  const { activeTab, projectId, offerId, t, searchParams } = useTabLink()

  return (
    <div
      className={`tab__switcher__container__link ${
        activeTab === tabName && 'active'
      }`}
    >
      <Link to={`/project/${projectId}/${tabName}/${offerId}${searchParams}`}>
        {t(tabName)}
      </Link>
    </div>
  )
}

export default TabLink
