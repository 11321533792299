import { handleNonValidationError, getHomeTablesSortName } from 'src/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { usePageQueryParams } from 'src/hooks'
import { useHistory } from 'react-router-dom'
import { useState, useMemo } from 'react'
import { statuses } from 'src/common'
import {
  archiveProjectRequest,
  deleteProjectRequest,
  allProjectsRequest,
  getSalesRequest,
} from 'src/services'

export const useAllProjects = (statusIds, managerIds) => {
  const [sort, setSort] = useState('')

  const [searchKeyWord, setSearchKeyWord] = useState('')

  const history = useHistory()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const {
    data: projectsData,
    isLoading: isProjectLoading,
    isPreviousData,
  } = useQuery(
    ['projects', 'all', page, size, searchKeyWord, sort, statusIds, managerIds],
    () =>
      allProjectsRequest({
        searchKeyWord,
        managerIds,
        statusIds,
        page,
        size,
        sort,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { data: managersData } = useQuery(['users', 'managers'], () =>
    getSalesRequest()
  )

  const statusFilter = useMemo(() => {
    return statuses?.map((status) => ({
      text: t(status.name),
      value: status.id,
    }))
  }, [])

  const managersFilter = useMemo(() => {
    return managersData?.data?.data?.map((manager) => ({
      text: manager?.name,
      value: manager?.id,
    }))
  }, [managersData])

  const { mutate: archiveProject } = useMutation(
    (id) => archiveProjectRequest({ id }),
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('projects')
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const { mutate: deleteProject } = useMutation(deleteProjectRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries('projects')
        queryClient.invalidateQueries('statusCounts')
      }
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const onRow = (record) => {
    return {
      onClick: () => {
        let selection = window.getSelection()
        if (!selection.toString()) {
          history.push(`/project/${record?.id}/offer`)
        }
      },
      onContextMenu: () => {
        return false
      },
    }
  }

  function handleTableChange(_, filters, sorter) {
    const { field, order } = sorter
    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      const fieldName = getHomeTablesSortName(field)
      setSort(`${fieldName},${tempOrd}`)
    }

    if (!order) {
      setSort('')
    }
  }

  function allProjectsSearchHandler(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  return {
    isProjectLoading: isProjectLoading || isPreviousData,
    project: projectsData?.data?.data,
    allProjectsSearchHandler,
    paginationInfo: {
      total: projectsData?.data?.meta?.total,
      page,
    },
    handleTableChange,
    handlePageChange,
    archiveProject,
    managersFilter,
    deleteProject,
    statusFilter,
    statusIds,
    onRow,
    t,
  }
}
