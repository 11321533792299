export const SalesPipelineIcon = ({
  height = 24,
  width = 24,
  color = '#ADB5BD',
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <mask
        id='mask0_5616_187105'
        maskUnits='userSpaceOnUse'
        x='2'
        y='1'
        width='20'
        height='22'
      >
        <path
          d='M20.5 7L12 2L3.5 7V17L12 22L20.5 17V7Z'
          fill='white'
          stroke='white'
          strokeWidth='2'
          strokeLinejoin='round'
        />
        <path
          d='M12 11V15M16 9V15M8 13V15'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </mask>
      <g mask='url(#mask0_5616_187105)'>
        <path d='M0 0H24V24H0V0Z' fill={color} />
      </g>
    </svg>
  )
}
