import { Products } from './Products'

import { OffersAndComments } from './OffersAndComments'
import { ProjectHeader } from './ProjectHeader'
import { ProjectCreation } from './ProjectCreation'
import { Switch, Route, useRouteMatch } from 'react-router'
import { canManageProject } from 'src/utils'

const Project = () => {
  const match = useRouteMatch()

  return (
    <div className='project'>
      <ProjectHeader />

      <Switch>
        {canManageProject() ? (
          <Route path={`${match.url}/project-creation/:projectId?`} exact>
            <ProjectCreation />
          </Route>
        ) : null}

        <Route path={`${match.url}/:projectId/offer/:id?`} exact>
          <OffersAndComments />
        </Route>

        {canManageProject() ? (
          <Route path={`${match.url}/:projectId/:product/:offerId`} exact>
            <Products />
          </Route>
        ) : null}
      </Switch>
    </div>
  )
}

export default Project
