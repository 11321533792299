import { CustomUpload, ShowFieldError, DatePicker, InputItem } from 'src/common'
import { disablePastDate, formItemErrorClasses, inputStyles } from 'src/utils'
import { Form, Input, Typography, Select, InputNumber } from 'antd'
import { useStartAndInspectionOfRoof } from './hooks'
const { Option } = Select

const StartAndInspectionOfRoof = ({
  setFieldsValue = () => {},
  getStepFiles = () => {},
  error = {},
  deleteInspectionImage = () => {},
  project = {},
}) => {
  const { t, fileList, uploadChangeHandler } = useStartAndInspectionOfRoof(
    getStepFiles,
    setFieldsValue
  )

  return (
    <>
      <Form.Item
        className={formItemErrorClasses(error?.inspection_date)}
        label={t('inspection_date')}
        required
      >
        <Form.Item name='inspection_date' noStyle>
          <DatePicker
            style={inputStyles(error?.inspection_date)}
            disabledDate={(current) => {
              return disablePastDate(current)
            }}
            placeholder={t('select_date')}
          />
        </Form.Item>
        <ShowFieldError error={error?.inspection_date} />
      </Form.Item>

      <InputItem
        label={t('inspector_name')}
        error={error?.name}
        required={false}
        disabled={true}
        name='name'
      />

      <InputItem
        label={t('customer_name')}
        value={project?.name}
        required={false}
        disabled={true}
      />

      <InputItem
        value={` ${project?.street || ''} - ${project?.house_number || ''} ${
          project?.zipcode || ''
        }, ${project?.location || ''}`}
        label={t('address')}
        required={false}
        disabled={true}
      />

      <InputItem
        value={project?.zipcode}
        label={t('zipcode')}
        required={false}
        disabled={true}
      />

      <InputItem
        value={project?.location}
        label={t('location')}
        required={false}
        disabled={true}
      />

      <Typography.Title className='wizards_title' level={5}>
        {t('inspection_of_the_roof_title')}
      </Typography.Title>

      <Form.Item
        className={formItemErrorClasses(error?.name)}
        label={t('roof_type')}
        required
      >
        <Form.Item name='roof_type' noStyle>
          <Select
            className={error?.roof_type ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('roof_type_placeholder')}
          >
            <Option value={1}>{t('angled_pans')}</Option>
            <Option value={2}>{t('flat_bitumen')}</Option>
            <Option value={3}>{t('sloping_steelroof')}</Option>
            <Option value={4}>{t('flat_epdm')}</Option>
            <Option value={5}>{t('other')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.roof_type} />
      </Form.Item>

      <InputItem
        placeholder={t('roof_details_placeholder')}
        error={error?.roof_details}
        label={t('roof_details')}
        name='roof_details'
        required={false}
      />

      <Form.Item
        className={formItemErrorClasses(error?.roof_condition)}
        label={t('roof_condition')}
        required
      >
        <Form.Item name='roof_condition' noStyle>
          <Select
            className={error?.roof_condition ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('roof_condition_placeholder')}
          >
            <Option value={1}>{t('good')}</Option>
            <Option value={2}>{t('reasonable')}</Option>
            <Option value={3}>{t('mediocre')}</Option>
            <Option value={4}>{t('bad')}</Option>
            <Option value={5}>{t('not_easy_to_determine')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.roof_condition} />
      </Form.Item>

      <InputItem
        placeholder={t('roof_tile_dimension')}
        label={t('roof_tile_dimension')}
        error={error?.roof_tile_dimension}
        name='roof_tile_dimension'
      />

      <InputItem
        placeholder={t('size_of_the_roof')}
        error={error?.size_of_the_roof}
        label={t('size_of_the_roof')}
        name='size_of_the_roof'
      />

      <Form.Item
        className={formItemErrorClasses(error?.roof_gutter_height)}
        label={t('roof_gutter_height')}
        required
      >
        <Form.Item name='roof_gutter_height' noStyle>
          <InputNumber
            style={inputStyles(error?.roof_gutter_height)}
            className='input_number'
            placeholder={t('roof_gutter_height_placeholder')}
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.roof_gutter_height} />
      </Form.Item>

      <Form.Item
        className={`${formItemErrorClasses(error?.roof_tiles_amount)}`}
        label={`${t('roof_tiles_amount')}:`}
        required
        style
      >
        <Form.Item name='roof_tiles_amount' noStyle>
          <InputNumber
            placeholder={t('roof_tiles_amount_placeholder')}
            style={inputStyles(error?.roof_tiles_amount)}
            className='input_number'
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.roof_tiles_amount} />
      </Form.Item>

      <InputItem
        placeholder={t('obstacles')}
        label={t('obstacles')}
        error={error?.obstacles}
        name='obstacles'
      />

      <Form.Item
        className={formItemErrorClasses(error?.is_ridge_tile_masonry)}
        required
        label={t('is_ridge_tile_masonry')}
      >
        <Form.Item name='is_ridge_tile_masonry' noStyle>
          <Select
            className={error?.is_ridge_tile_masonry ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
            <Option value={2}>Nvt</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.is_ridge_tile_masonry} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.is_installation_feasible)}
        required
        label={t('is_installation_feasible')}
      >
        <Form.Item name='is_installation_feasible' noStyle>
          <Select
            className={
              error?.is_installation_feasible ? 'antd-select-error ' : ''
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.is_installation_feasible} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.extra_information)}
        label={t('extra_information_roofsurface')}
        required
      >
        <Form.Item name='extra_information' noStyle>
          <Input.TextArea
            placeholder={t('extra_information_roofsurface')}
            style={inputStyles(error?.extra_information)}
            rows={4}
          />
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.extra_information} />
      </Form.Item>

      <Form.Item label={t('upload_roofsurface_pictures')}>
        <Form.Item name='roofsurface_pictures' noStyle>
          <CustomUpload
            multiple
            fileList={fileList}
            onChange={uploadChangeHandler}
            beforeUpload={() => false}
            listType='picture-card'
            accept='image/*'
            onRemove={deleteInspectionImage}
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
        </Form.Item>
        <p className='inspection_wizard_form__instruction'>
          {t('roofsurface_pictures_text')}
        </p>
      </Form.Item>
    </>
  )
}

export default StartAndInspectionOfRoof
