import { SelectRenderInput } from 'src/screens/Manage/components'
import { useMountingSystemForm } from './hooks'
import { Select } from 'antd'
import {
  FormModalWrapper,
  AmountInputItem,
  SelectItem,
  InputItem,
} from 'src/common'

const MountingSystemForm = ({
  currentMountingSystem = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  show,
  form,
}) => {
  const {
    addMountingSystemBrandLoading,
    mountingSystemBrands,
    fetchBrandOnScroll,
    clickHandler,
    t,
  } = useMountingSystemForm()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      modalTitle={t('mounting_system')}
      closeHandler={closeHandler}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <div className='mounting-system-inputs'>
        <InputItem error={errors?.name} label={t('name')} name='name' />

        <SelectItem
          defaultValue={currentMountingSystem?.brand?.id}
          onPopupScroll={fetchBrandOnScroll}
          error={errors?.brand_id}
          dropdownRender={(menu) => {
            return (
              <SelectRenderInput
                disabled={addMountingSystemBrandLoading}
                buttonTitle={t('add_brand')}
                clickHandler={clickHandler}
                menu={menu}
              />
            )
          }}
          label={t('brand')}
          name='brand_id'
          form={form}
        >
          {currentMountingSystem?.brand && (
            <Select.Option
              key={currentMountingSystem?.brand?.id}
              value={currentMountingSystem?.brand?.id}
            >
              {currentMountingSystem?.brand?.name}
            </Select.Option>
          )}
          {mountingSystemBrands?.map((page) => {
            return page?.data?.data?.map((brand) => {
              if (currentMountingSystem?.brand?.id !== brand?.id) {
                return (
                  <Select.Option key={brand?.id} value={brand?.id}>
                    {brand?.name}
                  </Select.Option>
                )
              } else {
                return null
              }
            })
          })}
        </SelectItem>

        <InputItem error={errors?.code} label={t('code')} name='code' />

        <InputItem
          error={errors?.material}
          label={t('material')}
          name='material'
        />

        <InputItem error={errors?.type} label={t('type')} name='type' />

        <InputItem
          error={errors?.description}
          label={t('description')}
          name='description'
        />

        <AmountInputItem
          defaultValue={currentMountingSystem?.purchase_price}
          setFieldsValue={form.setFieldsValue}
          error={errors?.purchase_price}
          label={t('purchase_price')}
          name='purchase_price'
        />

        <AmountInputItem
          defaultValue={currentMountingSystem?.selling_price}
          setFieldsValue={form.setFieldsValue}
          error={errors?.selling_price}
          label={t('selling_price')}
          name='selling_price'
        />

        <InputItem
          label={t('warranty_per_year')}
          error={errors?.warranty}
          name='warranty'
          type='number'
        />
      </div>
    </FormModalWrapper>
  )
}

export default MountingSystemForm
