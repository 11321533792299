import { AiOutlineMail, AiFillLock } from 'react-icons/ai'
import { inputIconColor, inputStyles } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { ShowFieldError } from 'src/common'
import { Form, Button, Input } from 'antd'
import { Link } from 'react-router-dom'
import { useLogin } from './useLogin'

const MainLoginContent = () => {
  const { initialValues, login, isLoading, loginError } = useLogin()
  const { t } = useTranslation()

  return (
    <>
      <div className='logincard__title'>{t('log_in')}</div>
      <div className='logincard__form'>
        <Form onFinish={login} initialValues={initialValues}>
          <Form.Item>
            <Form.Item name='email' noStyle>
              <Input
                style={inputStyles(loginError?.email)}
                name='email'
                type='email'
                placeholder={t('enter_mail')}
                prefix={
                  <AiOutlineMail
                    color={inputIconColor(loginError?.email)}
                    size={20}
                  />
                }
              />
            </Form.Item>
            <ShowFieldError error={loginError?.email} />
          </Form.Item>

          <Form.Item>
            <Form.Item
              name='password'
              style={{ backgroundColor: 'red' }}
              noStyle
            >
              <Input.Password
                style={inputStyles(loginError?.password)}
                name='password'
                placeholder={t('enter_password')}
                prefix={
                  <AiFillLock
                    color={inputIconColor(loginError?.password)}
                    size={20}
                  />
                }
              />
            </Form.Item>
            <ShowFieldError error={loginError?.password} />
          </Form.Item>

          <Button
            className='logincard__form__submit__button'
            disabled={isLoading}
            htmlType='submit'
            type='primary'
          >
            {t('log_in')}
          </Button>
          <Link
            to='/auth/forgot-password'
            className='logincard__forgotpassword'
          >
            {t('forgot_password', { question_mark: '?' })}
          </Link>
        </Form>
      </div>
    </>
  )
}

export default MainLoginContent
