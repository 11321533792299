import { useHistory, useLocation } from 'react-router'

function useHandleQueryParams() {
  const history = useHistory()
  const { pathname, search } = useLocation()

  function appendQueryParams(searchParams, options = { resetPage: false }) {
    const curParams = new URLSearchParams(search)
    const curParamsObject = Object.fromEntries(curParams)
    if (options.resetPage) {
      delete curParamsObject['open-page']
      delete curParamsObject['page']
    }
    const params = new URLSearchParams({ ...curParamsObject, ...searchParams })

    history.push({
      pathname,
      search: params.toString(),
    })
  }

  function deleteQueryParams(paramName = null) {
    if (!paramName) {
      history.push({
        search: '',
      })
    }
    const queryParams = new URLSearchParams(search)
    if (queryParams.has(paramName)) {
      queryParams.delete(paramName)
      history.push({
        search: queryParams.toString(),
      })
    }
  }
  return { appendQueryParams, deleteQueryParams }
}

export { useHandleQueryParams }
