import { ButtonWithTrash } from 'src/common'

const TableCompanies = ({ setDeleteModal, isSuperUser, user, t }) => {
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
    },

    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('mail'),
      dataIndex: 'email',
    },
    {
      title: t('city'),
      dataIndex: 'city',
    },
  ]

  if (isSuperUser) {
    columns.push({
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => {
        if (record?.id === user?.company.id) return null

        return (
          <ButtonWithTrash
            text={t('delete')}
            onClick={(event) => {
              setDeleteModal({ show: true, data: record })
              event.stopPropagation()
            }}
          />
        )
      },
    })
  }

  return columns
}

export default TableCompanies
