import ActivityFormModal from './ActivityFormModal'
import { useShowActivityModal } from './hooks'

const ShowActivityModal = ({ setShowActivityModal, currentActivity, show }) => {
  const { showActivityError, updateActivity, closeModal, isLoading, form, t } =
    useShowActivityModal(setShowActivityModal, currentActivity)

  return (
    <ActivityFormModal
      currentActivity={currentActivity}
      actionButtonTitle={t('update')}
      errors={showActivityError}
      onFinish={updateActivity}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default ShowActivityModal
