import { ButtonWithPlus, Search, Header, AgreeDeleteModal } from 'src/common'
import CompanyModal from './components/CompanyModal/CompanyModal'
import TableCompanies from './TableCompanies'
import { useCompanies } from './useCompanies'
import { Table } from 'antd'

const Companies = () => {
  const {
    isCompaniesDataLoading,
    setShowCompanyModal,
    isCompanyDeleting,
    showCompanyModal,
    handlePageChange,
    paginationInfo,
    setDeleteModal,
    onSearchClick,
    deleteCompany,
    clickHandler,
    deleteModal,
    isSuperUser,
    searching,
    companies,
    onRow,
    user,
    t,
  } = useCompanies()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('companies')}</div>
      <div className='manageScreensLayout__content companies'>
        <div className='contentheader'>
          {isSuperUser ? (
            <>
              <Search
                onSearchClick={onSearchClick}
                disabled={searching}
                className='contentheader__search'
                placeholder={t('search_word')}
              />
              <ButtonWithPlus text={t('add')} onClick={clickHandler} />
            </>
          ) : null}
        </div>

        <Table
          className='companies_table'
          bordered
          scroll={{ x: '992px' }}
          columns={TableCompanies({ setDeleteModal, isSuperUser, user, t })}
          dataSource={companies || []}
          rowKey={(record) => {
            return record?.id
          }}
          onRow={onRow}
          loading={isCompaniesDataLoading}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
        />
      </div>

      {showCompanyModal.show && (
        <CompanyModal
          setShowCompanyModal={setShowCompanyModal}
          showCompanyModal={showCompanyModal}
        />
      )}

      {deleteModal.show && (
        <AgreeDeleteModal
          visible={deleteModal.show}
          disabled={isCompanyDeleting}
          targetName={deleteModal?.data?.name}
          onCancel={() => setDeleteModal({ show: false, data: {} })}
          onSubmit={() =>
            deleteModal.data.id && deleteCompany(deleteModal.data.id)
          }
        />
      )}
    </div>
  )
}

export default Companies
