import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { canManageProject, formatCurrency } from 'src/utils'
import TotalItemQuotation from './components/TotalItemQuotation'
import QuotationVAT from './components/QuotationVAT'

const TotalQuotation = ({ subtotal, onVatChange, vatValue, disabled }) => {
  const VAT = useMemo(() => {
    return subtotal * (vatValue / 100)
  }, [subtotal, vatValue])

  const total = useMemo(() => {
    return subtotal + VAT
  }, [subtotal, VAT])

  const { t } = useTranslation()

  return (
    <div className='total'>
      <div className='total__title'>{t('quotation')}</div>
      <div className='total__content'>
        <TotalItemQuotation
          text={t('subtotal')}
          price={formatCurrency(subtotal)}
        />
        <QuotationVAT
          onVatChange={onVatChange}
          vatValue={vatValue}
          text={t('vat')}
          price={formatCurrency(VAT)}
          disabled={!canManageProject() || disabled}
        />
        <TotalItemQuotation
          text={t('total')}
          price={formatCurrency(total)}
          active={true}
        />
      </div>
    </div>
  )
}

export default TotalQuotation
