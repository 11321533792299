import { companyFormConstant } from 'src/screens/Manage/screens/Companies/constants/companyFormConstant'
import { appendPrefix, appendDutchCode } from 'src/utils'
import { Header, BackLink, AntdForm } from 'src/common'
import { Button, Spin, Affix } from 'antd'
import { Redirect } from 'react-router'
import useCompany from './useCompany'
import {
  companySectionFormClassnames,
  companySections,
} from './companySections'
import {
  CompanySectionsDropdown,
  FinancialInformation,
  EmailInformation,
  LeadIntegration,
  Configuration,
  TabSelector,
  Setup,
} from './components'

const Company = () => {
  const {
    setPrivacyStatementFile,
    setTermsOfConditionFile,
    setPrivacyStatementSrc,
    setTermsOfConditionSrc,
    isCompanyDataLoading,
    privacyStatementFile,
    termsOfConditionFile,
    termsOfConditionSrc,
    disableSubmitButton,
    privacyStatementSrc,
    canViewCompanyPage,
    isNewLeadsEnabled,
    companyTabError,
    submitHandler,
    updateErrors,
    setActiveTab,
    companyData,
    setLogoFile,
    activeTab,
    companyId,
    logoFile,
    form,
    t,
  } = useCompany()

  if (!canViewCompanyPage()) {
    return <Redirect to='/' />
  }

  return (
    <>
      <Header />

      <div className='company'>
        <Affix className='company__affix' offsetTop={130}>
          <TabSelector
            companyTabError={companyTabError}
            companySections={companySections}
            setActiveTab={setActiveTab}
            companyData={companyData}
            activeTab={activeTab}
          />
        </Affix>

        <CompanySectionsDropdown
          companySections={companySections}
          companyTabError={companyTabError}
          setActiveTab={setActiveTab}
          companyData={companyData}
          activeTab={activeTab}
        />

        <div className='company__tab-container'>
          <>
            {isCompanyDataLoading ? (
              <Spin />
            ) : (
              <>
                <AntdForm
                  initialValues={{
                    ...companyFormConstant,
                    ...companyData,
                    phone_number: appendDutchCode(companyData?.phone_number),
                  }}
                  onFinish={(formValues) => {
                    const formData = {
                      ...companyFormConstant,
                      ...companyData,
                      ...formValues,
                    }

                    submitHandler({
                      ...formData,
                      phone_number: appendPrefix(formData?.phone_number),
                    })
                  }}
                  className={`company__tab-container__form ${companySectionFormClassnames[activeTab]}`}
                  name='company'
                  form={form}
                >
                  <>
                    <Setup
                      setPrivacyStatementFile={setPrivacyStatementFile}
                      setTermsOfConditionFile={setTermsOfConditionFile}
                      setPrivacyStatementSrc={setPrivacyStatementSrc}
                      setTermsOfConditionSrc={setTermsOfConditionSrc}
                      privacyStatementFile={privacyStatementFile}
                      termsOfConditionFile={termsOfConditionFile}
                      show={activeTab === companySections.SETUP}
                      termsOfConditionSrc={termsOfConditionSrc}
                      privacyStatementSrc={privacyStatementSrc}
                      disableActionButtons={disableSubmitButton}
                      companyData={companyData}
                      setLogoFile={setLogoFile}
                      errors={updateErrors}
                      logoFile={logoFile}
                      form={form}
                    />

                    <FinancialInformation
                      show={activeTab === companySections.FINANCIAL_INFORMATION}
                      errors={updateErrors}
                    />

                    <EmailInformation
                      show={activeTab === companySections.EMAIL_INFORMATION}
                      companyData={companyData}
                      errors={updateErrors}
                      form={form}
                    />

                    {activeTab === companySections.LEAD_INTEGRATION &&
                      isNewLeadsEnabled && (
                        <LeadIntegration companyData={companyData} />
                      )}

                    <Configuration
                      show={activeTab === companySections.CONFIGURATION}
                      companyData={companyData}
                      errors={updateErrors}
                      form={form}
                    />
                  </>

                  {activeTab !== companySections.LEAD_INTEGRATION && (
                    <Button
                      className='company__tab-container__submit-button'
                      disabled={disableSubmitButton}
                      onClick={form.submit}
                      type='primary'
                    >
                      {t(!!companyId ? 'update' : 'save')}
                    </Button>
                  )}
                </AntdForm>
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}

export default Company
