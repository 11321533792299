import { useState } from 'react'

function useSelectGroup() {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(false)
  const [selectedOptionColor, setSelectedOptionColor] = useState(false)

  const handleSelectOptions = () => {
    setIsSelectOpen(!isSelectOpen)
  }

  const chooseOption = (option, color) => {
    setIsSelectOpen(!isSelectOpen)
    setSelectedOption(option)
    setSelectedOptionColor(color)
  }

  return {
    isSelectOpen,
    selectedOption,
    selectedOptionColor,
    handleSelectOptions,
    chooseOption,
  }
}

export { useSelectGroup }
