import { FormModalWrapper, InputItem } from 'src/common'
import { useFileUploadValidation } from 'src/hooks'
import { PlusOutlined } from '@ant-design/icons'
import { Form, Button, Upload } from 'antd'
import { ShowFieldError } from 'src/common'

const DocumentLibraryFormModal = ({
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  document,
  setDocument,
  errors,
  form,
  show,
}) => {
  const { uploadHandler, t } = useFileUploadValidation(form, errors)

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      modalTitle={t('document_library')}
      closeHandler={closeHandler}
      labelCol={{ span: 3 }}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.title?.[0]} label={t('name')} name='title' />

      <Form.Item
        className={`pdf-upload ${errors?.file?.[0] && 'pdf-upload-error'}`}
        label={t('document')}
        name='file'
        required
      >
        <Upload
          fileList={document ? [document] : []}
          beforeUpload={() => false}
          accept={'.pdf, image/*'}
          onChange={(info) => {
            uploadHandler(info, 'file', 'document')
            setDocument(info.file)
          }}
          maxCount={1}
        >
          <Button icon={<PlusOutlined />}>{t('upload')}</Button>
        </Upload>
        <ShowFieldError withHeight error={errors?.file?.[0]} />
      </Form.Item>
    </FormModalWrapper>
  )
}

export default DocumentLibraryFormModal
