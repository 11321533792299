import { WifiModuleFormModal } from './components'
import { useAddWifiModuleModal } from './hooks'

const AddWifiModuleModal = ({
  setShowAddWifiModuleModal,
  showAddWifiModuleModal,
}) => {
  const {
    addWifiModuleError,
    addWifiModule,
    closeHandler,
    isLoading,
    form,
    t,
  } = useAddWifiModuleModal(setShowAddWifiModuleModal)

  return (
    <WifiModuleFormModal
      actionButtonTitle={t('save')}
      show={showAddWifiModuleModal}
      errors={addWifiModuleError}
      closeHandler={closeHandler}
      onFinish={addWifiModule}
      isLoading={isLoading}
      form={form}
    />
  )
}

export default AddWifiModuleModal
