import { useOfferDeletionModal } from './hooks'
import { AgreeDeleteModal } from 'src/common'

const OfferDeletionModal = ({
  setShowOfferDeleteModal,
  showOfferDeleteModal,
  offerNumber,
  offerId,
}) => {
  const { deleteOfferMutation, isLoading, t } = useOfferDeletionModal(offerId)

  return (
    <AgreeDeleteModal
      onCancel={() => setShowOfferDeleteModal(false)}
      targetName={`${t('offer')}(${offerNumber})`}
      disabled={isLoading}
      visible={showOfferDeleteModal}
      onSubmit={deleteOfferMutation}
    />
  )
}

export default OfferDeletionModal
