import { useTranslation } from 'react-i18next'
import { getLogoUrl } from 'src/utils'
import { useMemo } from 'react'
import { Form } from 'antd'

const getDefaultCheckboxValue = (value) => {
  return value
    ?.slice(1, -1)
    ?.split(',')
    ?.map((element) => {
      return Number(element.replace(/"/g, ''))
    })
}

export const useAddInspectionModal = (initialValues, project) => {
  const [form] = Form.useForm()

  const {
    condition_of_crawl_space,
    size_of_the_roof,
    ac_route_run,
    reach_type,
    roofoutlet,
    reached,
  } = initialValues || {}

  const { t } = useTranslation()

  const additionalConfiguration = {
    width: 850,
  }

  const formInitialValues = {
    ...initialValues,
    reached: getDefaultCheckboxValue(reached),
    roofoutlet: getDefaultCheckboxValue(roofoutlet),
    ac_route_run: getDefaultCheckboxValue(ac_route_run),
    condition_of_crawl_space: getDefaultCheckboxValue(condition_of_crawl_space),
    name: initialValues?.name || project?.executor?.name,
    size_of_the_roof: size_of_the_roof || null,
    reach_type: getDefaultCheckboxValue(reach_type),
  }

  const inspectionCompanyLogo = useMemo(() => {
    return getLogoUrl(project?.company, {
      defaultImage: false,
    })
  }, [project])

  return {
    additionalConfiguration,
    inspectionCompanyLogo,
    formInitialValues,
    form,
    t,
  }
}
