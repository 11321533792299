import { BlockStyleControls, InlineStyleControls } from './Controls'
import { useEditor } from './hooks'
import { Editor } from 'draft-js'
import 'draft-js/dist/Draft.css'

export default function TextEditor({
  onValueChange,
  initialState,
  placeholder = '',
  className = '',
}) {
  const {
    focusEditor,
    onBlockClick,
    onInlineClick,
    editor,
    editorState,
    setEditorState,
  } = useEditor({ onValueChange, initialState })

  return (
    <div className={`editor-wrapper ${className}`} onMouseEnter={focusEditor}>
      <div className='controls-wrapper'>
        <BlockStyleControls onToggle={onBlockClick} />
        <InlineStyleControls onToggle={onInlineClick} />
      </div>

      <Editor
        ref={editor}
        editorState={editorState}
        onChange={setEditorState}
        placeholder={placeholder}
      />
    </div>
  )
}
