import { useParams } from 'react-router'
import { useGetProject } from './OffersAndComments/hooks'

export function useCreateProject() {
  const { projectId } = useParams()

  const { project, isProjectLoading } = useGetProject({
    projectId,
  })

  return {
    project,
    isProjectLoading,
  }
}
