import { AiOutlineCloseCircle, AiOutlineArrowLeft } from 'react-icons/ai'
import { useCustomModal } from './useCustomModal'
import { Modal } from 'antd'

const CustomModal = ({
  show = false,
  children,
  close = () => {},
  additionalConfiguration = {},
  zIndex = 1000,
  isEmpty = false,
  className = '',
  wrapClassName = '',
}) => {
  const { lg, sm } = useCustomModal()

  return (
    <Modal
      className={className}
      wrapClassName={`customModal ${wrapClassName}`}
      centered
      visible={show}
      onCancel={close}
      width={800}
      footer={null}
      mask={lg || false}
      zIndex={zIndex}
      closeIcon={<AiOutlineCloseCircle size={25} color='#86BBF3' />}
      {...additionalConfiguration}
    >
      <div className='modalContent'>
        {!isEmpty && (
          <div className='modalContent__header'>
            <AiOutlineArrowLeft
              className='modalContent__header__icon'
              onClick={close}
              size={sm ? 28 : 20}
            />
          </div>
        )}
        {children}
      </div>
    </Modal>
  )
}

export default CustomModal
