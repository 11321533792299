import { AgreeDeleteModal, ButtonWithTrash, Search, Header } from 'src/common'
import { LeadsTable } from './components'
import useNewLeads from './useNewLeads'

const NewLeads = () => {
  const {
    setShowLeadsDeleteModal,
    showLeadsDeleteModal,
    deleteLeadsMutation,
    isLeadsDataLoading,
    setSelectedLeadIds,
    leadsSearchHandler,
    handleTableChange,
    handlePageChange,
    selectedLeadIds,
    paginationInfo,
    leadsData,
    deleting,
    onRow,
    t,
  } = useNewLeads()

  return (
    <>
      <Header
        left={<p className='leads__page__title'>{t('new_leads')}</p>}
        wrapperClassName='leads__header-wrapper'
        className='leads__header'
        fullWidth
      />

      <div className='leads'>
        <p className='leads__page-title--mobile'>{t('new_leads')}</p>

        <div className='search__delete__wrapper'>
          <Search
            onSearchClick={leadsSearchHandler}
            placeholder={t('search_word')}
            disabled={isLeadsDataLoading}
          />

          <ButtonWithTrash
            className={selectedLeadIds.length}
            onClick={() => setShowLeadsDeleteModal(true)}
            disabled={!selectedLeadIds.length}
            text={t('delete_selected_item')}
          />
        </div>

        <LeadsTable
          showLeadsDeleteModal={showLeadsDeleteModal}
          isLeadsDataLoading={isLeadsDataLoading}
          setSelectedLeadIds={setSelectedLeadIds}
          handleTableChange={handleTableChange}
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
          leadsData={leadsData}
          onRow={onRow}
        />

        {showLeadsDeleteModal && (
          <AgreeDeleteModal
            onSubmit={() => deleteLeadsMutation(selectedLeadIds)}
            onCancel={() => setShowLeadsDeleteModal(false)}
            visible={showLeadsDeleteModal}
            disabled={deleting}
          />
        )}
      </div>
    </>
  )
}

export default NewLeads
