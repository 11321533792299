import { useMemo } from 'react'

function useSelectOption({ textColor, option }) {
  const optionColor = useMemo(() => {
    if (textColor) {
      return textColor[option.value] ? textColor[option.value] : null
    }

    return null
  }, [textColor, option.value])

  return {
    optionColor,
  }
}

export { useSelectOption }
