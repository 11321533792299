import { deleteMultipleProductRequest } from 'src/services'
import { useQueryClient, useMutation } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { usePageQueryParams } from 'src/hooks'

export const useDeleteMultipleProductItems = (
  setConfirmationModal,
  setSelectedProductsIds,
  queryKey,
  currentPageItemCount
) => {
  const { page, navigateToPreviousQueryPageParam } = usePageQueryParams()
  const queryClient = useQueryClient()

  const {
    mutate: deleteMultipleItemMutation,
    isLoading: deletingMultipleItem,
  } = useMutation(
    ({ table, items }) => deleteMultipleProductRequest(table, items),
    {
      onSuccess: (response, variables) => {
        if (response.status === 200) {
          if (currentPageItemCount === variables?.items?.length && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries(queryKey)
          setConfirmationModal(false)
          setSelectedProductsIds([])
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  return { deleteMultipleItemMutation, deletingMultipleItem }
}
