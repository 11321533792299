import { useMaterialReporting } from './useMaterialReporting'
import { Header, Search, CsvButton } from 'src/common'
import { MaterialTableColumns } from './table/Column'
import { Table } from 'antd'

const MaterialReporting = () => {
  const {
    isMaterialReportingDataLoading,
    exportMaterialReporting,
    handleFilterChange,
    projectReferences,
    handlePageChange,
    materialReport,
    paginationInfo,
    onSearchClick,
    exporting,
    t,
  } = useMaterialReporting()

  return (
    <div className='material'>
      <Header />

      <div className='material__title'>{t('material_reporting')}</div>

      <div className='material__content'>
        <div className='contentheader'>
          <Search
            className='contentheader__search'
            onSearchClick={onSearchClick}
          />
        </div>

        <Table
          columns={MaterialTableColumns(projectReferences, t)}
          rowKey={(record) => record?.id + record?.reference}
          loading={isMaterialReportingDataLoading}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          style={{
            marginBottom: '2rem',
          }}
          onChange={handleFilterChange}
          dataSource={materialReport}
          scroll={{ x: '992px' }}
          bordered
        />

        <div className='contentFooter'>
          <CsvButton
            disabled={exporting || !materialReport?.length}
            onClick={exportMaterialReporting}
            text={t('download_cvs')}
          />
        </div>
      </div>
    </div>
  )
}

export default MaterialReporting
