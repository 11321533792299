import { FormModalWrapper } from 'src/common'
import { useAddNewSource } from './hooks'
import { BiPlus } from 'react-icons/bi'
import { InputItem } from 'src/common'

const AddNewSource = ({ companyData }) => {
  const {
    setShowAddNewSourceModal,
    showAddNewSourceModal,
    addSourceMutation,
    modalCloseHandler,
    isLoading,
    errors,
    form,
    t,
  } = useAddNewSource()

  return (
    <>
      <div className='company__tab-container__lead-integration__add-source'>
        <h3 className='company__tab-container__lead-integration__add-source__title'>
          {companyData?.lead_tokens?.length > 0
            ? t('add_another_source_name')
            : t('source_name')}
        </h3>

        <div
          onClick={() => setShowAddNewSourceModal(true)}
          className='company__tab-container__lead-integration__add-source__plus-icon'
        >
          <BiPlus size={24} color='white' />
        </div>
      </div>

      <FormModalWrapper
        modalTitle={t('add_source_name')}
        closeHandler={modalCloseHandler}
        actionButtonTitle={t('save')}
        onFinish={addSourceMutation}
        show={showAddNewSourceModal}
        isLoading={isLoading}
        form={form}
      >
        <InputItem error={errors?.name} label={t('source_name')} name='name' />
      </FormModalWrapper>
    </>
  )
}

export default AddNewSource
