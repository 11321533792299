import MainLoginContent from './components/MainLoginContent/MainLoginContent'
import { ForgotPasswordContent, ResetPasswordContent } from './components'
import { useLoginScreen } from './useLoginScreen'
import { LanguageSelector } from 'src/common'
import {
  loginBackgroundTabletAndMobile,
  loginBackgroundDesktopMin,
  escLargeLogo,
} from 'src/assets'

const LoginScreen = () => {
  const { content } = useLoginScreen()

  return (
    <div className='login'>
      <picture className='login__background'>
        <source
          media='(max-width: 799px)'
          srcSet={loginBackgroundTabletAndMobile}
        />

        <img src={loginBackgroundDesktopMin} alt='login background desktop' />
      </picture>

      <div className='logincard'>
        <div className='logincard__language__selector'>
          <LanguageSelector />
        </div>

        <img className='logincard__logo' src={escLargeLogo} alt='background' />

        {content !== 'forgot-password' && content !== 'reset-password' && (
          <MainLoginContent />
        )}

        {content === 'forgot-password' && <ForgotPasswordContent />}
        {content === 'reset-password' && <ResetPasswordContent />}
      </div>
    </div>
  )
}

export default LoginScreen
