import { isSupplier, showSettings } from 'src/utils'
import useBurgerMenu from './useBurgerMenu'
import { Link } from 'react-router-dom'
import {
  salesPipelinePngIcon,
  ReportingIcon,
  PlanningIcon,
  SettingsIcon,
  LogoutIcon,
  CloseIcon,
} from 'src/assets'

const BurgerMenu = ({ disableBurgerMenu, isBurgerMenuOpen }) => {
  const {
    isSolarPlanningEnabled,
    isReportingEnabled,
    logoutMutation,
    iconsMainColor,
    t,
  } = useBurgerMenu()

  return (
    <div
      style={{
        width: isBurgerMenuOpen ? '100%' : '0',
      }}
      class='burger-menu'
    >
      <div className='burger-menu__close-btn' onClick={disableBurgerMenu}>
        <CloseIcon />
      </div>

      <div className='burger-menu__links-container'>
        {isReportingEnabled && (
          <Link onClick={disableBurgerMenu} to='/reporting'>
            <ReportingIcon width={20} height={22} color={iconsMainColor} />
            {t('reporting')}
          </Link>
        )}

        {isSolarPlanningEnabled && (
          <Link onClick={disableBurgerMenu} to='/planning'>
            <PlanningIcon width={26} height={25} color={iconsMainColor} />
            {t('planning_pipeline')}
          </Link>
        )}

        {!isSupplier() && (
          <Link onClick={disableBurgerMenu} to='/sales-projects'>
            <img src={salesPipelinePngIcon} alt='sales pipeline' />
            {t('sales_pipeline')}
          </Link>
        )}

        {showSettings() && (
          <Link onClick={disableBurgerMenu} to='/manage'>
            <SettingsIcon width={26} height={26} color={iconsMainColor} />
            {t('settings')}
          </Link>
        )}

        <Link
          style={{ marginTop: '2.9rem' }}
          onClick={logoutMutation}
          to='/auth/login'
        >
          <LogoutIcon width={24} height={21} color='#FF7875' />
          {t('log_out')}
        </Link>
      </div>
    </div>
  )
}

export default BurgerMenu
