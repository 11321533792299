import { AccordionWrapper, Search } from 'src/common'
import { useDesign } from './useDesign'
import React from 'react'
import {
  Form,
  Input,
  Checkbox,
  Col,
  Row,
  DatePicker,
  Radio,
  Table,
  Space,
  Steps,
  Menu,
  Dropdown,
  Collapse,
  Button,
} from 'antd'

const Design = () => {
  const { RangePicker } = DatePicker
  const { TextArea } = Input

  const { openNotificationWithIcon, rowSelection, columns, data } = useDesign()

  return (
    <div style={{ padding: '1rem' }}>
      <Form name='basic'>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
          }}
        >
          <h1>Inputs</h1>
          <Col span={8}>
            <Form.Item
              label='Username'
              name='username'
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Username'
              name='username'
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={<label className='labelError'>Username</label>}
              name='username'
              validateStatus='error'
            >
              <Input type='number' />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Datepicker</h1>
          <Col>
            <DatePicker />
            <RangePicker style={{ marginLeft: '30px' }} />
          </Col>
          <Col style={{ marginTop: '10px' }}>
            <DatePicker picker='month' />
            <RangePicker picker='month' style={{ marginLeft: '30px' }} />
          </Col>
          <Col style={{ marginTop: '10px' }}>
            <DatePicker picker='year' />
            <RangePicker picker='year' style={{ marginLeft: '30px' }} />
          </Col>
          <Col style={{ marginTop: '10px' }}>
            <DatePicker showTime />
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Textarea</h1>
          <Col span={8}>
            <Form.Item
              label='Username'
              name='username'
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <TextArea placeholder='text area' />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Checkbox</h1>
          <div>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '10px' }}>Checkbox</div>
                <Checkbox value='A'></Checkbox>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '10px' }}>Checkbox</div>
                <Checkbox indeterminate value='A'></Checkbox>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '10px' }}>Checkbox</div>
                <Checkbox indeterminate value='A' disabled></Checkbox>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: '10px' }}>Checkbox</div>
                <Checkbox value='A' disabled></Checkbox>
              </div>
            </Form.Item>
          </div>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Table & Pagination</h1>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            rowSelection={rowSelection}
          />
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Accordeon</h1>
          <AccordionWrapper>
            <Collapse.Panel header='This is panel header 1' key='1'>
              <div>This is accordeon component</div>
            </Collapse.Panel>
            <Collapse.Panel header='This is panel header 1' key='2'>
              <div>This is accordeon component</div>
            </Collapse.Panel>
          </AccordionWrapper>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Steps</h1>
          <Steps progressDot current={1}>
            <Steps.Step title='Client' />
            <Steps.Step title='Contact' />
            <Steps.Step title='Inspection' />
            <Steps.Step title='Survey' />
            <Steps.Step title='Options' />
            <Steps.Step title='Check' />
          </Steps>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Notification</h1>
          <Space>
            <Button onClick={() => openNotificationWithIcon('success')}>
              Success
            </Button>
            <Button onClick={() => openNotificationWithIcon('info')}>
              Info
            </Button>
            <Button onClick={() => openNotificationWithIcon('warning')}>
              Warning
            </Button>
            <Button onClick={() => openNotificationWithIcon('error')}>
              Error
            </Button>
          </Space>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Buttons</h1>
          <Space>
            <Button type='primary' size='large'>
              Button
            </Button>
            <Button type='danger' size='large'>
              Button
            </Button>
            <Button type='secondary' size='large'>
              Button
            </Button>
            <Button size='large' className='defaultButton'>
              Button
            </Button>
          </Space>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Radio</h1>
          <Radio.Group>
            <Radio value={1} disabled>
              A
            </Radio>
            <Radio value={2}>B</Radio>
            <Radio value={3}>C</Radio>
            <Radio value={4}>D</Radio>
          </Radio.Group>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Dropdown</h1>
          <Dropdown overlay={menu} placement='bottomLeft' arrow>
            <Button>Dropdown</Button>
          </Dropdown>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'black solid 1px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <h1>Search</h1>
          <Search placeholder='Search Item' />
        </Row>
      </Form>
    </div>
  )
}
export default Design

const menu = (
  <Menu>
    <Menu.Item>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.antgroup.com'
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.aliyun.com'
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.luohanacademy.com'
      >
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
)
