import { axios as privateAxios } from 'src/services'
import { appendIds } from 'src/utils'
import i18next from 'i18next'
import axios from 'axios'

const iframeAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URI}/`,
})

export const createLeadRequest = async ({ data, token }) => {
  return iframeAxiosInstance.post(`api-leads/v1/leads`, data, {
    headers: {
      authorization: `Bearer ${token}`,
      Locale: i18next.language,
    },
  })
}

export const getLeadInfoRequest = async (id) => {
  return await privateAxios.get(`api/leads/${id}`)
}

export const allLeadsRequest = async ({ searchKeyWord, page, size, sort }) => {
  return await privateAxios.get(
    `api/leads?page=${page}&per_page=${size}${
      searchKeyWord ? `&search=${searchKeyWord}` : ''
    }${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const getNewLeadsCountRequest = async () => {
  return await privateAxios.get('api/leads/get-unread-notifications')
}

export const readLeadsNotificationsRequest = async (idArr) => {
  return await privateAxios.post(
    `api/leads/read-notifications?${appendIds(idArr)}`
  )
}

export const deleteLeadsRequest = async (idArr) => {
  return await privateAxios.delete(`api/leads?${appendIds(idArr)}`)
}
