import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

i18n

  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)

  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl'],
    preload: ['en', 'nl'],
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },

    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
  })
