import MountingSystems from './Accordions/MountingSystem/MountingSystems'
import Converters from './Accordions/Converters/Converters'
import { useSurveyWizard } from './useSurveyWizard'
import Panels from './Accordions/Panels/Panels'

const SurveyWizard = ({
  converters = [],
  panels = [],
  mountingSystems = [],
  wifiModules = [],
  powerOptimizers = [],
  projectCompanyId,
}) => {
  const { panelsQuantity, mountingSystemsQuantity } = useSurveyWizard({
    panels,
    mountingSystems,
  })

  return (
    <div className='survey'>
      <div className='survey__content'>
        <Panels projectCompanyId={projectCompanyId} panels={panels} />
        <Converters
          powerOptimizers={powerOptimizers}
          wifiModules={wifiModules}
          converters={converters}
          projectCompanyId={projectCompanyId}
        />
        <MountingSystems
          mountingSystemsQuantity={mountingSystemsQuantity}
          mountingSystems={mountingSystems}
          panelsQuantity={panelsQuantity}
          projectCompanyId={projectCompanyId}
        />
      </div>
    </div>
  )
}

export default SurveyWizard
