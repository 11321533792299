import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { ShowFieldError, AgreeDeleteModal } from 'src/common'
import { Select, Form, Button, InputNumber } from 'antd'
import { useAttachPowerOptimizer } from './hooks'

const PowerOptimizer = ({
  deleteDisable = false,
  data = null,
  deletePowerOptimizer = () => {},
  projectCompanyId,
}) => {
  const {
    powerOptimizerChangeHandler,
    closeConfirmationModal,
    showConfirmationModal,
    powerOptimizers,
    powerOptimizer,
    finishHandler,
    fetchOnScroll,
    clickHandler,
    isAdding,
    layout,
    error,
    form,
    t,
  } = useAttachPowerOptimizer({ data, deletePowerOptimizer, projectCompanyId })

  return (
    <Form
      form={form}
      className='accordionArrayItem'
      initialValues={{
        een_merk: data?.power_optimizer?.name,
        quantity: data?.quantity,
      }}
      {...layout}
      onFinish={finishHandler}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 15 }}
    >
      <Form.Item
        className={formItemErrorClasses(error?.power_optimizer_id)}
        required
        label={t('select_power_optimizer')}
      >
        <Form.Item name='een_merk' noStyle>
          <Select
            className={error?.power_optimizer_id ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={powerOptimizerChangeHandler}
            disabled={!canManageProject()}
            virtual={false}
            onPopupScroll={fetchOnScroll}
          >
            {data?.power_optimizer && (
              <Select.Option
                key={data?.power_optimizer?.id}
                value={data?.power_optimizer?.id}
              >
                {data?.power_optimizer?.name}
              </Select.Option>
            )}
            {powerOptimizers?.map((page) => {
              return page?.data?.data?.map((powerOptimizer) => {
                if (data?.power_optimizer?.id !== powerOptimizer?.id) {
                  return (
                    <Select.Option
                      key={powerOptimizer?.id}
                      value={powerOptimizer?.name}
                    >
                      {powerOptimizer?.name}
                    </Select.Option>
                  )
                } else {
                  return null
                }
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.power_optimizer_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.quantity)}
        required
        label={t('quantity')}
      >
        <Form.Item name='quantity' noStyle>
          <InputNumber
            style={inputStyles(error?.quantity)}
            disabled={!canManageProject()}
            className='input_number'
            min={0}
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.quantity} />
      </Form.Item>

      <div className='productinfo'>
        <Form.Item label={t('max_dc')}>{powerOptimizer?.max_dc}</Form.Item>
        <Form.Item label={t('efficiency')}>
          {powerOptimizer?.efficiency}
        </Form.Item>

        <Form.Item label={t('dimensions_mm')}>
          {powerOptimizer
            ? `${powerOptimizer?.length} x ${powerOptimizer?.width} x ${powerOptimizer?.depth}`
            : ''}
        </Form.Item>
      </div>

      <div className='accordionArrayItem__removeSurveyProduct'>
        <Button
          onClick={clickHandler}
          disabled={isAdding || deleteDisable || !canManageProject()}
          type={data ? 'danger' : 'secondary'}
        >
          {!data ? t('close') : t('delete')}
        </Button>

        <Button
          disabled={isAdding || !canManageProject()}
          htmlType='submit'
          type='primary'
        >
          {!data ||
          (data && data?.power_optimizer?.name !== powerOptimizer?.name)
            ? t('save')
            : t('update')}
        </Button>
      </div>
      <AgreeDeleteModal
        visible={showConfirmationModal}
        text={t('power_optimizer_delete_warning')}
        yesText={t('yes')}
        noText={t('no')}
        onCancel={closeConfirmationModal}
        disabled={deleteDisable}
        onSubmit={() => deletePowerOptimizer(data?.id)}
      />
    </Form>
  )
}
export default PowerOptimizer
