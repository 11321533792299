import useLeadsButton from './useLeadsButton'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

const LeadsButton = () => {
  const { t, newLeadsCount } = useLeadsButton()

  return (
    <Link className='leads__link' to='/new-leads'>
      <div className='leads__link__container'>
        <Button className='leads__button'>{t('new_leads')}</Button>

        {newLeadsCount > 0 && (
          <div className='leads__count'>{newLeadsCount}</div>
        )}
      </div>
    </Link>
  )
}

export default LeadsButton
