import { AiOutlineLogin } from 'react-icons/ai'
import { Button } from 'antd'

const ButtonWithLogin = ({ text, type = 'primary', ...props }) => {
  return (
    <>
      <Button type={type} {...props} className='alignButtonIcon'>
        <AiOutlineLogin />
        {text}
      </Button>
    </>
  )
}

export default ButtonWithLogin
