import { Empty } from 'antd'
import { Document } from './components'
import { useTranslation } from 'react-i18next'

function Documents({ documents }) {
  const { t } = useTranslation()

  return (
    <div className='document_wrapper'>
      <div className='document_header'>
        <h4>{t('documents')}</h4>
      </div>
      {!documents?.length ? (
        <Empty style={{ marginTop: '2rem' }} />
      ) : (
        <div className='document_container'>
          {documents?.map((document) => {
            return <Document key={document?.id} document={document} />
          })}
        </div>
      )}
    </div>
  )
}

export default Documents
