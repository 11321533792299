export function formatCurrency(num) {
  if (!num) {
    return 0
  }
  if (typeof num !== 'number') {
    return 0
  }

  return num
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
