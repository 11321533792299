import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const useAccessibility = (
  getStepFiles,
  setFieldsValue,
  setUploadPictureErrors
) => {
  const [fileList, setFileList] = useState(getStepFiles(2))

  useEffect(() => {
    setFieldsValue({
      accessibility_picture: fileList,
    })
  }, [fileList])

  const { t } = useTranslation()

  const checkBoxChangeHandler = (value) => {
    setFieldsValue({
      reached: value,
    })
  }

  const uploadChangeHandler = (info) => {
    setUploadPictureErrors((prev) => ({ ...prev, accessibility_picture: null }))
    setFileList(info?.fileList)
  }

  return { t, checkBoxChangeHandler, uploadChangeHandler, fileList }
}
