import { useTranslation } from 'react-i18next'
import { BriefcaseIcon } from 'src/assets'
import { GoPerson } from 'react-icons/go'
import { AntdTooltip } from 'src/common'

const ProjectType = ({ type }) => {
  const { t } = useTranslation()

  return (
    <div className='type__container'>
      {type === 1 && (
        <AntdTooltip placement='top' title={t('private')}>
          <GoPerson className='homescreen__type-icon' />
        </AntdTooltip>
      )}

      {type === 2 && (
        <AntdTooltip placement='top' title={t('business')}>
          <div className='briefcase__icon'>
            <BriefcaseIcon />
          </div>
        </AntdTooltip>
      )}
      {type === 1 ? t('private') : t('business')}
    </div>
  )
}

export default ProjectType
