import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGetProductBrand } from 'src/hooks'
import { appendCompanyId } from 'src/utils'

export const useFormPowerOptimizer = () => {
  const { user } = useSelector((state) => state.globalSlice)

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'converterBrands',
    url: `api/brands?classification=4${appendCompanyId(user?.company_id)}`,
  })

  const { t } = useTranslation()

  return { brandsData: productBrands, fetchBrandOnScroll, t }
}
