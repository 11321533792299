import i18next from 'i18next'
import { statuses } from 'src/common'
import { isSalesPlusIsAdminOrIsSuperUser } from 'src/utils'

export const columns = (filters) => [
  {
    title: () => i18next.t('account_manager'),
    dataIndex: 'manager_name',
    filters: isSalesPlusIsAdminOrIsSuperUser()
      ? filters?.managersFilter
      : false,
    sorter: isSalesPlusIsAdminOrIsSuperUser(),
  },
  {
    title: i18next.t('reference'),
    dataIndex: 'reference',
    sorter: true,
  },
  {
    title: i18next.t('project_name'),
    dataIndex: 'project_name',
  },
  {
    title: () => i18next.t('status'),
    dataIndex: 'status_id',
    render: (value) => {
      const status = statuses?.find((item) => item?.id === value)
      return <>{i18next.t(status?.name)}</>
    },
    filters: filters?.statusFilter,
    sorter: true,
  },
]
