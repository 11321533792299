import { financialReportingInfoRequest, exportRequest } from 'src/services'
import { handleNonValidationError, downloadFile } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

export function useFinancialReport() {
  const { t } = useTranslation()
  const [reportData, setReportData] = useState(null)
  const [dateRange, setDateRange] = useState([' ', ' '])

  const { mutate: gerReportsData, isLoading: isReportDataLoading } =
    useMutation(financialReportingInfoRequest, {
      onSuccess: (resp) => setReportData(resp?.data),
    })

  useEffect(() => {
    gerReportsData(dateRange)
  }, [dateRange?.[0], dateRange?.[1]])

  const { mutate: exportMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
      onError: (err) => {
        handleNonValidationError(err)
      },
    }
  )

  function exportFinancialReportData() {
    return exportMutation({
      from: dateRange?.[0],
      till: dateRange?.[1],
      model: 'financial_report',
    })
  }

  return {
    reportData,
    exportFinancialReportData,
    setDateRange,
    isReportDataLoading,
    exporting,
    t,
  }
}
