import { useAntdForm } from './hooks'
import { Form } from 'antd'

export const AntdForm = ({ children, disableOnEnter = false, ...props }) => {
  const { keyDownHandler } = useAntdForm(props, disableOnEnter)

  return (
    <Form {...props} onKeyDown={keyDownHandler}>
      {children}
    </Form>
  )
}
