import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const useNavigationLink = (title, uri) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const currentRoute =
    (title === 'dashboard' &&
      (pathname === '/' || pathname.includes('create-project'))) ||
    (title !== 'dashboard' && pathname.includes(uri))

  return { currentRoute, t }
}
