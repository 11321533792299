import { useTypeSection } from './hooks/useTypeSection'
import TypeRadio from './TypeRadio'
import { Form, Radio } from 'antd'

const TypeSection = ({ form, setCurrentTpe, currentType }) => {
  const { t } = useTypeSection()

  return (
    <div className='form__section__container'>
      <Form.Item label={`${t('client_type')}*`}>
        <Form.Item name='type' noStyle>
          <Radio.Group
            onChange={(val) => {
              setCurrentTpe(val.target.value)

              if (val.target.value === 1) {
                form.setFieldsValue({
                  company_name: '',
                })
              }
            }}
            name='type'
          >
            <TypeRadio
              selectedValue={currentType}
              title={t('private')}
              value={1}
            />

            <TypeRadio
              selectedValue={currentType}
              title={t('business')}
              value={2}
            />
          </Radio.Group>
        </Form.Item>
      </Form.Item>
    </div>
  )
}

export default TypeSection
