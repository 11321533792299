import store from 'src/store/store'
import { roles } from './roles'

export function hasRole(roleId) {
  const user = store.getState()?.globalSlice?.user
  return user?.roles?.[0]?.id === roleId
}

export function canPerform(permissionName = '') {
  if (hasRole(roles.superUser)) {
    return true
  }
  const permissions = store.getState()?.globalSlice?.permissions

  const permission = permissions?.find(
    (permission) => permission === permissionName
  )
  if (permission) {
    return true
  }
  return false
}
