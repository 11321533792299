import { canPerform, renderAmount } from 'src/utils'
import { ButtonWithTrash } from 'src/common'

const PowerOptimizerColumns = (setDeleteModal, t) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
      render: (brand) => <>{brand?.name}</>,
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('max_dc'),
      dataIndex: 'max_dc',
    },
    {
      title: t('length'),
      dataIndex: 'length',
    },
    {
      title: t('width'),
      dataIndex: 'width',
    },
    {
      title: t('depth'),
      dataIndex: 'depth',
    },
    {
      title: t('efficiency'),
      dataIndex: 'efficiency',
    },
    {
      title: t('warranty'),
      dataIndex: 'warranty',
      render: (val) => (val ? `${val} ${t('year')}` : ''),
    },
    {
      title: t('purchase_price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          disabled={
            canPerform('delete power-optimizer') ||
            canPerform('delete own power-optimizer')
              ? false
              : true
          }
          text={t('delete')}
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default PowerOptimizerColumns
