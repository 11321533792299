import { useTranslation } from 'react-i18next'
import { extractDateAndTime } from 'src/utils'
import LoadMoreButton from './LoadMoreButton'
import { Empty, Form, Input } from 'antd'
import { useComments } from './hooks'
import Comment from './Comment'
import { memo } from 'react'

function Comments() {
  const {
    fetchNextPage,
    commentsCount,
    createComment,
    hasNextPage,
    isLoading,
    comments,
    form,
  } = useComments()

  const { t } = useTranslation()

  return (
    <>
      <h5>
        {t('comments')} {commentsCount ? `(${commentsCount})` : null}
      </h5>
      <div className='comments-content'>
        <Form form={form} onFinish={createComment}>
          <Form.Item name='comment'>
            <Input
              className='comment-input'
              type='text'
              placeholder={`${t('comment_placeholder')}...`}
              disabled={isLoading}
            />
          </Form.Item>
        </Form>

        {comments?.map((page, i) => {
          if (!page?.data?.data?.length) {
            return <Empty key={i} />
          }
          return page?.data?.data?.map((comment) => {
            return (
              <Comment
                key={comment?.id}
                commentText={comment?.comment}
                userName={comment?.user?.name}
                user={comment?.user}
                dateAndTime={extractDateAndTime(comment?.created_at)}
                createdAt={comment?.created_at}
              />
            )
          })
        })}

        {hasNextPage ? (
          <LoadMoreButton
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            text={t('load_more_comments')}
          />
        ) : null}
      </div>
    </>
  )
}

export default memo(Comments)
