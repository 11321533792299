import { DutchFlag, UkFlag } from 'src/assets/images/flags'

const SelectLanguage = ({ onChange, selected }) => {
  return (
    <div className='selectlanguage'>
      <div
        className={`selectlanguage__box ${selected === 'nl' && 'active'}`}
        onClick={() => {
          onChange('nl')
        }}
      >
        <img className='flagDutch' src={DutchFlag} alt='nl' />
      </div>
      <div
        className={`selectlanguage__box ${selected === 'en' && 'active'}`}
        onClick={() => {
          onChange('en')
        }}
      >
        <img className='flagUk' src={UkFlag} alt='uk' />
      </div>
    </div>
  )
}

export default SelectLanguage
