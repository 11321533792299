import ConverterFormInputs from './ConverterFormInputs'
import { useAddConverterModal } from './hooks'

const AddConverterModal = ({
  setShowAddConverterModal,
  showAddConverterModal,
}) => {
  const { addConverterError, addConverter, closeModal, isLoading, form, t } =
    useAddConverterModal(setShowAddConverterModal)

  return (
    <ConverterFormInputs
      actionButtonTitle={t('save')}
      show={showAddConverterModal}
      errors={addConverterError}
      closeHandler={closeModal}
      onFinish={addConverter}
      isLoading={isLoading}
      form={form}
    />
  )
}

export default AddConverterModal
