export const getTableSortValue = (sorter) => {
  const { field, order } = sorter

  let sort = ''
  if (order) {
    const sortOrder = order === 'ascend' ? 'asc' : 'desc'
    sort = `${field},${sortOrder}`
  }

  return sort
}
