import { Tooltip } from 'antd'

import { ImCopy } from 'react-icons/im'
import { useIframeInformation } from './hooks'

function IframeInformation({ iframeApiToken, apiToken }) {
  const { handleIframeUrlCopy, isEmbedCodeCopied, iframeUrl, t } =
    useIframeInformation({ iframeApiToken })

  return (
    <div className='iframe-info'>
      {iframeApiToken ? (
        <div className='iframe-section'>
          <div className='text-and-value'>
            <p>{t('iframe_url')}:</p>
            <p>
              <a href={iframeUrl} target='_blank' rel='noopener noreferrer'>
                {t('iframe_url')}
              </a>
            </p>
          </div>
        </div>
      ) : null}

      <div className='iframe-section'>
        <div className='text-and-value'>
          <p>{t('iframe_embed_code')}:</p>
          <p className='iframe-embed-code'>{`<iframe src="${iframeUrl}" title="lead-iframe"></iframe>`}</p>
        </div>
        <Tooltip title={isEmbedCodeCopied ? t('copied') : t('copy_embed_code')}>
          <ImCopy
            color={isEmbedCodeCopied && 'rgb(61, 146, 236)'}
            onClick={handleIframeUrlCopy}
          />
        </Tooltip>
      </div>

      <div className='iframe-section'>
        <p className='insertion-text'>{t('embed_text')}</p>
      </div>
    </div>
  )
}

export default IframeInformation
