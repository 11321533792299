import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addWifiModuleRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddWifiModuleModal = (setShowAddWifiModuleModal) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { user } = useSelector((state) => state.globalSlice)

  const closeHandler = () => {
    setShowAddWifiModuleModal(false)
    form.resetFields()
  }

  const {
    isLoading,
    mutate: addWifiModule,
    error,
  } = useMutation(
    (variables) => !isLoading && addWifiModuleRequest(variables),

    {
      onSuccess: () => {
        closeHandler()
        queryClient.refetchQueries('wifiModule')
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function handleAddWifiModule(values) {
    return addWifiModule({ ...values, company_id: user?.company?.id })
  }

  return {
    addWifiModuleError: handleValidationError(error),
    addWifiModule: handleAddWifiModule,
    closeHandler,
    isLoading,
    form,
    t,
  }
}
