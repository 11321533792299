import ConverterFormInputs from './ConverterFormInputs'
import { useShowConverterModal } from './hooks'

const ShowConverterModal = ({
  setShowConverter,
  currentConverter,
  showConverter,
}) => {
  const {
    updateConverterError,
    updateConverter,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowConverterModal(currentConverter, setShowConverter)

  return (
    <ConverterFormInputs
      currentConverter={currentConverter}
      actionButtonTitle={t('update')}
      errors={updateConverterError}
      onFinish={updateConverter}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={showConverter}
      form={form}
    />
  )
}

export default ShowConverterModal
