function AddressIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M10.0008 16.9984L9.68078 16.6384C9.48078 16.4384 4.80078 10.9184 4.80078 7.59844C4.80078 4.71844 7.12078 2.39844 10.0008 2.39844C12.8808 2.39844 15.2008 4.71844 15.2008 7.59844C15.2008 10.9184 10.5208 16.4384 10.3208 16.6784L10.0008 16.9984ZM10.0008 3.19844C7.56078 3.19844 5.60078 5.15844 5.60078 7.59844C5.60078 10.1584 8.96078 14.4784 10.0008 15.7584C11.0408 14.4784 14.4008 10.1584 14.4008 7.59844C14.4008 5.15844 12.4408 3.19844 10.0008 3.19844Z'
        fill='#868E96'
      />
      <path
        d='M10 9.60156C8.88 9.60156 8 8.72156 8 7.60156C8 6.48156 8.88 5.60156 10 5.60156C11.12 5.60156 12 6.48156 12 7.60156C12 8.72156 11.12 9.60156 10 9.60156ZM10 6.40156C9.32 6.40156 8.8 6.92156 8.8 7.60156C8.8 8.28156 9.32 8.80156 10 8.80156C10.68 8.80156 11.2 8.28156 11.2 7.60156C11.2 6.92156 10.68 6.40156 10 6.40156Z'
        fill='#868E96'
      />
    </svg>
  )
}

export { AddressIcon }
