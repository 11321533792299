import i18next from 'i18next'

export const slopeAngle = [
  { id: 1, name: 'plat dak' },
  { id: 2, name: '30°' },
  { id: 3, name: '45°' },
  { id: 4, name: '60°' },
  { id: 5, name: 'gevel' },
]

export const direction = [
  { id: 1, name: () => i18next.t('east_west'), translationKey: 'east_west' },
  {
    id: 2,
    name: () => i18next.t('southwest_southeast'),
    translationKey: 'southwest_southeast',
  },
  { id: 3, name: () => i18next.t('south'), translationKey: 'south' },
  { id: 4, name: () => i18next.t('east'), translationKey: 'east' },
  { id: 5, name: () => i18next.t('southeast'), translationKey: 'southeast' },
  { id: 6, name: () => i18next.t('west'), translationKey: 'west' },
  { id: 7, name: () => i18next.t('southwest'), translationKey: 'southwest' },
]

export const placeType = [
  { id: 1, name: 'flat' },
  { id: 2, name: 'slanted' },
]
