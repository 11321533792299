import useCompanySectionsDropdown from './useCompanySectionsDropdown'
import { Select } from 'antd'

const CompanySectionsDropdown = ({
  companyTabError,
  companySections,
  setActiveTab,
  companyData,
  activeTab,
}) => {
  const { options, t, handleChange } = useCompanySectionsDropdown({
    companySections,
    setActiveTab,
    companyData,
  })

  return (
    <div className='company__sections-dropdown'>
      <p className='company__sections-dropdown__title'>{t('company')}</p>

      <Select
        onChange={handleChange}
        defaultValue={activeTab}
        value={activeTab}
        options={options}
      />
    </div>
  )
}

export default CompanySectionsDropdown
