import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import Schema from 'async-validator'

import App from './App.jsx'
import store from './store/store'

import './services/i18n'
import { AntdConfigProvider } from './services/AntdConfigProvider.jsx'
import SuspenseFallback from './SuspenseFallback.jsx'

Schema.warning = function () {}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      notifyOnChangeProps: 'tracked',
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<SuspenseFallback />}>
            <AntdConfigProvider>
              <App />
            </AntdConfigProvider>
          </Suspense>
        </QueryClientProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
