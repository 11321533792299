import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addMaterialRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddMaterialModal = (setShowAddMaterialModal) => {
  const queryClient = useQueryClient()
  const { user } = useSelector((state) => state.globalSlice)

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const closeModal = () => {
    setShowAddMaterialModal(false)
    form.resetFields()
  }

  const {
    mutate: addMaterial,
    isLoading,
    error,
  } = useMutation((variables) => !isLoading && addMaterialRequest(variables), {
    onSuccess: () => {
      queryClient.invalidateQueries('materials')
      closeModal()
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function handleAddMaterial(values) {
    return addMaterial({ ...values, company_id: user?.company?.id })
  }

  return {
    addMaterialError: handleValidationError(error),
    addMaterial: handleAddMaterial,
    closeModal,
    isLoading,
    form,
    t,
  }
}
