import PanelModalForm from './PanelModalForm'
import { useShowPanelModal } from './hooks'

const ShowPanelModal = ({ setShowPanelModal, showPanelModal }) => {
  const {
    updatePanelError,
    currentPanel,
    updatePanel,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowPanelModal(setShowPanelModal, showPanelModal)

  return (
    <PanelModalForm
      actionButtonTitle={t('update')}
      currentPanel={currentPanel}
      show={showPanelModal.show}
      closeHandler={closeModal}
      errors={updatePanelError}
      onFinish={updatePanel}
      isLoading={isLoading}
      form={form}
    />
  )
}

export default ShowPanelModal
