import { useLocation } from 'react-router-dom'

const useLinkWithQueryParam = (queryParamName, queryParamValue) => {
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  searchParams.set(queryParamName, queryParamValue)

  const newSearch = searchParams.toString()

  return { location, newSearch }
}

export default useLinkWithQueryParam
