import React from 'react'

function ProjectPaidStatusIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M12.1244 11.1111C12.0249 11.1111 11.9295 11.1462 11.8591 11.2087C11.7888 11.2713 11.7493 11.356 11.7493 11.4444C11.7493 11.5328 11.7888 11.6176 11.8591 11.6801C11.9295 11.7427 12.0249 11.7778 12.1244 11.7778H13.8746C13.9741 11.7778 14.0695 11.7427 14.1399 11.6801C14.2102 11.6176 14.2497 11.5328 14.2497 11.4444C14.2497 11.356 14.2102 11.2713 14.1399 11.2087C14.0695 11.1462 13.9741 11.1111 13.8746 11.1111H12.1244ZM4 7.66667C4 7.22464 4.19758 6.80072 4.54927 6.48816C4.90096 6.17559 5.37795 6 5.87531 6H14.1247C14.6221 6 15.099 6.17559 15.4507 6.48816C15.8024 6.80072 16 7.22464 16 7.66667V12.3333C16 12.7754 15.8024 13.1993 15.4507 13.5118C15.099 13.8244 14.6221 14 14.1247 14H5.87581C5.37845 14 4.90146 13.8244 4.54977 13.5118C4.19808 13.1993 4.0005 12.7754 4.0005 12.3333V7.66667H4ZM5.87531 6.66667C5.57689 6.66667 5.2907 6.77202 5.07968 6.95956C4.86867 7.1471 4.75012 7.40145 4.75012 7.66667V8H15.2499V7.66667C15.2499 7.40145 15.1313 7.1471 14.9203 6.95956C14.7093 6.77202 14.4231 6.66667 14.1247 6.66667H5.87531ZM4.75012 12.3333C4.75012 12.5985 4.86867 12.8529 5.07968 13.0404C5.2907 13.228 5.57689 13.3333 5.87531 13.3333H14.1247C14.4231 13.3333 14.7093 13.228 14.9203 13.0404C15.1313 12.8529 15.2499 12.5985 15.2499 12.3333V8.66667H4.75063V12.3333H4.75012Z'
        fill='#868E96'
      />
    </svg>
  )
}

export { ProjectPaidStatusIcon }
