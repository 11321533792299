import {
  TASKS,
  PROJECT_PAID_STATUS,
  PROJECT_STATUS,
  WORKER_ROLES,
} from './enums'

const TASK_COLORS = {
  [TASKS.planned.value]: { text: '#CF1322', background: '#FFF4F3' },
  [TASKS.planned_and_confirmed.value]: {
    text: '#08979C',
    background: '#E6FFFB',
  },
  [TASKS.work_complete.value]: { text: '#389E0D', background: '#F6FFED' },
}

const PROJECT_PAID_COlORS = {
  [PROJECT_PAID_STATUS.partially_paid.value]: {
    text: '#0035C3',
    background: '#F1F3F5',
  },
  [PROJECT_PAID_STATUS.fully_paid.value]: {
    text: '#237804',
    background: '#F1F3F5',
  },
  [PROJECT_PAID_STATUS.not_paid.value]: {
    text: '#FA541C',
    background: '#F1F3F5',
  },
}

const PROJECT_STATUS_COLORS = {
  [PROJECT_STATUS.planning.value]: { text: '#606870', background: '#F8F9FA' },
  [PROJECT_STATUS.on_hold.value]: { text: '#D46B08', background: '#FFF7E6' },
  [PROJECT_STATUS.lost.value]: { text: '#C41D7F', background: '#FFF0F6' },
  [PROJECT_STATUS.finished.value]: { text: '#5B8C00', background: '#FCFFE6' },
}

const WORKER_ROLES_COLOR = {
  [WORKER_ROLES.mechanic.value]: { text: '#9254DE' },
  [WORKER_ROLES.roof_worker.value]: { text: '#08979C' },
  [WORKER_ROLES.team_lead.value]: { text: '#1F73E2' },
}

export {
  TASK_COLORS,
  PROJECT_PAID_COlORS,
  PROJECT_STATUS_COLORS,
  WORKER_ROLES_COLOR,
}
