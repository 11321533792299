import { useTranslation } from 'react-i18next'
import { ButtonWithPlus } from 'src/common'

const AddInputs = ({ onClick = () => {}, disabled = false }) => {
  const { t } = useTranslation()
  return (
    <div className='bottombuttons'>
      <ButtonWithPlus
        disabled={disabled}
        onClick={onClick}
        text={t('add_panel')}
        type='secondary'
      />
    </div>
  )
}
export default AddInputs
