import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { ShowFieldError, AgreeDeleteModal } from 'src/common'
import { Select, Form, InputNumber, Button } from 'antd'
import { useAttachConverterToProject } from './hooks'

const Converter = ({
  deleteConverter = () => {},
  data = null,
  deleteDisable = false,
  projectCompanyId,
}) => {
  const {
    converterBrands,
    converters,
    converter,
    isAdding,
    error,
    layout,
    form,
    t,
    clickHandler,
    converterChangeHandler,
    typeChangeHandler,
    finishHandler,
    closeConfirmationModal,
    showConfirmationModal,
    fetchBrandOnScroll,
    fetchConverterOnScroll,
  } = useAttachConverterToProject({
    data,
    deleteConverter,
    projectCompanyId,
  })

  return (
    <Form
      form={form}
      className='accordionArrayItem'
      name='converters'
      initialValues={{
        type: data?.converter?.type,
        een_merk: data?.converter?.brand_id,
        quantity: data?.quantity,
      }}
      {...layout}
      onFinish={finishHandler}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Form.Item
        className={formItemErrorClasses(error?.brand_id)}
        required
        label={t('select_converter')}
      >
        <Form.Item name='een_merk' noStyle>
          <Select
            className={error?.brand_id ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            onChange={converterChangeHandler}
            getPopupContainer={(trigger) => trigger.parentNode}
            onPopupScroll={fetchBrandOnScroll}
          >
            {data?.converter?.brand && (
              <Select.Option
                key={data?.converter?.brand?.id}
                value={data?.converter?.brand?.id}
              >
                {data?.converter?.brand?.name}
              </Select.Option>
            )}
            {converterBrands?.map((page) => {
              return page?.data?.data?.map((brand) => {
                if (data?.converter?.brand?.id !== brand?.id) {
                  return (
                    <Select.Option key={brand?.id} value={brand?.id}>
                      {brand?.name}
                    </Select.Option>
                  )
                } else {
                  return null
                }
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.brand_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.converter_id)}
        required
        label={t('select_type')}
      >
        <Form.Item name='type' noStyle>
          <Select
            className={error?.converter_id ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={!converters || !canManageProject()}
            onChange={typeChangeHandler}
            onPopupScroll={fetchConverterOnScroll}
          >
            {converters?.map((page) => {
              return page?.data?.data?.map((converter) => {
                return (
                  <Select.Option key={converter?.id} value={converter?.type}>
                    {converter?.type}
                  </Select.Option>
                )
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.converter_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.quantity)}
        required
        label={t('quantity')}
      >
        <Form.Item name='quantity' noStyle>
          <InputNumber
            style={inputStyles(error?.quantity)}
            disabled={!canManageProject()}
            className='input_number'
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.quantity} />
      </Form.Item>

      <div className='productinfo'>
        <Form.Item label={t('name')}>{converter?.name}</Form.Item>
        <Form.Item label={t('max_dc')}>{converter?.max_dc}</Form.Item>
        <Form.Item label={t('max_ac')}>{converter?.max_ac}</Form.Item>
        <Form.Item label={t('boot_power')}>{converter?.boot_power}</Form.Item>
        <Form.Item label={t('dimensions_mm')}>
          {converter
            ? `${converter?.length} x ${converter?.width} x ${converter?.depth}`
            : ''}
        </Form.Item>
        <Form.Item label={t('phases')}>{converter?.phases}</Form.Item>
        <Form.Item label={t('amp')}>{converter?.amp}</Form.Item>
        <Form.Item label={t('efficiency')}>{converter?.efficiency}</Form.Item>
      </div>

      <div className='accordionArrayItem__removeSurveyProduct'>
        <Button
          onClick={clickHandler}
          disabled={isAdding || deleteDisable || !canManageProject()}
          type={data ? 'danger' : 'secondary'}
        >
          {!data ? t('close') : t('delete')}
        </Button>
        <Button
          disabled={isAdding || !canManageProject()}
          htmlType='submit'
          type='primary'
        >
          {!data || (data && data?.converter?.id !== converter?.id)
            ? t('save')
            : t('update')}
        </Button>
      </div>
      {showConfirmationModal ? (
        <AgreeDeleteModal
          visible={showConfirmationModal}
          text={t('delete_converter_warning')}
          yesText={t('yes')}
          noText={t('no')}
          onCancel={closeConfirmationModal}
          onSubmit={() => deleteConverter(data?.id)}
          disabled={deleteDisable}
        />
      ) : null}
    </Form>
  )
}

export default Converter
