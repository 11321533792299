import FormActivityType from './FormActivityType'
import { useAddActivityType } from './hooks'

const AddActivityType = ({ setShowAddTypeModal, show }) => {
  const {
    addActivityTypeError,
    addActivityType,
    isLoading,
    closeModal,
    form,
    t,
  } = useAddActivityType(setShowAddTypeModal)

  return (
    <FormActivityType
      actionButtonTitle={t('save')}
      errors={addActivityTypeError}
      onFinish={addActivityType}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default AddActivityType
