import { Typography } from 'antd'
import React from 'react'
import { CustomModal } from 'src/common'

const additionalConfiguration = {
  style: {
    borderRadius: '50px',
  },
}

const TermsOfAgreementModal = ({ show = false, close = () => {} }) => {
  return (
    <CustomModal
      additionalConfiguration={additionalConfiguration}
      close={close}
      show={show}
    >
      <Typography.Title level={3} className='terms_title'>
        Terms of Agreement
      </Typography.Title>
      <p className='terms_body'>
        Lörem ipsum pobel rogen inaskad att uberisering agflation. Fanade
        semirar faliga e-demokrati. Egopobeligen preguktigt i dorer den anteligt
        i teotism. Triv fotobomba. Egons ser. Sek ybel äligen: i niligen kost.
        App ende ant sana. Agflation årat. Giganat södihet inte pretuns mill och
        David Larsson. Besk prektigt detelogi i falogi gens. Ditt dide dägt är
        bötresoda. Sock bens, retyngar, av dialigt autorogt. Telett teranosade
        och gårade i apocentrism vulkanresa. Kangar jånera. Plasa dekenat.
        Krobelt kvasinat. Noktiga Robert Candia i suskade. Planade dikär kvasir
        receptmotionär. Semimin preng i dusade incel nysm. Ter spårtjuv. Tinde
        EU-migrant. Kediliga por huligt. Pseudosade magen. Gustav Lundgren
        klittra. Hudat hus songar. Ofuska rask ifall rogisk. Syrtad loba
        tradade. Josöläktiga antigt vavasm. Du kan vara drabbad. Ångerrösta
        spårtjuv planosk tis och kropände. Legga tineras. Röng derar decinade.
        Ok exodade och obelt parareböska. Fidat kronera, personlig. Ger kaska.
        Unat dekadade monorore krode. Dingen gåbel. Bepiska pomuvis dm:a. Plal
        gifägon i dis. Debel seföbel. Tugelogi savis dihuren antipp.
        Förpackningsfri sen viagra, rugbyförälder. Hugo Hedlund räckviddsångest.
        Yoloa ljudöra. Tun isade. Terangen antivaxxare, dimisäsk. Cookie lara.
        Hungar sere elogi eurode. Biktig kronat. Omuv. Metalog gåktig såväl som
        tongen. Prevaligt kepining verade, intraska. Sogel tedäll. Upisa
        prektig. Opinde. Multissa intrangen opär. Ressade meganas utom
        kalsongbadare, även om åktig, lure. Multism vina. Inde sävis och gil.
        Syssna ygårat. Trevis degen, ona alltså faplagulig, ryggprotest. Ladol
        regågt fast immersiv. Talibanisering år i rong och skämsfilter
        nätvandra. Pande pende ade. Du kan vara drabbad. Ror dingen, barista än
        cisperson sonde. Dor dotesade gesesade. Ode tiplabel, misa såväl som
        pona. Vandrande skolbuss Helen Eriksson vithetsnorm torggängare monosk.
        Stenolig trekast nyngen fan. Pretyn essade ett Michael Lundström
        orysulig ifall poddtaxi. Ebba Larsson medelnetik. Klicktivism kor tyr
        krologi. Vanuligen beteendedesign, descentrism, biligt. Sper. Prefili
        begisk. Apomodern Yvonne Norberg beduling geoligt även om mms. Uckig.
        Pyramidmatta syv nuktig post-it-krig i megavis. Ann Nordström plogga
        Olle Abrahamsson inte astror. Dänar synitet. Sere oplaheten heterov bek.
        On trafficking, kärat, ifall snippgympa. Homohuralingar sassade.
        Metasion lur det framtidsfullmakt Ulla Ström abform. Lulig diasm.
      </p>
    </CustomModal>
  )
}

export default TermsOfAgreementModal
