import { canPerform, getTableSortValue } from 'src/utils'
import ShowPanelModal from './components/ShowPanelModal'
import AddPanelModal from './components/AddPanelModal'
import PanelsTableColumns from './PanelsTableColumns'
import { usePanels } from './usePanels'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Search,
  Header,
} from 'src/common'

const Panels = () => {
  const {
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowImportCsvModal,
    deletingMultipleItem,
    deleteSelectedItems,
    isPanelsDataLoading,
    showImportCsvModal,
    setShowPanelModal,
    selectedPanelsIds,
    setAddPanelModal,
    handlePageChange,
    setDeletePanels,
    paginationInfo,
    showPanelModal,
    exportDisabled,
    setImportError,
    onSearchClick,
    addPanelModal,
    rowSelection,
    importPanels,
    deletePanels,
    exportPanel,
    deletePanel,
    importError,
    importing,
    dispatch,
    deleting,
    setSort,
    panels,
    onRow,
    t,
  } = usePanels()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('panels')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isPanelsDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            disabled={
              canPerform('manage panel') || canPerform('manage own panel')
                ? false
                : true
            }
            text={t('add')}
            onClick={() => setAddPanelModal(true)}
          />
          <ButtonWithTrash
            onClick={showMultipleDeleteModalHandler}
            disabled={!selectedPanelsIds.length}
            text={t('delete_selected_item')}
          />
        </div>
        <Table
          scroll={{ x: '992px' }}
          rowSelection={rowSelection}
          columns={PanelsTableColumns(t, setDeletePanels)}
          dataSource={panels || []}
          onRow={onRow}
          loading={isPanelsDataLoading}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
          bordered
        />
        <div className='contentFooter'>
          <CsvButton
            disabled={exportDisabled}
            onClick={exportPanel}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage panel') || canPerform('manage own panel')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showImportCsvModal && (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          setImportError={setImportError}
          submitModal={importPanels}
          importError={importError}
          disabled={importing}
        />
      )}

      {addPanelModal && (
        <AddPanelModal
          setAddPanelModal={setAddPanelModal}
          addPanelModal={addPanelModal}
        />
      )}

      {showPanelModal.show && (
        <ShowPanelModal
          setShowPanelModal={setShowPanelModal}
          showPanelModal={showPanelModal}
        />
      )}

      {deletePanels.show && (
        <AgreeDeleteModal
          visible={deletePanels.show}
          targetName={deletePanels?.data?.name}
          onCancel={() => setDeletePanels({ show: false, data: {} })}
          onSubmit={() => {
            deletePanels.data.id && deletePanel(deletePanels.data.id)
          }}
          disabled={deleting}
        />
      )}

      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          onSubmit={deleteSelectedItems}
          disabled={deletingMultipleItem}
        />
      )}
    </div>
  )
}

export default Panels
