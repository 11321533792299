import { Empty, Spin } from 'antd'
import React from 'react'

const NoData = ({ isLoading = false }) => {
  return (
    <>{isLoading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>
  )
}

export default NoData
