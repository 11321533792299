export const BriefcaseIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M15.3608 3.31081H12.4387V1.52096C12.4379 1.19208 12.3109 0.876892 12.0854 0.64434C11.8599 0.411788 11.5542 0.2808 11.2353 0.280029H5.32885C5.00992 0.2808 4.70427 0.411788 4.47875 0.64434C4.25324 0.876892 4.12621 1.19208 4.12547 1.52096V3.31081H1.20338C0.884453 3.31158 0.578803 3.44257 0.353288 3.67512C0.127772 3.90768 0.000747778 4.22286 0 4.55174V14.4792C0.000747778 14.808 0.127772 15.1232 0.353288 15.3558C0.578803 15.5883 0.884453 15.7193 1.20338 15.7201H15.3608C15.6797 15.7193 15.9854 15.5883 16.2109 15.3558C16.4364 15.1232 16.5634 14.808 16.5642 14.4792V4.55174C16.5634 4.22286 16.4364 3.90768 16.2109 3.67512C15.9854 3.44257 15.6797 3.31158 15.3608 3.31081ZM9.05224 7.24674L9.44582 8.89061H7.11834L7.51192 7.24674H9.05224ZM4.83334 1.52096C4.83334 1.38544 4.88554 1.25547 4.97847 1.15965C5.07139 1.06382 5.19743 1.00999 5.32885 1.00999H11.2353C11.3667 1.00999 11.4928 1.06382 11.5857 1.15965C11.6786 1.25547 11.7308 1.38544 11.7308 1.52096V3.31081H4.83334V1.52096Z'
        fill='#ADB5BD'
      />
    </svg>
  )
}
