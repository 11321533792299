import { DownOutlined } from '@ant-design/icons'
import { ClockIcon } from './index'
import { useTranslation } from 'react-i18next'

function HistoryAndNotes({ handleToggleHistoryContent, isHistoryContentOpen }) {
  const { t } = useTranslation()

  return (
    <div className='container'>
      <button
        onClick={handleToggleHistoryContent}
        type='button'
        className='header_btn'
      >
        <div className='text_container'>
          <ClockIcon />
          <span>{t('history')}</span>
        </div>
        <DownOutlined
          className={`down_outline ${
            isHistoryContentOpen ? 'open_down_outlined' : ''
          }`}
        />
      </button>

      <div
        className={`content_container ${isHistoryContentOpen ? 'open' : ''}`}
      >
        <div className='content'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Blanditiis, quae.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HistoryAndNotes
