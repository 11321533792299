import { useAddressSection } from './hooks/useAddressSection'
import ProjectDetailsSelector from './ProjectDetailsSelector'
import { ShowFieldError } from 'src/common'
import { Form, Input } from 'antd'

const AddressSection = ({
  getAddressOnInitialRender,
  mutationErrors = {},
  form,
}) => {
  const { countriesList, error, t, setZipcode, setHouseNumber } =
    useAddressSection({ form, getAddressOnInitialRender })

  return (
    <div className='form__section__container address__section'>
      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.zipcode && 'error'
        }`}
        label={`${t('zipcode')}*`}
      >
        <Form.Item name='zipcode' noStyle>
          <Input
            placeholder={t('zipcode')}
            name='zipcode'
            onChange={(val) => setZipcode(val.target.value)}
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.zipcode} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.house_number && 'error'
        }`}
        label={`${t('house_number')}*`}
      >
        <Form.Item name='house_number' noStyle>
          <Input
            placeholder={t('house_number')}
            name='house_number'
            onChange={(val) => setHouseNumber(val.target.value)}
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.house_number} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.house_number_prefix && 'error'
        }`}
        label={`${t('house_number_suffix')}`}
      >
        <Form.Item name='house_number_prefix' noStyle>
          <Input
            placeholder={t('house_number_suffix')}
            name='house_number_prefix'
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.house_number_prefix} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.location && 'error'
        }`}
        label={`${t('location')}*`}
      >
        <Form.Item name='location' noStyle>
          <Input
            placeholder={t('location')}
            error={mutationErrors?.location}
            name='location'
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.location} />
      </Form.Item>

      <Form.Item
        className={`project__creation__form__item ${
          mutationErrors?.street && 'error'
        }`}
        label={`${t('street')}*`}
      >
        <Form.Item name='street' noStyle>
          <Input
            placeholder={t('street')}
            error={error?.street}
            name='street'
          />
        </Form.Item>
        <ShowFieldError error={mutationErrors?.street} />
      </Form.Item>

      <ProjectDetailsSelector
        optionsList={countriesList}
        error={mutationErrors}
        changeLanguage={true}
        label={t('country')}
        name='country_id'
      />
    </div>
  )
}

export default AddressSection
