import { renderAmount, canPerform } from 'src/utils'
import { ButtonWithTrash } from 'src/common'

const WifiModuleColumns = (setDeleteModal, t) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('purchase_price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          disabled={
            canPerform('delete wifi-module') ||
            canPerform('delete own wifi-module')
              ? false
              : true
          }
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default WifiModuleColumns
