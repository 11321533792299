import LeadsTableColumns from './LeadsTableColumns'
import { rowSelectionActions } from 'src/utils'
import { Table } from 'antd'

const LeadsTable = ({
  paginationInfo = {},
  setSelectedLeadIds,
  isLeadsDataLoading,
  handleTableChange,
  handlePageChange,
  leadsData,
  onRow,
}) => {
  return (
    <Table
      rowSelection={rowSelectionActions(setSelectedLeadIds)}
      columns={LeadsTableColumns()}
      rowKey={(record) => record.id}
      onChange={handleTableChange}
      dataSource={leadsData || []}
      className='custom-antd-table'
      pagination={{
        onChange: (page, size) => handlePageChange(page, size),
        total: paginationInfo?.total,
        current: paginationInfo?.page,
      }}
      loading={isLeadsDataLoading}
      scroll={{ x: '992px' }}
      onRow={onRow}
      bordered
    />
  )
}

export default LeadsTable
