import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addActivityRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddActivityType = (setShowAddActivitiesModal) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { user } = useSelector((state) => state.globalSlice)

  const closeModal = () => {
    setShowAddActivitiesModal(false)
    form.resetFields()
  }

  const {
    isLoading,
    mutate: addActivity,
    error,
  } = useMutation((data) => !isLoading && addActivityRequest(data), {
    onSuccess: () => {
      queryClient.refetchQueries('activities')
      closeModal()
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function handleAddActivity(values) {
    return addActivity({ ...values, company_id: user?.company?.id })
  }

  return {
    addActivityError: handleValidationError(error),
    addActivity: handleAddActivity,
    closeModal,
    isLoading,
    form,
    t,
  }
}
