import { Tooltip } from 'antd'

const AntdTooltip = (props) => {
  const { children, placement, title, color } = props

  return (
    <Tooltip
      color={color || 'rgba(61, 146, 236, 1)'}
      placement={placement}
      title={title}
    >
      {children}
    </Tooltip>
  )
}

export default AntdTooltip
