import React from 'react'
import { Badge } from 'antd'
import BellIcon from 'src/assets/icons/bell.svg'

function NotificationIcon({ toggleNotification, unreadNotifications }) {
  return (
    <div
      onClick={toggleNotification}
      className='sideNavigation__notificationIcon__badgeAndBell'
    >
      <Badge count={unreadNotifications}>
        <img className='sidebarIcon' src={BellIcon} alt='notification icon' />
      </Badge>
    </div>
  )
}

export default NotificationIcon
