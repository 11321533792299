import axios from '../../axios'

export const allNotificationsCountRequest = async (pageParam) => {
  return await axios.get(`api/notifications?page=${pageParam}&per_page=20`)
}

export const notificationsCountRequest = async () => {
  return await axios.get('api/notifications/count')
}

export const readNotificationRequest = async (data) => {
  return await axios.patch('api/notifications/read', data)
}

export const readAllNotificationsRequest = async () => {
  return await axios.patch('api/notifications/read_all')
}

export const notificationTokenRequest = async (data) => {
  return await axios.patch('api/notifications/fcm-token', data)
}
