import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { commentsRequest, createCommentRequest } from 'src/services'
import { handleNonValidationError } from 'src/utils'
import { useParams } from 'react-router'
import { Form } from 'antd'

export function useComments() {
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const [form] = Form.useForm()

  const {
    isLoading: isCommentsLoading,
    data: commentsData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['comments', projectId],
    ({ pageParam = 1 }) => commentsRequest(projectId, pageParam),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: !!projectId,
    }
  )

  const {
    mutate: createCommentMutation,
    isLoading: isCommentCreating,
    error,
  } = useMutation(createCommentRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('comments')
      form.resetFields()
    },
    onError: () => {
      handleNonValidationError(error)
    },
  })

  function createComment(commentInput) {
    const comment = commentInput?.comment
    if (comment?.length < 1 || !projectId) return

    const data = {
      commentable_type: 'Project',
      commentable_id: projectId,
      comment,
    }
    createCommentMutation(data)
  }

  return {
    isLoading: isCommentCreating || isCommentsLoading || isFetching,
    commentsCount: commentsData?.pages?.[0]?.data?.meta?.total,
    comments: commentsData?.pages,
    createComment,
    fetchNextPage,
    hasNextPage,
    form,
  }
}
