import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { handleNonValidationError, canManageProject } from 'src/utils'
import { createOfferRequest, offersRequest } from 'src/services'
import { useParams, useLocation } from 'react-router'
import { useEffect, useState } from 'react'

function useOffers() {
  const [openedOffers, setOpenedOffers] = useState([])
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const { search } = useLocation()

  const {
    data: offersData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['offers', projectId],
    ({ pageParam = 1 }) => offersRequest(projectId, pageParam),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: !!projectId,
      refetchOnWindowFocus: true,
      staleTime: 2 * 1000,
    }
  )

  const {
    mutate: createOfferMutation,
    isLoading: isOfferCreating,
    error,
  } = useMutation(createOfferRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['offers', projectId])
    },
    onError: () => {
      handleNonValidationError(error)
    },
  })

  useEffect(() => {
    const params = new URLSearchParams(search)

    const openedOffersParamsData = Array.from(params.entries())
      .filter(([key]) => key.startsWith('openedOffers['))
      .reduce((result, [key, value]) => {
        const match = key.match(/openedOffers\[(\d+)\]\.(id|step)/)
        if (match) {
          const [, index, property] = match
          if (!result[index]) {
            result[index] = {}
          }
          result[index][property] = +value ? +value : value
        }
        return result
      }, [])

    setOpenedOffers(openedOffersParamsData)
  }, [search])

  function createOffer() {
    createOfferMutation({ project_id: projectId })
  }

  return {
    isLoading: isOfferCreating,
    offers: offersData?.pages,
    fetchNextPage,
    openedOffers,
    hasNextPage,
    createOffer,
  }
}

export { useOffers }
