import { useTranslation } from 'react-i18next'

function PlaningProduct({ product }) {
  const { t } = useTranslation()

  return (
    <div className='product_information_container'>
      <div className='product_information_container_content'>
        <span className='product_information_font_semibold'>
          {t(product?.name)}
        </span>
        <span>{product?.type}</span>
      </div>
      <div className='product_information_container_content'>
        <span className='product_information_font_semibold'>
          {t('quantity')}
        </span>
        <span>{product?.quantityCount}</span>
      </div>
    </div>
  )
}

export default PlaningProduct
