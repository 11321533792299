export const appendIds = (idsArr) => {
  let queryStringParam = ''

  if (idsArr.length > 0) {
    idsArr?.forEach((id, index) => {
      queryStringParam += `${index === 0 ? '' : '&'}ids[]=${id}`
    })
  }

  return queryStringParam
}
