import { handleNonValidationError, canManageProject } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { removeOfferProductRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useState } from 'react'

export function useMountingSystemAccordion() {
  const [showMountingSystemAddingForm, setShowMountingSystemAddingForm] =
    useState(false)
  const { offerId } = useParams()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const {
    mutate: removeMountingSystemMutation,
    isLoading: removingMountingSystem,
  } = useMutation(removeOfferProductRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
      }
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function openMountingSystemAddingForm() {
    if (!canManageProject()) return

    setShowMountingSystemAddingForm(true)
  }
  function closeMountingSystemAddingForm() {
    setShowMountingSystemAddingForm(false)
  }

  function removeMountingSystem(id) {
    removeMountingSystemMutation({
      id,
      table: 'offer_mounting_system',
    })
  }

  return {
    removeMountingSystem,
    removingMountingSystem,
    showMountingSystemAddingForm,
    closeMountingSystemAddingForm,
    openMountingSystemAddingForm,
    translate: t,
  }
}
