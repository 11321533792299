function MultiselectOption({
  selectedOptions,
  option,
  labelKey,
  handleOptionSelect,
}) {
  return (
    <div>
      <label>
        <input
          type='checkbox'
          checked={selectedOptions.some(
            (item) => item[labelKey] === option[labelKey]
          )}
          onChange={() => handleOptionSelect(option)}
        />
        <span>{option[labelKey]}</span>
      </label>
    </div>
  )
}

export default MultiselectOption
