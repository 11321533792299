export const PlanningIcon = ({
  height = 19,
  width = 18,
  color = '#ADB5BD',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.125 2.5H14.25V1.625C14.25 1.1 13.9 0.75 13.375 0.75C12.85 0.75 12.5 1.1 12.5 1.625V2.5H5.5V1.625C5.5 1.1 5.15 0.75 4.625 0.75C4.1 0.75 3.75 1.1 3.75 1.625V2.5H2.875C1.3875 2.5 0.25 3.6375 0.25 5.125V6H17.75V5.125C17.75 3.6375 16.6125 2.5 15.125 2.5ZM0.25 15.625C0.25 17.1125 1.3875 18.25 2.875 18.25H15.125C16.6125 18.25 17.75 17.1125 17.75 15.625V7.75H0.25V15.625ZM13.375 9.5C13.9 9.5 14.25 9.85 14.25 10.375C14.25 10.9 13.9 11.25 13.375 11.25C12.85 11.25 12.5 10.9 12.5 10.375C12.5 9.85 12.85 9.5 13.375 9.5ZM13.375 13C13.9 13 14.25 13.35 14.25 13.875C14.25 14.4 13.9 14.75 13.375 14.75C12.85 14.75 12.5 14.4 12.5 13.875C12.5 13.35 12.85 13 13.375 13ZM9 9.5C9.525 9.5 9.875 9.85 9.875 10.375C9.875 10.9 9.525 11.25 9 11.25C8.475 11.25 8.125 10.9 8.125 10.375C8.125 9.85 8.475 9.5 9 9.5ZM9 13C9.525 13 9.875 13.35 9.875 13.875C9.875 14.4 9.525 14.75 9 14.75C8.475 14.75 8.125 14.4 8.125 13.875C8.125 13.35 8.475 13 9 13ZM4.625 9.5C5.15 9.5 5.5 9.85 5.5 10.375C5.5 10.9 5.15 11.25 4.625 11.25C4.1 11.25 3.75 10.9 3.75 10.375C3.75 9.85 4.1 9.5 4.625 9.5ZM4.625 13C5.15 13 5.5 13.35 5.5 13.875C5.5 14.4 5.15 14.75 4.625 14.75C4.1 14.75 3.75 14.4 3.75 13.875C3.75 13.35 4.1 13 4.625 13Z'
        fill={color}
      />
    </svg>
  )
}
