import { kindFilter } from './kindFilter'

const kindStyle = {
  paddingLeft: '5rem',
  paddingRight: '3rem',
}

export const MaterialTableColumns = (projectReferences = [], translate) => {
  return [
    {
      title: () => <div style={kindStyle}>{translate('kind')}</div>,
      dataIndex: 'kind',
      filters: kindFilter(),
      render: (val) => {
        return <div style={kindStyle}>{val}</div>
      },
      width: '200px',
    },
    {
      title: translate('project_reference'),
      dataIndex: 'reference',
      sorter: true,
    },
    {
      title: translate('project_name'),
      dataIndex: 'project_name',
      sorter: true,
    },
    {
      title: translate('brand'),
      dataIndex: 'brand',
    },
    {
      title: translate('type'),
      dataIndex: 'type',
    },
    {
      title: translate('delivery_week'),
      dataIndex: 'delivery_week',
      sorter: true,
    },
    {
      title: translate('quantity'),
      dataIndex: 'quantity',
    },
  ]
}
