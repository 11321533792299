import React from 'react'

function DateIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M13.8889 3H6.11111C5.49746 3 5 3.54721 5 4.22222V12.7778C5 13.4528 5.49746 14 6.11111 14H13.8889C14.5025 14 15 13.4528 15 12.7778V4.22222C15 3.54721 14.5025 3 13.8889 3Z'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 2V4'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 2V4'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 7H15'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { DateIcon }
