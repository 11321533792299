import { WifiModuleFormModal } from './components'
import { useShowWifiModule } from './hooks'

const ShowWifiModule = ({ currentWifiModule, setShowWifiModule, show }) => {
  const {
    updateWifiModuleError,
    updateWifiModule,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowWifiModule(currentWifiModule, setShowWifiModule)

  return (
    <WifiModuleFormModal
      currentWifiModule={currentWifiModule}
      actionButtonTitle={t('update')}
      errors={updateWifiModuleError}
      onFinish={updateWifiModule}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default ShowWifiModule
