import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from 'src/utils'

const useTabSelectorOption = (title, setActiveTab) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const activeTab = getQueryParam('activeTab')

  const tabSelectHandler = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('activeTab', title)
    setActiveTab(title)
    history.replace({ search: searchParams.toString() })
  }

  return { tabSelectHandler, activeTab, t }
}

export default useTabSelectorOption
