import { useAutoSignatureBtn } from './useAutoSignatureBtn'
import { storageFileUri } from 'src/utils'
import { Button } from 'antd'

const AutoSignatureBtn = ({
  btnType = 'default',
  disabled = false,
  setAutoSigned,
  setSignature,
}) => {
  const { userSignatureData, isUserDataLoading, t } = useAutoSignatureBtn()

  return (
    <Button
      onClick={() => {
        const { path, name } = userSignatureData
        setAutoSigned(true)
        setSignature(storageFileUri(path, name))
      }}
      disabled={(!isUserDataLoading && !userSignatureData?.id) || disabled}
      type={btnType}
    >
      {t('automatic_signature')}
    </Button>
  )
}

export default AutoSignatureBtn
