import { handleNonValidationError, canManageProject } from 'src/utils'
import { useHistory, useParams, useLocation } from 'react-router'
import { useMutation, useQueryClient } from 'react-query'
import { updateProjectRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'

function useClientInformation({ project }) {
  const { user } = useSelector((state) => state.globalSlice)
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const { search } = useLocation()
  const { t } = useTranslation()
  const history = useHistory()

  const [selectedProjectTab, setSelectedProjectTab] =
    useState('clientInformation')

  useEffect(() => {
    const params = new URLSearchParams(search)
    const activeTab = params.get('projectTab')

    if (activeTab) {
      setSelectedProjectTab(activeTab)
    } else {
      setSelectedProjectTab('clientInformation')
    }
  }, [search])

  const { isLoading: isUpdating, mutate: updateProject } = useMutation(
    (data) => updateProjectRequest(projectId, data),

    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['projects', projectId],
        })
        message.success(t('updated_successfully'))
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function onEditClick() {
    if (!canManageProject()) return
    history.push(`/project/project-creation/${projectId}${search}`)
  }

  function updateProjectStatus(value) {
    if (!canManageProject()) return
    const data = {
      company_id: user?.company_id,
      ...getProjectEditState(),
      status_id: value,
    }
    updateProject(data)
  }

  function getProjectEditState() {
    const {
      manager,
      executor,
      category,
      condition,
      country,
      ...projectClientEditState
    } = project

    Object.keys(projectClientEditState).forEach((key) => {
      if (!projectClientEditState[key]) {
        delete projectClientEditState[key]
      }
    })
    return projectClientEditState
  }

  return {
    isProjectStatusDisabled: isUpdating || !canManageProject(),
    setSelectedProjectTab,
    updateProjectStatus,
    selectedProjectTab,
    onEditClick,
    t,
  }
}

export { useClientInformation }
