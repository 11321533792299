export const appendSearchParams = (searchParamsArr, name) => {
  let temp = ''

  if (searchParamsArr) {
    searchParamsArr?.forEach((el) => {
      temp += `&${name}[]=${el}`
    })
  }

  return temp
}
