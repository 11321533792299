import { useProjectCountDetails } from './useProjectCountDetails'
import { ListIcon, TicketViewIcon } from 'src/assets'

const ProjectCountDetails = (props) => {
  const { t } = useProjectCountDetails()

  return (
    <div
      className={`project__count__details__container ${
        props?.totalProjectsCount === 0 && 'justify-end '
      }`}
    >
      <p className='project__count__details'>
        {props?.totalProjectsCount > 0 &&
          `${t('showing')} ${props?.currentProjectsCount || '...'} ${t(
            'from'
          )} ${props?.totalProjectsCount || '...'} ${t('projects')}`}
      </p>

      <div className='view_mode_icons'>
        <div onClick={() => props?.setTicketView(false)}>
          <ListIcon isActive={!props?.ticketView} />
        </div>

        <div onClick={() => props?.setTicketView(true)}>
          <TicketViewIcon isActive={props?.ticketView} />
        </div>
      </div>
    </div>
  )
}

export default ProjectCountDetails
