import { formatToYMD } from 'src/utils'

function renderPlanningProjectTableColumns(t) {
  return [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: t('project_name'),
      dataIndex: ['project', 'name'],
    },

    {
      title: t('planner'),
      dataIndex: 'planner',
      render: () => {
        return <div className='planner not-assigned'>{t('not_assigned')}</div>
      },
    },

    {
      title: t('reference'),
      dataIndex: ['project', 'reference'],
      render: (value, record) => {
        return <div>{`${value} (${record?.code})`}</div>
      },
    },
    {
      title: t('type'),
      dataIndex: ['project', 'type'],
      render: (value) => {
        return <div> {value === 1 ? t('private') : t('business')}</div>
      },
    },
    {
      title: t('payment_status'),
      dataIndex: 'payment_status',
      render: () => {
        return <div className='status not-paid'>{t('not_paid')}</div>
      },
    },
    {
      title: t('estimated_installation_time'),
      dataIndex: 'installation_week',
      render: (value) => {
        return <div>{formatToYMD(new Date(value))}</div>
      },
    },
    {
      title: t('project_status'),
      dataIndex: 'status',
      render: () => {
        return <div className='status'>{t('planning')}</div>
      },
    },
  ]
}

export { renderPlanningProjectTableColumns }
