import OffersAndCommentsTooltip from './OffersAndCommentsTooltip'
import { AiOutlineUser } from 'react-icons/ai'
import { useComment } from './hooks'
import { getProfilePictureSrc } from 'src/utils'

function CommentTimeTooltipContent({ date, time }) {
  return (
    <div className='comment-time'>
      <span>{date}</span>
      <div />
      <span>{time}</span>
    </div>
  )
}

function Comment({ commentText, userName, createdAt, user }) {
  const { daysPassed, date, time } = useComment(createdAt)
  const commentUserProfileSrc = getProfilePictureSrc(user)

  return (
    <div className='comment'>
      {commentUserProfileSrc ? (
        <img
          width={40}
          height={40}
          style={{ borderRadius: '50%' }}
          src={commentUserProfileSrc}
          alt='profile'
        />
      ) : (
        <div className='user-image-wrapper'>
          <AiOutlineUser size={22} color='#86BBF3' />
        </div>
      )}

      <div className='comment-body'>
        <h6>{userName}</h6>
        <OffersAndCommentsTooltip
          placement='right'
          title={<CommentTimeTooltipContent date={date} time={time} />}
          additionalStyles={{
            padding: '0.6rem 0.8rem',
          }}
        >
          <span className='days-passed'>{`${daysPassed}`}</span>
        </OffersAndCommentsTooltip>

        <p className='comment__text'>{commentText}</p>
      </div>
    </div>
  )
}

export default Comment
