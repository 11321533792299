import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allWifiModulesRequest = async ({
  page,
  size = 10,
  companyId,
  searchKeyWord = '',
  sort = '',
}) => {
  return await axios.get(
    `api/wifi_modules?page=${page}&per_page=${size}${
      searchKeyWord && `&search=${searchKeyWord}`
    }${appendCompanyId(companyId)}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updateWifiModuleRequest = async (id, data) => {
  return await axios.put(`api/wifi_modules/${id}`, data)
}

export const addWifiModuleRequest = async (data) => {
  return await axios.post('api/wifi_modules', data)
}

export const importWifiRequest = async (data) => {
  return await axios.post('api/wifi_modules/import', data)
}

export const searchWifiModulesRequest = async (data) => {
  return await axios.post('api/wifi_modules/search', data)
}

export const deleteWifiModuleRequest = async (id) => {
  return await axios.delete(`api/wifi_modules/${id}`)
}
