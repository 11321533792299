import MountingSystemForm from './MountingSystemForm'
import { useAddMountingSystemModal } from './hooks'

const AddMountingSystemModal = ({ setShowAddMountingSystemModal, show }) => {
  const {
    addMountingSystemError,
    addMountingSystem,
    closeModal,
    isLoading,
    form,
    t,
  } = useAddMountingSystemModal(setShowAddMountingSystemModal)

  return (
    <MountingSystemForm
      errors={addMountingSystemError}
      actionButtonTitle={t('save')}
      onFinish={addMountingSystem}
      closeHandler={closeModal}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}

export default AddMountingSystemModal
