import { forgotPasswordRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

export const useForgotPassword = () => {
  const { t } = useTranslation()
  const { mutate, isLoading, data, error } = useMutation(forgotPasswordRequest)

  const handleSendEmail = (values) => {
    if (!values) return
    mutate({
      email: values.email,
    })
  }

  return {
    emailFieldError: error?.response?.data?.message,
    handleSendEmail,
    isLoading,
    data,
    t,
  }
}
