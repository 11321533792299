import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'src/hooks'

export const useSearch = (onSearchClick) => {
  const { t } = useTranslation()
  const clearFocusRef = useRef()
  const inputRef = useRef(null)

  const [searchValue, setSearchValue] = useState('')
  const [activeSearch, setActiveSearch] = useState(false)
  const [focusInput, setFocusInput] = useState(false)

  useOnClickOutside(clearFocusRef, () => {
    resetSearchState()
  })

  useEffect(() => {
    const timeoutId = setTimeout(handleSearchClick, 800)
    return () => clearTimeout(timeoutId)
  }, [searchValue])

  const { lg } = useBreakpoint()

  const resetSearchState = () => {
    setActiveSearch(false)
    setFocusInput(false)
  }

  const handleOnChange = (value) => {
    setSearchValue(value)
  }
  const handleInputClick = () => {
    inputRef.current.focus()
  }

  const handleSearchClick = () => {
    return onSearchClick(searchValue)
  }

  const handleSearchIconClick = () => {
    setActiveSearch(!activeSearch)
    if (!activeSearch) {
      handleInputClick()
    }
  }

  return {
    handleSearchIconClick,
    handleInputClick,
    handleOnChange,
    clearFocusRef,
    activeSearch,
    searchValue,
    focusInput,
    inputRef,
    lg,
    t,
  }
}
