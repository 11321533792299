import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  importMaterialsRequest,
  deleteMaterialsRequest,
  allMaterialsRequest,
  exportRequest,
} from 'src/services'

export function useMaterials() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.globalSlice)
  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const [deleteMaterial, setDeleteMaterial] = useState({
    show: false,
    data: {},
  })

  const [showMaterialModal, setShowMaterialModal] = useState({
    show: false,
    data: {},
  })

  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [importError, setImportError] = useState()

  const [selectedMaterialsIds, setSelectedMaterialsIds] = useState([])
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const rowSelection = rowSelectionActions(setSelectedMaterialsIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const {
    data: materialsData,
    isLoading: isMaterialsDataLoading,
    isPreviousData,
  } = useQuery(
    ['materials', page, size, sort, searchKeyWord, user?.company?.id],
    () =>
      allMaterialsRequest({
        companyId: user?.company?.id,
        searchKeyWord,
        page,
        size,
        sort,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedMaterialsIds,
      'materials',
      materialsData?.data?.data?.length
    )

  const { mutate: DeleteMaterial, isLoading: deleting } = useMutation(
    deleteMaterialsRequest,
    {
      onSuccess: (resp, deletedMaterialId) => {
        if (resp.data.success) {
          if (selectedMaterialsIds.includes(deletedMaterialId)) {
            setSelectedMaterialsIds((prev) =>
              prev.filter((id) => id !== deletedMaterialId)
            )
          }

          if (materialsData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('materials')
          setDeleteMaterial({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importMaterials, isLoading: importing } = useMutation(
    importMaterialsRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('materials')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportMaterialsMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp.data, 'materials')
      },
    }
  )

  function exportMaterials() {
    exportMaterialsMutation({
      model: 'materials',
    })
  }

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteAllSelectedProducts = () => {
    deleteMultipleItemMutation({
      items: selectedMaterialsIds,
      table: 'materials',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage material') ||
          canPerform('manage own material')
        ) {
          setShowMaterialModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    isMaterialsDataLoading: isMaterialsDataLoading || isPreviousData,
    exportDisabled: exporting || !materialsData?.data?.data?.length,
    paginationInfo: {
      total: materialsData?.data?.meta?.total,
      page,
    },
    materials: materialsData?.data?.data,
    showMultipleDeleteConfirmation,
    showMultipleDeleteModalHandler,
    deleteAllSelectedProducts,
    setShowAddMaterialModal,
    setShowImportCsvModal,
    showAddMaterialModal,
    setShowMaterialModal,
    selectedMaterialsIds,
    deletingMultipleItem,
    showImportCsvModal,
    setDeleteMaterial,
    showMaterialModal,
    handlePageChange,
    exportMaterials,
    importMaterials,
    deleteMaterial,
    DeleteMaterial,
    setImportError,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  }
}
