import { useTranslation } from 'react-i18next'
import { Views } from 'react-big-calendar'

import { useState, useMemo } from 'react'

function usePlanningCalendar() {
  const { t, i18n } = useTranslation()

  const [currentView, setCurrentView] = useState(Views.MONTH)

  function handleViewChange(view) {
    setCurrentView(view)
  }

  const isDutch = useMemo(() => {
    return i18n.language === 'nl'
  }, [i18n.language])

  const isDayView = useMemo(() => {
    return currentView === Views.DAY
  }, [currentView])

  return { handleViewChange, currentView, isDayView, isDutch, t }
}

export { usePlanningCalendar }
