import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

export const useConvertersAndDC = (setFieldsValue, getStepFiles) => {
  const [fileList, setFileList] = useState(getStepFiles(3))

  useEffect(() => {
    setFieldsValue({
      convertersAndDC_pictures: fileList,
    })
  }, [fileList])

  const { t } = useTranslation()

  const uploadChangeHandler = (info) => {
    setFileList(info?.fileList)
  }

  const checkboxChangeHandler = (value) => {
    setFieldsValue({
      roofoutlet: value,
    })
  }

  return { t, fileList, uploadChangeHandler, checkboxChangeHandler }
}
