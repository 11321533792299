import { DownOutlined } from '@ant-design/icons'
import { SelectGroup, TaskMultiselect } from './index'
import { useState } from 'react'
import { TASKS } from '../../data'
import { TASK_COLORS } from '../../data'
import { useTranslation } from 'react-i18next'
import TimeField from './TimeField'
import StartFinishTime from './StartFinishTime'
import { DatePicker } from 'src/common'
import { Form } from 'antd'

function Task({ task, index, deleteTask }) {
  const { t } = useTranslation()
  const [isTaskOpen, setIsTaskOpen] = useState(
    task.is_new ? task.is_new : false
  )

  const handleTaskContent = () => {
    setIsTaskOpen(!isTaskOpen)
  }

  const plannedWorkOptions = [
    {
      name: 'planned_ipsum',
      value: 'planned_ipsum',
    },
    {
      name: 'planned_ipsum',
      value: 'planned_ipsum',
    },
    {
      name: 'planned_ipsum',
      value: 'planned_ipsum',
    },
    {
      name: 'planned_ipsum',
      value: 'planned_ipsum',
    },
  ]

  return (
    <div className='task_container'>
      <div className='task_header'>
        <div
          className='task_header_title'
          onClick={() => {
            return handleTaskContent()
          }}
        >
          <div>
            {t('task')} {index + 1}
          </div>
          <DownOutlined
            className={`down_outline down_outline ${
              isTaskOpen ? 'open_down_outlined' : ''
            }`}
          />
        </div>

        <div className='task_header_btn_group'>
          <button disabled={true} className='task_header_save_btn'>
            {t('save')}
          </button>
          <div className='btn_separator'></div>
          <button
            onClick={() => {
              return deleteTask(index)
            }}
            className='task_header_delete_btn'
          >
            {t('delete')}
          </button>
        </div>
      </div>

      <div className={`task_content ${!isTaskOpen ? 'is_close' : ''}`}>
        <Form.Item label={t('date')} className='task_date_field'>
          <DatePicker dropdownClassName='task_schedule_drop_down' />
        </Form.Item>
        <TimeField
          id='departure_time'
          label={t('departure_time')}
          placeholder={t('add_time')}
        />
        <StartFinishTime
          id='starting_or_finishing_time'
          label={t('starting_or_finishing_time')}
          placeholder={t('select_time')}
        />
        <SelectGroup
          id='task_status'
          label={t('task_status')}
          options={Object.values(TASKS)}
          textColor={TASK_COLORS}
          groupClass='project_details_input_group'
        />
        <SelectGroup
          id='planned_work'
          label={t('planned_work')}
          options={plannedWorkOptions}
          groupClass='project_details_input_group'
        />
        {/*<SelectGroup*/}
        {/*  id='machinery'*/}
        {/*  label='Machinery'*/}
        {/*  groupClass='project_details_input_group'*/}
        {/*/>*/}
        <TaskMultiselect label={t('team_or_employee')} />
      </div>
    </div>
  )
}

export default Task
