import { Tooltip } from 'antd'

const baseStyles = {
  background: '#262626',
  color: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px',
  padding: '0.6rem 0.8rem',
  fontWeight: '400',
  fontSize: '1.4rem',
  lineHeight: '2.2rem',
}

function OffersAndCommentsTooltip({
  children,
  title,
  placement = 'top',
  additionalStyles,
}) {
  return (
    <Tooltip
      overlayInnerStyle={{
        ...baseStyles,
        ...additionalStyles,
      }}
      placement={placement}
      title={title}
    >
      {children}
    </Tooltip>
  )
}

export default OffersAndCommentsTooltip
