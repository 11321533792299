import { Modal } from 'antd'
import Sidebar from './Sidebar'
import Header from './Header'
import { useHandleModalView } from '../hooks'
import PlaningDetails from './PlaningDetails'
import ProductInformation from './ProductInformation'
import ClientInformationAndProjectDetails from './ClientInformationAndProjectDetails'
import Documents from './Documents'
import HistoryAndNotes from './HistoryAndNotes'
import DetailsAndActivitySwitcher from './DetailsAndActivitySwitcher'
import { Spinner } from 'src/common'
import { joinPanels } from 'src/utils'

function ProjectViewModal({
  openProjectViewModal,
  modalData,
  projectViewModalOnClick,
}) {
  const {
    isPlanningDetailsActive,
    isProductInformationActive,
    isClientInformationAndProjectDetailsActive,
    isDocumentActive,
    changeViewOnClick,
    offerData,
    offerDataIsLoading,
    isCondense,
    condenseModalOnClick,
    isDetails,
    isActivity,
    handleCondenseView,
    showHistory,
    showDetails,
    sm,
  } = useHandleModalView(modalData)

  return (
    <>
      <Modal
        mask={sm !== false && isCondense !== true}
        visible={openProjectViewModal}
        onCancel={() => projectViewModalOnClick()}
        className={`custom-modal ${isCondense ? 'condense' : ''}`}
        footer={null}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Header
          projectViewModalOnClick={projectViewModalOnClick}
          isCondense={isCondense}
          condenseModalOnClick={condenseModalOnClick}
        />
        {offerDataIsLoading ? (
          <div className='spinner-center'>
            <Spinner />
          </div>
        ) : (
          <div className='project-view-modal-container'>
            <DetailsAndActivitySwitcher
              isDetails={isDetails}
              isActivity={isActivity}
              handleCondenseView={handleCondenseView}
            />
            {showDetails ? (
              <>
                <Sidebar
                  isPlanningDetailsActive={isPlanningDetailsActive}
                  isProductInformationActive={isProductInformationActive}
                  isClientInformationAndProjectDetailsActive={
                    isClientInformationAndProjectDetailsActive
                  }
                  isDocumentActive={isDocumentActive}
                  changeViewOnClick={changeViewOnClick}
                />

                <div className='project-view-modal-content'>
                  {isPlanningDetailsActive ? <PlaningDetails /> : null}
                  {isProductInformationActive ? (
                    <ProductInformation
                      products={[
                        ...joinPanels(offerData?.offer_panels),
                        ...offerData?.offer_converters,
                        ...offerData?.offer_power_optimizers,
                        ...offerData?.offer_wifi_modules,
                        ...offerData?.offer_mounting_systems,
                        ...offerData?.offer_options,
                      ]}
                    />
                  ) : null}
                  {isClientInformationAndProjectDetailsActive ? (
                    <ClientInformationAndProjectDetails
                      project={offerData?.project}
                    />
                  ) : null}
                  {isDocumentActive ? (
                    <Documents
                      documents={
                        offerData?.project?.inspection?.is_finished
                          ? [
                              {
                                id: offerData?.project?.inspection?.id,
                                name: 'inspection',
                              },
                              ...offerData?.additional_docs,
                            ]
                          : offerData?.additional_docs
                      }
                    />
                  ) : null}
                </div>
              </>
            ) : null}
            {showHistory ? <HistoryAndNotes /> : null}
          </div>
        )}
      </Modal>
    </>
  )
}

export default ProjectViewModal
