import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {
  importPanelsRequest,
  deletePanelRequest,
  allPanelsRequest,
  exportRequest,
} from 'src/services'

export function usePanels() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const [showPanelModal, setShowPanelModal] = useState({
    show: false,
    data: {},
  })

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)
  const [addPanelModal, setAddPanelModal] = useState(false)

  const [deletePanels, setDeletePanels] = useState({ show: false, data: {} })
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [importError, setImportError] = useState()
  const [sort, setSort] = useState('')

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const {
    data: panelsData,
    isLoading: isPanelsDataLoading,
    isPreviousData,
  } = useQuery(
    ['panels', page, size, sort, searchKeyWord, user?.company?.id],
    () =>
      allPanelsRequest({
        companyId: user?.company?.id,
        searchKeyWord,
        sort,
        page,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const [selectedPanelsIds, setSelectedPanelsIds] = useState([])
  const rowSelection = rowSelectionActions(setSelectedPanelsIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedPanelsIds,
      'panels',
      panelsData?.data?.data?.length
    )

  const { mutate: deletePanel, isLoading: deleting } = useMutation(
    deletePanelRequest,
    {
      onSuccess: (resp, deletedPanelId) => {
        if (resp.data.success) {
          if (selectedPanelsIds.includes(deletedPanelId)) {
            setSelectedPanelsIds((prev) =>
              prev.filter((id) => id !== deletedPanelId)
            )
          }

          if (panelsData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('panels')
          setDeletePanels({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importPanels, isLoading: importing } = useMutation(
    importPanelsRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('panels')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportPanelMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    }
  )

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  function exportPanel() {
    exportPanelMutation({
      model: 'panels',
    })
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedPanelsIds,
      table: 'panels',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (canPerform('manage panel') || canPerform('manage own panel')) {
          setShowPanelModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    exportDisabled: exporting || !panelsData?.data?.data?.length,
    isPanelsDataLoading: isPanelsDataLoading || isPreviousData,
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowImportCsvModal,
    deletingMultipleItem,
    deleteSelectedItems,
    showImportCsvModal,
    selectedPanelsIds,
    setShowPanelModal,
    handlePageChange,
    setAddPanelModal,
    setDeletePanels,
    showPanelModal,
    setImportError,
    addPanelModal,
    onSearchClick,
    deletePanels,
    importPanels,
    rowSelection,
    exportPanel,
    deletePanel,
    importError,
    importing,
    dispatch,
    deleting,
    panels: panelsData?.data?.data,
    paginationInfo: {
      total: panelsData?.data?.meta?.total,
      page,
    },
    setSort,
    onRow,
    t,
  }
}
