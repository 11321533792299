import { useDispatch, ReactReduxContext } from 'react-redux'
import { usePageQueryParams } from './usePageQueryParams'
import { useInfiniteQueryInAntdFilter } from 'src/hooks'
import { useState, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { setUpdateUser } from 'src/actions'
import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  useQuery,
} from 'react-query'
import {
  handleNonValidationError,
  appendSearchParams,
  translateRole,
  isSalesPlus,
  isSuperUser,
  roles,
  translateSkill,
} from 'src/utils'
import {
  allCompaniesRequest,
  deleteUserRequest,
  getUsersRequest,
  allRolesRequest,
  allSkillsRequest,
} from 'src/services'
import { STALE_AND_CACHE } from 'src/common'

export function useUsers() {
  const { store } = useContext(ReactReduxContext)
  const user = store.getState()?.globalSlice?.user
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [sort, setSort] = useState('')
  const [roleIds, setRoleIds] = useState('')

  const [deleteModal, setDeleteModal] = useState({ show: false, data: {} })
  const [showUserModal, setShowUserModal] = useState({ show: false, data: {} })
  const [addUserModal, setAddUserModal] = useState(false)

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const [isCompanyFilterVisible, setIsCompanyFilterVisible] = useState(false)

  const [companyFilterIds, setCompanyFilterIds] = useState([])

  const {
    data: usersData,
    isLoading: usersDataLoading,
    isPreviousData,
  } = useQuery(
    ['users', page, size, searchKeyword, sort, roleIds, companyFilterIds],
    () =>
      getUsersRequest({
        companyIds: appendSearchParams(companyFilterIds, 'company_id'),
        roleIds: appendRoleIds(),
        searchKeyword,
        page,
        size,
        sort,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { data: rolesData, isLoading: isRolesLoading } = useQuery('roles', {
    queryFn: () => allRolesRequest(),
    ...STALE_AND_CACHE,
  })

  const { data: skillsData, isLoading: isSkillsLoading } = useQuery('skills', {
    queryFn: () => allSkillsRequest(),
    select: (res) =>
      res.data?.data?.map((skill) => {
        return {
          label: translateSkill(skill?.name),
          value: skill?.id,
        }
      }),
    ...STALE_AND_CACHE,
  })

  const {
    data: companiesData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    'companies',
    ({ pageParam = 1 }) =>
      allCompaniesRequest({
        page: pageParam,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: isSuperUser(),
    }
  )

  useInfiniteQueryInAntdFilter({
    isTargetFilterVisible: isCompanyFilterVisible,
    fetchNextPage,
    hasNextPage,
  })

  const { mutate: deleteUser } = useMutation((id) => deleteUserRequest(id), {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        setDeleteModal({ show: false, data: {} })
        queryClient.refetchQueries('users')
      }
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const updateUserInfo = (user) => {
    dispatch(setUpdateUser(user))
  }

  function handleTableChange(_, filter, sorter) {
    const { roles, company } = filter
    const { field, order } = sorter

    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      let fieldName = ''
      if (field?.includes('company')) {
        fieldName = 'company_id'
      } else {
        fieldName = field
      }
      setSort(`${fieldName},${tempOrd}`)
    }

    if (!order) {
      setSort('')
    }

    setCompanyFilterIds(company)

    setRoleIds(roles)
  }

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyword(keyWord)
  }

  function appendRoleIds() {
    if (isSalesPlus()) {
      return `&role_id[]=${roles.sales}`
    }

    return appendSearchParams(roleIds, 'role_id')
  }

  const rolesFilter = useMemo(() => {
    return rolesData?.data?.map((role) => {
      return {
        text: translateRole(role?.name),
        value: role?.id,
      }
    })
  }, [rolesData])

  const companyFilters = useMemo(() => {
    return companiesData?.pages
      ?.map((page) => {
        return page?.data?.data?.map((company) => {
          return {
            text: company?.name,
            value: company?.id,
          }
        })
      })
      ?.flat()
  }, [companiesData])

  return {
    usersDataLoading:
      usersDataLoading || isSkillsLoading || isRolesLoading || isPreviousData,
    paginationInfo: {
      total: usersData?.data?.meta?.total,
      page,
    },
    skillsForDropDown: skillsData,
    users: usersData?.data?.data,
    setIsCompanyFilterVisible,
    roles: rolesData?.data,
    handleTableChange,
    handlePageChange,
    setShowUserModal,
    setAddUserModal,
    companyFilters,
    setDeleteModal,
    updateUserInfo,
    onSearchClick,
    showUserModal,
    addUserModal,
    rolesFilter,
    deleteModal,
    deleteUser,
    user,
    t,
  }
}
