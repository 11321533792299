import { useTranslation } from 'react-i18next'
import { DocumentDownloadIcon } from './'
import { downloadFile, storageFileUri } from 'src/utils'

function Document({ document }) {
  const { t } = useTranslation()

  const documentDownloadPath =
    document?.name === 'inspection'
      ? storageFileUri('companies', `inspection_${document?.id}.pdf`)
      : storageFileUri(document?.path, document?.name)

  return (
    <div className='document_group'>
      <span>
        {document?.name === 'inspection' ? t(document?.name) : document?.name}
      </span>
      <div
        className='download_group'
        onClick={() => {
          downloadFile(documentDownloadPath, { newTab: true })
        }}
      >
        <span>{t('download')}</span>
        <DocumentDownloadIcon />
      </div>
    </div>
  )
}

export default Document
