import { useTranslation } from 'react-i18next'

const ActionButtonsGroup = ({ resetHandler, cancelHandler, className }) => {
  const { t } = useTranslation()

  return (
    <div className={`action__button__group__container ${className}`}>
      <button className='reset__button' onClick={resetHandler} type='button'>
        {t('reset')}
      </button>
      <div>
        <button
          className='cancel__button'
          onClick={cancelHandler}
          type='button'
        >
          {t('cancel')}
        </button>
        <button className='save__button' type='submit'>
          {t('save')}
        </button>
      </div>
    </div>
  )
}

export default ActionButtonsGroup
