import { useTranslation } from 'react-i18next'
import { userInfoRequest } from 'src/services'
import { getProfilePictureSrc, joinFirstLetters } from 'src/utils'
import { useQuery } from 'react-query'

export const useProfile = () => {
  const { t } = useTranslation()

  const { data: userData, isLoading: isUserDataLoading } = useQuery(
    'user_info',
    userInfoRequest
  )

  const firstLettersOfUsername = joinFirstLetters(userData?.data?.name)

  return {
    profileImageSrc: getProfilePictureSrc(userData?.data),
    userData: userData?.data,
    firstLettersOfUsername,
    isUserDataLoading,
    t,
  }
}
