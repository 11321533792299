import TimeIcon from './TimeIcon'
import TimeOption from './TimeOption'
import { useTimeField } from '../../hooks'

function TimeField({ id, label, placeholder }) {
  const {
    isTimeOptionsOpen,
    time,
    timeArray,
    handleTimeOptions,
    setTimeOnClick,
  } = useTimeField()

  return (
    <div className='project_details_input_group'>
      <label htmlFor={id}>{label}</label>
      <div className='project_details_input' onClick={handleTimeOptions}>
        {isTimeOptionsOpen || time ? (
          <div
            className={`time_placeholder ${
              isTimeOptionsOpen ? 'time_placeholder_active' : ''
            }`}
          >
            {time ? time.label : '-- : --'}
          </div>
        ) : (
          <div>{placeholder}</div>
        )}
        <TimeIcon />
      </div>
      {isTimeOptionsOpen ? (
        <div className='time_options_container'>
          {timeArray?.map((time, index) => {
            return (
              <TimeOption key={index} time={time} setTime={setTimeOnClick} />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default TimeField
