import './less/app.less'

import { Route, Switch } from 'react-router-dom'
import { lazy, Suspense } from 'react'

/* screens */
import LoginScreen from './screens/Login/LoginScreen'
import { MainLayout } from 'src/containers'
import QuotationClone from './screens/QuotationClone/QuotationClone'
import SuspenseLoader from './screens/LeadIframe/SuspenseLoader'

const LeadIframe = lazy(() => import('./screens/LeadIframe/LeadIframe'))

function App() {
  return (
    <>
      <Switch>
        <Route path='/auth/:content' exact>
          <LoginScreen />
        </Route>
        <Route path='/signature' exact>
          <QuotationClone />
        </Route>
        <Route path='/lead-iframe' exact>
          <Suspense fallback={<SuspenseLoader />}>
            <LeadIframe />
          </Suspense>
        </Route>

        <Route path='/'>
          <MainLayout />
        </Route>
      </Switch>
    </>
  )
}

export default App
