import { OPTIONS_TYPE } from '../common'

function getProductDisplayInfo(product) {
  const productDisplayInfo = {}
  if (product['mounting_system']) {
    productDisplayInfo.name = 'mounting_systems'
    productDisplayInfo.type = product['mounting_system']?.name
    productDisplayInfo.quantityCount = product?.quantity
  }
  if (product['power_optimizer']) {
    productDisplayInfo.name = 'power_optimizers'
    productDisplayInfo.type = product['power_optimizer']?.name
    productDisplayInfo.quantityCount = product?.quantity
  }
  if (product['wifi_module']) {
    productDisplayInfo.name = 'wifi_modules'
    productDisplayInfo.type = product['wifi_module']?.name
    productDisplayInfo.quantityCount = product?.quantity
  }
  if (product['converter']) {
    productDisplayInfo.name = 'converters'
    productDisplayInfo.type = product['converter']?.name
    productDisplayInfo.quantityCount = product?.quantity
  }
  if (product['panel']) {
    productDisplayInfo.name = 'panels'
    productDisplayInfo.type = product['panel']?.name
    productDisplayInfo.quantityCount = product?.quantity
  }

  if (product['optionable']) {
    if (product?.optionable_type === OPTIONS_TYPE.activity) {
      productDisplayInfo.name = 'activities'
      productDisplayInfo.quantityCount = product?.hours
    }
    if (product?.optionable_type === OPTIONS_TYPE.material) {
      productDisplayInfo.name = 'materials'
      productDisplayInfo.quantityCount = product?.quantity
    }
    productDisplayInfo.type = product['optionable']?.name
  }

  return productDisplayInfo
}

export { getProductDisplayInfo }
