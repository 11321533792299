import { Button } from 'antd'
import { BiTrash } from 'react-icons/bi'

function ShowCompanyFile({ src, onDelete, text, disabled = false }) {
  return (
    <>
      <a href={src} target='_blank' rel='noopener noreferrer'>
        {text}
      </a>
      <Button
        disabled={disabled}
        onClick={onDelete}
        style={{ marginLeft: '1rem' }}
        type='danger'
      >
        <BiTrash />
      </Button>
    </>
  )
}

export default ShowCompanyFile
