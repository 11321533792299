import { Modal, Tooltip } from 'antd'
import React from 'react'
import EventHoverContent from './EventHoverContent'
import TaskPopup from './TaskPopup'
import { useCalendarEvent } from '../hooks'

function CalendarEvent({ event, children, onEditClick }) {
  const {
    handleTaskInfoTooltipToggle,
    handleTaskInfoTooltipClose,
    isHoverTooltipVisible,
    handHoverTooltipClose,
    handHoverTooltipOpen,
    handleEventMouseDown,
    isPopupModalVisible,
    isTaskTooltipVisible,
    eventRef,
    isSmall,
  } = useCalendarEvent()

  return (
    <>
      <Tooltip
        title={<EventHoverContent event={event} />}
        overlayInnerStyle={{ padding: 0 }}
        placement='rightTop'
        visible={isHoverTooltipVisible}
      >
        <Tooltip
          title={
            <TaskPopup
              event={event}
              onClose={handleTaskInfoTooltipClose}
              onEditClick={onEditClick}
            />
          }
          arrowContent={null}
          trigger='click'
          overlayClassName='event-tooltip'
          visible={isTaskTooltipVisible}
        >
          <div
            ref={eventRef}
            onMouseDown={handleEventMouseDown}
            onClick={handleTaskInfoTooltipToggle}
            onMouseEnter={handHoverTooltipOpen}
            onMouseLeave={handHoverTooltipClose}
          >
            {children}
          </div>
        </Tooltip>
      </Tooltip>
      {isPopupModalVisible ? (
        <Modal
          visible={isPopupModalVisible}
          mask={false}
          width={444}
          style={isSmall && { top: 0, margin: 0 }}
          footer={null}
          closeIcon={null}
          onCancel={handleTaskInfoTooltipClose}
          title={null}
          closable={false}
          className='task-popup-modal'
        >
          <TaskPopup
            event={event}
            onClose={handleTaskInfoTooltipClose}
            onEditClick={onEditClick}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default CalendarEvent
