import { inputIconColor, inputStyles } from 'src/utils'
import { AiOutlineMail } from 'react-icons/ai'
import { ShowFieldError } from 'src/common'
import { useForgotPassword } from './hooks'
import { Form, Input, Button } from 'antd'
import { Link } from 'react-router-dom'

const ForgotPasswordContent = () => {
  const { handleSendEmail, isLoading, data, t, emailFieldError } =
    useForgotPassword()

  return (
    <>
      <div className='logincard__title'>
        {' '}
        {t('forgot_password', { question_mark: '?' })}
      </div>
      <div className='logincard__subtitle'>{t('reset_password_text')}</div>

      {!data ? (
        <Form onFinish={handleSendEmail} className='logincard__form'>
          <Form.Item>
            <Form.Item name='email' noStyle>
              <Input
                style={inputStyles(emailFieldError)}
                name='email'
                placeholder={t('enter_mail')}
                prefix={
                  <AiOutlineMail
                    color={inputIconColor(emailFieldError)}
                    size={20}
                  />
                }
              />
            </Form.Item>
            <ShowFieldError error={emailFieldError} />
          </Form.Item>

          <Button
            className='logincard__form__submit__button'
            disabled={isLoading}
            htmlType='submit'
            type='primary'
          >
            {t('send')}
          </Button>

          <Link to='/auth/login' className='logincard__forgotpassword'>
            {t('log_in')}
          </Link>
        </Form>
      ) : (
        <p className='password-reset-success'>{`${data.data?.message} Please check your email`}</p>
      )}
    </>
  )
}

export default ForgotPasswordContent
