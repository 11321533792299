function joinPanels(panels) {
  if (!panels) return
  let temp = new Map()

  panels?.forEach((item) => {
    let panelItem = temp.get(item?.panel?.id)
    if (panelItem) {
      panelItem.quantity += item.quantity
    } else {
      let copiedPanel = {
        ...item,
        panel: {
          ...item?.panel,
        },
      }
      temp.set(item?.panel?.id, copiedPanel)
    }
  })
  return [...temp.values()]
}

export { joinPanels }
