import AddWifiModuleModal from './components/AddWifiModuleModal'
import { canPerform, getTableSortValue } from 'src/utils'
import ShowWifiModule from './components/ShowWifiModule'
import WifiModuleColumns from './WifiModuleColumns'
import { useWifiModules } from './useWifiModules'
import { Table, Space } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Header,
  Search,
} from 'src/common'

const WifiModule = () => {
  const {
    showMultipleDeleteConfirmation,
    showMultipleDeleteModalHandler,
    setShowAddWifiModuleModal,
    isWifiModulesDataLoading,
    showAddWifiModuleModal,
    setShowImportCsvModal,
    selectedWifiModuleIds,
    deletingMultipleItem,
    deleteSelectedItems,
    setDeleteWifiModule,
    showImportCsvModal,
    setShowWifiModule,
    handlePageChange,
    deleteWifiModule,
    DeleteWifiModule,
    showWifiModule,
    exportDisabled,
    paginationInfo,
    setImportError,
    onSearchClick,
    rowSelection,
    importError,
    wifiModules,
    exportWifi,
    importWifi,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  } = useWifiModules()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('wifi_modules')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Space>
            <Search
              onSearchClick={onSearchClick}
              disabled={isWifiModulesDataLoading}
              className='contentheader__search'
              placeholder={t('search_word')}
            />
            <ButtonWithPlus
              disabled={
                canPerform('manage wifi-module') ||
                canPerform('manage own wifi-module')
                  ? false
                  : true
              }
              text={t('add')}
              onClick={() => setShowAddWifiModuleModal(true)}
            />
            <ButtonWithTrash
              onClick={showMultipleDeleteModalHandler}
              disabled={!selectedWifiModuleIds.length}
              text={t('delete_selected_item')}
            />
          </Space>
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          columns={WifiModuleColumns(setDeleteWifiModule, t)}
          dataSource={wifiModules || []}
          onRow={onRow}
          rowKey={(record) => {
            return record?.id
          }}
          loading={isWifiModulesDataLoading}
          bordered
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
        />

        <div className='contentFooter'>
          <CsvButton
            onClick={exportWifi}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage wifi-module') ||
              canPerform('manage own wifi-module')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showAddWifiModuleModal && (
        <AddWifiModuleModal
          setShowAddWifiModuleModal={setShowAddWifiModuleModal}
          showAddWifiModuleModal={showAddWifiModuleModal}
        />
      )}

      {showWifiModule.show && (
        <ShowWifiModule
          currentWifiModule={showWifiModule.data}
          setShowWifiModule={setShowWifiModule}
          show={showWifiModule.show}
        />
      )}

      {showImportCsvModal && (
        <ImportCvsModal
          closeModal={() => setShowImportCsvModal(false)}
          setImportError={setImportError}
          show={showImportCsvModal}
          importError={importError}
          submitModal={importWifi}
          disabled={importing}
        />
      )}

      {deleteWifiModule.show && (
        <AgreeDeleteModal
          visible={deleteWifiModule.show}
          targetName={deleteWifiModule?.data?.name}
          onCancel={() => setDeleteWifiModule({ show: false, data: {} })}
          disabled={deleting}
          onSubmit={() =>
            deleteWifiModule.data.id &&
            DeleteWifiModule(deleteWifiModule.data.id)
          }
        />
      )}
      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          disabled={deletingMultipleItem}
          onSubmit={deleteSelectedItems}
        />
      )}
    </div>
  )
}

export default WifiModule
