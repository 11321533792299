import { ProjectCountDetails } from '../ProjectCountDetails'
import { OpenProjectsTable } from './components'
import { TicketView } from '../TicketView'

const OpenProjects = ({
  handleOpenTableChange,
  isProjectLoading,
  handlePageChange,
  paginationInfo,
  archiveProject,
  setTicketView,
  deleteProject,
  ticketView,
  project,
  onRow,
}) => {
  return (
    <>
      <ProjectCountDetails
        totalProjectsCount={paginationInfo?.total}
        currentProjectsCount={project?.length}
        setTicketView={setTicketView}
        ticketView={ticketView}
      />

      {ticketView ? (
        <TicketView
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
          archiveProject={archiveProject}
          deleteProject={deleteProject}
          projects={project}
        />
      ) : (
        <OpenProjectsTable
          className='tablet__table__visibility'
          handlePageChange={handlePageChange}
          isProjectLoading={isProjectLoading}
          paginationInfo={paginationInfo}
          handleOpenTableChange={handleOpenTableChange}
          archiveProject={archiveProject}
          deleteProject={deleteProject}
          project={project}
          onRow={onRow}
        />
      )}

      <OpenProjectsTable
        handleOpenTableChange={handleOpenTableChange}
        isProjectLoading={isProjectLoading}
        handlePageChange={handlePageChange}
        paginationInfo={paginationInfo}
        archiveProject={archiveProject}
        deleteProject={deleteProject}
        project={project}
        onRow={onRow}
      />
    </>
  )
}

export default OpenProjects
