import { useEnabledFeatures, useQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { view } from '../data'

function useHandleView() {
  const { isSolarPlanningEnabled } = useEnabledFeatures()

  const { t } = useTranslation()

  const query = useQueryParams()

  function isCalendarActive() {
    return query.get('view') === view.CALENDAR
  }

  function isTasksActive() {
    return query.get('view') === view.TASkS
  }

  function isProjectListActive() {
    return !query.get('view')
  }

  return {
    isProjectListActive: isProjectListActive(),
    isCalendarActive: isCalendarActive(),
    isTasksActive: isTasksActive(),
    isSolarPlanningEnabled,
    t,
  }
}

export { useHandleView }
