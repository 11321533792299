import ActivitiesTableColumn from '../../ActivitiesTables/ActivitiesTableColumn'
import { canPerform, getTableSortValue } from 'src/utils'
import ShowActivityModal from './ShowActivityModal'
import AddActivityModal from './AddActivityModal'
import { useActivities } from './hooks'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Search,
} from 'src/common'

const Activities = () => {
  const {
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowAddActivitiesModal,
    isActivitiesDataLoading,
    showAddActivitiesModal,
    selectedActivitiesIds,
    setShowActivityModal,
    deletingMultipleItem,
    setDeleteActivities,
    deleteSelectedItems,
    showActivityModal,
    deleteActivities,
    exportActivities,
    importActivities,
    handlePageChange,
    setShowCSVModal,
    paginationInfo,
    setImportError,
    deleteActivity,
    onSearchClick,
    rowSelection,
    showCSVModal,
    importError,
    activities,
    importing,
    exporting,
    deleting,
    setSort,
    onRow,
    t,
  } = useActivities()

  return (
    <>
      <div className='manageScreensLayout__title'>{t('activities')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isActivitiesDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />

          <ButtonWithPlus
            disabled={
              canPerform('manage activity') || canPerform('manage own activity')
                ? false
                : true
            }
            text={t('add_activity')}
            onClick={() => setShowAddActivitiesModal(true)}
          />
          <ButtonWithTrash
            onClick={showMultipleDeleteModalHandler}
            disabled={!selectedActivitiesIds.length}
            text={t('delete_selected_item')}
          />
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          dataSource={activities || []}
          columns={ActivitiesTableColumn(setDeleteActivities, t)}
          loading={isActivitiesDataLoading}
          onRow={onRow}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
          bordered
        />
        <div className='contentFooter contentFooter_activitiesTable'>
          <CsvButton
            onClick={exportActivities}
            disabled={exporting || !activities?.length}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage activity') || canPerform('manage own activity')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowCSVModal(true)}
          />
        </div>
      </div>

      {showCSVModal && (
        <ImportCvsModal
          show={showCSVModal}
          closeModal={() => {
            setShowCSVModal(false)
          }}
          submitModal={importActivities}
          disabled={importing}
          importError={importError}
          setImportError={setImportError}
        />
      )}

      {deleteActivities.show && (
        <AgreeDeleteModal
          visible={deleteActivities.show}
          targetName={deleteActivities?.data?.name}
          onCancel={() => setDeleteActivities({ show: false, data: {} })}
          onSubmit={() =>
            deleteActivities.data.id && deleteActivity(deleteActivities.data.id)
          }
          disabled={deleting}
        />
      )}

      {showAddActivitiesModal && (
        <AddActivityModal
          setShowAddActivitiesModal={setShowAddActivitiesModal}
          show={showAddActivitiesModal}
        />
      )}

      {showActivityModal.show && (
        <ShowActivityModal
          setShowActivityModal={setShowActivityModal}
          currentActivity={showActivityModal.data}
          show={showActivityModal.show}
        />
      )}

      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          disabled={deletingMultipleItem}
          onSubmit={deleteSelectedItems}
        />
      )}
    </>
  )
}

export default Activities
