import { useTranslation } from 'react-i18next'
import { useClearHomePageQuery, useHandleQueryParams } from 'src/hooks'

function useTags() {
  const { clearPageQuery } = useClearHomePageQuery()
  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()
  const { t } = useTranslation()

  function manageFilterParams(value, name) {
    if (value?.length) {
      appendQueryParams({
        [name]: JSON.stringify(value),
      })
    } else {
      deleteQueryParams(name)
    }
  }

  return {
    manageFilterParams,
    clearPageQuery,
    t,
  }
}

export { useTags }
