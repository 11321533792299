import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

export const useRenderOptions = ({
  materials,
  attaChedMaterials,
  options,
  attaChedActivities,
}) => {
  const { t } = useTranslation()

  const attachedTypes = useMemo(() => {
    if (materials) {
      return attaChedMaterials?.filter(
        (attachedOption) =>
          attachedOption?.optionable?.type === options[0]?.type
      )
    } else {
      return attaChedActivities?.filter(
        (attachedOption) =>
          attachedOption?.optionable?.type_id === options[0]?.type_id
      )
    }
  }, [attaChedActivities, attaChedMaterials, options, materials])

  const attaChedMaterialOptions = (option) => {
    return attaChedMaterials?.find(
      (activity) => activity?.optionable?.id === option?.id
    )
  }

  const attaChedActivitiesOptions = (option) => {
    return attaChedActivities?.find(
      (activity) => activity?.optionable?.id === option?.id
    )
  }

  return {
    attaChedActivitiesOptions,
    attaChedMaterialOptions,
    attachedTypes,
    t,
  }
}
