import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import parse from 'date-fns/parse'

function extractDateAndTime(dateTimeString) {
  if (!dateTimeString) return

  return {
    date: format(new Date(dateTimeString), 'dd.MM.yyyy'),
    time: format(new Date(dateTimeString), 'HH:mm'),
  }
}

function disablePastDate(current) {
  if (!current) return
  const tempDate = new Date()
  tempDate.setDate(tempDate.getDate() - 1)
  return isBefore(current, tempDate)
}

function parseDateString(date) {
  if (!date) return
  if (typeof date !== 'string') return date
  return parse(date, 'yyyy-MM-dd', new Date())
}

function formatToYMD(date) {
  if (!date) return
  return format(date, 'yyyy-MM-dd')
}

export { extractDateAndTime, disablePastDate, parseDateString, formatToYMD }
