import { useTableSettings } from './useTableSettings'
import { BsThreeDots } from 'react-icons/bs'
import { Dropdown, Button } from 'antd'

const TableSettings = ({ onDelete, onArchive, isArchived, placement }) => {
  const { menu, clickHandler } = useTableSettings(
    onDelete,
    onArchive,
    isArchived
  )

  return (
    <Dropdown
      placement={placement || 'bottomRight'}
      trigger={['click']}
      overlay={menu}
    >
      <Button className='homescreen__actions' onClick={clickHandler}>
        <BsThreeDots className='homescreen__actions-icon' />
      </Button>
    </Dropdown>
  )
}

export default TableSettings
