import React from 'react'
import { DatePicker } from '../DatePicker'

const TimePicker = React.forwardRef((props, ref) => {
  return <DatePicker.RangePicker {...props} mode={undefined} ref={ref} />
})

TimePicker.displayName = 'TimePicker'

export { TimePicker }
