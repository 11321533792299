import { ButtonWithTrash } from 'src/common'
import { canPerform } from 'src/utils'
import { renderAmount } from 'src/utils'

const ConvertersTableColumns = (setDeleteModal, t) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
      render: (brand) => <>{brand?.name}</>,
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('type'),
      dataIndex: 'type',
    },
    {
      title: t('max_dc'),
      dataIndex: 'max_dc',
    },
    {
      title: t('max_ac'),
      dataIndex: 'max_ac',
    },
    {
      title: t('boot_power'),
      dataIndex: 'boot_power',
    },
    {
      title: t('phases'),
      dataIndex: 'phases',
    },
    {
      title: t('amp'),
      dataIndex: 'amp',
    },
    {
      title: t('length'),
      dataIndex: 'length',
    },
    {
      title: t('purchase_price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          disabled={
            canPerform('delete converter') || canPerform('delete own converter')
              ? false
              : true
          }
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default ConvertersTableColumns
