import { formItemErrorClasses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import { Select, Form } from 'antd'

const SelectItem = ({
  additionalConfiguration = {},
  onPopupScroll = () => {},
  dropdownRender = null,
  onChange = () => {},
  defaultValue = '',
  required = true,
  children,
  label,
  error,
  form,
  name,
}) => {
  return (
    <Form.Item
      className={`${formItemErrorClasses(error)} ${
        error && 'ant-selector-error'
      } custom-antd-select-item ${name}`}
      required={required}
      label={label}
    >
      <Form.Item name={name}>
        <Select
          defaultValue={defaultValue}
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={(id) => {
            form.setFieldsValue({
              [name]: id,
            })
            onChange(id)
          }}
          dropdownRender={dropdownRender}
          onPopupScroll={onPopupScroll}
          error={error}
          name={name}
          {...additionalConfiguration}
        >
          {children}
        </Select>
        <ShowFieldError withHeight error={error} />
      </Form.Item>
    </Form.Item>
  )
}

export default SelectItem
