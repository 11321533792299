import i18next from 'i18next'

export const financialFirstTable = (t) => [
  {
    title: t('pending'),
    dataIndex: 'pending',
  },
  {
    title: t('agreement'),
    dataIndex: 'agreement',
  },
  {
    title: t('lost'),
    dataIndex: 'lost',
  },
  {
    title: t('total'),
    dataIndex: 'Total',
  },
]

const financialSecondTableMutualStyle = {
  maxWidth: '167px',
  width: '167px',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowX: 'auto',
}

export const financialSecondTable = (t) => [
  {
    title: t('account_manager'),
    dataIndex: 'user',
    render: (val) => {
      return <div style={financialSecondTableMutualStyle}>{val}</div>
    },

    width: '200px',
  },
  {
    title: () => t('margin_in_euro'),
    dataIndex: 'margin_euro',
    key: 'margin_euro',

    render: (val) => {
      return <div style={financialSecondTableMutualStyle}>{val}</div>
    },
    width: '200px',
  },
  {
    title: () => t('margin_in_percentage'),
    dataIndex: 'margin_percent',
    key: 'margin_percent',
    render: (val) => {
      return <div style={financialSecondTableMutualStyle}>{`${val}%`}</div>
    },
    width: '200px',
  },

  {
    title: () => t('revenue'),
    dataIndex: 'price_succeeded',
    render: (val) => {
      return <div style={financialSecondTableMutualStyle}>{val}</div>
    },
    width: '200px',
  },
  {
    title: t('number_of_projects'),
    dataIndex: 'number_of_projects',
  },
  {
    title: t('amount_succeeded'),
    dataIndex: 'amount_succeeded',
  },

  {
    title: t('percentage_succeeded_quotations'),
    dataIndex: 'percentage_succeeded',
    render: (val) => `${val}%`,
  },
  {
    title: t('total'),
    dataIndex: 'total',
    render: (val) => val,
  },
]

export const expandedTableColumns = [
  {
    dataIndex: ['project', 'reference'],
    key: 'reference',
    width: '200px',
  },
  {
    title: () => i18next.t('margin_in_euro'),

    dataIndex: 'margin_euro',
    key: 'margin_euro',
    render: (val) => val,
    width: '200px',
  },
  {
    title: () => i18next.t('margin_in_percentage'),
    dataIndex: 'margin_percent',
    key: 'margin_percent',
    render: (val) => `${val}%`,
    width: '200px',
  },

  {
    title: () => i18next.t('revenue'),

    dataIndex: 'revenue',
    key: 'revenue',
    render: (val) => val,
  },
]
