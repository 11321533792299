import { UploadDeliveredGoods, SignatureDoubleBox } from 'src/common'
import TableDelivery from './components/TableDelivery/TableDelivery'
import { useDeliveryWizard, useDeliverySignature } from './hooks'
import { canManageProject, joinPanels } from 'src/utils'
import { Button, Typography } from 'antd'

const Delivery = ({
  converters = [],
  panels = [],
  options = [],
  mountingSystems = [],
  wifiModules = [],
  powerOptimizers = [],
  deliverySignatures,
  files = [],
  offerId,
}) => {
  const {
    headquartersSignature,
    setHeadquartersSignature,
    isHeadquartersSignatureModalOpen,
    toggleHeadquartersSignatureModal,
    customerSignature,
    setCustomerSignature,
    isCustomerSignatureModalOpen,
    toggleCustomerSignatureModal,
  } = useDeliverySignature({
    headquartersSignatureSrc: deliverySignatures?.delHeadSignature,
    customerSignatureSrc: deliverySignatures?.delCustomerSignature,
  })

  const {
    handleDeliveryGoodsChange,
    handleDeliveryGoodsRemove,
    setIsMechanicAutoSigned,
    userSignatureData,
    validationError,
    defaultFiles,
    saveDelivery,
    activities,
    materials,
    saving,
    t,
  } = useDeliveryWizard({
    options,
    customerSignature,
    headquartersSignature,

    headquartersSignatureSrc: deliverySignatures?.delHeadSignature,
    customerSignatureSrc: deliverySignatures?.delCustomerSignature,
    files,
    offerId,
  })

  return (
    <div className='delivery-section'>
      <div className='delivery'>
        <Typography.Title level={3} className='capitalize_text'>
          {t('delivery')}
        </Typography.Title>

        <div className='delivery__content'>
          {panels?.length ? (
            <TableDelivery
              data={joinPanels(panels)}
              title={t('panels')}
              product='panel'
            />
          ) : null}
          {converters?.length ? (
            <TableDelivery
              data={converters}
              title={t('converters')}
              product='converter'
            />
          ) : null}
          {powerOptimizers?.length ? (
            <TableDelivery
              data={powerOptimizers}
              title={t('power_optimizers')}
              product='power_optimizer'
            />
          ) : null}
          {mountingSystems?.length ? (
            <TableDelivery
              data={mountingSystems}
              title={t('mounting_systems')}
              product='mounting_system'
            />
          ) : null}
          {wifiModules?.length ? (
            <TableDelivery
              data={wifiModules}
              title={t('wifi_modules')}
              product='wifi_module'
            />
          ) : null}
          {materials?.length ? (
            <TableDelivery
              data={materials}
              title={t('materials')}
              product='optionable'
            />
          ) : null}
          {activities?.length ? (
            <TableDelivery
              data={activities}
              title={t('activities')}
              product='optionable'
            />
          ) : null}
          <UploadDeliveredGoods
            handleRemove={handleDeliveryGoodsRemove}
            handleFileDrag={handleDeliveryGoodsChange}
            defaultFiles={defaultFiles}
            error={validationError?.file || validationError?.del_file_ids}
          />
          <SignatureDoubleBox
            isHeadquartersSignatureModalOpen={isHeadquartersSignatureModalOpen}
            toggleHeadquartersSignatureModal={toggleHeadquartersSignatureModal}
            disableHeadSignatureDelete={deliverySignatures?.delHeadSignature}
            isCustomerSignatureModalOpen={isCustomerSignatureModalOpen}
            toggleCustomerSignatureModal={toggleCustomerSignatureModal}
            setHeadquartersSignature={setHeadquartersSignature}
            headquartersSignature={headquartersSignature}
            setCustomerSignature={setCustomerSignature}
            setAutoSigned={setIsMechanicAutoSigned}
            customerSignature={customerSignature}
            userSignatureData={userSignatureData}
            dates={{
              headquarters: deliverySignatures?.delHeadSignatureDate,
              customer: deliverySignatures.delCustomerSignatureDate,
            }}
            title={t('mechanic')}
            disableCustomerSignatureDelete={
              deliverySignatures?.delCustomerSignature
            }
          />

          <div className='delivery__footer'>
            <Button
              type='primary'
              onClick={saveDelivery}
              className='nextButton'
              disabled={saving || !canManageProject()}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Delivery
