import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'

function useAllInViewModal() {
  const { lg, sm } = useBreakpoint()

  const additionalConfiguration = {
    width: lg ? '85%' : sm ? '90%' : '70%',
  }

  const { t } = useTranslation()

  return { t, additionalConfiguration }
}

export { useAllInViewModal }
