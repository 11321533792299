import { useMemo } from 'react'
import { taskViewEnum } from '../data'
import { useTranslation } from 'react-i18next'

function useTaskRadioSwitcher({
  handleCalendarViewClick,
  handleTasksViewClick,
  isCalendarActive,
  isTasksActive,
}) {
  const { t } = useTranslation()

  const radioValue = useMemo(() => {
    if (isCalendarActive) return taskViewEnum.calendar
    if (isTasksActive) return taskViewEnum.list
    return null
  }, [isTasksActive, isCalendarActive])

  function handleRadioChange(e) {
    const value = e?.target?.value
    if (!value) return null
    if (value === taskViewEnum.calendar) {
      return handleCalendarViewClick()
    }
    if (value === taskViewEnum.list) {
      return handleTasksViewClick()
    }
  }

  return {
    handleRadioChange,
    radioValue,
    t,
  }
}

export { useTaskRadioSwitcher }
