import MountingSystemForm from './MountingSystemForm'
import { useShowMountingSystem } from './hooks'

const ShowMountingSystem = ({
  setShowMountingSystemModal,
  currentMountingSystem,
  show,
}) => {
  const {
    updateMountingSystemError,
    updateMountingSystem,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowMountingSystem(currentMountingSystem, setShowMountingSystemModal)

  return (
    <MountingSystemForm
      currentMountingSystem={currentMountingSystem}
      errors={updateMountingSystemError}
      actionButtonTitle={t('update')}
      onFinish={updateMountingSystem}
      closeHandler={closeModal}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}

export default ShowMountingSystem
