import { useCustomUpload } from './useCustomUpload'
import { Modal, Upload } from 'antd'

const CustomUpload = ({ children, isDragger, ...args }) => {
  const {
    handleImagePreview,
    cancelHandler,
    imgStyles,
    Dragger,
    preview,
    file,
  } = useCustomUpload()

  return (
    <>
      {isDragger ? (
        <Dragger onPreview={handleImagePreview} {...args}>
          {children}
        </Dragger>
      ) : (
        <Upload onPreview={handleImagePreview} {...args}>
          {children}
        </Upload>
      )}
      <Modal
        className='custom-upload'
        width='auto'
        visible={preview}
        title={file?.name}
        footer={null}
        centered='true'
        onCancel={cancelHandler}
      >
        <img alt={file?.name} style={imgStyles} src={file?.url} />
      </Modal>
    </>
  )
}

export default CustomUpload
