import ProjectCreationForm from './ProjectCreationForm'
import { useProjectCreation } from './hooks'
import { Form, Spin } from 'antd'

const ProjectCreation = () => {
  const {
    projectCreationFormInitialValues,
    getAddressOnInitialRender,
    projectCondition,
    projectCompanyId,
    mutationErrors,
    submitHandler,
    disableSubmit,
    isLoading,
    project,
    form,
  } = useProjectCreation()

  if (isLoading) {
    return <Spin style={{ marginTop: '5rem' }} />
  }

  return (
    <div className='project__creation'>
      <Form
        initialValues={projectCreationFormInitialValues}
        onFinish={submitHandler}
        form={form}
      >
        <ProjectCreationForm
          getAddressOnInitialRender={getAddressOnInitialRender}
          projectCompanyId={projectCompanyId}
          projectCondition={projectCondition}
          mutationErrors={mutationErrors}
          disableSubmit={disableSubmit}
          project={project}
          form={form}
        />
      </Form>
    </div>
  )
}

export default ProjectCreation
