import React from 'react'

function TimeIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M10 4.66667V8H12.5M15 8C15 10.7614 12.7614 13 10 13C7.23858 13 5 10.7614 5 8C5 5.23858 7.23858 3 10 3C12.7614 3 15 5.23858 15 8Z'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { TimeIcon }
