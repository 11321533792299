function SideBarOPen() {
  return (
    <svg
      width='10'
      height='16'
      viewBox='0 0 10 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 14.667L8.16667 8.00033L1.5 1.33366'
        stroke='#0F305D'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { SideBarOPen }
