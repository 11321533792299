import { renderAmount, canPerform } from 'src/utils'
import { ButtonWithTrash } from 'src/common'
import { Result } from 'antd'

function getResultIcon(status) {
  if (status === 'success') return 'success'

  if (status === 'pending') return 'info'

  if (status === 'failed') return 'error'

  return null
}

const PanelsTableColumns = (t, setDeleteModal) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('synced_in_allinview'),
      dataIndex: 'sync_status',
      render: (status) => {
        if (!status) return null

        return (
          <Result
            className='synced-result'
            status={getResultIcon(status)}
            subTitle={t(status)}
          />
        )
      },
    },

    {
      title: t('name'),
      dataIndex: 'name',
    },

    {
      title: t('type'),
      dataIndex: 'type',
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
      render: (brand) => <>{brand?.name}</>,
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('watt'),
      dataIndex: 'watt',
    },
    {
      title: t('voc'),
      dataIndex: 'voc',
    },
    {
      title: t('weight'),
      dataIndex: 'weight',
    },
    {
      title: t('width'),
      dataIndex: 'width',
    },
    {
      title: t('lmp'),
      dataIndex: 'lmp',
    },
    {
      title: t('length'),
      dataIndex: 'length',
    },

    {
      title: t('depth'),
      dataIndex: 'depth',
    },
    {
      title: t('warranty'),
      dataIndex: 'warranty',
      render: (val) => `${val} ${t('year')}`,
    },

    {
      title: t('purchase_price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },

    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          disabled={
            canPerform('delete panel') || canPerform('delete own panel')
              ? false
              : true
          }
          text={t('delete')}
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default PanelsTableColumns
