import { Result } from 'antd'

const PageNotFound = ({ text }) => {
  return (
    <div className='pageNotFoundWrapper'>
      <Result status='404' title='404' subTitle={text} />
    </div>
  )
}

export default PageNotFound
