import { Spinner } from 'src/common'
import { useSuspenseLoader } from './hooks'

function SuspenseLoader() {
  const { show } = useSuspenseLoader()

  return show ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5rem',
      }}
    >
      <Spinner />
    </div>
  ) : null
}

export default SuspenseLoader
