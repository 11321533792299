import useLinkWithQueryParam from './useLinkWithQueryParam'
import { Link } from 'react-router-dom'

const LinkWithQueryParam = ({
  onClick = () => {},
  pathname = null,
  queryParamValue,
  queryParamName,
  className = '',
  replace = false,
  children,
}) => {
  const { location, newSearch } = useLinkWithQueryParam(
    queryParamName,
    queryParamValue
  )

  return (
    <Link
      onClick={onClick}
      className={className}
      to={{
        pathname: pathname || location.pathname,
        search: newSearch,
      }}
      replace={replace}
    >
      {children}
    </Link>
  )
}

export default LinkWithQueryParam
