import { companyFormConstant } from '../../constants/companyFormConstant'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useFileUpload, useEnabledFeatures } from 'src/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, setUpdateUser } from 'src/actions'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tabError } from './tabError/tabError'
import { changeLanguage } from 'i18next'
import { Form, message } from 'antd'
import {
  handleNonValidationError,
  handleValidationError,
  getCompanyPdfFileUrl,
  isSuperUser,
  getLogoUrl,
  isAdmin,
  appendDutchCode,
} from 'src/utils'
import {
  updateCompanyRequest,
  loginAsTenantRequest,
  addCompanyRequest,
} from 'src/services'

export const useCompanyModal = (setShowCompanyModal, showCompanyModal) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const [initialFormValues, setInitialFormValues] = useState({})

  const [categoryError, setCategoryError] = useState(false)

  const { uploadFile, uploadError, isUploading } = useFileUpload()

  const [termOfConditionsFile, setTermOfConditionsFile] = useState(null)

  const [logoFile, setLogoFile] = useState(null)

  const [privacyStatementFile, setPrivacyStatementFile] = useState(null)

  const [privacyStatementSrc, setPrivacyStatementSrc] = useState(
    showCompanyModal?.data?.privacy_statement &&
      getCompanyPdfFileUrl(showCompanyModal?.data, 'privacy_statement')
  )

  const [termsOfConditionSrc, setTermsOfConditionSrc] = useState(
    showCompanyModal?.data?.terms_of_conditions &&
      getCompanyPdfFileUrl(showCompanyModal?.data, 'terms_of_conditions')
  )

  const { isNewLeadsEnabled } = useEnabledFeatures()

  const {
    isLoading: creating,
    mutate: createCompany,
    error: createError,
  } = useMutation((data) => !creating && addCompanyRequest(data), {
    onSuccess: (resp) => {
      if (resp.data.success) {
        queryClient.invalidateQueries('companies')
        message.success(t('saved_successfully'))
        closeModal()
      }
    },
    onError: (error) => {
      handleNonValidationError(error)

      setCategoryError(tabError(handleValidationError(error)))
    },
  })

  const {
    isLoading: updating,
    mutate: updateCompany,
    error: updateError,
  } = useMutation(
    ({ id, data }) => !updating && updateCompanyRequest(id, data),
    {
      onSuccess: invalidateAfterUpdating,

      onError: (error) => {
        handleNonValidationError(error)
        setCategoryError(tabError(handleValidationError(error)))
      },
    }
  )

  const { isLoading: tennantlogining, mutate: loginAsTenant } = useMutation(
    ({ id }) => !tennantlogining && loginAsTenantRequest(id),
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          dispatch(setUser(resp.data))
          setShowCompanyModal({ show: false, data: null, type: '' })
          queryClient.invalidateQueries()
          history.push('/')
          changeLanguage(resp.data?.user?.lang)
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const closeModal = () => {
    setShowCompanyModal({ show: false, data: null, type: '' })
  }

  useEffect(() => {
    if (showCompanyModal?.type === 'update') {
      const updateData = {
        ...companyFormConstant,
        ...showCompanyModal?.data,
        phone_number: appendDutchCode(showCompanyModal?.data?.phone_number),
      }
      setInitialFormValues(updateData)
      form.setFieldsValue(updateData)
    } else {
      setInitialFormValues({ ...companyFormConstant })
    }
  }, [form, showCompanyModal?.data, showCompanyModal?.type])

  const finishHandler = async (values) => {
    try {
      const {
        country,
        logo,
        created_at,
        updated_at,
        privacy_statement,
        terms_of_conditions,
        logo_src,
        ...rest
      } = values || {}

      const [companyId, privacyStatementId, termsOfConditionsId] =
        await Promise.all([
          uploadCompanyFile(logoFile || logo, 'logo', 'logo'),
          uploadCompanyFile(
            privacyStatementFile || privacy_statement,
            'privacy-statement',
            'privacy_statement'
          ),
          uploadCompanyFile(
            termOfConditionsFile || terms_of_conditions,
            'terms-of-conditions',
            'terms_of_conditions'
          ),
        ])

      rest['company_logo_id'] = companyId

      rest['privacy_statement_id'] = privacyStatementId

      rest['terms_of_conditions_id'] = termsOfConditionsId

      if (!Object.keys(showCompanyModal?.data)?.length) {
        createCompany(rest)
      } else {
        updateCompany({ id: showCompanyModal?.data?.id, data: rest })
      }
    } catch (error) {
      return
    }
  }

  const clickHandler = () => {
    if (isAdmin()) return
    loginAsTenant({
      id: showCompanyModal?.data?.id,
    })
  }

  function invalidateAfterUpdating(resp, variables) {
    queryClient.invalidateQueries('companies')
    message.success(t('updated_successfully'))
    closeModal()
    if (user?.company_id === variables?.id) {
      const { id, logo } = resp?.data?.data

      const tempUser = {
        ...user,
        company: {
          id,
          logo,
        },
      }

      dispatch(setUpdateUser(tempUser))
    }
  }

  async function uploadCompanyFile(fileInfo, url, fieldName) {
    if (fileInfo?.id) {
      return fileInfo?.id
    }
    if (fileInfo?.fileList?.length > 0) {
      try {
        let response = await uploadFile({
          url: `company/${url}`,
          data: {
            file: fileInfo?.file,
          },
        })

        return response?.data?.id
      } catch (error) {
        form.setFields([
          {
            name: fieldName,
            errors: [error?.response?.data?.errors?.file?.[0]],
          },
        ])

        return error
      }
    }

    return null
  }

  const getLoadingState = () => updating || creating || isUploading

  function getErrorState() {
    if (getLoadingState()) return false
    const formError =
      handleValidationError(createError) || handleValidationError(updateError)
    const fileError = handleValidationError(uploadError)

    return {
      ...formError,
      logo: fileError?.logo,
      privacy_statement: fileError?.privacy_statement,
      terms_of_conditions: fileError?.terms_of_conditions,
    }
  }

  const iframeApiToken = useMemo(() => {
    return showCompanyModal?.data?.iframe_token
  }, [showCompanyModal?.data?.iframe_token])

  const apiToken = useMemo(() => {
    return showCompanyModal?.data?.api_token
  }, [showCompanyModal?.data?.api_token])

  return {
    termsOfConditionSrc,
    privacyStatementSrc,
    companyRequestError: getErrorState(),
    isLoading: getLoadingState(),
    logo: getLogoUrl(showCompanyModal?.data, {
      defaultImage: false,
    }),
    setTermOfConditionsFile,
    setPrivacyStatementFile,
    setPrivacyStatementSrc,
    setTermsOfConditionSrc,
    termOfConditionsFile,
    isNewLeadsEnabled,
    initialFormValues,
    tennantlogining,
    iframeApiToken,
    categoryError,
    finishHandler,
    clickHandler,
    isSuperUser,
    setLogoFile,
    closeModal,
    apiToken,
    form,
    t,
  }
}
