import { useState } from 'react'

function useHandleContainers() {
  const [isHistoryContentOpen, setIsHistoryContentOpen] = useState(false)
  const [isNotesContentOpen, setNotesContentOpen] = useState(false)

  const handleToggleHistoryContent = () => {
    setIsHistoryContentOpen(!isHistoryContentOpen)
  }

  const handleToggleNotesContent = () => {
    setNotesContentOpen(!isNotesContentOpen)
  }

  return {
    handleToggleHistoryContent,
    handleToggleNotesContent,
    isHistoryContentOpen,
    isNotesContentOpen,
  }
}

export { useHandleContainers }
