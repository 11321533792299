import useConfiguration from './useConfiguration'
import { SwitchButton } from 'src/common'
import { Button } from 'antd'

const Configuration = ({ companyData, form, show }) => {
  const { t, isLoginningAsTennant, loginAsTenantMutation } = useConfiguration()

  return (
    <div
      style={{
        display: show ? 'grid' : 'none',
      }}
      className='company__tab-container__configuration'
    >
      <div className='company__tab-container__configuration__switch-buttons'>
        <SwitchButton
          defaultChecked={!!companyData?.enable_solarplanning}
          label={t('enable_solar_planning')}
          name='enable_solarplanning'
          form={form}
        />

        <SwitchButton
          label={t('enable_lead_integration')}
          defaultChecked={!!companyData?.module_leads}
          name='module_leads'
          form={form}
        />

        <SwitchButton
          defaultChecked={!!companyData?.module_reports}
          label={t('enable_reporting')}
          name='module_reports'
          form={form}
        />
      </div>

      <div className='company__tab-container__configuration__tenant-login'>
        <Button
          onClick={() => loginAsTenantMutation(companyData?.id)}
          disabled={isLoginningAsTennant}
          type='primary'
        >
          {t('login_as_tenant')}
        </Button>
      </div>
    </div>
  )
}

export default Configuration
