import { inputIconColor, inputStyles } from 'src/utils'
import { AiFillLock } from 'react-icons/ai'
import { ShowFieldError } from 'src/common'
import { Form, Button, Input } from 'antd'
import { useResetPassword } from './hooks'
import { Link } from 'react-router-dom'

const ResetPasswordContent = () => {
  const {
    repeatPasswordErrors,
    newPasswordErrors,
    handleResetEmail,
    isLoading,
    token,
    data,
    t,
  } = useResetPassword()

  return (
    <>
      <div className='logincard__title'>{t('reset_password')}</div>
      <div className='logincard__form'>
        {token ? (
          data?.data?.data ? (
            <p className='password-reset-success'>
              {`${data.data.data} Go to `} <Link to='/'>{t('log_in')}</Link>
            </p>
          ) : (
            <Form onFinish={handleResetEmail}>
              <Form.Item>
                <Form.Item name='enterPassword' noStyle>
                  <Input.Password
                    style={inputStyles(newPasswordErrors)}
                    name='enterPassword'
                    placeholder={t('new_password')}
                    prefix={
                      <AiFillLock
                        color={inputIconColor(newPasswordErrors)}
                        size={20}
                      />
                    }
                  />
                </Form.Item>
                <ShowFieldError error={newPasswordErrors} />
              </Form.Item>

              <Form.Item>
                <Form.Item name='repeatPassword' noStyle>
                  <Input.Password
                    style={inputStyles(repeatPasswordErrors)}
                    name='repeatPassword'
                    placeholder={t('repeat_password')}
                    prefix={
                      <AiFillLock
                        color={inputIconColor(repeatPasswordErrors)}
                        size={20}
                      />
                    }
                  />
                </Form.Item>
                <ShowFieldError error={repeatPasswordErrors} />
              </Form.Item>

              <Button
                className='logincard__form__submit__button'
                disabled={isLoading}
                htmlType='submit'
                type='primary'
                block
              >
                {t('reset_password')}
              </Button>
            </Form>
          )
        ) : (
          <p>{t('token_not_found')}</p>
        )}
      </div>
    </>
  )
}
export default ResetPasswordContent
