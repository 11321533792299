import { canPerform, renderAmount } from 'src/utils'
import { ButtonWithTrash } from 'src/common'

const MountingSystemColumns = (setDeleteModal, t) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('brand'),
      dataIndex: 'brand',
      render: (brand) => <>{brand?.name}</>,
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('material'),
      dataIndex: 'material',
      render: (_, record) => {
        return record?.material
      },
    },
    {
      title: t('type'),
      dataIndex: 'type',
    },
    {
      title: t('description'),
      dataIndex: 'description',
    },
    {
      title: t('purchase_price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('warranty'),
      dataIndex: 'warranty',
      render: (val) => `${val} ${t('year')}`,
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          disabled={
            canPerform('delete mounting-system') ||
            canPerform('delete own mounting-system')
              ? false
              : true
          }
          text={t('delete')}
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default MountingSystemColumns
