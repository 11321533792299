import PaymentConditionsFormModal from './PaymentConditionsFormModal'
import { useShowPaymentCondition } from './hooks'

const ShowPaymentCondition = ({
  setShowPaymentConditionModal,
  currentPaymentCondition,
  show,
}) => {
  const {
    updateConditionError,
    updateCondition,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowPaymentCondition(
    setShowPaymentConditionModal,
    currentPaymentCondition
  )

  return (
    <PaymentConditionsFormModal
      actionButtonTitle={t('update')}
      errors={updateConditionError}
      onFinish={updateCondition}
      closeHandler={closeModal}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}

export default ShowPaymentCondition
