import { roles as userRoles, hasRole, isBottom, roles } from 'src/utils'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { allCompaniesRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { useMemo } from 'react'

export const useUserModalForm = (data) => {
  const { lg } = useBreakpoint()
  const { t } = useTranslation()

  const [selectedRole, setSelectedRole] = useState('')

  useEffect(() => {
    const userRole = data?.roles?.[0]?.id

    if (
      userRole === roles.sales ||
      userRole === roles.salesPlus ||
      userRole === roles.worker
    ) {
      setSelectedRole(userRole)
    }
  }, [data?.roles])

  const {
    data: companiesData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    'companies',
    ({ pageParam = 1 }) =>
      allCompaniesRequest({
        page: pageParam,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: hasRole(userRoles.superUser),
    }
  )

  function fetchCompaniesOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return

    fetchNextPage()
  }

  const isSalesSelected = useMemo(() => {
    return selectedRole === roles.sales || selectedRole === roles.salesPlus
  }, [selectedRole])

  const isWorkerSelected = useMemo(() => {
    return selectedRole === roles.worker
  }, [selectedRole])

  return {
    companies: companiesData?.pages,
    fetchCompaniesOnScroll,
    isWorkerSelected,
    setSelectedRole,
    isSalesSelected,
    selectedRole,
    lg,
    t,
  }
}
