function NotesIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='15'
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        d='M4.9987 13.6615C4.63203 13.6615 4.33203 13.3615 4.33203 12.9948V10.9948H1.66536C1.31174 10.9948 0.972604 10.8543 0.722555 10.6043C0.472507 10.3542 0.332031 10.0151 0.332031 9.66146V1.66146C0.332031 1.30784 0.472507 0.968698 0.722555 0.718649C0.972604 0.468601 1.31174 0.328125 1.66536 0.328125H12.332C12.6857 0.328125 13.0248 0.468601 13.2748 0.718649C13.5249 0.968698 13.6654 1.30784 13.6654 1.66146V9.66146C13.6654 10.4015 13.072 10.9948 12.332 10.9948H8.26536L5.7987 13.4681C5.66536 13.5948 5.4987 13.6615 5.33203 13.6615H4.9987ZM5.66536 9.66146V11.7148L7.7187 9.66146H12.332V1.66146H1.66536V9.66146H5.66536ZM9.5587 4.46146L8.88536 5.13479L7.50536 3.78146L8.1787 3.10146C8.31203 2.96146 8.5387 2.95479 8.6987 3.10146L9.5587 3.93479C9.6987 4.07479 9.70536 4.30146 9.5587 4.46146ZM4.33203 6.93479L7.11203 4.14146L8.49203 5.52812L5.7187 8.32812H4.33203V6.93479Z'
        fill='#606870'
      />
    </svg>
  )
}

export default NotesIcon
