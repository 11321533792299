import { useTranslation } from 'react-i18next'
import { formatKbToMb } from 'src/utils'
import { Upload, message } from 'antd'

export const useFileUploadValidation = (form, errors) => {
  const { t } = useTranslation()
  const sizeLimit = 10

  const uploadHandler = (file, filetype, fieldName) => {
    if (formatKbToMb(file?.size) > sizeLimit) {
      message.error(
        t('file_upload_limit', {
          fileType: t(filetype),
          sizeLimit: sizeLimit,
        })
      )
      return Upload.LIST_IGNORE
    }

    form.setFieldsValue({ [fieldName]: file })

    return false
  }

  const fileUploadClassName = (fieldName) => {
    return errors?.[fieldName] || !!form.getFieldError(fieldName)?.length
      ? 'pdf-upload-error'
      : ''
  }

  return {
    fileUploadClassName,
    uploadHandler,
    t,
  }
}
