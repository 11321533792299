import { formItemErrorClasses, inputStyles } from 'src/utils'
import { Form, Input, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ShowFieldError } from 'src/common'

const { Option } = Select

const Monitoring = ({ error = {} }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('monitoring')}
      </Typography.Title>

      <Form.Item
        className={`${formItemErrorClasses(
          error?.sufficient_range
        )} sufficient-range`}
        label={t('is_there_sufficient_wifi_range') + '::'}
        required
      >
        <Form.Item name='sufficient_range' noStyle>
          <Select
            className={error?.sufficient_range ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.sufficient_range} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.wired_connection_option)}
        label={t('wired_connection_option')}
        required
      >
        <Form.Item name='wired_connection_option' noStyle>
          <Select
            className={
              error?.wired_connection_option ? 'antd-select-error ' : ''
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.wired_connection_option} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.internet_connection)}
        label={t('is_the_internet_connection')}
        required
      >
        <Form.Item name='internet_connection' noStyle>
          <Select
            className={error?.internet_connection ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.internet_connection} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.how_utp_cable_run)}
        label={t('how_utp_cable_run')}
        required
      >
        <Form.Item name='how_utp_cable_run' noStyle>
          <Input.TextArea
            style={inputStyles(error?.how_utp_cable_run)}
            rows={4}
            placeholder={t('remarks')}
          />
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.how_utp_cable_run} />
      </Form.Item>

      <Form.Item label={t('extra_information_monitoring')}>
        <Form.Item name='extra_information_monitoring' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default Monitoring
