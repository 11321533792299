import { canManageProject, handleNonValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { removeOfferProductRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useState } from 'react'

export function useConvertersAccordion() {
  const [showConverterAddingForm, setShowConverterAddingForm] = useState(false)
  const [showWifiAddingForm, setShowWifiAddingForm] = useState(false)
  const [showPowerOptimizer, setShowPowerOptimizer] = useState(false)

  const { t } = useTranslation()

  const { offerId } = useParams()
  const queryClient = useQueryClient()

  const { mutate: removeConverterMutation, isLoading: removingConverter } =
    useMutation(removeOfferProductRequest, {
      onSuccess: () => {
        queryClient.invalidateQueries(['offer', offerId])
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    })

  const {
    mutate: removePowerOptimizerMutation,
    isLoading: removingPowerOptimizer,
  } = useMutation(removeOfferProductRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId])
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const { mutate: removeWifiMutation, isLoading: removingWifi } = useMutation(
    removeOfferProductRequest,
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries(['offer', offerId])
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function openConverterAddingForm() {
    if (!canManageProject()) return

    setShowConverterAddingForm(true)
  }

  function closeConverterAddingForm() {
    setShowConverterAddingForm(false)
  }

  function openWifiAddingForm() {
    if (!canManageProject()) return

    setShowWifiAddingForm(true)
  }

  function closeWifiAddingForm() {
    setShowWifiAddingForm(false)
  }

  function closePowerOptimizer() {
    setShowPowerOptimizer(false)
  }

  function openPowerOptimizer() {
    if (!canManageProject()) return

    setShowPowerOptimizer(true)
  }

  function removeConverter(id) {
    removeConverterMutation({
      id,
      table: 'offer_converter',
    })
  }

  function removeWifi(id) {
    removeWifiMutation({
      id,
      table: 'offer_wifi_module',
    })
  }
  function removePowerOptimizer(id) {
    removePowerOptimizerMutation({
      id,
      table: 'offer_power_optimizer',
    })
  }

  return {
    removeWifi,
    removingWifi,
    removingConverter,
    removeConverter,
    openWifiAddingForm,
    openConverterAddingForm,
    closeWifiAddingForm,
    closeConverterAddingForm,
    showWifiAddingForm,
    showConverterAddingForm,
    showPowerOptimizer,
    closePowerOptimizer,
    openPowerOptimizer,
    removePowerOptimizer,
    removingPowerOptimizer,
    translate: t,
  }
}
