import { useState } from 'react'

function useTeamAndEmployeeMultiselect({
  options,
  searchableKey,
  setIsSelectOpen,
  isSelectOpen,
}) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const handleOptionSelect = (option) => {
    if (selectedOptions.some((item) => item.id === option.id)) {
      setSelectedOptions(
        selectedOptions.filter((item) => item.id !== option.id)
      )
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const filteredOptions = options.filter((option) =>
    option[searchableKey].toLowerCase().includes(searchQuery.toLowerCase())
  )

  const resetSelectedOptions = () => {
    setSelectedOptions([])
  }

  const handleSaveOptions = () => {
    setIsSelectOpen(!isSelectOpen)
  }

  return {
    selectedOptions,
    searchQuery,
    setSearchQuery,
    handleOptionSelect,
    filteredOptions,
    resetSelectedOptions,
    handleSaveOptions,
  }
}

export { useTeamAndEmployeeMultiselect }
