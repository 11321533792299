import AddPaymentConditionModal from './components/AddPaymentConditionModal'
import ShowPaymentCondition from './components/ShowPaymentCondition'
import { usePaymentConditions } from './usePaymentConditions'
import PaymentTableColumns from './PaymentTableColumns'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Header,
  Search,
} from 'src/common'

const PaymentConditions = () => {
  const {
    isPaymentConditionsDataLoading,
    isPaymentConditionsDeleting,
    setShowPaymentConditionModal,
    setAddPaymentConditionModal,
    setDeletePaymentCondition,
    showPaymentConditionModal,
    addPaymentConditionModal,
    deletePaymentCondition,
    setShowImportCsvModal,
    showImportCsvModal,
    paymentConditions,
    importConditions,
    exportConditions,
    handlePageChange,
    paginationInfo,
    exportDisabled,
    setImportError,
    onSearchClick,
    deletePayment,
    importError,
    importing,
    onRow,
    t,
  } = usePaymentConditions()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>
        {t('payment_conditions')}
      </div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isPaymentConditionsDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            onClick={() => setAddPaymentConditionModal(true)}
            text={t('add')}
          />
        </div>
        <Table
          bordered
          scroll={{ x: '992px' }}
          columns={PaymentTableColumns(setDeletePaymentCondition, t)}
          dataSource={paymentConditions || []}
          rowKey={(record) => record.id}
          onRow={onRow}
          loading={isPaymentConditionsDataLoading}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
        />
        <div className='contentFooter'>
          <CsvButton
            onClick={exportConditions}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showImportCsvModal ? (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          setImportError={setImportError}
          submitModal={importConditions}
          importError={importError}
          disabled={importing}
        />
      ) : null}

      {deletePaymentCondition.show && (
        <AgreeDeleteModal
          disabled={isPaymentConditionsDeleting}
          visible={deletePaymentCondition.show}
          targetName={deletePaymentCondition?.data?.name}
          onCancel={() => setDeletePaymentCondition({ show: false, data: {} })}
          onSubmit={() =>
            deletePaymentCondition.data.id &&
            deletePayment(deletePaymentCondition.data.id)
          }
        />
      )}

      {addPaymentConditionModal && (
        <AddPaymentConditionModal
          setAddPaymentConditionModal={setAddPaymentConditionModal}
          show={addPaymentConditionModal}
        />
      )}

      {showPaymentConditionModal.show && (
        <ShowPaymentCondition
          setShowPaymentConditionModal={setShowPaymentConditionModal}
          currentPaymentCondition={showPaymentConditionModal.data}
          show={showPaymentConditionModal.show}
        />
      )}
    </div>
  )
}

export default PaymentConditions
