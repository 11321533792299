import axios from '../../axios'

export const allCompaniesRequest = async ({
  page,
  size = 10,
  searchKeyWord = '',
}) => {
  return await axios.get(
    `api/companies?page=${page}&per_page=${size}${
      searchKeyWord && `&search=${searchKeyWord}`
    }`
  )
}

export const getCompanyRequest = async (id) => {
  return await axios.get(`api/companies/${id}`)
}

export const updateCompanyRequest = async (id, data) => {
  return await axios.post(`api/companies/update/${id}`, data)
}

export const addCompanyRequest = async (data) => {
  return await axios.post('api/companies', data)
}

export const addNewSourceTokenRequest = async (data) => {
  return await axios.post('api/companies/tokens', data)
}

export const deleteSourceTokenRequest = async (id) => {
  return await axios.delete(`api/companies/tokens/${id}`)
}

export const deleteCompanyRequest = async (id) => {
  return await axios.delete(`api/companies/${id}`)
}
