function TaskStatusIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M4.66602 2.5V4.16667M4.66602 4.16667H14.666L12.9993 7.5L14.666 10.8333H4.66602M4.66602 4.16667V10.8333M4.66602 17.5V10.8333'
        stroke='#868E96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { TaskStatusIcon }
