import { getProjectsByManagerIdRequest } from 'src/services'
import { useMutation } from 'react-query'
import { useEffect } from 'react'

export const useExpandedTable = (id) => {
  const {
    mutate: getProjectsByManagerId,
    data: projectsData,
    isLoading,
  } = useMutation(({ id }) => {
    return getProjectsByManagerIdRequest(id)
  })
  useEffect(() => {
    getProjectsByManagerId({ id })
  }, [])

  return { projectsData, isLoading }
}
