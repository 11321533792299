import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { addUserRequest } from 'src/services'
import { Form } from 'antd'
import {
  handleNonValidationError,
  handleValidationError,
  roles as userRoles,
  hasRole,
  appendPrefix,
} from 'src/utils'

export const useUsersModal = (setAddUserModal, user) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const closeModal = () => setAddUserModal(false)

  const {
    isLoading,
    error,
    mutate: addUser,
  } = useMutation(
    (variables) => {
      const customVariables = {
        ...variables,
        password: variables?.passwordSearch,
        company_id: hasRole(userRoles.superUser)
          ? variables?.company_id
          : user?.company_id,
        max_discount: variables?.max_discount,
        phone_number: appendPrefix(variables?.phone_number),
        commission: variables?.commission,
      }

      return !isLoading && addUserRequest(customVariables)
    },
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          closeModal()
          queryClient.refetchQueries('users')
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  return {
    error: handleValidationError(error),
    closeModal,
    isLoading,
    addUser,
    form,
    t,
  }
}
