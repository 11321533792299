import { addActivityTypeRequest, allActivityTypesRequest } from 'src/services'
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isBottom } from 'src/utils'
import { Select } from 'antd'

export const useFormActivity = () => {
  const queryClient = useQueryClient()
  const { user } = useSelector((state) => state.globalSlice)

  const { t } = useTranslation()

  const { Option } = Select

  const { mutate: addActivityType, isLoading: addActivityLoading } =
    useMutation(addActivityTypeRequest, {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.refetchQueries('activity_types')
        }
      },
    })

  const {
    data: activityTypes,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['activity_types', user?.company?.id],
    ({ pageParam = 1 }) =>
      allActivityTypesRequest({
        page: pageParam,
        companyId: user?.company?.id,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
    }
  )

  const clickHandler = (newTypeValue) => {
    if (!newTypeValue) return
    addActivityType({ name: newTypeValue })
  }

  const fetchActivityTypeOnScroll = (e) => {
    if (!isBottom(e) || !hasNextPage) return

    fetchNextPage()
  }

  return {
    activityTypes: activityTypes?.pages,
    fetchActivityTypeOnScroll,
    addActivityLoading,
    clickHandler,
    Option,
    t,
  }
}
