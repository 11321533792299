import DocumentLibraryFormModal from './DocumentLibraryFormModal'
import { useAddDocument } from './hooks'

const AddDocument = ({ setAddDocumentModal, show }) => {
  const { form, t, document, setDocument, submitHandler, errors, isUploading } =
    useAddDocument(setAddDocumentModal)

  return (
    <DocumentLibraryFormModal
      closeHandler={() => setAddDocumentModal(false)}
      actionButtonTitle={t('save')}
      setDocument={setDocument}
      onFinish={submitHandler}
      isLoading={isUploading}
      document={document}
      errors={errors}
      show={show}
      form={form}
    />
  )
}

export default AddDocument
