import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { notification } from 'antd'
import {
  allMountingSystemsRequest,
  attachMountingSystemToProjectRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  appendCompanyId,
  isBottom,
  flatPaginatedData,
} from 'src/utils'
import { useGetProductBrand } from 'src/hooks'

export function useAttachMountingSystemToProject({
  data,
  clearForm,
  projectCompanyId,
}) {
  const { md, sm } = useBreakpoint()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const layout = {
    labelCol: { span: !md ? 8 : !sm ? 7 : 5 },
    wrapperCol: { span: 20 },
  }
  const inputRef = useRef(null)

  const { offerId } = useParams()

  const queryClient = useQueryClient()

  const [brandId, setBrandId] = useState(data?.mounting_system?.brand_id)
  const [mountingSystem, setMountingSystem] = useState(data?.mounting_system)

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'mountingSystemBrands',
    url: `api/brands?classification=3&project=1${appendCompanyId(
      projectCompanyId
    )}`,
  })

  const {
    data: mountingSystems,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['mountingSystems', brandId, projectCompanyId],
    ({ pageParam = 1 }) =>
      allMountingSystemsRequest({
        page: pageParam,
        companyId: projectCompanyId,
        brandId,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(brandId) && Boolean(projectCompanyId),
    }
  )

  const {
    mutate: addMountingSyStemToProject,
    isLoading: isAdding,
    error: addingError,
  } = useMutation(attachMountingSystemToProjectRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['offer', offerId])
      clearForm()
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function attachMountingSyStemToProject(values) {
    if (!canManageProject()) return

    return addMountingSyStemToProject({
      offer_id: offerId,
      brand_id: brandId,
      mounting_system_id: mountingSystem?.id,
      ...values,
    })
  }

  function closeConfirmationModal() {
    setShowConfirmationModal(false)
  }

  function openConfirmationModal() {
    setShowConfirmationModal(true)
  }

  function onQuantityChange(
    inputValue = 0,
    mountingSystemsQuantity = 0,
    panelsQuantity = 0,
    t
  ) {
    clearTimeout(inputRef.current)
    let temp = mountingSystemsQuantity
      ? mountingSystemsQuantity - (data?.quantity || 0)
      : 0
    let value = inputValue ? inputValue + temp : temp

    inputRef.current = setTimeout(() => {
      if (value !== panelsQuantity) {
        notification['warning']({
          message: t('pay_attention'),
          description: t('mounting_system_quantity_warning_text', {
            mountingSystemsQuantity: `(${value})`,
            panelsQuantity: `(${panelsQuantity})`,
          }),
        })
      }
    }, 1000)
  }

  function fetchMountingSystemOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return

    fetchNextPage()
  }

  function handleMountingSystemChange(value) {
    let tempMountingSystem = flatPaginatedData(mountingSystems)
    setMountingSystem(
      tempMountingSystem?.find(
        (mountingSystem) => mountingSystem?.type === value
      )
    )
  }

  return {
    attachMountingSyStemToProject,
    error: handleValidationError(addingError),
    layout,
    mountingSystemBrands: productBrands,
    fetchBrandOnScroll,
    fetchMountingSystemOnScroll,
    handleMountingSystemChange,
    mountingSystems: mountingSystems?.pages,
    mountingSystem,
    setMountingSystem,
    setBrandId,
    brandId,
    isAdding,
    closeConfirmationModal,
    openConfirmationModal,
    showConfirmationModal,
    onQuantityChange,
  }
}
