import { useMutation, useQueryClient } from 'react-query'
import { deleteSourceTokenRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from 'src/hooks'
import { useMemo, useState } from 'react'
import { message } from 'antd'

export const useSourceItem = (leadToken) => {
  const [isEmbedCodeCopied, setIsEmbedCodeCopied] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { user } = useGetUserInfo()

  const iframeUrl = useMemo(() => {
    return `${window.location.origin}/lead-iframe?token=${leadToken?.token}`
  }, [leadToken])

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    }
  }

  const handleCopyClick = (text, setState) => {
    if (!text) return
    copyTextToClipboard(text)
      .then(() => {
        setState(true)
        setTimeout(() => {
          setState(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleIframeUrlCopy = () => {
    const iframe = `<iframe src="${iframeUrl}" title="lead-iframe"></iframe>`
    return handleCopyClick(iframe, setIsEmbedCodeCopied)
  }

  const { mutate: deleteIframeMutation, isLoading: isDeleting } = useMutation(
    () => !isDeleting && deleteSourceTokenRequest(leadToken?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companies')
        message.success(t('deleted_successfully'))
      },
    }
  )

  return {
    deleteIframeMutation,
    handleIframeUrlCopy,
    isEmbedCodeCopied,
    isDeleting,
    iframeUrl,
    user,
    t,
  }
}
