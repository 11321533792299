import { formatCurrency, canManageProject, hidePurchasePrice } from 'src/utils'
import { Form, Input, InputNumber } from 'antd'
import NumberFormat from 'react-number-format'
import { ShowFieldError } from 'src/common'
import { useListItem } from './hooks'

const ListItem = ({
  onSellingPriceChange = () => {},
  disableEditing = false,
  canSeePriceDetails,
  products = [],
  product = '',
  title,
}) => {
  const { t, sellingPriceChangeHandler, validationError } = useListItem(
    onSellingPriceChange,
    product
  )

  return (
    <>
      <div className='list__item'>
        <div className='leftside'>
          <div className='leftside__title'>{title}</div>
          {products.map((item) => {
            return (
              <div key={item?.id} className='leftside__item'>
                <div className='leftside__subtitle'>{`${
                  item?.[product]?.brand?.name || ''
                }    ${item?.[product]?.name || ''}  ${
                  item?.[product]?.type || ''
                }`}</div>
                <div className='list_wrapper'>
                  <div
                    className={`ind_list_wrapper quantity ${
                      hidePurchasePrice() && 'quantity_hide_purchase_price'
                    }`}
                  >
                    <span className='list_title_mobile'>{t('quantity')}</span>
                    <span>{item?.quantity || item?.hours}</span>
                  </div>

                  {canSeePriceDetails ? (
                    <div className='ind_list_wrapper purchase_price'>
                      <span className='list_title_mobile'>
                        {t('purchase_price')}
                      </span>

                      <span>
                        {`€ ${formatCurrency(
                          Number(item?.[product]?.purchase_price)
                        )}`}
                      </span>
                    </div>
                  ) : null}

                  <div
                    className={`list_price_input ${
                      hidePurchasePrice() && 'hide_purchase_price'
                    }`}
                  >
                    <div className='ind_list_wrapper selling_price'>
                      <span className='list_title_mobile'>
                        {t('selling_price')}
                      </span>

                      <div className='price_wrapper'>
                        <Form.Item
                          name={`ind_selling_price_${product}_${
                            product === 'panel' ? item?.panel?.id : item?.id
                          }`}
                          noStyle
                        >
                          <NumberFormat
                            defaultValue={item?.[product]?.selling_price}
                            onValueChange={({ floatValue }) => {
                              sellingPriceChangeHandler(item, floatValue)
                            }}
                            onKeyPress={(e) => {
                              if (e.key === '-') {
                                e.preventDefault()
                              }
                            }}
                            prefix='€ '
                            customInput={Input}
                            decimalSeparator=','
                            thousandSeparator='.'
                            decimalScale={2}
                            className={`selling_price_input ${
                              validationError && 'error-border'
                            }`}
                            disabled={!canManageProject() || disableEditing}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='ind_list_wrapper selling_price'>
                      <span className='list_title_mobile'>
                        {t('total_selling_price')}
                      </span>

                      <div className='price_wrapper'>
                        <Form.Item
                          name={`total_selling_price_${`${product}`}_${
                            item?.id
                          }`}
                          noStyle
                        >
                          <InputNumber
                            readOnly
                            disabled
                            controls={false}
                            formatter={(value) =>
                              `€ ${formatCurrency(Number(value))}`
                            }
                            parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                            className='selling_price_input'
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {validationError && (
        <ShowFieldError
          error={t('product_price_requirement')}
          withHeight={true}
        />
      )}
    </>
  )
}

export default ListItem
