const ChangeProjectCategoryButton = (props) => {
  return (
    <div
      onClick={props?.clickHandler}
      className={`project__category ${props?.isActive && 'active__category'}`}
    >
      <p>{props?.title}</p>
      <span className={props?.isActive ? 'highlighted__number' : ''}>
        {props?.count}
      </span>
    </div>
  )
}

export default ChangeProjectCategoryButton
