import MaterialsFormModal from './MaterialsFormModal'
import { useAddMaterialModal } from './hooks'

const AddMaterialModal = ({ setShowAddMaterialModal, show }) => {
  const { addMaterialError, addMaterial, closeModal, isLoading, form, t } =
    useAddMaterialModal(setShowAddMaterialModal)

  return (
    <MaterialsFormModal
      actionButtonTitle={t('save')}
      errors={addMaterialError}
      closeHandler={closeModal}
      onFinish={addMaterial}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}
export default AddMaterialModal
