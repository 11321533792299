import ShowConverterModal from './components/ShowConverterModal'
import AddConverterModal from './components/AddConverterModal'
import ConvertersTableColumns from './ConvertersTableColumns'
import { canPerform, getTableSortValue } from 'src/utils'
import { useConverter } from './useConverters'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Search,
  Header,
} from 'src/common'

const Converters = () => {
  const {
    showMultipleDeleteConfirmation,
    showMultipleDeleteModalHandler,
    setShowAddConverterModal,
    isConvertersDataLoading,
    selectedConvertersIds,
    showAddConverterModal,
    setShowImportCsvModal,
    deletingMultipleItem,
    deleteSelectedItems,
    setDeleteConverters,
    showImportCsvModal,
    exportConverters,
    importConverters,
    deleteConverters,
    handlePageChange,
    setShowConverter,
    deleteConverter,
    exportDisabled,
    setImportError,
    paginationInfo,
    showConverter,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    converters,
    deleting,
    setSort,
    onRow,
    t,
  } = useConverter()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('converters')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isConvertersDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            disabled={
              canPerform('manage converter') ||
              canPerform('manage own converter')
                ? false
                : true
            }
            text={t('add')}
            onClick={() => setShowAddConverterModal(true)}
          />
          <ButtonWithTrash
            onClick={showMultipleDeleteModalHandler}
            disabled={!selectedConvertersIds.length}
            text={t('delete_selected_item')}
          />
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          bordered
          columns={ConvertersTableColumns(setDeleteConverters, t)}
          dataSource={converters}
          onRow={onRow}
          loading={isConvertersDataLoading}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
        />

        <div className='contentFooter'>
          <CsvButton
            onClick={exportConverters}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage converter') ||
              canPerform('manage own converter')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showConverter.show && (
        <ShowConverterModal
          currentConverter={showConverter.data}
          setShowConverter={setShowConverter}
          showConverter={showConverter.show}
        />
      )}

      {showAddConverterModal && (
        <AddConverterModal
          setShowAddConverterModal={setShowAddConverterModal}
          showAddConverterModal={showAddConverterModal}
        />
      )}

      {showImportCsvModal && (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          submitModal={importConverters}
          disabled={importing}
          importError={importError}
          setImportError={setImportError}
        />
      )}
      {deleteConverters.show && (
        <AgreeDeleteModal
          disabled={deleting}
          visible={deleteConverters.show}
          targetName={deleteConverters?.data?.name}
          onCancel={() => setDeleteConverters({ show: false, data: {} })}
          onSubmit={() =>
            deleteConverters.data.id &&
            deleteConverter(deleteConverters.data.id)
          }
        />
      )}
      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          disabled={deletingMultipleItem}
          onSubmit={deleteSelectedItems}
        />
      )}
    </div>
  )
}

export default Converters
