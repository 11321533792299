import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Space } from 'antd'
import { ConfirmInfoIcon, DeleteIcon, InfoIcon, XIcon } from 'src/assets'

const AgreeDeleteModal = ({
  visible = false,
  onCancel = () => {},
  onSubmit = () => {},
  disabled = false,
  text,
  targetName,
  suffix = '?',
  yesText,
  noText,
  yesType = 'danger',
  noType = 'primary',
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeIcon={<XIcon />}
      title={
        <div className='custom-title'>
          <ConfirmInfoIcon />
          <span>{t('confirmation_modal_title')}</span>
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      className='confirmation-modal'
    >
      <div className='agreeDelete__content'>
        {text ||
          t('delete_confirmation_text', {
            name: targetName || '',
          })}{' '}
        {suffix}
      </div>
      <div className='agreeDelete__footer'>
        <Space>
          <Button type={noType} onClick={onCancel}>
            {noText || t('cancel')}
          </Button>
          <Button disabled={disabled} type={yesType} onClick={onSubmit}>
            {yesText || t('delete')}
          </Button>
        </Space>
      </div>
    </Modal>
  )
}

export default AgreeDeleteModal
