import { CopyIcon, TrashIcon } from 'src/assets'
import { useSourceItem } from './hooks'
import { InputItem } from 'src/common'
import { Tooltip } from 'antd'

const SourceItem = ({ leadToken, companyData }) => {
  const {
    deleteIframeMutation,
    handleIframeUrlCopy,
    isEmbedCodeCopied,
    isDeleting,
    iframeUrl,
    user,
    t,
  } = useSourceItem(leadToken)

  return (
    <div
      style={{
        borderBottom:
          companyData?.id === user?.company?.id ? '1px solid #dee2e6' : '',
      }}
      key={leadToken.id}
      className='company__tab-container__lead-integration__source-list__item'
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <InputItem
          label={t('source_name')}
          value={leadToken?.name}
          withErrorHeight={false}
          required={false}
          disabled
        />

        <div onClick={deleteIframeMutation} className='delete-icon'>
          <TrashIcon disabled={isDeleting} height={24} width={24} />
        </div>

        <div
          onClick={() => !isDeleting && deleteIframeMutation()}
          className='source-delete'
        >
          {t('delete')}
        </div>
      </div>

      <div
        style={{
          position: 'relative',
        }}
      >
        <InputItem
          value={`<iframe src="${iframeUrl}" title="lead-iframe"></iframe>`}
          label={t('iframe_embedded_code')}
          className='iframe-embedded-code'
          withErrorHeight={false}
          required={false}
          disabled
        />

        <Tooltip
          onClick={handleIframeUrlCopy}
          title={isEmbedCodeCopied ? t('copied') : t('copy_embed_code')}
        >
          <div className='copy-icon'>
            <CopyIcon />
          </div>
        </Tooltip>

        <div onClick={handleIframeUrlCopy} className='source-copy'>
          {isEmbedCodeCopied ? t('copied') : t('copy')}
        </div>
      </div>
    </div>
  )
}

export default SourceItem
