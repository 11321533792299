import { Table } from 'antd'
import { deliveryTableColumns } from './table/Column'

const TableDelivery = ({ title = '', data = [], product = '' }) => {
  return (
    <div className='delivery__table'>
      <Table
        bordered
        columns={deliveryTableColumns(title, product)}
        dataSource={data || []}
        pagination={false}
      />
    </div>
  )
}

export default TableDelivery
