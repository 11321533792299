import { useTranslation } from 'react-i18next'
import { modalView } from '../data'

function Sidebar({
  isPlanningDetailsActive,
  isProductInformationActive,
  isClientInformationAndProjectDetailsActive,
  isDocumentActive,
  changeViewOnClick,
}) {
  const { t } = useTranslation()

  return (
    <div className='project-view-modal-sidebar'>
      <button
        className={isPlanningDetailsActive ? 'active' : ''}
        onClick={() => changeViewOnClick(modalView.PLANNING_DETAILS)}
      >
        {t('planning_details')}
      </button>
      <button
        className={isProductInformationActive ? 'active' : ''}
        onClick={() => changeViewOnClick(modalView.PRODUCT_INFORMATION)}
      >
        {t('product_information')}
      </button>
      <button
        className={isClientInformationAndProjectDetailsActive ? 'active' : ''}
        onClick={() =>
          changeViewOnClick(modalView.CLIENT_INFORMATION_AND_PROJECT_DETAILS)
        }
      >
        {t('client_information_and_project_details')}
      </button>
      <button
        className={isDocumentActive ? 'active' : ''}
        onClick={() => changeViewOnClick(modalView.DOCUMENT)}
      >
        {t('documents')}
      </button>
    </div>
  )
}

export default Sidebar
