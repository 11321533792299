import OffersClientInformation from './OffersClientInformation'
import Comments from './Comments'
import OffersHeader from './OffersHeader'
import Offer from './Offer'
import { useCreateProject } from '../useCreateProject'
import { useOffers } from './hooks'
import { useTranslation } from 'react-i18next'
import LoadMoreButton from './LoadMoreButton'
import { Spin, Affix } from 'antd'
import { PageNotFound } from 'src/screens/GeneralPages'
import ProjectDataModal from './ProjectDataModal'
import { canManageProject } from 'src/utils'

function OffersAndComments() {
  const { isProjectLoading, project } = useCreateProject()

  const {
    isLoading: isOffersActionLoading,
    fetchNextPage,
    openedOffers,
    hasNextPage,
    createOffer,
    offers,
  } = useOffers()

  const { t } = useTranslation()

  if (isProjectLoading) {
    return <Spin style={{ marginTop: '5rem' }} />
  }

  if (!project) {
    return <PageNotFound text={t('project_not_found')} />
  }

  return (
    <div className='offer-and-comments-wrapper'>
      <Affix
        className='client-information client-information__affix'
        offsetTop={16}
      >
        <OffersClientInformation
          className='information__section'
          project={project}
        />
      </Affix>

      {window.location.href.includes('projectDataModal') && (
        <ProjectDataModal project={project} />
      )}

      <section className='offer-and-comments'>
        <section className='offers'>
          <OffersHeader
            createOffer={createOffer}
            isOffersActionLoading={isOffersActionLoading}
            inspection={project?.inspection}
            offersCount={offers?.[0]?.data?.meta?.total}
            project={project}
          />

          {offers?.map((page) => {
            if (!page?.data?.data?.length) {
              return null
            }

            return page?.data?.data?.map((offer) => {
              return (
                <Offer
                  canCreateAllInView={offer?.can_create_scenario}
                  customerSignatureExists={offer?.quot_customer_signature_date}
                  disableAllInView={
                    (!offer?.can_create_scenario &&
                      !offer?.viewer_url &&
                      !!offer?.order_created_at) ||
                    !canManageProject()
                  }
                  offerCreatedAt={offer?.order_created_at}
                  allInviewMediaUrl={offer?.media_url}
                  offerSent={offer?.offer_sent_at}
                  userName={offer?.user?.name}
                  offerStatus={offer?.status}
                  remoteId={offer?.remote_id}
                  openedOffers={openedOffers}
                  offerNumber={offer?.code}
                  offerText={offer?.offer}
                  offerId={offer?.id}
                  key={offer?.id}
                />
              )
            })
          })}

          {hasNextPage ? (
            <LoadMoreButton
              fetchNextPage={fetchNextPage}
              isLoading={isOffersActionLoading}
              text={t('load_more_offers')}
            />
          ) : null}
        </section>

        <section className='comments'>
          <Comments />
        </section>
      </section>
    </div>
  )
}

export default OffersAndComments
