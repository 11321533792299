import { useClearHomePageQuery, useHandleQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { MANAGER_IDS_PARAM } from 'src/screens/Home/constants'
import { useEffect } from 'react'

export const useManagersFilter = ({
  managersList,
  accountManagersForm,
  managerIds,
}) => {
  const [accountManagersModalOpen, setAccountManagersModalOpen] =
    useState(false)

  const [managerSearchKeyword, setManagerSearchKeyword] = useState('')
  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  const { clearPageQuery } = useClearHomePageQuery()
  const { t } = useTranslation()

  const managersOptions = managersList?.map((manager) => {
    return {
      label: <div>{t(manager?.name)}</div>,
      value: manager?.id,
      name: t(manager?.name),
    }
  })

  let options = managersOptions

  if (managerSearchKeyword.trim().length === 0) {
    options = managersOptions
  } else {
    options = managersOptions?.filter((option) => {
      return option.name
        .toLowerCase()
        .includes(managerSearchKeyword.trim().toLowerCase())
    })
  }

  const submitHandler = (values) => {
    clearPageQuery()
    if (values?.filters) {
      appendQueryParams(
        {
          [MANAGER_IDS_PARAM]: JSON.stringify(values.filters),
        },
        {
          resetPage: true,
        }
      )
    }
    setAccountManagersModalOpen(false)
  }

  useEffect(() => {
    if (!managerIds?.length) {
      accountManagersForm.setFieldsValue({ filters: [] })
    }
  }, [managerIds?.length])

  const resetHandler = () => {
    deleteQueryParams(MANAGER_IDS_PARAM)
  }

  return {
    setAccountManagersModalOpen,
    accountManagersModalOpen,
    setManagerSearchKeyword,
    managerSearchKeyword,
    managersOptions,
    submitHandler,
    resetHandler,
    options,
    t,
  }
}
