import { canPerform, renderAmount } from 'src/utils'
import { ButtonWithTrash } from 'src/common'

const ActivitiesTableColumn = (setDeleteModal, t) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('type'),
      dataIndex: 'type_id',
      render: (_, record) => <>{record?.type?.name}</>,
    },
    {
      title: t('code'),
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: t('price'),
      dataIndex: 'purchase_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('selling_price'),
      dataIndex: 'selling_price',
      render: (amount) => renderAmount(amount),
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          disabled={
            canPerform('delete activity') || canPerform('delete own activity')
              ? false
              : true
          }
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default ActivitiesTableColumn
