import TimeIcon from './TimeIcon'
import TimeOption from './TimeOption'
import { useStartFinishTime } from '../../hooks'

function StartFinishTime({ id, label, placeholder }) {
  const {
    isTimeOptionsOpen,
    startTime,
    startTimeActive,
    finishTime,
    finishTimeActive,
    timeArray,
    startTimeActiveOnClick,
    finishTimeActiveOnClick,
    setStartTimeOnClick,
    setFinishTimeOnClick,
    closeOptions,
  } = useStartFinishTime()

  return (
    <div className='project_details_input_group'>
      <label htmlFor={id}>{label}</label>
      <div
        className='project_details_input'
        onClick={() => {
          if (!startTimeActive && !finishTimeActive) {
            return startTimeActiveOnClick()
          }
        }}
      >
        {isTimeOptionsOpen || startTime ? (
          <div className='start_finish_time_placeholder'>
            <span>From</span>
            <div
              className={`time_placeholder ${
                startTimeActive ? 'time_placeholder_active' : ''
              }`}
              onClick={startTimeActiveOnClick}
            >
              {startTime ? startTime.label : '-- : --'}
            </div>
            <span>-</span>
            <span>To</span>
            <div
              className={`time_placeholder ${
                finishTimeActive ? 'time_placeholder_active' : ''
              }`}
              onClick={finishTimeActiveOnClick}
            >
              {finishTime ? finishTime.label : '-- : --'}
            </div>
          </div>
        ) : (
          <div>{placeholder}</div>
        )}
        <div className='edit_icon'>
          <TimeIcon closePopup={closeOptions} />
        </div>
      </div>
      {isTimeOptionsOpen ? (
        <div className='time_options_container'>
          {timeArray?.map((time, index) => {
            return (
              <TimeOption
                key={index}
                time={time}
                setTime={() => {
                  return startTimeActive
                    ? setStartTimeOnClick(time)
                    : setFinishTimeOnClick(time)
                }}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default StartFinishTime
