const ProjectInfo = ({ title, value }) => {
  return (
    <div className='info__container'>
      <div className='title'>{title}</div>
      <div className='value'>{value}</div>
    </div>
  )
}

export default ProjectInfo
