function hasProfilePicture(data) {
  return data?.avatar?.id && data?.avatar?.name && data?.avatar?.path
}

function getProfilePictureSrc(data) {
  let temp = ''
  if (hasProfilePicture(data)) {
    temp = `${process.env.REACT_APP_BACKEND_URI}/storage/${data.avatar.path}/${data.avatar.name}`
  }
  return temp
}

export { getProfilePictureSrc, hasProfilePicture }
