import GeneralConditionsFormModal from './GeneralConditionsFormModal'
import { useShowGeneralCondition } from './hooks'

const ShowGeneralCondition = ({
  setShowGeneralConditionModal,
  currentGeneralCondition,
  show,
}) => {
  const {
    updateConditionError,
    updateCondition,
    closeModal,
    isLoading,
    form,
    t,
  } = useShowGeneralCondition(
    setShowGeneralConditionModal,
    currentGeneralCondition
  )

  return (
    <GeneralConditionsFormModal
      actionButtonTitle={t('update')}
      errors={updateConditionError}
      onFinish={updateCondition}
      closeHandler={closeModal}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default ShowGeneralCondition
