import { BsExclamationCircleFill } from 'react-icons/bs'
import { useErrorModal } from './useErrorModal'
import { VscClose } from 'react-icons/vsc'
import { Modal, Typography } from 'antd'

const ErrorModal = ({
  show = false,
  importError,
  fileName,
  close = () => {},
}) => {
  const { t, errorMessage } = useErrorModal(importError)

  return (
    <Modal
      wrapClassName='errorModal'
      centered
      visible={show}
      onCancel={close}
      footer={null}
      mask={false}
      closeIcon={<VscClose size={25} color='#ADB5BD' />}
    >
      <BsExclamationCircleFill size={32} color='rgba(255, 77, 79, 1)' />
      <div className='errorModal__content'>
        <Typography.Title level={5}>{fileName}</Typography.Title>
        <Typography.Text type='secondary'>
          {t('something_went_wrong')}: {errorMessage}
        </Typography.Text>
      </div>
    </Modal>
  )
}

export default ErrorModal
