import { useMutation, useQueryClient } from 'react-query'
import { useFileUpload, useGetUserInfo } from 'src/hooks'
import { updateCurrentUserRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { message } from 'antd'
import i18next from 'i18next'
import { Form } from 'antd'
import {
  handleNonValidationError,
  handleValidationError,
  getProfilePictureSrc,
  storageFileUri,
  dataUrlToFile,
  translateRole,
  appendDutchCode,
  appendPrefix,
  isWorker,
} from 'src/utils'

export const useProfileForm = () => {
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()

  const [draftSignatureSrc, setDraftSignatureSrc] = useState(null)

  const getSignatureImageUri = (userData) => {
    const { path, name } = userData?.draft_signature
    return storageFileUri(path, name)
  }

  const { user } = useGetUserInfo({
    onSuccess: (data) => {
      if (data?.data?.draft_signature?.id) {
        setDraftSignatureSrc(getSignatureImageUri(data?.data))
        setExistingSignatureId(data?.data?.draft_signature?.id)
      }
    },
  })

  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [isSignatureChanged, setIsSignatureChanged] = useState(false)

  const [existingSignatureId, setExistingSignatureId] = useState(
    user?.draft_signature?.id || null
  )

  const [fetchErrors, setFetchErrors] = useState(null)

  const [signatureImageData, setSignatureImageData] = useState('')

  const { uploadFile, uploadError, isUploading } = useFileUpload()
  const queryClient = useQueryClient()

  const localUserData = JSON.parse(localStorage.getItem('user'))

  const initialValues = {
    name: user?.name,
    phone_number: appendDutchCode(user?.phone_number),
    profile_picture: getProfilePictureList(),
    lang: user?.lang,
  }

  useEffect(() => {
    setFormValues((prev) => {
      return { ...prev, ...initialValues }
    })
  }, [user])

  const [formValues, setFormValues] = useState(initialValues)

  const formChangeHandler = (fieldValue, name) => {
    setFormValues((prev) => {
      return { ...prev, [name]: fieldValue }
    })
  }

  const detectChangesHandler = () => {
    const { lang, name, phone_number, profile_picture } = formValues

    return (
      !isSignatureChanged &&
      phone_number === localUserData?.phone_number &&
      name === localUserData?.name &&
      lang === i18next.language &&
      profile_picture?.[0]?.uid === user?.avatar?.id
    )
  }

  const cancelHandler = () => {
    const previousData = {
      name: localUserData?.name,
      phone_number: localUserData?.phone_number,
      lang: localUserData?.lang,
      profile_picture: getProfilePictureList(),
    }

    form.setFieldsValue(previousData)
    setFormValues(previousData)

    setSignatureImageData('')

    if (user?.draft_signature) {
      setDraftSignatureSrc(getSignatureImageUri(user))
      setExistingSignatureId(user?.draft_signature?.id)
    }

    setIsSignatureChanged(false)
    setFetchErrors({})
  }

  const { mutate: changeUserDetail, isLoading: isUpdating } = useMutation(
    updateCurrentUserRequest,
    {
      onSuccess: (data, variables) => {
        localUserData.name = variables?.name
        localUserData.phone_number = variables?.phone_number
        localUserData.lang = variables?.lang

        i18n.changeLanguage(variables?.lang)

        localStorage.setItem('user', JSON.stringify(localUserData))
        setFetchErrors({})
        setSignatureImageData('')
        setIsSignatureChanged(false)
        message.success(t('updated_successfully'))
        queryClient.invalidateQueries('user_info')

        const draftSignature = data?.data?.user?.draft_signature
        if (draftSignature) {
          const { path, name } = draftSignature
          setDraftSignatureSrc(storageFileUri(path, name))
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
        setFetchErrors((prev) => {
          return { ...prev, ...error?.response?.data?.errors }
        })
      },
    }
  )

  const submitHandler = async () => {
    const { name, phone_number, profile_picture } = formValues || {}

    const data = {
      draft_signature_id: existingSignatureId,
      lang: form.getFieldValue()?.lang,
      phone_number: appendPrefix(phone_number),
      name,
    }

    if (signatureImageData) {
      const file = await dataUrlToFile(signatureImageData, 'signature.png')

      const signatureRequestData = {
        url: 'signature',
        data: {
          media_type: 'draft_signature',
          file,
        },
      }

      const signatureResponse = await uploadFile(signatureRequestData)
      data.draft_signature_id = signatureResponse?.data?.id
      setExistingSignatureId(signatureResponse?.data?.id)
    }
    if (isWorker()) {
      data['skills'] = user?.skills?.map((skill) => skill?.id)
      data['is_team_lead'] = user?.is_team_lead
    }

    const res = await uploadProfilePicture(profile_picture)
    data['avatar_id'] = res
    changeUserDetail(data)
  }

  const signatureDeleteHandler = () => {
    setSignatureImageData('')
    setDraftSignatureSrc(null)
    setExistingSignatureId(null)

    if (draftSignatureSrc) {
      setIsSignatureChanged(true)
    } else {
      setIsSignatureChanged(false)
    }
  }

  async function uploadProfilePicture(fileList) {
    if (!fileList?.length) return null

    if (fileList?.length && !fileList?.[0]?.originFileObj) return

    const response = await uploadFile({
      url: 'user/avatar',
      data: {
        file: fileList?.[0]?.originFileObj,
      },
    })
    return response?.data?.id
  }

  function getProfilePictureList() {
    let temp = []

    const profileSrc = getProfilePictureSrc(user)

    if (profileSrc) {
      temp.push({
        uid: user.avatar.id,
        name: t('profile_picture'),
        status: 'done',
        url: profileSrc,
      })
    }
    return temp
  }

  const setSignatureImageDataHandler = (data) => {
    setFormValues((prev) => {
      return { ...prev, draft_signature: data }
    })
    setSignatureImageData(data)
  }

  return {
    setSignatureImageData: setSignatureImageDataHandler,
    roleValue: translateRole(user?.roles?.[0]?.name),
    fetchErrors: {
      ...fetchErrors,
      profile_picture: handleValidationError(uploadError)?.file,
    },
    isFormChanged: detectChangesHandler(),
    isLoading: isUpdating || isUploading,
    setChangePasswordModal,
    signatureDeleteHandler,
    setShowSignatureModal,
    setIsSignatureChanged,
    getProfilePictureList,
    detectChangesHandler,
    setDraftSignatureSrc,
    changePasswordModal,
    showSignatureModal,
    signatureImageData,
    draftSignatureSrc,
    formChangeHandler,
    initialValues,
    submitHandler,
    cancelHandler,
    formValues,
    user,
    form,
    t,
  }
}
