import { useImportCvsModal } from './useImportCvsModal'
import ErrorModal from '../ErrorModal/ErrorModal'
import { Typography, Button, Form } from 'antd'
import CsvButton from '../CsvButton/CsvButton'
import CustomModal from '../Modal/CustomModal'
import { uploadCloud } from 'src/assets'

const ImportCvsModal = ({
  show,
  closeModal,
  submitModal = () => {},
  disabled = false,
  importError,
  setImportError,
}) => {
  const {
    CustomModalCloseHandler,
    ErrorModalCloseHandler,
    addiTionalConfig,
    finishHandler,
    fileName,
    Dragger,
    form,
    t,
  } = useImportCvsModal(submitModal, setImportError, closeModal)

  return (
    <CustomModal
      additionalConfiguration={addiTionalConfig}
      close={CustomModalCloseHandler}
      show={show}
    >
      <Typography.Title level={3}>Import</Typography.Title>
      <Form
        form={form}
        onFinish={finishHandler}
        className='ImportCvsModal__content'
      >
        <Form.Item name='file' getValueFromEvent={(value) => value?.fileList}>
          <Dragger
            accept='.xlsx, .xls, .csv'
            className='ImportCvsModal__dragger'
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
          >
            <img
              src={uploadCloud}
              className='ImportCvsModal__image'
              alt='upload'
            />
            <p className='ImportCvsModal__text'>
              {t('import_csv_file_text', { fileSize: 15 })}
            </p>
            <Button type='secondary' className='ImportCvsModal__button'>
              {t('browse')}
            </Button>
          </Dragger>
        </Form.Item>
        <div className='modalContent__button project_modal'>
          <Button
            className='modalContent__button__child'
            type='secondary'
            onClick={closeModal}
          >
            {t('cancel')}
          </Button>
          <CsvButton text={t('import')} htmlType='submit' disabled={disabled} />
        </div>
      </Form>
      <ErrorModal
        close={ErrorModalCloseHandler}
        importError={importError}
        fileName={fileName}
        show={importError}
      />
    </CustomModal>
  )
}

export default ImportCvsModal
