import { getLibraryDocumentsRequest, deleteDocumentRequest } from 'src/services'
import { usePageQueryParams, useGetUserInfo } from 'src/hooks'
import { useQuery, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { message } from 'antd'

const useDocumentLibrary = () => {
  const [addDocumentModal, setAddDocumentModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: {},
  })

  const { t } = useTranslation()
  const { page, handlePageChange, size } = usePageQueryParams()
  const queryClient = useQueryClient()

  const { user } = useGetUserInfo()

  const {
    data,
    isLoading: isDocumentsLoading,
    isPreviousData,
  } = useQuery(
    ['document-library', page],
    () =>
      getLibraryDocumentsRequest({
        page,
        companyId: user?.company_id,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { mutate: deleteDocumentMutation, isLoading: isDeleting } = useMutation(
    () => deleteDocumentRequest(deleteModal.data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('document-library')
        message.success(t('document_deleted_successfully'))
        setDeleteModal(false)
      },
    }
  )

  return {
    isDataLoading: isDocumentsLoading || isPreviousData,
    documentsList: data?.data?.data,
    deleteDocumentMutation,
    setAddDocumentModal,
    addDocumentModal,
    handlePageChange,
    setDeleteModal,
    deleteModal,
    isDeleting,
    page,
    t,
    paginationInfo: {
      total: data?.data?.meta?.total,
      page,
    },
  }
}

export default useDocumentLibrary
