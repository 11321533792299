import { Form, Typography, Button } from 'antd'
import { useAddInspectionModal } from './hooks'

/* components */
import MoveAddInspectionWizard from './MoveAddInspectionWizard'
import { CustomModal, AgreeDeleteModal } from 'src/common'
import StartAndInspectionOfRoof from './StartAndInspectionOfRoof'
import Accessibility from './Accessibility'
import ConvertersAndDC from './ConvertersAndDC'
import AcTraject from './AcTraject'
import Monitoring from './Monitoring'
import Fusebox from './Fusebox'
import CrawlSpace from './CrawlSpace'
import ComplexityAndComments from './ComplexityAndComments'
import Safety from './Safety'

const AddInspectionModal = ({
  show = false,
  wizardPage = 0,
  closeModal = () => {},
  previousClick = () => {},
  error = {},
  loading = false,
  onNextClick = () => {},
  initialValues = null,
  isDeleteConfirmationOpen = false,
  closeDeleteConfirmation = () => {},
  openDeleteConfirmation = () => {},
  deleteInspection = () => {},
  isDeleting = false,
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
  project = {},
  setUploadPictureErrors = () => {},
  uploadPictureErrors = {},
  isInspectionPictureDeleting,
}) => {
  const {
    additionalConfiguration,
    inspectionCompanyLogo,
    formInitialValues,
    form,
    t,
  } = useAddInspectionModal(initialValues, project)

  return (
    <>
      <CustomModal
        additionalConfiguration={additionalConfiguration}
        close={closeModal}
        show={show}
      >
        {inspectionCompanyLogo && wizardPage === 0 && (
          <img
            alt={`${project?.company?.name} logo`}
            className='inspection-company-logo'
            src={inspectionCompanyLogo}
          />
        )}

        <Typography.Title level={3} className='capitalize_text'>
          {t('inspection')}
        </Typography.Title>

        <p className='wizardPosition'>
          <span>0{wizardPage + 1}</span>/09
        </p>

        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ overflow: 'hidden', textTransform: 'none' }}
          form={form}
          className='formContent formContent_inspection add-inspection-form'
          initialValues={formInitialValues}
        >
          {wizardPage === 0 && (
            <StartAndInspectionOfRoof
              setFieldsValue={form.setFieldsValue}
              getFieldsValue={form.getFieldValue}
              error={error}
              getStepFiles={getStepFiles}
              deleteInspectionImage={deleteInspectionImage}
              project={project}
            />
          )}

          {wizardPage === 1 && (
            <Accessibility
              deleteInspectionImage={deleteInspectionImage}
              error={{ ...error, ...uploadPictureErrors }}
              setFieldsValue={form.setFieldsValue}
              getStepFiles={getStepFiles}
              setUploadPictureErrors={setUploadPictureErrors}
            />
          )}

          {wizardPage === 2 && (
            <ConvertersAndDC
              getStepFiles={getStepFiles}
              setFieldsValue={form.setFieldsValue}
              error={{ ...error, ...uploadPictureErrors }}
              deleteInspectionImage={deleteInspectionImage}
            />
          )}

          {wizardPage === 3 && (
            <AcTraject
              getStepFiles={getStepFiles}
              setFieldsValue={form.setFieldsValue}
              error={error}
              deleteInspectionImage={deleteInspectionImage}
            />
          )}

          {wizardPage === 4 && <Monitoring error={error} />}

          {wizardPage === 5 && (
            <Fusebox
              setFieldsValue={form.setFieldsValue}
              getStepFiles={getStepFiles}
              error={{ ...error, ...uploadPictureErrors }}
              deleteInspectionImage={deleteInspectionImage}
            />
          )}

          {wizardPage === 6 && (
            <CrawlSpace
              getStepFiles={getStepFiles}
              setFieldsValue={form.setFieldsValue}
              error={error}
              deleteInspectionImage={deleteInspectionImage}
            />
          )}

          {wizardPage === 7 && (
            <ComplexityAndComments
              getStepFiles={getStepFiles}
              setFieldsValue={form.setFieldsValue}
              error={error}
              deleteInspectionImage={deleteInspectionImage}
            />
          )}

          {wizardPage === 8 && (
            <Safety setFieldsValue={form.setFieldsValue} error={error} />
          )}

          <div className='modalContent__button project_modal add_inspection_button'>
            <div>
              <Button type='secondary' onClick={closeModal}>
                {t('cancel')}
              </Button>
              {initialValues ? (
                <Button
                  X
                  type='danger'
                  style={{ marginLeft: '0.3rem' }}
                  onClick={openDeleteConfirmation}
                >
                  {t('delete')}
                </Button>
              ) : null}
            </div>

            <MoveAddInspectionWizard
              wizardPage={wizardPage}
              onPreviousClick={previousClick}
              onNextClick={() => onNextClick(form.getFieldsValue())}
              disabled={loading || isInspectionPictureDeleting}
            />
          </div>
        </Form>

        {isDeleteConfirmationOpen && (
          <AgreeDeleteModal
            disabled={isDeleting}
            onSubmit={deleteInspection}
            onCancel={closeDeleteConfirmation}
            visible={isDeleteConfirmationOpen}
          />
        )}
      </CustomModal>
    </>
  )
}

export default AddInspectionModal
