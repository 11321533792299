import { useTranslation } from 'react-i18next'
import { ButtonWithPlus } from 'src/common'

const AddInputs = ({ onClick = () => {}, disabled = false }) => {
  const { t } = useTranslation()
  return (
    <div className='bottombuttons'>
      <ButtonWithPlus
        onClick={onClick}
        text={t('add_mounting_system')}
        type='secondary'
        disabled={disabled}
      />
    </div>
  )
}

export default AddInputs
