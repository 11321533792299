import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useState } from 'react'
import { useGetProductBrand } from 'src/hooks'
import { allPanelsRequest, attachPanelToProjectRequest } from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  appendCompanyId,
  isBottom,
  flatPaginatedData,
} from 'src/utils'

export function useAttachPanelToProject({ data, clearForm, projectCompanyId }) {
  const { t } = useTranslation()
  const { md, sm } = useBreakpoint()
  const layout = {
    labelCol: { span: !md ? 8 : !sm ? 7 : 5 },
    wrapperCol: { span: 20 },
  }
  const [brandId, setBrandId] = useState(data?.panel?.brand_id)
  const [panel, setPanel] = useState(data?.panel)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { offerId } = useParams()

  const queryClient = useQueryClient()

  const { productBrands, fetchBrandOnScroll } = useGetProductBrand({
    queryKey: 'panelBrands',
    url: `api/brands?classification=1&project=1${appendCompanyId(
      projectCompanyId
    )}`,
  })

  const {
    data: panels,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['panels', brandId, projectCompanyId],
    ({ pageParam = 1 }) =>
      allPanelsRequest({
        page: pageParam,
        companyId: projectCompanyId,
        brandId,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
      enabled: Boolean(brandId) && Boolean(projectCompanyId),
    }
  )

  const {
    mutate: addPanelToProject,
    isLoading: isAdding,
    error: addIngError,
  } = useMutation(attachPanelToProjectRequest, {
    onSuccess: (resp) => {
      if (resp?.data?.success) {
        queryClient.invalidateQueries(['offer', offerId])
        clearForm()
      }
    },
    onError: (err) => handleNonValidationError(err),
  })

  function attachPanelToProject(values) {
    if (!canManageProject()) return

    return addPanelToProject({
      offer_id: offerId,
      brand_id: brandId,
      panel_id: panel?.id,
      ...values,
    })
  }

  function closeConfirmationModal() {
    setShowConfirmationModal(false)
  }

  function openConfirmationModal() {
    setShowConfirmationModal(true)
  }

  function fetchPanelOnScroll(e) {
    if (!isBottom(e) || !hasNextPage) return

    fetchNextPage()
  }

  function handlePanelChange(value) {
    let tempPanels = flatPaginatedData(panels)
    setPanel(tempPanels?.find((panel) => panel?.type === value))
  }

  return {
    attachPanelToProject,
    panelBrands: productBrands,
    panels: panels?.pages,
    fetchPanelOnScroll,
    fetchBrandOnScroll,
    handlePanelChange,
    panel,
    isAdding,
    brandId,
    setBrandId,
    setPanel,
    error: handleValidationError(addIngError),
    layout,
    translate: t,
    showConfirmationModal,
    closeConfirmationModal,
    openConfirmationModal,
  }
}
