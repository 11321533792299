export const TeamWorkIcon = () => {
  return (
    <svg width='90' height='88' viewBox='0 0 90 88' fill='none'>
      <path
        d='M44.1727 55.132C46.7772 55.132 48.9 57.2398 48.9 59.8594C48.9 62.479 46.7923 64.5867 44.1727 64.5867C41.553 64.5867 39.4453 62.479 39.4453 59.8594C39.4453 57.2398 41.553 55.132 44.1727 55.132Z'
        fill='#D0EAFE'
      />
      <path
        d='M70.0668 60.4617C80.5753 60.4617 89.1117 68.9981 89.1117 79.4916C89.1117 82.0811 88.66 84.3695 87.7417 86.6428H52.5575C51.6392 84.505 51.1875 82.066 51.1875 79.4916C51.1875 68.983 59.7088 60.4617 70.0668 60.4617C70.1671 60.4617 70.1671 60.4617 70.0668 60.4617Z'
        fill='white'
      />
      <path
        d='M20.1152 60.4617C30.4732 60.4617 38.9945 68.9981 38.9945 79.341C38.9945 81.9305 38.5428 84.2189 37.6245 86.4923H2.44034C1.52197 84.3544 1.07031 81.9305 1.07031 79.341C1.07031 68.983 9.60664 60.4617 20.1001 60.4617H20.1152Z'
        fill='white'
      />
      <path
        d='M70.0672 60.4617L74.9451 72.3403L70.0672 77.0526L65.3398 72.3403L70.0672 60.4617Z'
        fill='#D0EAFE'
      />
      <path
        d='M20.1123 60.4617L24.8396 72.3403L20.1123 77.0526L15.2344 72.3403L20.1123 60.4617Z'
        fill='#D0EAFE'
      />
      <path
        d='M70.0685 41.4167C75.2476 41.4167 79.5082 45.6773 79.5082 50.8563C79.5082 56.0353 75.2476 60.296 70.0685 60.296C64.8895 60.296 60.6289 56.0353 60.6289 50.8563C60.6289 45.6773 64.8895 41.4167 70.0685 41.4167Z'
        fill='#D0EAFE'
      />
      <path
        d='M20.1154 41.4167C25.2944 41.4167 29.555 45.6773 29.555 50.8563C29.555 56.0353 25.2944 60.296 20.1154 60.296C14.9364 60.296 10.6757 56.0353 10.6757 50.8563C10.5252 45.6773 14.7858 41.4167 20.1154 41.4167Z'
        fill='#D0EAFE'
      />
      <path
        d='M88.9481 5.47945V28.785C88.9481 31.3745 86.9608 33.3467 84.3713 33.3467H54.6673L45.0771 45.677L35.4869 33.3467H5.94846C3.35896 33.3467 1.38672 31.3745 1.38672 28.785V5.47945C1.38672 2.88994 3.35896 0.902649 5.94846 0.902649H84.3863C86.8253 0.902649 88.9631 2.88994 88.9631 5.47945H88.9481Z'
        fill='#D0EAFE'
      />
      <path
        d='M72.657 6.54871C78.4382 6.54871 83.1656 11.2761 83.1656 17.0573C83.1656 22.8385 78.4382 27.5658 72.657 27.5658C66.8758 27.5658 62.1484 22.8385 62.1484 17.0573C62.1484 11.2761 66.8758 6.54871 72.657 6.54871Z'
        fill='white'
      />
      <path
        d='M17.5242 6.54871C23.3054 6.54871 28.0328 11.2761 28.0328 17.0573C28.0328 22.8385 23.3054 27.5658 17.5242 27.5658C11.743 27.5658 7.01562 22.8385 7.01562 17.0573C7.01562 11.2761 11.743 6.54871 17.5242 6.54871Z'
        fill='white'
      />
      <path
        d='M20.114 61.3651C14.3328 61.3651 9.60547 56.6377 9.60547 50.8565C9.60547 45.0753 14.3328 40.348 20.114 40.348C25.8953 40.348 30.6226 45.0753 30.6226 50.8565C30.6226 56.6377 25.7447 61.3651 20.114 61.3651ZM20.114 42.3353C15.3867 42.3353 11.5928 46.1442 11.5928 50.8716C11.5928 55.5989 15.4017 59.4079 20.114 59.4079C24.8263 59.4079 28.6353 55.5989 28.6353 50.8716C28.6353 46.1442 24.6758 42.3353 20.114 42.3353Z'
        fill='#63AFF4'
      />
      <path
        d='M10.5238 87.5614C10.0722 87.5614 9.60547 87.1097 9.60547 86.643V79.4917C9.60547 79.0401 10.0571 78.5734 10.5238 78.5734C10.9906 78.5734 11.4422 79.025 11.4422 79.4917V86.643C11.4422 87.0946 11.1411 87.5614 10.5238 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M29.5551 87.5614C29.1034 87.5614 28.6367 87.1097 28.6367 86.643V79.4917C28.6367 79.0401 29.0884 78.5734 29.5551 78.5734C30.0218 78.5734 30.4735 79.025 30.4735 79.4917V86.643C30.4735 87.0946 30.0218 87.5614 29.5551 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M20.1124 77.9711C19.8113 77.9711 19.6607 77.8206 19.5102 77.67L14.6323 72.9427C14.3312 72.6416 14.3312 72.3405 14.4817 71.8737L19.2091 59.9952C19.3596 59.6941 19.6607 59.3929 20.1274 59.3929C20.5941 59.3929 20.8952 59.6941 21.0458 59.9952L25.7731 71.8737C25.9237 72.1749 25.7731 72.6416 25.6226 72.9427L20.7447 77.67C20.5941 77.9711 20.293 77.9711 20.1425 77.9711H20.1124ZM16.4539 72.0243L20.1124 75.6827L23.7708 72.0243L20.1124 62.8858L16.4539 72.0243Z'
        fill='#63AFF4'
      />
      <path
        d='M37.6231 87.5611H2.43895C1.98729 87.5611 1.67113 87.26 1.52058 86.9589C0.60221 84.5199 0 82.081 0 79.4915C0 68.5313 8.98799 59.5433 19.9482 59.5433C30.9084 59.5433 39.8964 68.5313 39.8964 79.4915C39.8964 82.081 39.4448 84.5199 38.3758 86.9589C38.3758 87.26 38.0747 87.5611 37.608 87.5611H37.6231ZM3.05622 85.5889H37.0209C37.7887 83.6016 38.0898 81.4788 38.0898 79.4915C38.0898 69.5851 30.0202 61.5155 20.1138 61.5155C10.2075 61.5155 2.13785 69.5851 2.13785 79.4915C1.98729 81.4788 2.43895 83.6016 3.05622 85.5889Z'
        fill='#63AFF4'
      />
      <path
        d='M70.0672 61.3651C64.2859 61.3651 59.5586 56.6377 59.5586 50.8565C59.5586 45.0753 64.2859 40.348 70.0672 40.348C75.8484 40.348 80.5757 45.0753 80.5757 50.8565C80.5757 56.6377 75.8484 61.3651 70.0672 61.3651ZM70.0672 42.3353C65.3398 42.3353 61.5308 46.1442 61.5308 50.8716C61.5308 55.5989 65.3398 59.4079 70.0672 59.4079C74.7945 59.4079 78.5884 55.5989 78.5884 50.8716C78.5884 46.1442 74.7794 42.3353 70.0672 42.3353Z'
        fill='#63AFF4'
      />
      <path
        d='M60.6293 87.5614C60.1777 87.5614 59.7109 87.1097 59.7109 86.643V79.4917C59.7109 79.0401 60.1626 78.5734 60.6293 78.5734C61.096 78.5734 61.5477 79.025 61.5477 79.4917V86.643C61.5477 87.0946 61.2466 87.5614 60.6293 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M79.6567 87.5614C79.205 87.5614 78.7383 87.1097 78.7383 86.643V79.4917C78.7383 79.0401 79.1899 78.5734 79.6567 78.5734C80.1234 78.5734 80.575 79.025 80.575 79.4917V86.643C80.575 87.0946 80.1234 87.5614 79.6567 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M70.0673 77.9711C69.7662 77.9711 69.6156 77.8206 69.4651 77.67L64.7377 72.9427C64.4366 72.6416 64.4366 72.3405 64.5872 71.8737L69.3145 59.9952C69.4651 59.6941 69.7662 59.3929 70.2329 59.3929C70.6845 59.3929 71.0007 59.6941 71.1513 59.9952L75.8786 71.8737C76.0292 72.1749 75.8786 72.6416 75.7281 72.9427L71.0007 77.67C70.6996 77.9711 70.3834 77.9711 70.0823 77.9711H70.0673ZM66.5594 72.0243L70.2178 75.6827L73.8763 72.0243L70.2178 62.8858L66.5594 72.0243Z'
        fill='#63AFF4'
      />
      <path
        d='M87.7285 87.5611H52.5444C52.0928 87.5611 51.7766 87.26 51.626 86.9589C50.7077 84.5199 50.1055 82.081 50.1055 79.4915C50.1055 68.5313 59.0935 59.5433 70.0537 59.5433C81.0139 59.5433 90.0019 68.5313 90.0019 79.4915C90.0019 82.081 89.5502 84.5199 88.4813 86.9589C88.4813 87.26 88.1802 87.5611 87.7135 87.5611H87.7285ZM53.1617 85.5889H87.1263C87.8941 83.6016 88.1953 81.4788 88.1953 79.4915C88.1953 69.5851 80.1256 61.5155 70.2193 61.5155C60.3129 61.5155 52.2433 69.5851 52.2433 79.4915C52.0928 81.4788 52.3939 83.6016 53.1617 85.5889Z'
        fill='#63AFF4'
      />
      <path
        d='M45.0886 46.596C44.7875 46.596 44.4864 46.4454 44.3208 46.2949L35.0317 34.2658H5.94495C2.90379 34.2658 0.464844 31.8268 0.464844 28.7856V5.48011C0.464844 2.43895 2.90379 0 5.94495 0H84.3828C87.424 0 89.8629 2.43895 89.8629 5.48011V28.7856C89.8629 31.8268 87.424 34.2658 84.3828 34.2658H55.1455L45.8564 46.2949C45.7059 46.4454 45.4048 46.596 45.0886 46.596ZM5.94495 1.82169C3.95766 1.82169 2.28653 3.49282 2.28653 5.48011V28.7856C2.28653 30.7579 3.95766 32.4441 5.94495 32.4441H35.649C35.9501 32.4441 36.2512 32.5946 36.4168 32.7452L45.2542 44.1721L54.0916 32.7452C54.2422 32.4441 54.5433 32.4441 54.8595 32.4441H84.5635C86.5508 32.4441 88.2219 30.7729 88.2219 28.7856V5.48011C88.2219 3.49282 86.5508 1.82169 84.5635 1.82169H5.94495Z'
        fill='#63AFF4'
      />
      <path
        d='M17.5246 28.4709C11.2767 28.4709 6.09766 23.2919 6.09766 17.044C6.09766 10.7961 11.2767 5.61707 17.5246 5.61707C23.7725 5.61707 28.9515 10.7961 28.9515 17.044C28.9515 23.2919 23.7725 28.4709 17.5246 28.4709ZM17.5246 7.60436C12.3456 7.60436 7.9344 11.865 7.9344 17.1946C7.9344 22.5241 12.195 26.7848 17.5246 26.7848C22.8542 26.7848 27.1148 22.5241 27.1148 17.1946C27.1148 11.865 22.7036 7.60436 17.5246 7.60436Z'
        fill='#63AFF4'
      />
      <path
        d='M72.6574 28.4709C66.4095 28.4709 61.2305 23.2919 61.2305 17.044C61.2305 10.7961 66.4095 5.61707 72.6574 5.61707C78.9053 5.61707 84.0843 10.7961 84.0843 17.044C84.0843 23.2919 79.0559 28.4709 72.6574 28.4709ZM72.6574 7.60436C67.4784 7.60436 63.0672 11.865 63.0672 17.1946C63.0672 22.5241 67.3278 26.7848 72.6574 26.7848C77.987 26.7848 82.2476 22.5241 82.2476 17.1946C82.2476 11.865 77.987 7.60436 72.6574 7.60436Z'
        fill='#63AFF4'
      />
      <path
        d='M70.9856 21.7706C70.6845 21.7706 70.5339 21.62 70.3683 21.4695L67.011 18.1122C66.7099 17.8111 66.7099 17.1938 67.011 16.7421C67.3121 16.2905 67.9294 16.441 68.381 16.7421L71.1211 19.4822L77.2184 13.3848C77.5195 13.0837 78.1368 13.0837 78.5885 13.3848C78.8896 13.6859 78.8896 14.3032 78.5885 14.7549L71.8889 21.4544C71.4372 21.7555 71.2867 21.7555 70.9705 21.7555L70.9856 21.7706Z'
        fill='#63AFF4'
      />
      <path
        d='M13.414 22.0709C13.1129 22.0709 12.9623 21.9203 12.8118 21.7698C12.5107 21.4687 12.5107 20.8514 12.8118 20.3998L20.8814 12.3301C21.1825 12.029 21.7998 12.029 22.2514 12.3301C22.5525 12.6312 22.5525 13.2485 22.2514 13.7002L14.1818 21.7698C13.8807 21.9203 13.7301 22.0709 13.414 22.0709Z'
        fill='#63AFF4'
      />
      <path
        d='M21.4836 22.0712C21.1825 22.0712 21.0319 21.9207 20.8814 21.7701L12.8118 13.7005C12.5107 13.3994 12.5107 12.7821 12.8118 12.3305C13.1129 11.8788 13.7301 12.0294 14.1818 12.3305L22.2514 20.4001C22.5525 20.7012 22.5525 21.3184 22.2514 21.7701C21.9503 21.9207 21.7998 22.0712 21.4836 22.0712Z'
        fill='#63AFF4'
      />
      <path
        d='M44.1718 65.6407C40.9801 65.6407 38.3906 63.0512 38.3906 59.8595C38.3906 56.6678 40.9801 54.0782 44.1718 54.0782C47.3636 54.0782 49.9531 56.6678 49.9531 59.8595C49.9531 63.0512 47.213 65.6407 44.1718 65.6407ZM44.1718 56.0505C42.034 56.0505 40.3629 57.7216 40.3629 59.8595C40.3629 61.9973 42.034 63.6684 44.1718 63.6684C46.3097 63.6684 47.9808 61.9973 47.9808 59.8595C47.9808 57.7216 46.3097 56.0505 44.1718 56.0505Z'
        fill='#63AFF4'
      />
      <path
        d='M62.2974 17.9603H28.0317C27.58 17.9603 27.1133 17.5086 27.1133 17.0419C27.1133 16.5752 27.5649 16.1235 28.0317 16.1235H62.2974C62.7491 16.1235 63.2158 16.5752 63.2158 17.0419C63.2158 17.5086 62.7641 17.9603 62.2974 17.9603Z'
        fill='#63AFF4'
      />
      <path
        d='M44.1727 55.132C46.7772 55.132 48.9 57.2398 48.9 59.8594C48.9 62.479 46.7923 64.5867 44.1727 64.5867C41.553 64.5867 39.4453 62.479 39.4453 59.8594C39.4453 57.2398 41.553 55.132 44.1727 55.132Z'
        fill='#D0EAFE'
      />
      <path
        d='M70.0668 60.4617C80.5753 60.4617 89.1117 68.9981 89.1117 79.4916C89.1117 82.0811 88.66 84.3695 87.7417 86.6428H52.5575C51.6392 84.505 51.1875 82.066 51.1875 79.4916C51.1875 68.983 59.7088 60.4617 70.0668 60.4617C70.1671 60.4617 70.1671 60.4617 70.0668 60.4617Z'
        fill='white'
      />
      <path
        d='M20.1152 60.4617C30.4732 60.4617 38.9945 68.9981 38.9945 79.341C38.9945 81.9305 38.5428 84.2189 37.6245 86.4923H2.44034C1.52197 84.3544 1.07031 81.9305 1.07031 79.341C1.07031 68.983 9.60664 60.4617 20.1001 60.4617H20.1152Z'
        fill='white'
      />
      <path
        d='M70.0672 60.4617L74.9451 72.3403L70.0672 77.0526L65.3398 72.3403L70.0672 60.4617Z'
        fill='#D0EAFE'
      />
      <path
        d='M20.1123 60.4617L24.8396 72.3403L20.1123 77.0526L15.2344 72.3403L20.1123 60.4617Z'
        fill='#D0EAFE'
      />
      <path
        d='M70.0685 41.4167C75.2476 41.4167 79.5082 45.6773 79.5082 50.8563C79.5082 56.0353 75.2476 60.296 70.0685 60.296C64.8895 60.296 60.6289 56.0353 60.6289 50.8563C60.6289 45.6773 64.8895 41.4167 70.0685 41.4167Z'
        fill='#D0EAFE'
      />
      <path
        d='M20.1154 41.4167C25.2944 41.4167 29.555 45.6773 29.555 50.8563C29.555 56.0353 25.2944 60.296 20.1154 60.296C14.9364 60.296 10.6757 56.0353 10.6757 50.8563C10.5252 45.6773 14.7858 41.4167 20.1154 41.4167Z'
        fill='#D0EAFE'
      />
      <path
        d='M88.9481 5.47945V28.785C88.9481 31.3745 86.9608 33.3467 84.3713 33.3467H54.6673L45.0771 45.677L35.4869 33.3467H5.94846C3.35896 33.3467 1.38672 31.3745 1.38672 28.785V5.47945C1.38672 2.88994 3.35896 0.902649 5.94846 0.902649H84.3863C86.8253 0.902649 88.9631 2.88994 88.9631 5.47945H88.9481Z'
        fill='#D0EAFE'
      />
      <path
        d='M72.657 6.54871C78.4382 6.54871 83.1656 11.2761 83.1656 17.0573C83.1656 22.8385 78.4382 27.5658 72.657 27.5658C66.8758 27.5658 62.1484 22.8385 62.1484 17.0573C62.1484 11.2761 66.8758 6.54871 72.657 6.54871Z'
        fill='white'
      />
      <path
        d='M17.5242 6.54871C23.3054 6.54871 28.0328 11.2761 28.0328 17.0573C28.0328 22.8385 23.3054 27.5658 17.5242 27.5658C11.743 27.5658 7.01562 22.8385 7.01562 17.0573C7.01562 11.2761 11.743 6.54871 17.5242 6.54871Z'
        fill='white'
      />
      <path
        d='M20.114 61.3651C14.3328 61.3651 9.60547 56.6377 9.60547 50.8565C9.60547 45.0753 14.3328 40.348 20.114 40.348C25.8953 40.348 30.6226 45.0753 30.6226 50.8565C30.6226 56.6377 25.7447 61.3651 20.114 61.3651ZM20.114 42.3353C15.3867 42.3353 11.5928 46.1442 11.5928 50.8716C11.5928 55.5989 15.4017 59.4079 20.114 59.4079C24.8263 59.4079 28.6353 55.5989 28.6353 50.8716C28.6353 46.1442 24.6758 42.3353 20.114 42.3353Z'
        fill='#63AFF4'
      />
      <path
        d='M10.5238 87.5614C10.0722 87.5614 9.60547 87.1097 9.60547 86.643V79.4917C9.60547 79.0401 10.0571 78.5734 10.5238 78.5734C10.9906 78.5734 11.4422 79.025 11.4422 79.4917V86.643C11.4422 87.0946 11.1411 87.5614 10.5238 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M29.5551 87.5614C29.1034 87.5614 28.6367 87.1097 28.6367 86.643V79.4917C28.6367 79.0401 29.0884 78.5734 29.5551 78.5734C30.0218 78.5734 30.4735 79.025 30.4735 79.4917V86.643C30.4735 87.0946 30.0218 87.5614 29.5551 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M20.1124 77.9711C19.8113 77.9711 19.6607 77.8206 19.5102 77.67L14.6323 72.9427C14.3312 72.6416 14.3312 72.3405 14.4817 71.8737L19.2091 59.9952C19.3596 59.6941 19.6607 59.3929 20.1274 59.3929C20.5941 59.3929 20.8952 59.6941 21.0458 59.9952L25.7731 71.8737C25.9237 72.1749 25.7731 72.6416 25.6226 72.9427L20.7447 77.67C20.5941 77.9711 20.293 77.9711 20.1425 77.9711H20.1124ZM16.4539 72.0243L20.1124 75.6827L23.7708 72.0243L20.1124 62.8858L16.4539 72.0243Z'
        fill='#63AFF4'
      />
      <path
        d='M37.6231 87.5611H2.43895C1.98729 87.5611 1.67113 87.26 1.52058 86.9589C0.60221 84.5199 0 82.081 0 79.4915C0 68.5313 8.98799 59.5433 19.9482 59.5433C30.9084 59.5433 39.8964 68.5313 39.8964 79.4915C39.8964 82.081 39.4448 84.5199 38.3758 86.9589C38.3758 87.26 38.0747 87.5611 37.608 87.5611H37.6231ZM3.05622 85.5889H37.0209C37.7887 83.6016 38.0898 81.4788 38.0898 79.4915C38.0898 69.5851 30.0202 61.5155 20.1138 61.5155C10.2075 61.5155 2.13785 69.5851 2.13785 79.4915C1.98729 81.4788 2.43895 83.6016 3.05622 85.5889Z'
        fill='#63AFF4'
      />
      <path
        d='M70.0672 61.3651C64.2859 61.3651 59.5586 56.6377 59.5586 50.8565C59.5586 45.0753 64.2859 40.348 70.0672 40.348C75.8484 40.348 80.5757 45.0753 80.5757 50.8565C80.5757 56.6377 75.8484 61.3651 70.0672 61.3651ZM70.0672 42.3353C65.3398 42.3353 61.5308 46.1442 61.5308 50.8716C61.5308 55.5989 65.3398 59.4079 70.0672 59.4079C74.7945 59.4079 78.5884 55.5989 78.5884 50.8716C78.5884 46.1442 74.7794 42.3353 70.0672 42.3353Z'
        fill='#63AFF4'
      />
      <path
        d='M60.6293 87.5614C60.1777 87.5614 59.7109 87.1097 59.7109 86.643V79.4917C59.7109 79.0401 60.1626 78.5734 60.6293 78.5734C61.096 78.5734 61.5477 79.025 61.5477 79.4917V86.643C61.5477 87.0946 61.2466 87.5614 60.6293 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M79.6567 87.5614C79.205 87.5614 78.7383 87.1097 78.7383 86.643V79.4917C78.7383 79.0401 79.1899 78.5734 79.6567 78.5734C80.1234 78.5734 80.575 79.025 80.575 79.4917V86.643C80.575 87.0946 80.1234 87.5614 79.6567 87.5614Z'
        fill='#63AFF4'
      />
      <path
        d='M70.0673 77.9711C69.7662 77.9711 69.6156 77.8206 69.4651 77.67L64.7377 72.9427C64.4366 72.6416 64.4366 72.3405 64.5872 71.8737L69.3145 59.9952C69.4651 59.6941 69.7662 59.3929 70.2329 59.3929C70.6845 59.3929 71.0007 59.6941 71.1513 59.9952L75.8786 71.8737C76.0292 72.1749 75.8786 72.6416 75.7281 72.9427L71.0007 77.67C70.6996 77.9711 70.3834 77.9711 70.0823 77.9711H70.0673ZM66.5594 72.0243L70.2178 75.6827L73.8763 72.0243L70.2178 62.8858L66.5594 72.0243Z'
        fill='#63AFF4'
      />
      <path
        d='M87.7285 87.5611H52.5444C52.0928 87.5611 51.7766 87.26 51.626 86.9589C50.7077 84.5199 50.1055 82.081 50.1055 79.4915C50.1055 68.5313 59.0935 59.5433 70.0537 59.5433C81.0139 59.5433 90.0019 68.5313 90.0019 79.4915C90.0019 82.081 89.5502 84.5199 88.4813 86.9589C88.4813 87.26 88.1802 87.5611 87.7135 87.5611H87.7285ZM53.1617 85.5889H87.1263C87.8941 83.6016 88.1953 81.4788 88.1953 79.4915C88.1953 69.5851 80.1256 61.5155 70.2193 61.5155C60.3129 61.5155 52.2433 69.5851 52.2433 79.4915C52.0928 81.4788 52.3939 83.6016 53.1617 85.5889Z'
        fill='#63AFF4'
      />
      <path
        d='M45.0886 46.596C44.7875 46.596 44.4864 46.4454 44.3208 46.2949L35.0317 34.2658H5.94495C2.90379 34.2658 0.464844 31.8268 0.464844 28.7856V5.48011C0.464844 2.43895 2.90379 0 5.94495 0H84.3828C87.424 0 89.8629 2.43895 89.8629 5.48011V28.7856C89.8629 31.8268 87.424 34.2658 84.3828 34.2658H55.1455L45.8564 46.2949C45.7059 46.4454 45.4048 46.596 45.0886 46.596ZM5.94495 1.82169C3.95766 1.82169 2.28653 3.49282 2.28653 5.48011V28.7856C2.28653 30.7579 3.95766 32.4441 5.94495 32.4441H35.649C35.9501 32.4441 36.2512 32.5946 36.4168 32.7452L45.2542 44.1721L54.0916 32.7452C54.2422 32.4441 54.5433 32.4441 54.8595 32.4441H84.5635C86.5508 32.4441 88.2219 30.7729 88.2219 28.7856V5.48011C88.2219 3.49282 86.5508 1.82169 84.5635 1.82169H5.94495Z'
        fill='#63AFF4'
      />
      <path
        d='M17.5246 28.4709C11.2767 28.4709 6.09766 23.2919 6.09766 17.044C6.09766 10.7961 11.2767 5.61707 17.5246 5.61707C23.7725 5.61707 28.9515 10.7961 28.9515 17.044C28.9515 23.2919 23.7725 28.4709 17.5246 28.4709ZM17.5246 7.60436C12.3456 7.60436 7.9344 11.865 7.9344 17.1946C7.9344 22.5241 12.195 26.7848 17.5246 26.7848C22.8542 26.7848 27.1148 22.5241 27.1148 17.1946C27.1148 11.865 22.7036 7.60436 17.5246 7.60436Z'
        fill='#63AFF4'
      />
      <path
        d='M72.6574 28.4709C66.4095 28.4709 61.2305 23.2919 61.2305 17.044C61.2305 10.7961 66.4095 5.61707 72.6574 5.61707C78.9053 5.61707 84.0843 10.7961 84.0843 17.044C84.0843 23.2919 79.0559 28.4709 72.6574 28.4709ZM72.6574 7.60436C67.4784 7.60436 63.0672 11.865 63.0672 17.1946C63.0672 22.5241 67.3278 26.7848 72.6574 26.7848C77.987 26.7848 82.2476 22.5241 82.2476 17.1946C82.2476 11.865 77.987 7.60436 72.6574 7.60436Z'
        fill='#63AFF4'
      />
      <path
        d='M70.9856 21.7706C70.6845 21.7706 70.5339 21.62 70.3683 21.4695L67.011 18.1122C66.7099 17.8111 66.7099 17.1938 67.011 16.7421C67.3121 16.2905 67.9294 16.441 68.381 16.7421L71.1211 19.4822L77.2184 13.3848C77.5195 13.0837 78.1368 13.0837 78.5885 13.3848C78.8896 13.6859 78.8896 14.3032 78.5885 14.7549L71.8889 21.4544C71.4372 21.7555 71.2867 21.7555 70.9705 21.7555L70.9856 21.7706Z'
        fill='#63AFF4'
      />
      <path
        d='M13.414 22.0709C13.1129 22.0709 12.9623 21.9203 12.8118 21.7698C12.5107 21.4687 12.5107 20.8514 12.8118 20.3998L20.8814 12.3301C21.1825 12.029 21.7998 12.029 22.2514 12.3301C22.5525 12.6312 22.5525 13.2485 22.2514 13.7002L14.1818 21.7698C13.8807 21.9203 13.7301 22.0709 13.414 22.0709Z'
        fill='#63AFF4'
      />
      <path
        d='M21.4836 22.0712C21.1825 22.0712 21.0319 21.9207 20.8814 21.7701L12.8118 13.7005C12.5107 13.3994 12.5107 12.7821 12.8118 12.3305C13.1129 11.8788 13.7301 12.0294 14.1818 12.3305L22.2514 20.4001C22.5525 20.7012 22.5525 21.3184 22.2514 21.7701C21.9503 21.9207 21.7998 22.0712 21.4836 22.0712Z'
        fill='#63AFF4'
      />
      <path
        d='M44.1718 65.6407C40.9801 65.6407 38.3906 63.0512 38.3906 59.8595C38.3906 56.6678 40.9801 54.0782 44.1718 54.0782C47.3636 54.0782 49.9531 56.6678 49.9531 59.8595C49.9531 63.0512 47.213 65.6407 44.1718 65.6407ZM44.1718 56.0505C42.034 56.0505 40.3629 57.7216 40.3629 59.8595C40.3629 61.9973 42.034 63.6684 44.1718 63.6684C46.3097 63.6684 47.9808 61.9973 47.9808 59.8595C47.9808 57.7216 46.3097 56.0505 44.1718 56.0505Z'
        fill='#63AFF4'
      />
      <path
        d='M62.2974 17.9603H28.0317C27.58 17.9603 27.1133 17.5086 27.1133 17.0419C27.1133 16.5752 27.5649 16.1235 28.0317 16.1235H62.2974C62.7491 16.1235 63.2158 16.5752 63.2158 17.0419C63.2158 17.5086 62.7641 17.9603 62.2974 17.9603Z'
        fill='#63AFF4'
      />
    </svg>
  )
}
