import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ path, exact, name, render }) => {
  const { token } = useSelector((state) => state.globalSlice)

  if (token) {
    return <Route path={path} exact={exact} name={name} render={render} />
  }

  return <Redirect to='/auth/login' />
}

export default PrivateRoute
