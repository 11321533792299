import ActionButtonGroup from './ActionButtonGroup'
import ClientInformation from './ClientInformation'
import ProjectDetails from './ProjectDetails'

const ProjectCreationForm = ({
  getAddressOnInitialRender,
  mutationErrors = {},
  projectCondition,
  projectCompanyId,
  disableSubmit,
  project,
  form,
}) => {
  return (
    <div className='project__creation__form__container'>
      <ClientInformation
        getAddressOnInitialRender={getAddressOnInitialRender}
        mutationErrors={mutationErrors}
        form={form}
      />
      <ProjectDetails
        projectCondition={projectCondition}
        projectCompanyId={projectCompanyId}
        mutationErrors={mutationErrors}
        project={project}
        form={form}
      />
      <ActionButtonGroup disableSubmit={disableSubmit} form={form} />
    </div>
  )
}

export default ProjectCreationForm
