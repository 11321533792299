import { AiOutlinePlus } from 'react-icons/ai'
import { Button } from 'antd'

const ButtonWithPlus = ({ text, type = 'primary', ...props }) => {
  return (
    <>
      <Button
        className={`alignButtonIcon ${props?.className}`}
        type={type}
        {...props}
      >
        <AiOutlinePlus size={props?.size || 16} />
        {text}
      </Button>
    </>
  )
}

export default ButtonWithPlus
