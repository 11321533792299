import { initialLoginFromValues } from 'src/screens/Login/components/MainLoginContent/constants/initialLoginFormValues'
import { handleNonValidationError, handleValidationError } from 'src/utils'
import { loginRequest, notificationTokenRequest } from 'src/services'
import { useMutation, useQueryClient } from 'react-query'
import { getFirebaseToken } from 'src/firebase'
import { useDispatch } from 'react-redux'
import { changeLanguage } from 'i18next'
import { setUser } from 'src/actions'

export function useLogin() {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { mutate, isLoading, error } = useMutation(loginRequest, {
    onSuccess: async (resp) => {
      if (resp?.data?.success) {
        dispatch(setUser(resp.data))
        queryClient.clear()
        changeLanguage(resp.data?.user?.lang)
        await sendNotificationToken()
      }
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const { mutate: sendNotificationTokenMutation, isLoading: isTokenSending } =
    useMutation((token) => notificationTokenRequest({ token }))

  function login(values) {
    mutate({
      email: values.email,
      password: values.password,
    })
  }

  async function sendNotificationToken() {
    const token = await getFirebaseToken()
    if (!token) return
    sendNotificationTokenMutation(token)
  }

  return {
    login,
    mutate,
    isLoading: isLoading || isTokenSending,
    loginError: handleValidationError(error),
    initialValues: initialLoginFromValues,
  }
}
