import { useClearHomePageQuery, useHandleQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const useTagsList = ({ accountManagersForm, statusFiltersForm }) => {
  const [showAllTagsModal, setShowAllTagsModal] = useState(false)

  const { clearPageQuery } = useClearHomePageQuery()
  const { deleteQueryParams } = useHandleQueryParams()
  const { t } = useTranslation()

  const clearAllHandler = () => {
    clearPageQuery()
    deleteQueryParams()
    statusFiltersForm.resetFields()
    accountManagersForm.resetFields()
  }

  return { t, clearAllHandler, showAllTagsModal, setShowAllTagsModal }
}
