import { createSlice } from '@reduxjs/toolkit'

const token = localStorage.getItem('token-esc')
const user = localStorage.getItem('user')
const permissions = localStorage.getItem('permissions')

const initialState = {
  sideNavigation: { open: true },
  token: token ? token : '',
  user: user ? JSON.parse(user) : {},
  permissions: permissions ? JSON.parse(permissions) : [],
}

const globalSclie = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setSideNavigationOpen: (state, action) => {
      state.sideNavigation.open = action.payload
    },
    setUser: (state, action) => {
      state.token = action.payload.token
      state.user = action.payload.user

      const permissions = action.payload?.permissions?.map(
        (permission) => permission?.name
      )

      state.permissions = permissions

      localStorage.setItem('token-esc', action.payload.token)
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('permissions', JSON.stringify(permissions))
    },
    setRemoveUser: (state) => {
      state.token = ''
      state.user = ''
      state.permissions = []

      localStorage.setItem('token-esc', '')
      localStorage.setItem('user', '')
      localStorage.setItem('permissions', '')
    },
    setUpdateUser: (state, action) => {
      state.user = action?.payload

      localStorage.setItem('user', JSON.stringify(action?.payload))
    },
  },
})

export const { setSideNavigationOpen, setUser, setRemoveUser, setUpdateUser } =
  globalSclie.actions

export default globalSclie.reducer
