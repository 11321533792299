import { useQueryParams } from 'src/hooks/useQueryParams'
import { resetPasswordRequest } from 'src/services'
import { handleValidationError } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

export const useResetPassword = () => {
  const { mutate, isLoading, data, error } = useMutation(resetPasswordRequest)
  const { t } = useTranslation()
  const query = useQueryParams()
  const token = query.get('token')

  const handleResetEmail = (values) => {
    if (!values) return
    mutate({
      passwordToken: token,
      password: values.enterPassword,
      password_confirmation: values.repeatPassword,
    })
  }

  const validationErrors = handleValidationError(error)

  return {
    repeatPasswordErrors: validationErrors?.password_confirmation?.[0],
    newPasswordErrors: validationErrors?.password?.[0],
    handleResetEmail,
    isLoading,
    token,
    data,
    t,
  }
}
