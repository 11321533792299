import { useTranslation } from 'react-i18next'
import { Upload, Form } from 'antd'

export const useImportCvsModal = (submitModal, setImportError, closeModal) => {
  const { Dragger } = Upload
  const [form] = Form.useForm()
  const filePath = form.getFieldValue()?.file
  const fileName = filePath && filePath[0]?.name
  const { t } = useTranslation()

  const addiTionalConfig = {
    className: 'ImportCvsModal',
  }

  const finishHandler = (val) => {
    if (!val?.file?.length) return
    const formData = new FormData()
    formData.append('file', val.file[0]?.originFileObj)
    submitModal(formData)
  }

  const ErrorModalCloseHandler = () => {
    setImportError(false)
  }

  const CustomModalCloseHandler = () => {
    closeModal()
  }

  return {
    CustomModalCloseHandler,
    ErrorModalCloseHandler,
    addiTionalConfig,
    finishHandler,
    fileName,
    Dragger,
    form,
    t,
  }
}
