import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnClickOutside } from 'src/hooks'

function useTaskPopup(onClose) {
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false)
  const taskInfoRef = useRef(null)

  useOnClickOutside(taskInfoRef, onClose)

  function handleDeleteConfirmationModalOpen() {
    setIsDeleteConfirmationModalVisible(true)
  }

  function handleDeleteConfirmationModalClose() {
    setIsDeleteConfirmationModalVisible(false)
  }

  const { t } = useTranslation()
  return {
    handleDeleteConfirmationModalClose,
    handleDeleteConfirmationModalOpen,
    isDeleteConfirmationModalVisible,
    taskInfoRef,
    t,
  }
}

export { useTaskPopup }
