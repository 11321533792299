import { useTranslation } from 'react-i18next'
import AddressSection from './AddressSection'
import ContactSection from './ContactSection'
import TypeSection from './TypeSection'
import { useState } from 'react'

const ClientInformation = ({
  getAddressOnInitialRender,
  mutationErrors,
  form,
}) => {
  const [currentType, setCurrentTpe] = useState(() =>
    form.getFieldValue('type')
  )
  const { t } = useTranslation()

  return (
    <div className='client__information__container'>
      <h3 className='section__title'>{t('client_information')}</h3>

      <TypeSection
        form={form}
        currentType={currentType}
        setCurrentTpe={setCurrentTpe}
      />
      <ContactSection
        mutationErrors={mutationErrors}
        currentType={currentType}
      />
      <AddressSection
        getAddressOnInitialRender={getAddressOnInitialRender}
        mutationErrors={mutationErrors}
        form={form}
      />
    </div>
  )
}

export default ClientInformation
