import { Form, Input, Checkbox, Typography, Select } from 'antd'
import { CustomUpload, ShowFieldError } from 'src/common'
import { useAccessibility } from './hooks'
const { Option } = Select

const Accessibility = ({
  setFieldsValue = () => {},
  error = {},
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
  setUploadPictureErrors,
}) => {
  const { t, checkBoxChangeHandler, uploadChangeHandler, fileList } =
    useAccessibility(getStepFiles, setFieldsValue, setUploadPictureErrors)

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('accessibility')}
      </Typography.Title>

      <Form.Item required label={t('parking_available')}>
        <Form.Item name='parking_available' noStyle>
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.parking_available} />
      </Form.Item>

      <Form.Item
        className={error?.reached ? 'group-label-error' : ''}
        label={<div>{t('reached_checkbox_title')}</div>}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        required
      >
        <Form.Item name='reached' noStyle>
          <Checkbox.Group onChange={checkBoxChangeHandler}>
            <Checkbox className='wizards_checkbox_space' value={8}>
              {t('mobile_scaffold')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={9}>
              {t('moving_elevator')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={10}>
              {t('mini_scaffolding')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={11}>
              {t('aerial_work_platform_telescope')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={12}>
              {t('telehandler')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={13}>
              {t('Scissor_lift')}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.reached} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        label={t('extra_information_accessibility')}
      >
        <Form.Item name='extra_information_accessibility' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
        <p className='inspection_wizard_form__instruction'>
          {t('extra_information_accessibility_text')}
        </p>
      </Form.Item>

      <Form.Item
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        label={t('accessibility_picture')}
        required
      >
        <Form.Item name='accessibility_picture' noStyle>
          <CustomUpload
            onRemove={deleteInspectionImage}
            onChange={uploadChangeHandler}
            beforeUpload={() => false}
            listType='picture-card'
            fileList={fileList}
            accept='image/*'
            multiple
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
          <ShowFieldError withHeight error={error?.accessibility_picture} />
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default Accessibility
