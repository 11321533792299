import { appendStatusIds, appendManagerIds } from 'src/utils'
import axios from '../../axios'

export const statusCountsRequest = async () => {
  return await axios.get('api/report/general/count')
}

export const financialReportingInfoRequest = async (data) => {
  return await axios.get(
    `api/financial_reporting?start_date=${data?.[0]}&end_date=${data?.[1]}`
  )
}

export const materialReportingInfoRequest = async ({
  appendKind,
  keyWord,
  page,
  size,
  sort,
}) => {
  return await axios.get(
    `api/material_reporting?page=${page}&per_page=${size}${
      keyWord ? `&search=${keyWord}` : ''
    }${sort ? `&sort[]=${sort}` : ''}${appendKind()}`
  )
}

export const salesOverviewReportingRequest = async ({
  managerId,
  page,
  size,
  from,
  till,
}) => {
  return await axios.get(
    `api/report/sales?page=${page}&per_page=${size}${
      managerId ? `&manager_ids[]=${managerId}` : ''
    }${from && till ? `&from=${from}&till=${till}` : ''}`
  )
}

export const generalReportingRequest = async ({
  managerIds,
  statusIds,
  page,
  size,
  sort,
  from,
  till,
}) => {
  return await axios.get(
    `api/report/general?page=${page}&per_page=${size}${
      sort ? `&sort[]=${sort}` : ''
    }${statusIds ? appendStatusIds(statusIds) : ''}${
      managerIds ? appendManagerIds(managerIds) : ''
    }${from && till ? `&from=${from}&till=${till}` : ''}`
  )
}

export const generalReportCountRequest = async ({ managerIds, from, till }) => {
  return await axios.get(
    `api/report/general/count?${
      managerIds ? appendManagerIds(managerIds) : ''
    }${from && till ? `&from=${from}&till=${till}` : ''}`
  )
}
