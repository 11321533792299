import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

function MultiselectSearch({ searchQuery, setSearchQuery, searchPlaceholder }) {
  const { t } = useTranslation()

  return (
    <div className='multiselect_options_search'>
      <SearchOutlined />
      <input
        type='text'
        placeholder={searchPlaceholder}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  )
}

export default MultiselectSearch
