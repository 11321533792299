import { CustomModal, Spinner } from 'src/common'
import { Button, Typography } from 'antd'
import { useAllInViewModal } from './hooks'

const AllInViewModal = ({
  show = () => {},
  close = () => {},
  iframeSrc = '',
  error = '',
  isAllViewLoading = false,
  reTryGetAllInViewUrl = () => {},
}) => {
  const { t, additionalConfiguration } = useAllInViewModal()

  return (
    <CustomModal
      additionalConfiguration={additionalConfiguration}
      show={show}
      close={close}
    >
      <Typography.Title level={3}>{t('all_in_view')}</Typography.Title>
      {iframeSrc ? (
        <iframe
          title={t('all_in_view')}
          src={iframeSrc}
          className='allInViewIframe'
        />
      ) : (
        <p>
          {error ? (
            <>
              {error?.response.status === 425 ? (
                <div className='all_in_view_retry'>
                  <p>{t('all_in_view_retry_message')}</p>
                  <Button
                    disabled={isAllViewLoading}
                    type='primary'
                    className='all_in_view_retry__button'
                    onClick={reTryGetAllInViewUrl}
                  >
                    {t('retry')}
                  </Button>
                </div>
              ) : (
                <div>
                  {error?.response?.data?.message || error?.response?.data?.[1]}
                </div>
              )}
            </>
          ) : isAllViewLoading ? (
            <div className='all_in_view_loading'>
              <div className='all_in_view_loading__text'>
                {t('all_in_view_loading_text')}
              </div>
              <Spinner />
            </div>
          ) : null}
        </p>
      )}
    </CustomModal>
  )
}

export default AllInViewModal
