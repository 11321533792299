import { statuses, LinkWithQueryParam } from 'src/common'
import Business from 'src/assets/icons/business.svg'
import Private from 'src/assets/icons/private.svg'
import ClientInfoField from './ClientInforField'
import { LabelIcon, EditIcon } from 'src/assets'
import { useClientInformation } from './hooks'
import { Select } from 'antd'
import i18next from 'i18next'
import React from 'react'
import {
  getProjectStatuses,
  extractDateAndTime,
  canManageProject,
} from 'src/utils'

const OffersClientInformation = (props) => {
  const { project, className } = props

  const {
    isProjectStatusDisabled,
    setSelectedProjectTab,
    updateProjectStatus,
    selectedProjectTab,
    onEditClick,
    t,
  } = useClientInformation({ project })

  return (
    <div className={`${className}`}>
      <div className='tab__switcher'>
        <LinkWithQueryParam
          onClick={() => setSelectedProjectTab('clientInformation')}
          queryParamValue='clientInformation'
          queryParamName='projectTab'
          className={
            selectedProjectTab === 'clientInformation'
              ? 'tab__switcher__active'
              : ''
          }
          replace={true}
        >
          <p>{t('client_information')}</p>
        </LinkWithQueryParam>

        <LinkWithQueryParam
          onClick={() => setSelectedProjectTab('projectDetails')}
          queryParamValue='projectDetails'
          queryParamName='projectTab'
          className={
            selectedProjectTab === 'projectDetails'
              ? 'tab__switcher__active'
              : ''
          }
          replace={true}
        >
          <p>{t('project_details')}</p>
        </LinkWithQueryParam>
      </div>

      <div className='project__tab__container'>
        {selectedProjectTab === 'clientInformation' && (
          <>
            <div className='client-information__header'>
              {canManageProject() ? (
                <div className='edit__icon' onClick={onEditClick}>
                  <EditIcon disabled='true' />
                </div>
              ) : null}
            </div>

            <div className='client-info-fields'>
              <div className='type__field'>
                <span className='type__field__text'>{t('client_type')}</span>

                <span className='type__field__icon'>
                  <span>
                    {t(`${project.type === 1 ? 'private' : 'business'}`)}
                  </span>

                  <img
                    src={`${project?.type === 1 ? Private : Business}`}
                    alt='private_icon'
                    height='16'
                    width='16'
                  />
                </span>
              </div>

              <ClientInfoField
                name={t('date')}
                value={extractDateAndTime(project.made_on_date)?.date}
              />
              <ClientInfoField name={t('client_name')} value={project?.name} />
              <ClientInfoField
                nameClassName='email-field'
                value={project?.email}
                name={t('email')}
              />
              <ClientInfoField
                name={t('phone_number')}
                value={project?.phone_number}
              />
              <ClientInfoField name={t('telephone')} value={project?.mobile} />
              <ClientInfoField
                name={t('country')}
                value={
                  i18next.language === 'nl'
                    ? project?.country?.name_du
                    : project?.country?.name
                }
              />
              <ClientInfoField name={t('location')} value={project?.location} />
              <ClientInfoField name={t('street')} value={project?.street} />
              <ClientInfoField
                name={t('house_number')}
                value={project?.house_number}
              />
              <ClientInfoField
                name={t('house_number_suffix')}
                value={project?.house_number_prefix}
              />
              <ClientInfoField name={t('zipcode')} value={project?.zipcode} />
            </div>
          </>
        )}
        {selectedProjectTab === 'projectDetails' && (
          <>
            <div className='client-info-fields'>
              <div className='project-details-header'>
                {canManageProject() ? (
                  <div className='edit__icon' onClick={onEditClick}>
                    <EditIcon disabled='true' />
                  </div>
                ) : null}
              </div>

              <div className='client-info-fields__field'>
                <span className='field-name'>{t('status')}</span>
                <div className='field-value__status'>
                  <Select
                    className='field-value__status__select custom__status__selector'
                    dropdownStyle={{
                      borderRadius: '5px',
                    }}
                    value={project?.status_id}
                    onChange={updateProjectStatus}
                    disabled={isProjectStatusDisabled}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownClassName='offer__status__select__dropdown'
                  >
                    {getProjectStatuses(project?.status_id).map((status) => {
                      return (
                        <Select.Option key={status.id} value={status.id}>
                          <div className='option'>
                            <div className='option__label_icon'>
                              <LabelIcon />
                            </div>
                            <div
                              className='option__text'
                              style={{
                                color: statuses.find((s) => s.id === status.id)
                                  ?.color,
                              }}
                            >
                              <span>{t(status.name)}</span>
                            </div>
                          </div>
                        </Select.Option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <ClientInfoField
                name={t('account_manager')}
                value={project?.manager?.name}
              />
              <ClientInfoField
                name={t('executor')}
                value={project?.executor?.name}
              />
              <ClientInfoField
                name={t('condition')}
                value={project?.condition?.name}
              />
              <ClientInfoField
                name={t('category')}
                value={t(project?.category?.name)}
              />
              <ClientInfoField
                name={t('annual_consumption')}
                value={project?.annual_consumption}
              />
              <ClientInfoField
                name={t('reference')}
                value={project?.reference}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default OffersClientInformation
