import { ConfigProvider } from 'antd'
import { useTranslation } from 'react-i18next'
import enEN from 'antd/lib/locale/en_US'
import nlNL from 'antd/lib/locale/nl_NL'

export const AntdConfigProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const locale = i18n.language === 'nl' ? nlNL : enEN
  locale.DatePicker.lang.locale = 'enUS'

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>
}
