import { dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { nl, enGB } from 'date-fns/locale'
import 'react-big-calendar/lib/css/react-big-calendar.css'
const locales = {
  nl,
  'en-GB': enGB,
}

const dateFnsCalendarLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export { dateFnsCalendarLocalizer }
