export const appendManagerIds = (managerIds) => {
  let temp = ''

  if (managerIds) {
    managerIds?.forEach((id) => {
      temp += `&manager_id[]=${id}`
    })
  }

  return temp
}
