import useConfirmOrder from './useConfirmOrder'
import { CustomCheckbox } from 'src/common'
import { Button } from 'antd'

const ConfirmOrder = ({ offerData }) => {
  const {
    confirmOrderMutation,
    setCheckboxValue,
    isOrderConfirmed,
    checkboxValue,
    isConfirming,
    disabled,
    t,
  } = useConfirmOrder(offerData)

  return (
    <div className='confirm__order'>
      <div className='confirm__order__container'>
        <div className='confirm__order__container__warning'>
          <p className='warning__text'>
            {t('order_confirmation_instructions')}
          </p>

          <CustomCheckbox
            disabled={isOrderConfirmed || isConfirming}
            setCheckboxValue={setCheckboxValue}
            checkboxClass='confirm__checkbox'
            checkboxValue={checkboxValue}
            title={t('i_understand')}
          />
        </div>

        <Button
          className={`confirm__order__button ${
            disabled ? 'confirm__order__button__disabled' : ''
          }`}
          onClick={confirmOrderMutation}
          disabled={disabled}
        >
          {t('confirm_order')}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmOrder
