import { SelectOption } from './index'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useSelectGroup } from '../../hooks'

function SelectGroup({ id, label, options, groupClass, textColor }) {
  const { t } = useTranslation()
  const {
    isSelectOpen,
    selectedOption,
    selectedOptionColor,
    handleSelectOptions,
    chooseOption,
  } = useSelectGroup()

  return (
    <div className={groupClass}>
      <label>{label}</label>
      <div
        className='select_header'
        onClick={() => {
          return handleSelectOptions()
        }}
      >
        {selectedOption ? (
          <div
            className='selected_header_option'
            style={{
              color: selectedOptionColor
                ? selectedOptionColor?.text
                : '#343A40',
              background: selectedOptionColor
                ? selectedOptionColor.background
                : '#FFF',
            }}
          >
            {t(selectedOption.name)}
          </div>
        ) : (
          <div className='select_header_placeholder'>{label}</div>
        )}
        <div>
          <DownOutlined
            className={`down_outline ${
              isSelectOpen ? 'open_down_outlined' : ''
            }`}
          />
        </div>
      </div>
      {isSelectOpen ? (
        <>
          <div
            className='select_background'
            onClick={() => {
              return handleSelectOptions()
            }}
          ></div>
          <div className='select_options'>
            {options
              ? options?.map((option, index) => {
                  return (
                    <SelectOption
                      key={index}
                      option={option}
                      textColor={textColor}
                      chooseOption={chooseOption}
                    />
                  )
                })
              : null}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default SelectGroup
