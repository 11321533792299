import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import {
  DashboardReportingIcon,
  AllCompaniesIcon,
  MyCompanyIcon,
  VacationIcon,
  MyTeamIcon,
} from 'src/assets'

const useMenuLinkWrapper = (title) => {
  const { t } = useTranslation()

  const linkIcons = useMemo(
    () => ({
      [t('reporting')]: DashboardReportingIcon,
      [t('vacation_request')]: VacationIcon,
      [t('my_team')]: MyTeamIcon,
      [t('my_company')]: MyCompanyIcon,
      [t('all_companies')]: AllCompaniesIcon,
    }),
    [t]
  )

  const LinkIcon = useMemo(() => linkIcons[title], [linkIcons, title])

  return { LinkIcon, t }
}

export default useMenuLinkWrapper
