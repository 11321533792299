import { useParams, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

export const useTabLink = () => {
  const { product: activeTab, projectId, offerId } = useParams()

  const { search } = useLocation()

  const { t } = useTranslation()

  return { activeTab, projectId, offerId, t, searchParams: search }
}
