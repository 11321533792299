import { CompanyTextEditor } from 'src/screens/Manage/screens/Companies/components/CompanyModal/tabs/components'
import { useTranslation } from 'react-i18next'
import { InputItem } from 'src/common'

const EmailInformation = ({ show, errors, form, companyData }) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: show ? 'grid' : 'none',
      }}
      className='company__tab-container__email-information'
    >
      <InputItem
        error={errors?.email_quotation_from}
        label={t('mail_quotation_from')}
        name='email_quotation_from'
        required={false}
      />

      <CompanyTextEditor
        initialState={companyData?.email_quotation_text}
        error={errors?.email_quotation_text}
        setFieldsValue={form.setFieldsValue}
        label={t('text_quotation_mail')}
        fieldName='email_quotation_text'
      />

      <CompanyTextEditor
        initialState={companyData?.offerte_link}
        setFieldsValue={form.setFieldsValue}
        error={errors?.offerte_link}
        label={t('text_offer_link')}
        fieldName='offerte_link'
      />

      <InputItem
        error={errors?.email_confirmation_from}
        label={t('mail_confirmation_from')}
        name='email_confirmation_from'
        required={false}
      />
    </div>
  )
}

export default EmailInformation
