import { ButtonWithTrash } from 'src/common'

const PaymentTableColumns = (setDeleteModal, t) => {
  return [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '75%',
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      width: '25%',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default PaymentTableColumns
