import { useTranslation } from 'react-i18next'

export const useSignatureDoubleBox = () => {
  const { t } = useTranslation()

  const getPopupContainer = (trigger) => {
    return trigger.parentNode
  }

  return {
    getPopupContainer,
    t,
  }
}
