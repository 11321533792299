import { ShowFieldError, AutoSignatureBtn, DatePicker } from 'src/common'
import { useSignatureDoubleBox } from './useSignatureDoubleBox'
import SignatureModal from '../signatureModal/SignatureModal'
import { BiTrash } from 'react-icons/bi'
import { Button } from 'antd'

const SignatureDoubleBox = ({
  disableSignatureBoxes = false,
  dates = {},
  headquartersSignature = '',
  setHeadquartersSignature = () => {},
  isHeadquartersSignatureModalOpen = false,
  toggleHeadquartersSignatureModal = () => {},
  customerSignature = '',
  setCustomerSignature = () => {},
  isCustomerSignatureModalOpen = false,
  toggleCustomerSignatureModal = () => {},
  disableHeadSignatureDelete = '',
  disableCustomerSignatureDelete = '',
  customerSignatureError = '',
  title = '',
  quotationSaveErrors = {},
  setAutoSigned = () => {},
  userSignatureData = {},
}) => {
  const { getPopupContainer, t } = useSignatureDoubleBox()

  return (
    <div className='signatures'>
      <div>
        <div
          className={`signatures__box ${
            quotationSaveErrors?.head_signature_id && 'signature__box__error'
          }`}
        >
          <div className='signatures__header'>
            <div className='signatures__title'>{title}</div>
            <div>
              {dates?.headquarters ? (
                <DatePicker
                  className={
                    quotationSaveErrors?.quot_head_signature_date &&
                    'quotation__date__picker__error'
                  }
                  value={new Date(dates.headquarters)}
                  getPopupContainer={getPopupContainer}
                  disabled={true}
                />
              ) : null}
            </div>
          </div>
          <ShowFieldError
            error={quotationSaveErrors?.quot_head_signature_date}
            noErrorClassName='empty__signature__error'
            className='signature__error__message'
            withHeight
          />

          <div className='signatures__buttonBox'>
            {headquartersSignature ? (
              <div className='signatureImgAndDeleteWrapper'>
                <img
                  className='signatures__buttonBox__image'
                  src={headquartersSignature}
                  alt='headquarters signature'
                />
                {!disableHeadSignatureDelete ? (
                  <Button
                    onClick={() => {
                      setHeadquartersSignature('')
                      setAutoSigned(false)
                    }}
                    disabled={!userSignatureData?.id || disableSignatureBoxes}
                    className='deleteSignatureImg'
                    type='danger'
                  >
                    <BiTrash />
                  </Button>
                ) : null}
              </div>
            ) : (
              <div className='signatures__buttons__container'>
                <Button
                  onClick={toggleHeadquartersSignatureModal}
                  disabled={disableSignatureBoxes}
                  type='default'
                >
                  {t('enter_signature')}
                </Button>

                <AutoSignatureBtn
                  setSignature={setHeadquartersSignature}
                  disabled={disableSignatureBoxes}
                  setAutoSigned={setAutoSigned}
                />
              </div>
            )}
          </div>
        </div>

        <ShowFieldError
          error={quotationSaveErrors?.head_signature_id}
          noErrorClassName='empty__signature__error'
          className='signature__error__message'
          withHeight
        />
      </div>

      <div>
        <div
          className={`signatures__box ${
            quotationSaveErrors?.quot_customer_signature_id &&
            'signature__box__error'
          }`}
        >
          <div className='signatures__header'>
            <div className='signatures__title'>{t('customer')}</div>
            <div>
              {dates?.customer ? (
                <DatePicker
                  className={
                    quotationSaveErrors?.quot_customer_signature_date &&
                    'quotation__date__picker__error'
                  }
                  value={new Date(dates.customer)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={true}
                />
              ) : null}
            </div>
          </div>
          <ShowFieldError
            error={quotationSaveErrors?.quot_customer_signature_date}
            noErrorClassName='empty__signature__error'
            className='signature__error__message'
            withHeight
          />

          <div className='signatures__buttonBox'>
            {customerSignature ? (
              <div className='signatureImgAndDeleteWrapper'>
                <img
                  className='signatures__buttonBox__image'
                  src={customerSignature}
                  alt='customers signature'
                />
                {!disableCustomerSignatureDelete ? (
                  <Button
                    onClick={() => setCustomerSignature('')}
                    className='deleteSignatureImg'
                    type='danger'
                  >
                    <BiTrash />
                  </Button>
                ) : null}
              </div>
            ) : (
              <Button
                onClick={toggleCustomerSignatureModal}
                disabled={disableSignatureBoxes}
                type='default'
              >
                {t('enter_signature')}
              </Button>
            )}
          </div>
          {customerSignatureError ||
          quotationSaveErrors?.quot_customer_signature_id ? (
            <ShowFieldError
              error={
                customerSignatureError ||
                quotationSaveErrors?.quot_customer_signature_id
              }
            />
          ) : null}
        </div>
      </div>

      <SignatureModal
        setImageUrl={setHeadquartersSignature}
        show={isHeadquartersSignatureModalOpen}
        close={toggleHeadquartersSignatureModal}
      />
      <SignatureModal
        setImageUrl={setCustomerSignature}
        show={isCustomerSignatureModalOpen}
        close={toggleCustomerSignatureModal}
      />
    </div>
  )
}

export default SignatureDoubleBox
