import { memo } from 'react'

const ClientInfoField = (props) => {
  return (
    <div className='client-info-fields__field'>
      <span className={`field-name ${props?.nameClassName}`}>
        {props?.name}
      </span>
      <span className='field-value'>{props?.value}</span>
    </div>
  )
}

export default memo(ClientInfoField)
