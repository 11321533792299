import axios from '../../axios'

export const countriesInfoRequest = async () => {
  return await axios.get('api/countries')
}

export const templatesInfoRequest = (type) => {
  return axios.get(`api/templates?type=${type}`)
}

export const exportRequest = async (data) => {
  return await axios.post('api/generate_export_url', data)
}

export const exportOffer = async ({ offerId }) => {
  return axios.post(`api/generate_export_url?model=offers&offer_id=${offerId}`)
}

export const fileUploadRequest = async (url, data) => {
  return await axios.post(`api/uploads/${url}`, data)
}

export const generateExportRequest = async (keyWord, data) => {
  return await axios.post(
    `/api/generate_export_url${keyWord && `?search=${keyWord}`}`,
    data
  )
}

export const deleteMultipleProductRequest = async (table, items) => {
  return await axios.delete(`api/${table}`, { data: { items } })
}
