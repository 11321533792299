import { useClearHomePageQuery, useHandleQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { statuses } from 'src/common'
import { useEffect, useState } from 'react'
import { STATUS_IDS_PARAM } from 'src/screens/Home/constants'

export const useStatusFilters = ({
  statusFiltersForm,
  findStatusCount,
  statusIds,
}) => {
  const [statusModalOpen, setStatusModalOpen] = useState(false)

  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  const { clearPageQuery } = useClearHomePageQuery()
  const { t } = useTranslation()

  useEffect(() => {
    if (!statusIds?.length) {
      statusFiltersForm.setFieldsValue({ filters: [] })
    }
  }, [statusIds?.length])

  const statusOptions = statuses.map((status) => {
    return {
      label: (
        <div>
          {t(status.name)}{' '}
          <span
            style={{
              color: status.color,
            }}
          >
            {findStatusCount(status?.id)}
          </span>
        </div>
      ),
      value: status.id,
    }
  })

  const submitHandler = (values) => {
    clearPageQuery()
    if (values?.filters) {
      appendQueryParams(
        {
          [STATUS_IDS_PARAM]: JSON.stringify(values.filters),
        },
        {
          resetPage: true,
        }
      )
    }
    setStatusModalOpen(false)
  }

  const resetHandler = () => {
    clearPageQuery()
    deleteQueryParams(STATUS_IDS_PARAM)
  }

  return {
    setStatusModalOpen,
    statusModalOpen,
    statusOptions,
    submitHandler,
    resetHandler,
    t,
  }
}
