import { useHistory, useLocation, useParams } from 'react-router'
import { useCallback, useState, useMemo } from 'react'
import { offerInfoRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { canManageProject } from 'src/utils'

function useOffer(offerId, openedOffers) {
  const { t } = useTranslation()

  const [offerOpenState, setOfferOpenState] = useState({})

  const { search } = useLocation()
  const { projectId } = useParams()
  const history = useHistory()

  const params = new URLSearchParams(search)

  useEffect(() => {
    const currentOffer = openedOffers.find((offer) => offer?.id === offerId)

    setOfferOpenState(currentOffer)
  }, [offerId, openedOffers])

  const {
    isLoading: isOfferLoading,
    data: offerData,
    refetch,
  } = useQuery(['offer', offerId], () => offerInfoRequest(offerId), {
    enabled: !!offerId && offerId === offerOpenState?.id,
  })

  function onProductEdit() {
    if (!canManageProject()) return
    history.push(`/project/${projectId}/survey/${offerId}${search}`)
  }

  function onProductOpenClose() {
    if (offerId === offerOpenState?.id) {
      const index = openedOffers?.findIndex((offer) => offer?.id === offerId)

      params.delete(`openedOffers[${index}].id`)
      params.delete(`openedOffers[${index}].step`)

      history.replace(`?${params.toString()}`)
    } else {
      params.append(`openedOffers[${openedOffers?.length}].id`, offerId)
      params.append(`openedOffers[${openedOffers?.length}].step`, 'products')

      history.replace(`?${params.toString()}`)
      refetch()
    }
  }

  const hasProducts = useMemo(() => {
    return (
      offerData?.data?.offer_panels?.length ||
      offerData?.data?.offer_converters?.length ||
      offerData?.data?.offer_power_optimizers?.length ||
      offerData?.data?.offer_wifi_modules?.length ||
      offerData?.data?.offer_mounting_systems?.length ||
      offerData?.data?.offer_options?.length
    )
  }, [offerData])

  function showOfferPage(offerPage) {
    if (!canManageProject() && offerPage !== 'products') return false
    if (offerPage === 'products' && !hasProducts) return false

    return offerId === offerOpenState?.id && offerOpenState?.step === offerPage
  }

  const hasRequiredProducts = useMemo(() => {
    return (
      offerData?.data?.offer_panels?.length &&
      offerData?.data?.offer_converters?.length &&
      offerData?.data?.offer_wifi_modules?.length
    )
  }, [offerData])

  const isStepEnabled = useCallback(
    (projectStatusId = 0) => {
      return (
        hasRequiredProducts &&
        offerData?.data?.project?.status_id > projectStatusId
      )
    },
    [offerData?.data?.project?.status_id, hasRequiredProducts]
  )

  const requiredProductsMessage = useMemo(() => {
    let message = ''

    if (!hasRequiredProducts) {
      message = `${t('required_products_are_missing')}: ${
        !offerData?.data?.offer_panels?.length
          ? `${t('panels')}${appendComa('offer_converters')}`
          : ''
      }${
        !offerData?.data?.offer_converters?.length
          ? `${t('converters')}${appendComa()}`
          : ''
      }${!offerData?.data?.offer_wifi_modules?.length ? `${t('network')}` : ''}`
    }

    return message
  }, [offerData])

  function appendComa(name = '') {
    let coma = ''
    if (
      !offerData?.data?.offer_wifi_modules?.length ||
      (name && !offerData?.data?.[name]?.length)
    ) {
      coma = `, `
    }
    return coma
  }

  const canSeeQuotation = isStepEnabled(3) && offerData?.data?.signature

  const canSeeDelivery =
    isStepEnabled(4) &&
    offerData?.data?.signature &&
    offerData?.data?.customer_signature

  return {
    isOrderCreated: !!offerData?.data?.order_created_at,
    offerData: offerData?.data,
    quotationIsSigned:
      !!offerData?.data?.customer_signature && offerData?.data?.head_signature,
    isLoading: isOfferLoading,
    requiredProductsMessage,
    offer: offerData?.data,
    onProductOpenClose,
    canSeeQuotation,
    canSeeDelivery,
    offerOpenState,
    onProductEdit,
    isStepEnabled,
    showOfferPage,
  }
}

export { useOffer }
