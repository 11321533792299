import axios from '../../axios'

export const commentsRequest = async (projectId, pageParam) => {
  return await axios.get(
    `/api/comments?commentable_type=Project&commentable_id=${projectId}&page=${pageParam}&per_page=10`
  )
}

export const createCommentRequest = async (data) => {
  return await axios.post('api/comments', data)
}
