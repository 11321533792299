export const companyFormConstant = {
  country_id: 649,
  city: null,
  name: '',
  accaunt_manager_id: null,
  reference_prefix: '',
  email: '',
  email_quotation_from: '',
  email_quotation_text: '',
  email_confirmation_from: '',
  offerte_link: '',
  street: '',
  house_number: '',
  house_number_suffix: '',
  zipcode: '',
  phone_number: '',
  coc_number: '',
  vat_number: '',
  bic_number: '',
  iban: '',
  website: '',
  show_weather_conditions: 0,
  show_warranty_cert: 0,
  show_avg: 0,
  show_terms: 0,
  show_leudal: 0,
  planning_token: '',
  enable_solar_planning: 0,
  terms_of_conditions_id: null,
  terms_of_conditions: null,
  timezone: null,
  module_leads: 0,
  module_reports: 0,
  enable_solarplanning: 0,
  // working_hours: null,
}

export const basicInfoFields = [
  'name',
  'file',
  'email_quotation_from',
  'email_quotation_text',
  'offerte_link',
  'email_confirmation_from',
]

export const setupFields = [
  'logo',
  'name',
  'reference_prefix',
  'email',
  'phone_number',
  'street',
  'house_number',
  'house_number_suffix',
  'zipcode',
  'city',
  'country_id',
  'website',
  'privacy_statement_id',
  'terms_of_conditions_id',
]

export const financialInformationFields = [
  'coc_number',
  'vat_number',
  'bic_number',
  'iban',
]

export const emailInformationFields = [
  'email_quotation_from',
  'email_quotation_text',
  'offerte_link',
  'email_confirmation_from',
]

export const additionalInfoFields = ['planning_token']

export const configTabFields = [
  'reference_prefix',
  'email',
  'phone_number',
  'street',
  'house_number',
  'house_number_suffix',
  'zipcode',
  'country_id',
  'city',
  'manager_id',
  'coc_number',
  'vat_number',
  'bic_number',
  'iban',
]
