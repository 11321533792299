import { ButtonWithPlus } from 'src/common'
import { Space } from 'antd'

const AddInputs = ({ text = '', onClick = () => {}, disabled = false }) => {
  return (
    <div className='bottombuttons'>
      <Space>
        <ButtonWithPlus
          disabled={disabled}
          onClick={onClick}
          type='secondary'
          text={text}
        />
      </Space>
    </div>
  )
}
export default AddInputs
