import { useTranslation } from 'react-i18next'
import { InputItem } from 'src/common'
import { Form } from 'antd'

const DummyPassword = ({ setChangePasswordModal }) => {
  const { t } = useTranslation()

  return (
    <Form.Item className='disabled__password__container' label={t('password')}>
      <div onClick={() => setChangePasswordModal(true)}>
        <InputItem placeholder='*********' disabled />
      </div>

      <span
        onClick={() => setChangePasswordModal(true)}
        className='disabled__password__container__edit'
      >
        {t('change')}
      </span>
    </Form.Item>
  )
}

export default DummyPassword
