export const categories = [
  {
    id: 1,
    name: 'internal_lead',
  },
  {
    id: 2,
    name: 'reference_lead',
  },
  {
    id: 3,
    name: 'external_lead_generator',
  },
  {
    id: 4,
    name: 'roof_scan',
  },
  {
    id: 5,
    name: 'other',
  },
]
