import { handleNonValidationError, handleValidationError } from 'src/utils'
import { GENERAL_CONDITION_TYPE } from '../../useConditions'
import { useMutation, useQueryClient } from 'react-query'
import { addGeneralConditionRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

export const useAddGeneralConditionModal = (setAddGeneralConditionModal) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.globalSlice)

  const closeModal = () => setAddGeneralConditionModal(false)

  const {
    isLoading,
    mutate: createCondition,
    error,
  } = useMutation((data) => !isLoading && addGeneralConditionRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('conditions')
      closeModal()
    },
    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  function handleAddGeneralCondition(values) {
    return createCondition({
      ...values,
      company_id: user?.company?.id,
      type: GENERAL_CONDITION_TYPE,
    })
  }

  return {
    createConditionError: handleValidationError(error),
    handleAddGeneralCondition,
    closeModal,
    isLoading,
    form,
    t,
  }
}
