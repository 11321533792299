import { useQuery, useMutation, useQueryClient } from 'react-query'
import { handleNonValidationError, isAdmin } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import {
  usePageQueryParams,
  useEnabledFeatures,
  useGetUserInfo,
} from 'src/hooks'
import {
  readLeadsNotificationsRequest,
  deleteLeadsRequest,
  allLeadsRequest,
} from 'src/services'

const useNewLeads = () => {
  const [showLeadsDeleteModal, setShowLeadsDeleteModal] = useState(false)
  const [selectedLeadIds, setSelectedLeadIds] = useState([])
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const { user } = useGetUserInfo()

  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const history = useHistory()

  const { isNewLeadsEnabled } = useEnabledFeatures()

  const { mutate: readLeadsNotificationsMutation } = useMutation(
    readLeadsNotificationsRequest,
    {
      onError: handleNonValidationError,
    }
  )

  useEffect(() => {
    if (!isNewLeadsEnabled && user) history.push('/')
  }, [user, isNewLeadsEnabled, history])

  const {
    isLoading: isLeadsDataLoading,
    data: leadsData,
    isPreviousData,
  } = useQuery(
    ['leads', page, size, searchKeyWord, sort],
    () => allLeadsRequest({ page, size, searchKeyWord, sort }),
    {
      onSuccess: (response) => {
        if (isAdmin()) {
          const unreadLeadsIds = []

          response?.data?.data?.forEach((lead) => {
            if (!lead?.read_at) {
              unreadLeadsIds.push(lead?.id)
            }
          })

          if (unreadLeadsIds.length) {
            readLeadsNotificationsMutation(unreadLeadsIds)
          }
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
      enabled: isNewLeadsEnabled,
      keepPreviousData: true,
    }
  )

  const { mutate: deleteLeadsMutation, isLoading: deleting } = useMutation(
    deleteLeadsRequest,
    {
      onSuccess: () => {
        queryClient.refetchQueries('leads')
        setShowLeadsDeleteModal(false)
        setSelectedLeadIds([])
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const leadsSearchHandler = (keyWord) => {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const handleTableChange = (_pagination, _filters, sorter) => {
    const { field, order } = sorter

    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      setSort(`${field},${tempOrd}`)
    }

    if (!order) {
      setSort('')
    }
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        let selection = window.getSelection()
        if (!selection.toString()) {
          history.push(`/project/project-creation/${record?.id}?isLead=true`)
        }
      },
      onContextMenu: () => {
        return false
      },
    }
  }

  return {
    isLeadsDataLoading: isPreviousData || isLeadsDataLoading,
    paginationInfo: leadsData?.data?.meta,
    leadsData: leadsData?.data?.data,
    setShowLeadsDeleteModal,
    showLeadsDeleteModal,
    deleteLeadsMutation,
    leadsSearchHandler,
    setSelectedLeadIds,
    handleTableChange,
    handlePageChange,
    selectedLeadIds,
    deleting,
    onRow,
    t,
  }
}

export default useNewLeads
