import { userInfoRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

export const useAutoSignatureBtn = () => {
  const { t } = useTranslation()

  const { data, isLoading: isUserDataLoading } = useQuery(
    'user_info',
    userInfoRequest
  )

  return {
    userSignatureData: data?.data?.draft_signature,
    isUserDataLoading,
    t,
  }
}
