export const useAntdForm = (props, disableOnEnter) => {
  const { onFinish } = props

  const keyDownHandler = (e) => {
    if (e.key === 'Enter' && !disableOnEnter) {
      onFinish && onFinish()
    }
  }

  return { keyDownHandler }
}
