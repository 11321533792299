import { useQuery } from 'react-query'
import { STALE_AND_CACHE } from 'src/common'
import { projectInfoRequest } from 'src/services'

function useGetProject({ projectId, additionalConfig = {} }) {
  const { data, isLoading: isProjectLoading } = useQuery(
    ['projects', projectId],
    () => projectInfoRequest(projectId),
    {
      enabled: Boolean(projectId),
      ...STALE_AND_CACHE,
      ...additionalConfig,
    }
  )
  return { project: data?.data, isProjectLoading }
}

export { useGetProject }
