import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { downloadFile, canPerform, rowSelectionActions } from 'src/utils'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  importPowerOptimizersRequest,
  deletePowerOptimizerRequest,
  allPowerOptimizersRequest,
  exportRequest,
} from 'src/services'

export function usePowerOptimizers() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { user } = useSelector((state) => state.globalSlice)

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const [importError, setImportError] = useState()

  const [addPowerOptimizerModal, setAddPowerOptimizerModal] = useState(false)

  const [showPowerOptimizerModal, setShowPowerOptimizerModal] = useState({
    show: false,
    data: {},
  })

  const [deletePowerOptimizer, setDeletePowerOptimizer] = useState({
    show: false,
    data: {},
  })

  const [selectedPowerOptimizersIds, setSelectedPowerOptimizersIds] = useState(
    []
  )
  const rowSelection = rowSelectionActions(setSelectedPowerOptimizersIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const {
    isLoading: isPowerOptimizersDataLoading,
    data: powerOptimizersData,
    isPreviousData,
  } = useQuery(
    ['powerOptimizers', page, size, sort, searchKeyWord, user?.company_id],
    () =>
      allPowerOptimizersRequest({
        companyId: user?.company_id,
        searchKeyWord,
        page,
        sort,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedPowerOptimizersIds,
      'powerOptimizers',
      powerOptimizersData?.data?.data?.length
    )

  const { mutate: DeletePowerOptimizer, isLoading: deleting } = useMutation(
    deletePowerOptimizerRequest,
    {
      onSuccess: (resp, deletedOptimizerId) => {
        if (resp.data.success) {
          if (selectedPowerOptimizersIds.includes(deletedOptimizerId)) {
            setSelectedPowerOptimizersIds((prev) =>
              prev.filter((id) => id !== deletedOptimizerId)
            )
          }

          if (powerOptimizersData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('powerOptimizers')
          setDeletePowerOptimizer({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importPowerOptimizers, isLoading: importing } = useMutation(
    importPowerOptimizersRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('powerOptimizers')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportPowerOptimizersMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp.data, 'powerOptimizers')
      },
    })

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }
  function exportPowerOptimizers() {
    exportPowerOptimizersMutation({
      model: 'power_optimizers',
    })
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedPowerOptimizersIds,
      table: 'power_optimizers',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage power-optimizer') ||
          canPerform('manage own power-optimizer')
        ) {
          setShowPowerOptimizerModal({ show: true, data: record })
        }
      },
    }
  }

  return {
    exportDisabled: exporting || !powerOptimizersData?.data?.data?.length,
    paginationInfo: {
      total: powerOptimizersData?.data?.meta?.total,
      page,
    },
    powerOptimizers: powerOptimizersData?.data?.data,
    isPowerOptimizersDataLoading:
      isPowerOptimizersDataLoading || isPreviousData,
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowPowerOptimizerModal,
    selectedPowerOptimizersIds,
    setAddPowerOptimizerModal,
    setDeletePowerOptimizer,
    showPowerOptimizerModal,
    addPowerOptimizerModal,
    setShowImportCsvModal,
    importPowerOptimizers,
    exportPowerOptimizers,
    deletingMultipleItem,
    deletePowerOptimizer,
    DeletePowerOptimizer,
    deleteSelectedItems,
    showImportCsvModal,
    handlePageChange,
    setImportError,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  }
}
