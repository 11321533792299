import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updateActivityTypeRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowActivityType = (setShowTypesModal, currentType) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const closeModal = () => {
    setShowTypesModal({
      show: false,
      data: {},
    })
    form.resetFields()
  }

  const {
    mutate: updateActivityType,
    isLoading,
    error,
  } = useMutation(
    (values) => !isLoading && updateActivityTypeRequest(currentType.id, values),
    {
      onSuccess: () => {
        queryClient.refetchQueries('activity_types')
        message.success(t('updated_successfully'))
        closeModal()
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (!!currentType) {
      form.setFieldsValue(currentType)
    }
  }, [currentType, form])

  return {
    updateActivityTypeError: handleValidationError(error),
    updateActivityType,
    closeModal,
    isLoading,
    form,
    t,
  }
}
