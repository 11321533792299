export const rowSelectionActions = (setSelectedIds) => {
  return {
    type: 'checkbox',

    onSelect: (record, selected) => {
      if (selected) {
        setSelectedIds((prev) => [...prev, record?.id])
      } else {
        setSelectedIds((prev) => prev.filter((id) => id !== record?.id))
      }
    },

    onSelectAll: (selected, selectedRows, changesRow) => {
      const selectedProductsArr = []

      if (selected) {
        selectedRows.forEach((item) => {
          if (item) {
            selectedProductsArr.push(item?.id)
          }
        })

        setSelectedIds(selectedProductsArr)
      } else {
        setSelectedIds((prev) =>
          prev.filter((id) => !changesRow.find((item) => item?.id === id))
        )
      }
    },
  }
}
