export const LabelIcon = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M13.6566 6.16875L13.1941 1.28437C13.1707 1.03125 12.9691 0.83125 12.716 0.80625L7.83163 0.34375H7.82538C7.77538 0.34375 7.73632 0.359375 7.70663 0.389063L0.389444 7.70625C0.374959 7.72071 0.363468 7.73788 0.355627 7.75678C0.347786 7.77568 0.34375 7.79594 0.34375 7.81641C0.34375 7.83687 0.347786 7.85713 0.355627 7.87603C0.363468 7.89494 0.374959 7.91211 0.389444 7.92656L6.07382 13.6109C6.10351 13.6406 6.14257 13.6562 6.18476 13.6562C6.22694 13.6562 6.26601 13.6406 6.29569 13.6109L13.6129 6.29375C13.6441 6.26094 13.6598 6.21562 13.6566 6.16875V6.16875ZM6.18319 12.0422L1.95819 7.81719L8.20351 1.57188L12.0629 1.9375L12.4285 5.79688L6.18319 12.0422ZM9.62538 3C8.86757 3 8.25038 3.61719 8.25038 4.375C8.25038 5.13281 8.86757 5.75 9.62538 5.75C10.3832 5.75 11.0004 5.13281 11.0004 4.375C11.0004 3.61719 10.3832 3 9.62538 3ZM9.62538 4.875C9.34882 4.875 9.12538 4.65156 9.12538 4.375C9.12538 4.09844 9.34882 3.875 9.62538 3.875C9.90194 3.875 10.1254 4.09844 10.1254 4.375C10.1254 4.65156 9.90194 4.875 9.62538 4.875Z'
        fill='#606870'
      />
    </svg>
  )
}
