import { useState, useEffect } from 'react'

function useSuspenseLoader() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => setShow(true), 200)
    return () => clearTimeout(timeoutId)
  }, [])

  return { show }
}

export { useSuspenseLoader }
