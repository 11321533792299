export const userFormConstants = {
  name: '',
  email: '',
  password: '',
  repeat_password: '',
  company_id: null,
  role: null,
  lang: '',
  max_discount: null,
  phone_number: null,
  commission: null,
  skills: [],
  is_team_lead: 0,
}
