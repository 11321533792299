import { useLanguageSelector } from './useLanguageSelector'
import { Select } from 'antd'

const LanguageSelector = (props) => {
  const { languageChangeHandler, defaultValue, options } = useLanguageSelector()

  return (
    <Select
      onChange={languageChangeHandler}
      defaultValue={defaultValue}
      options={options}
      style={{
        width: props?.width || 100,
      }}
    />
  )
}

export default LanguageSelector
