import { useTranslation } from 'react-i18next'
import { useFetchCountries } from 'src/hooks'
import i18next from 'i18next'

export const useConfigurationTab = () => {
  const { countriesData } = useFetchCountries()
  const { t } = useTranslation()

  return { t, countriesData, lang: i18next.language }
}
