import { useTranslation } from 'react-i18next'
import { InputItem } from 'src/common'

const FinancialInformation = ({ errors, show }) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: show ? 'grid' : 'none',
      }}
      className='company__tab-container__financial-information'
    >
      <InputItem
        error={errors?.coc_number}
        label={t('coc_number')}
        name='coc_number'
        required={false}
      />

      <InputItem
        error={errors?.vat_number}
        label={t('vat_number')}
        name='vat_number'
        required={false}
      />

      <InputItem
        error={errors?.bic_number}
        label={t('bic_number')}
        name='bic_number'
        required={false}
      />

      <InputItem
        error={errors?.iban}
        label={t('iban')}
        name='iban'
        required={false}
      />
    </div>
  )
}

export default FinancialInformation
