import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from 'src/hooks'
import { useState } from 'react'
import {
  showPowerOptimizers,
  showMountingSystems,
  isSuperUserOrAdmin,
  showActivityTypes,
  showWifiModules,
  showActivities,
  showConditions,
  showConverters,
  showMaterials,
  isSuperUser,
  showPanels,
  showUsers,
  isSupplier,
} from 'src/utils'

export function useManageScreen() {
  const { user } = useGetUserInfo()

  const { t } = useTranslation()

  const [activeAccordionKey, setActiveAccordionKey] = useState('')

  const saleProductPages = [
    showPanels() ? { title: t('panels'), path: '/manage/panels' } : null,

    showConverters()
      ? { title: t('converters'), path: '/manage/converters' }
      : null,

    showWifiModules()
      ? { title: t('wifi_modules'), path: '/manage/wifi-module' }
      : null,

    showMountingSystems()
      ? {
          title: t('mounting_systems'),
          path: '/manage/mounting-systems',
        }
      : null,

    showMaterials()
      ? { title: t('materials'), path: '/manage/materials' }
      : null,

    showActivities() || showActivityTypes()
      ? { title: t('activities'), path: '/manage/activities' }
      : null,

    showPowerOptimizers()
      ? {
          title: t('power_optimizers'),
          path: '/manage/power-optimizers',
        }
      : null,
  ]

  const applicationPages = [
    showConditions()
      ? {
          title: t('payment_conditions'),
          path: '/manage/payment-conditions',
        }
      : null,

    isSuperUserOrAdmin()
      ? {
          title: t('document_library'),
          path: '/manage/document-library',
        }
      : null,
  ]

  const companySettingsPages = [
    isSuperUserOrAdmin()
      ? {
          title: t('my_company'),
          path: `/manage/company?id=${user?.company?.id}&activeTab=setup`,
        }
      : null,

    showUsers()
      ? {
          path: '/manage/users',
          title: t('users'),
        }
      : null,

    isSuperUser()
      ? {
          path: '/manage/companies',
          title: t('companies'),
        }
      : null,
  ]

  return {
    showCompanySettings: isSuperUserOrAdmin() || showUsers(),
    showProducts: isSupplier() || isSuperUserOrAdmin(),
    setActiveAccordionKey,
    companySettingsPages,
    activeAccordionKey,
    saleProductPages,
    applicationPages,
    t,
  }
}
