import { SelectRenderInput } from 'src/screens/Manage/components'
import { useConverterFormInputs } from './hooks'
import { Select } from 'antd'
import {
  FormModalWrapper,
  AmountInputItem,
  SelectItem,
  InputItem,
} from 'src/common'

const ConverterFormInputs = ({
  currentConverter = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  form,
  show,
}) => {
  const {
    addConverterBrandLoading,
    fetchBrandOnScroll,
    converterBrands,
    clickHandler,
    t,
  } = useConverterFormInputs()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      modalTitle={t('converter')}
      closeHandler={closeHandler}
      labelCol={{ span: 7 }}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <SelectItem
        defaultValue={currentConverter?.brand?.id}
        onPopupScroll={fetchBrandOnScroll}
        error={errors?.brand_id}
        dropdownRender={(menu) => {
          return (
            <SelectRenderInput
              disabled={addConverterBrandLoading}
              buttonTitle={t('add_brand')}
              clickHandler={clickHandler}
              menu={menu}
            />
          )
        }}
        label={t('brand')}
        name='brand_id'
        form={form}
      >
        {currentConverter?.brand && (
          <Select.Option
            key={currentConverter?.brand?.id}
            value={currentConverter?.brand?.id}
          >
            {currentConverter?.brand?.name}
          </Select.Option>
        )}
        {converterBrands?.map((page) => {
          return page?.data?.data?.map((brand) => {
            if (currentConverter?.brand?.id !== brand?.id) {
              return (
                <Select.Option key={brand?.id} value={brand?.id}>
                  {brand?.name}
                </Select.Option>
              )
            } else {
              return null
            }
          })
        })}
      </SelectItem>

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <InputItem error={errors?.type} label={t('type')} name='type' />

      <InputItem
        error={errors?.max_dc}
        label={t('max_dc')}
        type='number'
        name='max_dc'
      />

      <InputItem
        error={errors?.max_ac}
        label={t('max_ac')}
        type='number'
        name='max_ac'
      />

      <InputItem
        error={errors?.boot_power}
        label={t('boot_power')}
        name='boot_power'
        type='number'
      />

      <InputItem
        error={errors?.phases}
        label={t('phases')}
        name='phases'
        type='number'
      />

      <InputItem
        error={errors?.amp}
        label={t('amp')}
        type='number'
        name='amp'
      />

      <InputItem
        label={t('length_in_cm')}
        error={errors?.length}
        type='number'
        name='length'
      />

      <InputItem
        label={t('width_in_cm')}
        error={errors?.width}
        type='number'
        name='width'
      />

      <InputItem
        label={t('depth_in_cm')}
        error={errors?.depth}
        type='number'
        name='depth'
      />

      <InputItem
        error={errors?.efficiency}
        label={t('efficiency')}
        name='efficiency'
        type='number'
      />

      <AmountInputItem
        defaultValue={currentConverter?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={currentConverter?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />

      <InputItem
        label={t('warranty_per_year')}
        error={errors?.warranty}
        name='warranty'
        type='number'
      />
    </FormModalWrapper>
  )
}

export default ConverterFormInputs
