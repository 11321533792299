import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { statuses } from 'src/common'

function Notification({
  onNotificationClick,
  projectReference,
  notificationType,
  projectName,
  dateAndTime,
  statusFrom,
  panelName,
  userName,
  statusTo,
  isNew,
}) {
  const { t } = useTranslation()
  const { date, time } = dateAndTime || {}

  return (
    <div onClick={onNotificationClick} className='notifications__notification'>
      <p className='notifications__notification__text'>
        <Trans
          i18nKey={`notification_${notificationType}`} // optional -> fallbacks to defaults if not provided
          values={{
            statusFrom: t(
              `${statuses?.find((status) => status.id === statusFrom)?.name}`
            ),
            statusTo: t(
              `${statuses?.find((status) => status.id === statusTo)?.name}`
            ),
            projectName: projectName || 'Project',
            userName: userName || 'User',
            panelName: panelName || 'Panel',
            projectReference,
          }}
          components={{ bold: <strong /> }}
        />
      </p>
      <div className='notifications__notification__info'>
        <div className='notifications__notification__date-and-time'>
          <span>{date}</span>
          <div className='notifications__notification__separator' />
          <span>{time}</span>
        </div>
        {isNew ? (
          <span className='notifications__notification__status'>
            {t('new')}
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default Notification
