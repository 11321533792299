import { canManageProject, formItemErrorClasses, inputStyles } from 'src/utils'
import { useAttachPanelToProject } from './useAttachPanelToProject'
import { Form, Select, InputNumber, Button, message } from 'antd'
import { ShowFieldError, AgreeDeleteModal } from 'src/common'
import { slopeAngle, direction } from '../../../constants'
import { useEffect } from 'react'

const Panel = ({
  data = null,
  deletePanel = () => {},
  deleteDisable = false,
  projectCompanyId,
}) => {
  const [form] = Form.useForm()

  const {
    attachPanelToProject,
    panelBrands,
    panels,
    panel,
    isAdding,
    setBrandId,
    setPanel,
    layout,
    error,
    translate,
    closeConfirmationModal,
    openConfirmationModal,
    showConfirmationModal,
    fetchBrandOnScroll,
    fetchPanelOnScroll,
    handlePanelChange,
  } = useAttachPanelToProject({ data, clearForm, projectCompanyId })

  function clearForm() {
    if (!data) {
      form.resetFields()
      setPanel(null)
      setBrandId(null)
      deletePanel()
    }
    if (data && data?.panel?.id !== panel?.id) {
      form.resetFields()
      setPanel(data?.panel)
      setBrandId(data?.panel?.brand_id)
    }

    if (data && data?.panel?.id === panel?.id) {
      message.success(` ${translate('updated_successfully')}`)
    }
  }

  useEffect(() => {
    if (data) {
      form.resetFields()
    }
    if (openConfirmationModal) {
      closeConfirmationModal()
    }
  }, [data])

  return (
    <Form
      form={form}
      onFinish={(val) => {
        const { een_merk, type, ...otherData } = val || {}
        return attachPanelToProject({ ...otherData })
      }}
      className='accordionArrayItem'
      name='panels'
      initialValues={{
        angle: slopeAngle.find((item) => item?.name === data?.angle)?.id || '',
        type: data?.panel?.type,
        een_merk: data?.panel?.brand_id,
        quantity: data?.quantity,
        direction:
          direction.find((item) => {
            const direction = translate(item?.translationKey, {
              lng: 'nl',
            })
            return direction === data?.direction
          })?.id || '',
      }}
      {...layout}
    >
      <Form.Item
        className={formItemErrorClasses(error?.brand_id)}
        label={translate('selecteer_een_merk')}
        required
      >
        <Form.Item name='een_merk' noStyle>
          <Select
            onPopupScroll={fetchBrandOnScroll}
            className={error?.brand_id ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(val) => {
              setBrandId(val)
              form.setFieldsValue({
                type: '',
                quantity: '',
                direction: '',
                angle: '',
              })
              setPanel(null)
            }}
          >
            {data?.panel?.brand && (
              <Select.Option
                key={data?.panel?.brand?.id}
                value={data?.panel?.brand?.id}
              >
                {data?.panel?.brand?.name}
              </Select.Option>
            )}
            {panelBrands?.map((page) => {
              return page?.data?.data?.map((brand) => {
                if (data?.panel?.brand?.id !== brand?.id) {
                  return (
                    <Select.Option key={brand?.id} value={brand?.id}>
                      {brand?.name}
                    </Select.Option>
                  )
                } else {
                  return null
                }
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.brand_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.panel_id)}
        label={translate('select_type')}
        required
      >
        <Form.Item name='type' noStyle>
          <Select
            className={error?.panel_id ? 'antd-select-error ' : ''}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={!panels || !canManageProject()}
            onChange={handlePanelChange}
            onPopupScroll={fetchPanelOnScroll}
          >
            {panels?.map((page) => {
              return page?.data?.data?.map((panel) => {
                return (
                  <Select.Option key={panel?.id} value={panel?.type}>
                    {panel?.type}
                  </Select.Option>
                )
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.panel_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.quantity)}
        required
        name='quantity'
        label={translate('quantity')}
      >
        <Form.Item name='quantity' noStyle>
          <InputNumber
            style={inputStyles(error?.quantity)}
            disabled={!canManageProject()}
            className='input_number'
            min={0}
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.quantity} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.direction)}
        label={translate('direction')}
        required
      >
        <Form.Item name='direction' noStyle>
          <Select
            className={error?.direction ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            name='panels_direction'
          >
            {direction?.map((item) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.direction} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.angle)}
        label={translate('slope_angle')}
        required
      >
        <Form.Item name='angle' noStyle>
          <Select
            className={error?.angle ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            name='panels_direction'
          >
            {slopeAngle.map((item) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.angle} />
      </Form.Item>

      <div className='productinfo'>
        <Form.Item label={translate('name')}>{panel?.name || ''}</Form.Item>
        <Form.Item label={translate('description')}>
          {panel?.description || ''}
        </Form.Item>
        <Form.Item label={translate('wp/watt')}>{panel?.watt || ''}</Form.Item>
        <Form.Item label={translate('voc/v')}>{panel?.voc || ''}</Form.Item>
        <Form.Item label={translate('imp')}>{panel?.lmp || ''}</Form.Item>
        <Form.Item label={translate('dimensions_mm')}>
          {panel ? `${panel?.length} x ${panel?.width} x ${panel?.depth}` : ''}
        </Form.Item>
        <Form.Item label={translate('weight_kg')}>
          {panel?.weight || ''}
        </Form.Item>
      </div>

      <div className='accordionArrayItem__removeSurveyProduct'>
        <Button
          onClick={() => {
            if (!canManageProject()) return

            if (data) {
              return openConfirmationModal()
            } else {
              deletePanel()
            }
          }}
          disabled={isAdding || deleteDisable || !canManageProject()}
          type={data ? 'danger' : 'secondary'}
        >
          {!data ? translate('close') : translate('delete')}
        </Button>
        <Button
          disabled={isAdding || !canManageProject()}
          htmlType='submit'
          type='primary'
        >
          {!data || (data && data?.panel?.id !== panel?.id)
            ? translate('save')
            : translate('update')}
        </Button>
      </div>
      {showConfirmationModal ? (
        <AgreeDeleteModal
          visible={showConfirmationModal}
          text={translate('panel_delete_warning')}
          yesText={translate('yes')}
          noText={translate('no')}
          onCancel={closeConfirmationModal}
          onSubmit={() => deletePanel(data?.id)}
          disabled={deleteDisable}
        />
      ) : null}
    </Form>
  )
}

export default Panel
