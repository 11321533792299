import { setRemoveUser } from 'src/actions'
import store from 'src/store/store'
import { message } from 'antd'
import i18next from 'i18next'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URI}/`,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return new Promise((resolve) => {
      return resolve(response)
    })
  },

  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(setRemoveUser())
    }

    if (error.response?.status === 403) {
      if (error.response.config.method !== 'get') {
        message.error(error.response.data?.message)
      }
    }

    return Promise.reject(error)
  }
)

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization'] && localStorage.getItem('token-esc')) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'token-esc'
      )}`
    }

    if (!config.headers['Locale']) {
      config.headers['Locale'] = i18next.language
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
