import { Radio } from 'antd'

const QuotationVAT = ({
  text,
  price,
  active,
  onVatChange,
  vatValue,
  disabled,
}) => {
  return (
    <div className={`total__totalItem ${active ? 'active' : ''}`}>
      <div className='vat-ratio-buttons'>
        <span style={{ marginRight: '0.5rem' }}>{text && text}:</span>
        <Radio.Group
          disabled={disabled}
          onChange={onVatChange}
          value={vatValue}
        >
          <Radio value={21}>21%</Radio>
          <Radio value={6}>6%</Radio>
          <Radio value={0}>0%</Radio>
        </Radio.Group>
      </div>
      <div>€ {price && price}</div>
    </div>
  )
}

export default QuotationVAT
