import ListHeader from './ListHeader'
import PlanningHeader from './PlanningHeader'
import PlanningProjects from './PlanningProjects'
import TaskPlanning from './TaskPlanning'
import PlanningSearch from './PlanningSearch'
import { useHandleView } from './hooks/useHandleView'
import { Redirect } from 'react-router'
import { PlanningCalendar } from './PlanningCalendar'
import { ProjectViewModal } from './ProjectViewModal'
import { useProjectViewModal } from './hooks'
import { Header } from 'src/common'

function Planning() {
  const {
    isSolarPlanningEnabled,
    isProjectListActive,
    isCalendarActive,
    isTasksActive,
    t,
  } = useHandleView()

  const { projectViewModalOnClick, modalData, openProjectViewModal } =
    useProjectViewModal()

  if (!isSolarPlanningEnabled) {
    return <Redirect to='/' />
  }

  return (
    <div className='planning'>
      <Header
        left={
          <p className='planning__title--desktop'>{t('planning_pipeline')}</p>
        }
      />

      <div className='planning-wrapper'>
        <ListHeader
          isProjectListActive={isProjectListActive}
          isCalendarActive={isCalendarActive}
          isTasksActive={isTasksActive}
        />
        <PlanningSearch />
        {isProjectListActive ? (
          <PlanningProjects projectViewModalOnClick={projectViewModalOnClick} />
        ) : null}
        {isTasksActive ? <TaskPlanning /> : null}
        {isCalendarActive ? (
          <PlanningCalendar
            onEditClick={() => projectViewModalOnClick({ id: 2224 })}
          />
        ) : null}
        {openProjectViewModal ? (
          <ProjectViewModal
            openProjectViewModal={openProjectViewModal}
            modalData={modalData}
            projectViewModalOnClick={projectViewModalOnClick}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Planning
