import {
  MultiselectFooter,
  MultiselectOption,
  MultiselectSearch,
} from './index'

function MultiselectContainer({
  options,
  labelKey,
  searchQuery,
  setSearchQuery,
  filteredOptions,
  selectedOptions,
  headerTitle,
  handleOptionSelect,
  resetSelectedOptions,
  handleSelectOptions,
  handleSaveOptions,
  searchPlaceholder,
}) {
  return (
    <>
      <div className='multiselect_options'>
        <div className='multiselect_options_header'>{headerTitle}</div>
        <MultiselectSearch
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchPlaceholder={searchPlaceholder}
        />
        <div className='multiselect_options_container'>
          {filteredOptions.map((option) => {
            return (
              <MultiselectOption
                key={option.id}
                option={option}
                labelKey={labelKey}
                selectedOptions={selectedOptions}
                handleOptionSelect={handleOptionSelect}
              />
            )
          })}
        </div>
        <MultiselectFooter
          resetSelectedOptions={resetSelectedOptions}
          handleSelectOptions={handleSelectOptions}
          handleSaveOptions={handleSaveOptions}
        />
      </div>
    </>
  )
}

export default MultiselectContainer
