import ShowPowerOptimizerModal from './components/ShowPowerOptimizer'
import AddPowerOptimizer from './components/AddPowerOptimizer'
import PowerOptimizerColumns from './PowerOptimizerColumns'
import { usePowerOptimizers } from './usePowerOptimizers'
import { canPerform, getTableSortValue } from 'src/utils'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Header,
  Search,
} from 'src/common'

const PowerOptimizers = () => {
  const {
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    isPowerOptimizersDataLoading,
    selectedPowerOptimizersIds,
    setShowPowerOptimizerModal,
    setAddPowerOptimizerModal,
    setDeletePowerOptimizer,
    showPowerOptimizerModal,
    addPowerOptimizerModal,
    importPowerOptimizers,
    exportPowerOptimizers,
    deletePowerOptimizer,
    DeletePowerOptimizer,
    deletingMultipleItem,
    setShowImportCsvModal,
    deleteSelectedItems,
    showImportCsvModal,
    handlePageChange,
    powerOptimizers,
    exportDisabled,
    setImportError,
    paginationInfo,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  } = usePowerOptimizers()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('power_optimizers')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isPowerOptimizersDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            disabled={
              canPerform('manage power-optimizer') ||
              canPerform('manage own power-optimizer')
                ? false
                : true
            }
            text={t('add')}
            onClick={() => setAddPowerOptimizerModal(true)}
          />
          <ButtonWithTrash
            disabled={!selectedPowerOptimizersIds.length}
            onClick={showMultipleDeleteModalHandler}
            text={t('delete_selected_item')}
          />
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          columns={PowerOptimizerColumns(setDeletePowerOptimizer, t)}
          dataSource={powerOptimizers}
          onRow={onRow}
          loading={isPowerOptimizersDataLoading}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
          bordered
        />

        <div className='contentFooter'>
          <CsvButton
            onClick={exportPowerOptimizers}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage power-optimizer') ||
              canPerform('manage own power-optimizer')
                ? false
                : true
            }
            onClick={() => setShowImportCsvModal(true)}
            text={t('import_cvs')}
          />
        </div>
      </div>

      {showPowerOptimizerModal.show && (
        <ShowPowerOptimizerModal
          setShowPowerOptimizerModal={setShowPowerOptimizerModal}
          currentPowerOptimizer={showPowerOptimizerModal.data}
          show={showPowerOptimizerModal.show}
        />
      )}

      {addPowerOptimizerModal && (
        <AddPowerOptimizer
          setAddPowerOptimizerModal={setAddPowerOptimizerModal}
          show={addPowerOptimizerModal}
        />
      )}

      {showImportCsvModal && (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          submitModal={importPowerOptimizers}
          setImportError={setImportError}
          importError={importError}
          disabled={importing}
        />
      )}

      {deletePowerOptimizer.show && (
        <AgreeDeleteModal
          visible={deletePowerOptimizer.show}
          targetName={deletePowerOptimizer?.data?.name}
          onCancel={() => setDeletePowerOptimizer({ show: false, data: {} })}
          onSubmit={() =>
            deletePowerOptimizer.data.id &&
            DeletePowerOptimizer(deletePowerOptimizer?.data?.id)
          }
          disabled={deleting}
        />
      )}

      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          disabled={deletingMultipleItem}
          onSubmit={deleteSelectedItems}
        />
      )}
    </div>
  )
}

export default PowerOptimizers
