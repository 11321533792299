import { MultiselectContainer } from './index'
import { useTeamAndEmployeeMultiselect } from '../../hooks'

function TeamAndEmployeeMultiselectContainer({
  teams,
  handleSelectOptions,
  isSelectOpen,
  setIsSelectOpen,
  searchableKey,
  headerTitle,
  searchPlaceholder,
}) {
  const {
    selectedOptions,
    searchQuery,
    setSearchQuery,
    handleOptionSelect,
    filteredOptions,
    resetSelectedOptions,
    handleSaveOptions,
  } = useTeamAndEmployeeMultiselect({
    options: teams,
    searchableKey: searchableKey,
    isSelectOpen,
    setIsSelectOpen,
  })

  return (
    <div className='task_multiselect'>
      <MultiselectContainer
        options={teams}
        labelKey={searchableKey}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filteredOptions={filteredOptions}
        selectedOptions={selectedOptions}
        headerTitle={headerTitle}
        handleOptionSelect={handleOptionSelect}
        resetSelectedOptions={resetSelectedOptions}
        handleSelectOptions={handleSelectOptions}
        handleSaveOptions={handleSaveOptions}
        searchPlaceholder={searchPlaceholder}
      />
    </div>
  )
}

export default TeamAndEmployeeMultiselectContainer
