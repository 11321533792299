import { useState } from 'react'

function useTaskHandler() {
  const [tasks, setTasks] = useState([
    {
      date: '2023-6-03',
      departure_time: '13:00',
      start_finish_time: '11:00/18:00',
      task_status: 'approved',
      planned_work: 'rame',
      machinery: 'rame',
      team: 'team',
    },
  ])

  function addNewTask() {
    const newTask = {
      date: '2023-6-03',
      departure_time: '13:00',
      start_finish_time: '11:00/18:00',
      task_status: 'approved',
      planned_work: 'rame',
      machinery: 'rame',
      team: 'team',
    }

    newTask.is_new = true

    setTasks((prevTasks) => [...prevTasks, newTask])
  }

  function deleteTask(index) {
    setTasks((prevTasks) => {
      const updatedTasks = [...prevTasks]
      updatedTasks.splice(index, 1)
      return updatedTasks
    })
  }

  return {
    tasks,
    addNewTask,
    deleteTask,
  }
}

export { useTaskHandler }
