import { Radio } from 'antd'
import { useTaskRadioSwitcher } from './hooks'
import { taskViewEnum } from './data'

function TaskViewRadioSwitcher({
  handleCalendarViewClick,
  handleTasksViewClick,
  isCalendarActive,
  isTasksActive,
}) {
  const { handleRadioChange, radioValue, t } = useTaskRadioSwitcher({
    handleCalendarViewClick,
    handleTasksViewClick,
    isCalendarActive,
    isTasksActive,
  })

  return (
    <div className='task-radio-switcher-wrapper'>
      <div className='task-radio-switcher'>
        <Radio.Group value={radioValue} onChange={handleRadioChange}>
          <Radio
            style={{ color: isTasksActive ? '#1F73E2' : '#606870' }}
            value={taskViewEnum.list}
          >
            {t('list')}
          </Radio>
          <Radio
            style={{ color: isCalendarActive ? '#1F73E2' : '#606870' }}
            value={taskViewEnum.calendar}
          >
            {t('calendar')}
          </Radio>
        </Radio.Group>
      </div>
    </div>
  )
}

export default TaskViewRadioSwitcher
