import { useTranslation } from 'react-i18next'
import { useSelectOption } from '../../hooks'

function SelectOption({ option, textColor, chooseOption }) {
  const { t } = useTranslation()
  const { optionColor } = useSelectOption({ option, textColor })

  return (
    <div
      className='select_option'
      onClick={() => {
        return chooseOption(option, optionColor)
      }}
    >
      <div
        style={{
          color: optionColor ? optionColor?.text : '#343A40',
          background: optionColor ? optionColor.background : '#FFF',
        }}
      >
        {t(option.name)}
      </div>
    </div>
  )
}

export default SelectOption
