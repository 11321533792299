import Button from 'antd/es/button'
import RenderOptionsTypes from './Accordions/RenderOptionsTypes'
import { useOptionsWizard } from './useOptionsWizard'

const OptionsWizard = ({ attachedOptions = [], projectCompanyId }) => {
  const {
    isActivitiesOrMaterials,
    attaChedActivities,
    attaChedMaterials,
    groupedActivities,
    groupedMaterials,
    fetchOptions,
    t,
  } = useOptionsWizard({ attachedOptions, projectCompanyId })

  return (
    <div className='options'>
      {!!groupedActivities ? (
        <div className='content'>
          <div className='content__title'>{'activities'}</div>

          {!!groupedActivities &&
            Object.keys(groupedActivities)?.map((key, index) => {
              return (
                <RenderOptionsTypes
                  title={groupedActivities[key]?.[0]?.type?.name}
                  key={index}
                  options={groupedActivities[key]}
                  attaChedActivities={attaChedActivities}
                />
              )
            })}
        </div>
      ) : null}

      {!!groupedMaterials ? (
        <div className='content'>
          <div className='content__title'>{t('materials')}</div>

          {Object.keys(groupedMaterials)?.map((key, index) => {
            return (
              <RenderOptionsTypes
                title={key}
                key={index}
                options={groupedMaterials[key]}
                attaChedMaterials={attaChedMaterials}
                materials
              />
            )
          })}
        </div>
      ) : null}
      {isActivitiesOrMaterials ? (
        <Button
          style={{ marginTop: '2rem' }}
          type='primary'
          onClick={fetchOptions}
        >
          {t('load_more_options')}
        </Button>
      ) : null}
    </div>
  )
}

export default OptionsWizard
