import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHandleQueryParams } from 'src/hooks'

function usePlanningSearch() {
  const { t } = useTranslation()

  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  const [planningSearchValue, setPlanningSearchValue] = useState('')

  function handlePlanningSearchChange(e) {
    setPlanningSearchValue(e?.target?.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(handlePlanningSearchClick, 800)
    return () => clearTimeout(timeoutId)
  }, [planningSearchValue])

  function handlePlanningSearchClick() {
    if (planningSearchValue) {
      appendQueryParams({ search: planningSearchValue })
    } else {
      deleteQueryParams('search')
    }
  }

  return {
    handlePlanningSearchChange,
    planningSearchValue,
    t,
  }
}

export { usePlanningSearch }
