import { useHistory } from 'react-router'
import { useQueryParams } from './useQueryParams'
import { useHandleQueryParams } from './useHandleQueryParams'

const INITIAL_PAGE_VALUES = {
  page: 1,
  size: 10,
}

export function usePageQueryParams(pageName = 'page', sizeName = 'size') {
  const history = useHistory()
  const query = useQueryParams()

  const { appendQueryParams, deleteQueryParams } = useHandleQueryParams()

  const page = Number(query.get(pageName)) || INITIAL_PAGE_VALUES.page
  const size = Number(query.get(sizeName)) || INITIAL_PAGE_VALUES.size

  function handlePageChange(currentPage, currentSize) {
    const page = currentPage
    const size = currentSize

    const { page: initialPage, size: initialSize } = INITIAL_PAGE_VALUES
    if (page !== initialPage && size !== initialSize) {
      appendQueryParams({ [pageName]: page, [sizeName]: size })
    } else if (currentPage !== initialPage) {
      appendQueryParams({ [pageName]: page })
    } else if (currentSize !== initialSize) {
      appendQueryParams({ [sizeName]: size })
    }

    if (page === initialPage) {
      deleteQueryParams(pageName)
    }
    if (currentSize === initialSize) {
      deleteQueryParams(sizeName)
    }
  }

  const navigateToPreviousQueryPageParam = () => {
    const url = new URL(window.location.href)
    url.searchParams.set('page', page - 1)
    history.replace(url.pathname + url.search)
  }

  return {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  }
}
