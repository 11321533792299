import { LeftArrowIcon, LeftArrowIconMobile } from 'src/assets'
import { Link, useHistory } from 'react-router-dom'

const LinkContent = ({ title = '' }) => {
  return (
    <>
      <div className='back-link--desktop'>
        <LeftArrowIcon />
      </div>

      <div className='back-link--mobile'>
        <LeftArrowIconMobile />
      </div>

      {title ? <p className='back-link__title'>{title}</p> : ''}
    </>
  )
}

const BackLink = ({ path = null, title = '' }) => {
  const { goBack } = useHistory()

  return (
    <>
      {path ? (
        <Link className='back-link' to={path}>
          <LinkContent title={title} />
        </Link>
      ) : (
        <div className='back-link' onClick={goBack}>
          <LinkContent title={title} />
        </div>
      )}
    </>
  )
}

export default BackLink
