import { Button } from 'antd'

function LoadMoreButton({
  type = 'primary',
  className = '',
  fetchNextPage,
  isLoading,
  text,
}) {
  return (
    <div className={`load-more-button ${className}`}>
      <Button disabled={isLoading} onClick={fetchNextPage} type={type}>
        {text}
      </Button>
    </div>
  )
}

export default LoadMoreButton
