import { inputStyles, formItemErrorClasses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import { InputNumber } from 'antd'
import { Form } from 'antd'

const NumberInputItem = ({
  onChangeHandler = () => {},
  controls = false,
  required = true,
  className = '',
  error = '',
  max = 100,
  min = 0,
  label,
  name,
}) => {
  return (
    <Form.Item
      className={`custom-antd-input-item ${formItemErrorClasses(
        error
      )} ${className}`}
      required={required}
      label={label}
    >
      <Form.Item name={name}>
        <InputNumber
          style={{
            width: '100%',
            borderRadius: '8px',
            ...inputStyles(error),
          }}
          onChange={onChangeHandler}
          controls={controls}
          min={min}
          max={max}
        />
      </Form.Item>

      <ShowFieldError withHeight error={error} />
    </Form.Item>
  )
}

export default NumberInputItem
