function PlannerIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M8 5.78571C8.39556 5.78571 8.78224 5.90092 9.11114 6.11676C9.44004 6.33259 9.69638 6.63937 9.84776 6.9983C9.99913 7.35723 10.0387 7.75218 9.96157 8.13321C9.8844 8.51425 9.69392 8.86425 9.41421 9.13896C9.13451 9.41367 8.77814 9.60075 8.39018 9.67654C8.00222 9.75234 7.60009 9.71344 7.23463 9.56476C6.86918 9.41609 6.55682 9.16432 6.33706 8.8413C6.1173 8.51827 6 8.1385 6 7.75C6 7.22904 6.21071 6.72942 6.58579 6.36104C6.96086 5.99267 7.46957 5.78571 8 5.78571ZM8 5C7.44621 5 6.90486 5.16128 6.4444 5.46346C5.98395 5.76563 5.62506 6.19512 5.41314 6.69762C5.20121 7.20012 5.14576 7.75305 5.2538 8.2865C5.36184 8.81995 5.62851 9.30995 6.0201 9.69454C6.41169 10.0791 6.9106 10.3411 7.45375 10.4472C7.99689 10.5533 8.55988 10.4988 9.07151 10.2907C9.58315 10.0825 10.0204 9.73005 10.3281 9.27782C10.6358 8.82558 10.8 8.2939 10.8 7.75C10.8 7.02065 10.505 6.32118 9.9799 5.80546C9.4548 5.28973 8.74261 5 8 5ZM12 16H11.2V14.0357C11.2 13.5148 10.9893 13.0151 10.6142 12.6468C10.2391 12.2784 9.73043 12.0714 9.2 12.0714H6.8C6.26957 12.0714 5.76086 12.2784 5.38579 12.6468C5.01071 13.0151 4.8 13.5148 4.8 14.0357V16H4V14.0357C4 13.3064 4.295 12.6069 4.8201 12.0912C5.3452 11.5754 6.05739 11.2857 6.8 11.2857H9.2C9.94261 11.2857 10.6548 11.5754 11.1799 12.0912C11.705 12.6069 12 13.3064 12 14.0357V16ZM12 5.78571H16V6.57143H12V5.78571ZM12 7.75H16V8.53571H12V7.75ZM12 9.71429H14.8V10.5H12V9.71429Z'
        fill='#868E96'
      />
    </svg>
  )
}

export { PlannerIcon }
