import { useTranslation } from 'react-i18next'

export const useSurveyWizard = ({ panels, mountingSystems }) => {
  const { t } = useTranslation()

  function calculateProductsQuantity(products = []) {
    return products?.reduce((acc, product) => {
      return acc + product?.quantity
    }, 0)
  }

  return {
    mountingSystemsQuantity: calculateProductsQuantity(mountingSystems),
    panelsQuantity: calculateProductsQuantity(panels),
    t,
  }
}
