import { useQuery, useMutation, useQueryClient } from 'react-query'
import { usePageQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { downloadFile } from 'src/utils'
import { useState } from 'react'
import {
  generalConditionsByTypeRequest,
  importGeneralConditionsRequest,
  deleteGeneralConditionRequest,
  exportRequest,
} from 'src/services'

export const PAYMENT_CONDITION_TYPE = 2

export function usePaymentConditions() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [importError, setImportError] = useState()
  const [searchKeyWord, setSearchKeyWord] = useState('')

  const { user } = useSelector((state) => state.globalSlice)

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const [deletePaymentCondition, setDeletePaymentCondition] = useState({
    show: false,
    data: {},
  })

  const [showPaymentConditionModal, setShowPaymentConditionModal] = useState({
    show: false,
    data: {},
  })

  const [addPaymentConditionModal, setAddPaymentConditionModal] =
    useState(false)

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const {
    data: paymentConditionsData,
    isLoading: isPaymentConditionsDataLoading,
    isPreviousData,
  } = useQuery(
    [
      'paymentConditions',
      PAYMENT_CONDITION_TYPE,
      page,
      size,
      searchKeyWord,
      user?.company?.id,
    ],
    () =>
      generalConditionsByTypeRequest({
        type: PAYMENT_CONDITION_TYPE,
        page,
        companyId: user?.company?.id,
        size,
        searchKeyWord,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { mutate: deletePayment, isLoading: isPaymentConditionsDeleting } =
    useMutation(deleteGeneralConditionRequest, {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          setDeletePaymentCondition({ show: false, data: {} })
          queryClient.invalidateQueries('paymentConditions')
        }
      },
    })

  const { mutate: importConditions, isLoading: importing } = useMutation(
    importGeneralConditionsRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('paymentConditions')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportConditionsMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp.data, 'materials')
      },
    })

  function exportConditions() {
    exportConditionsMutation({
      model: 'conditions',
      type: 2,
    })
  }

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const onRow = (record) => {
    return {
      onClick: () => setShowPaymentConditionModal({ show: true, data: record }),
    }
  }

  return {
    exportDisabled: exporting || !paymentConditionsData?.data?.data?.length,
    paymentConditions: paymentConditionsData?.data?.data,
    paginationInfo: {
      total: paymentConditionsData?.data?.meta?.total,
      page,
    },
    isPaymentConditionsDataLoading:
      isPaymentConditionsDataLoading || isPreviousData,
    setShowPaymentConditionModal,
    isPaymentConditionsDeleting,
    setAddPaymentConditionModal,
    setDeletePaymentCondition,
    showPaymentConditionModal,
    addPaymentConditionModal,
    deletePaymentCondition,
    setShowImportCsvModal,
    showImportCsvModal,
    handlePageChange,
    exportConditions,
    importConditions,
    setImportError,
    onSearchClick,
    deletePayment,
    importError,
    importing,
    onRow,
    t,
  }
}
