import { useAllInViewRequest, useOffer } from './hooks'
import { Quotation } from 'src/screens/Qutation'
import { Delivery } from 'src/screens/Delivery'
import AllInViewModal from './AllInViewModal'
import OfferProducts from './OfferProducts'
import { ConfirmOrder } from 'src/screens'
import { CheckWizard } from '../wizards'
import OfferHeader from './OfferHeader'
import { joinPanels } from 'src/utils'
import { memo } from 'react'

function Offer({
  customerSignatureExists,
  canCreateAllInView,
  allInviewMediaUrl,
  disableAllInView,
  offerCreatedAt,
  openedOffers,
  offerStatus,
  offerNumber,
  offerSent,
  remoteId,
  offerId,
}) {
  const {
    requiredProductsMessage,
    isOrderCreated,
    onProductOpenClose,
    quotationIsSigned,
    canSeeQuotation,
    canSeeDelivery,
    offerOpenState,
    showOfferPage,
    onProductEdit,
    isStepEnabled,
    isLoading,
    offerData,
    offer,
  } = useOffer(offerId, openedOffers)
  const {
    isAllInViewModalOpen,
    closeAllInViewModal,
    allInViewIframeSrc,
    allInViewError,
    isAllViewLoading,
    reTryGetAllInViewUrl,
    onAllInViewClick,
  } = useAllInViewRequest({ remoteId, offerId, canCreateAllInView })

  return (
    <>
      <OfferHeader
        requiredProductsMessage={requiredProductsMessage}
        customerSignatureExists={customerSignatureExists}
        isOrderCreated={offerCreatedAt || isOrderCreated}
        offerSent={offerSent || offer?.offer_sent_at}
        offerQuotationSent={offer?.quotation_sent_at}
        customerSignature={offer?.customer_signature}
        disableProductEditing={isOrderCreated}
        onProductOpenClose={onProductOpenClose}
        allInviewMediaUrl={allInviewMediaUrl}
        quotationIsSigned={quotationIsSigned}
        onAllInViewClick={onAllInViewClick}
        disableAllInView={disableAllInView}
        hasPdfOffer={offer?.has_pdf_offer}
        checkSignature={offer?.signature}
        canSeeQuotation={canSeeQuotation}
        canSeeDelivery={canSeeDelivery}
        offerOpenState={offerOpenState}
        offerName={offer?.offer?.name}
        offerPath={offer?.offer?.path}
        isStepEnabled={isStepEnabled}
        onProductEdit={onProductEdit}
        openedOffers={openedOffers}
        isOfferLoading={isLoading}
        offerStatus={offerStatus}
        offerNumber={offerNumber}
        open={!!offerOpenState}
        offerId={offerId}
      />

      {showOfferPage('products') ? (
        <OfferProducts
          products={[
            ...joinPanels(offer?.offer_panels),
            ...offer?.offer_converters,
            ...offer?.offer_power_optimizers,
            ...offer?.offer_wifi_modules,
            ...offer?.offer_mounting_systems,
            ...offer?.offer_options,
          ]}
        />
      ) : null}

      {showOfferPage('check') && isStepEnabled() ? (
        <CheckWizard
          showAdditionalPriceDetails={offer?.show_additional_price_details}
          powerOptimizers={offer?.offer_power_optimizers}
          mountingSystems={offer?.offer_mounting_systems}
          installationWeek={offer?.installation_week}
          projectDiscount={offer?.project_discount}
          disableCheckEditing={isOrderCreated}
          wifiModules={offer?.offer_wifi_modules}
          converters={offer?.offer_converters}
          options={offer?.offer_options}
          correction={offer?.correction}
          panels={offer?.offer_panels}
          totals={{
            margin: offer?.margin,
            margin_in_percent: offer?.margin_in_percent,
            subtotal_selling_price: offer?.subtotal_selling_price,
            total_purchase_price: offer?.total_purchase_price,
            total_selling_price: offer?.total_selling_price,
          }}
          contact={{
            name: offer?.project?.manager?.name,
            email: offer?.project?.manager?.email,
          }}
          address={{
            street: offer?.project?.street,
            house_number: offer?.project?.house_number,
            zipcode: offer?.project?.zipcode,
            location: offer?.project?.location,
          }}
          offerId={offerId}
          signatureSrc={
            offer?.signature
              ? `${offer?.signature?.path}/${offer?.signature?.name}`
              : ''
          }
        />
      ) : null}

      {showOfferPage('quotation') && canSeeQuotation ? (
        <Quotation
          disableQuotationEditing={isOrderCreated}
          correction={offer?.correction}
          panels={offer?.offer_panels}
          converters={offer?.offer_converters}
          powerOptimizers={offer?.offer_power_optimizers}
          wifiModules={offer?.offer_wifi_modules}
          mountingSystems={offer?.offer_mounting_systems}
          options={offer?.offer_options}
          installationWeek={offer?.installation_week}
          subtotal={offer?.total_selling_price}
          agreement={offer?.agreement}
          attachInspection={offer?.project?.has_inspection_file}
          reference={offer?.project?.reference}
          contact={{
            name: offer?.project?.name,
            email: offer?.project?.email,
            phoneNumber: offer?.project?.phone_number,
          }}
          address={{
            street: offer?.project?.street,
            house_number: offer?.project?.house_number,
            zipcode: offer?.project?.zipcode,
            location: offer?.project?.location,
          }}
          accountManagerInfo={{
            name: offer?.project?.manager?.name,
            email: offer?.project?.manager?.email,
            phoneNumber: offer?.project?.manager?.phone_number,
          }}
          quotationSignatures={{
            quotHeadSignatureDate: offer?.head_signature?.created_at,
            quotCustomerSignatureDate: offer?.customer_signature?.created_at,
            quotHeadSignature: offer?.head_signature
              ? `${offer?.head_signature?.path}/${offer?.head_signature?.name}`
              : '',

            quotCustomerSignature: offer?.customer_signature
              ? `${offer?.customer_signature?.path}/${offer?.customer_signature?.name}`
              : '',
          }}
          offerAdditionDocument={offer?.additional_docs}
          company={offer?.project?.company}
          vatNumber={offer?.vat}
          offerId={offerId}
          offer={offer}
        />
      ) : null}

      {showOfferPage('confirmOrder') ? (
        <ConfirmOrder offerData={offerData} />
      ) : null}

      {showOfferPage('delivery') && canSeeDelivery ? (
        <Delivery
          powerOptimizers={offer?.offer_power_optimizers}
          mountingSystems={offer?.offer_mounting_systems}
          installationWeek={offer?.installation_week}
          wifiModules={offer?.offer_wifi_modules}
          converters={offer?.offer_converters}
          options={offer?.offer_options}
          files={offer?.delivery_files}
          panels={offer?.offer_panels}
          deliverySignatures={{
            delHeadSignatureDate: offer?.del_head_signature?.created_at,
            delCustomerSignatureDate: offer?.del_customer_signature?.created_at,
            delHeadSignature: offer?.del_head_signature
              ? `${offer?.del_head_signature?.path}/${offer?.del_head_signature?.name}`
              : '',
            delCustomerSignature: offer?.del_customer_signature
              ? `${offer?.del_customer_signature?.path}/${offer?.del_customer_signature?.name}`
              : '',
          }}
          offerId={offerId}
        />
      ) : null}

      {isAllInViewModalOpen ? (
        <AllInViewModal
          reTryGetAllInViewUrl={reTryGetAllInViewUrl}
          isAllViewLoading={isAllViewLoading}
          iframeSrc={allInViewIframeSrc}
          show={isAllInViewModalOpen}
          close={closeAllInViewModal}
          error={allInViewError}
        />
      ) : null}
    </>
  )
}

export default memo(Offer)
