import { Tags, TagSettings, AllTagsModal } from './components'
import { useTagsList } from './useTagsList'
import { statuses } from 'src/common'

const TagsList = ({
  accountManagersForm,
  statusFiltersForm,
  findStatusCount,
  managersList,
  managerIds,
  statusIds,
}) => {
  const { t, clearAllHandler, showAllTagsModal, setShowAllTagsModal } =
    useTagsList({ accountManagersForm, statusFiltersForm })

  return (
    <div>
      {showAllTagsModal && (
        <AllTagsModal
          setShowAllTagsModal={setShowAllTagsModal}
          accountManagersForm={accountManagersForm}
          statusFiltersForm={statusFiltersForm}
          showAllTagsModal={showAllTagsModal}
          clearAllHandler={clearAllHandler}
          findStatusCount={findStatusCount}
          managersList={managersList}
          managerIds={managerIds}
          statusIds={statusIds}
          statuses={statuses}
        />
      )}

      <div className='tags__container'>
        <div className='tags__list'>
          <Tags
            accountManagersForm={accountManagersForm}
            statusFiltersForm={statusFiltersForm}
            findStatusCount={findStatusCount}
            managersList={managersList}
            managerIds={managerIds}
            statusIds={statusIds}
            statuses={statuses}
          />
        </div>

        <div className='clear__all__tags' onClick={clearAllHandler}>
          {t('clear-all')}
        </div>

        <div className='tags__list__mobile'>
          <div className='tags'>
            <Tags
              accountManagersForm={accountManagersForm}
              statusFiltersForm={statusFiltersForm}
              findStatusCount={findStatusCount}
              managersList={managersList}
              managerIds={managerIds}
              statusIds={statusIds}
              statuses={statuses}
              sliceEnd={2}
            />
          </div>
          <TagSettings
            setShowAllTagsModal={setShowAllTagsModal}
            clearAllHandler={clearAllHandler}
          />
        </div>
      </div>
    </div>
  )
}

export default TagsList
