import { useMutation, useQueryClient, useQuery } from 'react-query'
import { useFileUpload } from 'src/hooks/useFileUpload'
import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OPTIONS_TYPE } from 'src/common'
import { useParams } from 'react-router'
import { message } from 'antd'
import {
  deleteOfferDeliveryImageRequest,
  saveOfferDeliveryRequest,
  userInfoRequest,
} from 'src/services'
import {
  handleNonValidationError,
  handleValidationError,
  canManageProject,
  dataUrlToFile,
} from 'src/utils'

export function useDeliveryWizard({
  options = [],
  headquartersSignature,
  customerSignature,

  headquartersSignatureSrc,
  customerSignatureSrc,
  files = [],
  offerId,
}) {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const [deliveredGoods, setDeliveredGoods] = useState([])
  const [uploadedDelHeadId, setUploadedDelHeadId] = useState(null)
  const [uploadedDelCustomerId, setUploadedDelCustomerId] = useState(null)

  const [isMechanicAutoSigned, setIsMechanicAutoSigned] = useState(false)

  const { uploadFile, uploadError, isUploading } = useFileUpload()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (uploadedDelHeadId && !headquartersSignature) {
      setUploadedDelHeadId(null)
    }
  }, [headquartersSignature])

  useEffect(() => {
    if (uploadedDelCustomerId && !customerSignature) {
      setUploadedDelCustomerId(null)
    }
  }, [customerSignature])

  const materials = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.material
    )
  }, [options])

  const activities = useMemo(() => {
    return options?.filter(
      (option) => option?.optionable_type === OPTIONS_TYPE.activity
    )
  }, [options])

  const { data: userData } = useQuery('user_info', userInfoRequest)

  const {
    mutate: saveDeliveryMutation,
    isLoading: saving,
    error: saveError,
  } = useMutation(
    ({ offerId, data }) => saveOfferDeliveryRequest(offerId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['offer', offerId])
        queryClient.invalidateQueries(['projects', projectId])
        queryClient.invalidateQueries(['offers', projectId])

        message.success(t('saved_successfully'))
        setDeliveredGoods([])
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const { mutate: deleteDeliveryImageMutation } = useMutation(
    ({ offerId, fileId }) => deleteOfferDeliveryImageRequest(offerId, fileId),
    {
      onSuccess: () => {
        message.success(`${t('deleted_successfully')}`)
        queryClient.invalidateQueries(['offers', offerId])
      },
      onError: (err) => {
        handleNonValidationError(err)
      },
    }
  )

  async function saveDelivery() {
    if (!canManageProject()) return

    const data = {
      step: 3,
    }

    let delHeadSignatureFileObj = { del_head_signature_id: null }

    if (!isMechanicAutoSigned) {
      delHeadSignatureFileObj = await uploadDelHeadSignature()
    } else {
      const signatureId = userData?.data?.draft_signature?.id
      if (signatureId) {
        delHeadSignatureFileObj.del_head_signature_id = signatureId
      }
    }

    const delCustomerSignatureFileObj = await uploadDelCustomerSignature()

    const uploadedDelGoodsIds = await uploadDeliveryGoods()

    const formDataValues = {
      ...data,
      ...delHeadSignatureFileObj,
      ...delCustomerSignatureFileObj,
      del_file_ids: uploadedDelGoodsIds,
    }

    saveDeliveryMutation({
      offerId,
      data: formDataValues,
    })
  }

  function handleDeliveryGoodsChange(value) {
    setDeliveredGoods([...value])
  }

  function handleDeliveryGoodsRemove(value) {
    if (!value?.originFileObj) {
      deleteDeliveryImageMutation({ offerId, fileId: value?.uid })
    }
  }

  async function uploadDelHeadSignature() {
    if (headquartersSignatureSrc) return {}
    if (headquartersSignature && !uploadedDelHeadId) {
      const quotationHeadQuartersSignatureFile = !headquartersSignatureSrc
        ? await dataUrlToFile(
            headquartersSignature,
            'headquartersSignature.png'
          )
        : ''
      let data = {
        file: quotationHeadQuartersSignatureFile,
        media_type: 'del_head_signature',
      }
      let response = await uploadFile({ url: 'signature', data })
      setUploadedDelHeadId(response?.data?.id)

      return { del_head_signature_id: response?.data?.id }
    }
    if (uploadedDelHeadId) {
      return { del_head_signature_id: uploadedDelHeadId }
    }
  }

  async function uploadDelCustomerSignature() {
    if (customerSignatureSrc) return {}
    if (customerSignature && !uploadedDelCustomerId) {
      const quotationCustomerSignatureFile = !customerSignatureSrc
        ? await dataUrlToFile(customerSignature, 'customerSignature.png')
        : ''
      let data = {
        file: quotationCustomerSignatureFile,
        media_type: 'del_customer_signature',
      }
      let response = await uploadFile({ url: 'signature', data })
      setUploadedDelCustomerId(response?.data?.id)
      return { del_customer_signature_id: response?.data?.id }
    }
    if (uploadedDelCustomerId) {
      return { del_customer_signature_id: uploadedDelCustomerId }
    }
  }

  async function uploadDeliveryGoods() {
    if (!deliveredGoods.length) return
    let uploadedGoodsIds = []

    for (const deliveredGood of deliveredGoods) {
      const file = deliveredGood?.originFileObj
      if (file) {
        const res = await uploadFile({
          url: 'delivery-file',
          data: { file },
        })

        uploadedGoodsIds.push(res?.data?.id)
      }
    }
    return uploadedGoodsIds
  }

  function getLoadingState() {
    return saving || isUploading
  }

  function getValidationError() {
    if (getLoadingState()) return {}
    const save = handleValidationError(saveError)
    const upload = handleValidationError(uploadError)
    return { ...save, ...upload }
  }

  const defaultFiles = useMemo(() => {
    if (!!files && files?.length) {
      return files.map((file) => ({
        uid: file?.id,
        url: `${process.env.REACT_APP_BACKEND_URI}/storage/${file?.path}/${file?.name}`,
      }))
    } else {
      return null
    }
  }, [files])

  return {
    userSignatureData: userData?.data?.draft_signature,
    validationError: getValidationError(),
    handleDeliveryGoodsChange,
    handleDeliveryGoodsRemove,
    saving: getLoadingState(),
    setIsMechanicAutoSigned,
    isMechanicAutoSigned,
    defaultFiles,
    saveDelivery,
    activities,
    materials,
    t,
  }
}
