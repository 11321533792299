import { routes, PrivateRoute } from 'src/routes'
import { Switch } from 'react-router-dom'

const Content = () => {
  return (
    <>
      <Switch>
        {routes()?.map((route, idx) => {
          if (route) {
            const { path, component: Component, name, exact } = route || {}

            return (
              <PrivateRoute
                key={idx}
                path={path}
                exact={exact}
                name={name}
                render={(props) => <Component {...props} />}
              />
            )
          } else {
            return null
          }
        })}
      </Switch>
    </>
  )
}

export default Content
