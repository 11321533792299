import { userFormConstants } from '../../constants/userFormConstants'
import { useMutation, useQueryClient } from 'react-query'
import { updateUserRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { changeLanguage } from 'i18next'
import { Form, message } from 'antd'
import {
  appendPrefix,
  handleNonValidationError,
  handleValidationError,
  roles,
} from 'src/utils'

export const useShowUserModal = (
  updateUserInfo,
  user,
  currentUser,
  setShowUserModal
) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    form.setFieldsValue({
      role: currentUser?.roles?.[0]?.id,
      company_id: currentUser?.company?.id,
    })
  }, [currentUser?.company?.id, currentUser?.roles, form])

  const [showUserValues, setShowUserValues] = useState({
    ...userFormConstants,
  })

  const closeModal = () => setShowUserModal({ show: false, data: {} })

  const {
    isLoading,
    mutate: updateUser,
    error,
  } = useMutation(
    (variables) => {
      const { roles: userRole, phone_number, ...other } = variables || {}
      const phone =
        phone_number !== null
          ? { phone_number: appendPrefix(phone_number) }
          : {}
      const customVariables = {
        ...other,
        password: variables?.passwordSearch,
        company_id:
          user?.roles?.[0]?.id === roles.superUser
            ? variables?.company_id
            : user?.company_id,
        max_discount: variables?.max_discount,
        commission: variables?.commission,
        ...phone,
      }

      return !isLoading && updateUserRequest(currentUser?.id, customVariables)
    },

    {
      onSuccess: (resp, variables) => {
        if (resp?.data?.success) {
          queryClient.refetchQueries()

          if (user?.id === variables?.id) {
            updateUserInfo(resp.data?.user)
            changeLanguage(resp?.data?.user?.lang)
          }

          message.success(t('updated_successfully'))
          closeModal()
        }
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    getFormValues()
  }, [currentUser])

  function getFormValues() {
    if (currentUser) {
      setShowUserValues({
        ...userFormConstants,
        ...currentUser,
        role: currentUser?.roles?.[0]?.id,
      })
    }
  }

  return {
    showUserValues,
    closeModal,
    isLoading,
    updateUser,
    error: handleValidationError(error),
    form,
    t,
  }
}
