export async function dataUrlToFile(dataUrl, fileName) {
  try {
    if (!dataUrl) return null
    const res = await fetch(dataUrl)
    const blob = await res.blob()
    return new File([blob], fileName, { type: 'image/png' })
  } catch (error) {
    console.log(error)
  }
}
