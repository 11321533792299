import { Collapse } from 'antd'
import { AccordionWrapper } from 'src/common'

import { MountingSystem, AddInputs } from './Components'

import { useMountingSystemAccordion } from './useMountingSystemAccordion'
import { canManageProject } from 'src/utils'

const MountingSystems = ({
  mountingSystems = [],
  panelsQuantity = 0,
  mountingSystemsQuantity = 0,
  projectCompanyId,
}) => {
  const {
    removeMountingSystem,
    removingMountingSystem,
    showMountingSystemAddingForm,
    closeMountingSystemAddingForm,
    openMountingSystemAddingForm,
    translate,
  } = useMountingSystemAccordion()

  return (
    <div className='survey__accordion'>
      <AccordionWrapper>
        <Collapse.Panel header={translate('mounting_system_upper')} key='1'>
          <div>
            {/* <Typography.Title level={4}>Mounting Systems</Typography.Title> */}
            {mountingSystems?.map((item) => (
              <MountingSystem
                deleteMountingSystem={removeMountingSystem}
                deleteDisable={removingMountingSystem}
                data={item}
                key={item?.id}
                panelsQuantity={panelsQuantity}
                mountingSystemsQuantity={
                  mountingSystems?.length > 1 ? mountingSystemsQuantity : 0
                }
                projectCompanyId={projectCompanyId}
              />
            ))}
            {showMountingSystemAddingForm ? (
              <MountingSystem
                projectCompanyId={projectCompanyId}
                deleteMountingSystem={closeMountingSystemAddingForm}
                panelsQuantity={panelsQuantity}
                mountingSystemsQuantity={
                  mountingSystems?.length ? mountingSystemsQuantity : 0
                }
              />
            ) : null}
          </div>
          <AddInputs
            onClick={openMountingSystemAddingForm}
            disabled={showMountingSystemAddingForm || !canManageProject()}
          />
        </Collapse.Panel>
      </AccordionWrapper>
    </div>
  )
}

export default MountingSystems
