export const LogoutIcon = ({ width = 20, height = 19, color = '#ADB5BD' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 19' fill='none'>
      <path
        d='M0 3.62233V14.5172C0 15.5203 0.41848 16.412 1.08805 17.1086C1.75762 17.7773 2.62248 18.1395 3.62683 18.1395H9.09497V15.8825H3.62683C2.87357 15.8825 2.28769 15.2695 2.28769 14.5172V3.62233C2.28769 2.87 2.87357 2.28486 3.62683 2.28486H9.09497V0H3.62683C2.62248 0 1.75762 0.390097 1.08805 1.05884C0.41848 1.75544 0 2.61923 0 3.62233ZM5.99822 6.9103V11.2571C5.99822 11.7587 6.4446 12.1766 6.94677 12.1766H11.9964V15.6039C11.9964 15.9104 12.1638 16.1612 12.4428 16.3005C12.5544 16.3284 12.666 16.3284 12.7218 16.3284C12.9171 16.3284 13.0845 16.2726 13.224 16.1333L19.7802 9.58525C20.0871 9.33448 20.0592 8.83292 19.7802 8.55428L13.224 2.03408C12.8334 1.61612 11.9964 1.8669 11.9964 2.53563V5.99078H6.94677C6.4446 5.99078 5.99822 6.40874 5.99822 6.9103Z'
        fill={color}
      />
    </svg>
  )
}
