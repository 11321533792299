import { InputItem, CheckboxInput } from 'src/common'
import { useTranslation } from 'react-i18next'

const AdditionalInformationTab = ({ form, errors, data }) => {
  const { t } = useTranslation()

  return (
    <>
      <InputItem
        label={t('show_planning_token')}
        error={errors?.offerte_link}
        name='planning_token'
        required={false}
      />

      <CheckboxInput
        defaultChecked={!!data?.enable_solarplanning}
        className='solar__planning__checkbox'
        text={t('enable_solar_planning') + ':'}
        name='enable_solarplanning'
        form={form}
      />

      <CheckboxInput
        defaultChecked={!!data?.module_reports}
        className='solar__planning__checkbox'
        text={t('enable_reporting') + ':'}
        name='module_reports'
        form={form}
      />

      <CheckboxInput
        defaultChecked={!!data?.module_leads}
        className='solar__planning__checkbox'
        text={t('enable_leads_form') + ':'}
        name='module_leads'
        form={form}
      />
    </>
  )
}
export default AdditionalInformationTab
