import { Switch, Form } from 'antd'

const SwitchButton = ({
  onChange = () => {},
  defaultChecked,
  size = 'small',
  className = '',
  label,
  name,
  form,
}) => {
  return (
    <Form.Item
      className={`antd-switch-button ${className} ${name}`}
      label={label}
      name={name}
    >
      <Switch
        onChange={(checked) => {
          form.setFieldsValue({
            [name]: checked ? 1 : 0,
          })

          onChange && onChange(checked)
        }}
        defaultChecked={defaultChecked}
        size={size}
      />
    </Form.Item>
  )
}

export default SwitchButton
