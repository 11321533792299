import {
  HomeScreen,
  Quotation,
  Delivery,
  Planning,
  NewLeads,
  Profile,
  Project,
  Design,
} from 'src/screens'

/* reporting screens */
import {
  FinancialReporting,
  MaterialReporting,
  ProjectsOverview,
  GeneralReporting,
  ReportingScreen,
} from 'src/screens'

/* manage screens */
import {
  PaymentConditions,
  GeneralConditions,
  DocumentLibrary,
  PowerOptimizers,
  MountingSystem,
  ManageScreen,
  Activities,
  Converters,
  WifiModule,
  Companies,
  Dashboard,
  Materials,
  Company,
  Panels,
  Users,
} from 'src/screens'

import {
  showPowerOptimizers,
  showMountingSystems,
  showWifiModules,
  showConditions,
  showActivities,
  showConverters,
  showMaterials,
  showCompanies,
  showSettings,
  showProjects,
  showReports,
  isSuperUser,
  showPanels,
  showUsers,
  hasRole,
  isAdmin,
  roles,
} from 'src/utils'

const routes = () => [
  {
    component: Dashboard,
    name: 'Dashboard',
    exact: true,
    path: '/',
  },

  showProjects()
    ? {
        path: '/sales-projects',
        component: HomeScreen,
        exact: true,
        name: 'Home',
      }
    : null,

  { path: '/quotation', name: 'Quotation', component: Quotation },
  { path: '/delivery', name: 'Delivery', component: Delivery },
  { path: '/new-leads', name: 'New Leads', component: NewLeads },

  showReports()
    ? {
        path: '/reporting',
        exact: true,
        name: 'Reporting',
        component: ReportingScreen,
      }
    : null,
  showReports()
    ? {
        path: '/reporting/financial',
        name: 'FinancialReporting',
        component: FinancialReporting,
      }
    : null,
  showReports()
    ? {
        path: '/reporting/material',
        name: 'MaterialReporting',
        component: MaterialReporting,
      }
    : null,
  showReports()
    ? {
        path: '/reporting/projects_overview',
        name: 'ProjectsOverview',
        component: ProjectsOverview,
      }
    : null,
  showReports()
    ? {
        path: '/reporting/general-reporting',
        name: 'GeneralReporting',
        component: GeneralReporting,
      }
    : null,

  /* manage screens */
  showSettings()
    ? {
        path: '/manage',
        exact: true,
        name: 'Manage',
        component: ManageScreen,
      }
    : null,

  showUsers()
    ? { path: '/manage/users', name: 'Users', component: Users }
    : null,

  showCompanies()
    ? {
        path: '/manage/companies',
        name: 'Companies',
        component: Companies,
      }
    : null,

  showCompanies()
    ? {
        path: '/manage/company',
        component: Company,
        name: 'company',
        exact: true,
      }
    : null,

  showMaterials()
    ? {
        path: '/manage/materials',
        name: 'Materials',
        component: Materials,
      }
    : null,

  showConverters()
    ? {
        path: '/manage/converters',
        name: 'Converters',
        component: Converters,
      }
    : null,

  showPowerOptimizers()
    ? {
        path: '/manage/power-optimizers',
        name: 'Power-optimizers',
        component: PowerOptimizers,
      }
    : null,

  showMountingSystems()
    ? {
        path: '/manage/mounting-systems',
        name: 'Mounting-Systems',
        component: MountingSystem,
      }
    : null,

  showWifiModules()
    ? {
        path: '/manage/wifi-module',
        name: 'Wifi-Module',
        component: WifiModule,
      }
    : null,

  showConditions()
    ? {
        path: '/manage/general-conditions',
        name: 'General Conditions',
        component: GeneralConditions,
      }
    : null,

  isSuperUser() || isAdmin()
    ? {
        path: '/manage/document-library',
        name: 'Document Library',
        component: DocumentLibrary,
      }
    : null,

  showConditions()
    ? {
        path: '/manage/payment-conditions',
        name: 'Payment Conditions',
        component: PaymentConditions,
      }
    : null,

  showPanels()
    ? {
        path: '/manage/panels',
        name: 'Panels',
        component: Panels,
      }
    : null,

  showActivities() || showActivities()
    ? {
        path: '/manage/activities',
        name: 'Activities',
        component: Activities,
      }
    : null,

  { path: '/design', name: 'Design', component: Design },

  { path: '/profile', name: 'Profile', component: Profile },

  { path: '/planning', name: 'Planning', component: Planning },

  showProjects()
    ? {
        path: '/project',
        name: 'Project',
        component: Project,
      }
    : null,

  {
    path: '*',
    name: 'Create Project',
    component: hasRole(roles.supplier) ? ManageScreen : HomeScreen,
  },
]

export default routes
