import { handleNonValidationError, handleValidationError } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { addPowerOptimizerRequest } from 'src/services'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

export const useAddPowerOptimizer = (setAddPowerOptimizerModal) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const {
    isLoading,
    mutate: addPowerOptimizer,
    error,
  } = useMutation(
    (variables) => !isLoading && addPowerOptimizerRequest(variables),
    {
      onSuccess: () => {
        queryClient.refetchQueries('powerOptimizers')
        setAddPowerOptimizerModal(false)
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function handleAdding(values) {
    return addPowerOptimizer({ ...values, company_id: user?.company_id })
  }

  return {
    addPowerOptimizerError: handleValidationError(error),
    handleAdding,
    isLoading,
    form,
    t,
  }
}
