import { useState } from 'react'

function useTaskMultiselect() {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [isTeamContainerOpen, setIsTeamContainerOpen] = useState(false)
  const [isEmployeeContainerOpen, setIsEmployeeContainerOpen] = useState(false)

  const handleSelectOptions = () => {
    setIsSelectOpen(!isSelectOpen)
    if (isSelectOpen) {
      setIsEmployeeContainerOpen(false)
      setIsTeamContainerOpen(false)
    }
  }

  const handleTeamMultiselect = () => {
    setIsTeamContainerOpen(!isTeamContainerOpen)
    setIsEmployeeContainerOpen(false)
  }

  const handleEmployeeMultiselect = () => {
    setIsTeamContainerOpen(false)
    setIsEmployeeContainerOpen(!isEmployeeContainerOpen)
  }

  return {
    isSelectOpen,
    isTeamContainerOpen,
    isEmployeeContainerOpen,
    setIsSelectOpen,
    handleSelectOptions,
    handleTeamMultiselect,
    handleEmployeeMultiselect,
  }
}

export { useTaskMultiselect }
