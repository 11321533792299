export const LeftArrowIconMobile = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g id='Icons'>
        <path
          id='Vector'
          d='M19 12H5'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M12 19L5 12L12 5'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
