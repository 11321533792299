import { ButtonWithPlus, Header, AgreeDeleteModal } from 'src/common'
import DocumentLibraryColumns from './DocumentLibraryColumns'
import useDocumentLibrary from './useDocumentLibrary'
import { AddDocument } from './components'
import { Table } from 'antd'

const DocumentLibrary = () => {
  const {
    deleteDocumentMutation,
    setAddDocumentModal,
    handlePageChange,
    addDocumentModal,
    paginationInfo,
    setDeleteModal,
    isDataLoading,
    documentsList,
    deleteModal,
    isDeleting,
    t,
  } = useDocumentLibrary()

  return (
    <div className='manageScreensLayout'>
      {deleteModal.show && (
        <AgreeDeleteModal
          onCancel={() => setDeleteModal(false)}
          targetName={deleteModal?.data?.title}
          onSubmit={deleteDocumentMutation}
          visible={deleteModal.show}
          disabled={isDeleting}
        />
      )}

      <Header />
      <div className='manageScreensLayout__title'>{t('document_library')}</div>

      <div className='manageScreensLayout__content manageScreensLayout__document-library'>
        <div className='contentheader'>
          <ButtonWithPlus
            onClick={() => setAddDocumentModal(true)}
            text={t('add')}
          />
        </div>

        <Table
          columns={DocumentLibraryColumns(t, setDeleteModal)}
          scroll={{ x: '992px' }}
          dataSource={documentsList}
          rowKey={(record) => record.id}
          pagination={{
            total: paginationInfo?.total,
            current: paginationInfo?.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          loading={isDataLoading}
          bordered
        />
      </div>

      {addDocumentModal && (
        <AddDocument
          setAddDocumentModal={setAddDocumentModal}
          show={addDocumentModal}
        />
      )}
    </div>
  )
}

export default DocumentLibrary
