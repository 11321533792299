import FormPowerOptimizersFormModal from './FormPowerOptimizersFormModal'
import { useShowPowerOptimizerModal } from './hooks'

const ShowPowerOptimizerModal = ({
  setShowPowerOptimizerModal,
  currentPowerOptimizer,
  show,
}) => {
  const {
    updatePowerOptimizerError,
    updatePowerOptimizer,
    isLoading,
    form,
    t,
  } = useShowPowerOptimizerModal(
    setShowPowerOptimizerModal,
    currentPowerOptimizer
  )

  return (
    <FormPowerOptimizersFormModal
      closeHandler={() => setShowPowerOptimizerModal(false)}
      powerOptimizerData={currentPowerOptimizer}
      errors={updatePowerOptimizerError}
      actionButtonTitle={t('update')}
      onFinish={updatePowerOptimizer}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default ShowPowerOptimizerModal
