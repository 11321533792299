import { extractDateAndTime } from 'src/utils'
import { useNotifications } from './hooks'
import Notification from './Notification'
import { Empty, Button } from 'antd'

function Notifications({
  fetchNotificationsOnScroll,
  handleNotificationClick,
  notificationParentRef,
  toggleNotification,
  notifications,
}) {
  const { t, onNotificationClick, markAllAsReadMutation } = useNotifications(
    handleNotificationClick
  )

  return (
    <div
      onScroll={fetchNotificationsOnScroll}
      ref={notificationParentRef}
      className='notifications'
    >
      <div className='notifications__header'>
        <h4 className='notifications__header__title'>{t('notifications')}</h4>

        <Button type='default' onClick={markAllAsReadMutation}>
          {t('mark_all_as_read')}
        </Button>
      </div>

      {notifications?.map((page) => {
        if (!page?.data?.data?.length) {
          return <Empty />
        }

        return page?.data?.data?.map((notification) => {
          return (
            <Notification
              onNotificationClick={() => onNotificationClick(notification)}
              dateAndTime={extractDateAndTime(notification?.created_at)}
              projectReference={`${notification?.data?.project_reference}${
                notification?.data?.offer_code
                  ? `: ${t('offer')}-${notification?.data?.offer_code}`
                  : ''
              }`}
              notificationType={notification?.notification_type}
              statusFrom={notification?.data?.status_id_from}
              projectName={notification?.data?.project_name}
              statusTo={notification?.data?.status_id_to}
              panelName={notification?.data?.panel_name}
              userName={notification?.data?.user_name}
              toggleNotification={toggleNotification}
              isNew={!notification?.read_at}
              key={notification?.id}
            />
          )
        })
      })}
    </div>
  )
}

export default Notifications
