import { useState } from 'react'

function useTimeField() {
  const [isTimeOptionsOpen, setIsTimeOptionsOpen] = useState(false)
  const [time, setTime] = useState(false)
  const timeArray = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const isAM = hour < 12
      const hour12Format = hour % 12 || 12
      const timeLabel = `${String(hour12Format).padStart(2, '0')}:${String(
        minute
      ).padStart(2, '0')} ${isAM ? 'AM' : 'PM'}`
      const timeName = `${isAM ? '0' : ''}${hour}${String(minute).padStart(
        2,
        '0'
      )}`

      timeArray.push({ label: timeLabel, name: timeName })
    }
  }

  const handleTimeOptions = () => {
    setIsTimeOptionsOpen(!isTimeOptionsOpen)
  }

  const setTimeOnClick = (time) => {
    setTime(time)
    setIsTimeOptionsOpen(!isTimeOptionsOpen)
  }

  return {
    isTimeOptionsOpen,
    time,
    timeArray,
    handleTimeOptions,
    setTimeOnClick,
  }
}

export { useTimeField }
