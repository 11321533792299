import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getLogoUrl } from 'src/utils'
import { useState } from 'react'

export const useHeader = () => {
  const { user } = useSelector((state) => state.globalSlice)

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)

  const { t } = useTranslation()

  const isProjectDataModalOpen =
    window.location.href.includes('projectDataModal')

  return {
    logotype: getLogoUrl(user?.company),
    isProjectDataModalOpen,
    setIsBurgerMenuOpen,
    isBurgerMenuOpen,
    t,
  }
}
