import { handleNonValidationError, handleValidationError } from 'src/utils'
import { updateGeneralConditionRequest } from 'src/services'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowGeneralCondition = (
  setShowGeneralConditionModal,
  currentGeneralCondition
) => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const closeModal = () =>
    setShowGeneralConditionModal({ show: false, data: {} })

  const {
    isLoading,
    mutate: updateCondition,
    error,
  } = useMutation(
    (variables) =>
      !isLoading &&
      updateGeneralConditionRequest(currentGeneralCondition?.id, variables),
    {
      onSuccess: () => {
        queryClient.refetchQueries('conditions')
        message.success(t('updated_successfully'))
        closeModal()
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (currentGeneralCondition) {
      form.setFieldsValue(currentGeneralCondition)
    }
  }, [currentGeneralCondition, form])

  return {
    updateConditionError: handleValidationError(error),
    updateCondition,
    closeModal,
    isLoading,
    form,
    t,
  }
}
