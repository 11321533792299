export const ReportingIcon = ({
  color = '#ADB5BD',
  width = 16,
  height = 18,
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 18' fill='none'>
      <path
        d='M5.14453 0.5625C5.14453 0.25184 5.40037 0 5.71596 0H10.2874C10.603 0 10.8588 0.25184 10.8588 0.5625V2.8125C10.8588 3.12316 10.603 3.375 10.2874 3.375H5.71596C5.40037 3.375 5.14453 3.12316 5.14453 2.8125V0.5625Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.85714 1.125H4V3.75C4 4.16422 4.35817 4.5 4.8 4.5H11.2C11.6418 4.5 12 4.16422 12 3.75V1.125H13.1429C14.7208 1.125 16 2.3842 16 3.9375V15.1875C16 16.7408 14.7208 18 13.1429 18H2.85714C1.27919 18 0 16.7408 0 15.1875V3.9375C0 2.3842 1.27919 1.125 2.85714 1.125ZM11.4286 14.0625C11.4286 14.3732 11.1727 14.625 10.8571 14.625C10.5415 14.625 10.2857 14.3732 10.2857 14.0625V7.3125C10.2857 7.00183 10.5415 6.75 10.8571 6.75C11.1727 6.75 11.4286 7.00183 11.4286 7.3125V14.0625ZM8 14.625C8.3156 14.625 8.57143 14.3732 8.57143 14.0625V9.5625C8.57143 9.25183 8.3156 9 8 9C7.6844 9 7.42857 9.25183 7.42857 9.5625V14.0625C7.42857 14.3732 7.6844 14.625 8 14.625ZM5.71429 14.0625C5.71429 14.3732 5.45845 14.625 5.14286 14.625C4.82726 14.625 4.57143 14.3732 4.57143 14.0625V11.8125C4.57143 11.5018 4.82727 11.25 5.14286 11.25C5.45845 11.25 5.71429 11.5018 5.71429 11.8125V14.0625Z'
        fill={color}
      />
    </svg>
  )
}
