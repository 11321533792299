export const STATUS_IDS = {
  PROSPECT: 1,
  PROSPECT_CALL: 2,
  PROSPECT_HOUSE: 3,
  OFFER: 4,
  ON_HOLD: 5,
  APPROVED: 6,
  PARTIALLY_PAYED: 7,
  PLANNING: 8,
  FULLY_PAYED: 9,
  LOST: 10,
  INACTIVE: 11,
  CHECK: 12,
}

export const salesProjectStatusIds = [
  STATUS_IDS.ON_HOLD,
  STATUS_IDS.APPROVED,
  STATUS_IDS.LOST,
]

export const statuses = [
  {
    id: STATUS_IDS.PROSPECT,
    name: 'prospect', //'Prospect' /* prospect */
    color: '#FF7A45',
  },
  {
    id: STATUS_IDS.PROSPECT_CALL,
    name: 'prospect_call', //'Prospect telefonisch' /* prospect call */
    color: '#C41D7F',
  },
  {
    id: STATUS_IDS.PROSPECT_HOUSE,
    name: 'prospect_house', //'Prospect huisbezoek' /* prospect house */
    color: '#871400',
  },
  {
    id: STATUS_IDS.OFFER,
    name: 'offer', //'Offerte' /* offer */
    color: '#531DAB',
  },
  {
    id: STATUS_IDS.ON_HOLD,
    name: 'on_hold', //'In afwachting' /* on hold */
    color: '#D46B08',
  },
  {
    id: STATUS_IDS.APPROVED,
    name: 'approved', //'Akkoord' /* approved */
    color: '#389E0D',
  },
  {
    id: STATUS_IDS.PARTIALLY_PAYED,
    name: 'partialy_payed', //'Aanbetaald' /* partialy payed */
    color: '#A0D911',
  },
  {
    id: STATUS_IDS.PLANNING,
    name: 'planning', //'Planning' /* planning */
    color: '#D4B106',
  },
  {
    id: STATUS_IDS.FULLY_PAYED,
    name: 'fully_payed', //'Volledig betaald' /* fully payed */
    color: '#389E0D',
  },
  {
    id: STATUS_IDS.LOST,
    name: 'lost', //'Verloren' /* lost */
    color: '#F759AB',
  },
  {
    id: STATUS_IDS.INACTIVE,
    name: 'inactive', //'Inactief' /* inactive */
    color: '#B37FEB ',
  },
  {
    id: STATUS_IDS.CHECK,
    name: 'check', //'Controle' /* check */
    color: '#AD6800',
  },
]
