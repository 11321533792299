import { AddNewSource, SourceItem } from './components'
import useLeadIntegration from './useLeadIntegration'
import { NewTabRedirectIcon } from 'src/assets'

const LeadIntegration = ({ companyData }) => {
  const { t, iframePreviewToken, user } = useLeadIntegration(companyData)

  return (
    <div className='company__tab-container__lead-integration'>
      {iframePreviewToken && (
        <ul className='company__tab-container__lead-integration__iframe-list'>
          <li className='iframe-links-container' key={iframePreviewToken.id}>
            <a
              href={`${window.location.origin}/lead-iframe?token=${iframePreviewToken?.token}`}
              target='_blank'
              rel='noreferrer'
            >
              <div className='iframe-links-container__title'>
                <p>{t('iFrame_URL')}</p>
                <NewTabRedirectIcon />
              </div>
            </a>
          </li>
        </ul>
      )}

      <h3 className='company__tab-container__lead-integration__info-text'>
        {t('lead_integration_info')}
      </h3>

      <div className='company__tab-container__lead-integration__source-list'>
        {companyData?.lead_tokens?.map((leadToken) => (
          <SourceItem companyData={companyData} leadToken={leadToken} />
        ))}
      </div>

      {companyData?.id === user?.company?.id && (
        <AddNewSource companyData={companyData} />
      )}
    </div>
  )
}

export default LeadIntegration
