import { useState, useEffect } from 'react'

export const useFormatNumber = (setFieldsValue, name) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setFieldsValue({
      [name]: value,
    })
  }, [name, setFieldsValue, value])

  return {
    setValue,
  }
}
