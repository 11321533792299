import { TableStatusTags, ProjectType } from './components'
import {
  getProfilePictureSrc,
  joinFirstLetters,
  formattedDate,
} from 'src/utils'
import { useTranslation } from 'react-i18next'

const ProjectTableColumns = (archiveProject, deleteProject) => {
  const { t } = useTranslation()

  return [
    {
      title: '#',
      dataIndex: 'id',
      align: 'center',
    },

    {
      title: t('account_manager'),
      dataIndex: ['client', 'manager', 'name'],
      align: 'left',
      sorter: true,
      render: (_, record) => {
        const managerImageSrc = getProfilePictureSrc(record?.manager)

        return record?.manager_name ? (
          <div className='homescreen__manager__container'>
            {managerImageSrc ? (
              <img
                width={32}
                height={32}
                style={{ borderRadius: '50%' }}
                src={managerImageSrc}
                alt='profile'
              />
            ) : (
              <div className='icon__container'>
                {joinFirstLetters(record?.manager_name, 1)}
              </div>
            )}

            <p>{record?.manager_name}</p>
          </div>
        ) : (
          <></>
        )
      },
    },

    {
      title: t('project_name'),
      dataIndex: ['contacts'],
      render: (_, record) => {
        return <div>{record?.name}</div>
      },
      sorter: true,
    },

    {
      title: t('reference'),
      dataIndex: ['client', 'type'],
      sorter: true,
      render: (_, record) => {
        return record.reference
      },
    },

    {
      title: t('date'),
      dataIndex: 'updated_at',
      render: (value) => {
        return formattedDate(value)
      },
      sorter: true,
    },

    {
      title: t('type'),
      dataIndex: ['type'],
      render: (_, record) => {
        return <ProjectType type={record?.type} />
      },
      sorter: true,
    },

    {
      title: t('city'),
      dataIndex: ['location'],
      render: (value) => {
        return value
      },
    },

    {
      title: t('status'),
      dataIndex: ['client', 'status_id'],
      render: (_, record) => {
        return (
          <TableStatusTags
            archiveProject={archiveProject}
            deleteProject={deleteProject}
            value={record?.status_id}
            record={record}
          />
        )
      },
      sorter: true,
    },
  ]
}

export default ProjectTableColumns
