import TextEditor from '../../../editor/Editor'
import { ShowFieldError } from 'src/common'
import { Form } from 'antd'

const CompanyTextEditor = ({
  setFieldsValue,
  initialState,
  fieldName,
  label,
  error,
}) => {
  return (
    <Form.Item
      className={`text-editor ${error ? 'text-editor-error' : ''}`}
      name={fieldName}
      label={label}
    >
      <TextEditor
        onValueChange={(value) => setFieldsValue({ [fieldName]: value })}
        initialState={initialState}
        placeholder={label}
      />
      <ShowFieldError withHeight error={error} />
    </Form.Item>
  )
}

export default CompanyTextEditor
