import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { usePageQueryParams } from 'src/hooks'
import { useSelector } from 'react-redux'
import { downloadFile } from 'src/utils'
import { useState } from 'react'
import {
  generalConditionsByTypeRequest,
  importGeneralConditionsRequest,
  deleteGeneralConditionRequest,
  exportRequest,
} from 'src/services'

export const GENERAL_CONDITION_TYPE = 1

export function useGeneralConditions() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [importError, setImportError] = useState()

  const { user } = useSelector((state) => state.globalSlice)

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const [deleteGeneralCondition, setDeleteGeneralCondition] = useState({
    show: false,
    data: {},
  })

  const [addGeneralConditionModal, setAddGeneralConditionModal] =
    useState(false)

  const [showGeneralConditionModal, setShowGeneralConditionModal] = useState({
    show: false,
    data: {},
  })

  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [searchKeyWord, setSearchKeyWord] = useState('')

  const {
    data: conditionsData,
    isLoading: isGeneralConditionsDataLoading,
    isPreviousData,
  } = useQuery(
    [
      'conditions',
      GENERAL_CONDITION_TYPE,
      page,
      size,
      searchKeyWord,
      user?.company?.id,
    ],
    () =>
      generalConditionsByTypeRequest({
        type: GENERAL_CONDITION_TYPE,
        page,
        companyId: user?.company?.id,
        size,
        searchKeyWord,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { mutate: deleteCondition, isLoading: isGeneralConditionDeleting } =
    useMutation(deleteGeneralConditionRequest, {
      onSuccess: (resp) => {
        if (resp.data.success) {
          queryClient.invalidateQueries('conditions')
          setDeleteGeneralCondition({ show: false, data: {} })
        }
      },
    })

  const { mutate: importConditions, isLoading: importing } = useMutation(
    importGeneralConditionsRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('conditions')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportConditionsMutation, isLoading: exporting } =
    useMutation(exportRequest, {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
    })

  function exportConditions() {
    exportConditionsMutation({
      model: 'conditions',
      type: 1,
    })
  }

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const onRow = (record) => {
    return {
      onClick: () => setShowGeneralConditionModal({ show: true, data: record }),
    }
  }

  return {
    exportDisabled: exporting || !conditionsData?.data?.data?.length,
    generalConditions: conditionsData?.data?.data,
    paginationInfo: {
      total: conditionsData?.data?.meta?.total,
      page,
    },
    isGeneralConditionsDataLoading:
      isGeneralConditionsDataLoading || isPreviousData,
    setShowGeneralConditionModal,
    setAddGeneralConditionModal,
    isGeneralConditionDeleting,
    showGeneralConditionModal,
    setDeleteGeneralCondition,
    addGeneralConditionModal,
    deleteGeneralCondition,
    setShowImportCsvModal,
    showImportCsvModal,
    handlePageChange,
    exportConditions,
    importConditions,
    deleteCondition,
    setImportError,
    onSearchClick,
    importError,
    importing,
    onRow,
    t,
  }
}
