import { useControlsButton } from './hooks'

const ControlsButton = (props) => {
  const { isActive, onClickButton } = useControlsButton(props)

  return (
    <div
      className={`controls-button ${isActive && 'controls-button-active'}`}
      onMouseDown={onClickButton}
      style={props.displayStyle}
    >
      {props.label}
    </div>
  )
}

export default ControlsButton
