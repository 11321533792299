import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const getLibraryDocumentsRequest = ({ size = 10, page, companyId }) => {
  return axios.get(
    `api/media?page=${page}&per_page=${size}${appendCompanyId(companyId)}`
  )
}

export const deleteDocumentRequest = (id) => {
  return axios.delete(`api/media/${id}`)
}
