import { useAttachMountingSystemToProject } from './useAttachMountingSystemToProject'
import { canManageProject, inputStyles, formItemErrorClasses } from 'src/utils'
import { direction, placeType, slopeAngle } from '../../../constants'
import { Form, Select, InputNumber, Button, message } from 'antd'
import { ShowFieldError, AgreeDeleteModal } from 'src/common'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const MountingSystem = ({
  deleteMountingSystem = () => {},
  data = null,
  deleteDisable = false,
  panelsQuantity = 0,
  mountingSystemsQuantity = 0,
  projectCompanyId,
}) => {
  const [form] = Form.useForm()

  const {
    attachMountingSyStemToProject,
    error,
    layout,
    mountingSystemBrands,
    mountingSystems,
    mountingSystem,
    setMountingSystem,
    setBrandId,
    isAdding,
    closeConfirmationModal,
    openConfirmationModal,
    showConfirmationModal,
    onQuantityChange,
    fetchBrandOnScroll,
    fetchMountingSystemOnScroll,
    handleMountingSystemChange,
  } = useAttachMountingSystemToProject({ data, clearForm, projectCompanyId })

  const { t } = useTranslation()

  function clearForm() {
    if (!data) {
      form.resetFields()
      setMountingSystem(null)
      setBrandId(null)
      deleteMountingSystem()
    }
    if (data && data?.mounting_system?.id !== mountingSystem?.id) {
      form.resetFields()
      setMountingSystem(data?.mounting_system)
      setBrandId(data?.mounting_system?.brand_id)
    }
    if (data && data?.mounting_system?.id === mountingSystem?.id) {
      message.success(`${t('updated_successfully')}`)
    }
  }

  useEffect(() => {
    if (data) {
      form.resetFields()
    }
    if (openConfirmationModal) {
      closeConfirmationModal()
    }
  }, [data])

  return (
    <Form
      form={form}
      className='accordionArrayItem'
      name='mountingSystems'
      {...layout}
      onFinish={(values) => {
        const { type, enn_merk, ...otherData } = values || {}
        attachMountingSyStemToProject({
          ...otherData,
        })
      }}
      initialValues={{
        enn_merk: data?.mounting_system?.brand_id,
        type: data?.mounting_system?.type,
        quantity: data?.quantity,
        placement: data?.placement,
        orientation: data?.orientation,
        angle: data?.angle,
      }}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Form.Item
        className={formItemErrorClasses(error?.brand_id)}
        required
        label={t('selecteer_een_merk')}
      >
        <Form.Item name='enn_merk' noStyle>
          <Select
            className={error?.brand_id ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            onPopupScroll={fetchBrandOnScroll}
            onChange={(val) => {
              setBrandId(val)
              form.setFieldsValue({
                type: '',
                quantity: '',
                placement: '',
                orientation: '',
                angle: '',
              })
              setMountingSystem(null)
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {data?.mounting_system?.brand && (
              <Select.Option
                key={data?.mounting_system?.brand?.id}
                value={data?.mounting_system?.brand?.id}
              >
                {data?.mounting_system?.brand?.name}
              </Select.Option>
            )}
            {mountingSystemBrands?.map((page) => {
              return page?.data?.data?.map((brand) => {
                if (data?.mounting_system?.brand?.id !== brand?.id) {
                  return (
                    <Select.Option key={brand?.id} value={brand?.id}>
                      {brand?.name}
                    </Select.Option>
                  )
                } else {
                  return null
                }
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.brand_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.mounting_system_id)}
        required
        label={t('select_type')}
      >
        <Form.Item name='type' noStyle>
          <Select
            className={error?.mounting_system_id ? 'antd-select-error ' : ''}
            virtual={false}
            disabled={!mountingSystems || !canManageProject()}
            onChange={handleMountingSystemChange}
            getPopupContainer={(trigger) => trigger.parentNode}
            onPopupScroll={fetchMountingSystemOnScroll}
          >
            {mountingSystems?.map((page) => {
              return page?.data?.data?.map((mountingSystem) => {
                return (
                  <Select.Option
                    key={mountingSystem?.id}
                    value={mountingSystem?.type}
                  >
                    {mountingSystem?.type}
                  </Select.Option>
                )
              })
            })}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.mounting_system_id} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.quantity)}
        required
        label={t('quantity')}
      >
        <Form.Item name='quantity' noStyle>
          <InputNumber
            style={inputStyles(error?.quantity)}
            onChange={(inputValue) => {
              onQuantityChange(
                inputValue,
                mountingSystemsQuantity,
                panelsQuantity,
                t
              )
            }}
            disabled={!canManageProject()}
            className='input_number'
            min={0}
            controls={false}
          />
        </Form.Item>
        <ShowFieldError error={error?.quantity} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.placement)}
        required
        label={t('select_placement')}
      >
        <Form.Item name='placement' noStyle>
          <Select
            className={error?.placement ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            name='mountingSystem_placement'
          >
            {placeType.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {t(item.name)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.placement} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.orientation)}
        required
        label={t('select_orientation')}
      >
        <Form.Item name='orientation' noStyle>
          <Select
            className={error?.orientation ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {direction.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.orientation} />
      </Form.Item>

      <Form.Item
        className={formItemErrorClasses(error?.angle)}
        required
        label={t('slope_angle')}
      >
        <Form.Item name='angle' noStyle>
          <Select
            className={error?.angle ? 'antd-select-error ' : ''}
            disabled={!canManageProject()}
            virtual={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            name='panels_direction'
          >
            {slopeAngle.map((item) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.angle} />
      </Form.Item>

      <div className='productinfo'>
        <Form.Item label={t('name')}>{mountingSystem?.name}</Form.Item>
        <Form.Item label={t('material')}>{mountingSystem?.material}</Form.Item>
        <Form.Item label={t('description')}>
          {mountingSystem?.description}
        </Form.Item>
      </div>

      <div className='accordionArrayItem__removeSurveyProduct'>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            if (!canManageProject()) return

            if (data) {
              return openConfirmationModal()
            } else {
              deleteMountingSystem()
            }
          }}
          disabled={isAdding || deleteDisable || !canManageProject()}
          type={data ? 'danger' : 'secondary'}
        >
          {!data ? t('close') : t('delete')}
        </Button>
        <Button
          disabled={isAdding || !canManageProject()}
          htmlType='submit'
          type='primary'
        >
          {!data || (data && data?.mounting_system?.id !== mountingSystem?.id)
            ? t('save')
            : t('update')}
        </Button>
      </div>
      <AgreeDeleteModal
        visible={showConfirmationModal}
        targetName={data?.mounting_system?.name}
        yesText={t('yes')}
        noText={t('no')}
        onCancel={closeConfirmationModal}
        disabled={deleteDisable}
        onSubmit={() => deleteMountingSystem(data?.id)}
      />
    </Form>
  )
}
export default MountingSystem
