import AddMountingSystemModal from './components/AddMountingSystemModal'
import ShowMountingSystem from './components/ShowMountingSystemModal'
import MountingSystemColumns from './MountingSystemColumns'
import { useMountingSystems } from './useMountingSystems'
import { canPerform, getTableSortValue } from 'src/utils'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Header,
  Search,
} from 'src/common'

const MountingSystem = () => {
  const {
    showMultipleDeleteModalHandler,
    showMultipleDeleteConfirmation,
    setShowAddMountingSystemModal,
    isMountingSystemsDataLoading,
    showAddMountingSystemModal,
    selectedMountingSystemsIds,
    setShowMountingSystemModal,
    showMountingSystemModal,
    setDeleteMountingSystem,
    importMountingSystems,
    exportMountingSystems,
    setShowImportCsvModal,
    DeleteMountingSystem,
    deleteMountingSystem,
    deletingMultipleItem,
    deleteSelectedItems,
    showImportCsvModal,
    handlePageChange,
    mountingSystems,
    exportDisabled,
    setImportError,
    paginationInfo,
    onSearchClick,
    rowSelection,
    importError,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  } = useMountingSystems()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('mounting_systems')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isMountingSystemsDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            disabled={
              canPerform('manage mounting-system') ||
              canPerform('manage own mounting-system')
                ? false
                : true
            }
            text={t('add')}
            onClick={() => setShowAddMountingSystemModal(true)}
          />
          <ButtonWithTrash
            onClick={showMultipleDeleteModalHandler}
            disabled={!selectedMountingSystemsIds.length}
            text={t('delete_selected_item')}
          />
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          columns={MountingSystemColumns(setDeleteMountingSystem, t)}
          dataSource={mountingSystems}
          onRow={onRow}
          loading={isMountingSystemsDataLoading}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
          bordered
        />
        <div className='contentFooter'>
          <CsvButton
            onClick={exportMountingSystems}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage mounting-system') ||
              canPerform('manage own mounting-system')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showAddMountingSystemModal && (
        <AddMountingSystemModal
          setShowAddMountingSystemModal={setShowAddMountingSystemModal}
          show={showAddMountingSystemModal}
        />
      )}

      {showMountingSystemModal.show && (
        <ShowMountingSystem
          setShowMountingSystemModal={setShowMountingSystemModal}
          currentMountingSystem={showMountingSystemModal.data}
          show={showMountingSystemModal.show}
        />
      )}

      {showImportCsvModal && (
        <ImportCvsModal
          closeModal={() => setShowImportCsvModal(false)}
          submitModal={importMountingSystems}
          setImportError={setImportError}
          show={showImportCsvModal}
          importError={importError}
          disabled={importing}
        />
      )}

      {deleteMountingSystem.show && (
        <AgreeDeleteModal
          visible={deleteMountingSystem.show}
          targetName={deleteMountingSystem?.data?.name}
          onCancel={() => setDeleteMountingSystem({ show: false, data: {} })}
          onSubmit={() =>
            deleteMountingSystem.data.id &&
            DeleteMountingSystem(deleteMountingSystem.data.id)
          }
          disabled={deleting}
        />
      )}

      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          disabled={deletingMultipleItem}
          onSubmit={deleteSelectedItems}
        />
      )}
    </div>
  )
}

export default MountingSystem
