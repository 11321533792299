import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { renderPlanningProjectTableColumns } from './tableColumns'
import { useGetOrders } from './hooks'

function PlanningProjects({ projectViewModalOnClick }) {
  const { t } = useTranslation()

  const { paginationInfo, handlePageChange, isOrdersLoading, orders } =
    useGetOrders()

  return (
    <div className='planning-project-table-wrapper'>
      <Table
        scroll={{ x: '992px' }}
        columns={renderPlanningProjectTableColumns(t)}
        dataSource={orders || []}
        className='planning-tables'
        loading={isOrdersLoading}
        rowKey={(record) => {
          return record?.id
        }}
        pagination={{
          total: paginationInfo.total,
          current: paginationInfo.page,
          onChange: (page, size) => {
            handlePageChange(page, size)
          },
        }}
        onChange={() => {}}
        onRow={(record) => ({
          onClick: () => projectViewModalOnClick(record),
        })}
        bordered
      />
    </div>
  )
}

export default PlanningProjects
