import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const useListItem = (onSellingPriceChange, product) => {
  const [validationError, setValidationError] = useState(false)
  const { t } = useTranslation()

  const sellingPriceChangeHandler = (item, value) => {
    if (item?.[product]?.purchase_price >= value || !value) {
      setValidationError(true)
    } else {
      setValidationError(false)
    }

    onSellingPriceChange({
      [`ind_selling_price_${product}_${
        product === 'panel' ? item?.panel?.id : item?.id
      }`]: value,

      [`total_selling_price_${product}_${item?.id}`]: Number(
        (item?.quantity || item?.hours) * value
      ).toFixed(2),
    })
  }

  return { t, sellingPriceChangeHandler, validationError }
}
