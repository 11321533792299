import { getMessaging, isSupported, onMessage } from 'firebase/messaging'
import { useHistory, useLocation } from 'react-router'
import { handleNonValidationError } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { STALE_AND_CACHE } from 'src/common'
import { setRemoveUser } from 'src/actions'
import { useDispatch } from 'react-redux'
import { useState, useRef } from 'react'
import {
  allNotificationsCountRequest,
  notificationsCountRequest,
  readNotificationRequest,
  logoutRequest,
} from 'src/services'
import {
  useEnabledFeatures,
  useOnClickOutside,
  useGetUserInfo,
  useInnerHeight,
} from 'src/hooks'
import {
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  useQuery,
} from 'react-query'

export const useSideNavigation = () => {
  const [isSideNavigationOpen, setSideNavigationOpen] = useState(false)
  const [isNotificationOpen, setNotificationOpen] = useState(false)
  const notificationParentRef = useRef(null)
  const sideBarRef = useRef(null)
  const history = useHistory()

  const { isReportingEnabled, isSolarPlanningEnabled } = useEnabledFeatures()
  const queryClient = useQueryClient()
  const { pathname } = useLocation()
  const { user } = useGetUserInfo()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { windowHeight } = useInnerHeight()

  ;(async function () {
    if (!(await isSupported())) return
    onMessage(getMessaging(), () => {
      invalidateNotifications()
    })
  })()

  const {
    data: notificationsData,
    isLoading: isNotificationsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['notifications', user?.company?.id],
    ({ pageParam = 1 }) => allNotificationsCountRequest(pageParam),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      ...STALE_AND_CACHE,
    }
  )
  const { data: notificationsCountData } = useQuery(
    ['notifications_count', user?.company?.id],
    notificationsCountRequest,
    { ...STALE_AND_CACHE }
  )

  const { mutate: logoutMutation, isLoading } = useMutation(logoutRequest, {
    onSuccess: () => {
      dispatch(setRemoveUser())
    },

    onError: (error) => {
      handleNonValidationError(error)
    },
  })

  const { mutate: readNotificationsMutations } = useMutation(
    readNotificationRequest,
    {
      onSuccess: () => {
        invalidateNotifications()
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useOnClickOutside(notificationParentRef, () => setNotificationOpen(false))
  useOnClickOutside(sideBarRef, closeSideNavigation)

  function logoutHandler() {
    if (isLoading) return
    logoutMutation()
  }

  function toggleNotification() {
    setNotificationOpen((prev) => !prev)
  }

  function fetchNotificationsOnScroll(e) {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1

    if (!bottom || !hasNextPage) return

    fetchNextPage()
  }

  function invalidateNotifications() {
    queryClient.invalidateQueries('notifications')
    queryClient.invalidateQueries('notifications_count')
  }

  function navigateAfterNotificationClick(projectId) {
    history.push(`/project/${projectId}/offer`)
    toggleNotification()
  }

  function handleNotificationClick({ notificationsId, projectId }) {
    navigateAfterNotificationClick(projectId)
    const data = {
      ids: [notificationsId],
    }
    readNotificationsMutations(data)
  }

  function toggleSideNavigationState() {
    setSideNavigationOpen((prev) => !prev)
  }

  function closeSideNavigation() {
    setSideNavigationOpen(false)
  }

  return {
    unreadNotifications: notificationsCountData?.data?.unread_count,
    notifications: notificationsData?.pages,
    fetchNotificationsOnScroll,
    open: isSideNavigationOpen,
    toggleSideNavigationState,
    handleNotificationClick,
    isSolarPlanningEnabled,
    isNotificationsLoading,
    notificationParentRef,
    currentPath: pathname,
    closeSideNavigation,
    toggleNotification,
    isReportingEnabled,
    isNotificationOpen,
    logoutHandler,
    windowHeight,
    sideBarRef,
    isLoading,
    user,
    t,
  }
}
