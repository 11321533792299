export const InvoiceIcon = () => {
  return (
    <svg width='90' height='92' viewBox='0 0 90 92' fill='none'>
      <path
        d='M77.1556 14.425V78.2832H65.2674V90.1714H23.2748C20.2675 90.1714 17.8867 87.7906 17.8867 84.7833V14.425C17.8867 11.4177 20.2675 9.03693 23.2748 9.03693H71.9241C74.7748 9.03693 77.1556 11.4177 77.1556 14.425Z'
        fill='white'
      />
      <path
        d='M85.0799 12.9999C87.3041 12.9999 89.0427 14.7385 89.0427 16.9626C89.0427 19.1868 87.3041 20.9254 85.0799 20.9254C82.8558 20.9254 81.1172 19.1868 81.1172 16.9626C81.1172 14.7385 82.8558 12.9999 85.0799 12.9999Z'
        fill='#D0EAFE'
      />
      <path
        d='M3.96433 76.3726C5.5463 76.3726 6.97163 77.6413 6.97163 79.3799C6.97163 80.9618 5.70293 82.3872 3.96433 82.3872C2.38237 82.3872 0.957031 81.1185 0.957031 79.3799C0.957031 77.6413 2.22574 76.3726 3.96433 76.3726Z'
        fill='#D0EAFE'
      />
      <path
        d='M61.0055 49.6046C66.7068 49.6046 71.3118 54.1939 71.3118 59.9109C71.3118 65.6279 66.7225 70.2171 61.0055 70.2171C55.2885 70.2171 50.6992 65.6279 50.6992 59.9109C50.6992 54.1939 55.2885 49.6046 61.0055 49.6046Z'
        fill='#D0EAFE'
      />
      <path
        d='M33.9117 13.9562C39.6131 13.9562 44.218 18.5454 44.218 24.2624C44.218 29.9795 39.6287 34.5687 33.9117 34.5687C28.1947 34.5687 23.6055 29.9795 23.6055 24.2624C23.7621 18.5611 28.367 13.9562 33.9117 13.9562Z'
        fill='#D0EAFE'
      />
      <path
        d='M65.2827 6.4999V9.03731H23.2901C20.2828 9.03731 17.9021 11.4181 17.9021 14.4254V80.3354H15.3646C12.3573 80.3354 9.97656 77.9546 9.97656 74.9473V6.4999C9.97656 3.4926 12.3573 1.11182 15.3646 1.11182H59.879C62.8863 1.11182 65.267 3.4926 65.267 6.4999H65.2827Z'
        fill='#D0EAFE'
      />
      <path
        d='M65.2852 78.2842H77.1734C73.5239 81.9337 68.9346 86.5229 65.2852 90.1724V78.2842Z'
        fill='white'
      />
      <path
        d='M60.8503 58.4704V56.7318C60.2238 56.7318 59.8949 57.045 59.8949 57.5306C59.8949 57.8438 60.0515 58.0005 60.2081 58.1571C60.2081 58.1571 60.3647 58.3137 60.8346 58.4704H60.8503ZM61.4768 62.903C62.2756 62.903 62.5889 62.5897 62.5889 62.1042C62.5889 61.7909 62.4323 61.6343 62.2756 61.4777C62.119 61.321 61.8057 61.1644 61.4768 61.1644V62.903ZM61.6335 54.5076C62.26 54.5076 62.9022 54.6642 63.372 54.9775C63.8419 55.1341 64.3275 55.4474 64.7974 55.604L63.6853 57.4992C63.6853 57.4992 63.5287 57.3426 63.372 57.3426C63.2154 57.186 63.0588 57.186 62.9022 57.0294C62.7455 56.8727 62.4323 56.8727 62.1033 56.7161C61.7901 56.5595 61.6335 56.5595 61.3045 56.5595V58.4547L61.7744 58.6113C62.2443 58.768 62.7299 58.9246 63.0431 59.0812C63.513 59.2378 63.8419 59.3945 63.9986 59.7077C64.3118 60.021 64.4685 60.1776 64.6251 60.5065C64.7817 60.8198 64.7817 61.3054 64.7817 61.7753C64.7817 62.2451 64.6251 62.7307 64.4685 63.2006C64.3118 63.5138 63.9986 63.8271 63.6696 64.156C63.3564 64.4693 63.0431 64.6259 62.5576 64.7826C62.0877 64.9392 61.7588 64.9392 61.2889 64.9392V66.6778H60.3334V64.9392C59.7069 64.9392 59.0647 64.7826 58.2816 64.4693C57.6394 64.3127 57.0129 63.9994 56.543 63.6705L57.655 61.603L57.9683 61.9162C58.1249 62.0729 58.2816 62.0728 58.6105 62.2295C58.9237 62.3861 59.237 62.5427 59.5659 62.5427C59.8792 62.6994 60.2081 62.6994 60.5214 62.856V60.9608L59.8792 60.8041C59.4093 60.6475 58.9237 60.4909 58.6105 60.3343C58.2972 60.1776 57.9683 60.021 57.8117 59.6921C57.655 59.5354 57.3418 59.2222 57.3418 58.8933C57.1852 58.58 57.1852 58.2667 57.1852 57.9378C57.1852 57.4679 57.3418 56.9824 57.4984 56.6691C57.655 56.3558 57.9683 55.8703 58.1249 55.7137C58.4382 55.4004 58.7515 55.2438 59.237 55.0715C59.7069 54.9148 60.0358 54.7582 60.5057 54.7582V53.3329H61.4612L61.6178 54.445L61.6335 54.5076Z'
        fill='#63AFF4'
      />
      <path
        d='M33.7518 22.8208V21.0823C33.1097 21.0823 32.7964 21.3955 32.7964 21.8811C32.7964 22.1943 32.953 22.351 33.1097 22.5076C33.1097 22.5076 33.4229 22.6642 33.7518 22.8208ZM34.3784 27.2535C35.1772 27.2535 35.4904 26.9402 35.4904 26.4547C35.4904 26.1414 35.3338 25.9848 35.1772 25.8281C35.0206 25.6715 34.7073 25.5149 34.3784 25.5149V27.2535ZM33.5796 29.3053C32.953 29.3053 32.3108 29.1487 31.512 28.8354C30.8855 28.6788 30.2433 28.3656 29.7734 28.0366L30.8855 25.9691C30.8855 25.9691 31.0421 26.1257 31.1988 26.2824C31.3554 26.439 31.512 26.439 31.841 26.5956C32.1542 26.7523 32.4675 26.9089 32.7964 26.9089C33.1097 27.0655 33.4386 27.0655 33.7518 27.2222V25.3269L33.1097 25.0137C32.6398 24.857 32.1542 24.7004 31.841 24.5438C31.5277 24.3871 31.1988 24.2305 31.0421 23.9173C30.8855 23.7606 30.5722 23.4474 30.5722 23.1184C30.4156 22.8052 30.4156 22.4763 30.4156 22.163C30.4156 21.6931 30.5723 21.2076 30.7289 20.8943C30.8855 20.581 31.1988 20.0955 31.3554 19.9388C31.6687 19.6256 31.9819 19.469 32.4675 19.3123C32.9374 19.1557 33.2663 18.9991 33.7362 18.9991V17.5737H34.6916V18.9991C35.3338 18.9991 35.9603 19.1557 36.4302 19.469C36.9001 19.6256 37.3857 19.9388 37.8556 20.0955L36.7435 21.8341C36.7435 21.8341 36.5869 21.6774 36.4302 21.6774C36.2736 21.6774 36.117 21.5208 35.9603 21.3642C35.8037 21.2076 35.4904 21.2076 35.1615 21.0509C34.8483 20.8943 34.6916 20.8943 34.3627 20.8943V22.7895L34.8326 22.9461C35.3025 23.1028 35.788 23.2594 36.1013 23.416C36.5712 23.5727 36.9001 23.7293 37.0567 24.0426C37.37 24.3558 37.5266 24.5124 37.6833 24.8414C37.8399 25.1546 37.8399 25.6402 37.8399 26.1101C37.8399 26.58 37.6833 27.0655 37.5266 27.5354C37.37 27.8487 37.0567 28.1619 36.7278 28.4909C36.4146 28.8041 36.0856 28.9607 35.6157 29.1174C35.1459 29.274 34.8169 29.274 34.347 29.274V31.0126H33.3916L33.5482 29.274L33.5796 29.3053Z'
        fill='#63AFF4'
      />
      <path
        d='M65.2857 91.2681H23.2931C19.8003 91.2681 16.793 88.4174 16.793 84.7679V14.4253C16.793 10.9324 19.6436 7.92511 23.2931 7.92511H71.7702C75.263 7.92511 78.2703 10.7758 78.2703 14.4253V78.2834C78.2703 78.5967 78.1137 78.7533 77.9571 78.9099L66.0688 90.7982C65.7556 91.1114 65.5989 91.2681 65.27 91.2681H65.2857ZM23.2931 9.99263C20.7557 9.99263 18.8605 12.0601 18.8605 14.4253V84.7836C18.8605 87.321 20.928 89.2162 23.2931 89.2162H64.9568L76.2028 77.9702V14.4253C76.2028 11.8879 74.1353 9.99263 71.7702 9.99263H23.2931Z'
        fill='#63AFF4'
      />
      <path
        d='M17.7446 81.2911H15.2072C11.7143 81.2911 8.70703 78.4404 8.70703 74.7909V6.50015C8.70703 3.0073 11.5577 0 15.2072 0H59.7372C63.23 0 66.2373 2.85067 66.2373 6.50015V9.03756C66.2373 9.50745 65.7674 9.99301 65.2819 9.99301C64.7963 9.99301 64.3264 9.52312 64.3264 9.03756V6.50015C64.3264 3.96274 62.2589 2.06752 59.8938 2.06752H15.3638C12.8264 2.06752 10.9312 4.11937 10.9312 6.50015V74.7909C10.9312 77.3284 12.9987 79.2236 15.3638 79.2236H17.9012C18.3711 79.2236 18.8567 79.6935 18.8567 80.179C18.7 80.8055 18.3868 81.2911 17.7446 81.2911Z'
        fill='#63AFF4'
      />
      <path
        d='M65.2836 91.2685C64.8137 91.2685 64.3281 90.7986 64.3281 90.3131V78.4249C64.3281 77.955 64.798 77.4694 65.2836 77.4694H77.1718C77.6417 77.4694 78.1273 77.9393 78.1273 78.4249C78.1273 78.9104 77.6574 79.3803 77.1718 79.3803H66.239V90.3131C66.239 90.783 65.9258 91.2685 65.2836 91.2685Z'
        fill='#63AFF4'
      />
      <path
        d='M33.9101 35.492C27.7232 35.492 22.6641 30.4172 22.6641 24.2459C22.6641 18.0747 27.7389 12.9999 33.9101 12.9999C40.0813 12.9999 45.1562 18.0747 45.1562 24.2459C45.1562 30.4172 40.0813 35.492 33.9101 35.492ZM33.9101 14.8951C28.8353 14.8951 24.7159 19.0145 24.7159 24.0893C24.7159 29.1641 28.8353 33.2835 33.9101 33.2835C38.9849 33.2835 43.1043 29.1641 43.1043 24.0893C43.2609 19.0145 39.1416 14.8951 33.9101 14.8951Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1873 16.9639H45.4711C45.0012 16.9639 44.5156 16.494 44.5156 16.0085C44.5156 15.5229 44.9855 15.053 45.4711 15.053H70.1873C70.6572 15.053 71.1428 15.5229 71.1428 16.0085C71.1428 16.494 70.8295 16.9639 70.1873 16.9639Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1862 21.8805H47.5218C47.052 21.8805 46.5664 21.4106 46.5664 20.9251C46.5664 20.4395 47.0363 19.9696 47.5218 19.9696H70.3429C70.8128 19.9696 71.2983 20.4395 71.2983 20.9251C71.2983 21.4106 70.8284 21.8805 70.1862 21.8805Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1839 26.7839H48.475C48.0051 26.7839 47.5195 26.314 47.5195 25.8285C47.5195 25.3429 47.9894 24.873 48.475 24.873H70.3406C70.8105 24.873 71.296 25.3429 71.296 25.8285C71.296 26.314 70.8261 26.7839 70.1839 26.7839Z'
        fill='#63AFF4'
      />
      <path
        d='M70.185 31.8588H46.4242C45.9543 31.8588 45.4688 31.3889 45.4688 30.9033C45.4688 30.4178 45.9386 29.9479 46.4242 29.9479H70.185C70.6549 29.9479 71.1404 30.4178 71.1404 30.9033C71.2971 31.3732 70.8272 31.8588 70.185 31.8588Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1868 36.7613H42.4633C41.9934 36.7613 41.5078 36.2914 41.5078 35.8058C41.5078 35.3203 41.9777 34.8504 42.4633 34.8504H70.1868C70.6567 34.8504 71.1423 35.3203 71.1423 35.8058C71.2989 36.2757 70.829 36.7613 70.1868 36.7613Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1869 41.6788H24.7172C24.2473 41.6788 23.7617 41.2089 23.7617 40.7233C23.7617 40.2378 24.2316 39.7679 24.7172 39.7679H70.3436C70.8134 39.7679 71.299 40.2378 71.299 40.7233C71.299 41.2089 70.8291 41.6788 70.1869 41.6788Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1869 46.598H24.7172C24.2473 46.598 23.7617 46.1281 23.7617 45.6426C23.7617 45.157 24.2316 44.6871 24.7172 44.6871H70.3436C70.8134 44.6871 71.299 45.157 71.299 45.6426C71.299 46.1281 70.8291 46.598 70.1869 46.598Z'
        fill='#63AFF4'
      />
      <path
        d='M61.0078 71.158C54.8209 71.158 49.7617 66.0832 49.7617 59.9119C49.7617 53.7407 54.8365 48.6659 61.0078 48.6659C67.179 48.6659 72.2538 53.7407 72.2538 59.9119C72.2538 66.0832 67.179 71.158 61.0078 71.158ZM61.0078 50.5611C55.933 50.5611 51.8136 54.6805 51.8136 59.7553C51.8136 64.8301 55.933 68.9495 61.0078 68.9495C66.0826 68.9495 70.202 64.8301 70.202 59.7553C70.202 54.6805 66.0826 50.5611 61.0078 50.5611Z'
        fill='#63AFF4'
      />
      <path
        d='M49.4334 52.6124H24.7172C24.2473 52.6124 23.7617 52.1425 23.7617 51.6569C23.7617 51.1714 24.2316 50.7015 24.7172 50.7015H49.4334C49.9033 50.7015 50.3889 51.1714 50.3889 51.6569C50.3889 52.1425 50.0756 52.6124 49.4334 52.6124Z'
        fill='#63AFF4'
      />
      <path
        d='M47.5382 57.5298H24.7172C24.2473 57.5298 23.7617 57.06 23.7617 56.5744C23.7617 56.0888 24.2316 55.619 24.7172 55.619H47.5382C48.0081 55.619 48.4936 56.0888 48.4936 56.5744C48.4936 57.06 48.0237 57.5298 47.5382 57.5298Z'
        fill='#63AFF4'
      />
      <path
        d='M46.4261 62.4324H24.7172C24.2473 62.4324 23.7617 61.9625 23.7617 61.4769C23.7617 60.9914 24.2316 60.5215 24.7172 60.5215H46.5827C47.0526 60.5215 47.5382 60.9914 47.5382 61.4769C47.5382 61.9625 47.0683 62.4324 46.4261 62.4324Z'
        fill='#63AFF4'
      />
      <path
        d='M48.478 67.5072H24.7172C24.2473 67.5072 23.7617 67.0373 23.7617 66.5518C23.7617 66.0662 24.2316 65.5963 24.7172 65.5963H48.478C48.9479 65.5963 49.4334 66.0662 49.4334 66.5518C49.4334 67.0373 48.9635 67.5072 48.478 67.5072Z'
        fill='#63AFF4'
      />
      <path
        d='M52.4407 72.4255H24.7172C24.2473 72.4255 23.7617 71.9556 23.7617 71.4701C23.7617 70.9845 24.2316 70.5146 24.7172 70.5146H52.4407C52.9106 70.5146 53.3962 70.9845 53.3962 71.4701C53.3962 71.9556 52.9263 72.4255 52.4407 72.4255Z'
        fill='#63AFF4'
      />
      <path
        d='M56.4035 77.329H24.7172C24.2473 77.329 23.7617 76.8591 23.7617 76.3735C23.7617 75.888 24.2316 75.4181 24.7172 75.4181H56.4035C56.8733 75.4181 57.3589 75.888 57.3589 76.3735C57.3589 76.8591 56.889 77.329 56.4035 77.329Z'
        fill='#63AFF4'
      />
      <path
        d='M59.4264 82.2465H24.7328C24.2629 82.2465 23.7773 81.7766 23.7773 81.291C23.7773 80.8055 24.2472 80.3356 24.7328 80.3356H59.4264C59.8963 80.3356 60.3818 80.8055 60.3818 81.291C60.3818 81.7766 59.9119 82.2465 59.4264 82.2465Z'
        fill='#63AFF4'
      />
      <path
        d='M3.96274 83.3594C1.73859 83.3594 0 81.6208 0 79.3966C0 77.1725 1.73859 75.4339 3.96274 75.4339C6.18689 75.4339 7.92549 77.1725 7.92549 79.3966C7.92549 81.6208 6.03026 83.3594 3.96274 83.3594ZM3.96274 77.3448C2.85067 77.3448 1.91089 78.3002 1.91089 79.4123C1.91089 80.5244 2.86633 81.4798 3.96274 81.4798C5.05915 81.4798 6.03026 80.5244 6.03026 79.4123C5.87363 78.3002 5.07482 77.3448 3.96274 77.3448Z'
        fill='#63AFF4'
      />
      <path
        d='M85.0823 21.8818C82.3882 21.8818 80.1641 19.6576 80.1641 16.9636C80.1641 14.2696 82.3882 12.0454 85.0823 12.0454C87.7763 12.0454 90.0004 14.2696 90.0004 16.9636C90.0004 19.6576 87.7763 21.8818 85.0823 21.8818ZM85.0823 13.9563C83.5003 13.9563 82.075 15.225 82.075 16.9636C82.075 18.7022 83.3437 19.9709 85.0823 19.9709C86.8208 19.9709 88.0895 18.7022 88.0895 16.9636C88.0895 15.225 86.6642 13.9563 85.0823 13.9563Z'
        fill='#63AFF4'
      />
      <path
        d='M77.1556 14.425V78.2832H65.2674V90.1714H23.2748C20.2675 90.1714 17.8867 87.7906 17.8867 84.7833V14.425C17.8867 11.4177 20.2675 9.03693 23.2748 9.03693H71.9241C74.7748 9.03693 77.1556 11.4177 77.1556 14.425Z'
        fill='white'
      />
      <path
        d='M85.0799 12.9999C87.3041 12.9999 89.0427 14.7385 89.0427 16.9626C89.0427 19.1868 87.3041 20.9254 85.0799 20.9254C82.8558 20.9254 81.1172 19.1868 81.1172 16.9626C81.1172 14.7385 82.8558 12.9999 85.0799 12.9999Z'
        fill='#D0EAFE'
      />
      <path
        d='M3.96433 76.3726C5.5463 76.3726 6.97163 77.6413 6.97163 79.3799C6.97163 80.9618 5.70293 82.3872 3.96433 82.3872C2.38237 82.3872 0.957031 81.1185 0.957031 79.3799C0.957031 77.6413 2.22574 76.3726 3.96433 76.3726Z'
        fill='#D0EAFE'
      />
      <path
        d='M61.0055 49.6046C66.7068 49.6046 71.3118 54.1939 71.3118 59.9109C71.3118 65.6279 66.7225 70.2171 61.0055 70.2171C55.2885 70.2171 50.6992 65.6279 50.6992 59.9109C50.6992 54.1939 55.2885 49.6046 61.0055 49.6046Z'
        fill='#D0EAFE'
      />
      <path
        d='M33.9117 13.9562C39.6131 13.9562 44.218 18.5454 44.218 24.2624C44.218 29.9795 39.6287 34.5687 33.9117 34.5687C28.1947 34.5687 23.6055 29.9795 23.6055 24.2624C23.7621 18.5611 28.367 13.9562 33.9117 13.9562Z'
        fill='#D0EAFE'
      />
      <path
        d='M65.2827 6.4999V9.03731H23.2901C20.2828 9.03731 17.9021 11.4181 17.9021 14.4254V80.3354H15.3646C12.3573 80.3354 9.97656 77.9546 9.97656 74.9473V6.4999C9.97656 3.4926 12.3573 1.11182 15.3646 1.11182H59.879C62.8863 1.11182 65.267 3.4926 65.267 6.4999H65.2827Z'
        fill='#D0EAFE'
      />
      <path
        d='M65.2852 78.2842H77.1734C73.5239 81.9337 68.9346 86.5229 65.2852 90.1724V78.2842Z'
        fill='white'
      />
      <path
        d='M60.8503 58.4704V56.7318C60.2238 56.7318 59.8949 57.045 59.8949 57.5306C59.8949 57.8438 60.0515 58.0005 60.2081 58.1571C60.2081 58.1571 60.3647 58.3137 60.8346 58.4704H60.8503ZM61.4768 62.903C62.2756 62.903 62.5889 62.5897 62.5889 62.1042C62.5889 61.7909 62.4323 61.6343 62.2756 61.4777C62.119 61.321 61.8057 61.1644 61.4768 61.1644V62.903ZM61.6335 54.5076C62.26 54.5076 62.9022 54.6642 63.372 54.9775C63.8419 55.1341 64.3275 55.4474 64.7974 55.604L63.6853 57.4992C63.6853 57.4992 63.5287 57.3426 63.372 57.3426C63.2154 57.186 63.0588 57.186 62.9022 57.0294C62.7455 56.8727 62.4323 56.8727 62.1033 56.7161C61.7901 56.5595 61.6335 56.5595 61.3045 56.5595V58.4547L61.7744 58.6113C62.2443 58.768 62.7299 58.9246 63.0431 59.0812C63.513 59.2378 63.8419 59.3945 63.9986 59.7077C64.3118 60.021 64.4685 60.1776 64.6251 60.5065C64.7817 60.8198 64.7817 61.3054 64.7817 61.7753C64.7817 62.2451 64.6251 62.7307 64.4685 63.2006C64.3118 63.5138 63.9986 63.8271 63.6696 64.156C63.3564 64.4693 63.0431 64.6259 62.5576 64.7826C62.0877 64.9392 61.7588 64.9392 61.2889 64.9392V66.6778H60.3334V64.9392C59.7069 64.9392 59.0647 64.7826 58.2816 64.4693C57.6394 64.3127 57.0129 63.9994 56.543 63.6705L57.655 61.603L57.9683 61.9162C58.1249 62.0729 58.2816 62.0728 58.6105 62.2295C58.9237 62.3861 59.237 62.5427 59.5659 62.5427C59.8792 62.6994 60.2081 62.6994 60.5214 62.856V60.9608L59.8792 60.8041C59.4093 60.6475 58.9237 60.4909 58.6105 60.3343C58.2972 60.1776 57.9683 60.021 57.8117 59.6921C57.655 59.5354 57.3418 59.2222 57.3418 58.8933C57.1852 58.58 57.1852 58.2667 57.1852 57.9378C57.1852 57.4679 57.3418 56.9824 57.4984 56.6691C57.655 56.3558 57.9683 55.8703 58.1249 55.7137C58.4382 55.4004 58.7515 55.2438 59.237 55.0715C59.7069 54.9148 60.0358 54.7582 60.5057 54.7582V53.3329H61.4612L61.6178 54.445L61.6335 54.5076Z'
        fill='#63AFF4'
      />
      <path
        d='M65.2857 91.2681H23.2931C19.8003 91.2681 16.793 88.4174 16.793 84.7679V14.4253C16.793 10.9324 19.6436 7.92511 23.2931 7.92511H71.7702C75.263 7.92511 78.2703 10.7758 78.2703 14.4253V78.2834C78.2703 78.5967 78.1137 78.7533 77.9571 78.9099L66.0688 90.7982C65.7556 91.1114 65.5989 91.2681 65.27 91.2681H65.2857ZM23.2931 9.99263C20.7557 9.99263 18.8605 12.0601 18.8605 14.4253V84.7836C18.8605 87.321 20.928 89.2162 23.2931 89.2162H64.9568L76.2028 77.9702V14.4253C76.2028 11.8879 74.1353 9.99263 71.7702 9.99263H23.2931Z'
        fill='#63AFF4'
      />
      <path
        d='M17.7446 81.2911H15.2072C11.7143 81.2911 8.70703 78.4404 8.70703 74.7909V6.50015C8.70703 3.0073 11.5577 0 15.2072 0H59.7372C63.23 0 66.2373 2.85067 66.2373 6.50015V9.03756C66.2373 9.50745 65.7674 9.99301 65.2819 9.99301C64.7963 9.99301 64.3264 9.52312 64.3264 9.03756V6.50015C64.3264 3.96274 62.2589 2.06752 59.8938 2.06752H15.3638C12.8264 2.06752 10.9312 4.11937 10.9312 6.50015V74.7909C10.9312 77.3284 12.9987 79.2236 15.3638 79.2236H17.9012C18.3711 79.2236 18.8567 79.6935 18.8567 80.179C18.7 80.8055 18.3868 81.2911 17.7446 81.2911Z'
        fill='#63AFF4'
      />
      <path
        d='M65.2836 91.2685C64.8137 91.2685 64.3281 90.7986 64.3281 90.3131V78.4249C64.3281 77.955 64.798 77.4694 65.2836 77.4694H77.1718C77.6417 77.4694 78.1273 77.9393 78.1273 78.4249C78.1273 78.9104 77.6574 79.3803 77.1718 79.3803H66.239V90.3131C66.239 90.783 65.9258 91.2685 65.2836 91.2685Z'
        fill='#63AFF4'
      />
      <path
        d='M33.9101 35.492C27.7232 35.492 22.6641 30.4172 22.6641 24.2459C22.6641 18.0747 27.7389 12.9999 33.9101 12.9999C40.0813 12.9999 45.1562 18.0747 45.1562 24.2459C45.1562 30.4172 40.0813 35.492 33.9101 35.492ZM33.9101 14.8951C28.8353 14.8951 24.7159 19.0145 24.7159 24.0893C24.7159 29.1641 28.8353 33.2835 33.9101 33.2835C38.9849 33.2835 43.1043 29.1641 43.1043 24.0893C43.2609 19.0145 39.1416 14.8951 33.9101 14.8951Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1873 16.9639H45.4711C45.0012 16.9639 44.5156 16.494 44.5156 16.0085C44.5156 15.5229 44.9855 15.053 45.4711 15.053H70.1873C70.6572 15.053 71.1428 15.5229 71.1428 16.0085C71.1428 16.494 70.8295 16.9639 70.1873 16.9639Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1862 21.8805H47.5218C47.052 21.8805 46.5664 21.4106 46.5664 20.9251C46.5664 20.4395 47.0363 19.9696 47.5218 19.9696H70.3429C70.8128 19.9696 71.2983 20.4395 71.2983 20.9251C71.2983 21.4106 70.8284 21.8805 70.1862 21.8805Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1839 26.7839H48.475C48.0051 26.7839 47.5195 26.314 47.5195 25.8285C47.5195 25.3429 47.9894 24.873 48.475 24.873H70.3406C70.8105 24.873 71.296 25.3429 71.296 25.8285C71.296 26.314 70.8261 26.7839 70.1839 26.7839Z'
        fill='#63AFF4'
      />
      <path
        d='M70.185 31.8588H46.4242C45.9543 31.8588 45.4688 31.3889 45.4688 30.9033C45.4688 30.4178 45.9386 29.9479 46.4242 29.9479H70.185C70.6549 29.9479 71.1404 30.4178 71.1404 30.9033C71.2971 31.3732 70.8272 31.8588 70.185 31.8588Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1868 36.7613H42.4633C41.9934 36.7613 41.5078 36.2914 41.5078 35.8058C41.5078 35.3203 41.9777 34.8504 42.4633 34.8504H70.1868C70.6567 34.8504 71.1423 35.3203 71.1423 35.8058C71.2989 36.2757 70.829 36.7613 70.1868 36.7613Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1869 41.6788H24.7172C24.2473 41.6788 23.7617 41.2089 23.7617 40.7233C23.7617 40.2378 24.2316 39.7679 24.7172 39.7679H70.3436C70.8134 39.7679 71.299 40.2378 71.299 40.7233C71.299 41.2089 70.8291 41.6788 70.1869 41.6788Z'
        fill='#63AFF4'
      />
      <path
        d='M70.1869 46.598H24.7172C24.2473 46.598 23.7617 46.1281 23.7617 45.6426C23.7617 45.157 24.2316 44.6871 24.7172 44.6871H70.3436C70.8134 44.6871 71.299 45.157 71.299 45.6426C71.299 46.1281 70.8291 46.598 70.1869 46.598Z'
        fill='#63AFF4'
      />
      <path
        d='M61.0078 71.158C54.8209 71.158 49.7617 66.0832 49.7617 59.9119C49.7617 53.7407 54.8365 48.6659 61.0078 48.6659C67.179 48.6659 72.2538 53.7407 72.2538 59.9119C72.2538 66.0832 67.179 71.158 61.0078 71.158ZM61.0078 50.5611C55.933 50.5611 51.8136 54.6805 51.8136 59.7553C51.8136 64.8301 55.933 68.9495 61.0078 68.9495C66.0826 68.9495 70.202 64.8301 70.202 59.7553C70.202 54.6805 66.0826 50.5611 61.0078 50.5611Z'
        fill='#63AFF4'
      />
      <path
        d='M49.4334 52.6124H24.7172C24.2473 52.6124 23.7617 52.1425 23.7617 51.6569C23.7617 51.1714 24.2316 50.7015 24.7172 50.7015H49.4334C49.9033 50.7015 50.3889 51.1714 50.3889 51.6569C50.3889 52.1425 50.0756 52.6124 49.4334 52.6124Z'
        fill='#63AFF4'
      />
      <path
        d='M47.5382 57.5298H24.7172C24.2473 57.5298 23.7617 57.06 23.7617 56.5744C23.7617 56.0888 24.2316 55.619 24.7172 55.619H47.5382C48.0081 55.619 48.4936 56.0888 48.4936 56.5744C48.4936 57.06 48.0237 57.5298 47.5382 57.5298Z'
        fill='#63AFF4'
      />
      <path
        d='M46.4261 62.4324H24.7172C24.2473 62.4324 23.7617 61.9625 23.7617 61.4769C23.7617 60.9914 24.2316 60.5215 24.7172 60.5215H46.5827C47.0526 60.5215 47.5382 60.9914 47.5382 61.4769C47.5382 61.9625 47.0683 62.4324 46.4261 62.4324Z'
        fill='#63AFF4'
      />
      <path
        d='M48.478 67.5072H24.7172C24.2473 67.5072 23.7617 67.0373 23.7617 66.5518C23.7617 66.0662 24.2316 65.5963 24.7172 65.5963H48.478C48.9479 65.5963 49.4334 66.0662 49.4334 66.5518C49.4334 67.0373 48.9635 67.5072 48.478 67.5072Z'
        fill='#63AFF4'
      />
      <path
        d='M52.4407 72.4255H24.7172C24.2473 72.4255 23.7617 71.9556 23.7617 71.4701C23.7617 70.9845 24.2316 70.5146 24.7172 70.5146H52.4407C52.9106 70.5146 53.3962 70.9845 53.3962 71.4701C53.3962 71.9556 52.9263 72.4255 52.4407 72.4255Z'
        fill='#63AFF4'
      />
      <path
        d='M56.4035 77.329H24.7172C24.2473 77.329 23.7617 76.8591 23.7617 76.3735C23.7617 75.888 24.2316 75.4181 24.7172 75.4181H56.4035C56.8733 75.4181 57.3589 75.888 57.3589 76.3735C57.3589 76.8591 56.889 77.329 56.4035 77.329Z'
        fill='#63AFF4'
      />
      <path
        d='M59.4264 82.2465H24.7328C24.2629 82.2465 23.7773 81.7766 23.7773 81.291C23.7773 80.8055 24.2472 80.3356 24.7328 80.3356H59.4264C59.8963 80.3356 60.3818 80.8055 60.3818 81.291C60.3818 81.7766 59.9119 82.2465 59.4264 82.2465Z'
        fill='#63AFF4'
      />
      <path
        d='M3.96274 83.3594C1.73859 83.3594 0 81.6208 0 79.3966C0 77.1725 1.73859 75.4339 3.96274 75.4339C6.18689 75.4339 7.92549 77.1725 7.92549 79.3966C7.92549 81.6208 6.03026 83.3594 3.96274 83.3594ZM3.96274 77.3448C2.85067 77.3448 1.91089 78.3002 1.91089 79.4123C1.91089 80.5244 2.86633 81.4798 3.96274 81.4798C5.05915 81.4798 6.03026 80.5244 6.03026 79.4123C5.87363 78.3002 5.07482 77.3448 3.96274 77.3448Z'
        fill='#63AFF4'
      />
      <path
        d='M85.0823 21.8818C82.3882 21.8818 80.1641 19.6576 80.1641 16.9636C80.1641 14.2696 82.3882 12.0454 85.0823 12.0454C87.7763 12.0454 90.0004 14.2696 90.0004 16.9636C90.0004 19.6576 87.7763 21.8818 85.0823 21.8818ZM85.0823 13.9563C83.5003 13.9563 82.075 15.225 82.075 16.9636C82.075 18.7022 83.3437 19.9709 85.0823 19.9709C86.8208 19.9709 88.0895 18.7022 88.0895 16.9636C88.0895 15.225 86.6642 13.9563 85.0823 13.9563Z'
        fill='#63AFF4'
      />
    </svg>
  )
}
