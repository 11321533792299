import { InputItem, ShowFieldError } from 'src/common'
import { Form, Checkbox, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const Safety = ({ setFieldsValue = () => {}, error = {} }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('safety')}
      </Typography.Title>

      <InputItem
        placeholder={t('how_roof_reached_safety')}
        label={t('how_roof_reached_safety')}
        error={error?.reach_description}
        name='reach_description'
      />

      <Form.Item
        className={error?.reach_type ? 'group-label-error' : ''}
        label={t('how_to_reach')}
        required
      >
        <Form.Item name='reach_type' noStyle>
          <Checkbox.Group
            onChange={(value) => {
              setFieldsValue({
                reach_type: value,
              })
            }}
          >
            <Checkbox className='wizards_checkbox_space' value={1}>
              {t('aerial_work_platform')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={2}>
              {t('telehandler')}
            </Checkbox>

            <br />
            <Checkbox className='wizards_checkbox_space' value={3}>
              {t('Harness')}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.reach_type} />
      </Form.Item>
    </>
  )
}

export default Safety
