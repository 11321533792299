import { useState } from 'react'
import { Upload } from 'antd'

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const useCustomUpload = () => {
  const [preview, setPreview] = useState(false)
  const [file, setFile] = useState()
  const { Dragger } = Upload

  const imgStyles = {
    maxHeight: '80vh',
    maxWidth: '90vw',
    objectFit: 'contain',
    display: 'block',
    margin: '0 auto',
  }

  const handleImagePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreview(true)
    setFile({
      url: file.url || file.preview,
      name: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  const cancelHandler = () => {
    setPreview(false)
  }

  return {
    handleImagePreview,
    cancelHandler,
    imgStyles,
    Dragger,
    preview,
    file,
  }
}
