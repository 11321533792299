import { CustomUpload, ShowFieldError } from 'src/common'
import { Form, Input, Select, Typography } from 'antd'
import { useComplexityAndComments } from './hooks'
import { formItemErrorClasses } from 'src/utils'

const { Option } = Select

const ComplexityAndComments = ({
  setFieldsValue = () => {},
  error = {},
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
}) => {
  const { layout, t, uploadChangeHandler, fileList } = useComplexityAndComments(
    getStepFiles,
    setFieldsValue
  )

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('complexity_project')}
      </Typography.Title>

      <Form.Item
        className={formItemErrorClasses(error?.complex_value)}
        {...layout}
        required
        label={t('complex_value')}
      >
        <Form.Item name='complex_value' noStyle>
          <Select
            className={error?.complex_value ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
            virtual={false}
          >
            <Option value={1}>{t('simple')}</Option>
            <Option value={2}>{t('normal')}</Option>
            <Option value={3}>{t('complex')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError error={error?.complex_value} />
      </Form.Item>

      <Form.Item {...layout} label={t('comments_complex')}>
        <Form.Item name='comments_complex' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>

      <Form.Item {...layout} label={t('upload_complexity_pictures')}>
        <Form.Item name='complexity_pictures' noStyle>
          <CustomUpload
            beforeUpload={() => false}
            multiple
            listType='picture-card'
            accept='image/*'
            fileList={fileList}
            onChange={uploadChangeHandler}
            onRemove={deleteInspectionImage}
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
        </Form.Item>
      </Form.Item>

      <Typography.Title className='wizards_title' level={5}>
        {t('comments')}
      </Typography.Title>

      <Form.Item {...layout} label={t('comments_after_inspection')}>
        <Form.Item name='comments_after_inspection' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default ComplexityAndComments
