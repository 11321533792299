import { Content, SideNavigation } from 'src/containers'

const MainLayout = () => {
  return (
    <div className='mainLayout'>
      <SideNavigation />

      <div className='mainLayoutContent'>
        <Content />
      </div>
    </div>
  )
}

export default MainLayout
