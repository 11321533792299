import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allMaterialsRequest = async ({
  searchKeyWord = '',
  brandId = '',
  companyId,
  sort = '',
  size = 10,
  page,
}) => {
  return await axios.get(
    `api/materials?page=${page}&per_page=${size}${
      brandId && `&brands[]=${brandId}`
    }${searchKeyWord && `&search=${searchKeyWord}`}${appendCompanyId(
      companyId
    )}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updateMaterialRequest = async (id, data) => {
  return await axios.put(`api/materials/${id}`, data)
}

export const importMaterialsRequest = async (data) => {
  return await axios.post('api/materials/import', data)
}

export const addMaterialRequest = async (data) => {
  return await axios.post('api/materials', data)
}

export const deleteMaterialsRequest = async (id) => {
  return await axios.delete(`api/materials/${id}`)
}
