import { useFuseBoxRadioButton } from './hooks/useFuseBoxRadioButton'
import { ShowFieldError } from 'src/common'
import { Form, Radio } from 'antd'

const FuseBoxRadioButton = (props) => {
  const { t } = useFuseBoxRadioButton()

  return (
    <Form.Item
      label={
        <label className={props?.error ? 'ant-form-item-label-error' : ''}>
          {t(props?.label || props?.name)}
        </label>
      }
      className='fusebox_radio_group'
      required
    >
      <Form.Item className='fusebox_radio_group__item' name={props?.name}>
        <Radio.Group name={props?.name}>
          <Radio value={1}>{props?.yes || t('yes')}</Radio>
          <Radio value={0}>{props?.no || t('no')}</Radio>
        </Radio.Group>
      </Form.Item>
      <ShowFieldError withHeight={true} error={props?.error} />
    </Form.Item>
  )
}

export default FuseBoxRadioButton
