function ClockIcon() {
  return (
    <svg
      width='17'
      height='15'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.84165 0.00232676C4.44831 -0.0910066 1.66831 2.63566 1.66831 6.00233H0.474981C0.174981 6.00233 0.0283146 6.36233 0.241648 6.56899L2.10165 8.43566C2.23498 8.56899 2.44165 8.56899 2.57498 8.43566L4.43498 6.56899C4.48105 6.522 4.51218 6.46241 4.52444 6.39776C4.5367 6.3331 4.52954 6.26626 4.50388 6.20566C4.47821 6.14506 4.43518 6.09341 4.38021 6.05723C4.32524 6.02104 4.26079 6.00194 4.19498 6.00233H3.00165C3.00165 3.40233 5.12165 1.30233 7.73498 1.33566C10.215 1.36899 12.3016 3.45566 12.335 5.93566C12.3683 8.54233 10.2683 10.669 7.66831 10.669C6.59498 10.669 5.60165 10.3023 4.81498 9.68233C4.68729 9.58174 4.52708 9.53159 4.36483 9.54142C4.20257 9.55126 4.04959 9.62039 3.93498 9.73566C3.65498 10.0157 3.67498 10.489 3.98831 10.729C5.03573 11.5573 6.33296 12.0062 7.66831 12.0023C11.035 12.0023 13.7616 9.22233 13.6683 5.82899C13.5816 2.70233 10.9683 0.0889934 7.84165 0.00232676ZM7.50165 3.33566C7.22831 3.33566 7.00165 3.56233 7.00165 3.83566V6.28899C7.00165 6.52233 7.12831 6.74233 7.32831 6.86233L9.40831 8.09566C9.64831 8.23566 9.95498 8.15566 10.095 7.92233C10.235 7.68233 10.155 7.37566 9.92165 7.23566L8.00165 6.09566V3.82899C8.00165 3.56233 7.77498 3.33566 7.50165 3.33566Z'
        fill='#606870'
      />
    </svg>
  )
}

export default ClockIcon
