import { useMutation, useQuery } from 'react-query'
import { statuses, STATUS_IDS } from 'src/common'
import { usePageQueryParams } from 'src/hooks'
import { useTranslation } from 'react-i18next'
import { useState, useMemo } from 'react'
import i18next from 'i18next'
import {
  isSalesPlusIsAdminOrIsSuperUser,
  handleNonValidationError,
  appendValuesToFormData,
  downloadFile,
} from 'src/utils'
import {
  generalReportCountRequest,
  generalReportingRequest,
  getSalesRequest,
  exportRequest,
} from 'src/services'

export function useGeneralReport() {
  const { t } = useTranslation()
  const [sort, setSort] = useState('')
  const [statusIds, setStatusIds] = useState('')
  const [managerIds, setManagerIds] = useState('')
  const [projectDates, setProjectDates] = useState({
    from: '',
    till: '',
  })

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const {
    data: reportData,
    isLoading: isReportDataLoading,
    isPreviousData,
  } = useQuery(
    [
      'general_report',
      page,
      size,
      projectDates.from,
      projectDates.till,
      sort,
      statusIds,
      managerIds,
    ],
    () => {
      const { from, till } = projectDates

      return generalReportingRequest({
        managerIds,
        statusIds,
        page,
        size,
        sort,
        from,
        till,
      })
    },
    {
      keepPreviousData: true,
    }
  )

  const { data: countData, isLoading: isCountDataLoading } = useQuery(
    ['general_report_count', projectDates.from, projectDates.till, managerIds],
    () => {
      const { from, till } = projectDates

      return generalReportCountRequest({ managerIds, from, till })
    }
  )

  const { data: managersData } = useQuery(
    ['users', 'managers'],
    () => getSalesRequest({ trashed: 1 }),
    {
      enabled: isSalesPlusIsAdminOrIsSuperUser(),
    }
  )

  const managersFilter = useMemo(() => {
    return managersData?.data?.data?.map((manager) => ({
      text: manager?.name,
      value: manager?.id,
    }))
  }, [managersData])

  const statusFilter = useMemo(() => {
    return statuses?.map((status) => ({
      text: t(status.name),
      value: status.id,
    }))
  }, [])

  const { mutate: exportMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp?.data)
      },
      onError: (err) => {
        handleNonValidationError(err)
      },
    }
  )

  function getFilters() {
    let data = null
    if (projectDates.from && projectDates.till) {
      data = appendValuesToFormData(projectDates)
    }
    if (managerIds?.length > 0) {
      if (!data) {
        data = new FormData()
      }
      for (let values of managerIds) {
        data.append('manager_ids[]', values)
      }
    }
    return data || new FormData()
  }

  function exportGeneralReporting() {
    const data = getFilters()
    data.append('model', 'general_report')
    exportMutation(data)
  }

  function handleDateChange(value) {
    if (!value) return
    deleteQueryParams('page')
    setProjectDates({
      from: value?.[0],
      till: value?.[1],
    })
  }

  function handleTableChange(_, filters, sorter) {
    const { field, order } = sorter
    const statuses = filters?.['status_id']
    const managers = filters?.['manager_name']
    if (statuses || managers) {
      setStatusIds(statuses)
      setManagerIds(managers)
    }
    if (!statuses) {
      setStatusIds('')
    }

    if (!managers) {
      setManagerIds('')
    }

    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      const fieldName = getSortName(field)
      setSort(`${fieldName},${tempOrd}`)
    }

    if (!order) {
      setSort('')
    }
  }

  function getSortName(field) {
    let temp = ''
    if (field?.includes('manager')) {
      temp = 'manager_name'
    }

    if (field?.includes('reference')) {
      temp = 'reference'
    }
    if (field?.includes('status_id')) {
      temp = 'status'
    }

    return temp
  }

  const projectStatuses = useMemo(() => {
    const arr = []
    countData?.data?.data?.forEach((item) => {
      const status = statuses.find((status) => status?.id === item?.status_id)

      if (item?.total !== 0) {
        if (item?.status_id) {
          arr.push({
            id: status?.id,
            name: i18next.t(status?.name),
            color: status?.color,
            value: item?.total,
          })
        }
      }
    })
    return arr
  }, [countData])

  const successRate = useMemo(() => {
    const approved = countData?.data?.data?.find((item) => {
      return item?.status_id === STATUS_IDS.APPROVED
    })
    return approved?.rate?.toFixed(2)
  }, [countData])

  return {
    projects: reportData?.data?.data,
    paginationInfo: {
      total: reportData?.data?.meta?.total,
      page,
    },
    handleTableChange,
    handlePageChange,
    handleDateChange,
    isLoading: isReportDataLoading || isPreviousData,
    filters: {
      managersFilter,
      statusFilter,
    },
    exportGeneralReporting,
    exporting,
    successRate,
    projectStatuses,
    isCountDataLoading,
    t,
  }
}
