import { userInfoRequest } from 'src/services'
import { useQuery } from 'react-query'

export const useGetUserInfo = (options = {}) => {
  const { data: user, isLoading } = useQuery(
    'user_info',
    userInfoRequest,
    options
  )

  return { user: user?.data, isUserDataLoading: isLoading }
}
