import { useProfileForm } from './useProfileForm'
import { Select, Radio, Form, Button, Image } from 'antd'
import { BiTrash } from 'react-icons/bi'
import {
  SelectLanguageInput,
  PhoneNumberInput,
  ShowFieldError,
  SignatureModal,
  CustomUpload,
  InputItem,
  AntdForm,
} from 'src/common'
import {
  ChangePasswordModal,
  ProfileFormButtons,
  DummyPassword,
} from './components'
import { isWorker, translateSkill } from 'src/utils'

const ProfileForm = () => {
  const {
    setChangePasswordModal,
    signatureDeleteHandler,
    setSignatureImageData,
    setIsSignatureChanged,
    setShowSignatureModal,
    changePasswordModal,
    showSignatureModal,
    signatureImageData,
    draftSignatureSrc,
    formChangeHandler,
    cancelHandler,
    initialValues,
    submitHandler,
    isFormChanged,
    fetchErrors,
    formValues,
    isLoading,
    roleValue,
    user,
    form,
    t,
  } = useProfileForm()

  return (
    <>
      <ChangePasswordModal
        setShowModal={setChangePasswordModal}
        showModal={changePasswordModal}
        userData={user}
      />

      {user && (
        <AntdForm
          initialValues={initialValues}
          className='profile__form'
          onFinish={submitHandler}
          form={form}
        >
          <InputItem
            changeHandler={formChangeHandler}
            error={fetchErrors?.name?.[0]}
            label={t('name')}
            name='name'
          />

          <PhoneNumberInput
            error={fetchErrors?.phone_number?.[0]}
            onChange={(phone) => formChangeHandler(phone, 'phone_number')}
            label={t('phone_number')}
            name='phone_number'
          />

          <InputItem
            placeholder={user?.email}
            label={t('email')}
            required={false}
            disabled={true}
          />

          <InputItem
            placeholder={user?.company?.name}
            label={t('company')}
            required={false}
            disabled={true}
          />

          <InputItem
            placeholder={roleValue}
            required={false}
            label={t('role')}
            disabled={true}
          />

          {isWorker() && (
            <>
              <Form.Item
                className={`custom-antd-select-item`}
                name='skills'
                label={t('skills')}
              >
                <Select
                  mode='multiple'
                  allowClear
                  style={{
                    width: '100%',
                  }}
                  disabled
                  defaultValue={user?.skills?.map((skill) => skill?.id)}
                  options={user?.skills?.map((skill) => {
                    return {
                      label: translateSkill(skill?.name),
                      value: skill?.id,
                    }
                  })}
                />
              </Form.Item>

              <Form.Item
                className='custom-antd-input-item'
                name='is_team_lead'
                label={t('is_team_lead')}
              >
                <Radio.Group
                  disabled
                  style={{ display: 'flex', width: '2rem' }}
                  defaultValue={user?.is_team_lead}
                >
                  <Radio value={1}>{t('yes')}</Radio>
                  <Radio value={0}>{t('no')}</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}

          <DummyPassword setChangePasswordModal={setChangePasswordModal} />

          <Form.Item name='profile_picture' label={t('profile_picture')}>
            <CustomUpload
              multiple={false}
              onChange={(e) => {
                formChangeHandler(e?.fileList, 'profile_picture')
                form.setFieldsValue({ profile_picture: e?.fileList })
              }}
              name='profile_picture'
              maxCount={1}
              beforeUpload={() => false}
              fileList={formValues.profile_picture}
              listType='picture-card'
              accept='image/*'
            >
              <div>
                <div style={{ marginTop: 8, padding: 5 }}>
                  {user?.avatar?.id
                    ? t('change_profile_picture')
                    : t('upload_profile_picture')}
                </div>
              </div>
            </CustomUpload>
            <ShowFieldError
              error={fetchErrors?.profile_picture}
              withHeight={true}
            />
          </Form.Item>

          <div className='signature__view__container'>
            <div className='signature__view__content'>
              <p className='signature__title'>{t('your_signature')}:</p>

              <div className='signatures__box'>
                <div className='signatures__buttonBox'>
                  <div className='signatureImgAndDeleteWrapper'>
                    {draftSignatureSrc || signatureImageData ? (
                      <>
                        <Image.PreviewGroup
                          preview={{
                            className: 'signature__preview__group',
                          }}
                        >
                          <Image
                            src={draftSignatureSrc || signatureImageData}
                            className='signatures__buttonBox__image signature__preview'
                            alt='user signature'
                          />
                        </Image.PreviewGroup>

                        <Button
                          onClick={signatureDeleteHandler}
                          className='deleteSignatureImg'
                          disabled={isLoading}
                          type='danger'
                        >
                          <BiTrash />
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => setShowSignatureModal(true)}
                        disabled={isLoading}
                        type='default'
                      >
                        {t('enter_signature')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='lang__container'>
            <SelectLanguageInput
              changeHandler={formChangeHandler}
              error={fetchErrors?.lang?.[0]}
              label={t('language')}
              form={form}
              name='lang'
            />
          </div>

          <SignatureModal
            saveHandler={() => setIsSignatureChanged(true)}
            close={() => setShowSignatureModal(false)}
            setImageUrl={setSignatureImageData}
            show={showSignatureModal}
          />

          <ProfileFormButtons
            disabled={isFormChanged || isLoading}
            cancelHandler={cancelHandler}
            submitForm={form.submit}
          />
        </AntdForm>
      )}
    </>
  )
}

export default ProfileForm
