import { useCustomMessageModal } from './useCustomMessageModal'
import { Button, Form, Select } from 'antd'
import { isSuperUser } from 'src/utils'
import {
  ShowFieldError,
  CheckboxInput,
  TextAreaInput,
  CustomModal,
  SelectItem,
  Spinner,
} from 'src/common'

const CustomMessageModal = ({
  headquartersSignatureSrc,
  isCustomerSignatureExist,
  customerSignatureSrc,
  companyId,
  offerId,
  close,
  show,
}) => {
  const {
    homeMadeEnergyTemplates,
    sendMailToCustomer,
    isTemplateLoading,
    isHomemadeEnergy,
    isMailSending,
    errors,
    form,
    t,
  } = useCustomMessageModal({
    headquartersSignatureSrc,
    customerSignatureSrc,
    companyId,
    offerId,
    close,
  })

  return (
    <CustomModal
      className='quotation_custom__message__modal'
      close={close}
      show={show}
    >
      <>
        <div className='title'>{t('new_message')}</div>

        {isTemplateLoading ? (
          <Spinner />
        ) : (
          <>
            <Form
              initialValues={{
                template_id: homeMadeEnergyTemplates?.[0]?.id,
                message: '',
              }}
              onFinish={sendMailToCustomer}
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 5 }}
              form={form}
            >
              <TextAreaInput
                className={errors?.message ? 'error-border' : ''}
                name='message'
              />

              <ShowFieldError withHeight={true} error={errors?.message} />

              {isHomemadeEnergy &&
                !isSuperUser() &&
                !isCustomerSignatureExist &&
                homeMadeEnergyTemplates && (
                  <SelectItem
                    defaultValue={homeMadeEnergyTemplates?.[0]?.id}
                    label={t('choose_email_template')}
                    error={errors?.template_id}
                    name='template_id'
                    required={false}
                    form={form}
                  >
                    {homeMadeEnergyTemplates?.map((template) => {
                      return (
                        <Select.Option value={template?.id} key={template?.id}>
                          {template?.name}
                        </Select.Option>
                      )
                    })}
                  </SelectItem>
                )}

              <div className='checkbox__button__container'>
                <CheckboxInput
                  text={t('include_custom_message')}
                  name='includeCustomMessage'
                  form={form}
                />

                <Button
                  disabled={isMailSending}
                  className='send__btn'
                  onClick={form.submit}
                  type='primary'
                >
                  {t('send')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </>
    </CustomModal>
  )
}

export default CustomMessageModal
