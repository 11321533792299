import { SurveyWizard, OptionsWizard } from '../wizards'
import { PageNotFound } from 'src/screens/GeneralPages'
import { Redirect } from 'react-router-dom'
import { useProducts } from './useProducts'
import { TabSwitcher } from './components'
import { Spin } from 'antd'

const Products = () => {
  const { isOfferLoading, product, offer, t, projectId, disableEditing } =
    useProducts()

  if (isOfferLoading) {
    return <Spin style={{ marginTop: '5rem' }} />
  }

  if (!offer) {
    return <PageNotFound text={t('offer_not_found')} />
  }

  if (disableEditing) {
    return <Redirect to={`/project/${projectId}/offer`} />
  }

  return (
    <div className='offers__products__container'>
      <TabSwitcher />

      <div className='page__container'>
        {product === 'survey' && (
          <SurveyWizard
            mountingSystems={offer?.offer_mounting_systems}
            powerOptimizers={offer?.offer_power_optimizers}
            projectCompanyId={offer?.project?.company_id}
            wifiModules={offer?.offer_wifi_modules}
            converters={offer?.offer_converters}
            panels={offer?.offer_panels}
            translate={t}
          />
        )}

        {product === 'options' && (
          <OptionsWizard
            projectCompanyId={offer?.project?.company_id}
            attachedOptions={offer?.offer_options}
          />
        )}
      </div>
    </div>
  )
}

export default Products
