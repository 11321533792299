import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
import { useCustomExpandIcon } from './useCustomExpandIcon'

const CustomExpandIcon = (
  { isActive, onItemClick, record },
  AccordionContent
) => {
  const { clickHandler } = useCustomExpandIcon(record, onItemClick)

  if (isActive) {
    return (
      <a style={{ color: 'black' }} onClick={clickHandler}>
        <div className='wrapperContent'>
          {AccordionContent && <AccordionContent />}
          <AiFillMinusCircle className='ant-collapse-icon' size={32} />
        </div>
      </a>
    )
  }

  return (
    <a style={{ color: 'black' }} onClick={clickHandler}>
      <div className='wrapperContent'>
        {AccordionContent && <AccordionContent />}
        <AiFillPlusCircle className='ant-collapse-icon' size={32} />
      </div>
    </a>
  )
}

export default CustomExpandIcon
