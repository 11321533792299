import { handleNonValidationError, canManageProject } from 'src/utils'
import { useMutation, useQueryClient } from 'react-query'
import { removeOfferProductRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useState } from 'react'

export function usePanelsAccordion() {
  const [showPanelAddingForm, setShowPanelAddingForm] = useState(false)
  const { t } = useTranslation()

  const { offerId } = useParams()
  const queryClient = useQueryClient()

  const { mutate: removePanelMutation, isLoading: removingPanel } = useMutation(
    removeOfferProductRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries(['offer', offerId])
        }
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  function openPanelAddingForm() {
    if (!canManageProject()) return

    setShowPanelAddingForm(true)
  }
  function closePanelAddingForm() {
    setShowPanelAddingForm(false)
  }

  function removePanel(id) {
    removePanelMutation({
      id,
      table: 'offer_panel',
    })
  }

  return {
    removePanel,
    removingPanel,
    showPanelAddingForm,
    openPanelAddingForm,
    closePanelAddingForm,
    translate: t,
  }
}
