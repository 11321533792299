import { configureStore, combineReducers } from '@reduxjs/toolkit'
import globalReducer from '../actions/globalSlice'

const reducer = combineReducers({
  globalSlice: globalReducer,
})

const store = configureStore({
  reducer,
})

export default store
