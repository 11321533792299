import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { renderTaskPlanningTableColumns } from './tableColumns'

function TaskPlanning() {
  const { t } = useTranslation()

  return (
    <div>
      <Table
        scroll={{ x: '992px' }}
        columns={renderTaskPlanningTableColumns(t)}
        dataSource={[]}
        className='planning-tables'
        onRow={() => {}}
        rowKey={(record) => {
          return record?.id
        }}
        onChange={() => {}}
        bordered
      />
    </div>
  )
}

export default TaskPlanning
