import { usePageQueryParams } from 'src/hooks'

export const useClearHomePageQuery = () => {
  const { deleteQueryParams } = usePageQueryParams()

  const clearPageQuery = () => {
    deleteQueryParams('open-page')
    deleteQueryParams('page')
  }

  return { clearPageQuery }
}
