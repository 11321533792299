import { MenuLinkWrapper } from './components'
import { dashboardHero } from 'src/assets'
import useDashboard from './useDashboard'
import { Link } from 'react-router-dom'
import { Header } from 'src/common'
import { Spin } from 'antd'
import {
  joinFirstLetters,
  getRoleNameById,
  showProjects,
  isSuperUser,
  isSalesPlus,
  isAdmin,
} from 'src/utils'

const Dashboard = () => {
  const {
    isSolarPlanningEnabled,
    isReportingEnabled,
    isUserDataLoading,
    userAvatar,
    user,
    t,
  } = useDashboard()

  return (
    <>
      <Header showBackLink={false} />

      <div className='dashboard'>
        {isUserDataLoading ? (
          <Spin />
        ) : (
          <>
            <div className='dashboard__info'>
              <div className='dashboard__info__container'>
                <div className='dashboard__info__user'>
                  {userAvatar ? (
                    <img
                      className='dashboard__info__user__avatar'
                      src={userAvatar}
                      alt={`avatar of ${user?.name}`}
                    />
                  ) : (
                    <div className='dashboard__info__user__initials'>
                      {joinFirstLetters(user?.name)}
                    </div>
                  )}
                  <p className='dashboard__info__user__name'>{user?.name}</p>
                  <p className='dashboard__info__user__company-name'>
                    {user?.company?.name}
                  </p>
                  <div className='dashboard__info__user__role-tag'>
                    {getRoleNameById(user?.roles?.[0]?.id)}
                  </div>
                </div>

                <ul className='dashboard__info__pipelines'>
                  {showProjects() ? (
                    <li>
                      <Link to='/sales-projects'>{t('sales_pipeline')}</Link>
                    </li>
                  ) : null}

                  {isSolarPlanningEnabled ? (
                    <li>
                      <Link to='/planning'>{t('planning_pipeline')}</Link>
                    </li>
                  ) : null}
                </ul>
              </div>

              <img
                className='dashboard__info__hero-img'
                alt='solar panels on buildings'
                src={dashboardHero}
              />
            </div>

            <div
              className='dashboard__menu-links'
              style={{
                justifyContent: isSuperUser() ? 'space-between' : 'flex-start',
              }}
            >
              {isReportingEnabled && (
                <MenuLinkWrapper
                  backgroundColor='#EFF9FF'
                  borderColor='#D0EAFE'
                  title={t('reporting')}
                  path='/reporting'
                />
              )}

              {/* <MenuLinkWrapper
              backgroundColor='#FFFBE6'
              borderColor='#FFF1B8'
              title={t('vacation_request')}
              path='#'
            /> */}

              {(isSalesPlus() || isSuperUser() || isAdmin()) && (
                <MenuLinkWrapper
                  backgroundColor='#FFF4F3'
                  borderColor='#FFCCC7'
                  title={t('my_team')}
                  path='/manage/users'
                />
              )}

              {(isAdmin() || isSuperUser()) && (
                <MenuLinkWrapper
                  backgroundColor='#E6FFFB'
                  borderColor='#B5F5EC'
                  title={t('my_company')}
                  path={`/manage/company?id=${user?.company?.id}&activeTab=setup`}
                />
              )}

              {isSuperUser() && (
                <MenuLinkWrapper
                  backgroundColor='#F0F5FF'
                  borderColor='#D6E4FF'
                  title={t('all_companies')}
                  path='/manage/companies'
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Dashboard
