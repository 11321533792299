import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allMountingSystemsRequest = async ({
  searchKeyWord = '',
  brandId = '',
  sort = '',
  companyId,
  size = 10,
  page,
}) => {
  return await axios.get(
    `api/mounting_systems?page=${page}&per_page=${size}${
      brandId && `&brand_ids[]=${brandId}`
    }${searchKeyWord && `&search=${searchKeyWord}`}${appendCompanyId(
      companyId
    )}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updateMountingSystemRequest = async (id, data) => {
  return await axios.put(`api/mounting_systems/${id}`, data)
}

export const addMountingSystemRequest = async (data) => {
  return await axios.post('api/mounting_systems', data)
}

export const importMountingSystemsRequest = async (data) => {
  return await axios.post('api/mounting_systems/import', data)
}

export const deleteMountingSystemRequest = async (id) => {
  return await axios.delete(`api/mounting_systems/${id}`)
}
