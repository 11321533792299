const TASKS = {
  planned: { value: 'planned', name: 'planned' },
  planned_and_confirmed: {
    value: 'planned_and_confirmed',
    name: 'planned_and_confirmed',
  },
  work_complete: { value: 'work_complete', name: 'work_complete' },
}

const PROJECT_PAID_STATUS = {
  partially_paid: { value: 'partially_paid', name: 'partially_paid' },
  fully_paid: { value: 'fully_paid', name: 'fully_paid' },
  not_paid: { value: 'not_paid', name: 'not_paid' },
}

const PROJECT_STATUS = {
  planning: { value: 'planning', name: 'planning' },
  on_hold: { value: 'on_hold', name: 'on_hold' },
  lost: { value: 'lost', name: 'lost' },
  finished: { value: 'finished', name: 'finished' },
}

const WORKER_ROLES = {
  mechanic: { value: 1, name: 'mechanic' },
  roof_worker: { value: 2, name: 'roof_worker' },
  team_lead: { value: 3, name: 'team_lead' },
}

export { TASKS, PROJECT_PAID_STATUS, PROJECT_STATUS, WORKER_ROLES }
