function Comment({ comment }) {
  return (
    <div className='comment'>
      <div className='comment_user_icon'>
        <img src='https://static.thenounproject.com/png/1743560-200.png' />
      </div>
      <div className='comment_user_content'>
        <div className='comment_user_header'>
          <div className='comment_user_fullname'>Ahmad Fergusson</div>
          <div className='comment_user_date'>30/05/2023 - 04:00 PM</div>
        </div>
        <div className='comment_user_comment'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure iusto
            iusto porro repudiandae sint totam vero!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Comment
