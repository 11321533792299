import { InputItem, AmountInputItem, FormModalWrapper } from 'src/common'
import { useTranslation } from 'react-i18next'

const WifiModuleFormModal = ({
  currentWifiModule = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  form,
  show,
}) => {
  const { t } = useTranslation()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      modalTitle={t('wifi_module')}
      closeHandler={closeHandler}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <AmountInputItem
        defaultValue={currentWifiModule?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={currentWifiModule?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />
    </FormModalWrapper>
  )
}

export default WifiModuleFormModal
