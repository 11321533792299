import { ButtonWithTrash } from 'src/common'
import { storageFileUri } from 'src/utils'

const DocumentLibraryColumns = (t, setDeleteModal) => {
  return [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'title',
    },
    {
      title: t('document'),
      dataIndex: 'name',
      render: (text, record) => {
        return (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={storageFileUri('docs', record?.name)}
          >
            {text}
          </a>
        )
      },
    },
    {
      title: t('delete'),
      dataIndex: 'id',
      render: (_, record) => (
        <ButtonWithTrash
          text={t('delete')}
          onClick={(event) => {
            setDeleteModal({ show: true, data: record })
            event.stopPropagation()
          }}
        />
      ),
    },
  ]
}

export default DocumentLibraryColumns
