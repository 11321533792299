export const ListIcon = ({ isActive }) => {
  const strokeColor = isActive ? '#495057' : '#ADB5BD'

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M8 6H21'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 12H21'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 18H21'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 6H3.01'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 12H3.01'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 18H3.01'
        stroke={strokeColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
