const QuotationInfoBoxes = ({
  firstFieldData,
  secondFieldData,
  thirdFieldData,
  title,
  info,
}) => {
  return (
    <div className='clientaddress'>
      <div className='clientaddress__title'>{title}</div>
      <div className='clientaddress__boxes'>
        <div key={info?.id} className='clientaddress__box'>
          <div className='title'>{firstFieldData?.name}</div>
          <div className='text'>{firstFieldData?.value}</div>

          <div className='title'>{secondFieldData?.name}</div>
          <div className='text'>{secondFieldData?.value}</div>

          <div className='title'>{thirdFieldData?.name}</div>
          <div className='text'>{thirdFieldData?.value}</div>
        </div>
      </div>
    </div>
  )
}

export default QuotationInfoBoxes
