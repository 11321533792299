import { Checkbox, Form } from 'antd'

const CheckboxInput = ({
  defaultChecked = false,
  className = '',
  name,
  text,
  form,
}) => {
  return (
    <Form.Item className={className} name={name}>
      <Checkbox
        defaultChecked={defaultChecked}
        onChange={(e) => {
          form.setFieldsValue({
            [name]: e.target.checked,
          })
        }}
      >
        {text}
      </Checkbox>
    </Form.Item>
  )
}

export default CheckboxInput
