import PanelModalForm from './PanelModalForm'
import { useAddPanelModal } from './hooks'

const AddPanelModal = ({ addPanelModal, setAddPanelModal }) => {
  const { addPanelError, closeModal, isLoading, addPanel, form, t } =
    useAddPanelModal(setAddPanelModal)

  return (
    <PanelModalForm
      actionButtonTitle={t('save')}
      closeHandler={closeModal}
      errors={addPanelError}
      isLoading={isLoading}
      show={addPanelModal}
      onFinish={addPanel}
      form={form}
    />
  )
}

export default AddPanelModal
