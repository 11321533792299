const TotalItemQuotation = ({ text, price, active }) => {
  return (
    <div className={`total__totalItem ${active && 'active'}`}>
      <div>{text && text}:</div>
      <div>€ {price && price}</div>
    </div>
  )
}

export default TotalItemQuotation
