export const TicketViewIcon = ({ isActive }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M3 12L21 12M3 12L3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V12L3 12ZM3 12L3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V12L3 12Z'
        stroke={isActive ? '#495057' : '#ADB5BD'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
