import { AiOutlineDownload } from 'react-icons/ai'
import { Button } from 'antd'

const CsvButton = ({ type = 'primary', text, ...props }) => {
  return (
    <Button
      type={type}
      {...props}
      className='alignButtonIcon contentFooter__child modalContent__button__child'
    >
      <AiOutlineDownload />
      {text}
    </Button>
  )
}

export default CsvButton
