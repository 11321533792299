import React from 'react'
import Opened from 'src/assets/icons/opened.svg'
import Success from 'src/assets/icons/success.svg'
import QuotationDownload from 'src/assets/icons/quotation-download.svg'
import AllInViewDownload from 'src/assets/icons/allinview-download.svg'
import Download from 'src/assets/icons/default-download.svg'

import OffersAndCommentsTooltip from './OffersAndCommentsTooltip'
import { EditIcon, TrashIcon, allInViewImage } from 'src/assets'
import { canManageProject, downloadFile, offerStatuses } from 'src/utils'
import { useOfferHeader } from './hooks'
import OfferDeletionModal from './OfferDeletionModal'

function OfferHeader({
  open,
  onProductOpenClose,
  onProductEdit,
  onAllInViewClick,
  offerSent,
  offerQuotationSent,
  hasPdfOffer,
  allInviewMediaUrl,
  isStepEnabled,
  offerName,
  offerPath,
  offerNumber,
  disableProductEditing,
  requiredProductsMessage,
  isOfferLoading,
  disableAllInView,
  offerStatus,
  offerId,
  canSeeQuotation,
  canSeeDelivery,
  openedOffers,
  offerOpenState,
  quotationIsSigned,
  isOrderCreated,
}) {
  const {
    setShowOfferDeleteModal,
    showOfferDeleteModal,
    detectOfferStatus,
    stepClickHandler,
    isOfferExporting,
    downloadOffer,
    t,
  } = useOfferHeader({ offerStatus, offerSent, offerId, openedOffers })

  return (
    <div className={`offer ${!open && 'not-opened'}`}>
      <OfferDeletionModal
        setShowOfferDeleteModal={setShowOfferDeleteModal}
        showOfferDeleteModal={showOfferDeleteModal}
        offerNumber={offerNumber}
        offerId={offerId}
      />

      <div className={`header-and-project-steps ${open && 'header__opened'}`}>
        <div className='offer-header'>
          <div
            className={`open-close ${!open && 'closed'}`}
            onClick={onProductOpenClose}
          >
            <span>
              {t('offer')} {`(${offerNumber})`}
            </span>
            <img src={Opened} alt='open-close' />
          </div>

          <div
            className={`sending-indicator ${
              (offerStatus !== offerStatuses.offerNotSent || offerSent) &&
              'offer-status-changed'
            } ${open && 'hide'}`}
          >
            <span>{t(detectOfferStatus())}</span>
            {offerSent ? <img src={Success} alt='success' /> : null}
          </div>

          <div className={`dummy__div ${open && 'hide'}`} />

          <div
            className={`allinview__trash__container allinview__btn ${
              open && 'offer__open'
            }`}
          >
            <div
              onClick={() => {
                !disableAllInView && onAllInViewClick()
              }}
              className={`allinview ${
                !allInviewMediaUrl && 'allinview__not__exists'
              } ${disableAllInView && 'disabled__allinview__btn'}`}
            >
              <span className='title'>{t('all_in_view')}</span>
              <img
                className='allinview-logo'
                src={allInViewImage}
                alt='allinview logo'
              />

              <OffersAndCommentsTooltip
                title={t('download')}
                placement='topRight'
              >
                <img
                  onClick={(e) => {
                    e.stopPropagation()
                    if (!allInviewMediaUrl) return
                    const downloadPath = `${process.env.REACT_APP_BACKEND_URI}/${allInviewMediaUrl}`
                    downloadFile(downloadPath)
                  }}
                  src={AllInViewDownload}
                  alt='download all in view files'
                  style={{
                    cursor: allInviewMediaUrl ? 'pointer' : 'not-allowed',
                    opacity: allInviewMediaUrl ? 1 : 0.5,
                  }}
                />
              </OffersAndCommentsTooltip>
            </div>

            {!isOrderCreated && canManageProject() ? (
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!canManageProject()) return
                  setShowOfferDeleteModal(true)
                }}
                className='trash__icon'
              >
                <TrashIcon />
              </div>
            ) : canManageProject() ? (
              <OffersAndCommentsTooltip
                title={t('download_offer')}
                placement='topRight'
              >
                <img
                  onClick={(e) => {
                    e.stopPropagation()
                    downloadOffer()
                  }}
                  src={Download}
                  alt={t('download_offer')}
                  style={{
                    cursor: !isOfferExporting ? 'pointer' : 'not-allowed',
                    opacity: !isOfferExporting ? 1 : 0.5,
                    paddingBottom: '0.3rem',
                  }}
                />
              </OffersAndCommentsTooltip>
            ) : null}
          </div>
        </div>

        {open ? (
          <div className='steps__container'>
            <div id='project-steps' className='project-steps'>
              <div className='steps'>
                <div
                  className={`project-steps__products project-step ${
                    offerOpenState?.step === 'products' && 'active'
                  }`}
                >
                  <p
                    onClick={() => {
                      stepClickHandler('products')
                    }}
                  >
                    <span className='products__step__title'>
                      {t('products')}
                    </span>
                  </p>

                  {canManageProject() ? (
                    <OffersAndCommentsTooltip title={t('open_products_page')}>
                      <div
                        onClick={() => {
                          !disableProductEditing && onProductEdit()
                        }}
                        className='edit__icon'
                        style={{
                          cursor: disableProductEditing
                            ? 'not-allowed'
                            : 'pointer',
                        }}
                      >
                        <EditIcon
                          disabled={disableProductEditing || isOfferLoading}
                        />
                      </div>
                    </OffersAndCommentsTooltip>
                  ) : null}
                </div>

                {canManageProject() ? (
                  <p
                    onClick={() => {
                      if (!isStepEnabled()) return
                      stepClickHandler('check')
                    }}
                    className={`project-step ${
                      offerOpenState?.step === 'check' && 'active'
                    }`}
                    style={{
                      cursor: `${!isStepEnabled() ? 'not-allowed' : 'pointer'}`,
                    }}
                  >
                    <OffersAndCommentsTooltip title={requiredProductsMessage}>
                      {t('check')}
                    </OffersAndCommentsTooltip>
                  </p>
                ) : null}

                {canManageProject() ? (
                  <p
                    onClick={() => {
                      if (!canSeeQuotation) return
                      stepClickHandler('quotation')
                    }}
                    className={`project-step quotation-step ${
                      offerOpenState?.step === 'quotation' && 'active'
                    }`}
                    style={{
                      cursor: `${!canSeeQuotation ? 'not-allowed' : 'pointer'}`,
                    }}
                  >
                    <OffersAndCommentsTooltip
                      title={
                        <>
                          <span>
                            {t(
                              `${
                                offerQuotationSent
                                  ? 'quotation_sent'
                                  : 'quotation_not_sent'
                              }`
                            )}
                          </span>
                          {offerQuotationSent ? (
                            <img
                              style={{
                                marginLeft: '0.8rem',
                                width: '1.6rem',
                                height: '1.1rem',
                              }}
                              src={`${Success}#svgView(preserveAspectRatio(none))`}
                              alt='success'
                            />
                          ) : null}
                        </>
                      }
                    >
                      <div
                        style={{
                          background: `${
                            offerQuotationSent ? '#7CB305' : '#FAAD14'
                          }`,
                        }}
                      />
                    </OffersAndCommentsTooltip>
                    <span>{t('quotation')}</span>

                    {hasPdfOffer ? (
                      <OffersAndCommentsTooltip title={t('download')}>
                        <img
                          onClick={(e) => {
                            const downloadPath = `${process.env.REACT_APP_BACKEND_URI}/storage/${offerPath}/${offerName}`
                            e.stopPropagation()
                            downloadFile(downloadPath, { newTab: true })
                          }}
                          src={QuotationDownload}
                          alt='quotation-download'
                        />
                      </OffersAndCommentsTooltip>
                    ) : null}
                  </p>
                ) : null}

                {canManageProject() ? (
                  <p
                    onClick={() => {
                      if (!isStepEnabled() || !quotationIsSigned) return
                      stepClickHandler('confirmOrder')
                    }}
                    className={`project-step ${
                      offerOpenState?.step === 'confirmOrder' && 'active'
                    }`}
                    style={{
                      cursor: `${
                        !isStepEnabled() || !quotationIsSigned
                          ? 'not-allowed'
                          : 'pointer'
                      }`,
                    }}
                  >
                    {t('confirm_order')}
                  </p>
                ) : null}

                {canManageProject() ? (
                  <p
                    id='delivery-step'
                    onClick={() => {
                      if (!canSeeDelivery) return
                      stepClickHandler('delivery')
                    }}
                    className={`project-step ${
                      offerOpenState?.step === 'delivery' && 'active'
                    }`}
                    style={{
                      cursor: `${!canSeeDelivery ? 'not-allowed' : 'pointer'}`,
                    }}
                  >
                    {t('delivery')}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OfferHeader
