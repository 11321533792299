import { useTranslation } from 'react-i18next'

export const useTotalPrices = (
  subTotalSellingPrice,
  correctionValue,
  discount
) => {
  const { t } = useTranslation()

  const getValidNumberValueOrZero = (value) => {
    return !isNaN(value) ? value : 0
  }

  const subtotalSellingPriceAfterDiscount = (discountValue) => {
    return (subTotalSellingPrice * discountValue) / 100
  }

  const isDiscountChangeAllowed = ({ floatValue }) => {
    const correctionAdditionValue = getValidNumberValueOrZero(correctionValue)
    const newDiscountValue = getValidNumberValueOrZero(floatValue)

    const newTotalSellingPrice =
      subTotalSellingPrice +
      correctionAdditionValue -
      subtotalSellingPriceAfterDiscount(newDiscountValue)

    return (newTotalSellingPrice >= 0 && floatValue <= 100) || !floatValue
  }

  const isCorrectionAdditionChangeAllowed = ({ floatValue }) => {
    const correctionAdditionValue = getValidNumberValueOrZero(floatValue)
    let newTotalSellingPrice = subTotalSellingPrice + correctionAdditionValue

    if (discount) {
      newTotalSellingPrice -= subtotalSellingPriceAfterDiscount(discount)
    }

    return newTotalSellingPrice >= 0 || !floatValue
  }

  return { t, isDiscountChangeAllowed, isCorrectionAdditionChangeAllowed }
}
