import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allPowerOptimizersRequest = async ({
  searchKeyWord = '',
  size = 10,
  companyId,
  sort = '',
  page,
}) => {
  return await axios.get(
    `api/power_optimizers/?page=${page}&per_page=${size}${
      searchKeyWord && `&search=${searchKeyWord}`
    }${appendCompanyId(companyId)}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const updatePowerOptimizerRequest = async (id, data) => {
  return await axios.put(`api/power_optimizers/${id}`, data)
}

export const addPowerOptimizerRequest = async (data) => {
  return await axios.post('api/power_optimizers', data)
}

export const importPowerOptimizersRequest = async (data) => {
  return await axios.post('api/power_optimizers/import', data)
}

export const searchPowerOptimizersRequest = async (data) => {
  return await axios.post('api/power_optimizers/search', data)
}

export const deletePowerOptimizerRequest = async (id) => {
  return await axios.delete(`api/power_optimizers/${id}`)
}
