import i18next from 'i18next'

export const skills = {
  electrician: 1,

  roof_worker: 2,
}

export const translateSkill = (name) => {
  if (!name || typeof name !== 'string') {
    return
  }
  const temp = name.replace(' ', '_').toLowerCase()

  return i18next.t(temp)
}
