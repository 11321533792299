import {
  financialInformationFields,
  emailInformationFields,
  additionalInfoFields,
  basicInfoFields,
  configTabFields,
  setupFields,
} from '../../../constants/companyFormConstant'

const checkValidity = (errorArr, tabFieldsArr) =>
  Object.keys(errorArr).some((item) => tabFieldsArr.includes(item))

export const tabError = (errors) => ({
  basicInfoFields: checkValidity(errors, basicInfoFields),
  additionalInfoFields: checkValidity(errors, additionalInfoFields),
  configTabFields: checkValidity(errors, configTabFields),
})

export const companyTabErrors = (errors) => ({
  setupFields: checkValidity(errors, setupFields),
  financialInformationFields: checkValidity(errors, financialInformationFields),
  emailInformationFields: checkValidity(errors, emailInformationFields),
})

export const CustomSpan = ({ isError, text }) => {
  const style = isError ? { color: '#FF0000' } : {}
  return <span style={style}>{text}</span>
}
