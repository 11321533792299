import { useState } from 'react'

const useSelectLanguageInput = (form, name, onChangeHandler) => {
  const [language, setLanguage] = useState(form.getFieldValue('lang'))

  const changeHandler = (lang) => {
    form.setFieldsValue({ lang })
    setLanguage(lang)
    onChangeHandler && onChangeHandler(lang, name)
  }

  return { changeHandler, value: language }
}

export default useSelectLanguageInput
