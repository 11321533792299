import { message } from 'antd'
import i18next from 'i18next'

export function handleNonValidationError(error) {
  if (
    error?.response?.status === 403 ||
    error?.response?.status === 422 ||
    error?.response?.data?.validation_error
  )
    return

  if (error?.response?.status > 499) {
    message.error(i18next.t('something_went_wrong'))
  } else {
    if (!error?.response?.data?.message) {
      message.error(i18next.t('something_went_wrong'))
    } else {
      message.error(error?.response?.data?.message)
    }
  }
}
