import { ContentState, EditorState, RichUtils, convertFromHTML } from 'draft-js'
import { useState, useEffect, useRef, useMemo } from 'react'

export const useEditor = ({ onValueChange = () => {}, initialState = '' }) => {
  const state = useMemo(() => {
    if (!initialState) return initialState
    const allStyles = `<div>${initialState}</div>`

    const blocksFromHTML = convertFromHTML(allStyles)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return state
  }, [initialState])

  const [editorState, setEditorState] = useState(() =>
    state ? EditorState.createWithContent(state) : EditorState.createEmpty()
  )

  useEffect(() => {
    getEditorValue()
  }, [editorState])

  const editor = useRef(null)

  const focusEditor = () => {
    editor.current.focus()
  }

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e)
    setEditorState(nextState)
  }

  const onBlockClick = (e) => {
    let nextState = RichUtils.toggleBlockType(editorState, e)
    setEditorState(nextState)
  }

  const getEditorValue = () => {
    const value = editorState.getCurrentContent().getPlainText('\u0001')
    onValueChange(value)
  }

  return {
    focusEditor,
    onBlockClick,
    onInlineClick,
    editorState,
    setEditorState,
    editor,
  }
}
