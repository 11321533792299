import { InputGroup, MultiselectField, SelectGroup } from './index'
import { EditOutlined } from '@ant-design/icons'
import {
  PROJECT_STATUS,
  PROJECT_PAID_STATUS,
  PROJECT_STATUS_COLORS,
  PROJECT_PAID_COlORS,
} from '../../data'
import { useTranslation } from 'react-i18next'

function ProjectDetailsContainer() {
  const { t } = useTranslation()
  const plannerOptions = [
    {
      id: 1,
      employee: 'Jhon',
    },
    {
      id: 2,
      employee: 'Alice',
    },
    {
      id: 3,
      employee: 'Bob',
    },
    {
      id: 4,
      employee: 'Emma',
    },
    {
      id: 5,
      employee: 'Michael',
    },
    {
      id: 6,
      employee: 'Sophia',
    },
    {
      id: 7,
      employee: 'Daniel',
    },
    {
      id: 8,
      employee: 'Olivia',
    },
    {
      id: 9,
      employee: 'William',
    },
    {
      id: 10,
      employee: 'Ava',
    },
  ]

  return (
    <div className='project_details_container'>
      <div className='project_details_header'>
        <h4>{t('project_details')}</h4>
        <button disabled={true}>{t('update')}</button>
      </div>
      <div className='project_details_content'>
        <InputGroup
          id='project_name'
          label={t('project_name')}
          placeholder={t('project_name')}
          icon={<EditOutlined className='edit_icon' />}
        />

        <InputGroup
          id='address'
          label={t('address')}
          placeholder={t('address')}
          icon={<EditOutlined className='edit_icon' />}
        />

        <SelectGroup
          id='payments_status'
          label={t('payments_status')}
          options={Object.values(PROJECT_PAID_STATUS)}
          textColor={PROJECT_PAID_COlORS}
          groupClass='project_details_input_group'
        />

        <SelectGroup
          id='project_status'
          label={t('project_status')}
          options={Object.values(PROJECT_STATUS)}
          textColor={PROJECT_STATUS_COLORS}
          groupClass='project_details_input_group'
        />

        <MultiselectField
          label={t('planner')}
          options={plannerOptions}
          searchPlaceholder={t('search_employee')}
        />
      </div>
    </div>
  )
}

export default ProjectDetailsContainer
