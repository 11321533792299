import PaymentConditionsFormModal from './PaymentConditionsFormModal'
import { useAddPaymentConditionModal } from './hooks'

const AddPaymentConditionModal = ({ setAddPaymentConditionModal, show }) => {
  const {
    handleAddPaymentCondition,
    createConditionError,
    closeModal,
    isLoading,
    form,
    t,
  } = useAddPaymentConditionModal(setAddPaymentConditionModal)

  return (
    <PaymentConditionsFormModal
      onFinish={handleAddPaymentCondition}
      actionButtonTitle={t('save')}
      errors={createConditionError}
      closeHandler={closeModal}
      isLoading={isLoading}
      form={form}
      show={show}
    />
  )
}
export default AddPaymentConditionModal
