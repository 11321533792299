import useDocumentLibraryDocsModal from './useDocumentLibraryDocsModal'
import { FormModalWrapper, CheckboxInput } from 'src/common'
import { storageFileUri } from 'src/utils'
import { Spin } from 'antd'

const DocumentLibraryDocsModal = ({
  setShowDocumentLibraryModal,
  showDocumentLibraryModal,
  saveQuotation,
  offer,
}) => {
  const {
    quotationDocumentsFromLibraryIds,
    fetchLibraryDocumentsOnScroll,
    libraryDocuments,
    submitHandler,
    isLoading,
    form,
    t,
  } = useDocumentLibraryDocsModal({
    setShowDocumentLibraryModal,
    saveQuotation,
    offer,
  })

  return (
    <FormModalWrapper
      closeHandler={() => setShowDocumentLibraryModal(false)}
      modalTitle={t('document_library')}
      show={showDocumentLibraryModal}
      actionButtonTitle={t('save')}
      onFinish={submitHandler}
      isLoading={isLoading}
      form={form}
    >
      <div
        onScroll={fetchLibraryDocumentsOnScroll}
        className='document-library-modal'
      >
        {isLoading ? <Spin /> : null}

        {!libraryDocuments?.length && !isLoading ? (
          <p className='document-library-modal__not-found'>
            {t('document_library_is_empty')}!
          </p>
        ) : null}

        {libraryDocuments?.map((el) => {
          return (
            <div key={el?.id}>
              <CheckboxInput
                defaultChecked={
                  !!quotationDocumentsFromLibraryIds?.find(
                    (item) => item?.parent_id === el?.id
                  )
                }
                text={
                  <div>
                    <p>{el?.title}</p>

                    <a
                      href={storageFileUri('docs', el?.name)}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {el?.name}
                    </a>
                  </div>
                }
                name={el?.id}
                form={form}
              />
            </div>
          )
        })}
      </div>
    </FormModalWrapper>
  )
}

export default DocumentLibraryDocsModal
