import { HouseIcon, CompanyIcon } from 'src/assets'
import { Radio } from 'antd'

const TypeRadio = ({ selectedValue, value, title }) => {
  return (
    <Radio value={value}>
      <div className='type__radio__option'>
        <div>{title}</div>

        {value === 1 ? (
          <HouseIcon isActive={selectedValue === 1} />
        ) : (
          <CompanyIcon isActive={selectedValue === 2} />
        )}
      </div>
    </Radio>
  )
}

export default TypeRadio
