import { Tooltip } from 'antd'
import { TASK_COLORS } from '../data'
import { useTranslation } from 'react-i18next'

function EventHoverContent({ event }) {
  const { t } = useTranslation()
  return (
    <div className='event-hover-content'>
      <div>
        <span>{t('project_name')}</span>
        <span>{event?.title}</span>
      </div>
      <div>
        <span>{t('status')}</span>
        <div
          className='event-status'
          style={{
            background: TASK_COLORS[event?.resource.status]?.background,
            color: TASK_COLORS[event?.resource.status]?.text,
          }}
        >
          {t(event?.resource.status)}
        </div>
      </div>
      <div>
        <span>{t('date')}</span>
        <span>{event.start.toLocaleString()}</span>
      </div>
      <div>
        <span>{t('team')}</span>
        <div className='worker-images'>
          <div
            className='team-name'
            style={{
              right: `calc(${
                (event?.resource?.workers?.length + 1) * 0.8
              }rem + 0.4rem)`,
            }}
          >
            Team 1
          </div>
          {event?.resource?.workers?.map((worker, i) => {
            return (
              <Tooltip
                key={i}
                overlayInnerStyle={{
                  padding: 0,
                  minHeight: 0,
                }}
                arrowContent={false}
                title={<div className='worker-name'>{worker.name}</div>}
                placement='bottom'
                overlayClassName='worker-name-tooltip'
              >
                <img
                  style={{ zIndex: i, right: i * 8 }}
                  className='worker-image'
                  src={worker.img_src}
                  alt='worker'
                />
              </Tooltip>
            )
          })}
        </div>
      </div>
      <div>
        <span>{t('planned_work')}</span>
        <span>Planned work</span>
      </div>
    </div>
  )
}

export default EventHoverContent
