const DUTCH_CODE = '+31'
function appendPrefix(tel, prefix = '+') {
  if (typeof tel !== 'string' || tel.length < 1) return tel

  let firstChar = tel[0]

  if (firstChar === prefix) return tel
  return `${prefix}${tel}`
}

function appendDutchCode(tel) {
  if (typeof tel !== 'string' || tel.length < 1) return tel

  let firstChar = tel[0]

  if (firstChar === '+') return tel

  return `${DUTCH_CODE}${tel}`
}

export { appendPrefix, appendDutchCode }
