import { useTagSettings } from './useTagSettings'
import { BsThreeDots } from 'react-icons/bs'
import { Dropdown, Button } from 'antd'

const TagSettings = ({ clearAllHandler, setShowAllTagsModal }) => {
  const { menu, clickHandler } = useTagSettings(
    clearAllHandler,
    setShowAllTagsModal
  )

  return (
    <Dropdown placement='bottomRight' trigger={['click']} overlay={menu}>
      <Button className='homescreen__actions' onClick={clickHandler}>
        <BsThreeDots className='homescreen__actions-icon' />
      </Button>
    </Dropdown>
  )
}

export default TagSettings
