export const wizardsList = (translate) => [
  translate('client'),
  translate('contacts'),
  translate('inspection'),
  translate('survey'),
  translate('options'),
  translate('check'),
  translate('quotation'),
  translate('delivery'),
]
