import { useSignatureModal } from './useSignatureModal'
import CustomModal from '../Modal/CustomModal'
import { Typography, Button } from 'antd'

const SignatureModal = ({
  show = false,
  close = () => {},
  setImageUrl = () => {},
  saveHandler = () => {},
}) => {
  const { clearSignature, saveSignature, canvasRef, t } = useSignatureModal(
    close,
    setImageUrl,
    saveHandler
  )

  return (
    <>
      <CustomModal show={show} close={close}>
        <Typography.Title level={3}>{t('signature')}</Typography.Title>

        <div className='signatureModal_content'>
          <canvas className='signatureModal_content__canvas' ref={canvasRef} />
          <div className='signatureModal_content__clear'>
            <Button onClick={clearSignature} type='danger'>
              {t('clear')}
            </Button>
          </div>
        </div>
        <div className='modalContent__button'>
          <Button
            onClick={close}
            className='modalContent__button__child'
            type='secondary'
          >
            {t('cancel')}
          </Button>
          <Button
            className='modalContent__button__child'
            onClick={saveSignature}
            type='primary'
          >
            {t('save')}
          </Button>
        </div>
      </CustomModal>
    </>
  )
}

export default SignatureModal
