export const MyTeamIcon = () => {
  return (
    <svg width='61' height='60' viewBox='0 0 61 60' fill='none'>
      <g id='Isolation_Mode' clipPath='url(#clip0_6552_16844)'>
        <path
          id='Vector'
          d='M44.41 0C47.95 0 50.78 2.83 50.78 6.37C50.78 9.91 47.95 12.74 44.41 12.74C40.87 12.74 38.04 9.81 38.04 6.37C38.04 2.93 40.87 0 44.41 0Z'
          fill='#B79B78'
        />
        <path
          id='Vector_2'
          d='M30.4501 8.90039C33.9901 8.90039 36.8201 11.7304 36.8201 15.2704C36.8201 18.8104 33.9901 21.6404 30.4501 21.6404C26.9101 21.6404 24.0801 18.8104 24.0801 15.2704C24.0801 11.7304 26.9101 8.90039 30.4501 8.90039Z'
          fill='#B79B78'
        />
        <path
          id='Vector_3'
          d='M16.59 0C20.13 0 22.96 2.83 22.96 6.37C22.96 9.91 20.13 12.74 16.59 12.74C13.05 12.74 10.22 9.91 10.22 6.37C10.22 2.83 13.05 0 16.59 0Z'
          fill='#B79B78'
        />
        <path
          id='Vector_4'
          d='M40.56 42.9891H35.5C34.79 42.9891 34.18 42.3791 34.18 41.6691C34.18 40.9591 34.79 40.3591 35.5 40.3591H39.34V31.4591C39.34 29.1291 38.43 26.9091 36.71 25.1891C34.99 23.4691 32.76 22.5591 30.44 22.5591C25.58 22.5591 21.54 26.5091 21.54 31.4591V40.3591H25.38C26.09 40.3591 26.69 40.9691 26.69 41.6691C26.69 42.3691 26.08 42.9891 25.38 42.9891H20.32C19.61 42.9891 19 42.3791 19 41.6691V31.5491C19 25.2791 24.06 20.1191 30.43 20.1191C33.46 20.1191 36.3 21.3291 38.52 23.4591C40.64 25.5791 41.86 28.5191 41.86 31.5491V41.6691C41.86 42.4791 41.25 42.9891 40.55 42.9891H40.56Z'
          fill='#65B2FE'
        />
        <path
          id='Vector_5'
          d='M25.3901 59.4791C24.6801 59.4791 24.0801 58.8691 24.0801 58.1691V37.9391C24.0801 37.2291 24.6901 36.6191 25.3901 36.6191C26.0901 36.6191 26.7001 37.2291 26.7001 37.9391V58.1691C26.7001 58.8791 26.0901 59.4791 25.3901 59.4791Z'
          fill='#469BE0'
        />
        <path
          id='Vector_6'
          d='M35.5099 59.4791C34.7999 59.4791 34.1899 58.8691 34.1899 58.1691V37.9391C34.1899 37.2291 34.7999 36.6191 35.5099 36.6191C36.2199 36.6191 36.8199 37.2291 36.8199 37.9391V58.1691C36.8199 58.8791 36.2099 59.4791 35.5099 59.4791Z'
          fill='#469BE0'
        />
        <path
          id='Vector_7'
          d='M30.4499 59.4802C29.7399 59.4802 29.1399 58.8702 29.1399 58.1702V48.0502C29.1399 47.3402 29.7499 46.7402 30.4499 46.7402C31.1499 46.7402 31.7599 47.3502 31.7599 48.0502V58.1702C31.7599 58.8802 31.1499 59.4802 30.4499 59.4802Z'
          fill='#469BE0'
        />
        <path
          id='Vector_8'
          d='M5.77 45.1191H1.31C0.6 45.1191 0 44.5091 0 43.8091V34.9091C0 29.3491 4.55 24.7891 10.12 24.7891C10.83 24.7891 11.44 25.3991 11.44 26.1091C11.44 26.8191 10.83 27.4291 10.12 27.4291C5.97 27.4291 2.53 30.8691 2.53 35.0191V42.6091H5.67C6.38 42.6091 6.99 43.2191 6.99 43.9191C7.09 44.5291 6.48 45.1291 5.78 45.1291L5.77 45.1191Z'
          fill='#65B2FE'
        />
        <path
          id='Vector_9'
          d='M5.76995 59.4802C5.05995 59.4802 4.44995 58.8702 4.44995 58.1702V40.4702C4.44995 39.7602 5.05995 39.1602 5.76995 39.1602C6.47995 39.1602 7.07995 39.7702 7.07995 40.4702V58.1702C7.07995 58.8802 6.46995 59.4802 5.76995 59.4802Z'
          fill='#469BE0'
        />
        <path
          id='Vector_10'
          d='M14.6701 59.4802C13.9601 59.4802 13.3501 58.8702 13.3501 58.1702V40.4702C13.3501 39.7602 13.9601 39.1602 14.6701 39.1602C15.3801 39.1602 15.9801 39.7702 15.9801 40.4702V58.1702C15.8801 58.8802 15.3701 59.4802 14.6701 59.4802Z'
          fill='#469BE0'
        />
        <path
          id='Vector_11'
          d='M10.2199 59.4809C9.50991 59.4809 8.90991 58.8709 8.90991 58.1709V49.2709C8.90991 48.5609 9.51991 47.9609 10.2199 47.9609C10.9199 47.9609 11.5399 48.5709 11.5399 49.2709V58.1709C11.5399 58.8809 10.9299 59.4809 10.2199 59.4809Z'
          fill='#469BE0'
        />
        <path
          id='Vector_12'
          d='M59.5801 45.1197H55.1301C54.4201 45.1197 53.8201 44.5097 53.8201 43.8097C53.8201 43.1097 54.4301 42.4897 55.1301 42.4897H58.2701V34.8997C58.2701 30.7497 54.8301 27.3097 50.6801 27.3097C49.9701 27.3097 49.3601 26.6997 49.3601 25.9897C49.3601 25.2797 49.9701 24.6797 50.6801 24.6797C56.2401 24.6797 60.8001 29.2297 60.8001 34.7997V43.6997C60.8001 44.5097 60.1901 45.1197 59.5901 45.1197H59.5801Z'
          fill='#65B2FE'
        />
        <path
          id='Vector_13'
          d='M46.2299 59.4802C45.5199 59.4802 44.9199 58.8702 44.9199 58.1702V40.4702C44.9199 39.7602 45.5299 39.1602 46.2299 39.1602C46.9299 39.1602 47.5399 39.7702 47.5399 40.4702V58.1702C47.5399 58.8802 46.9299 59.4802 46.2299 59.4802Z'
          fill='#469BE0'
        />
        <path
          id='Vector_14'
          d='M55.1301 59.4802C54.4201 59.4802 53.8201 58.8702 53.8201 58.1702V40.4702C53.8201 39.7602 54.4301 39.1602 55.1301 39.1602C55.8301 39.1602 56.4401 39.7702 56.4401 40.4702V58.1702C56.3401 58.8802 55.8301 59.4802 55.1301 59.4802Z'
          fill='#469BE0'
        />
        <path
          id='Vector_15'
          d='M50.6801 59.4809C49.9701 59.4809 49.3601 58.8709 49.3601 58.1709V49.2709C49.3601 48.5609 49.9701 47.9609 50.6801 47.9609C51.3901 47.9609 51.9901 48.5709 51.9901 49.2709V58.1709C51.9901 58.8809 51.3801 59.4809 50.6801 59.4809Z'
          fill='#469BE0'
        />
        <path
          id='Vector_16'
          d='M50.6801 16.2891C53.7501 16.2891 56.2401 18.7791 56.2401 21.8491C56.2401 24.9191 53.7501 27.4091 50.6801 27.4091C47.6101 27.4091 45.1201 24.9191 45.1201 21.8491C45.1201 18.7791 47.6101 16.2891 50.6801 16.2891Z'
          fill='#B79B78'
        />
        <path
          id='Vector_17'
          d='M10.2199 16.2891C13.2899 16.2891 15.7799 18.7791 15.7799 21.8491C15.7799 24.9191 13.2899 27.4091 10.2199 27.4091C7.14991 27.4091 4.65991 24.9191 4.65991 21.8491C4.65991 18.7791 7.14991 16.2891 10.2199 16.2891Z'
          fill='#B79B78'
        />
      </g>
      <defs>
        <clipPath id='clip0_6552_16844'>
          <rect width='60.8' height='59.48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
