import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { isBottom } from 'src/utils'
import { useMemo } from 'react'
import {
  getLibraryDocumentsRequest,
  deleteMultipleOfferFiles,
} from 'src/services'

const useDocumentLibraryDocsModal = ({
  setShowDocumentLibraryModal,
  saveQuotation,
  offer,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    'document-library',
    ({ pageParam = 1 }) =>
      getLibraryDocumentsRequest({
        page: pageParam,
      }),
    {
      getNextPageParam: (info) => {
        let { current_page: currentPage, last_page: lastPage } =
          info?.data?.meta

        return currentPage < lastPage ? ++currentPage : undefined
      },
      keepPreviousData: true,
    }
  )

  const quotationDocumentsFromLibraryIds = useMemo(() => {
    const libraryDocuments = offer?.additional_docs?.filter(
      (el) => !!el.parent_id
    )

    libraryDocuments?.forEach((el) => {
      form.setFieldsValue({
        [el.parent_id]: true,
      })
    })

    return libraryDocuments
  }, [form, offer?.additional_docs])

  const { mutate: deleteMultipleDocumentMutation } = useMutation(
    (data) => deleteMultipleOfferFiles(offer?.id, { file_ids: data?.file_ids }),
    {
      onSuccess: () => {
        if (data?.showSuccessMessage) {
          message.success(t('saved_successfully'))
          queryClient.invalidateQueries(['offer', offer?.id])
        }
        setShowDocumentLibraryModal(false)
      },
    }
  )

  const submitHandler = (formValues) => {
    const newLibraryDocumentIds = []
    const deletedLibraryDocumentIds = []

    for (const key in formValues) {
      const isDocumentAttachedToQuotation =
        quotationDocumentsFromLibraryIds?.find((el) => el.parent_id === +key)

      if (formValues[key] && !isDocumentAttachedToQuotation) {
        newLibraryDocumentIds.push(+key)
      }

      if (!formValues[key] && isDocumentAttachedToQuotation) {
        deletedLibraryDocumentIds.push(isDocumentAttachedToQuotation?.id)
      }
    }

    !!newLibraryDocumentIds && saveQuotation(newLibraryDocumentIds)

    !!deletedLibraryDocumentIds?.length &&
      deleteMultipleDocumentMutation({
        file_ids: deletedLibraryDocumentIds,
        showSuccessMessage: !!newLibraryDocumentIds,
      })

    if (!newLibraryDocumentIds && !deletedLibraryDocumentIds) {
      setShowDocumentLibraryModal(false)
    }
  }

  const fetchLibraryDocumentsOnScroll = (e) => {
    if (!hasNextPage) return

    isBottom(e) && fetchNextPage()
  }

  const libraryDocuments = useMemo(() => {
    return data?.pages?.map((el) => el.data.data).flat()
  }, [data?.pages])

  return {
    quotationDocumentsFromLibraryIds,
    fetchLibraryDocumentsOnScroll,
    libraryDocuments,
    fetchNextPage,
    submitHandler,
    hasNextPage,
    isLoading,
    form,
    t,
  }
}

export default useDocumentLibraryDocsModal
