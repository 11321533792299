import { CustomUpload, ShowFieldError, InputItem } from 'src/common'
import { Form, Input, Select, Typography } from 'antd'
import FuseBoxRadioButton from './FuseBoxRadioButton'
import { useFusebox } from './hooks'

const { Option } = Select

const Fusebox = ({
  setFieldsValue = () => {},
  error = {},
  getStepFiles = () => {},
  deleteInspectionImage = () => {},
}) => {
  const { t, fileList, uploadChangeHandler } = useFusebox(
    getStepFiles,
    setFieldsValue
  )

  return (
    <>
      <Typography.Title className='wizards_title' level={5}>
        {t('fusebox')}
      </Typography.Title>

      <Form.Item
        required
        className={'fusebox_input'}
        label={
          <div className={error?.location_fusebox ? 'error-message-color' : ''}>
            {t('location_fusebox')}:
          </div>
        }
      >
        <Form.Item name='location_fusebox' noStyle>
          <Select
            className={error?.location_fusebox ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('location_fusebox_placeholder')}
          >
            <Option value={1}>{t('in_the_hallway_directly')}</Option>
            <Option value={2}>{t('in_the_hallway_three_meter')}</Option>
            <Option value={3}>{t('elsewhere')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.location_fusebox} />
      </Form.Item>

      <Form.Item
        className={'fusebox_input'}
        required
        label={
          <div className={error?.sealing_present ? 'error-message-color' : ''}>
            {t('sealing_present')}:
          </div>
        }
      >
        <Form.Item name='sealing_present' noStyle>
          <Select
            className={error?.sealing_present ? 'antd-select-error ' : ''}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes')}</Option>
            <Option value={0}>{t('no')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.sealing_present} />
      </Form.Item>

      <Form.Item
        className='fusebox_input'
        required
        label={
          <div
            className={`${
              error?.cupboard_suitable_for_system ? 'error-message-color' : ''
            } cupboard_suitable_for_system`}
          >
            {t('cupboard_suitable_for_system')}:
          </div>
        }
      >
        <Form.Item name='cupboard_suitable_for_system' noStyle>
          <Select
            className={
              error?.cupboard_suitable_for_system ? 'antd-select-error ' : ''
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={t('select_value')}
          >
            <Option value={1}>{t('yes_connected_according_to_1010')}</Option>
            <Option value={2}>{t('not_connected_according_to_1010')}</Option>
            <Option value={3}>
              {t('customer_wants_adjustment_of_meter_cupboard')}
            </Option>
            <Option value={4}>{t('Customer wants new meter cupboard')}</Option>
          </Select>
        </Form.Item>
        <ShowFieldError
          withHeight={true}
          error={error?.cupboard_suitable_for_system}
        />
      </Form.Item>

      <FuseBoxRadioButton name='main_switch' error={error?.main_switch} />

      <FuseBoxRadioButton
        name='module_box_required'
        error={error?.module_box_required}
      />

      <FuseBoxRadioButton
        name='meter_box_phase'
        error={error?.meter_box_phase}
        yes={t('meter_box_yes')}
        no={t('meter_box_no')}
        label={t('meter_box_phase')}
      />

      <InputItem
        label={t('how_cupboard_is_secured') + '::'}
        placeholder={t('how_cupboard_is_secured')}
        error={error?.ampere}
        name='ampere'
      />

      <Form.Item
        className='fusebox_input ampere'
        label={`${t('extra_information_meter_cupboard')}::`}
      >
        <Form.Item name='extra_information_meter_cupboard' noStyle>
          <Input.TextArea rows={4} placeholder={t('remarks')} />
        </Form.Item>
      </Form.Item>

      <Form.Item required label={`${t('upload_fusebox_pictures')}:`}>
        <Form.Item name='fusebox_pictures' noStyle>
          <CustomUpload
            beforeUpload={() => false}
            fileList={fileList}
            onChange={uploadChangeHandler}
            multiple
            listType='picture-card'
            accept='image/*'
            onRemove={deleteInspectionImage}
          >
            <div>
              <div style={{ marginTop: 8 }}>{t('upload')}</div>
            </div>
          </CustomUpload>
          {error?.isInspectionFileSizeError ? (
            <p style={{ color: 'red' }}>{t('max_file_size_exceeded')}</p>
          ) : null}
        </Form.Item>
        <ShowFieldError withHeight={true} error={error?.fusebox_pictures} />
        <p className='inspection_wizard_form__instruction'>
          {t('checklist_pictures')}
          <br /> {t('overview_photo_meter_cupboard')} <br />{' '}
          {t('picture_closed_meter_cupboard')} <br />{' '}
          {t('picture_open_meter_cupboard')}
        </p>
      </Form.Item>
    </>
  )
}

export default Fusebox
