import i18next from 'i18next'

const WEEKDAYS = () => [
  { name: i18next.t('monday'), value: 'monday' },
  { name: i18next.t('tuesday'), value: 'tuesday' },
  { name: i18next.t('wednesday'), value: 'wednesday' },
  { name: i18next.t('thursday'), value: 'thursday' },
  { name: i18next.t('friday'), value: 'friday' },
  { name: i18next.t('saturday'), value: 'saturday' },
  { name: i18next.t('sunday'), value: 'sunday' },
]

export { WEEKDAYS }
