import ActivityFormModal from './ActivityFormModal'
import { useAddActivityType } from './hooks'

const AddActivityType = ({ setShowAddActivitiesModal, show }) => {
  const { addActivityError, addActivity, closeModal, isLoading, form, t } =
    useAddActivityType(setShowAddActivitiesModal)

  return (
    <ActivityFormModal
      actionButtonTitle={t('save')}
      errors={addActivityError}
      closeHandler={closeModal}
      onFinish={addActivity}
      isLoading={isLoading}
      show={show}
      form={form}
    />
  )
}

export default AddActivityType
