import { extractDateAndTime } from 'src/utils'
import formatRelative from 'date-fns/formatRelative'
import nl from 'date-fns/locale/nl'
import enGB from 'date-fns/locale/en-GB'

import { useTranslation } from 'react-i18next'

export function useComment(createdAt) {
  const { i18n } = useTranslation()
  const { date, time } = extractDateAndTime(createdAt) || {}

  return {
    daysPassed: formatRelative(new Date(createdAt), new Date(), {
      locale: i18n.language === 'nl' ? nl : enGB,
    }),
    date,
    time,
  }
}
