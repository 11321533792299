import { InputItem, FormModalWrapper } from 'src/common'
import { useTranslation } from 'react-i18next'

const FormActivityType = ({
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  form,
  show,
}) => {
  const { t } = useTranslation()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      closeHandler={closeHandler}
      modalTitle={t('activity')}
      labelCol={{ span: 3 }}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />
    </FormModalWrapper>
  )
}

export default FormActivityType
