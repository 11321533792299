import ProjectTableColumns from 'src/screens/Home/ProjectTableColumns'
import { Table } from 'antd'

const AllProjectsTable = ({
  className = 'desktop__table__visibility',
  handleTableChange,
  handlePageChange,
  isProjectLoading,
  paginationInfo,
  archiveProject,
  deleteProject,
  project,
  onRow,
}) => {
  return (
    <div className={`content ${className}`}>
      <div className='content__table'>
        <Table
          columns={ProjectTableColumns(archiveProject, deleteProject)}
          bordered
          scroll={{ x: '992px' }}
          rowKey={(record) => record.id}
          dataSource={project || []}
          loading={isProjectLoading}
          onChange={handleTableChange}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onRow={onRow}
        />
      </div>
    </div>
  )
}

export default AllProjectsTable
