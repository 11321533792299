import { useEffect, useMemo, useState } from 'react'
import { getLogoUrl } from 'src/utils'
import {
  useFileUploadValidation,
  useFetchCountries,
  useGetUserInfo,
} from 'src/hooks'

const useSetup = ({ form, errors, companyData }) => {
  const { t, uploadHandler } = useFileUploadValidation(form, errors)

  const { countriesData } = useFetchCountries()
  const { user } = useGetUserInfo()

  const [companyLogoFileList, setCompanyLogoFileList] = useState([])

  const companyLogo = useMemo(() => {
    return getLogoUrl(companyData, {
      defaultImage: false,
    })
  }, [companyData])

  useEffect(() => {
    setCompanyLogoFileList(
      companyLogo
        ? [
            {
              uid: companyLogo,
              url: companyLogo,
            },
          ]
        : []
    )
  }, [companyLogo])

  return {
    userLanguage: user?.lang,
    setCompanyLogoFileList,
    companyLogoFileList,
    uploadHandler,
    countriesData,
    companyLogo,
    t,
  }
}

export default useSetup
