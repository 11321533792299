import InstallationWeekQuotation from './components/InstallationWeekQuotation'
import TableQuotation from './components/TableQuotation/TableQuotation'
import TotalQuotation from './components/TotalQuotation/TotalQuotation'
import { useQuotationWizard, useQuotationSignature } from './hooks'
import AgreementQuotation from './components/AgreementQuotation'
import { Button, Typography } from 'antd'
import {
  getCompanyPdfFileUrl,
  isSuperUserOrAdmin,
  isSalesOrSalesPlus,
  canManageProject,
  joinPanels,
} from 'src/utils'
import {
  DocumentLibraryDocsModal,
  UploadAdditionalText,
  CustomMessageModal,
  InfoBoxes,
} from './components'
import {
  UploadDocumentDraggable,
  SignatureDoubleBox,
  Controle,
  SelectLanguage,
} from 'src/common'

const Quotation = ({
  address,
  options = [],
  converters = [],
  panels = [],
  mountingSystems = [],
  wifiModules = [],
  powerOptimizers = [],
  installationWeek,
  subtotal,
  agreement,
  attachInspection,
  reference,
  quotationSignatures = {},
  offerAdditionDocument,
  company = {},
  quotationCloneId = null,
  vatNumber,
  contact = {},
  offerId,
  accountManagerInfo = {},
  disableQuotationEditing = false,
  offer = {},
}) => {
  const {
    headquartersSignature,
    setHeadquartersSignature,
    isHeadquartersSignatureModalOpen,
    toggleHeadquartersSignatureModal,
    customerSignature,
    setCustomerSignature,
    isCustomerSignatureModalOpen,
    toggleCustomerSignatureModal,
  } = useQuotationSignature({
    headquartersSignatureSrc: quotationSignatures?.quotHeadSignature,
    customerSignatureSrc: quotationSignatures?.quotCustomerSignature,
  })

  const {
    activities,
    materials,
    saveQuotation,
    onAgreementChange,
    agreementValue,
    handleAdditionFileDrag,
    handleAdditionFileRemove,
    validationError,
    handleDeleteAdditionalDocument,
    customerSignatureError,
    saveDisabledHelper,
    disableCustomerSignatureDelete,
    saving,
    onVatChange,
    vatValue,
    quotationSaveErrors,
    customerSignatureDate,
    quotationCloneLanguage,
    setIsHeadAutoSigned,
    handleQuotationCloneLanguageChange,
    setShowCustomMessageModal,
    showCustomMessageModal,
    setShowDocumentLibraryModal,
    showDocumentLibraryModal,
    userSignatureData,
    offerFileList,
    t,
  } = useQuotationWizard({
    options,
    agreement,
    headquartersSignature,
    customerSignature,
    headquartersSignatureSrc: quotationSignatures?.quotHeadSignature,
    customerSignatureSrc: quotationSignatures?.quotCustomerSignature,
    offerAdditionDocument,
    quotationCloneId,
    setCustomerSignature,
    vatNumber,
    attachInspection,
    offerId,
  })

  return (
    <div className='quotation-section'>
      {showCustomMessageModal && (
        <CustomMessageModal
          headquartersSignatureSrc={quotationSignatures?.quotHeadSignature}
          customerSignatureSrc={quotationSignatures?.quotCustomerSignature}
          close={() => setShowCustomMessageModal(false)}
          isCustomerSignatureExist={
            !!quotationSignatures?.quotCustomerSignature
          }
          show={showCustomMessageModal}
          companyId={company?.id}
          offerId={offerId}
        />
      )}

      <div className={`quotation`}>
        <Typography.Title level={3} className='quotation-header'>
          {t('quotation')}
        </Typography.Title>
        {quotationCloneId ? (
          <div className='quotationClone-language-switcher'>
            <SelectLanguage
              selected={quotationCloneLanguage}
              onChange={handleQuotationCloneLanguageChange}
            />
          </div>
        ) : null}

        <div className='quotation__subtitle'>{reference}</div>
        <div className='quotation__content'>
          <Controle
            name={contact?.name || ''}
            mail={contact?.email || ''}
            phoneNumber={contact?.phoneNumber || ''}
            title={t('quotation')}
          />

          <InfoBoxes
            accountManagerInfo={accountManagerInfo}
            address={address}
          />

          {panels?.length ? (
            <TableQuotation
              data={joinPanels(panels)}
              title={t('panels')}
              product='panel'
            />
          ) : null}

          {converters?.length ? (
            <TableQuotation
              data={converters}
              title='Converters'
              product='converter'
            />
          ) : null}

          {powerOptimizers?.length ? (
            <TableQuotation
              data={powerOptimizers}
              title={t('power_optimizers')}
              product='power_optimizer'
            />
          ) : null}

          {mountingSystems?.length ? (
            <TableQuotation
              data={mountingSystems}
              title={t('mounting_systems')}
              product='mounting_system'
            />
          ) : null}

          {wifiModules?.length ? (
            <TableQuotation
              data={wifiModules}
              title={t('wifi_modules')}
              product='wifi_module'
            />
          ) : null}

          {materials?.length ? (
            <TableQuotation
              data={materials}
              title={t('materials')}
              product='optionable'
            />
          ) : null}

          {activities?.length ? (
            <TableQuotation
              data={activities}
              title={t('activities')}
              product='optionable'
            />
          ) : null}

          <TotalQuotation
            disabled={quotationCloneId || disableQuotationEditing}
            onVatChange={onVatChange}
            vatValue={vatValue}
            subtotal={subtotal}
          />

          <AgreementQuotation
            terms_of_condition_src={getCompanyPdfFileUrl(
              company,
              'terms_of_conditions'
            )}
            disabled={quotationCloneId || disableQuotationEditing}
            onAgreementChange={onAgreementChange}
            error={validationError?.agreement}
            value={agreementValue}
          />

          {showDocumentLibraryModal && (
            <DocumentLibraryDocsModal
              setShowDocumentLibraryModal={setShowDocumentLibraryModal}
              showDocumentLibraryModal={showDocumentLibraryModal}
              saveQuotation={saveQuotation}
              offer={offer}
            />
          )}

          <UploadDocumentDraggable
            text={t('upload_additional_document', {
              document_format: '(PDF, Image)',
            })}
            additionalText={
              isSuperUserOrAdmin() || isSalesOrSalesPlus() ? (
                <UploadAdditionalText
                  setShowDocumentLibraryModal={setShowDocumentLibraryModal}
                  disabled={quotationCloneId || disableQuotationEditing}
                />
              ) : null
            }
            multiple={true}
            accept={'.pdf, image/*'}
            handleFileDrag={handleAdditionFileDrag}
            offerFileSrc={offerAdditionDocument}
            deleteAdditionalDocument={handleDeleteAdditionalDocument}
            disabled={quotationCloneId || disableQuotationEditing}
            error={validationError?.file || validationError?.additional_doc_ids}
            handleRemove={handleAdditionFileRemove}
            fileList={offerFileList}
          />

          <InstallationWeekQuotation installationWeek={installationWeek} />

          <SignatureDoubleBox
            userSignatureData={userSignatureData}
            quotationSaveErrors={quotationSaveErrors}
            dates={{
              headquarters: quotationSignatures?.quotHeadSignatureDate,
              customer:
                quotationSignatures.quotCustomerSignatureDate ||
                customerSignatureDate,
            }}
            headquartersSignature={headquartersSignature}
            setHeadquartersSignature={setHeadquartersSignature}
            isHeadquartersSignatureModalOpen={isHeadquartersSignatureModalOpen}
            toggleHeadquartersSignatureModal={() => {
              if (!canManageProject() || quotationCloneId) {
                return
              }
              toggleHeadquartersSignatureModal()
            }}
            customerSignature={customerSignature}
            setCustomerSignature={setCustomerSignature}
            isCustomerSignatureModalOpen={isCustomerSignatureModalOpen}
            toggleCustomerSignatureModal={toggleCustomerSignatureModal}
            disableHeadSignatureDelete={
              quotationSignatures?.quotHeadSignature || saving
            }
            disableCustomerSignatureDelete={
              quotationSignatures?.quotCustomerSignature ||
              disableCustomerSignatureDelete
            }
            customerSignatureError={customerSignatureError}
            disableSignatureBoxes={disableQuotationEditing}
            setAutoSigned={setIsHeadAutoSigned}
            title={t('headquarters')}
          />

          <div className='quotation__footer'>
            <Button
              disabled={saveDisabledHelper() || disableQuotationEditing}
              onClick={saveQuotation}
              type='primary'
            >
              {t('save')}
            </Button>
            {!quotationCloneId ? (
              <Button
                onClick={() => setShowCustomMessageModal(true)}
                disabled={!quotationSignatures?.quotHeadSignature}
                type='primary'
              >
                {t('send_to_customer')}
              </Button>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quotation
