export const AllCompaniesIcon = () => {
  return (
    <svg width='59' height='59' viewBox='0 0 59 59' fill='none'>
      <g id='Isolation_Mode' clipPath='url(#clip0_6552_5067)'>
        <g id='1921, Business, Hierarchy, Leadership, Management, Organization'>
          <g id='Group'>
            <path id='Vector' d='M58.17 0H0V30.35H58.17V0Z' fill='#D7DEED' />
            <path
              id='Vector_2'
              d='M29.03 5.06055C31.82 5.06055 34.09 7.32055 34.09 10.1205C34.09 12.9205 31.83 15.1805 29.03 15.1805C26.23 15.1805 23.97 12.9205 23.97 10.1205C23.97 7.32055 26.23 5.06055 29.03 5.06055Z'
              fill='#FDF8F1'
            />
            <path
              id='Vector_3'
              d='M54.32 11.3301H51.79V13.8601H54.32V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_4'
              d='M49.27 11.3301H46.74V13.8601H49.27V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_5'
              d='M44.2099 11.3301H41.6799V13.8601H44.2099V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_6'
              d='M17.6999 11.3301H15.1699V13.8601H17.6999V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_7'
              d='M12.6501 11.3301H10.1201V13.8601H12.6501V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_8'
              d='M7.59006 11.3301H5.06006V13.8601H7.59006V11.3301Z'
              fill='#AFB9D2'
            />
            <path
              id='Vector_9'
              d='M29.03 49.2593C28.32 49.2593 27.72 48.6493 27.72 47.9393V30.3493C27.72 29.6393 28.33 29.0293 29.03 29.0293C29.73 29.0293 30.34 29.6393 30.34 30.3493V48.0493C30.34 48.6593 29.73 49.2593 29.03 49.2593Z'
              fill='#414A56'
            />
            <path
              id='Vector_10'
              d='M53.1099 49.2609C52.3999 49.2609 51.7999 48.6509 51.7999 47.9409V41.6709H6.26988V48.0409C6.26988 48.7509 5.65988 49.3509 4.94988 49.3509C4.23988 49.3509 3.62988 48.7409 3.62988 48.0409V40.4509C3.62988 39.7409 4.23988 39.1309 4.94988 39.1309H53.0099C53.7199 39.1309 54.3199 39.7409 54.3199 40.4509V48.0409C54.3199 48.6509 53.7099 49.2509 53.1099 49.2509V49.2609Z'
              fill='#57606F'
            />
            <path
              id='Vector_11'
              d='M34.0899 30.3499H18.9199V25.2899C18.9199 19.7299 23.4699 15.1699 29.0399 15.1699C31.8699 15.1699 34.3999 16.2799 36.2199 18.0999C38.0399 19.9199 39.1499 22.4499 39.1499 25.2799V30.3399H34.0899V30.3499Z'
              fill='#65B2FE'
            />
            <g id='Group_2'>
              <path
                id='Vector_12'
                d='M23.9699 22.7598C23.2599 22.7598 22.6599 23.3698 22.6599 24.0798V30.4498H25.2899V24.0798C25.2899 23.2698 24.6799 22.7598 23.9799 22.7598H23.9699Z'
                fill='#469BE0'
              />
              <path
                id='Vector_13'
                d='M34.09 22.7598C33.38 22.7598 32.77 23.3698 32.77 24.0798V30.4498H35.4V24.0798C35.4 23.2698 34.79 22.7598 34.09 22.7598Z'
                fill='#469BE0'
              />
            </g>
            <path
              id='Vector_14'
              d='M53.1101 48.0508C55.9001 48.0508 58.17 50.3108 58.17 53.1108C58.17 55.9108 55.9101 58.1708 53.1101 58.1708C50.3101 58.1708 48.05 55.9108 48.05 53.1108C48.05 50.3108 50.3101 48.0508 53.1101 48.0508Z'
              fill='#FFD24D'
            />
            <path
              id='Vector_15'
              d='M29.0771 58.1736C31.8716 58.1463 34.1147 55.8588 34.0874 53.0644C34.0601 50.2699 31.7726 48.0267 28.9782 48.0541C26.1838 48.0814 23.9406 50.3688 23.9679 53.1633C23.9952 55.9577 26.2827 58.2009 29.0771 58.1736Z'
              fill='#5165A2'
            />
            <path
              id='Vector_16'
              d='M5.06 58.1708C7.85456 58.1708 10.12 55.9053 10.12 53.1108C10.12 50.3162 7.85456 48.0508 5.06 48.0508C2.26544 48.0508 0 50.3162 0 53.1108C0 55.9053 2.26544 58.1708 5.06 58.1708Z'
              fill='#E3835A'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_6552_5067'>
          <rect width='58.17' height='58.17' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
