import { handleNonValidationError, downloadFile } from 'src/utils'
import { useQuery, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { usePageQueryParams } from 'src/hooks'
import { useState } from 'react'
import {
  materialReportingInfoRequest,
  generateExportRequest,
} from 'src/services'

export const useMaterialReporting = () => {
  const [materialReportFilters, setMaterialReportFilters] = useState({
    brand: null,
    kind: {
      converter: '',
      panel: '',
      wifi_module: '',
      mounting_system: '',
      material: '',
      power_optimizer: '',
    },
  })

  const [sort, setSort] = useState('')
  const [keyWord, setSearchKeyWord] = useState('')

  const { t } = useTranslation()

  const { page, size, handlePageChange, deleteQueryParams } =
    usePageQueryParams()

  const {
    data: materialReportData,
    isLoading: isMaterialReportingDataLoading,
    isPreviousData,
  } = useQuery(
    [
      'materialReporting',
      page,
      size,
      keyWord,
      sort,
      materialReportFilters.kind.converter,
      materialReportFilters.kind.panel,
      materialReportFilters.kind.wifi_module,
      materialReportFilters.kind.material,
      materialReportFilters.kind.mounting_system,
      materialReportFilters.kind.power_optimizer,
    ],
    () => {
      return materialReportingInfoRequest({
        appendKind,
        keyWord,
        page,
        size,
        sort,
      })
    },
    {
      keepPreviousData: true,
    }
  )

  const onSearchClick = (keyWord) => {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  const handleFilterChange = (_, filters, sorter) => {
    const { kind } = filters || {}
    const { field, order } = sorter
    const tempKind = {}
    if (kind) {
      kind?.forEach((item) => {
        let name = item?.replaceAll(' ', '_')
        tempKind[name] = item
      })
    }
    if (order) {
      let tempOrd = order === 'ascend' ? 'asc' : 'desc'
      setSort(`${field},${tempOrd}`)
    }
    if (!order) {
      setSort('')
    }
    setMaterialReportFilters((prev) => {
      return { ...prev, kind: tempKind }
    })
  }

  function appendKind() {
    const {
      kind: {
        converter,
        panel,
        mounting_system,
        material,
        wifi_module,
        power_optimizer,
      },
    } = materialReportFilters
    return `${material ? `&kind[]=${material}` : ''}${
      converter ? `&kind[]=${converter}` : ''
    }${panel ? `&kind[]=${panel}` : ''} ${
      mounting_system ? `&kind[]=${mounting_system}` : ''
    } ${wifi_module ? `&kind[]=${wifi_module}` : ''}${
      power_optimizer ? `&kind[]=${power_optimizer}` : ''
    }`
  }

  const { mutate: exportMutation, isLoading: exporting } = useMutation(
    (data) => generateExportRequest(keyWord, data),
    {
      onSuccess: ({ data }) => {
        downloadFile(data)
      },

      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  const exportMaterialReporting = () => {
    const data = new FormData()

    data.append('model', 'material_report')
    const { kind } = materialReportFilters

    const kinds = Object.keys(kind)

    if (kinds.length) {
      kinds.forEach((item) => {
        return data.append('kind[]', kind[item])
      })
    }

    exportMutation(data)
  }

  return {
    isMaterialReportingDataLoading:
      isMaterialReportingDataLoading || isPreviousData,
    exportMaterialReporting,
    handleFilterChange,
    handlePageChange,
    materialReport: materialReportData?.data?.data,
    paginationInfo: {
      total: materialReportData?.data?.meta?.total,
      page,
    },
    onSearchClick,
    exporting,
    t,
  }
}
