import { formatCurrency, canManageProject } from 'src/utils'
import NumberFormat from 'react-number-format'
import { ShowFieldError } from 'src/common'
import { useTotalPrices } from './hooks'
import { Form, Input } from 'antd'

const TotalPrices = ({
  subTotalSellingPrice,
  purchasePrice,
  totalSellingPrice,
  margin,
  marginPercent,
  correctionValue,
  onCorrectionChange = () => {},
  error,
  discount,
  onDiscountChange,
  canSeePriceDetails,
  disableEditing,
}) => {
  const { t, isDiscountChangeAllowed, isCorrectionAdditionChangeAllowed } =
    useTotalPrices(subTotalSellingPrice, correctionValue, discount)

  return (
    <div className='total_prices'>
      {canSeePriceDetails ? (
        <div className='ind_total_price'>
          <span className='total_title'>{t('total_purchase_price')}:</span>
          <Form.Item noStyle>
            <Input
              value={`€ ${formatCurrency(Number(purchasePrice))}`}
              className='total_value'
              readOnly
              disabled
            />
          </Form.Item>
        </div>
      ) : null}
      <div className='ind_total_price'>
        <span className='total_title'>{t('subtotal_selling_price')}:</span>
        <Form.Item noStyle>
          <Input
            value={`€ ${formatCurrency(Number(subTotalSellingPrice))}`}
            className='total_value'
            readOnly
            disabled
          />
        </Form.Item>
      </div>

      <div className='ind_total_price'>
        <>
          <span className='total_title'>{t('discount')}:</span>
          <NumberFormat
            defaultValue={discount}
            onValueChange={(e) => {
              onDiscountChange(e.floatValue)
            }}
            disabled={!canManageProject() || disableEditing}
            isAllowed={isDiscountChangeAllowed}
            className='correction_input'
            allowNegative={false}
            decimalSeparator=','
            customInput={Input}
            decimalScale={2}
            prefix='% '
            max={100}
            min={0}
          />
        </>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ShowFieldError error={error?.project_discount} />
      </div>

      <div className='ind_total_price'>
        <>
          <span className='total_title'>{t('correction_addition')}:</span>
          <NumberFormat
            defaultValue={correctionValue}
            onValueChange={(e) => {
              onCorrectionChange(e.floatValue)
            }}
            disabled={!canManageProject() || disableEditing}
            isAllowed={isCorrectionAdditionChangeAllowed}
            className='correction_input'
            thousandSeparator='.'
            allowNegative={true}
            decimalSeparator=','
            customInput={Input}
            decimalScale={2}
            prefix='€ '
          />
        </>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ShowFieldError error={error?.correction} />
      </div>

      <div className='ind_total_price'>
        <span className='total_title'>{t('total_selling_price')}:</span>
        <Form.Item noStyle>
          <Input
            value={`€ ${formatCurrency(Number(totalSellingPrice))}`}
            className='total_value'
            readOnly
            disabled
          />
        </Form.Item>
      </div>

      {canSeePriceDetails ? (
        <>
          <div className='ind_total_price'>
            <span className='total_title'>{t('margin')}:</span>
            <Form.Item noStyle>
              <Input
                value={`€ ${formatCurrency(Number(margin))}`}
                className='total_value'
                readOnly
                disabled
              />
            </Form.Item>
          </div>

          <div className='ind_total_price'>
            <span className='total_title'>{t('margin_in')} %:</span>
            <Form.Item noStyle>
              <Input
                value={`${formatCurrency(Number(marginPercent))} %`}
                className='total_value'
                readOnly
                disabled
              />
            </Form.Item>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default TotalPrices
