import { handleNonValidationError, handleValidationError } from 'src/utils'
import { updatePowerOptimizerRequest } from 'src/services'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useEffect } from 'react'

export const useShowPowerOptimizerModal = (
  setShowPowerOptimizerModal,
  currentPowerOptimizer
) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const {
    isLoading,
    mutate: updatePowerOptimizer,
    error,
  } = useMutation(
    (variables) =>
      !isLoading &&
      updatePowerOptimizerRequest(currentPowerOptimizer.id, variables),
    {
      onSuccess: () => {
        message.success(t('updated_successfully'))
        setShowPowerOptimizerModal({ show: false, data: {} })
        queryClient.refetchQueries('powerOptimizers')
      },
      onError: (error) => {
        handleNonValidationError(error)
      },
    }
  )

  useEffect(() => {
    if (!!currentPowerOptimizer) {
      form.setFieldsValue(currentPowerOptimizer)
    }
  }, [currentPowerOptimizer, form])

  return {
    updatePowerOptimizerError: handleValidationError(error),
    updatePowerOptimizer,
    isLoading,
    form,
    t,
  }
}
