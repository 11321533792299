import { TASK_COLORS } from '../data'

import CalendarEvent from './CalendarEvent'

function CustomEvent({ event, onEditClick }) {
  return (
    <CalendarEvent event={event} onEditClick={onEditClick}>
      <div
        className='custom-event'
        style={{ background: TASK_COLORS[event.resource.status] }}
      >
        <span className='project-name'>{event?.title}</span>
        <span className='event-start'>{event.start.toLocaleString()}</span>
      </div>
    </CalendarEvent>
  )
}

export default CustomEvent
