import { StatusFilters, ManagersFilter, TagsList } from './components'
import { useFilterAndSortSection } from './useFilterAndSortSection'
import { isTechnician } from 'src/utils'
import { Search } from 'src/common'

const FilterAndSortSection = ({
  allProjectsSearchHandler,
  openProjectsSearchHandler,
  statusFiltersForm,
  paginationInfo,
  managersList,
  managerIds,
  statusIds,
}) => {
  const { t, findStatusCount, accountManagersForm } = useFilterAndSortSection()

  return (
    <div className='filters__and__sorts__container'>
      <div className='filters'>
        <Search
          className='search__input__container'
          onSearchClick={(keyWord) => {
            allProjectsSearchHandler(keyWord)
            openProjectsSearchHandler(keyWord)
          }}
          placeholder={t('search_word')}
          stayActive={true}
        />
        <div className='status__managers__group'>
          <StatusFilters
            statusFiltersForm={statusFiltersForm}
            findStatusCount={findStatusCount}
            paginationInfo={paginationInfo}
            statusIds={statusIds}
          />

          {!isTechnician() && (
            <ManagersFilter
              accountManagersForm={accountManagersForm}
              managersList={managersList}
              managerIds={managerIds}
            />
          )}
        </div>
      </div>

      {(statusIds?.length > 0 || managerIds?.length > 0) && (
        <TagsList
          accountManagersForm={accountManagersForm}
          statusFiltersForm={statusFiltersForm}
          findStatusCount={findStatusCount}
          managersList={managersList}
          managerIds={managerIds}
          statusIds={statusIds}
        />
      )}
    </div>
  )
}

export default FilterAndSortSection
