import { useQuotationClone } from './useQuotationClone'
import Quotation from '../Qutation/Quotation'
import { PageNotFound } from 'src/screens'
import { Spinner } from 'src/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLogoUrl } from 'src/utils'

function QuotationClone() {
  const { quotationOffer, isLoading, error } = useQuotationClone()
  const { t } = useTranslation()

  if (error) {
    return <PageNotFound text={t('page_not_found')} />
  }

  return (
    <div className='quotationCloneWrapper'>
      {quotationOffer ? (
        <div className='logo-wrapper'>
          <img
            src={getLogoUrl(quotationOffer?.project?.company)}
            alt='company logo'
          />
        </div>
      ) : null}
      {quotationOffer ? (
        <Quotation
          correction={quotationOffer?.correction}
          panels={quotationOffer?.offer_panels}
          converters={quotationOffer?.offer_converters}
          powerOptimizers={quotationOffer?.offer_power_optimizers}
          wifiModules={quotationOffer?.offer_wifi_modules}
          mountingSystems={quotationOffer?.offer_mounting_systems}
          options={quotationOffer?.offer_options}
          installationWeek={quotationOffer?.installation_week}
          subtotal={quotationOffer?.total_selling_price}
          agreement={quotationOffer?.agreement}
          attachInspection={quotationOffer?.project?.has_inspection_file}
          reference={quotationOffer?.project?.reference}
          contact={{
            name: quotationOffer?.project?.name,
            email: quotationOffer?.project?.email,
            phoneNumber: quotationOffer?.project?.phone_number,
          }}
          address={{
            street: quotationOffer?.project?.street,
            house_number: quotationOffer?.project?.house_number,
            zipcode: quotationOffer?.project?.zipcode,
            location: quotationOffer?.project?.location,
          }}
          accountManagerInfo={{
            name: quotationOffer?.project?.manager?.name,
            email: quotationOffer?.project?.manager?.email,
            phoneNumber: quotationOffer?.project?.manager?.phone_number,
          }}
          quotationSignatures={{
            quotHeadSignatureDate: quotationOffer?.head_signature?.created_at,
            quotCustomerSignatureDate:
              quotationOffer?.customer_signature?.created_at,
            quotHeadSignature: quotationOffer?.head_signature
              ? `${quotationOffer?.head_signature?.path}/${quotationOffer?.head_signature?.name}`
              : '',

            quotCustomerSignature: quotationOffer?.customer_signature
              ? `${quotationOffer?.customer_signature?.path}/${quotationOffer?.customer_signature?.name}`
              : '',
          }}
          offerAdditionDocument={quotationOffer?.additional_docs}
          company={quotationOffer?.project?.company}
          vatNumber={quotationOffer?.vat}
          offerId={quotationOffer?.id}
          quotationCloneId={quotationOffer?.id}
        />
      ) : isLoading ? (
        <div className='loadingWrapper'>
          <Spinner />
        </div>
      ) : null}
    </div>
  )
}

export default QuotationClone
