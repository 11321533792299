import { expandedTableColumns } from './table/Columns'
import { useExpandedTable } from './hooks'
import { Table } from 'antd'

const ExpandedTable = ({ id }) => {
  const { projectsData, isLoading } = useExpandedTable(id)

  return (
    <Table
      columns={expandedTableColumns}
      dataSource={projectsData?.data || []}
      pagination={false}
      loading={isLoading}
      style={{ width: '818px', marginBottom: '20px' }}
      bordered
      components={{
        header: {
          row: () => false,
        },
      }}
    />
  )
}

export default ExpandedTable
