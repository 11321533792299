import { companyFormConstant } from 'src/screens/Manage/screens/Companies/constants/companyFormConstant'
import { InputItem, PhoneNumberInput, SelectItem } from 'src/common'
import { useConfigurationTab } from './hooks'
import { Select } from 'antd'

const ConfigurationTab = ({ form, errors, data }) => {
  const { t, countriesData, lang } = useConfigurationTab()

  return (
    <>
      <InputItem
        error={errors?.reference_prefix}
        label={t('reference_prefix')}
        name='reference_prefix'
      />
      <InputItem error={errors?.email} label={t('email')} name='email' />

      <PhoneNumberInput
        wrapperClassName='custom-antd-input-item'
        error={errors?.phone_number}
        label={t('phone')}
        name='phone_number'
      />

      <InputItem error={errors?.street} label={t('street')} name='street' />
      <InputItem
        error={errors?.house_number}
        label={t('house_number')}
        name='house_number'
      />
      <InputItem
        error={errors?.house_number_suffix}
        label={t('house_number_suffix')}
        name='house_number_suffix'
        required={false}
      />
      <InputItem error={errors?.zipcode} label={t('zipcode')} name='zipcode' />
      <SelectItem
        defaultValue={data?.country_id || companyFormConstant.country_id}
        error={errors?.country_id}
        label={t('country')}
        name='country_id'
        required={false}
        form={form}
      >
        {countriesData?.data?.map((country) => {
          return (
            <Select.Option key={country?.id} value={country?.id}>
              {lang === 'nl' ? country?.name_du : country?.name}
            </Select.Option>
          )
        })}
      </SelectItem>
      <InputItem error={errors?.city} label={t('city')} name='city' />
      <InputItem
        error={errors?.coc_number}
        label={t('coc_number')}
        name='coc_number'
        required={false}
      />
      <InputItem
        error={errors?.vat_number}
        label={t('vat_number')}
        name='vat_number'
        required={false}
      />
      <InputItem
        error={errors?.bic_number}
        label={t('bic_number')}
        name='bic_number'
        required={false}
      />
      <InputItem
        error={errors?.iban}
        label={t('iban')}
        name='iban'
        required={false}
      />
      <InputItem
        error={errors?.website}
        label={t('website')}
        name='website'
        required={false}
      />
    </>
  )
}
export default ConfigurationTab
