import { useTaskHandler } from '../../hooks'
import { Task } from './index'
import { useTranslation } from 'react-i18next'

function TasksContainer() {
  const { t } = useTranslation()
  const { tasks, addNewTask, deleteTask } = useTaskHandler()

  return (
    <div className='tasks_wrap'>
      {tasks?.map((task, index) => {
        return (
          <Task key={index} task={task} index={index} deleteTask={deleteTask} />
        )
      })}
      <div className='tasks_add_new_task'>
        <button
          onClick={() => {
            return addNewTask()
          }}
        >
          + {t('add_new_task')}
        </button>
      </div>
    </div>
  )
}

export default TasksContainer
