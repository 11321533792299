import { useMutation, useQueryClient } from 'react-query'
import { handleNonValidationError } from 'src/utils'
import { confirmOrderRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const useConfirmOrder = (offerData) => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { mutate: confirmOrderMutation, isLoading: isConfirming } = useMutation(
    () => confirmOrderRequest(offerData?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['offer', offerData?.id])
      },
      onError: handleNonValidationError,
    }
  )

  return {
    disabled: !checkboxValue || isConfirming || !!offerData?.order_created_at,
    checkboxValue: !!offerData?.order_created_at ? true : checkboxValue,
    isOrderConfirmed: !!offerData?.order_created_at,
    isConfirming: isConfirming,
    confirmOrderMutation,
    setCheckboxValue,
    t,
  }
}

export default useConfirmOrder
