import { useDeleteMultipleProductItems, usePageQueryParams } from 'src/hooks'
import { rowSelectionActions, downloadFile, canPerform } from 'src/utils'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  importConvertersRequest,
  deleteConverterRequest,
  allConvertersRequest,
  exportRequest,
} from 'src/services'

export function useConverter() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { user } = useSelector((state) => state.globalSlice)

  const [deleteConverters, setDeleteConverters] = useState({
    show: false,
    data: {},
  })

  const [showConverter, setShowConverter] = useState({
    show: false,
    data: {},
  })

  const [showAddConverterModal, setShowAddConverterModal] = useState(false)
  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const [importError, setImportError] = useState()
  const [searchKeyWord, setSearchKeyWord] = useState('')
  const [sort, setSort] = useState('')

  const [selectedConvertersIds, setSelectedConvertersIds] = useState([])
  const rowSelection = rowSelectionActions(setSelectedConvertersIds)

  const [showMultipleDeleteConfirmation, setShowMultipleDeleteConfirmation] =
    useState(false)

  const {
    navigateToPreviousQueryPageParam,
    deleteQueryParams,
    handlePageChange,
    page,
    size,
  } = usePageQueryParams()

  const {
    data: convertersData,
    isLoading: isConvertersDataLoading,
    isPreviousData,
  } = useQuery(
    ['converters', page, size, sort, searchKeyWord, user?.company_id],
    () =>
      allConvertersRequest({
        companyId: user?.company_id,
        searchKeyWord,
        sort,
        page,
        size,
      }),
    {
      keepPreviousData: true,
    }
  )

  const { deleteMultipleItemMutation, deletingMultipleItem } =
    useDeleteMultipleProductItems(
      setShowMultipleDeleteConfirmation,
      setSelectedConvertersIds,
      'converters',
      convertersData?.data?.data?.length
    )

  const { mutate: deleteConverter, isLoading: deleting } = useMutation(
    deleteConverterRequest,
    {
      onSuccess: (response, deletedConverterId) => {
        if (response.data.success) {
          if (selectedConvertersIds.includes(deletedConverterId)) {
            setSelectedConvertersIds((prev) =>
              prev.filter((id) => id !== deletedConverterId)
            )
          }

          if (convertersData?.data?.data?.length === 1 && page > 1) {
            navigateToPreviousQueryPageParam()
          }

          queryClient.refetchQueries('converters')
          setDeleteConverters({ show: false, data: {} })
        }
      },
    }
  )

  const { mutate: importConverters, isLoading: importing } = useMutation(
    importConvertersRequest,
    {
      onSuccess: (resp) => {
        if (resp?.data?.success) {
          queryClient.invalidateQueries('converters')
          setShowImportCsvModal(false)
        }
      },
      onError: (error) => {
        setImportError(error)
      },
    }
  )

  const { mutate: exportConverterMutation, isLoading: exporting } = useMutation(
    exportRequest,
    {
      onSuccess: (resp) => {
        downloadFile(resp.data)
      },
    }
  )

  function onSearchClick(keyWord) {
    if (keyWord) {
      deleteQueryParams('page')
    }
    setSearchKeyWord(keyWord)
  }

  function exportConverters() {
    exportConverterMutation({
      model: 'converters',
    })
  }

  const showMultipleDeleteModalHandler = () => {
    setShowMultipleDeleteConfirmation((prev) => !prev)
  }

  const deleteSelectedItems = () => {
    deleteMultipleItemMutation({
      items: selectedConvertersIds,
      table: 'converters',
    })
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        if (
          canPerform('manage converter') ||
          canPerform('manage own converter')
        ) {
          setShowConverter({ show: true, data: record })
        }
      },
    }
  }

  return {
    isConvertersDataLoading: isConvertersDataLoading || isPreviousData,
    exportDisabled: exporting || !convertersData?.data?.data?.length,
    converters: convertersData?.data?.data,
    showMultipleDeleteConfirmation,
    showMultipleDeleteModalHandler,
    setShowAddConverterModal,
    selectedConvertersIds,
    setShowImportCsvModal,
    showAddConverterModal,
    deletingMultipleItem,
    deleteSelectedItems,
    setDeleteConverters,
    showImportCsvModal,
    importConverters,
    exportConverters,
    deleteConverters,
    setShowConverter,
    deleteConverter,
    setImportError,
    showConverter,
    onSearchClick,
    rowSelection,
    importError,
    paginationInfo: {
      total: convertersData?.data?.meta?.total,
      page,
    },
    handlePageChange,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  }
}
