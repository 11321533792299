import { InputItem, AmountInputItem, FormModalWrapper } from 'src/common'
import { useFormPowerOptimizer } from './hooks'
import { SelectItem } from 'src/common'
import { Select } from 'antd'

const FormPowerOptimizersFormModal = ({
  powerOptimizerData = {},
  actionButtonTitle,
  closeHandler,
  isLoading,
  onFinish,
  errors,
  show,
  form,
}) => {
  const { fetchBrandOnScroll, brandsData, t } = useFormPowerOptimizer()

  return (
    <FormModalWrapper
      actionButtonTitle={actionButtonTitle}
      modalTitle={t('power_optimizers')}
      closeHandler={closeHandler}
      labelCol={{ span: 7 }}
      isLoading={isLoading}
      onFinish={onFinish}
      form={form}
      show={show}
    >
      <InputItem error={errors?.name} label={t('name')} name='name' />

      <SelectItem
        defaultValue={powerOptimizerData?.brand?.id}
        onPopupScroll={fetchBrandOnScroll}
        label={t('converter_brand')}
        error={errors?.brand_id}
        name='brand_id'
        form={form}
      >
        {powerOptimizerData?.brand && (
          <Select.Option
            key={powerOptimizerData?.brand?.id}
            value={powerOptimizerData?.brand?.id}
          >
            {powerOptimizerData?.brand?.name}
          </Select.Option>
        )}
        {brandsData?.map((page) => {
          return page?.data?.data?.map((brand) => {
            if (powerOptimizerData?.brand?.id !== brand?.id) {
              return (
                <Select.Option key={brand?.id} value={brand?.id}>
                  {brand?.name}
                </Select.Option>
              )
            } else {
              return null
            }
          })
        })}
      </SelectItem>

      <InputItem error={errors?.code} label={t('code')} name='code' />

      <InputItem
        error={errors?.max_dc}
        label={t('max_dc')}
        type='number'
        name='max_dc'
      />

      <InputItem
        label={t('length_in_cm')}
        error={errors?.length}
        type='number'
        name='length'
      />

      <InputItem
        label={t('width_in_cm')}
        error={errors?.width}
        type='number'
        name='width'
      />

      <InputItem
        label={t('depth_in_cm')}
        error={errors?.depth}
        type='number'
        name='depth'
      />

      <InputItem
        error={errors?.efficiency}
        label={t('efficiency')}
        name='efficiency'
        type='number'
      />

      <InputItem
        label={t('warranty_per_year')}
        error={errors?.warranty}
        required={false}
        name='warranty'
        type='number'
      />

      <AmountInputItem
        defaultValue={powerOptimizerData?.purchase_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.purchase_price}
        label={t('purchase_price')}
        name='purchase_price'
      />

      <AmountInputItem
        defaultValue={powerOptimizerData?.selling_price}
        setFieldsValue={form.setFieldsValue}
        error={errors?.selling_price}
        label={t('selling_price')}
        name='selling_price'
      />
    </FormModalWrapper>
  )
}

export default FormPowerOptimizersFormModal
