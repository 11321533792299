import ShowMaterialsModal from './components/ShowMaterialsModal'
import AddMaterialModal from './components/AddMaterialModal'
import MaterialTableColumns from './MaterialsTableColumns'
import { canPerform, getTableSortValue } from 'src/utils'
import { useMaterials } from './useMaterials'
import { Table } from 'antd'
import {
  AgreeDeleteModal,
  ButtonWithTrash,
  ButtonWithPlus,
  ImportCvsModal,
  CsvButton,
  Search,
  Header,
} from 'src/common'

const Materials = () => {
  const {
    showMultipleDeleteConfirmation,
    showMultipleDeleteModalHandler,
    deleteAllSelectedProducts,
    setShowAddMaterialModal,
    isMaterialsDataLoading,
    setShowImportCsvModal,
    setShowMaterialModal,
    showAddMaterialModal,
    selectedMaterialsIds,
    deletingMultipleItem,
    showImportCsvModal,
    showMaterialModal,
    setDeleteMaterial,
    handlePageChange,
    exportMaterials,
    paginationInfo,
    importMaterials,
    deleteMaterial,
    DeleteMaterial,
    setImportError,
    exportDisabled,
    onSearchClick,
    rowSelection,
    importError,
    materials,
    importing,
    deleting,
    setSort,
    onRow,
    t,
  } = useMaterials()

  return (
    <div className='manageScreensLayout'>
      <Header />
      <div className='manageScreensLayout__title'>{t('materials')}</div>
      <div className='manageScreensLayout__content'>
        <div className='contentheader'>
          <Search
            onSearchClick={onSearchClick}
            disabled={isMaterialsDataLoading}
            className='contentheader__search'
            placeholder={t('search_word')}
          />
          <ButtonWithPlus
            disabled={
              canPerform('manage material') || canPerform('manage own material')
                ? false
                : true
            }
            text={t('add')}
            onClick={() => setShowAddMaterialModal(true)}
          />
          <ButtonWithTrash
            onClick={showMultipleDeleteModalHandler}
            disabled={!selectedMaterialsIds.length}
            text={t('delete_selected_item')}
          />
        </div>

        <Table
          rowSelection={rowSelection}
          scroll={{ x: '992px' }}
          bordered
          columns={MaterialTableColumns(setDeleteMaterial, t)}
          dataSource={materials}
          onRow={onRow}
          loading={isMaterialsDataLoading}
          rowKey={(record) => {
            return record?.id
          }}
          pagination={{
            total: paginationInfo.total,
            current: paginationInfo.page,
            onChange: (page, size) => {
              handlePageChange(page, size)
            },
          }}
          onChange={(_pagination, _filters, sorter) => {
            setSort(getTableSortValue(sorter))
          }}
        />

        <div className='contentFooter'>
          <CsvButton
            onClick={exportMaterials}
            disabled={exportDisabled}
            text={t('download_cvs')}
          />
          <CsvButton
            disabled={
              canPerform('manage material') || canPerform('manage own material')
                ? false
                : true
            }
            text={t('import_cvs')}
            onClick={() => setShowImportCsvModal(true)}
          />
        </div>
      </div>

      {showImportCsvModal ? (
        <ImportCvsModal
          show={showImportCsvModal}
          closeModal={() => setShowImportCsvModal(false)}
          submitModal={importMaterials}
          disabled={importing}
          importError={importError}
          setImportError={setImportError}
        />
      ) : null}

      {showAddMaterialModal && (
        <AddMaterialModal
          setShowAddMaterialModal={setShowAddMaterialModal}
          show={showAddMaterialModal}
        />
      )}

      {showMaterialModal.show && (
        <ShowMaterialsModal
          setShowMaterialModal={setShowMaterialModal}
          currentMaterial={showMaterialModal.data}
          show={showMaterialModal.show}
        />
      )}

      {deleteMaterial.show && (
        <AgreeDeleteModal
          disabled={deleting}
          visible={deleteMaterial.show}
          targetName={deleteMaterial?.data?.name}
          onCancel={() => setDeleteMaterial({ show: false, data: {} })}
          onSubmit={() =>
            deleteMaterial.data.id && DeleteMaterial(deleteMaterial.data.id)
          }
        />
      )}

      {showMultipleDeleteConfirmation && (
        <AgreeDeleteModal
          text={t('delete_selected_items_confirmation')}
          onCancel={showMultipleDeleteModalHandler}
          visible={showMultipleDeleteConfirmation}
          onSubmit={deleteAllSelectedProducts}
          disabled={deletingMultipleItem}
        />
      )}
    </div>
  )
}
export default Materials
