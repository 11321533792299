import { statuses, salesProjectStatusIds } from 'src/common'
import { isSales } from 'src/utils'

export const getProjectStatuses = (selectedStatusId = null) => {
  if (isSales()) {
    return statuses.filter(
      (el) =>
        salesProjectStatusIds.includes(el?.id) || el?.id === selectedStatusId
    )
  }

  return statuses
}
