import i18next from 'i18next'

export const roles = {
  admin: 1,

  sales: 2,

  supplier: 3,

  technician: 4,

  superUser: 5,

  salesPlus: 6,

  worker: 7,

  planner: 8,
}

export const translateRole = (roleName) => {
  if (!roleName || typeof roleName !== 'string') {
    return
  }
  return i18next.t(roleName.toLowerCase())
}

export const getRoleNameById = (id) => {
  const roleName = Object.entries(roles).find((role) => role[1] === id)?.[0]

  return translateRole(roleName) || ''
}
