export function appendValuesToFormData(values) {
  if (!values) return
  const formData = new FormData()

  for (let key in values) {
    if (values.hasOwnProperty(key)) {
      if (values[key] !== 0 && !values[key]) {
        formData.append(key, '')
      } else {
        formData.append(key, values[key])
      }
    }
  }
  return formData
}
