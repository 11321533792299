import { WifiModule, Converter, AddInputs, PowerOptimizer } from './Components'
import { useConvertersAccordion } from './useConvertersAccordion'
import { AccordionWrapper } from 'src/common'
import { canManageProject } from 'src/utils'
import { Collapse, Typography } from 'antd'

const Converters = ({
  converters = [],
  wifiModules = [],
  powerOptimizers = [],
  projectCompanyId,
}) => {
  const {
    showPowerOptimizer,
    closePowerOptimizer,
    openPowerOptimizer,
    openWifiAddingForm,
    openConverterAddingForm,
    closeWifiAddingForm,
    closeConverterAddingForm,
    showWifiAddingForm,
    showConverterAddingForm,
    removingConverter,
    removeConverter,
    removeWifi,
    removingWifi,
    removingPowerOptimizer,
    removePowerOptimizer,

    translate,
  } = useConvertersAccordion()

  return (
    <div className='survey__accordion'>
      <AccordionWrapper>
        <Collapse.Panel header={`${translate('converters_upper')}*`} key='1'>
          <div className='converters'>
            <Typography.Title level={4} className='capitalize_text'>
              {`${translate('converters')}*`}
            </Typography.Title>
            {converters?.length
              ? converters?.map((item) => (
                  <Converter
                    deleteConverter={removeConverter}
                    deleteDisable={removingConverter}
                    key={item?.id}
                    data={item}
                    projectCompanyId={projectCompanyId}
                  />
                ))
              : null}
            {showConverterAddingForm ? (
              <Converter
                deleteConverter={closeConverterAddingForm}
                projectCompanyId={projectCompanyId}
              />
            ) : null}
          </div>
          <AddInputs
            onClick={openConverterAddingForm}
            text={translate('add_converter')}
            disabled={showConverterAddingForm || !canManageProject()}
          />

          <div className='wifimodules'>
            <Typography.Title level={4}>
              {translate('power_optimizers')}
            </Typography.Title>
            {powerOptimizers?.map((item) => {
              return (
                <PowerOptimizer
                  deletePowerOptimizer={removePowerOptimizer}
                  deleteDisable={removingPowerOptimizer}
                  projectCompanyId={projectCompanyId}
                  key={item?.id}
                  data={item}
                />
              )
            })}
          </div>
          {showPowerOptimizer ? (
            <PowerOptimizer
              deletePowerOptimizer={closePowerOptimizer}
              projectCompanyId={projectCompanyId}
            />
          ) : null}
          <AddInputs
            onClick={openPowerOptimizer}
            disabled={showPowerOptimizer || !canManageProject()}
            text={translate('add_power_optimizer')}
          />

          <div className='wifimodules'>
            <Typography.Title level={4}>
              {`${translate('network')}*`}
            </Typography.Title>
            {wifiModules?.map((item) => {
              return (
                <WifiModule
                  deleteWifi={removeWifi}
                  deleteDisable={removingWifi}
                  key={item?.id}
                  data={item}
                  projectCompanyId={projectCompanyId}
                />
              )
            })}
          </div>
          {showWifiAddingForm ? (
            <WifiModule
              deleteWifi={closeWifiAddingForm}
              projectCompanyId={projectCompanyId}
            />
          ) : null}
          <AddInputs
            onClick={openWifiAddingForm}
            disabled={showWifiAddingForm || !canManageProject()}
            text={translate('add_wifi_module')}
          />
        </Collapse.Panel>
      </AccordionWrapper>
    </div>
  )
}

export default Converters
