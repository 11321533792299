import { useNavigationLink } from './hooks'
import { NavLink } from 'react-router-dom'
import { escLogo } from 'src/assets'
import React from 'react'

const NavigationLink = ({
  linkClassName = '',
  className = '',
  styles = {},
  imageSrc,
  logoLink,
  onClick,
  title,
  Icon,
  uri,
}) => {
  const { currentRoute, t } = useNavigationLink(title, uri)

  return (
    <>
      {logoLink ? (
        <NavLink to='/' className='sideNavigation__logo'>
          <img alt='Energy service center logo' src={escLogo} />
        </NavLink>
      ) : (
        <NavLink className={linkClassName} onClick={onClick} to={uri} exact>
          <div
            style={styles}
            className={`sideNavigation__navItem ${
              title !== 'log_out' && currentRoute
                ? `sideNavigation__navItem--active ${
                    imageSrc && 'user__navItem--active'
                  }}`
                : ''
            } ${className}`}
          >
            {imageSrc && <img src={imageSrc} alt='user profile' />}

            {Icon && !imageSrc && <Icon />}

            {title && (
              <p
                className={`${
                  title !== 'log_out' &&
                  currentRoute &&
                  'sideNavigation__navItem__selected'
                } title slide-in-left-animation`}
              >
                {t(title)}
              </p>
            )}
          </div>
        </NavLink>
      )}
    </>
  )
}

export default React.memo(NavigationLink)
