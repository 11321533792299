import { useTranslation } from 'react-i18next'
import { getProductDisplayInfo } from '../../../utils'

function OfferProduct({ productDisplayInfo }) {
  const { t } = useTranslation()

  return (
    <div className='product'>
      <div className='product-content'>
        <h6>{t(productDisplayInfo?.name)}</h6>
        <span>{productDisplayInfo?.type}</span>
      </div>
      <div className='product-content'>
        <h6>{t('quantity')}</h6>
        <span>{productDisplayInfo?.quantityCount}</span>
      </div>
    </div>
  )
}

function OfferProducts({ products }) {
  return (
    <div className='products-wrapper'>
      {products?.map((product) => {
        return (
          <OfferProduct
            key={product.id}
            productDisplayInfo={getProductDisplayInfo(product)}
          />
        )
      })}
    </div>
  )
}

export default OfferProducts
