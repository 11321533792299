import { Form, Select } from 'antd'

import { Space } from 'antd'

import { ShowFieldError, TimePicker } from 'src/common'
import {
  DEFAULT_TIMEZONE,
  EUROPEAN_TIMEZONE,
  WEEKDAYS,
  extractDateAndTime,
} from 'src/utils'
import { usePlanningTool } from './hooks/usePlanningTool'

function PlanningTool({ form, errors }) {
  const { setInitialData, setHours, hours, t } = usePlanningTool(form)

  return (
    <div className='planning-tool-wrapper'>
      <Space direction='vertical'>
        <div>{t('choose_time_zone')}</div>

        <Form.Item>
          <Form.Item name='timezone' noStyle>
            <Select
              defaultValue={DEFAULT_TIMEZONE}
              style={{ minWidth: '15rem' }}
            >
              {EUROPEAN_TIMEZONE().map((zone) => {
                return (
                  <Select.Option key={zone.value} value={zone.value}>
                    {zone.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <ShowFieldError withHeight={true} error={errors?.timezone} />
        </Form.Item>
      </Space>

      <Form.Item>
        <Form.Item name='working_hours' noStyle>
          <div className='weekdays-wrapper'>
            <div className='chose-working-hours'>
              {t('choose_working_hours')}
            </div>
            {WEEKDAYS().map((day) => {
              return (
                <div className='day-and-time' key={day.value}>
                  <div className='weekday-name'>{day.name}</div>

                  <TimePicker
                    className='time-picker'
                    picker='time'
                    format='HH:mm'
                    status='success'
                    defaultValue={() => setInitialData(hours?.[day.value])}
                    use12Hours={false}
                    onChange={(val) => {
                      const temp = {
                        ...hours,
                        [day.value]: val && {
                          start: extractDateAndTime(val?.[0])?.time,
                          end: extractDateAndTime(val?.[1])?.time,
                        },
                      }

                      setHours(temp)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </Form.Item>
        <ShowFieldError withHeight={true} error={errors?.working_hours} />
      </Form.Item>
    </div>
  )
}

export default PlanningTool
