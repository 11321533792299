import { useMemo } from 'react'
import { Form } from 'antd'
import i18next from 'i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { formItemErrorClasses } from 'src/utils'
import { ShowFieldError } from 'src/common'
import nl from 'src/assets/countries/nl.json'

const PhoneNumberInput = ({
  label,
  name,
  country = 'nl',
  enableSearch = true,
  enableLongNumbers = true,
  placeholder = '',
  error = '',
  wrapperClassName = 'custom-antd-input-item',
  preferredCountries = [],
  inputStyles = {},
  noStyle = true,
  errorClass = '',
  onChange,
  searchPlaceHolder = '',
  required = false,
}) => {
  const localization = useMemo(() => {
    return i18next.language === 'nl' ? nl : ''
  }, [i18next.language])

  return (
    <Form.Item
      className={`${wrapperClassName} ${formItemErrorClasses(error)} ${
        error && 'ant-input-border-error'
      } `}
      required={required}
      label={label}
      name={name}
    >
      <Form.Item name={name} noStyle={noStyle}>
        <PhoneInput
          searchPlaceholder={searchPlaceHolder || i18next.t('search_word')}
          preferredCountries={['nl', ...preferredCountries]}
          enableLongNumbers={enableLongNumbers}
          localization={localization}
          enableSearch={enableSearch}
          placeholder={placeholder}
          country={country}
          inputStyle={{
            width: '100%',
            minHeight: '32px',
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            ...inputStyles,
          }}
          onChange={onChange}
        />
      </Form.Item>

      <ShowFieldError withHeight error={error} className={errorClass} />
    </Form.Item>
  )
}

export default PhoneNumberInput
