import i18next from 'i18next'

// const FULL_LIST = Intl.supportedValuesOf('timeZone')

// const EUROPE = FULL_LIST.filter(isEurope)

// const ZONE_SHORT_NAME = EUROPE.map(getShortName)

const EUROPEAN_TIMEZONE = () => [
  {
    name: `(UTC+01:00) ${i18next.t('west_and_central_european_timezone')}`,
    value: '+1',
  },
  {
    name: `(UTC+02:00) ${i18next.t('east_european_timezone')}`,
    value: '+2',
  },

  {
    name: `UTC+03:00`,
    value: '+3',
  },
  {
    name: `UTC+04:00`,
    value: '+4',
  },
]

const DEFAULT_TIMEZONE = getZoneOffset(
  getShortName(Intl.DateTimeFormat().resolvedOptions().timeZone)
)

// function isEurope(value = '') {
//   return value.toLowerCase().includes('europe')
// }

function getZoneOffset(value = '') {
  return value.split('GMT')?.[1]
}

function getShortName(timeZone) {
  const test = new Intl.DateTimeFormat('en', {
    timeZoneName: 'short',
    timeZone,
  }).formatToParts()

  return test.find(({ type }) => type === 'timeZoneName').value
}

export { EUROPEAN_TIMEZONE, DEFAULT_TIMEZONE }
