import { modalView } from '../data'
import { useQuery } from 'react-query'
import { offerInfoRequest } from '../../../services'
import { useMemo, useState } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

function useHandleModalView(modalData) {
  const [activeViewName, setActiveViewName] = useState(
    modalView.PLANNING_DETAILS
  )

  const [isCondense, setIsCondense] = useState(false)
  const [isDetails, setIsDetails] = useState(true)
  const [isActivity, setIsActivity] = useState(false)

  const { data: offerData, isLoading: offerDataIsLoading } = useQuery(
    ['offer', modalData?.id],
    () => offerInfoRequest(modalData?.id),
    {
      enabled: !!modalData?.id,
      select: (res) => res.data,
    }
  )

  const { lg, sm } = useBreakpoint()

  const showHistory = useMemo(() => {
    if (!isCondense && !lg && isActivity) {
      return true
    }

    if (!isCondense && lg) {
      return true
    }

    if (isCondense && isActivity) {
      return true
    }

    return false
  }, [isCondense, lg, isActivity])

  const showDetails = useMemo(() => {
    if (!isCondense && !lg && isDetails) {
      return true
    }

    if (!isCondense && lg) {
      return true
    }

    if (isCondense && isDetails) {
      return true
    }

    return false
  }, [isCondense, lg, isDetails])

  function handleCondenseView() {
    setIsDetails(!isDetails)
    setIsActivity(!isActivity)
  }

  function condenseModalOnClick() {
    setIsCondense(!isCondense)
  }

  function changeViewOnClick(modalView) {
    setActiveViewName(modalView)
  }

  function isPlanningDetailsActive() {
    return activeViewName === modalView.PLANNING_DETAILS
  }

  function isProductInformationActive() {
    return activeViewName === modalView.PRODUCT_INFORMATION
  }

  function isClientInformationAndProjectDetailsActive() {
    return activeViewName === modalView.CLIENT_INFORMATION_AND_PROJECT_DETAILS
  }

  function isDocumentActive() {
    return activeViewName === modalView.DOCUMENT
  }

  return {
    isPlanningDetailsActive: isPlanningDetailsActive(),
    isProductInformationActive: isProductInformationActive(),
    isClientInformationAndProjectDetailsActive:
      isClientInformationAndProjectDetailsActive(),
    isDocumentActive: isDocumentActive(),
    changeViewOnClick,
    offerData,
    offerDataIsLoading,
    isCondense,
    condenseModalOnClick,
    isDetails,
    isActivity,
    handleCondenseView,
    showHistory,
    showDetails,
    sm,
  }
}

export { useHandleModalView }
