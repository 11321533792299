export const renderAmount = (amount = 0) => {
  const amountSplit = amount?.toString()?.split('.')

  amountSplit[0] = amountSplit[0]
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  if (!amountSplit[1]?.length) amountSplit[1] = '00'
  if (amountSplit[1]?.length === 1) amountSplit[1] += '0'

  return `€ ${amountSplit.join(',')}`
}
