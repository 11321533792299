export const companySections = {
  SETUP: 'setup',
  FINANCIAL_INFORMATION: 'financial_information',
  EMAIL_INFORMATION: 'email_information',
  LEAD_INTEGRATION: 'lead_integration',
  CONFIGURATION: 'configuration',
}

export const companySectionFormClassnames = {
  [companySections.SETUP]: 'setup-form',
  [companySections.FINANCIAL_INFORMATION]: 'financial-information-form',
  [companySections.EMAIL_INFORMATION]: 'email-information-form',
  [companySections.LEAD_INTEGRATION]: 'lead-integration-form',
  [companySections.CONFIGURATION]: 'configuration-form',
}
