import 'react-big-calendar/lib/css/react-big-calendar.css'
import { TASK_COLORS, dateFnsCalendarLocalizer, eventsDummyData } from '../data'

import { Calendar } from 'react-big-calendar'

import { PrevIcon, NextIcon } from 'src/assets'
import { usePlanningCalendar } from '../hooks'
import CustomMonthEvent from './CustomMonthEvent'
import CustomEvent from './CustomEvent'

const eventStyleGetter = (event) => {
  const style = {
    backgroundColor: TASK_COLORS[event.resource.status]?.background,
    border: '1px solid #DEE2E6',
    height: 'auto',
  }

  return {
    style,
  }
}

function PlanningCalendar({ onEditClick }) {
  const { handleViewChange, currentView, isDayView, isDutch, t } =
    usePlanningCalendar()

  return (
    <div style={{ position: 'relative', paddingTop: '5rem' }}>
      <div style={{ overflowX: 'auto' }}>
        <Calendar
          localizer={dateFnsCalendarLocalizer}
          events={eventsDummyData}
          components={{
            event: (ctx) => (
              <CustomEvent event={ctx.event} onEditClick={onEditClick} />
            ),

            month: {
              event: (ctx) => (
                <CustomMonthEvent event={ctx.event} onEditClick={onEditClick} />
              ),
            },
          }}
          startAccessor='start'
          className={`planning-calendar ${isDayView && 'day-view'}`}
          endAccessor='end'
          style={{ height: 800 }}
          onView={handleViewChange}
          culture={isDutch ? 'nl' : 'en-GB'}
          views={['month', 'week', 'day']}
          view={currentView}
          formats={{ eventTimeRangeFormat: () => null }}
          eventPropGetter={eventStyleGetter}
          popup
          dayLayoutAlgorithm='no-overlap'
          messages={{
            month: t('month'),
            day: t('day'),
            week: t('week'),
            next: <NextIcon />,
            today: t('today'),
            tomorrow: t('tomorrow'),
            previous: <PrevIcon />,
          }}
        />
      </div>
    </div>
  )
}

export default PlanningCalendar
