import { appendCompanyId } from 'src/utils'
import axios from '../../axios'

export const allConvertersRequest = async ({
  searchKeyWord = '',
  brandId = '',
  sort = '',
  companyId,
  size = 10,
  page,
}) => {
  return await axios.get(
    `api/converters?page=${page}&per_page=${size}${
      brandId && `&brands[]=${brandId}`
    }${searchKeyWord && `&search=${searchKeyWord}`}${appendCompanyId(
      companyId
    )}${sort ? `&sort[]=${sort}` : ''}`
  )
}

export const importConvertersRequest = async (data) => {
  return await axios.post('api/converters/import', data)
}

export const addConverterRequest = async (data) => {
  return await axios.post('api/converters', data)
}

export const updateConverterRequest = async (id, data) => {
  return await axios.put(`api/converters/${id}`, data)
}

export const deleteConverterRequest = async (id) => {
  return await axios.delete(`api/converters/${id}`)
}
