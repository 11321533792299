import { useProductDetailsInfo } from 'src/screens/Project/OffersAndComments/hooks'

function ProductDetails({ project }) {
  const { category, status, t } = useProductDetailsInfo(project)

  return (
    <>
      <div className='client_information_header product_details_header'>
        <h4>{t('project_details')}</h4>
      </div>
      <div className='client_information_and_project_container'>
        <div className='client_information_and_project_group group_border_right'>
          <div className='information_row'>
            <span>{t('status')}</span>

            <span
              style={{
                color: status?.color,
              }}
              className='status'
            >
              {t(status?.name)}
            </span>
          </div>
          <div className='information_row'>
            <span>{t('account_manager')}</span>
            <span>{project?.manager?.name}</span>
          </div>
          <div className='information_row'>
            <span>{t('executor')}</span>
            <span>{project?.executor?.name}</span>
          </div>
        </div>
        <div className='client_information_and_project_group'>
          <div className='information_row'>
            <span>{t('condition')}</span>
            <span>{project?.condition?.name}</span>
          </div>
          <div className='information_row'>
            <span>{t('category')}</span>
            <span>{t(category?.name)}</span>
          </div>
          <div className='information_row'>
            <span>{t('annual_consumption')}</span>
            <span>{project?.annual_consumption}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetails
