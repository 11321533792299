import React from 'react'
import { Table, DatePicker } from 'antd'

import { CsvButton, Header, NoData } from 'src/common'

import { BsArrowRight } from 'react-icons/bs'
import { columns } from './table/columns'
import { useGeneralReport } from './hooks'
import ProjectStatusesChart from './ProjectStatusesChart'

const GeneralReporting = () => {
  const {
    projects,
    isLoading,
    handleDateChange,
    filters,
    successRate,
    exportGeneralReporting,
    exporting,
    paginationInfo,
    handlePageChange,
    handleTableChange,
    projectStatuses,
    isCountDataLoading,
    t,
  } = useGeneralReport() || {}

  return (
    <div className='general-reporting'>
      <Header />
      <div className='general-reporting__title'>{t('general_reporting')}</div>
      <div className='general-reporting__contentitem'>
        <div className='general-reporting__content'>
          <div className='general-reporting__ratioAndChartWrapper'>
            <div className='general-reporting__ratioWrapper'>
              <h5 className='general-reporting__ratioWrapper__title'>
                {t('success_rate')}
              </h5>
              <div className='general-reporting__ratioWrapper__body'>
                {successRate || successRate === 0 ? (
                  `${Number(successRate).toFixed(2)}%`
                ) : (
                  <NoData isLoading={isCountDataLoading} />
                )}
              </div>
            </div>
            <div className='general-reporting__chartWrapper'>
              {projectStatuses?.length ? (
                <ProjectStatusesChart projectStatuses={projectStatuses} />
              ) : (
                <div className='general-reporting__chartWrapper__empty'>
                  <NoData isLoading={isCountDataLoading} />
                </div>
              )}
            </div>
          </div>

          <div className='contentheader'>
            <div />
            <DatePicker.RangePicker
              onChange={(_, dateString) => {
                handleDateChange(dateString)
              }}
              className='contentheader__datepicker_container__datepicker'
              format='YY-MM-DD'
              separator={<BsArrowRight color='#bfbfbf' />}
            />
          </div>
          <Table
            scroll={{ x: '992px' }}
            bordered
            rowKey={(record) => record.id}
            columns={columns(filters)}
            pagination={{
              total: paginationInfo.total,
              current: paginationInfo.page,
              onChange: (page, size) => {
                handlePageChange(page, size)
              },
            }}
            dataSource={projects}
            loading={isLoading}
            onChange={handleTableChange}
          />
          <div className='contentFooter'>
            <CsvButton
              disabled={exporting || !projects?.length}
              onClick={exportGeneralReporting}
              text={t('download_cvs')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralReporting
