import { canPerform, hasRole } from './checkRole'
import { roles } from './roles'

export function isSuperUser() {
  return hasRole(roles.superUser)
}

export function isAdmin() {
  return hasRole(roles.admin)
}

export function isTechnician() {
  return hasRole(roles.technician)
}

export function isSales() {
  return hasRole(roles.sales)
}

export function isSalesPlus() {
  return hasRole(roles.salesPlus)
}

export function isSupplier() {
  return hasRole(roles.supplier)
}

export function isPlanner() {
  return hasRole(roles.planner)
}

export function isWorker() {
  return hasRole(roles.worker)
}

export function isSalesOrSalesPlus() {
  return hasRole(roles.sales) || hasRole(roles.salesPlus)
}

export function isSuperUserOrAdmin() {
  return isSuperUser() || isAdmin()
}

export function isSalesPlusIsAdminOrIsSuperUser() {
  return isSuperUser() || isAdmin() || isSalesPlus()
}

export function disableHelper(roleId) {
  if (roleId === roles.superUser) {
    return true
  }
  if (hasRole(roles.admin)) {
    return roles.admin === roleId
  }

  return false
}

export function showSettings() {
  if (hasRole(roles.technician)) {
    return false
  }
  return (
    showConditions() ||
    showUsers() ||
    showPanels() ||
    showConverters() ||
    showWifiModules() ||
    showMountingSystems() ||
    showMaterials() ||
    showActivities() ||
    showActivityTypes() ||
    showPowerOptimizers()
  )
}

export const showCheckPriceDetails = () => {
  return isSalesPlus() || isAdmin() || isSuperUser()
}

export function showUsers() {
  if (hasRole(roles.technician)) {
    return false
  }
  return canPerform('view user') || canPerform('view own user')
}
export function showCompanies() {
  return canPerform('view company') || canPerform('view own company')
}

export function showConditions() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view condition') || canPerform('view onw condition')
}

export function showMaterials() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view material') || canPerform('view own material')
}

export function showConverters() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view converter') || canPerform('view own converter')
}

export function showPowerOptimizers() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return (
    canPerform('view own power-optimizer') || canPerform('view power-optimizer')
  )
}

export function showMountingSystems() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return (
    canPerform('view own mounting-system') || canPerform('view mounting-system')
  )
}

export function showWifiModules() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view own wifi-module') || canPerform('view wifi-module')
}

export function showActivities() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view activity') || canPerform('view own activity')
}

export function showActivityTypes() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return (
    canPerform('view activity-type') || canPerform('view own activity-type')
  )
}

export function showPanels() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return canPerform('view panel') || canPerform('view own panel')
}
export function showProjects() {
  return canPerform('view project') || canPerform('view own project')
}

export function showReports() {
  return canPerform('view reports') || canPerform('view own reports')
}

export function canManageProject() {
  if (isTechnician()) {
    return false
  }
  return canPerform('manage project') || canPerform('manage own project')
}

export function hidePurchasePrice() {
  if (hasRole(roles.sales) || hasRole(roles.technician)) {
    return true
  }
  return false
}

export function showProducts() {
  if (isSalesOrSalesPlus() || hasRole(roles.technician)) {
    return false
  }
  return (
    showPanels() ||
    showConverters() ||
    showWifiModules() ||
    showMountingSystems() ||
    showMaterials() ||
    showActivities() ||
    showActivityTypes() ||
    showPowerOptimizers()
  )
}

export function showPlanningPipeline() {
  return isAdmin() || isPlanner() || isWorker()
}
export const showLeads = () => {
  return isSuperUser() || isAdmin()
}
