import { isAdmin, isSuperUser } from 'src/utils'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'

export const useTableSettings = (onDelete, onArchive, isArchived) => {
  const { t } = useTranslation()

  const onClickHandler = (e, callBack) => {
    e.domEvent.stopPropagation()
    callBack && callBack()
  }

  const clickHandler = (e) => {
    e.stopPropagation()
  }

  const menu = (
    <Menu className='archive-delete-box-menu'>
      <Menu.Item
        onClick={(e) => onClickHandler(e, onArchive)}
        className='archive-delete-box-menu__item'
        key='1'
      >
        {isArchived ? t('unarchive') : t('archive')}
      </Menu.Item>

      {(isSuperUser() || isAdmin()) && (
        <Menu.Item
          className='archive-delete-box-menu__item archive-delete-box-menu__item__delete'
          onClick={(e) => onClickHandler(e, onDelete)}
          key='2'
        >
          {t('delete')}
        </Menu.Item>
      )}
    </Menu>
  )

  return { menu, clickHandler }
}
