import { useQueryClient, useMutation } from 'react-query'
import { addNewSourceTokenRequest } from 'src/services'
import { useTranslation } from 'react-i18next'
import { Form, message } from 'antd'
import { useState } from 'react'

export const useAddNewSource = () => {
  const [showAddNewSourceModal, setShowAddNewSourceModal] = useState(false)
  const [errors, setErrors] = useState({})

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const modalCloseHandler = () => {
    form.resetFields()
    setErrors({})
    setShowAddNewSourceModal(false)
  }

  const { mutate: addSourceMutation, isLoading } = useMutation(
    addNewSourceTokenRequest,
    {
      onSuccess: () => {
        queryClient.refetchQueries('companies')
        message.success(t('source_added_successfully'))
        modalCloseHandler()
      },

      onError: (error) => setErrors(error?.response?.data?.errors),
    }
  )

  return {
    setShowAddNewSourceModal,
    showAddNewSourceModal,
    addSourceMutation,
    modalCloseHandler,
    isLoading,
    errors,
    form,
    t,
  }
}
